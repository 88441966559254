import { DevTool } from '@hookform/devtools';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetDigitalGameProduct } from 'api/gstore';
import { IOrder, OOrderTab, useCreateDigitalGameOrder } from 'api/order';
import { PageLayout } from 'components';
import { useFetchResult } from 'hooks';
import { IGStoreShippingFormData } from 'types/GStoreTypes';
import eventTracker from 'utils/eventTracker';
import OrderPayInfo from './OrderPayInfo';
import OrderProductInfo from './OrderProductInfo';
import OrderShippingInfo from './OrderShippingInfo';

interface Props {
  defaultValues: IGStoreShippingFormData;
}

export default function Order({ defaultValues }: Props) {
  const methods = useForm<IGStoreShippingFormData>({ defaultValues, shouldUnregister: true });
  const navigate = useNavigate();
  const { showResult } = useFetchResult();
  const { id = '' } = useParams<{ id: string }>();
  const { data } = useGetDigitalGameProduct(Number(id));
  const { isLoading: loadingOrderDigitalGame, fetch: orderDigitalGame } =
    useCreateDigitalGameOrder();

  const digitalGameData = data?.data;

  const onSubmit = async () => {
    if (!digitalGameData) return;

    eventTracker('gstore_order_placeorder', {
      event_label: digitalGameData.id ?? '',
    });

    const data: IOrder.CreateOrder.Request = {
      digital_game_id: digitalGameData.id,
      quantity: 1,
      price: digitalGameData.price,
    };

    const response = await orderDigitalGame(data);
    showResult(response, {
      onSuccess: () => {
        navigate('/store/success', { state: OOrderTab.DIGITAL_GAMES });
      },
      onError: () => {
        navigate('/store/failed', { state: OOrderTab.DIGITAL_GAMES });
      },
    });
  };

  return (
    <PageLayout mt="80px" width="1200px" alignItems="flex-start" gap="60px">
      <OrderProductInfo digitalGameData={digitalGameData} />
      <FormProvider {...methods}>
        <DevTool control={methods.control} placement="top-right" />
        <form
          style={{ display: 'flex', gap: 40 }}
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <OrderShippingInfo />
          <OrderPayInfo loading={loadingOrderDigitalGame} price={digitalGameData?.price ?? 0} />
        </form>
      </FormProvider>
    </PageLayout>
  );
}
