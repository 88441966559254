import { styled } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { ImageBox } from 'atoms';
import { GameCardTinyProps } from '.';

const ImageWrap = styled(Link)({
  width: '100%',
  height: '100%',
});

export default function GameCardTinyUpperArea({ id, thumbnail_url }: GameCardTinyProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  return (
    <ImageWrap ref={ref} to={`/detail/${id}`}>
      {inView && thumbnail_url && (
        <ImageBox
          src={thumbnail_url}
          alt="thumbnail"
          width={'100%'}
          height={'100%'}
          resize
          resizeWidth={285}
          resizeHeight={184}
          overlay
          hoverEffect
          sx={{ borderRadius: '5px' }}
        />
      )}
    </ImageWrap>
  );
}
