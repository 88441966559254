import 'react-app-polyfill/stable';

import { createGroundTheme } from '@ground/ui';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { SWRConfig, SWRConfiguration } from 'swr';

import { store } from 'store';
import 'utils/api';
import App from 'views/App';
import AppSnackbarContainer from 'views/AppSnackbarContainer';
import packageJson from '../package.json';
import { loadScript } from './loadScript';

import '@ground/text-editor/dist/style.css';

// global variable 타입 정의
declare global {
  // twitter universal web tag
  const twq: any; // 정의된 타입 알 수 없음
  // twitter sdk
  const twttr: any; // 정의된 타입 알 수 없음
  // reddit sdk
  const rdt: any; // 정의된 타입 알 수 없음

  // custom으로 추가한 env 값의 정의
  namespace NodeJS {
    interface ProcessEnv {
      readonly REACT_APP_ENV: 'DEV' | 'STAGE' | 'PROD';
      readonly REACT_APP_API_URL: string;
      readonly REACT_APP_DEBUG: 'ON' | 'OFF'; // default OFF
      readonly REACT_APP_LOG: 'ON' | 'OFF'; // default OFF
      readonly REACT_APP_MSW: 'ON' | 'OFF'; // default OFF
    }
  }
}

// event tracking script
if (process.env.REACT_APP_ENV === 'PROD') {
  loadScript();
}

const swrOptions: SWRConfiguration = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenHidden: false,
  refreshWhenOffline: false,
  dedupingInterval: 100,
  focusThrottleInterval: 100,
};

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_MSW === 'OFF') {
    return;
  }

  const { worker } = await import('./mocks/browser');
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: 'bypass' });
}

Sentry.init({
  dsn: 'https://e77d07ca80df4952c4dc15ab0d8a6eeb@o4506385814978560.ingest.sentry.io/4506385825136640',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        window.location.origin,
        /^https:\/\/(.*\.)?gameround\.co\/api(?:\/.*)/,
      ],
      networkRequestHeaders: ['Cache-Control'],
      networkResponseHeaders: ['Referrer-Policy'],
    }),
    new HttpClientIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/(.*\.)?gameround\.co\/api/],

  // Session Replay
  replaysSessionSampleRate: 0, // 수집 가능한 replay 개수가 월 500개 밖에 안되서 일반 세션은 제외하고 에러만 수집
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV.toLocaleLowerCase(), // dev, stage, prod
  release: packageJson.version, // package version
  enabled: process.env.NODE_ENV === 'production', // local에선 동작하지 않도록
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // ground custom
    'twttr is not defined',
    'FB is not defined',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

const container = document.getElementById('root') as HTMLElement;
const rootContainer = ReactDOM.createRoot(container);

enableMocking().then(() => {
  rootContainer.render(
    <Provider store={store}>
      <StyledEngineProvider>
        <ThemeProvider theme={createGroundTheme()}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <SWRConfig value={swrOptions}>
                <AppSnackbarContainer>
                  <App />
                </AppSnackbarContainer>
              </SWRConfig>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
});
