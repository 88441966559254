export interface DigitalGoodsDetail {
  id: number;
  name: string;
  display_point: number;
  point_no_display: boolean;
  main_media_url: string;
  summary: string;
  note: string;
  description: string;
  status: string;
  editor_content: string | null;
  option_name: string;
  options: ParentOption[];
  top_contents: TopContent[];
  detail_contents: DetailContent[];
  display: boolean;
}

export interface ParentOption {
  external_key: string;
  external_name: string;
  parent_option_name: string;
  children: ChildrenOption[];
}

export interface ChildrenOption {
  option_id: number;
  external_key: string;
  external_name: string;
  child_option_name: string;
  quantity: number;
  point_price: number;
}

export interface CommonContent {
  id?: number;
  media_type: 'VIDEO' | 'IMAGE';
  url: string;
  seq: number;
}

export interface TopContent extends CommonContent {
  type: 'TOP';
}

export interface DetailContent extends CommonContent {
  type: 'DETAIL';
}

export enum DigitalGoodsStatus {
  ENABLE = 'PSS_001',
  UPCOMING = 'PSS_002',
  DISABLED = 'PSS_003',
  SOLD_OUT = 'PSS_004',
}

export type DigitalGoodsList = DigitalGoodsType[];

export type DigitalGoodsType = {
  product_id: number;
  digital_goods_type_cd: string;
  language_code: string;
  digital_goods_name: string;
  display_point: string;
  point_no_display: boolean;
  main_media_url: string;
  summary: string;
  digital_goods_note: string;
  description: string;
  sales_status_cd: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: any;
  delete_at: any;
  digital_goods_type_nm: string;
  sales_status_nm: string;
  order_no: number;
  display_yn: boolean;
};
