import { styled } from '@mui/material/styles';

import ArtDevAboutHeader from './articlesDeveloper/ArtDevAboutHeader';
import ArtDevAboutTestimonials from './articlesDeveloper/ArtDevAboutTestimonials';
import ArtDevServiceValues from './articlesDeveloper/ArtDevServiceValues';
import ArtDevAboutOnBoard from './articlesDeveloper/ArtDevAboutOnBoard';
import ArtDevAboutCriteria from './articlesDeveloper/ArtDevAboutCriteria';
import ArtDevAboutPackages from './articlesDeveloper/ArtDevAboutPackages';
import ArtDevAboutStatistics from './articlesDeveloper/ArtDevAboutStatistics';
import ArtDevaboutFAQ from './articlesDeveloper/ArtDevaboutFAQ';
import ArtDevAboutContact from './articlesDeveloper/ArtDevContactUs';

const PREFIX = 'AboutDeveloper';

const classes = {
  article: `${PREFIX}-article`,

  articleBlack: `${PREFIX}-articleBlack`,
  articleBlue: `${PREFIX}-articleBlue`,
};

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  [`& .${classes.article}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  [`& .${classes.articleBlack}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.base.black,
  },

  [`& .${classes.articleBlue}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#3348b2',
  },
}));

export default function AboutDeveloper() {
  return (
    <Root>
      <article className={classes.articleBlack}>
        <ArtDevAboutHeader />
      </article>
      <article className={classes.articleBlack}>
        <ArtDevAboutTestimonials />
      </article>
      <article className={classes.article}>
        <ArtDevServiceValues />
      </article>
      <article className={classes.articleBlue}>
        <ArtDevAboutOnBoard />
      </article>
      <article className={classes.articleBlack}>
        <ArtDevAboutCriteria />
      </article>
      <article className={classes.article}>
        <ArtDevAboutPackages />
      </article>
      <article className={classes.articleBlue}>
        <ArtDevAboutStatistics />
      </article>
      <article className={classes.articleBlue}>
        <ArtDevaboutFAQ />
      </article>
      <article className={classes.articleBlack}>
        <ArtDevAboutContact />
      </article>
    </Root>
  );
}
