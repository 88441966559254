import { Link, Paper, Typography } from '@mui/material';

export default function ContactBanner() {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
        borderRadius: '5px',
        marginTop: '120px',
        gap: '20px',
      }}
    >
      <Typography variant="body1" color="text.secondary">
        If you have any questions about our products, shipping issues, or inquiries please contact
        our customer support at
      </Typography>
      <Link color="text.primary" variant="subtitle1" href="mailto:support@gameround.co">
        support@gameround.co
      </Link>
    </Paper>
  );
}
