import { useSearchParams } from 'react-router-dom';

import LauncherAuthFacebook from './LauncherAuthFacebook';
import LauncherAuthGround from './LauncherAuthGround';
import LauncherAuthWithToken from './LauncherAuthWithToken';

export default function LauncherAuthMain() {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type');
  const state = searchParams.get('state');
  const launcherToken = searchParams.get('lt');
  const verify = searchParams.get('verify');

  return state ? (
    type === 'facebook' ? (
      <LauncherAuthFacebook />
    ) : type === 'ground' ? (
      <LauncherAuthGround state={state} />
    ) : launcherToken && verify ? (
      <LauncherAuthWithToken launcherToken={launcherToken} state={state} verify={verify} />
    ) : (
      <div>Something went wrong.</div>
    )
  ) : (
    <div>Something went wrong.</div>
  );
}
