import { TextConvertLink } from '@ground/tools';
import { Link, Stack, Typography } from '@mui/material';

interface Props {
  description?: string;
  isLoading?: boolean;
}

export default function ProductDescription({ description, isLoading }: Props) {
  return (
    <Stack spacing="120px" sx={{ width: '820px' }}>
      <Stack>
        <Typography variant="h3">Product Descriptions</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          marginTop="40px"
          whiteSpace="pre-wrap"
          width="827px"
        >
          {!isLoading &&
            (description ? (
              <TextConvertLink wholeText={description} LinkComponent={Link} />
            ) : (
              'No product description'
            ))}
        </Typography>
      </Stack>
    </Stack>
  );
}
