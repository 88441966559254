import { LoadingCounter } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';

const Root = styled('div')({
  width: 255,
  height: 141,
  padding: '24px 32px',
  borderRadius: 10,
  backgroundColor: '#3C53C6',
});

export interface CardProps {
  id: number;
  topText: string;
  title: string;
  number: number;
  loading: boolean;
}

export default function ArtDevStatisticsCard({ id, topText, title, number, loading }: CardProps) {
  return (
    <Root>
      <Typography variant="body3" marginBottom="4px">
        {topText}
      </Typography>
      <Typography variant="h2">
        <LoadingCounter loading={loading} id={`counter_${id}`} endCount={number} />
        {id === 4 && !loading && '+'}
      </Typography>
      <Box
        marginTop="8px"
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '84px',
          height: '22px',
          borderRadius: 20,
          background: theme.palette.opacity.white[20],
        })}
      >
        <Typography variant="body3">{title}</Typography>
      </Box>
    </Root>
  );
}
