import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

import AboutGamerGetGpointButton from './component/AboutGamerGetGpointButton';

import clipsImgRight from 'images/about/aboutGamer/about_clips_img.png';
import gpointImgLeft from 'images/about/aboutGamer/about_gpoint_img.png';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 1200,
  paddingBottom: 100,
}));

export default function ArtAboutGamerClipsAndGpoint() {
  return (
    <Root>
      <Box display="flex">
        <Box marginRight="60px" width="490px">
          <Typography variant="h4" marginTop="77px">
            Clips
          </Typography>
          <Typography variant="body1" color="text.secondary" marginTop="24px">
            Enjoy gameplay clips from developers or game content creators.
            <br /> Check out real gameplay to see which games you’d like to play
            <br /> yourself.
          </Typography>
        </Box>
        <img src={clipsImgRight} alt="clips page thumbnail" />
      </Box>

      <Box marginTop="100px" display="flex">
        <img src={gpointImgLeft} alt="gpoint page thumbnail" />
        <Box marginLeft="60px" width="490px">
          <Typography variant="h4" marginTop="47px">
            Earn Rewards in the G.Point Store
          </Typography>
          <Typography variant="body1" color="text.secondary" marginTop="24px">
            G.Points are the loyalty reward that can be earned by our users who
            <br /> completed specific actions that can be found in our tier list.
            <br /> G.Points can be redeemed for rewards in the G.Point Store.
          </Typography>
          <AboutGamerGetGpointButton />
        </Box>
      </Box>
    </Root>
  );
}
