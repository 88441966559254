import { SxProps, Typography } from '@mui/material';
import { InputFieldBaseProps } from 'atoms';

interface Props {
  label: React.ReactNode;
  size: InputFieldBaseProps['size'];
  sx?: SxProps;
}

function FormLabel({ label, size, sx }: Props) {
  return (
    <Typography
      component="label"
      color="text.secondary"
      variant={size === 'large' ? 'subtitle2' : 'body1'}
      marginBottom="8px"
      sx={sx}
    >
      {label}
    </Typography>
  );
}

export default FormLabel;
