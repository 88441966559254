import useFetch from 'api/useFetch';
import useSWRFetch from 'api/useSWRFetch';

// 탈퇴시 - 인증코드 전송 여부
export function usePasswordStatusCheckAPI() {
  const url = 'users/password/verification';

  return useFetch(url, { method: 'GET' }, true);
}

interface ReasonType {
  user_withdrawal_reasons: {
    code: string;
    code_name: string;
  }[];
}

// 탈퇴사유 목록
export function useWithDrawalReasonAPI() {
  const url = 'code/simple?code_group=user_withdrawal_reasons';
  return useSWRFetch<ReasonType>({ key: url, useToken: true });
}

// 회원 비밀번호 체크
export function usePasswordCheckAPI() {
  return useFetch('users/password/check', { method: 'POST' }, true);
}

//회원 이메일 인증코드 요청
export function useEmailCertificationCodeAPI() {
  return useFetch('sendcode', { method: 'POST' }, true);
}

//회원 이메일 인증코드 요청
export function useVerifyCodeAPI() {
  return useFetch('users/password/verifyValidation', { method: 'POST' }, true);
}

// 탈퇴 요청
export function useWithDrawalRequestAPI() {
  return useFetch('users/withdrawal', { method: 'POST' }, true);
}
