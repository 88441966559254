import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';

import { GIconButton } from '../../atoms';

export interface ReviewPopupMenuItem {
  label?: ReactNode;
  value: number | string;
  /** divider로 사용할 땐 value는 겹치지 않는 임의 값 사용 */
  divider?: boolean;
  onClick?: (value: number | string) => void;
}

export interface ReviewPopupMenuProps {
  /** 메뉴 리스트 정의 */
  menuList: ReviewPopupMenuItem[];
  /** Menu List's props */
  MenuListProps?: MenuListProps;
  /** Menu Item's props */
  MenuItemProps?: MenuItemProps;
}

export default function ReviewPopupMenu({
  menuList,
  MenuListProps,
  MenuItemProps,
}: ReviewPopupMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickButton = () => {
    setOpen(true);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickMenuItem = (menuItem: ReviewPopupMenuItem) => {
    menuItem.onClick?.(menuItem.value);
    setOpen(false);
  };

  return (
    <div>
      <GIconButton
        ref={setAnchorEl}
        onClick={handleClickButton}
        iconName="more"
        iconSize={16}
        size={24}
        backgroundHoverColor="gray.60"
        variant="rounded"
      />
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 2] } }] }}
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper sx={{ borderRadius: '3px', bgcolor: 'gray.40' }}>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList {...MenuListProps}>
                  {menuList.map((menu) =>
                    menu.divider ? (
                      <Divider key={menu.value} sx={{ mx: '12px' }} />
                    ) : (
                      <MenuItem
                        key={menu.value}
                        sx={{
                          minWidth: 90,
                          minHeight: 'auto',
                          p: 0,
                          marginX: '12px',
                          color: 'text.secondary',
                          '&:hover': { backgroundColor: 'transparent', color: 'text.primary' },
                        }}
                        onClick={() => handleClickMenuItem(menu)}
                        {...MenuItemProps}
                      >
                        {typeof menu.label === 'string' ? (
                          <Typography noWrap variant="button2">
                            {menu.label}
                          </Typography>
                        ) : (
                          menu.label
                        )}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
