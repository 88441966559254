import { Stack } from '@mui/material';

import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import GStoreOrderDetailBanner from '../GStoreOrderDetailNotificationBanner';
import GStoreOrderDetailDeliveryDigital from './GStoreOrderDetailDeliveryDigital';
import GStoreOrderDetailDigitalPaymentBox from './GStoreOrderDetailDigitalPaymentBox';
import { useGStoreOrderDetailDigitalData } from './GStoreOrderDetailDigitalProvider';
import GStoreOrderDetailDigitalShippingInfoBox from './GStoreOrderDetailDigitalShippingInfoBox';
import GStoreOrderDetailDigitalTitleBox from './GStoreOrderDetailDigitalTitleBox';

export function GStoreOrderDetailDigitalMain() {
  const { isLoading, data } = useGStoreOrderDetailDigitalData();

  const orderId = data?.data?.order_id;

  return isLoading ? (
    <LoadingPage />
  ) : orderId ? (
    <Stack rowGap="60px" sx={{ padding: '60px 0 200px' }}>
      <GStoreOrderDetailDigitalTitleBox orderId={orderId} />
      <GStoreOrderDetailDeliveryDigital />
      <GStoreOrderDetailDigitalPaymentBox />
      <GStoreOrderDetailDigitalShippingInfoBox />
      <GStoreOrderDetailBanner />
    </Stack>
  ) : (
    <NotFound />
  );
}
