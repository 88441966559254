import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';
import GameCardLikeButtonContainer from '../GameCardLikeButton/GameCardLikeButtonContainer';
import { ReviewGauge } from '../../../components/ReviewGauge';

interface Props extends GameCardProps {
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  fillHeight?: boolean;
}

export default function GameCardMediumLowerArea({
  state,
  studio_name,
  title,
  started_at,
  ended_at,
  isHover = false,
  id,
  like_check,
  likeButton,
  isPrivateShow,
  onLikeClicked,
  progress_rate,
  progressed_review,
  required_review,
  fillHeight,
}: Props) {
  const hasGauge = typeof required_review === 'number' && typeof progressed_review === 'number';

  return (
    <Box
      p="20px"
      sx={{
        backgroundColor: isHover && !isPrivateShow ? 'gray.70' : 'gray.80',
        borderRadius: '0px 0px 5px 5px',
        position: 'relative',
        minHeight: fillHeight ? '159px' : undefined,
        boxSizing: 'border-box',
      }}
    >
      <Typography
        color="text.primary"
        variant="subtitle1"
        noWrap
        sx={{ width: '220px', opacity: isPrivateShow ? 0.3 : '' }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle3"
        color={isHover && !isPrivateShow ? 'text.primary' : 'text.secondary2'}
        noWrap
        sx={{
          marginTop: '6px',
          width: '220px',
          opacity: isPrivateShow ? 0.3 : undefined,
        }}
      >
        {studio_name}
      </Typography>
      {(state === GameStatusType.TESTING ||
        state === GameStatusType.SURVEY ||
        state === GameStatusType.CLOSED) && (
        <Typography
          variant="subtitle3"
          color={isHover && !isPrivateShow ? 'text.primary' : 'text.secondary2'}
          sx={{ marginTop: '6px', opacity: isPrivateShow ? 0.3 : '' }}
        >
          Period: {format(new Date(started_at), 'MMM dd, yyyy')} -{' '}
          {format(new Date(ended_at), 'MMM dd, yyyy')}
        </Typography>
      )}
      {hasGauge && (
        <Box sx={{ marginTop: '28px' }}>
          <ReviewGauge goal={required_review} count={progressed_review} rate={progress_rate} />
        </Box>
      )}
      {likeButton && (
        <Box position="absolute" sx={{ top: 13, right: 11 }}>
          <GameCardLikeButtonContainer
            gameId={id}
            likeCheck={like_check}
            onLikeClicked={onLikeClicked}
          />
        </Box>
      )}
    </Box>
  );
}
