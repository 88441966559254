import { SelectionProps } from '@ground/ui';
import { Box, Divider } from '@mui/material';

import { useSupporterApplyData } from '../../SupporterApplyProvider';
import FormCheckboxGroup from '../question/form/FormCheckboxGroup';
import SupporterApplyNewQuestionChoice from '../question/genre/SupporterApplyNewQuestionChoice';
import SupporterQuestionTitle from '../question/SupporterApplyQuestionTitle';

// 1섹션
export default function FavoriteSection() {
  const { supporterQuestionData } = useSupporterApplyData();

  // 장르 라디오 - required
  const genreRadioData = supporterQuestionData?.filter(
    (item) => item.question_section_id === 10 && item.question_type === 'RADIO'
  );

  // 장르 체크박스 sub genre - required 아님
  const subGenre = supporterQuestionData?.filter(
    (item) =>
      item.question_section_id === 10 && item.question_type === 'CHECKBOX' && item.question_id === 9
  );

  const subGenreOption: SelectionProps[] =
    subGenre?.[0].question_selection.map((selection, index) => ({
      id: selection.question_id + index,
      order: selection.question_seq,
      label: selection.question_content,
    })) ?? [];

  // 장르 체크박스 type genre - required 아님
  const typeGenreData = supporterQuestionData?.filter(
    (item) =>
      item.question_section_id === 10 &&
      item.question_type === 'CHECKBOX' &&
      item.question_id === 10
  );

  const typeGenreOption: SelectionProps[] =
    typeGenreData?.[0].question_selection.map((selection, index) => ({
      id: selection.question_id + index,
      order: selection.question_seq,
      label: selection.question_content,
    })) ?? [];

  return (
    <Box p="40px" sx={{ backgroundColor: 'gray.90' }} mb="20px">
      <SupporterQuestionTitle
        question="ハンドジャンル"
        subtitle="各ジャンルにどれだけ興味があるかを1~5の尺度で評価します"
        required
        mb="20px"
      />
      <Box p="32px 40px" sx={{ backgroundColor: 'gray.100' }} mb="20px">
        {genreRadioData?.map((question, index) => (
          <Box key={question.question_id}>
            <SupporterApplyNewQuestionChoice
              disabled={false}
              index={index}
              {...question}
              required={Boolean(question.required)}
            />

            {genreRadioData.length - 1 !== index && (
              <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
            )}
          </Box>
        ))}
      </Box>

      <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />

      {/* 9번쨰꺼 */}
      <SupporterQuestionTitle
        question="サブジャンル"
        subtitle="ご希望のサブジャンルをお選びください。（お好きなだけお選びいただけます。）"
        mb="20px"
      />
      <Box p="32px 0 32px 40px" sx={{ backgroundColor: 'gray.100' }}>
        {subGenre?.map((question, index) => (
          <Box
            key={question.question_id}
            sx={{
              backgroundColor: 'gray.100',
            }}
          >
            <FormCheckboxGroup
              minSelect={1}
              name={`supporter_question_vos.8.question_answers`}
              options={subGenreOption}
              required={!!question.required}
              sx={{
                width: '150px',
                marginBottom: '20px',
                marginRight: '40px',
              }}
              index={8}
            />
          </Box>
        ))}
      </Box>

      <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />

      <Box>
        {typeGenreData?.map((question, index) => (
          <Box key={question.question_id}>
            <SupporterQuestionTitle
              question="タイプします"
              subtitle="普段プレイしているゲームの種類または好きなゲームを選択します (好きなだけ選択できます)"
              mb="20px"
            />
            <FormCheckboxGroup
              minSelect={1}
              name={`supporter_question_vos.9.question_answers`}
              options={typeGenreOption}
              required={!!question.required}
              index={9}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
