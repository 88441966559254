import { Box, Typography, Divider } from '@mui/material';
import ordinal from 'ordinal';

import { useGetDetailData } from 'views/detail/DetailProvider';

export default function DetailTestInfo() {
  const { data, files } = useGetDetailData();

  return (
    <Box display="flex" alignItems="center">
      {files && files.length > 0 && (
        <Box
          sx={(theme) => ({
            display: 'inline-flex',
            fontSize: 10,
            padding: '0 6px',
            lineHeight: '14px',
            color: 'black',
            backgroundColor: theme.palette.base.dimWhite,
            borderRadius: '3px',
          })}
        >
          {files[0].platform}
        </Box>
      )}
      <Typography variant="body3" color="text.secondary" marginLeft="8px">
        {ordinal(data?.round || 1)} Test
      </Typography>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={(theme) => ({
          borderColor: theme.palette.opacity.white[10],
          height: '10px',
          margin: 'auto 12px',
        })}
      />
      <Typography variant="body3" color="text.secondary">
        VERSION {data?.version ?? ''}
      </Typography>
    </Box>
  );
}
