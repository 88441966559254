import { useSelector } from 'react-redux';

import useFetch from 'api/useFetch';
import useSWRFetch, { useSWRInfiniteFetch } from 'api/useSWRFetch';
import { NewsProps } from 'components/news/NewsType';
import { iRootState } from 'store';
import { VideoClipDetailData, VideoClipDetailType } from 'types/ClipsTypes';
import {
  GameDetailProps,
  GameInfo,
  LauncherUserSummaryType,
  LineBannerDataType,
  PlayTimeDataType,
  StudioType,
  SystemRequirementsType,
} from 'types/GameDetailTypes';
import { MediaExposureItem } from 'views/detail/gameinfo/GameInfoMediaExposureItem';

export function useGameSupportLanguageListAPI(gameId?: string) {
  const url = !gameId ? null : `games/${gameId}/language`;

  return useSWRFetch<string[]>({ key: url });
}

export function useGameDetailBasicInfoAPI(gameId: number | string) {
  const url = `v2/games/${gameId}/detail `;

  return useSWRFetch<GameDetailProps>({ key: url, useToken: true });
}

// 클립팝업용 api
// TODO: 중복 API 확인
export function useGameClipsDetailBasicInfoAPI(gameId: number | string) {
  const url = `games/${gameId}/detail?`;

  return useSWRFetch<GameDetailProps>({ key: url, useToken: true });
}

export function useGameDetailInfoAPI(gameId?: string, language?: string | null) {
  const url = gameId && language ? `games/${gameId}/info/${language}` : null;

  return useSWRFetch<GameInfo[]>({ key: url, useToken: true });
}

export function useGameDetailMediaExposureAPI(gameId: number | string) {
  return useSWRInfiniteFetch<MediaExposureItem[]>({
    getKey: (pageIndex, previousPageData) => {
      // 리스트의 끝이면 return null
      if (previousPageData?.data && !previousPageData.data.length) return null;

      return `games/${gameId}/media?pageNo=${pageIndex}&pageSize=6`;
    },
  });
}

export function useGameDetailNewsAPI(gameId: number | string) {
  return useSWRInfiniteFetch<NewsProps[]>({
    getKey: (pageIndex, previousPageData) => {
      // 리스트의 끝이면 return null
      if (previousPageData?.data && !previousPageData.data.length) return null;

      return `games/${gameId}/news?pageNo=${pageIndex}&pageSize=5`;
    },
  });
}

export function useGameDetailVideoClipAPI(gameId: number | string, query: string) {
  const url = `games/${gameId}/videos?${query}`;

  return useSWRFetch<VideoClipDetailType[]>({ key: url, useToken: true });
}

export function useGameDetailVideoClipDetailAPI(
  videoId: number | string | (string | number)[] | null
) {
  const url = `videos/${videoId}`;

  return useSWRFetch<VideoClipDetailData>({
    key: typeof videoId === 'string' || typeof videoId === 'number' ? url : null,
    useToken: true,
  });
}

export function useGameDetailVideoClipDetailPlayerAPI(videoId?: number) {
  return useFetch(`videos/${videoId && videoId}/view`, { method: 'PATCH' }, true);
}

export function useGameInfoStudioInfoAPI(studioId: number) {
  return useSWRFetch<StudioType>({ key: `studios/${studioId}` });
}

export function useGameInfoSystemRequirementsAPI(gameId?: string) {
  return useSWRFetch<SystemRequirementsType[]>({ key: gameId ? `games/${gameId}/system` : null });
}

export function useDetailLineBannerAPI() {
  return useSWRFetch<LineBannerDataType[]>({ key: 'banner/site/4/list/activated' });
}

// 유저의 런처에서 수집한 게임플레이 통계 API
export function useLauncherUserSummaryAPI(gameId?: number | string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  return useSWRFetch<LauncherUserSummaryType | null>({
    key:
      gameId && isLogin
        ? `https://api-statistics.gameround.co/launcher/game/${gameId}/user/summary`
        : null,
    swrConfig: { revalidateOnFocus: true },
    useToken: true,
  });
}

// 플레이타임 정보 가져오기
export function useGamePlayTimeInfoAPI(gameId: string) {
  return useSWRFetch<PlayTimeDataType>({
    key: `games/${gameId}/playtime`,
    useToken: true,
  });
}
