import { Route, Routes } from 'react-router-dom';

import DigitalGamesDetail from './detail/digital-games/DigitalGamesDetail';
import OrderPageDigitalGames from './order/digital-games/OrderPageDigitalGames';

export default function DigitalGameRoute() {
  return (
    <Routes>
      <Route path=":id" element={<DigitalGamesDetail />} />
      <Route path=":id/order" element={<OrderPageDigitalGames />} />
    </Routes>
  );
}
