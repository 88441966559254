import axios from 'axios';

import { store } from 'store';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.validateStatus = (status) => status < 501;

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG === 'ON') {
      console.log('request: ', config);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG === 'ON') {
      console.log('response: ', response);
    }
    // 토큰이 변조되었거나 만료된 경우 status 401, 블록처리된 유저인 경우 status 462
    if (response.status === 401 || response.status === 462) {
      store.dispatch.login.logout();
      // alert('The login token has expired.');
      // window.location.reload();
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
