import { Typography } from '@mui/material';

export default function LauncherReinstallText() {
  return (
    <>
      <Typography color="primary" variant="subtitle1">
        Please re-install your launcher if you are using the 1.5.10 or older versions of the
        launcher
      </Typography>
      <Typography color="text.secondary" variant="body2" mt="20px">
        If your launcher is 1.5.10 or later, just sign in and it will automatically update the
        launcher. If you are not able to log into the G.Round launcher, you are using an outdated
        version of it. Please re-install the launcher by uninstalling the old launcher first.
      </Typography>
    </>
  );
}
