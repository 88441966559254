// 상품의 판매 상태
export const OSaleStatus = {
  ENABLE: 'Enable',
  UPCOMING: 'Upcoming',
  DISABLE: 'Disable',
  SOLD_OUT: 'Sold Out',
} as const;
export type SaleStatusKey = keyof typeof OSaleStatus;
export type SaleStatus = (typeof OSaleStatus)[SaleStatusKey];

export const OProductStatus = {
  ENABLED: 'ENABLED',
  UPCOMING: 'UPCOMING',
  DISABLED: 'DISABLED',
  SOLD_OUT: 'SOLD_OUT',
} as const;
export type ProductStatusStatusKey = keyof typeof OProductStatus;
export type ProductStatusStatus = (typeof OProductStatus)[ProductStatusStatusKey];

// Merchandise 상품 정의
export namespace IStoreMerchandise {
  // 디지털 게임 상품 목록 조회
  export namespace GetShippingCountryList {
    export interface ShippingCountry {
      country_code: string; // 국가 코드
      country_name: string; // 국가명
      id_card_item: number | null; // 국가 추가 입력 아이템 아이디
      id_card_title: string | null; // 국가 추가 입력 아이템 타이틀
      id_card_required: boolean; // 국가 추가 입력 필수 여부 (true : 필수, false : 옵션)
    }

    export type Response = ShippingCountry[];
  }

  export namespace GetMerchandiseDetail {
    export interface MerchandiseData {
      sale_product_id: string;
      name: string;
      point: number;
      point_no_display: boolean;
      main_media_url: string;
      summary: string;
      description: string;
      editor_content: string | null;
      status: ProductStatusStatusKey;
      avail_count: number;
      top_contents: TopContent[];
      detail_contents: DetailContent[];
      countries: Country[];
      promotion: Promotion;
    }

    export interface CommonContent {
      media_type: 'VIDEO' | 'IMAGE';
      url: string;
      seq: number;
    }

    export interface TopContent extends CommonContent {
      type: 'TOP';
    }

    export interface DetailContent extends CommonContent {
      type: 'DETAIL';
    }

    export interface Country {
      country_code: string;
      country_name: string;
      id_card_item?: number;
      id_card_title?: string;
      id_card_required: boolean;
    }

    export interface Promotion {
      id: number;
      title: string;
      started_at: string;
      ended_at: string;
      delivery_at: string;
      status: string;
    }

    export type Response = MerchandiseData;
  }
}

// Digital Game 상품 정의
export namespace IStoreDigitalGame {
  // 상품에서 사용하는 미디어 컨텐츠
  export interface MediaContent {
    id: number;
    type: 'TOP' | 'DETAIL';
    media_type: 'IMAGE' | 'VIDEO';
    url: string;
    seq: number;
  }

  // 디지털 게임 상품 목록 조회
  export namespace GetProductList {
    export interface Product {
      id: number; // 상품 ID
      title: string; // 상품 이름
      thumbnail_url: string;
      status: SaleStatusKey;
      display: boolean; // 스토어에서 상품 노출 여부
      price: number; // 상품 가격
      seq: number; // 상품 전시 순서
    }

    export type Response = Product[];
  }

  // 디지털 게임 상품 조회
  export namespace GetProduct {
    export interface Response {
      id: number; // 상품 ID
      title: string; // 상품 이름
      price: number; // 상품 가격
      thumbnail_url: string; // 상품 썸네일 이미지. 이미지를 어떻게 삽입할지 논의
      overview: string; // 상품의 간략한 설명
      note?: string; // 구매자 전달 사항
      detail: string; // 상품 상세 설명
      status: SaleStatusKey; // 상품의 판매 상태
      available_stock: number; // 재고 개수
      top_contents: MediaContent[]; // 상품의 상단 컨텐츠 리스트
      detail_contents: MediaContent[]; // 상품의 상세 설명 컨텐츠 리스트
      editor_content: string | null;
    }
  }
}
