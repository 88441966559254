import { GIconButton, GroundLogo } from '@ground/ui';
import { Button, IconButton, Link, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, iRootState } from 'store';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

import FooterScrollToTopIcon from 'images/footer/footer_top_btn_hover.svg';

const FooterContainer = styled('footer')(({ theme }) => ({
  width: '100vw',
  backgroundColor: theme.palette.gray[80],
  padding: '40px 20px',
  position: 'relative',
}));

const TextBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: 10,
  marginTop: 28,
}));

const MediaBox = styled('div')(() => ({
  display: 'flex',
  gap: 10,
  marginTop: 32,
}));

const MediaCircle = styled(Link)({
  width: 42,
  height: 42,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ViewPCButton = styled(Button)({
  marginTop: 32,
});

const ScrollTopBtn = styled(IconButton)({
  position: 'absolute',
  right: 20,
  top: 40,
  opacity: 0.5,
  width: '40px',
  height: '40px',
});

const handleClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

function Footer() {
  const { gxcTag } = useLog();
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const dispatch = useDispatch<Dispatch>();

  const onLogoutHandler = () => {
    dispatch.login.logout();
    eventTracker('footer_mobile_logout');
    gxcTag('click', { area: 'footer', ui: 'signout' });
  };

  return (
    <FooterContainer>
      <GroundLogo colorType="dark" width={91} height={14} />
      <ScrollTopBtn
        onClick={() => {
          gxcTag('click', { area: 'footer', ui: 'go_to_top' });
          handleClick();
        }}
      >
        <img src={FooterScrollToTopIcon} alt="scroll to top" />
      </ScrollTopBtn>
      <TextBox>
        <Typography variant="small2" color="text.secondary2">
          For Developer
        </Typography>
        <Link
          href="https://developer.gameround.co/"
          target="_blank"
          variant="body2"
          color="text.primary"
          underline="none"
          onClick={() => gxcTag('click', { area: 'footer', ui: 'b2b_intro' })}
        >
          G.Round Intro
        </Link>
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSe0lqFCXEBhRRgpAPHGlP_qGXXsOOMdcZV_ou8DZG53ojCEoA/viewform"
          target="_blank"
          variant="body2"
          color="text.primary"
          underline="none"
          onClick={() => gxcTag('click', { area: 'footer', ui: 'b2b_submit_a_game' })}
        >
          Submit a Game
        </Link>
      </TextBox>
      <TextBox>
        <Typography variant="small2" color="text.secondary2">
          Information
        </Typography>
        <Link
          href="/privacyPolicy.pdf"
          target="_blank"
          variant="body2"
          color="text.primary"
          underline="none"
          onClick={() => gxcTag('click', { area: 'footer', ui: 'privacy_policy' })}
        >
          Privacy Policy
        </Link>
        <Link
          href="/userTerms.pdf"
          target="_blank"
          variant="body2"
          color="text.primary"
          underline="none"
          onClick={() => gxcTag('click', { area: 'footer', ui: 'terms_of_use' })}
        >
          Terms & Conditions
        </Link>
      </TextBox>

      {isLogin && (
        <Typography
          onClick={onLogoutHandler}
          typography="body2"
          color="text.secondary2"
          mt="32px"
          sx={{ textDecoration: 'underline' }}
        >
          Sign out
        </Typography>
      )}

      <ViewPCButton
        variant="ghost"
        fullWidth
        onClick={() => {
          gxcTag('click', { area: 'footer', ui: 'view_pcweb' });
          dispatch.mobile.transformDesktopView();
        }}
      >
        View PC Website
      </ViewPCButton>

      <MediaBox>
        <MediaCircle
          href="https://www.youtube.com/channel/UCs3X1qAr8hXkBnn0v8lT9DA"
          target="_blank"
          onClick={() => {
            gxcTag('click', { area: 'footer', ui: 'youtube' });
          }}
        >
          <GIconButton
            iconName="youtube"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </MediaCircle>
        <MediaCircle
          href="https://twitter.com/gameroundco"
          target="_blank"
          onClick={() => {
            gxcTag('click', { area: 'footer', ui: 'twitter' });
          }}
        >
          <GIconButton
            iconName="x"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </MediaCircle>
        <MediaCircle
          href="https://www.facebook.com/gameround/"
          target="_blank"
          onClick={() => {
            gxcTag('click', { area: 'footer', ui: 'facebook' });
          }}
        >
          <GIconButton
            iconName="facebook"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </MediaCircle>
      </MediaBox>

      <TextBox>
        <Typography variant="body3" color="text.secondary2">
          In collaboration with: Global Top Round | Round Ventures
        </Typography>
        <Typography variant="body3" color="text.secondary2">
          ⓒ G.Round. All Rights Reserved.
        </Typography>
      </TextBox>
    </FooterContainer>
  );
}

export default Footer;
