import { SuppoterQuestionData } from 'api/supporter/useSuppoterTypes';

export const questions: SuppoterQuestionData[] = [
  {
    question_id: 1,
    question_section_id: 10,
    question_seq: 10,
    question_context: 'アクション',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 1,
        question_id: 1,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 2,
        question_id: 1,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 3,
        question_id: 1,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 4,
        question_id: 1,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 5,
        question_id: 1,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 2,
    question_section_id: 10,
    question_seq: 20,
    question_context: '冒険です',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 6,
        question_id: 2,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 7,
        question_id: 2,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 8,
        question_id: 2,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 9,
        question_id: 2,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 10,
        question_id: 2,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 3,
    question_section_id: 10,
    question_seq: 30,
    question_context: 'カジュアルです',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 11,
        question_id: 3,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 12,
        question_id: 3,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 13,
        question_id: 3,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 14,
        question_id: 3,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 15,
        question_id: 3,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 4,
    question_section_id: 10,
    question_seq: 40,
    question_context: 'レーシング',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 16,
        question_id: 4,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 17,
        question_id: 4,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 18,
        question_id: 4,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 19,
        question_id: 4,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 20,
        question_id: 4,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 5,
    question_section_id: 10,
    question_seq: 50,
    question_context: 'RPG',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 21,
        question_id: 5,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 22,
        question_id: 5,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 23,
        question_id: 5,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 24,
        question_id: 5,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 25,
        question_id: 5,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 6,
    question_section_id: 10,
    question_seq: 60,
    question_context: 'シミュレーションです',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 26,
        question_id: 6,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 27,
        question_id: 6,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 28,
        question_id: 6,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 29,
        question_id: 6,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 30,
        question_id: 6,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 7,
    question_section_id: 10,
    question_seq: 70,
    question_context: 'スポーツです',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 31,
        question_id: 7,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 32,
        question_id: 7,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 33,
        question_id: 7,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 34,
        question_id: 7,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 35,
        question_id: 7,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 8,
    question_section_id: 10,
    question_seq: 80,
    question_context: '戦略',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 36,
        question_id: 8,
        question_seq: 10,
        question_content: '1',
      },
      {
        selection_id: 37,
        question_id: 8,
        question_seq: 20,
        question_content: '2',
      },
      {
        selection_id: 38,
        question_id: 8,
        question_seq: 30,
        question_content: '3',
      },
      {
        selection_id: 39,
        question_id: 8,
        question_seq: 40,
        question_content: '4',
      },
      {
        selection_id: 40,
        question_id: 8,
        question_seq: 50,
        question_content: '5',
      },
    ],
  },
  {
    question_id: 9,
    question_section_id: 10,
    question_seq: 90,
    question_context: 'Sub Genre',
    question_type: 'CHECKBOX',
    required: 0,
    required_option: '',
    detail_context:
      'Pick the Sub Genres you are interested in. (You can select as many as you want.)',
    detail_url: '',
    question_selection: [
      {
        selection_id: 41,
        question_id: 9,
        question_seq: 10,
        question_content: '4X',
      },
      {
        selection_id: 42,
        question_id: 9,
        question_seq: 20,
        question_content: 'Battle Royale',
      },
      {
        selection_id: 43,
        question_id: 9,
        question_seq: 30,
        question_content: 'Bullet Hell',
      },
      {
        selection_id: 44,
        question_id: 9,
        question_seq: 40,
        question_content: 'Card Game',
      },
      {
        selection_id: 45,
        question_id: 9,
        question_seq: 50,
        question_content: 'City Builder',
      },
      {
        selection_id: 46,
        question_id: 9,
        question_seq: 60,
        question_content: 'Educational',
      },
      {
        selection_id: 47,
        question_id: 9,
        question_seq: 70,
        question_content: 'Farming Sim',
      },
      {
        selection_id: 48,
        question_id: 9,
        question_seq: 80,
        question_content: 'Fighting',
      },
      {
        selection_id: 49,
        question_id: 9,
        question_seq: 90,
        question_content: 'Hack & Slash',
      },
      {
        selection_id: 50,
        question_id: 9,
        question_seq: 100,
        question_content: 'Horror',
      },
      {
        selection_id: 51,
        question_id: 9,
        question_seq: 110,
        question_content: 'Idler',
      },
      {
        selection_id: 52,
        question_id: 9,
        question_seq: 120,
        question_content: 'Metroidvania',
      },
      {
        selection_id: 53,
        question_id: 9,
        question_seq: 130,
        question_content: 'MOBA',
      },
      {
        selection_id: 54,
        question_id: 9,
        question_seq: 140,
        question_content: 'Mystery',
      },
      {
        selection_id: 55,
        question_id: 9,
        question_seq: 150,
        question_content: 'Open World',
      },
      {
        selection_id: 56,
        question_id: 9,
        question_seq: 160,
        question_content: 'Party Game',
      },
      {
        selection_id: 57,
        question_id: 9,
        question_seq: 170,
        question_content: 'Pixel Graphics',
      },
      {
        selection_id: 58,
        question_id: 9,
        question_seq: 180,
        question_content: 'Partformer',
      },
      {
        selection_id: 59,
        question_id: 9,
        question_seq: 190,
        question_content: 'Puzzle',
      },
      {
        selection_id: 60,
        question_id: 9,
        question_seq: 200,
        question_content: 'Rhythm',
      },
      {
        selection_id: 61,
        question_id: 9,
        question_seq: 210,
        question_content: 'Rogue-like',
      },
      {
        selection_id: 62,
        question_id: 9,
        question_seq: 220,
        question_content: 'RTS',
      },
      {
        selection_id: 63,
        question_id: 9,
        question_seq: 230,
        question_content: 'Sandbox',
      },
      {
        selection_id: 64,
        question_id: 9,
        question_seq: 240,
        question_content: 'Shooter',
      },
      {
        selection_id: 65,
        question_id: 9,
        question_seq: 250,
        question_content: 'Souls-like',
      },
      {
        selection_id: 66,
        question_id: 9,
        question_seq: 260,
        question_content: 'Stealth',
      },
      {
        selection_id: 67,
        question_id: 9,
        question_seq: 270,
        question_content: 'Survival',
      },
      {
        selection_id: 68,
        question_id: 9,
        question_seq: 280,
        question_content: 'Tabletop',
      },
      {
        selection_id: 69,
        question_id: 9,
        question_seq: 290,
        question_content: 'Tactical',
      },
      {
        selection_id: 70,
        question_id: 9,
        question_seq: 300,
        question_content: 'Tower Defense',
      },
      {
        selection_id: 71,
        question_id: 9,
        question_seq: 310,
        question_content: 'Turn-Based',
      },
      {
        selection_id: 72,
        question_id: 9,
        question_seq: 320,
        question_content: 'Visual Novel',
      },
    ],
  },
  {
    question_id: 10,
    question_section_id: 10,
    question_seq: 100,
    question_context: 'Type',
    question_type: 'CHECKBOX',
    required: 0,
    required_option: '',
    detail_context:
      'Pick the Type of games you are interested in. (You can select as many as you want.)',
    detail_url: '',
    question_selection: [
      {
        selection_id: 73,
        question_id: 10,
        question_seq: 10,
        question_content: 'Co-Op',
      },
      {
        selection_id: 74,
        question_id: 10,
        question_seq: 20,
        question_content: '非常にマルチプレイヤーです',
      },
      {
        selection_id: 75,
        question_id: 10,
        question_seq: 30,
        question_content: 'マルチプレイヤーです',
      },
      {
        selection_id: 130,
        question_id: 10,
        question_seq: 40,
        question_content: 'PvE',
      },
      {
        selection_id: 131,
        question_id: 10,
        question_seq: 50,
        question_content: 'PvP',
      },
      {
        selection_id: 132,
        question_id: 10,
        question_seq: 60,
        question_content: 'シングルプレイヤーです',
      },
    ],
  },
  {
    question_id: 16,
    question_section_id: 20,
    question_seq: 10,
    question_context: '2-1. どのプラットフォームでテストできますか?',
    question_type: 'CHECKBOX',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 76,
        question_id: 16,
        question_seq: 10,
        question_content: 'PC',
      },
      {
        selection_id: 77,
        question_id: 16,
        question_seq: 20,
        question_content: 'モバイル',
      },
    ],
  },
  {
    question_id: 17,
    question_section_id: 20,
    question_seq: 20,
    question_context: '2-2. ゲーム周辺機器',
    question_type: 'CHECKBOX',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 78,
        question_id: 17,
        question_seq: 10,
        question_content: 'マウスとキーボード',
      },
      {
        selection_id: 79,
        question_id: 17,
        question_seq: 20,
        question_content: 'コントローラー',
      },
      {
        selection_id: 129,
        question_id: 17,
        question_seq: 21,
        question_content: 'PC VR',
      },
      {
        selection_id: 80,
        question_id: 17,
        question_seq: 30,
        question_content: 'その他',
      },
    ],
  },
  {
    question_id: 18,
    question_section_id: 20,
    question_seq: 30,
    question_context: '2-3. ご自宅のダウンロード速度はどのくらいですか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: 'https://www.speedtest.net',
    question_selection: [
      {
        selection_id: 81,
        question_id: 18,
        question_seq: 10,
        question_content: '10Mbps 未満',
      },
      {
        selection_id: 82,
        question_id: 18,
        question_seq: 20,
        question_content: '10 - 100Mbps',
      },
      {
        selection_id: 83,
        question_id: 18,
        question_seq: 30,
        question_content: '100 - 500Mbps',
      },
      {
        selection_id: 84,
        question_id: 18,
        question_seq: 40,
        question_content: '500Mbps 以上',
      },
    ],
  },
  {
    question_id: 19,
    question_section_id: 20,
    question_seq: 40,
    question_context: '2-4. ご自宅のアップロード速度はどのくらいですか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: 'https://www.speedtest.net',
    question_selection: [
      {
        selection_id: 85,
        question_id: 19,
        question_seq: 10,
        question_content: '10Mbps 未満',
      },
      {
        selection_id: 86,
        question_id: 19,
        question_seq: 20,
        question_content: '10 - 100Mbps',
      },
      {
        selection_id: 87,
        question_id: 19,
        question_seq: 30,
        question_content: '100 - 500Mbps',
      },
      {
        selection_id: 88,
        question_id: 19,
        question_seq: 40,
        question_content: '500Mbps 以上',
      },
    ],
  },
  {
    //토르여기 detail_context,detail_url 확인해볼것
    question_id: 20,
    question_section_id: 20,
    question_seq: 50,
    question_context: '2-5. PC の仕様',
    question_type: 'TEXT',
    required: 0,
    required_option: 'PC',
    detail_context:
      '*Upload DXdiag (for PC) (Please download DXdiag.txt file. You could check this guide to get DxDiag.txt.)',
    detail_url:
      'https://support.microsoft.com/en-us/windows/open-and-run-dxdiag-exe-dad7792c-2ad5-f6cd-5a37-bf92228dfd85',
    question_selection: [],
  },
  {
    question_id: 21,
    question_section_id: 20,
    question_seq: 60,
    question_context: '2-5. PC specifications CPU',
    question_type: 'TEXT',
    required: 0,
    required_option: 'PCDETAIL',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 22,
    question_section_id: 20,
    question_seq: 70,
    question_context: '2-5. PC specifications Memory',
    question_type: 'TEXT',
    required: 0,
    required_option: 'PCDETAIL',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 23,
    question_section_id: 20,
    question_seq: 80,
    question_context: '2-5. PC specifications GPU',
    question_type: 'TEXT',
    required: 0,
    required_option: 'PCDETAIL',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 24,
    question_section_id: 20,
    question_seq: 90,
    question_context: '2-5. PC specifications VRAM',
    question_type: 'TEXT',
    required: 0,
    required_option: 'PCDETAIL',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 25,
    question_section_id: 20,
    question_seq: 100,
    question_context: '2-6. スマートフォンでモバイルゲームを試してみませんか?',
    question_type: 'RADIO',
    required: 0,
    required_option: 'Mobile',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 93,
        question_id: 25,
        question_seq: 10,
        question_content: 'はい',
      },
      {
        selection_id: 94,
        question_id: 25,
        question_seq: 20,
        question_content: 'いいえ',
      },
    ],
  },
  {
    question_id: 26,
    question_section_id: 20,
    question_seq: 110,
    question_context:
      '2-7. スマートフォンやタブレットでゲームをするのに、平均して週に何時間費やしていますか?',
    question_type: 'RADIO',
    required: 0,
    required_option: 'Mobile',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 95,
        question_id: 26,
        question_seq: 10,
        question_content: '1 - 5 (時間)',
      },
      {
        selection_id: 96,
        question_id: 26,
        question_seq: 20,
        question_content: '5 - 10 (時間)',
      },
      {
        selection_id: 97,
        question_id: 26,
        question_seq: 30,
        question_content: '10 - 20 (時間)',
      },
      {
        selection_id: 98,
        question_id: 26,
        question_seq: 40,
        question_content: '20 - 30 (時間)',
      },
      {
        selection_id: 99,
        question_id: 26,
        question_seq: 50,
        question_content: '30 (時間以上)',
      },
    ],
  },
  {
    question_id: 27,
    question_section_id: 20,
    question_seq: 120,
    question_context: '2-8. モバイル デバイスで最もよくプレイするジャンルを選択してください。',
    question_type: 'CHECKBOX',
    required: 0,
    required_option: 'Mobile',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 100,
        question_id: 27,
        question_seq: 10,
        question_content: 'パズル',
      },
      {
        selection_id: 101,
        question_id: 27,
        question_seq: 20,
        question_content: '戦略',
      },
      {
        selection_id: 102,
        question_id: 27,
        question_seq: 30,
        question_content: 'アクション',
      },
      {
        selection_id: 103,
        question_id: 27,
        question_seq: 40,
        question_content: 'アドベンチャー',
      },
      {
        selection_id: 104,
        question_id: 27,
        question_seq: 50,
        question_content: 'ボード',
      },
      {
        selection_id: 105,
        question_id: 27,
        question_seq: 60,
        question_content: 'RPG',
      },
      {
        selection_id: 106,
        question_id: 27,
        question_seq: 70,
        question_content: 'カード',
      },
      {
        selection_id: 107,
        question_id: 27,
        question_seq: 80,
        question_content: 'カジノ',
      },
      {
        selection_id: 108,
        question_id: 27,
        question_seq: 90,
        question_content: 'シミュレーション',
      },
    ],
  },
  {
    question_id: 28,
    question_section_id: 20,
    question_seq: 130,
    question_context:
      '2-9. モバイル デバイスに該当するすべてのオペレーティング システムをマークします。(スマートフォン/タブレット)',
    question_type: 'CHECKBOX',
    required: 0,
    required_option: 'Mobile',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 109,
        question_id: 28,
        question_seq: 10,
        question_content: 'iOS',
      },
      {
        selection_id: 110,
        question_id: 28,
        question_seq: 20,
        question_content: 'Android',
      },
      {
        selection_id: 111,
        question_id: 28,
        question_seq: 30,
        question_content: 'その他',
      },
    ],
  },
  {
    question_id: 29,
    question_section_id: 20,
    question_seq: 140,
    question_context:
      '2-10. モバイル ゲームのテストに参加する際に使用する予定のスマートフォンまたはタブレットのモデルをリストします。',
    question_type: 'TEXT',
    required: 0,
    required_option: 'Mobile',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 30,
    question_section_id: 30,
    question_seq: 10,
    question_context: '3-1. ゲームを始めてからどのくらい経ちますか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 112,
        question_id: 30,
        question_seq: 10,
        question_content: '2 年未満',
      },
      {
        selection_id: 113,
        question_id: 30,
        question_seq: 20,
        question_content: '2～7 年',
      },
      {
        selection_id: 114,
        question_id: 30,
        question_seq: 30,
        question_content: '7 年以上',
      },
    ],
  },
  {
    question_id: 31,
    question_section_id: 30,
    question_seq: 20,
    question_context: '3-2. 年間でゲームにいくら費やしていますか？',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 115,
        question_id: 31,
        question_seq: 10,
        question_content: '20,000 円未満',
      },
      {
        selection_id: 116,
        question_id: 31,
        question_seq: 20,
        question_content: `20,000 円～50,000 円`,
      },
      {
        selection_id: 117,
        question_id: 31,
        question_seq: 30,
        question_content: '50,000 円以上',
      },
    ],
  },
  {
    question_id: 32,
    question_section_id: 30,
    question_seq: 30,
    question_context: '3-3. 1 週間あたりの実際の平均プレイ時間はどのくらいですか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 118,
        question_id: 32,
        question_seq: 10,
        question_content: '1 - 5 (時間)',
      },
      {
        selection_id: 119,
        question_id: 32,
        question_seq: 20,
        question_content: '5 - 10 (時間)',
      },
      {
        selection_id: 120,
        question_id: 32,
        question_seq: 30,
        question_content: '10 - 20 (時間)',
      },
      {
        selection_id: 121,
        question_id: 32,
        question_seq: 40,
        question_content: '20 - 30 (時間)',
      },
      {
        selection_id: 122,
        question_id: 32,
        question_seq: 50,
        question_content: '30 (時間以上)',
      },
    ],
  },
  {
    question_id: 33,
    question_section_id: 30,
    question_seq: 40,
    question_context: '3-4. どのくらいの頻度でゲームをプレイしますか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 123,
        question_id: 33,
        question_seq: 10,
        question_content: '週に 1 回未満',
      },
      {
        selection_id: 124,
        question_id: 33,
        question_seq: 20,
        question_content: '週に 1 回',
      },
      {
        selection_id: 125,
        question_id: 33,
        question_seq: 30,
        question_content: '週に数回',
      },
      {
        selection_id: 126,
        question_id: 33,
        question_seq: 40,
        question_content: '毎日',
      },
    ],
  },
  {
    question_id: 34,
    question_section_id: 30,
    question_seq: 50,
    question_context: '3-5. ゲームのレビュー経験はありますか?',
    question_type: 'RADIO',
    required: 1,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [
      {
        selection_id: 127,
        question_id: 34,
        question_seq: 10,
        question_content: 'はい',
      },
      {
        selection_id: 128,
        question_id: 34,
        question_seq: 20,
        question_content: 'いいえ',
      },
    ],
  },
  {
    question_id: 35,
    question_section_id: 30,
    question_seq: 60,
    question_context:
      '3-6. 過去にレビューした例を教えてください。スクリーンショットへのリンク、Steam、G.Round、その他のプラットフォームのレビューへのリンクでも構いません。',
    question_type: 'TEXT',
    required: 0,
    required_option: '30',
    detail_context: '',
    detail_url: 'https://gameround.co/blog/156',
    question_selection: [],
  },
  {
    question_id: 36,
    question_section_id: 40,
    question_seq: 10,
    question_context:
      '4-1. 国籍を選択してください。これはプロフィールに記載されている「居住国」とは異なる場合があります。',
    question_type: 'DROPBOX',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 37,
    question_section_id: 40,
    question_seq: 20,
    question_context: '4-2. サポーターズ プログラムを紹介してくれたのは誰ですか?',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 38,
    question_section_id: 40,
    question_seq: 30,
    question_context:
      '4-3. アクセシビリティ要件はありますか? (要件を記載しても、選出される可能性は変わりません)',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 39,
    question_section_id: 40,
    question_seq: 40,
    question_context:
      '4-4. あなたは大学、短大、または専門学校の学生ですか? そうであれば、学校名を記入してください。',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 40,
    question_section_id: 40,
    question_seq: 50,
    question_context: '4-5. はいの場合、専攻/主な研究テーマは何ですか?',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 41,
    question_section_id: 40,
    question_seq: 60,
    question_context:
      '4-6. 将来ゲーム業界で働きたいと考えていますか? もしそうなら、詳しく教えてください。',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 42,
    question_section_id: 40,
    question_seq: 70,
    question_context: '4-7. Instagram プロフィールリンク',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 43,
    question_section_id: 40,
    question_seq: 80,
    question_context: '4-8. Twitter プロフィールリンク',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 44,
    question_section_id: 40,
    question_seq: 90,
    question_context: '4-9. TikTok プロフィールリンク',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 45,
    question_section_id: 40,
    question_seq: 100,
    question_context: '4-10. Twitch プロフィールリンク',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
  {
    question_id: 46,
    question_section_id: 40,
    question_seq: 110,
    question_context: '4-11. YouTube プロフィール リンク',
    question_type: 'TEXT',
    required: 0,
    required_option: '',
    detail_context: '',
    detail_url: '',
    question_selection: [],
  },
];
