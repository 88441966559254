import { Box, Typography } from '@mui/material';

import { useGetDetailData } from 'views/detail/DetailProvider';
import DiscordBanner from 'views/detail/banner/discord/DiscordBanner';

export default function GameInfoDiscordBanner() {
  const { data } = useGetDetailData();
  return (
    <>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mt="120px" mb="38px">
        <Typography color="text.primary" variant="h5">
          Join the Discord community
        </Typography>
        <Typography color="text.secondary" variant="body3">
          Click the banner to join the conversation on Discord.
        </Typography>
      </Box>
      <DiscordBanner discord_channel={data?.discord_channel} type="gameinfo" />
    </>
  );
}
