import { FormFieldArray, FormHiddenInput, FormInput } from '@ground/ui';

interface MajorField {
  major_id: number | null;
  major_name: string;
}

interface Props {
  minCount?: number;
}

export default function MajorForm({ minCount = 1 }: Props) {
  return (
    <FormFieldArray<MajorField>
      label="Major"
      fieldName="major"
      initialValue={{ major_id: null, major_name: '' }}
      minCount={minCount}
      maxCount={5}
    >
      {({ name }) => (
        <>
          <FormHiddenInput name={`${name}.major_id`} />
          <FormInput
            name={`${name}.major_name`}
            fullWidth
            withBackground
            placeholder="Enter Major"
            rules={{ required: true, maxLength: { value: 50, message: 'max 50 characters' } }}
          />
        </>
      )}
    </FormFieldArray>
  );
}
