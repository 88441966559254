import { GIcon } from '@ground/ui';
import { styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface NaviLinkProps {
  text: string;
  to?: string;
  onClick?: () => void;
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
  gap: '4px',
  marginTop: '16px',
});

export default function NaviLink({ text, to, onClick }: NaviLinkProps) {
  return (
    <StyledLink to={to || ''} onClick={onClick}>
      <Typography color="blue.primary" variant="subtitle3">
        {text}
      </Typography>
      <GIcon iconName="arrow_right" iconSize={16} iconColor="primary.main" />
    </StyledLink>
  );
}
