import { numberWithCommas } from '@ground/tools';
import { Box, Typography } from '@mui/material';

import { useGetGPointSummary } from 'api/profile/profileAPI';

export default function GnbGPointText() {
  const { data } = useGetGPointSummary();

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Typography noWrap variant="button">
        G.Points
      </Typography>

      <Typography noWrap variant="button" color="primary">
        {numberWithCommas(data?.data?.available_point ?? 0)} G.P
      </Typography>
    </Box>
  );
}
