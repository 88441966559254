import { baseUrls } from 'api/baseUrls';
import { OrderTypeKey } from './orderTypes';

export const orderQueries = {
  fetchOrderCount() {
    return `${baseUrls.pointV2}/orders/count`;
  },
  fetchOrder(id: string) {
    return `${baseUrls.pointV2}/orders/${id}`;
  },
  fetchOrderList(type: OrderTypeKey) {
    return `${baseUrls.pointV2}/orders?type=${type}`;
  },
  fetchOrderDigitalGame() {
    return `${baseUrls.pointV2}/orders/digital-games`;
  },
};
