import { LoadingWrapper } from '@ground/ui';
import { Box } from '@mui/material';

import { useGetDashboardAPI, useGetGPointSummary } from 'api/profile/profileAPI';
import SummaryItem from './SummaryItem';
import { useGetPointHistoryAPI } from 'api/gstore/gstoreAPI';

export default function SummaryList() {
  const { data, isLoading } = useGetDashboardAPI();
  const { data: pointData, mutate } = useGetGPointSummary();
  const { mutate: refreshPointHistory } = useGetPointHistoryAPI();

  return (
    <LoadingWrapper isLoading={isLoading} sx={{ width: '100%' }}>
      {data?.data ? (
        <Box>
          <Box display="flex" alignItems="center" marginTop="4px" gap="40px">
            <SummaryItem text="TBD" subtitle="Testing Games" />
            <SummaryItem text={data.data.num_review} subtitle="Reviews" />
            <SummaryItem text={data.data.num_global_survey} subtitle="Global Surveys" />
            <SummaryItem
              text={pointData?.data?.available_point || 0}
              subtitle="G.Points"
              hasPurchaseBtn
              onComplete={() => {
                mutate();
                refreshPointHistory();
              }}
            />
          </Box>
        </Box>
      ) : null}
    </LoadingWrapper>
  );
}
