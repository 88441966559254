import { FormInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { useFetch } from 'api';
import { ImageBox } from 'atoms';

export interface CheckcData {
  studio_id: number;
  studio_name: string;
  game_type: 'new' | 'game' | 'submitted' | 'declined' | 'recommended' | 'rejected' | 'upcoming';
  game_title: string;
  game_id: number;
  thumbnail_ext: string;
  thumbnail_folder: string;
  thumbnail_id: number;
  thumbnail_name: string;
  thumbnail_org_name: string;
}

interface DuplicateCheckData {
  data: CheckcData[];
}

interface RecommendGameDuplicateCheckProps {
  isDuplicateChecked: boolean;
  setIsDuplicateChecked: (checkState: boolean) => void;
  setDuplicateCheckStatus: Dispatch<SetStateAction<CheckcData['game_type'] | undefined>>;
}

export default function RecommendGameDuplicateCheck({
  isDuplicateChecked,
  setIsDuplicateChecked,
  setDuplicateCheckStatus,
}: RecommendGameDuplicateCheckProps) {
  const { getValues, watch, formState } = useFormContext();

  const gameTitleValue = watch('submit_game_title');

  const {
    isLoading,
    data: checkData,
    fetch: fetchDuplicateCheck,
  } = useFetch<DuplicateCheckData>(
    `recommendGame/duplicateCheck?submit_game_title=${gameTitleValue}`,
    {},
    true
  );

  //게임 타이틀 체크하는 api 에서 응답값의 game_type 키로 submitStatus 값을 만들어 준다.
  const submitStatus: CheckcData['game_type'] =
    checkData &&
    Array.isArray(checkData.data) &&
    (checkData.data.length === 0 ? 'new' : checkData.data[0].game_type);

  const isOverRecommendState =
    checkData &&
    Array.isArray(checkData.data) &&
    checkData.data.length > 0 &&
    !!checkData.data.find(
      (item) =>
        item.game_type === 'recommended' ||
        item.game_type === 'rejected' ||
        item.game_type === 'upcoming'
    );

  const checkDataItem =
    checkData && Array.isArray(checkData.data) && checkData.data.length > 0 && checkData?.data[0];

  const handleClickDuplicateCheck = async (e: SyntheticEvent) => {
    // 필드가 비어있는 상태에서 체크 버튼을 클릭하는 경우 동작을 막음
    if (
      !formState.dirtyFields['submit_game_title'] ||
      getValues('submit_game_title') === null ||
      getValues('submit_game_title') === ''
    ) {
      return;
    }

    const response = await fetchDuplicateCheck();

    setIsDuplicateChecked(true);

    if (response.status === 200 && response.data.result_code === 'OK') {
      const responseSubmitStatus: CheckcData['game_type'] =
        Array.isArray(response.data.data) &&
        (response.data.data.length === 0 ? 'new' : response.data.data?.[0].game_type);

      //응답값의 game_type 값을 RecommendGameForm 컴포넌트에 전파하여 제출 전 검증에 사용
      setDuplicateCheckStatus(isOverRecommendState ? 'recommended' : responseSubmitStatus);
    } else if (response.status === 200 && response.data.result_code === '400') {
      return;
    } else {
      //
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsDuplicateChecked(false);
    setDuplicateCheckStatus(undefined);
  };

  return (
    <>
      <Box alignItems="flex-end" display="flex" gap="8px">
        <FormInput
          name="submit_game_title"
          label="Game Title"
          placeholder="Enter game title"
          required
          rules={{ required: 'This is a required question' }}
          onKeyDown={(e) => e.key === 'Enter' && handleClickDuplicateCheck(e)}
          inputProps={{ maxLength: 100, onChange: handleInputChange }}
          fullWidth
          withBackground
          sx={{ flex: 1 }}
        />
        <LoadingButton
          onClick={handleClickDuplicateCheck}
          loading={isLoading}
          variant="contained"
          size="medium"
        >
          Duplicate check
        </LoadingButton>
      </Box>
      {(checkData || isDuplicateChecked === false) && (
        <Box>
          <Typography
            variant="body3"
            color={
              isDuplicateChecked === false ||
              submitStatus === undefined ||
              submitStatus === 'game' ||
              submitStatus === 'recommended' ||
              submitStatus === 'rejected' ||
              submitStatus === 'upcoming' ||
              isOverRecommendState
                ? 'error.main'
                : 'success.main'
            }
            sx={{ marginTop: '12px' }}
          >
            {formState.isSubmitted &&
            isDuplicateChecked === false &&
            formState.dirtyFields['submit_game_title'] ? (
              <>Please check for duplicate first</>
            ) : isDuplicateChecked && submitStatus === 'game' && gameTitleValue !== '' ? (
              <>
                {checkDataItem.studio_name}'s {checkDataItem.game_title} has already been on
                G.Round, please recommend a different game. <br />
                If there is an issue or you think that this game hasn't been on our platform before,
                please contact <br />
                <Link
                  href="mailto:support@gameround.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="error.main"
                >
                  support@gameround.co
                </Link>
              </>
            ) : isDuplicateChecked && isOverRecommendState && gameTitleValue !== '' ? (
              <>
                We have reached out to {checkDataItem.studio_name}'s {checkDataItem.game_title},
                please recommend a different game. <br />
                If there is an issue or you think that this is a different game, please contact{' '}
                <Link
                  href="mailto:support@gameround.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="error.main"
                >
                  support@gameround.co
                </Link>
              </>
            ) : (isDuplicateChecked &&
                submitStatus === 'submitted' &&
                !isOverRecommendState &&
                gameTitleValue !== '') ||
              (isDuplicateChecked &&
                submitStatus === 'declined' &&
                !isOverRecommendState &&
                gameTitleValue !== '') ? (
              <>
                This game has been recommended by another user.
                <br />
                You can still get the chance to win G.Points by filling out the form.
              </>
            ) : isDuplicateChecked && submitStatus === 'new' && gameTitleValue !== '' ? (
              'This game is available to recommend.'
            ) : (
              isDuplicateChecked === false && ''
            )}
          </Typography>
          {isDuplicateChecked &&
            submitStatus === 'game' &&
            checkData &&
            Array.isArray(checkData.data) &&
            checkData.data.length > 0 &&
            gameTitleValue !== '' && (
              <Grid container rowGap="24px" columnGap="32px" mt="20px">
                {checkData.data.map((item, index) => (
                  <Grid key={index} item width="306px" display="flex">
                    <ImageBox
                      src={item.thumbnail_location}
                      alt={item.game_title ?? ''}
                      resize
                      resizeWidth={285}
                      resizeHeight={184}
                      width="93px"
                      height="60px"
                      format="webp"
                      sx={{ borderRadius: '5px' }}
                    />
                    <Box ml="16px">
                      <Typography variant="body2" color="text.primary">
                        {item.game_title}
                      </Typography>
                      <Typography variant="body3" color="text.secondary" mt="4px">
                        {item.studio_name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
        </Box>
      )}
    </>
  );
}
