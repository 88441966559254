import { AutoResizeImage } from '@ground/tools';
import { styled } from '@mui/material';

import profileDefaultImg from 'images/icons/profile_default_img.svg';

const StyledAutoResizeImage = styled(AutoResizeImage)({
  borderRadius: '50%',
});

const StyledImage = styled('img')({
  borderRadius: '50%',
});

interface UserAvatarProps {
  width: number;
  src: string;
  alt?: string;
}

export default function UserAvatar({ width, src, alt = '' }: UserAvatarProps) {
  const isGroundImage = !!src?.startsWith('https://gxc-ground');

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    //원본 이미지의 확장자가 jfif 같은 AutoResizeImage 미지원 확장자인 경우
    event.currentTarget.srcset = '';
    event.currentTarget.src = profileDefaultImg;
  };

  return isGroundImage ? (
    <StyledAutoResizeImage
      src={src}
      alt={alt}
      resizeWidth={width}
      resizeHeight={width}
      format="webp"
      onError={imageOnErrorHandler}
    />
  ) : (
    <StyledImage
      src={src ?? profileDefaultImg}
      width={width}
      alt={alt}
      onError={imageOnErrorHandler}
    />
  );
}
