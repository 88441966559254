import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import clsx from 'clsx';

import { useAboutCountryStatisticsAPI } from 'api/about/aboutCountryStatisticsAPI';
import ArtDevAboutStatisticsTooptip from './component/ArtDevAboutStatisticsTooptip';
import ArtDevStatisticsCard from './component/ArtDevStatisticsCard';

import bgWorldMap from 'images/about/aboutDev/worldmap.svg';

const PREFIX = 'ArtDevAboutStatistics';

const classes = {
  cardsWrap: `${PREFIX}-cardsWrap`,
  worldWrap: `${PREFIX}-worldWrap`,
  region: `${PREFIX}-region`,
  regionButton: `${PREFIX}-regionButton`,
};

const Root = styled('div')(({ theme }) => ({
  paddingTop: 80,
  paddingBottom: 60,
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.cardsWrap}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 60,
    '& > *:not(:nth-of-type(4n))': {
      marginRight: 20,
    },
  },

  [`& .${classes.worldWrap}`]: {
    position: 'relative',
    width: 1400,
    height: 694,
    backgroundImage: `url(${bgWorldMap})`,
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
  },

  [`& .${classes.region}`]: {
    position: 'absolute',
    //Africa
    '&.region-AF': {
      top: 285,
      left: 700,
    },
    //Asia
    '&.region-AS': {
      top: 79,
      right: 184,
    },
    //CIS
    '&.region-CI': {
      top: 45,
      left: 862,
    },
    //Europe
    '&.region-EU': {
      top: 7,
      left: 701,
    },
    //MENA
    '&.region-ME': {
      top: 152,
      left: 737,
    },
    //North America
    '&.region-NA': {
      top: 84,
      left: 199,
    },
    //Oceania
    '&.region-OC': {
      top: 428,
      right: 90,
    },
    //South America
    '&.region-SA': {
      top: 321,
      left: 318,
    },
    //SEA
    '&.region-SE': {
      top: 235,
      right: 195,
    },
  },

  [`& .${classes.regionButton}`]: {
    width: 92,
    height: 92,
    borderRadius: '50%',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.04,
    letterSpacing: 0.23,
    backgroundColor: 'rgb(255,255,255,0.1)',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1665c3',
      opacity: 0.9,
    },
  },
}));

export default function ArtDevAboutStatistics() {
  const { isLoading, data } = useAboutCountryStatisticsAPI();

  const statData = data?.data.data_list.filter(
    (item) => item.region_code !== 'AN' && item.region_code !== 'GO' && item.region_code !== 'ZZ'
  );

  const cardInfoList = [
    {
      id: 1,
      topText: 'Featured',
      title: 'Games',
      number: data?.data?.summary?.game_count ?? 0,
    },
    {
      id: 2,
      topText: 'Over',
      title: 'Users',
      number: data?.data?.summary?.user_count ?? 0,
    },
    {
      id: 3,
      topText: 'Users in',
      title: 'Regions',
      number: data?.data?.summary?.country_count ?? 0,
    },
    {
      id: 4,
      topText: 'Over',
      title: 'Supporters',
      number: 1000,
    },
  ];

  return (
    <Root>
      <Typography variant="h4" textAlign="center">
        Our Community Statistics
      </Typography>
      <div className={classes.cardsWrap}>
        {cardInfoList.map((info, index) => (
          <ArtDevStatisticsCard key={index} {...info} loading={isLoading} />
        ))}
      </div>

      <div className={classes.worldWrap}>
        {statData &&
          statData?.map((item, index) => (
            <div className={clsx(classes.region, `region-${item.region_code}`)} key={index}>
              <ArtDevAboutStatisticsTooptip {...item} />
            </div>
          ))}
      </div>
    </Root>
  );
}
