import {
  FormAutocomplete,
  FormAutocompleteOption,
  FormInput,
  FormRadioGroup,
  SelectionProps,
} from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useGetGameGenreAPI, useGetGameTypeAPI } from 'api/genre/gameGenreAPI';
import {
  useGamePlatformTypeAPI,
  useSubmitGameLinkTypeAPI,
} from 'api/recommendGame/recommendGameAPI';
import RecommendGameAddWebsiteFieldList from './RecommendGameAddWebsiteFieldList';
import RecommendGameBottomListText from './RecommendGameBottomListText';
import RecommendGameDuplicateCheck, { CheckcData } from './RecommendGameDuplicateCheck';
import RecommendGameWebsiteForm from './RecommendGameWebsiteForm';

const InputBox = styled('div')({
  marginTop: 32,

  '&:nth-of-type(1)': {
    marginTop: 0,
  },
});

interface RecommendGamePaperProps {
  isDuplicateChecked: boolean;
  setIsDuplicateChecked: Dispatch<SetStateAction<boolean>>;
  setDuplicateCheckStatus: Dispatch<SetStateAction<CheckcData['game_type'] | undefined>>;
}

export default function RecommendGamePaper({
  isDuplicateChecked,
  setIsDuplicateChecked,
  setDuplicateCheckStatus,
}: RecommendGamePaperProps) {
  const { isLoading: isLoadingGamePlatform, data: gamePlatformTypeData } = useGamePlatformTypeAPI();
  const { data: submitGameLinkTypeData } = useSubmitGameLinkTypeAPI();
  const { data: genreData } = useGetGameGenreAPI();
  const { data: gameTypeData } = useGetGameTypeAPI();
  const { data: countriesData } = useGetCountriesAPI();

  const gamePlatformType = gamePlatformTypeData?.data.game_platform ?? [];
  const gamePlatformTypeList: SelectionProps[] = gamePlatformType.map((item) => ({
    id: item.code_order,
    order: item.code_order,
    label: item.code_name,
  }));

  const gameLinkType = submitGameLinkTypeData?.data.submit_game_link_type ?? [];
  const gameLinkTypeList: FormAutocompleteOption[] = gameLinkType.map((item) => ({
    name: item.code_name,
    value: item.code,
  }));

  const gameGenres = genreData?.data ?? [];
  const gameGenreList: FormAutocompleteOption[] = gameGenres.map((genre) => ({
    name: genre.genre_name,
    value: `genre_${genre.genre_id}`,
  }));

  const gameTypes = gameTypeData?.data ?? [];
  const gameTypeList: FormAutocompleteOption[] = gameTypes.map((type) => ({
    name: type.game_type_name,
    value: `type_${type.genre_id}`,
  }));

  const countries = countriesData ?? [];
  const countryList: FormAutocompleteOption[] = countries.map((country) => ({
    name: country.name,
    value: country.code,
  }));

  const gamePlatformOptions = gamePlatformTypeList.map((item) => {
    if (item.label === 'Mobile') {
      return { ...item, disabled: true };
    }

    return { ...item };
  });

  return (
    <>
      {/* game title */}
      <InputBox>
        <RecommendGameDuplicateCheck
          isDuplicateChecked={isDuplicateChecked}
          setIsDuplicateChecked={setIsDuplicateChecked}
          setDuplicateCheckStatus={setDuplicateCheckStatus}
        />
      </InputBox>
      {/* game genres */}
      <InputBox>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px' }}>
            Game Genre/Type
          </Typography>
          <Typography variant="body2" color="text.secondary2">
            Multiple selection is possible (one to five)
          </Typography>
        </Box>
        <FormAutocomplete
          name="submit_game_genre"
          placeholder="Select or enter the game's genre/type"
          options={[...gameGenreList, ...gameTypeList]}
          withBackground
          rules={{ required: 'This is a required question' }}
          multiple
          maxSelectCount={5}
        />
      </InputBox>
      {/* game platform */}
      <InputBox>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '20px' }}>
            Platform
          </Typography>
          <Typography variant="body2" color="text.secondary2">
            Please recommend a game that can be played on PC
          </Typography>
        </Box>
        {!isLoadingGamePlatform && (
          <FormRadioGroup
            name="game_platform"
            options={gamePlatformOptions}
            defaultValue={gamePlatformOptions[0].order.toString()}
            sx={{ columnGap: '40px' }}
          />
        )}
      </InputBox>
      {/* website */}
      <InputBox>
        <RecommendGameAddWebsiteFieldList
          name="submit_game_link"
          component={RecommendGameWebsiteForm}
          initialValue={{ submit_game_link_type: '', submit_game_link_url: '' }}
          minCount={1}
          maxCount={10}
          gameLinkTypeList={gameLinkTypeList}
        />
      </InputBox>
      {/* reason for recommend */}
      <InputBox>
        <FormInput
          name="submit_reason"
          label="The reason for recommending this game"
          placeholder="Please let us know why you are recommending this game (Up to 1,000 characters)"
          required
          rules={{
            required: 'This is a required question',
            maxLength: { value: 1000, message: 'max 1,000 characters' },
          }}
          inputProps={{ maxLength: 1000 }}
          fullWidth
          withBackground
          multiline
          minRows={4}
        />
      </InputBox>
      {/* studio name */}
      <InputBox>
        <FormInput
          name="submit_studio"
          label="Studio or Developer Name"
          placeholder="Enter name"
          required
          rules={{
            required: 'This is a required question',
            maxLength: { value: 100, message: 'max 100 characters' },
          }}
          inputProps={{ maxLength: 100 }}
          fullWidth
          withBackground
        />
      </InputBox>
      {/* studio country */}
      <InputBox>
        <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px' }}>
          Studio or Developer’s Country (Optional)
        </Typography>
        <FormAutocomplete
          name="submit_studio_country"
          placeholder="Select country"
          options={countryList}
          withBackground
        />
      </InputBox>
      {/* studio contact */}
      <InputBox>
        <FormInput
          name="studio_contact"
          label="Studio or Developer’s Contact (Optional)"
          placeholder="Enter contact info (email, phone number, etc)"
          rules={{
            maxLength: { value: 100, message: 'max 100 characters' },
          }}
          inputProps={{ maxLength: 100 }}
          fullWidth
          withBackground
        />
      </InputBox>
      <RecommendGameBottomListText />
    </>
  );
}
