import { Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import ArtDevOnBoardList from './component/ArtDevOnBoardList';

const PREFIX = 'ArtDevAboutOnBoard';

const classes = {
  cardsWrap: `${PREFIX}-cardsWrap`,
  miniInfoWrap: `${PREFIX}-miniInfoWrap`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 1080,
  paddingTop: 80,
  paddingBottom: 80,

  [`& .${classes.cardsWrap}`]: {
    height: 438,
    display: 'flex',
    position: 'relative',
    marginTop: 80,
  },

  [`& .${classes.miniInfoWrap}`]: {
    position: 'absolute',
    width: 406,
    bottom: 0,
    left: 450,
    margin: 0,
    padding: '60px 0 12px 13px',
    textIndent: -4,
    backgroundColor: '#3348b2',
  },
}));

const onBoardList = [
  {
    id: 1,
    title: 'Review & Selection',
    period: (
      <>
        1 month+ <br /> before going Live
      </>
    ),
    stage: '',
    desc: [
      '1. Submit your game',
      '2. Internal review',
      '3. Greenlight > Select proposed slots & packages',
    ],
  },
  {
    id: 2,
    title: 'On-boarding',
    period: '4 Weeks',
    stage: '',
    desc: [
      'Invite to G.Round Channel',
      <>
        Private invite to <br />
        dedicated G.Round tech <br />
        support channel
      </>,
      'SDK guidelines & integration',
      'Survey creation',
      'Cross-promotion plan',
    ],
  },
  {
    id: 3,
    title: 'Game Test (2 weeks)',
    period: '2 Weeks',
    stage: 'Review',
    desc: [
      <>
        10-12 standard
        <br /> review questions
      </>,
    ],
  },
  {
    id: 4,
    title: 'Game Test (2 weeks)',
    period: '2 Weeks',
    stage: 'Global Survey',
    desc: [
      <>
        Customizable survey with <br />5 questions shown to our <br />
        global user base
      </>,
    ],
  },
  {
    id: 5,
    title: 'Localization',
    period: '2 Weeks',
    stage: 'Localized Survey',
    desc: ['Communicate with target region’s gamers for optimizing your game'],
  },
];

export default function ArtDevAboutOnBoard() {
  return (
    <Root>
      <Typography variant="h4">G.Round On-Boarding Procedure</Typography>
      <div className={classes.cardsWrap}>
        {onBoardList.map((item) => (
          <ArtDevOnBoardList key={item.id} {...item} />
        ))}
        <ul className={classes.miniInfoWrap}>
          <Typography component="li" variant="body2" sx={{ opacity: 0.6 }}>
            Access to international partner content creators
          </Typography>
          <Typography component="li" variant="body2" sx={{ margin: '16px 0 16px', opacity: 0.6 }}>
            Gather playtesting data
          </Typography>
          <Typography component="li" variant="body2" sx={{ opacity: 0.6 }}>
            Gather target market data
          </Typography>
        </ul>
      </div>
    </Root>
  );
}
