import { FormInput } from '@ground/ui';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, UseFormSetError, useForm } from 'react-hook-form';

export interface ConfirmFormData {
  password: string;
  setError: UseFormSetError<ConfirmFormData>;
}

interface DeleteAccountVerificationProps {
  email?: string;
  isPassword: boolean;
  onClickVertificationHandler: (data: ConfirmFormData) => void;
}
// 패스워드, 이메일 확인
export default function DeleteAccountVerification({
  email,
  isPassword,
  onClickVertificationHandler,
}: DeleteAccountVerificationProps) {
  const methods = useForm<ConfirmFormData>({ mode: 'onChange' });
  const { setError } = methods;

  const onSubmit = (data: { password: string }) => {
    const params = {
      password: data.password,
      setError,
    };

    onClickVertificationHandler(params);
  };
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {isPassword ? (
          'Please enter your password for account verification.'
        ) : (
          <>
            We need to verify your email. <br />
            Please click the button to send a verification code to your registered email address.
          </>
        )}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isPassword ? (
            <FormInput
              label="Password"
              name="password"
              type="password"
              password
              placeholder="Enter your password"
              rules={{
                required: 'Enter your password',
              }}
              fullWidth
              withBackground
              size="large"
              sx={{ mt: '20px' }}
            />
          ) : (
            <Box
              sx={{
                padding: '20px 50px',
                textAlign: 'center',
                backgroundColor: '#333333',
                borderRadius: '5px',
              }}
              mt="20px"
            >
              <Typography variant="subtitle1" color="text.primary">
                {email}
              </Typography>
            </Box>
          )}

          <Box mt="40px">
            <Button color="primary" variant="contained" sx={{ width: '100%' }} type="submit">
              {isPassword ? 'Confirm' : 'Send Verification Email'}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
