import { LoadingWrapper } from '@ground/ui';
import { Box, styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useSupporterApplyConfirmAPI } from 'api/supporter/useSupporterAPI';
import NotFound from 'views/main/NotFound';
import SuppoterApplyConfirmCotents from './SuppoterApplyConfirmCotents';

// 프린트 display: none 처리
const RootBox = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  '@media print': {
    display: 'none',
  },
}));

export default function SuppoterApplyConfirmMain() {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  const { data, isValidating } = useSupporterApplyConfirmAPI(String(key));

  return (
    <RootBox
      display="flex"
      width="1200px"
      mt="40px"
      alignItems="center"
      flexDirection="column"
      onContextMenu={(e) => {
        e.preventDefault();
        alert('Prevent right-click');
      }}
    >
      <LoadingWrapper isLoading={isValidating} size={80}>
        {data?.data === null ? (
          <NotFound />
        ) : (
          <SuppoterApplyConfirmCotents
            ndaData={
              data
                ? data?.data
                : {
                    fullname: '',
                    ndacompleted_at: '',
                  }
            }
          />
        )}
      </LoadingWrapper>
    </RootBox>
  );
}
