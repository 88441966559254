import { useState } from 'react';
import { styled } from '@mui/material/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton, Typography, CircularProgress, Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useFetch, useSWRFetch } from 'api';
import { useError } from 'hooks';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import { PlayGameItemRow } from './PlayGameComponents';

import icon_copy from 'images/icons/copy.svg';

const KeyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.gray[50],
  marginTop: '16px',
  padding: '20px',
}));

export interface PlayGameDialogBodyItemProps {
  data: GameDetailProps;
}

export default function PlayGameDialogBodyItemGameKey({ data }: PlayGameDialogBodyItemProps) {
  const [copied, setCopied] = useState(false);
  const gameFile = data?.game_files?.[0];
  const deploy_type = gameFile?.deploy_type?.toLowerCase();

  //게임키 활성화 여부확인 api
  const { isLoading: loadingGameKeysActivateData, data: gameKeysActivateData } = useSWRFetch<
    string | null
  >({ key: data?.id ? `games/${data.id}/users/gameKeys` : null, useToken: true });

  const {
    isLoading: loadingGameKeysData,
    data: gameKeysData,
    fetch,
  } = useFetch<string | null>(`games/${data?.id}/gameKeys`, { method: 'GET' }, true);
  const { showError } = useError();

  const handleClickGetAGameKey = async () => {
    //distribution_off 가 true 일 때 게임키 받기 중지
    if (gameFile?.distribution_off) {
      return;
    }
    const response = await fetch();

    if (response.status === 200 && response.data.result_code === 'OK') {
    } else if (response.data?.result_code !== '402') {
      showError(response);
    }
    data && eventTracker(`playgamepopup_${deploy_type}_getgamekey`, { event_label: data.id });
  };

  const handleCopyGameKey = () => {
    data && eventTracker(`playgamepopup_${deploy_type}_copygamekey`, { event_label: data.id });
  };

  return loadingGameKeysActivateData ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress size={14} />
    </Box>
  ) : (
    <>
      <PlayGameItemRow>
        <Typography color="text.primary" variant="body2" width={260}>
          No, I need to claim a game key.
        </Typography>
        <LoadingButton
          loading={loadingGameKeysData}
          disabled={gameFile?.key_off}
          onClick={handleClickGetAGameKey}
          variant="ghost"
          size="small"
        >
          Get a Game Key
        </LoadingButton>
      </PlayGameItemRow>
      {(gameFile?.distribution_off ||
        gameKeysActivateData?.result_code === 'OK' ||
        // 402는 생성된 키가 바닥나서 없는 상태
        gameKeysData?.result_code === '402' ||
        gameKeysData?.result_code === 'OK') && (
        <KeyWrapper>
          <Stack direction="row" alignItems="center">
            <Typography
              color={
                gameKeysData?.result_code === '402' ||
                (gameFile?.distribution_off && gameKeysActivateData?.data === null)
                  ? 'error.main'
                  : 'text.primary'
              }
              variant="subtitle1"
            >
              {gameKeysActivateData?.result_code === 'OK'
                ? gameKeysActivateData?.data
                : gameKeysData?.result_code === '402' ||
                  (gameFile?.distribution_off && gameKeysActivateData?.data === null)
                ? 'Out Of Keys!'
                : gameKeysData?.data || gameKeysData?.description}
            </Typography>
            {(gameKeysActivateData?.data ||
              (gameKeysData?.data && gameKeysData?.result_code !== '402')) && (
              <CopyToClipboard
                text={
                  gameKeysActivateData?.result_code === 'OK'
                    ? gameKeysActivateData?.data ?? ''
                    : gameKeysData?.data ?? ''
                }
                onCopy={(_, result) => setCopied(result)}
              >
                <IconButton
                  onClick={handleCopyGameKey}
                  size="small"
                  sx={{ marginLeft: '8px', padding: 0 }}
                >
                  <img src={icon_copy} alt="copy" />
                </IconButton>
              </CopyToClipboard>
            )}
          </Stack>
          {copied && (
            <Typography color="primary.main" marginTop="4px" variant="button3">
              Game key copied!
            </Typography>
          )}
        </KeyWrapper>
      )}
    </>
  );
}
