import type { PathLog } from 'utils/userLog/types/logTypes';

/**
 * path 로그 정의
 */

interface PathLogs {
  [key: string]: Omit<PathLog, 'parameters'> & { pattern: RegExp; paramNames?: string[] };
}

export const pathLogs: PathLogs = {
  // main
  '/': { pattern: /^\/$/, service: 'main', page: 'main', area: 'N/A', ui: 'N/A' },

  // signin
  '/login': {
    pattern: /^\/login$/,
    service: 'signin',
    page: 'signin_main',
    area: 'N/A',
    ui: 'N/A',
  },
  '/login/email': {
    pattern: /^\/login\/email$/,
    service: 'signin',
    page: 'signin_email',
    area: 'N/A',
    ui: 'N/A',
  },

  // reset password
  '/request-reset-password': {
    pattern: /^\/request-reset-password$/,
    service: 'signin',
    page: 'reset_pw',
    area: 'N/A',
    ui: 'N/A',
  },
  '/reset-password': {
    pattern: /^\/reset-password$/,
    service: 'signin',
    page: 'new_pw',
    area: 'N/A',
    ui: 'N/A',
  },

  // signup
  '/signup': {
    pattern: /^\/signup$/,
    service: 'signup',
    page: 'signup_main',
    area: 'N/A',
    ui: 'N/A',
  },
  '/signup/email': {
    pattern: /^\/signup\/email$/,
    service: 'signup',
    page: 'signup_email',
    area: 'N/A',
    ui: 'N/A',
  },
  '/signup/verification': {
    pattern: /^\/signup\/verification$/,
    service: 'signup',
    page: 'email_verification',
    area: 'N/A',
    ui: 'N/A',
  },
  '/signup/profile': {
    pattern: /^\/signup\/profile$/,
    service: 'signup',
    page: 'personal_detail',
    area: 'N/A',
    ui: 'N/A',
  },
  '/signup/university': {
    pattern: /^\/signup\/university$/,
    service: 'signup',
    page: 'university_detail',
    area: 'N/A',
    ui: 'N/A',
  },

  // games
  '/testing': {
    pattern: /^\/testing$/,
    service: 'games',
    page: 'gamesmain',
    area: 'N/A',
    ui: 'N/A',
  },

  '/detail/:gameId': {
    pattern: /^\/detail\/\d+$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'game_detail',
    area: 'N/A',
    ui: 'N/A',
  },

  '/detail/:gameId/info': {
    pattern: /^\/detail\/\d+\/info$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'gameinfo',
    ui: 'N/A',
  },
  '/detail/:gameId/review/reviews': {
    pattern: /^\/detail\/\d+\/review\/reviews$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'reviews',
    ui: 'N/A',
  },
  '/detail/:gameId/review/my-activities': {
    pattern: /^\/detail\/\d+\/review\/my-activities$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'myactivities',
    ui: 'N/A',
  },
  '/detail/:gameId/clips': {
    pattern: /^\/detail\/\d+\/clips$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'clips',
    ui: 'N/A',
  },
  'detail/:gameId/news': {
    pattern: /^\/detail\/\d+\/news$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'newsnupdates',
    ui: 'N/A',
  },
  '/detail/:gameId/guide': {
    pattern: /^\/detail\/\d+\/guide$/,
    paramNames: ['gameId'],
    service: 'games',
    page: 'gamedetail',
    area: 'howtoplay',
    ui: 'N/A',
  },

  // blog
  '/blog': {
    pattern: /^\/blog$/,
    service: 'blog',
    page: 'blog_main',
    area: 'N/A',
    ui: 'N/A',
  },
  '/blog/:blogId': {
    pattern: /^\/blog\/\d+$/,
    paramNames: ['blogId'],
    service: 'blog',
    page: 'blog_detail',
    area: 'N/A',
    ui: 'N/A',
  },

  // clips
  '/clips': {
    pattern: /^\/clips$/,
    service: 'clips',
    page: 'clips_main',
    area: 'N/A',
    ui: 'N/A',
  },
  '/clips/allclip': {
    pattern: /^\/clips\/allclip$/,
    service: 'clips',
    page: 'all_clips_main',
    area: 'N/A',
    ui: 'N/A',
  },

  // store
  '/store': {
    pattern: /^\/store$/,
    service: 'store',
    page: 'storemain',
    area: 'N/A',
    ui: 'N/A',
  },
  '/store/merchandise/:productId': {
    pattern: /^\/store\/merchandise\/[^/]+$/,
    paramNames: ['productId'],
    service: 'store',
    page: 'merchandise',
    area: 'detail',
    ui: 'N/A',
  },
  '/store/digital-games/:productId': {
    pattern: /^\/store\/digital-games\/[^/]+$/,
    paramNames: ['productId'],
    service: 'store',
    page: 'digital_game',
    area: 'detail',
    ui: 'N/A',
  },
  '/store/digital-games/:productId/order': {
    pattern: /^\/store\/digital-games\/[^/]+\/order$/,
    paramNames: ['productId'],
    service: 'store',
    page: 'digital_game',
    area: 'payment',
    ui: 'N/A',
  },
  '/store/digital/:productId': {
    pattern: /^\/store\/digital\/[^/]+$/,
    paramNames: ['productId'],
    service: 'store',
    page: 'digital_product',
    area: 'detail',
    ui: 'N/A',
  },

  // about
  '/about': {
    pattern: /^\/about$/,
    service: 'about',
    page: 'about_main',
    area: 'N/A',
    ui: 'N/A',
  },

  // profile
  '/profile/profile': {
    pattern: /^\/profile\/profile$/,
    service: 'mypage',
    page: 'mypage',
    area: 'profile',
    ui: 'N/A',
  },
  '/profile/gpoint': {
    pattern: /^\/profile\/gpoint$/,
    service: 'mypage',
    page: 'mypage',
    area: 'gpoint',
    ui: 'N/A',
  },
  '/profile/orders/merchandise': {
    pattern: /^\/profile\/orders\/merchandise$/,
    service: 'mypage',
    page: 'mypage',
    area: 'merchandise',
    ui: 'N/A',
  },
  '/profile/orders/digital-games': {
    pattern: /^\/profile\/orders\/digital-games$/,
    service: 'mypage',
    page: 'mypage',
    area: 'digitalgames',
    ui: 'N/A',
  },
  '/profile/orders/digital-products': {
    pattern: /^\/profile\/orders\/digital-products$/,
    service: 'mypage',
    page: 'mypage',
    area: 'digitalproducts',
    ui: 'N/A',
  },

  // common
  '/404': {
    pattern: /^\/404$/,
    service: 'common',
    page: '404',
    area: 'N/A',
    ui: 'N/A',
  },
  '/maintenance': {
    pattern: /^\/maintenance$/,
    service: 'common',
    page: 'under_construction',
    area: 'N/A',
    ui: 'N/A',
  },
};
