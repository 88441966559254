import { numberWithCommas } from '@ground/tools';
import { Box, Button, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { OMerchandiseOrderStatus, OrderTypeKey, useGetOrderList } from 'api/order';
import eventTracker from 'utils/eventTracker';
import ProfileOrderDataReactTable from './orderDataReactTable/ProfileOrderDataReactTable';

const headers: Column[] = [
  { accessor: 'dateTime', Header: 'Date/Time' },
  { accessor: 'product', Header: 'Product' },
  { accessor: 'quantity', Header: 'Quantity' },
  { accessor: 'price', Header: 'Price (G.P)' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'detailBtn', Header: ' ' },
];

interface Props {
  type: OrderTypeKey;
}

export default function OrderListTableContainer({ type }: Props) {
  const navigate = useNavigate();
  const { data: orderHistoryData, isLoading } = useGetOrderList(type);

  const cellData = useMemo(
    () =>
      orderHistoryData && Array.isArray(orderHistoryData?.data)
        ? orderHistoryData.data.map((order) => ({
            dateTime: (
              <>
                <Typography variant="body2" color="text.secondary">
                  {order.order_date ? format(new Date(order.order_date), 'MMM d, yyyy') : ''}
                </Typography>
                <Typography variant="small2" color="text.secondary2" marginTop="4px" noWrap>
                  {order.order_date ? `${format(new Date(order.order_date), 'h:mm aa')}(PT)` : ''}
                </Typography>
              </>
            ),
            product: (
              <Stack direction="row" alignItems="center" spacing="20px">
                <Box
                  component="img"
                  src={order.product_thumbnail_url}
                  alt="product"
                  width={60}
                  height={48}
                  borderRadius="3px"
                />
                <Typography variant="body2" color="text.secondary" align="left">
                  {order.product_title}
                </Typography>
              </Stack>
            ),
            quantity: (
              <Typography variant="body2" color="text.secondary">
                {order.quantity}
              </Typography>
            ),
            price: (
              <Typography variant="body2" color="text.secondary">
                {order.order_price ? numberWithCommas(order.order_price) : ''}
              </Typography>
            ),
            status: (
              <Typography component="span" variant="body2" color="text.secondary">
                {order.order_status === 'CONFIRMED'
                  ? OMerchandiseOrderStatus.ORDERED
                  : OMerchandiseOrderStatus[order.order_status]}
              </Typography>
            ),
            detailBtn: (
              <Button
                variant="ghost"
                size="small"
                onClick={() => {
                  eventTracker('profile_order_detail', { event_label: order.order_id });
                  navigate(`/profile/order/${order?.order_id}`);
                }}
              >
                Order Detail
              </Button>
            ),
          }))
        : [],
    [navigate, orderHistoryData]
  );

  return <ProfileOrderDataReactTable headers={headers} cellData={cellData} isLoading={isLoading} />;
}
