import useSWRFetch from 'api/useSWRFetch';
import { PopularClipsType, PickedReviewType } from 'types/DisplayContentType';
import { GameCardProps } from 'types/GameCardTypes';

export function useFeaturedGamesAPI() {
  const url = 'v2/display/content/featured-games';

  return useSWRFetch<GameCardProps[]>({ key: url, useToken: true });
}

export function usePopularClipsAPI() {
  const url = 'display/content/videos?display_type=1';

  return useSWRFetch<PopularClipsType[]>({ key: url });
}

export function usePickedReviewsAPI() {
  const url = 'display/content/picked_reviews';

  return useSWRFetch<PickedReviewType[]>({ key: url });
}

export function useBestGamesAPI() {
  const url = 'display/content/games?display_type=1';

  return useSWRFetch<GameCardProps[]>({ key: url, useToken: true });
}
