import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';

import { useGameDetailMediaExposureAPI } from 'api/detail/detailAPI';
import GameInfoMediaExposureItem from './GameInfoMediaExposureItem';

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: 120,
  marginBottom: 40,
}));

export default function GameInfoMediaExposure() {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isValidating, setSize } = useGameDetailMediaExposureAPI(id);

  const viewMore: boolean = useMemo(() => {
    const pagination = data && data[data.length - 1]?.pagination;

    return pagination ? pagination.total_pages > pagination.current_page + 1 : false;
  }, [data]);

  return data && data[0].data.length > 0 ? (
    <StyledDiv>
      <Typography variant="h5" marginBottom="40px">
        Media Exposure
      </Typography>
      <Box display="flex" flexWrap="wrap" rowGap="12px" columnGap="12px">
        {data.map((responseData) =>
          responseData.data.map((item, index) => (
            <GameInfoMediaExposureItem key={index} {...item} />
          ))
        )}
      </Box>
      {viewMore && (
        <Box display="flex" justifyContent="center" marginTop="28px">
          <LoadingButton
            component={ButtonBase}
            loading={isValidating}
            endIcon={<ExpandMoreIcon />}
            size="large"
            onClick={() => {
              setSize((size) => size + 1);
            }}
            color="light"
          >
            See More
          </LoadingButton>
        </Box>
      )}
    </StyledDiv>
  ) : null;
}
