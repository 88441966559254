import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, Stack, Typography, styled, useTheme } from '@mui/material';
import format from 'date-fns/format';
import isPlainObject from 'lodash-es/isPlainObject';
import { useEffect, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  SimilarityStatus,
  useGetReviewSurveyState,
  usePublicReviewRegisterAPI,
  usePublicReviewUpdateAPI,
} from 'api/review/reviewAPI';
import { useDialog, useError } from 'hooks';
import { iRootState } from 'store';
import { ReviewFormData } from 'types/FormDataType';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from '../DetailProvider';
import { useImportDraftData } from '../ImportDraftProvider';
import ReviewPaper from './ReviewPaper';
import { useReviewData } from './ReviewProvider';

import { ReactComponent as Robot } from 'images/icons/robot.svg';

const BannerContainer = styled(Box)({
  width: '100%',
  padding: '20px 20px 20px 32px',
  background: '#303D5E',
  display: 'flex',
  gap: '30px',
  alignItems: 'center',
});

export default function ReviewForm() {
  const { showSimpleDialog, showDialog } = useDialog();
  const { showError } = useError();
  const { id = '' } = useParams<{ id: string }>();
  const { data, mutate } = useReviewData();
  const { shouldImportReviewDraftData, setShouldImportReviewDraftData } = useImportDraftData();
  const { data: reviewSurveyData, mutate: reviewStateReload } = useGetReviewSurveyState(id);
  const reviewState = reviewSurveyData?.data?.review_status;
  const { setOpenReview } = useGetDetailData();
  const { identifier } = useSelector((state: iRootState) => state.login);
  const [hasUserInput, setHasUserInput] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const theme = useTheme();

  useBeforeunload((event) => {
    event.preventDefault();
  });

  const localStorageKey = `draft-${identifier}-${id}-review`;

  const savedDraftDataInLocalStorage = localStorage.getItem(localStorageKey) ?? '{}';

  const [draftData, setDraftData] = useState<any[]>(JSON.parse(savedDraftDataInLocalStorage));

  const savedTimeInDraft =
    draftData && Array.isArray(draftData) && draftData.length > 1
      ? new Date((draftData[1] as { time: Date }).time)
      : null;

  const [savedTime, setSavedTime] = useState<Date | null>(savedTimeInDraft);
  const defaultValues: ReviewFormData = useMemo(
    () => ({
      essential:
        data?.essential_questions.map((essential) => ({
          id: essential.id,
          value: essential.answer?.toString() ?? '',
          opinion: essential.opinion,
        })) ?? [],
      optional:
        data?.optional_questions.map((optional) => ({
          id: optional.id,
          value: optional.answer ?? '',
          agree: true,
        })) ?? [],
    }),
    [data?.essential_questions, data?.optional_questions]
  );

  const { isLoading: submitLoading, fetch: submitReview } = usePublicReviewRegisterAPI(id);
  const { isLoading: updateLoading, fetch: updateReview } = usePublicReviewUpdateAPI(id);

  const methods = useForm<ReviewFormData>({ defaultValues, reValidateMode: 'onSubmit' });

  // methods.formState.isDirty를 안쓰고 왜 이렇게 구현했는지 확인
  const dirtyFields = methods?.formState?.dirtyFields;
  const isDirty = isPlainObject(dirtyFields) && Object.keys(dirtyFields)?.length > 0;

  // Review Submit
  const onSubmit = async (formData: ReviewFormData) => {
    const statusResult = await reviewStateReload();

    if (statusResult?.data?.review_status?.similarity_status === SimilarityStatus.WAITING) {
      showDialog({
        title: 'Editing Disabled',
        body: (
          <Typography variant="body2" color="text.secondary">
            This review is currently under inspection. You will be able to make edits once the
            inspection is complete. <br /> (The inspection process typically takes about 10 minutes.
            )
          </Typography>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    // 초기 리뷰 작성 시 공개 체크 해제한 경우 사용자 확인 후 다음 단계로 진행
    const isReviewsHasNotPublic = formData.optional?.some((input) => input?.value && !input.agree);

    if (!reviewSubmitted && isReviewsHasNotPublic) {
      showDialog({
        title: 'Confirmation',
        body: "You haven't made your review public. If you want to have the chance to have the studio pick your review, the review must be made public. Do you still want to proceed?",
        primaryButton: {
          text: 'Yes',
          callback: () => {
            // checkOptionalTextLength에서 submit 가능 여부 확인 후 가능하지 않은 경우에 다이얼로그가 다시 뜨자마자 닫히는 문제를 해결하기 위해 delay 추가
            window.setTimeout(() => checkOptionalTextLength(formData), 500);
          },
        },
        secondaryButton: { text: 'No' },
      });
      return;
    }

    checkOptionalTextLength(formData);
  };

  // 리뷰 글자 수 체크 후 submit 여부 확인
  const checkOptionalTextLength = (formData: ReviewFormData) => {
    // 100글자 미만 체크
    const isReviewsHasLessThan300 = formData?.optional?.some(
      (input) => input?.value && input.value.trim().length < 300
    );

    // 퍼블릭 300자 미만일때
    if (isReviewsHasLessThan300) {
      showDialog({
        title: 'Leave a Detailed Review',
        body: `The studio values your feedback! Please ensure your review is at least 300 characters long to help improve the game with your insights.`,
        primaryButton: {
          text: 'OK',
          ButtonProps: {
            color: 'primary',
            variant: 'contained',
          },
        },
      });
      return;
    }

    // 모든 조건 만족한 경우
    submit(formData);
    // 데이터를 전송한 뒤, 임시 저장된 값을 지워준다.
    localStorage.removeItem(localStorageKey);
    // 저장값을 불러오주는 기능을 off 한다.
    setShouldImportReviewDraftData(false); // TODO : onClose 로 빼야함
  };

  // formdata submit
  const submit = async (formData: ReviewFormData) => {
    const essentialList =
      formData.essential?.map((essential) => ({
        question_id: essential.id,
        type: 'POINT',
        answer: essential.value,
        opinion: essential.opinion,
      })) || [];

    const optionalList =
      formData.optional?.map((optional) => ({
        question_id: optional.id,
        type: 'ANSWER',
        answer: optional.value,
        agree: optional.agree ?? false,
      })) || [];

    const response = await (reviewSubmitted ? updateReview : submitReview)({
      answers: [...essentialList, ...optionalList],
    });
    if (response.status === 200) {
      // 에러 코드 처리 변경 필요
      if (response.data.result?.code === 200) {
        setOpenReview(false);
        setIsSubmitted(true);
        eventTracker(`review_${reviewSubmitted ? 'update' : 'submit'}`, { event_label: id });
        mutate && mutate();

        reviewStateReload();

        showDialog({
          title: 'Submit a review',
          body: (
            <>
              <Typography variant="subtitle1" color="text.primary">
                Thank you for submitting a review of this game.
              </Typography>
              <Box mt="12px">
                <Typography variant="body2" color="text.secondary2">
                  Submitted reviews are checked by our{' '}
                  <span style={{ color: theme.palette.text.primary }}>
                    automatic monitoring system
                  </span>{' '}
                  and finalized, with points awarded{' '}
                  <span style={{ color: theme.palette.text.primary }}>within 10 minutes</span>. As a
                  result of AI Checking, submissions of reviews with problems are rejected. And may
                  result in restrictions on future service usage.
                </Typography>
              </Box>
            </>
          ),
          primaryButton: {
            text: 'OK',
            ButtonProps: {
              color: 'primary',
              variant: 'contained',
            },
          },
          onClose: () => localStorage.removeItem(localStorageKey),
        });
      } else if (response.data.result_code === '401') {
        if (response.data.data.error_code.code === 'U001') {
          // showDialog({
          //   title: 'Alert',
          //   body: `Your profile is incomplete. Head over to your profile page and connect to Facebook. Don't forget to add any other missing information, like country and gender!`,
          //   primaryButton: {
          //     text: 'Go to Profile',
          //     ButtonProps: {
          //       color: isPrivate ? 'private' : 'primary',
          //       variant: 'contained',
          //     },
          //     callback: () => {
          //       navigate('/profile/profile');
          //       eventTracker('alertpopup_goto_profile');
          //       twttr?.conversion.trackPid('o91dr', { tw_sale_amount: 0, tw_order_quantity: 0 });
          //       gtag('event', 'conversion', { send_to: 'AW-669277837/ySe-CMidsMQDEI29kb8C' });
          //     },
          //   },
          //   secondaryButton: { text: 'Close' },
          // });
        } else if (response.data.data.error_code.code === 'P003') {
          showSimpleDialog('Error', 'Failed to earn point.');
        }
      } else if (response.data.result_code === '402') {
        showSimpleDialog('Error', 'Reward does not exist.');
      } else {
        showError(response);
      }
    } else {
      showError(response);
    }
  };

  useEffect(() => {
    const dirtyFields = methods.formState.dirtyFields;
    setHasUserInput(Object.keys(dirtyFields).length > 0);
  }, [methods.formState.dirtyFields]);

  useEffect(() => {
    const interval = hasUserInput
      ? setInterval(() => {
          const currentTime = new Date();
          const formData = JSON.stringify([methods.getValues(), { time: currentTime }]);
          // 값을 임시 저장한다.
          if (isDirty) {
            localStorage.setItem(localStorageKey, formData);
            setDraftData([methods.getValues(), { time: currentTime }]);
            // 현재 시간을 표시하여 준다.
            setSavedTime(currentTime);
            // 저장이 되었으면 10초마다 자동저장 기능을 중단한다.
          }
        }, 10000)
      : null;

    return interval ? () => clearInterval(interval) : () => {};
  }, [hasUserInput, isDirty, localStorageKey, methods]);

  useEffect(() => {
    if (draftData && shouldImportReviewDraftData) {
      methods.setValue('essential', draftData[0].essential);
      methods.setValue('optional', draftData[0].optional);
    }
  }, [draftData, shouldImportReviewDraftData, methods]);

  useEffect(() => {
    // 모달이 닫힐 때, 값을 임시저장
    return () => {
      if (!isSubmitted) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify([methods.watch(), { time: new Date() }])
        );
      }
    };
  }, [isSubmitted, localStorageKey, methods]);

  // 리뷰 제출 전과 완료 상태 확인
  const reviewSubmitted = useMemo(() => reviewState?.complete, [reviewState]);

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        {reviewSubmitted ? (
          <Typography color="text.secondary" variant="body2" marginBottom="20px">
            You have already submitted a review. If you want to change your review, please click the
            [Update] button at the bottom of this page.
          </Typography>
        ) : (
          <Typography color="text.secondary" variant="body2" marginBottom="20px">
            Interested in writing better reviews and getting picked more often? Then check out these
            guides on <br />
            <Link
              href="https://gameround.co/blog/156"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              ‘How to write a video game review?’
            </Link>{' '}
            and How to{' '}
            <Link
              href="https://gameround.co/blog/155"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              ‘Become the Ultimate Playtester’
            </Link>
          </Typography>
        )}

        <ReviewPaper />
        {/* cross posting 경고 문구 */}
        <Stack gap="12px" mt="20px">
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box
              sx={{
                alignSelf: 'flex-start',
                height: '5px',
                borderRadius: '5px',
                backgroundColor: '#767676',
                flexBasis: '6px',
                marginTop: '8px',
              }}
            />
            <Typography variant="body2" color="text.secondary2" component={Box}>
              You can edit the review you've submitted until it is selected by the studio. After
              that, no further edits are possible.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box
              sx={{
                alignSelf: 'flex-start',
                height: '5px',
                borderRadius: '5px',
                backgroundColor: '#767676',
                flexBasis: '8px',
                marginTop: '8px',
              }}
            />
            <Typography variant="body2" color="text.secondary2" component={Box}>
              You can still delete your review later, but be aware that it cannot be recovered, and
              you will not be able to submit a new review for the game.
            </Typography>
          </Box>
        </Stack>

        <BannerContainer mt="40px">
          <Box>
            <Robot />
          </Box>
          <Stack gap="8px">
            <Typography variant="button1" color="text.primary">
              Submitted reviews are checked by our automatic monitoring system and finalized, with
              points awarded within 10 minutes.
            </Typography>
            <Typography variant="subtitle3" color="text.primary">
              Reviews identified as spam, fake, or inappropriate will be hidden, and may result in
              restrictions on future service usage.
            </Typography>
          </Stack>
        </BannerContainer>

        {/* Submit Button Area */}
        <Box
          marginTop="40px"
          display="flex"
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gap="2px">
            {hasUserInput && savedTime && (
              <>
                <Typography variant="button3" color="text.secondary2">
                  Last auto-saved :
                </Typography>
                <Typography variant="button3" color="text.secondary">
                  {format(savedTime, 'MMM dd, yyyy HH:mm:ss')}
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" gap="8px">
            <Button onClick={() => setOpenReview(false)} variant="ghost" size="large">
              Cancel
            </Button>
            <LoadingButton
              loading={submitLoading || updateLoading}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              {reviewSubmitted ? 'Update' : 'Submit'}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
