import { FormCheckbox } from '@ground/ui';

import { useLog } from 'utils/userLog';

export default function SignupNewsletter() {
  const { gxcTag } = useLog();

  return (
    <FormCheckbox
      defaultChecked
      label="Subscribe to Newsletter"
      name="subscribe_email"
      checkboxProps={{
        onChange: (_, checked) => {
          gxcTag('click', {
            area: 'detail',
            ui: 'check_subscribe',
            parameters: { check_box: String(checked) },
          });
        },
      }}
    />
  );
}
