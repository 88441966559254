import { Box, Button, Typography, styled } from '@mui/material';

import mountainIcon from 'images/gstore/mountain-icon.png';

const Root = styled(Box)({
  background: '#2D2D40',
  width: '100%',
  height: '100%',
  padding: '30px',
  paddingLeft: '60px',
  position: 'relative',
});

export default function PurchaseDigitalProductBox() {
  return (
    <Root>
      <Typography variant="h5" mt="40px" color="base.white">
        Attention gift card
        <br />
        enthusiasts!
      </Typography>
      <Typography variant="body3" mt="8px" sx={{ opacity: 0.5 }}>
        We want to hear your thoughts on the
        <br /> ideal gift card options.
      </Typography>
      <Button
        variant="ghost"
        size="small"
        sx={{ marginTop: '28px' }}
        onClick={() => {
          window.open('https://forms.gle/1RyFqFePUdy39yxN6', '_blank');
        }}
      >
        Take Survey
      </Button>
      <img
        src={mountainIcon}
        alt="mountain icon"
        style={{
          position: 'absolute',
          top: '60px',
          right: '60px',
          width: '180px',
        }}
      />
    </Root>
  );
}
