import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface LoadingState {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingState | undefined>(undefined);

export function LoadingContextProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);
  const value = useMemo(() => ({ setLoading }), [setLoading]);

  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress size={48} />
      </Backdrop>
    </LoadingContext.Provider>
  );
}

function useLoadingContext() {
  const context = useContext(LoadingContext);
  if (!context) throw new Error('LoadingProvider not found');
  return context;
}

export function useLoading() {
  const setState = useLoadingContext();

  return setState;
}
