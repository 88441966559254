import SectionContainer from 'components/SectionContainer';
import PickedReviewList from './PickedReviewList';

export default function HomePickedReview() {
  return (
    <SectionContainer title="Picked Review">
      <PickedReviewList />
    </SectionContainer>
  );
}
