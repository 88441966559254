import { FormInput } from '@ground/ui';

export default function StudentId() {
  return (
    <FormInput
      fullWidth
      label="Student ID or Number"
      name="student_number"
      placeholder="Enter Student ID or Number"
      rules={{
        validate: {
          maxLength: (v) => v?.trim().length < 51 || 'max 50 characters',
          required: (v) => !!v?.trim(),
        },
      }}
      withBackground
    />
  );
}
