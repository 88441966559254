import { Box, Divider, Typography } from '@mui/material';
import { useSupporterRegisterAPI } from 'api/supporter/useSupporterAPI';
import { SupporterFormData, SupporterQuestionOptionVos } from 'api/supporter/useSuppoterTypes';
import { useQueryDispatch, useQueryState } from 'context';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Step1Contents from '../jpn/content/SupporterApplyStep1Content';
import Step2Contents from '../jpn/content/SupporterApplyStep2Content';
import Step3Contents from '../jpn/content/SupporterApplyStep3Content';
import Step4Contents from '../jpn/content/SupporterApplyStep4Content';
import { useSupporterApplyData } from '../SupporterApplyProvider';

interface Section1FormData {
  supporter_question_vos: SupporterQuestionOptionVos[];
}

interface Section2FormData {
  files: string[];
}

function dataURLtoBlob(dataURL: string) {
  const arr = dataURL.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/** Contents 영역 */
export default function ClientApplyContents() {
  const { fetch, isLoading } = useSupporterRegisterAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { changeQuery } = useQueryDispatch();
  const { supporterQuestionData, postFileInfo, userCountryCode } = useSupporterApplyData();
  const { step = 1 } = useQueryState<{ step: string | number }>();

  const defaultValues: { supporter_question_vos: SupporterQuestionOptionVos[]; files: string[] } =
    useMemo(
      () => ({
        files: [],
        supporter_question_vos:
          supporterQuestionData?.map((essential) => ({
            question_id: essential.question_id, //질문 번호
            question_type: essential.question_type, //질문유형
            question_answer: null, //질문 유형이 라디오일 경우
            question_answers: [], //질문 유형이 체크박스일 경우
            question_context: essential.question_type === 'DROPBOX' ? userCountryCode : '', //질문 유형이 텍스트, 드롭박스일 경우
            required_option: essential?.required_option,
            require: essential.required,
          })) ?? [],
      }),
      [supporterQuestionData, userCountryCode]
    );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const validateSection1 = (formData: Section1FormData): boolean => {
    if (
      // 2-1 문항 pc가 체크된상태 15,16,17,18 text가 비었을때 (하나만체크해도동일함))
      formData.supporter_question_vos[10]?.question_answers?.['10'] &&
      formData.supporter_question_vos[15].required_option === 'PCDETAIL'
    ) {
      if (formData.supporter_question_vos[15].question_context === '') {
        return true;
      }
    }

    return formData.supporter_question_vos.some((item) => {
      if (item.question_type === 'RADIO' && item.require === 1 && item.question_answer === null) {
        return true;
      } else if (item.question_type === 'CHECKBOX' && item.require === 1) {
        const checkboxValidation = item.question_answers?.some((answer) => answer === true);
        if (!checkboxValidation) {
          return true;
        }
      }
      return false;
    });
  };

  const section1Submit = (formData: Section1FormData) => {
    if (validateSection1(formData)) {
      enqueueSnackbar('require answer', { variant: 'error' });
    } else {
      changeQuery({ step: 2 });
    }
  };

  const section2Submit = (formData: Section2FormData) => {
    if (!formData.files || formData.files.length === 0) {
      enqueueSnackbar('Attachments Image Required', { variant: 'error' });
    } else {
      changeQuery({ step: 3 });
    }
  };

  const section3Submit = async (formData: SupporterFormData) => {
    const supporterQuestions = formData?.supporter_question_vos?.map((item) => {
      if (item.question_type === 'CHECKBOX') {
        return {
          ...item,
          question_answers: Object.keys(item.question_answers).filter(
            (key) => item.question_answers[Number(key)]
          ),
        };
      } else {
        return item;
      }
    });

    const postFormData = new FormData();
    postFileInfo.forEach((file) => postFormData.append('files', dataURLtoBlob(file)));

    const request_body = {
      country: formData?.country,
      supporter_info: {
        discord_id: formData.discord_id,
        fullname: formData.fullname,
        payment_email: formData.payment_email,
        payment_info: formData.payment_info,
      },
      supporter_answers: supporterQuestions,
    };

    console.log('request_body', request_body);

    postFormData.append(
      'request_body',
      new Blob([JSON.stringify(request_body)], { type: 'application/json' })
    );

    const response = await fetch(postFormData);

    if (response?.status === 200 && response?.data?.result?.code === 0) {
      changeQuery({ step: 4 });
    } else if (response?.status === 200 && response?.data?.result?.code === 999001) {
      enqueueSnackbar('This account has applied for the program', { variant: 'error' });
    } else {
      enqueueSnackbar('Server Error occur', { variant: 'error' });
    }
  };

  const onSubmit = (data: SupporterFormData) => {
    if (step === '1') {
      section1Submit(data);
    } else if (step === '2') {
      section2Submit(data);
    } else if (step === '3') {
      section3Submit(data);
    }
  };

  return (
    <Box width="1200px" mb="40px" p="60px" sx={{ background: (theme) => theme.palette.gray[80] }}>
      <Box textAlign="center">
        <Typography variant="h3" mb="20px">
          Apply for a G.Round Supporters
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {Number(step) < 4 ? 'Please enter your details to register as a supporter.' : null}
        </Typography>
        <Divider sx={{ margin: '40px 0 40px' }} />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(onSubmit)} autoComplete="off">
          {step === 1 || step === '1' ? <Step1Contents /> : null}
          {step === '2' ? <Step2Contents /> : null}
          {step === '3' ? <Step3Contents isLoading={isLoading} /> : null}
        </form>
        {step === '4' ? <Step4Contents /> : null}
      </FormProvider>
    </Box>
  );
}
