import { Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useLauncherDownloadRecordAPI } from 'api/launcher/launcherDownloadAPI';
import { GameFile } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import HowToPlayControlGuide from './HowToPlayControlGuide';
import HowToPlayGridList from './HowToPlayGridList';

interface Props {
  gamefileData: GameFile;
}

export default function HowtoPlayGeneral({ gamefileData }: Props) {
  const { id = '' } = useParams<{ id: string }>();
  const { fetch } = useLauncherDownloadRecordAPI();

  const contents = [...gamefileData.guide_contents]
    .sort((a, b) => a.seq - b.seq)
    .map((guide, index) => ({
      id: index + 1,
      title: guide.content,
      image: guide.file_url,
    }));

  const handleClickDownloadLauncher = () => {
    // launcher download 횟수 기록
    fetch({ game_id: id ?? null });
    eventTracker('gamedetail_howtoplay_download_launcher', { event_label: id });
  };

  return (
    <>
      {Array.isArray(gamefileData.game_control_guides) &&
        gamefileData.game_control_guides.length > 0 && (
          <HowToPlayControlGuide game_control_guides={gamefileData.game_control_guides} />
        )}
      <Typography variant="h5" marginBottom="40px">
        Install Guide
      </Typography>
      <Typography variant="body1" marginBottom="4px">
        *If you don’t have the launcher installed, go to the website and download it first
      </Typography>
      <Link
        variant="body2"
        color="primary.main"
        href={gamefileData.main_url}
        target="_self"
        rel="noopener noreferrer"
        onClick={handleClickDownloadLauncher}
      >
        Download Launcher &gt;
      </Link>
      <HowToPlayGridList contentList={contents} />
    </>
  );
}
