import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { menu = '' } = useParams();
  const prevMenuRef = useRef<string | null>(null);

  useEffect(() => {
    // game detail 내에서 URL이 변경된 경우를 제외하고 모두 scroll to top
    if (prevMenuRef.current !== 'detail' || menu !== 'detail') {
      window.scrollTo(0, 0);
    }
    prevMenuRef.current = menu;
  }, [menu, pathname]);

  return null;
}
