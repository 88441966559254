import { Divider, styled } from '@mui/material';

export const PlayGameItemRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: 20,
});

export const PlayGameDivider = styled(Divider)({
  margin: '20px 0',
});
