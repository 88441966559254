import { StyledComponent } from '@emotion/styled';
import { numberWithCommas } from '@ground/tools';
import { Box, ButtonBase, ButtonBaseProps, Skeleton, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, LinkProps } from 'react-router-dom';

import { ImageBox } from 'atoms';
import eventTracker from 'utils/eventTracker';

const PREFIX = 'GStoreItem';

const classes = {
  container: `${PREFIX}-container`,
  productContent: `${PREFIX}-productContent`,
};

interface StyledProps {
  isMerchandise?: boolean;
}

const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isMerchandise',
})<StyledProps>(({ isMerchandise }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: '5px',
    background: '#000',
  },
  [`& .${classes.productContent}`]: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: isMerchandise ? '48px 30px 30px' : '46px 30px 30px',
    background: isMerchandise
      ? 'linear-gradient(rgba(38, 38, 38, 0), rgba(38, 38, 38, 1))'
      : 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))',
    borderRadius: '5px',
  },
})) as StyledComponent<ButtonBaseProps, StyledProps & LinkProps>;

interface IProps {
  productName: string;
  price: number;
  imageUrl: string;
  to: string;
  isLoading?: boolean;
  hasNoPriceDisplay?: boolean;
  isMerchandise?: boolean;
}

/**
 * @description Ground > GStore 에서 상품 카드로 쓰이는 컴포넌트 이다.
 * @param {string} productName : 상품 이름
 * @param {number} price : 상품 가격
 * @param {string} imageUrl : 상품 이미지 주소
 * @param {string} to : 링크 주소
 * @param {boolean} isLoading : 로딩 중 상태 - 스켈레톤
 * @param {boolean} hasNoPriceDisplay : 가격 표시 유무 true - 보이지 않음, false : 보임
 * @param {boolean} isMerchandise : merchandise 용 gstoreItem 유무 - overlay linear-gradient가 다름
 */
export default function GStoreItem({
  productName,
  price,
  imageUrl,
  to,
  isLoading,
  hasNoPriceDisplay,
  isMerchandise,
}: IProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const [isHover, setIsHover] = useState(false);

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height="100%"
      sx={{ background: 'rgba(100, 100, 100, 0.3)', borderRadius: '5px' }}
    />
  ) : (
    <StyledButtonBase
      className={classes.container}
      LinkComponent={Link}
      to={to}
      onClick={() => eventTracker('gstore_selectproduct', { event_label: to })}
      ref={ref}
      isMerchandise={isMerchandise}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {inView && (
        <ImageBox
          src={imageUrl}
          alt="product"
          overlay
          width="100%"
          height="100%"
          sx={{
            borderRadius: '5px',
            objectFit: 'cover',
            filter: isHover ? 'brightness(1.4)' : 'unset',
          }}
        />
      )}

      <Box className={classes.productContent}>
        <Typography variant="subtitle1" color="text.primary" sx={{ wordBreak: 'break-word' }}>
          {productName || 'No Product Name'}
        </Typography>
        {!hasNoPriceDisplay && (
          <Typography variant="body2" mt="4px" color="text.secondary">
            {numberWithCommas(price)} G.P
          </Typography>
        )}
      </Box>
    </StyledButtonBase>
  );
}
