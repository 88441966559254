import { FormDropdown } from '@ground/ui';

import { genderOptions } from 'utils/commonData';

export default function PersonalDetailsGender() {
  return (
    <FormDropdown
      label="Gender"
      name="sex"
      placeholder="Select Gender"
      options={genderOptions}
      rules={{ required: true }}
      withBackground
    />
  );
}
