import { Typography, Box } from '@mui/material';
import { format } from 'date-fns';

import { GameCardProps } from 'types/GameCardTypes';
import { ReviewGauge } from '../../../components/ReviewGauge';

export default function GameCardBigLowerArea({
  studio_name,
  title,
  started_at,
  ended_at,
  isHover = false,
  remainingText,
  progress_rate,
  progressed_review,
  required_review,
}: GameCardProps) {
  const hasGauge = typeof required_review === 'number' && typeof progressed_review === 'number';

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: isHover ? 'gray.70' : 'gray.80',
      }}
    >
      <Typography
        variant="subtitle1"
        color="text.primary"
        noWrap
        sx={{ opacity: remainingText ? 0.3 : undefined }}
      >
        {title}
      </Typography>
      <Box display="flex" justifyContent="space-between" gap="40px">
        <Box flexGrow={1}>
          <Typography
            variant="subtitle3"
            color={isHover ? 'text.primary' : 'text.secondary2'}
            noWrap
            sx={{ marginTop: '6px', opacity: remainingText ? 0.3 : undefined }}
          >
            {studio_name}
          </Typography>
          <Typography
            variant="subtitle3"
            color={isHover ? 'text.primary' : 'text.secondary2'}
            sx={{ marginTop: '6px', opacity: remainingText ? 0.3 : undefined }}
          >
            Period: {format(new Date(started_at), 'MMM dd, yyyy')} -{' '}
            {format(new Date(ended_at), 'MMM dd, yyyy')}
          </Typography>
        </Box>
        {hasGauge && (
          <Box sx={{ flexBasis: '245px', flexShrink: 0, alignSelf: 'flex-end' }}>
            <ReviewGauge goal={required_review} count={progressed_review} rate={progress_rate} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
