import { Box, BoxProps, SxProps, useTheme } from '@mui/material';
import { Ref, forwardRef } from 'react';

import GIconBase from './GIconBase';
import GIconList from './GIconList';

export interface GIconProps extends Omit<BoxProps, 'ref'> {
  disabled?: boolean;
  /** 아이콘 이름 */
  iconName: keyof typeof GIconList;
  /** Basic 아이콘의 크기 */
  iconSize: 16 | 24 | 32 | 48 | number;
  /** 아이콘 전체 사이즈 */
  size?: number;
  /** 아이콘 세로 사이즈. 1:1 비율이 아닌 경우 사용 */
  height?: number;
  /** 아이콘 배경 색상 */
  backgroundColor?: string;
  /** 아이콘 내부 색상 */
  iconColor?: string;
  /** 아이콘 내부 투명도 */
  iconOpacity?: number;
  /** 아이콘 배경 스타일 */
  variant?: 'circle' | 'rounded' | 'outlined';
  sx?: SxProps;
}

const GIcon = forwardRef((props: GIconProps, ref: Ref<HTMLDivElement>) => {
  const { palette } = useTheme();
  const {
    disabled,
    iconName,
    iconSize,
    size = iconSize,
    height,
    backgroundColor,
    iconOpacity,
    iconColor = palette.base.dimWhite,
    variant,
    sx,
    ...others
  } = props;

  const opacity = disabled ? 0.2 : iconOpacity ?? 1;

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: size,
        height: height ?? size,
        color: iconColor,
        backgroundColor: backgroundColor,
        borderRadius:
          variant === 'circle' || variant === 'outlined'
            ? '50%'
            : variant === 'rounded'
            ? '3px'
            : undefined,
        ...(variant === 'outlined' && {
          border: `1px solid ${iconColor}`,
        }),
        opacity: opacity,
        ...sx,
      }}
      {...others}
    >
      <GIconBase icon={GIconList[iconName]} iconSize={iconSize} />
    </Box>
  );
});

export default GIcon;
