import { AutoResizeImage } from '@ground/tools';
import { Box, Typography, styled } from '@mui/material';
import ReactPlayer from 'react-player/youtube';

import { SurveyAttachType } from 'types/FormDataType';

const AttachImage = styled(AutoResizeImage)({
  display: 'block',
});

interface Props {
  type: 'NA' | 'IMAGE' | 'VIDEO';
  attaches: SurveyAttachType[];
}

export default function QuestionAttaches({ type, attaches }: Props) {
  return type === 'IMAGE' || type === 'VIDEO' ? (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent={attaches?.length === 1 ? 'center' : 'space-between'}
    >
      {attaches?.map((attach) => (
        <Box
          key={attach.id}
          textAlign="center"
          maxWidth={attaches?.length === 1 ? 888 : 430}
          marginBottom="32px"
        >
          {type === 'IMAGE'
            ? attach.file_url && (
                <AttachImage
                  src={attach.file_url}
                  alt={`attach-${attach.seq}`}
                  resizeWidth={attaches.length === 1 ? 888 : 430}
                  resizeHeight={attaches.length === 1 ? undefined : 279}
                  format="webp"
                />
              )
            : attach.video_url && (
                <ReactPlayer
                  url={attach.video_url}
                  controls
                  width={attaches.length === 1 ? 888 : 430}
                  height={attaches.length === 1 ? 500 : 279}
                />
              )}
          <Typography color="text.secondary" variant="body2" marginTop="20px">
            {attach.description}
          </Typography>
        </Box>
      ))}
    </Box>
  ) : null;
}
