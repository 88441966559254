import { FormInput } from '@ground/ui';
import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useAxiosWithAuth, useDialog, useError } from 'hooks';
import eventTracker from 'utils/eventTracker';

interface PromotionFormData {
  promotionCode: string;
}

interface Props {
  reload: () => void;
}

export default function PromotionCode({ reload }: Props) {
  const { showDialog, showSimpleDialog } = useDialog();
  const { showError } = useError();
  const methods = useForm<PromotionFormData>({
    defaultValues: { promotionCode: sessionStorage.getItem('referralcode') ?? '' },
  });
  const { isLoading: sumbmitLoading, refetch: fetchPromotion } = useAxiosWithAuth(
    'users/promotions',
    { method: 'POST' },
    true
  );

  const onSubmit = async (formData: PromotionFormData) => {
    const response = await fetchPromotion({ params: formData });
    if (response.status === 200 && response.data?.result_code === 'OK') {
      showDialog({
        title: 'Referral Code',
        body: 'Referral code registration is complete.',
        primaryButton: { text: 'OK' },
        onClose: () => reload(),
      });
    } else if (response.data?.result_code === '400') {
      showSimpleDialog('Referral Code', 'Invalid referral code.');
    } else if (response.data?.result_code === '456') {
      showSimpleDialog('Referral Code', 'Already registered referral code.');
    } else if (response.data?.result_code === '461') {
      showSimpleDialog('Referral Code', 'Cannot register your own referral code.');
    } else {
      showError(response, 'Referral Code');
    }
    eventTracker('profile_profile_referralcode_register');
  };

  return (
    <div>
      <Typography variant="h5" marginBottom="20px">
        Referral Code
      </Typography>
      <Paper sx={{ padding: '40px', borderRadius: '5px' }}>
        <FormProvider {...methods}>
          <DevTool control={methods.control} placement="top-left" />
          <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" color="text.secondary">
                You can use Referral Code and show support for another user!
              </Typography>
              <div>
                <FormInput
                  name="promotionCode"
                  placeholder="Got a referral code? Copy it here!"
                  withBackground
                  sx={{ width: 428, height: 40, marginRight: '8px' }}
                  rules={{
                    required: { value: true, message: 'enter referral code' },
                    minLength: { value: 10, message: 'enter at least 10 characters' },
                    maxLength: { value: 20, message: 'enter at most 20 characters' },
                  }}
                />
                <LoadingButton
                  loading={sumbmitLoading}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Register
                </LoadingButton>
              </div>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </div>
  );
}
