import React, { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { SectionContainer } from 'components';
import { Carousel } from 'elements';
import { ClipCardLarge } from 'elements/NewClipCard';
import { useLog } from 'utils/userLog';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';
import { FeatureBottom } from './ClipsPopup/Area/ClipBottomArea';
import { ClipUpperArea } from './ClipsPopup/Area/ClipUpperArea';
import { useClipPopupData } from './ClipsPopup/ClipPopupProvider';

interface IProps {
  title: string;
  dataList: {
    game_id: number;
    id: number;
    link: string;
    thumbnail_url: string;
    title: string;
    created_at: string;
    description: string;
    additional?: ReactElement;
  }[];
  count?: number;
  type: 'feature';
}

const ClipFeatureListCardLarge: React.FC<IProps> = ({ dataList, count = 4, type, title }) => {
  const { gxcTag } = useLog();
  const { dataIndex, setDataIndex } = useClipPopupData();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryGameId = searchParams.get('gameId') || '';
  const cardType = searchParams.get('type');
  const [gameId, setGameId] = useState(Number(queryGameId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (gameId: number) => {
    setGameId(gameId);
    setDataIndex(gameId);
    setSearchParams({ type: 'feature', gameId: String(gameId) });
    setShowModal(true);
    gxcTag('view', {
      area: 'featured_popup',
      parameters: {
        clips_id: gameId,
      },
    });
  };

  const closeModal = () => {
    setSearchParams({});
    setShowModal(false);
    gxcTag('view', {
      area: 'featured_popup',
      ui: 'close_x',
      parameters: {
        clips_id: gameId,
      },
    });
  };

  const isOpen = showModal || !!(String(gameId) && cardType === 'feature');
  const clipUpper = dataList;
  const clipBottom = dataList[dataIndex];

  return (
    <>
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="608px"
          open={isOpen}
          onClose={closeModal}
          body={
            <>
              <ClipUpperArea initialSlide={gameId} clipsData={clipUpper} disableThumbnail />
              <FeatureBottom
                title={clipBottom.title}
                created_at={clipBottom.created_at}
                description={clipBottom.description}
                additional={clipBottom?.additional}
              />
            </>
          }
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      <SectionContainer title={title}>
        <Carousel
          width={1200}
          name="featured-clips"
          hasNavigation={count > 2}
          swiperOption={{ slidesPerView: 2, spaceBetween: 20, allowTouchMove: false }}
          navigationPositionTop={166}
          onClickLeftArrow={() => gxcTag('click', { area: 'featured', ui: 'left_arrow' })}
          onClickRightArrow={() => gxcTag('click', { area: 'featured', ui: 'right_arrow' })}
        >
          {dataList?.map((element) => (
            <SwiperSlide key={element.id}>
              <ClipCardLarge
                videoTitle={element.title}
                videoDescription={element.description}
                cardThumbnailUrl={element.thumbnail_url}
                onCardClick={() => {
                  gxcTag('click', {
                    area: 'featured',
                    ui: 'clips_card',
                    parameters: {
                      clips_id: element.id,
                    },
                  });

                  openModal(element.game_id);
                }}
                type={type}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      </SectionContainer>
    </>
  );
};

export default ClipFeatureListCardLarge;
