import { Paper } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import { GameStatusType } from 'types/GameCommonTypes';
import AuthRoute from 'views/main/AuthRoute';
import { useGetDetailData } from '../DetailProvider';
import { AdditionalReviewBox } from './AdditionalReviewBox';
import CommentInGameDetailProvider from './CommentProvider';
import CommentTabs from './CommentTabs';
import MyCommentsMain from './my-activities/MyCommentsMain';
import MyCommentsPrivate from './my-activities/MyCommentsPrivate';
import ReviewsMain from './reviews/ReviewsMain';
import ReviewsMainAdmin from './reviews/ReviewsMainAdmin';
import ReviewsMainPrivate from './reviews/ReviewsMainPrivateAdmin';
import ReviewsMainPrivateUser from './reviews/ReviewsMainPrivateUser';
import StudentReviewsMain from './student-reviews/StudentReviewsMain';

export default function Comment() {
  const { data: gameDetailData, isStudio, isAdmin } = useGetDetailData();

  const isPrivate = typeof gameDetailData?.private_test === 'number';

  return (
    <CommentInGameDetailProvider>
      {(isAdmin || isStudio) && !isPrivate && gameDetailData?.state === GameStatusType.CLOSED && (
        <AdditionalReviewBox />
      )}
      <Paper sx={{ padding: '32px 40px 40px 40px' }}>
        <Routes>
          <Route path=":reviewTab" element={<CommentTabs />} />
        </Routes>
        <Routes>
          <Route
            path="reviews"
            element={
              isPrivate ? (
                isAdmin || isStudio ? (
                  <ReviewsMainPrivate />
                ) : (
                  <ReviewsMainPrivateUser />
                )
              ) : isAdmin || isStudio ? (
                <ReviewsMainAdmin />
              ) : (
                <ReviewsMain />
              )
            }
          />
          <Route
            path="my-activities"
            element={
              <AuthRoute>{isPrivate ? <MyCommentsPrivate /> : <MyCommentsMain />}</AuthRoute>
            }
          />
          <Route
            path="student-reviews"
            element={
              <AuthRoute>
                <StudentReviewsMain />
              </AuthRoute>
            }
          />
          <Route path="*" element={<Navigate to="reviews" replace />} />
        </Routes>
      </Paper>
    </CommentInGameDetailProvider>
  );
}
