import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import deepEqual from 'deep-equal';
import { useSelector } from 'react-redux';
import { iRootState } from 'store';
import { convertUrlEnv } from 'utils/common';

const { CancelToken } = axios;

export interface AxiosResponseDataPagination {
  total_pages: number;
  total_elements: number;
  current_page: number;
  current_elements: number;
}

export interface AxiosResponseData<P = any> {
  result?: { code: number; msg: string };
  transaction_time: string;
  result_code: string;
  description: string;
  data: P;
  pagination: AxiosResponseDataPagination | null;
}

interface AxiosProps<P> {
  response: AxiosResponse<AxiosResponseData<P>> | null;
  error: AxiosError | null;
  isLoading: boolean;
}

export interface AxiosState<P = any> {
  data: AxiosResponseData<P> | undefined;
  response: AxiosResponse<AxiosResponseData<P>> | null;
  error: AxiosError | null;
  isLoading: boolean;
  refetch: (
    config?: AxiosRequestConfig | undefined
  ) => Promise<AxiosResponse<AxiosResponseData<P>>>;
}

// 인증이 필요한 통신
export function useAxiosWithAuth<P = any>(
  url: string,
  axiosConfig: AxiosRequestConfig = {},
  manualFetch: boolean = false
) {
  const { access_token } = useSelector((state: iRootState) => state.login);

  return useAxios<P>(
    convertUrlEnv(url),
    {
      ...axiosConfig,
      headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
    },
    manualFetch
  );
}

/**
 * Params
 * @param {string} url - API 주소
 * @param {AxiosRequestConfig} [axiosConfig={}] - (optional) config 값(method, params, data, ...)
 * @param {boolean} [manualFetch=false] - (optional) 수동 실행
 */

export function useAxios<P = any>(
  url: string,
  axiosConfig: AxiosRequestConfig = {},
  manualFetch: boolean = false
): AxiosState<P> {
  const [axiosState, setAxiosState] = useState<AxiosProps<P>>({
    response: null,
    error: null,
    isLoading: !manualFetch,
  });
  const configRef = useRef(axiosConfig);
  const manualFetchRef = useRef(manualFetch); // 초기값 설정 후 변경 불가

  // config 값이 변경되었을 때 기존 값과 비교하여 변경되었을 때 다시 통신하도록 하기위한 변수
  const configEqual: boolean = useMemo(
    () => deepEqual(configRef.current, axiosConfig, { strict: true }),
    [axiosConfig]
  );
  // config 값이 바뀌었으면 새로운 값으로 교체
  if (!configEqual) configRef.current = axiosConfig;

  // 비동기 통신 후 데이터를 가져와서 써준다. URL이 바뀌면 다시 통신
  const fetch = useCallback(
    async (config: AxiosRequestConfig) => {
      setAxiosState((state) => ({ ...state, isLoading: true }));
      let response = null;
      try {
        response = await axios({
          url,
          ...config,
          cancelToken: CancelToken.source().token,
        });
        setAxiosState({ response, error: null, isLoading: false });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled by cleanup: ', error.message);
        } else {
          setAxiosState({ error, response: null, isLoading: false });
          console.log(error.message);
        }
        return error.message;
      }

      return response;
    },
    [url]
  );

  // 수동으로 다시 호출하는 함수
  const refetch = useCallback(
    async (config?: AxiosRequestConfig) => {
      // 추가된 config 값과 병합하여 반영
      configRef.current = { ...configRef.current, ...config };
      return await fetch(configRef.current);
    },
    [fetch]
  );

  // url, config 값이 변경되었을 때 호출됨
  useEffect(() => {
    // 수동호출인 경우 useEffect를 수행하지 않음
    if (manualFetchRef.current) return;

    fetch(configRef.current);

    return () => {
      CancelToken.source().cancel('useEffect cleanup');
    };
  }, [fetch]);

  const { response, error, isLoading } = axiosState;
  const data = response?.data;

  return { data, response, error, isLoading, refetch };
}
