import { GIconButton } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useLauncherDownloadRecordAPI } from 'api/launcher/launcherDownloadAPI';
import { GameGuide } from 'components';
import { iRootState } from 'store';
import { groundDiscordChannel, launcherDownloadUrl } from 'utils/common';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import DiscordBanner from 'views/detail/banner/discord/DiscordBanner';

import welcomeBg from './welcome-bg.png';
import welcomeBg2x from './welcome-bg@2x.png';
import welcomeBg3x from './welcome-bg@3x.png';

export default function WelcomeDialog() {
  const { gxcTag } = useLog();
  const { state } = useLocation();

  const welcome = (state as { welcome?: boolean })?.welcome;
  const [open, setOpen] = useState(!!welcome);
  const name = useSelector((state: iRootState) => state.login.name);
  const { isLoading, fetch } = useLauncherDownloadRecordAPI();

  const handleClickLauncherDownload = async () => {
    gxcTag('click', { area: 'welcome_popup', ui: 'install_launcher' });

    // 런처 설치파일 다운로드
    window.open(launcherDownloadUrl, '_self', 'noopener noreferrer');

    // launcher download 횟수 기록
    fetch({});

    // button custom event
    eventTracker('signup_complete_download_launcher');
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={false}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      }}
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
      PaperProps={{ sx: { borderRadius: '8px' } }}
      scroll="body"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1,
        }}
      >
        <GIconButton
          iconName="close"
          iconSize={24}
          size={46}
          onClick={() => {
            gxcTag('click', { area: 'welcome_popup', ui: 'close_x' });

            setOpen(false);
          }}
        />
      </Box>
      {/* 상단 영역 */}
      <Box position="relative">
        <img
          src={welcomeBg}
          srcSet={`${welcomeBg}, ${welcomeBg2x} 2x, ${welcomeBg3x} 3x`}
          style={{ backgroundColor: 'black', display: 'block', filter: 'brightness(0.3)' }}
          width={1200}
          height={248}
          alt="welcome background"
        />
        <Stack
          sx={{
            position: 'absolute',
            alignItems: 'center',
            top: 0,
            left: 0,
            right: 0,
            marginTop: '60px',
            textAlign: 'center',
          }}
        >
          <Typography color="text.primary" variant="h5">
            Welcome to G.Round!
          </Typography>
          <Typography color="text.secondary" variant="body2" mt="12px">
            Hi,{' '}
            <Typography component="span" color="text.primary">
              {name || 'unknown'}
            </Typography>
            ! You are ready to test games!
            <br />
            Let's install the launcher so you can start playing your free games!
          </Typography>
          <LoadingButton
            color="primary"
            loading={isLoading}
            onClick={handleClickLauncherDownload}
            variant="contained"
            sx={{ marginTop: '24px' }}
          >
            Install G.Round Launcher
          </LoadingButton>
        </Stack>
      </Box>
      {/* 하단 영역 */}
      <Stack
        alignItems="center"
        bgcolor="gray.80"
        padding="60px 40px 40px"
        spacing="40px"
        width="1200px"
      >
        <Typography color="text.primary" variant="h5">
          Playtest games and help developers!
        </Typography>
        <GameGuide spacing="20px" />
        <DiscordBanner
          discord_channel={groundDiscordChannel}
          type="comment"
          onClick={() => gxcTag('click', { area: 'welcome_popup', ui: 'discord' })}
        />
      </Stack>
    </Dialog>
  );
}
