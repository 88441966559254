import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { YoutubePlayer } from 'components';
import { Carousel } from 'elements';
import { CommonContent } from 'types/DigitalGoodsType';

const WIDTH = 1200;
const HEIGHT = 480;

interface Props {
  contents: CommonContent[];
}

export default function ProductSlide({ contents }: Props) {
  const [pause, setPause] = useState(false);

  const handleTransitionStart = () => {
    setPause(true);
  };
  const handleTransitionEnd = () => {
    setPause(false);
  };

  return contents ? (
    <Carousel
      width={WIDTH}
      height={HEIGHT}
      name="store-detail-contents"
      bulletWidth={48}
      hasPagination
      hasNavigation
      navBtnIconType="arrow"
      paginationPosition="INSIDE"
      navBtnIconWidth={48}
      navBtnRowPosition={-78}
      onSlideChangeTransitionStart={handleTransitionStart}
      onSlideChangeTransitionEnd={handleTransitionEnd}
    >
      {contents?.map((content) =>
        content.media_type === 'IMAGE' ? (
          <SwiperSlide key={content.seq} style={{ width: WIDTH, height: HEIGHT }}>
            {/* 스토어 이미지는 autoResize 불가 */}
            <img src={content.url} alt="cover" width={WIDTH} height={HEIGHT} />
          </SwiperSlide>
        ) : (
          <SwiperSlide key={content.seq}>
            <YoutubePlayer url={content.url} globalPause={pause} height={HEIGHT} width={WIDTH} />
          </SwiperSlide>
        )
      )}
    </Carousel>
  ) : (
    <Skeleton
      width={WIDTH}
      height={HEIGHT}
      animation="wave"
      variant="rectangular"
      sx={{ marginBottom: '45px', background: '#88919e', opacity: 0.3 }}
    />
  );
}
