import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { Avatar } from '../../atoms';
import { FormInput } from '../../form';

export interface ReplyPostFormData {
  comment: string;
}

interface ReplyPostProps {
  userAvatarUrl?: string;
  onSubmit: (formData: ReplyPostFormData, methods: UseFormReturn<ReplyPostFormData>) => void;
  inputMode: boolean;
  onFocusInput: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlurInput: () => void;
  loadingReplyPost: boolean;
  isLogin: boolean;
}

export default function ReplyPost({
  userAvatarUrl,
  onSubmit,
  inputMode,
  onFocusInput,
  onBlurInput,
  loadingReplyPost,
  isLogin,
}: ReplyPostProps) {
  const methods = useForm<ReplyPostFormData>({ defaultValues: { comment: '' } });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: 'gray.80',
        padding: '20px',
        borderRadius: '5px',
      }}
    >
      <Box height="28px" margin="6px 20px 6px 0">
        <Avatar size="small" src={userAvatarUrl ?? ''} alt="user avatar" />
      </Box>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => onSubmit(data, methods))}
          style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}
        >
          <FormInput
            name="comment"
            rules={{ required: true, minLength: 1, maxLength: 3000 }}
            sx={{
              '.MuiInputBase-input': {
                '&:active': { transition: 'height 150ms' },
                '&:not(:focus)': { transition: 'height 150ms' },
              },
            }}
            minRows={inputMode ? 3 : 1}
            autoComplete="off"
            placeholder={
              isLogin
                ? 'If you have any questions or answers related to the comment, feel free to reply.'
                : 'Sign in if you want to write comments'
            }
            required
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            withBackground
            fullWidth
            multiline
          />
          <LoadingButton
            // disabled={!commentText?.trim()}
            type="submit"
            color="primary"
            variant="contained"
            loading={loadingReplyPost}
            sx={{ marginLeft: '8px' }}
          >
            Post
          </LoadingButton>
        </form>
      </FormProvider>
    </Box>
  );
}
