import { useSelector } from 'react-redux';

import useSWRFetch from 'api/useSWRFetch';
import { iRootState } from 'store';
import { GStoreUserStoreType } from 'types/GStoreTypes';
import { storeApiUrl } from './gstoreAPI';

export function useGetUserStoreDataAPI() {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const url = isLogin ? `${storeApiUrl}/api/v2/orders/usage` : null;

  return useSWRFetch<GStoreUserStoreType>({ key: url, useToken: true });
}
