import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import { useFetch } from 'api';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import { PlayGameDivider, PlayGameItemRow } from './PlayGameComponents';
import PlayGameDialogBodyItemGameKey from './PlayGameDialogBodyItemGameKey';

interface PlayGameDialogBodySelfProps {
  data?: GameDetailProps;
  reload: () => void;
}

export default function PlayGameDialogBodySelf({ data, reload }: PlayGameDialogBodySelfProps) {
  const gameFile = data?.game_files?.[0];
  const platform = gameFile?.platform;

  const { isLoading: btnLoading, fetch } = useFetch(
    `games/${data?.id}/${platform}/download`,
    {},
    true
  );

  const handleClickBtnMain = async () => {
    // main 버튼 클릭 시 게임을 플레이 한 것으로 판단하고 download api 호출
    if (platform && !data?.download) {
      const response = await fetch();
      if (response?.status === 200 && response.data?.result_code === 'OK') {
        // 데이터 갱신
        reload();
      }
    }

    window.open(gameFile && gameFile.main_url, '_blank', 'noopener noreferrer');
    data?.id && eventTracker('playgamepopup_self_body', { event_label: data.id });
  };

  const handleClickBtnSub = async () => {
    // sub 버튼 클릭 시 게임을 플레이 한 것으로 판단하고 download api 호출
    if (!data?.download) {
      const response = await fetch();
      if (response?.status === 200 && response.data?.result_code === 'OK') {
        // 데이터 갱신
        reload();
      }
    }

    window.open(gameFile && gameFile.sub_url, '_blank', 'noopener noreferrer');
    data?.id && eventTracker('playgamepopup_self_gamebuild', { event_label: data.id });
  };

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {gameFile?.self_info_msg}
      </Typography>
      <Box marginTop="20px" padding="20px" bgcolor="gray.60">
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            {gameFile?.main_msg}
          </Typography>
          <Button
            disabled={gameFile?.main_off}
            onClick={handleClickBtnMain}
            variant="ghost"
            size="small"
          >
            {gameFile?.main_name}
          </Button>
        </PlayGameItemRow>
        <PlayGameDivider />
        {data && gameFile?.has_game_key && (
          <>
            <PlayGameDialogBodyItemGameKey data={data} />
            <PlayGameDivider />
          </>
        )}
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            {gameFile?.sub_msg}
          </Typography>
          <LoadingButton
            loading={btnLoading}
            disabled={gameFile?.sub_off}
            onClick={handleClickBtnSub}
            variant="contained"
            size="small"
          >
            {gameFile?.sub_name}
          </LoadingButton>
        </PlayGameItemRow>
      </Box>
    </>
  );
}
