import { useParams } from 'react-router-dom';
import { Box, ButtonBase } from '@mui/material';

import { ImageBox } from 'atoms';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from 'views/detail/DetailProvider';

export default function DetailLineBanner() {
  const { id = '' } = useParams<{ id: string }>();
  const { detailLineBannerData } = useGetDetailData();

  const findBannerData = detailLineBannerData?.data.find((item) => item.target_id === Number(id));

  return detailLineBannerData && detailLineBannerData?.data.length > 0 && findBannerData ? (
    <Box marginTop="45px">
      {findBannerData.image_link_url ? (
        <ButtonBase
          href={findBannerData.image_link_url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            eventTracker('gamedetail_banner', { event_label: id });
          }}
        >
          <ImageBox
            src={findBannerData.image_url}
            alt={`${findBannerData.title} banner`}
            resize
            resizeWidth={1200}
            resizeHeight={76}
            format="webp"
            sx={{ borderRadius: '5px' }}
          />
        </ButtonBase>
      ) : (
        <ImageBox
          src={findBannerData.image_url}
          alt={`${findBannerData.title} banner`}
          resize
          resizeWidth={1200}
          resizeHeight={76}
          format="webp"
          sx={{ borderRadius: '5px' }}
        />
      )}
    </Box>
  ) : null;
}
