import { numberWithCommas } from '@ground/tools';
import { Box, Button, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isNumber } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { useGetDigitalProductHistoryInfiniteAPI } from 'api/gstore/gstoreAPI';
import eventTracker from 'utils/eventTracker';
import { convertDigitalOrderStatusToWord } from './GStoreProfileOrderUtil';
import ProfileOrderDataReactTable from './orderDataReactTable/ProfileOrderDataReactTable';

const headers: Column[] = [
  { accessor: 'dateTime', Header: 'Date/Time' },
  { accessor: 'product', Header: 'Product' },
  { accessor: 'quantity', Header: 'Quantity' },
  { accessor: 'price', Header: 'Price (G.P)' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'detailBtn', Header: ' ' },
];

export default function GStoreOrderStatusDigitalProductTableContainer() {
  const navigate = useNavigate();
  const { data = [], isLoading, setSize } = useGetDigitalProductHistoryInfiniteAPI();

  const [ref, inView] = useInView({ threshold: 0.1 });
  const orderHistoryData = useMemo(
    () =>
      data
        ?.map((data) => data?.data?.list)
        .filter((x) => x)
        ?.flat() || [],
    [data]
  );
  const paginationData = data[data?.length - 1]?.data?.pagination;

  const isNotDataLast = paginationData?.current_page < paginationData?.list_count;

  useEffect(() => {
    if (inView) {
      setSize((size: number) => {
        return size + 1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const cellData = useMemo(
    () =>
      Array.isArray(orderHistoryData)
        ? orderHistoryData.map((order) => ({
            dateTime: (
              <>
                <Typography variant="body2" color="text.secondary">
                  {order?.created_at ? format(new Date(order?.created_at), 'MMM d, yyyy') : ''}
                </Typography>
                <Typography variant="small2" color="text.secondary2" marginTop="4px" noWrap>
                  {order?.created_at ? `${format(new Date(order?.created_at), 'h:mm aa')}(PT)` : ''}
                </Typography>
              </>
            ),
            product: (
              <Stack direction="row" alignItems="center" spacing="24px">
                <Box
                  component="img"
                  //   src={order?.m}
                  src={order.shopProductDigital.main_media_url}
                  alt="product"
                  width={60}
                  height={48}
                  borderRadius="3px"
                />
                <Typography variant="body2" color="text.secondary">
                  {order?.shopProductDigital?.digital_goods_name}
                </Typography>
              </Stack>
            ),
            quantity: (
              <Typography variant="body2" color="text.secondary">
                1
              </Typography>
            ),
            price: (
              <Typography variant="body2" color="text.secondary">
                {isNumber(order?.shopProductDigitalOption?.point_price)
                  ? numberWithCommas(order?.shopProductDigitalOption?.point_price)
                  : ''}
              </Typography>
            ),
            status: (
              <>
                <Typography component="span" variant="body2" color="text.secondary">
                  {convertDigitalOrderStatusToWord(order?.status)}
                </Typography>
                <br />
                {order?.order_ship?.tracking_no && (
                  <Typography variant="body2" component="span" sx={{ textDecoration: 'underline' }}>
                    {order?.order_ship?.tracking_no}
                  </Typography>
                )}
              </>
            ),
            detailBtn: (
              <Button
                variant="ghost"
                size="small"
                onClick={() => {
                  eventTracker('profile_order_detail', { event_label: order.order_id });
                  navigate(`/profile/order/digital/${order?.order_id}`);
                }}
              >
                Order Detail
              </Button>
            ),
          }))
        : [],
    [navigate, orderHistoryData]
  );

  return (
    <ProfileOrderDataReactTable
      headers={headers}
      cellData={cellData}
      isLoading={isLoading}
      observedRef={isNotDataLast ? ref : undefined}
    />
  );
}
