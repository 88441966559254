import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useDialog } from 'hooks';
import { Dispatch } from 'store';
import { useLog } from 'utils/userLog';
import SignupVerificationContent from 'views/sign/verification/SignupVerificationContent';

export default function useVerificationDialog(
  isMobile?: boolean,
  onVerificationSuccess?: () => void
) {
  const { closeDialog, showDialog } = useDialog();
  const dispatch = useDispatch<Dispatch>();
  const { gxcTag } = useLog();

  const showVerificationDialog = useCallback(async () => {
    gxcTag('view', { area: 'code_verification_popup' });
    showDialog({
      title: 'Please Verify Your Email',
      body: (
        <Stack alignItems="center">
          <SignupVerificationContent
            isMobile={isMobile}
            type="dialog"
            onVerificationSuccess={() => {
              onVerificationSuccess?.();
              dispatch.login.updateLoginInfo({ verified: true });
              closeDialog();
            }}
          />
        </Stack>
      ),
      onClickClose: () => {
        gxcTag('click', { area: 'code_verification_popup', ui: 'close_x' });
      },
    });
  }, [closeDialog, dispatch.login, gxcTag, isMobile, onVerificationSuccess, showDialog]);

  return { showVerificationDialog };
}
