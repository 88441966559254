import { Route, Routes } from 'react-router-dom';

import AuthRoute from 'views/main/AuthRoute';
import { DetailProvider } from './DetailProvider';
import DetailMain from './DetailMain';
import { NotFound } from '..';
import AppSnackbarContainer from 'views/AppSnackbarContainer';
import { styled } from '@mui/material';

// Mobile 용 스낵바 - 현재 게임 디테일 페이지 게이지 컴포넌트에 쓰임.
const StyledAppSnackbarContainer = styled(AppSnackbarContainer)(({ theme }) => ({
  '& #notistack-snackbar': {
    padding: '10px 0px',
  },
  '&.SnackbarItem-variantInfo': {
    backgroundColor: theme.palette.blue.primary,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.button2,
  },

  marginBottom: '1px',
}));

export default function GameDetail() {
  return (
    <Routes>
      <Route
        path=":id/*"
        element={
          <AuthRoute>
            <DetailProvider>
              <StyledAppSnackbarContainer hideIconVariant preventDuplicate>
                <DetailMain />
              </StyledAppSnackbarContainer>
            </DetailProvider>
          </AuthRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
