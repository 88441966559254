import { Box, CircularProgress } from '@mui/material';

import { useSurveyData } from './SurveyProvider';
import SurveyList from './SurveyList';
import SurveyDiscordBanner from './SurveyDiscordBanner';

export default function SurveyPaper() {
  const { loading } = useSurveyData();

  return (
    <div>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" size={64} />
        </Box>
      ) : (
        <SurveyList />
      )}
      <SurveyDiscordBanner />
    </div>
  );
}
