import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface SectionContainerProps {
  sx?: SxProps;
  title: string;
  titleSideButton?: ReactNode;
  children: ReactNode;
  containerOptions?: ReactNode;
  divider?: boolean;
}

export default function SectionContainer({
  sx,
  title,
  titleSideButton,
  children,
  containerOptions,
  divider,
}: SectionContainerProps) {
  return (
    <Stack width="1200px" component="section" sx={sx}>
      {/* header */}
      <Stack gap="20px" mb={divider ? '60px' : '32px'}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* title */}
          <Box display="flex" columnGap="12px" alignItems="center">
            <Typography variant="title">{title}</Typography>
            {titleSideButton}
          </Box>
          {containerOptions}
        </Box>
        {/* divider */}
        {divider && <Divider />}
      </Stack>
      {/* content */}
      {children}
    </Stack>
  );
}
