import {
  styled,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

const PREFIX = 'ArtDevPackagesTable';

const classes = {
  table: `${PREFIX}-table`,
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: 1080,
  margin: '0 auto',
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  boxShadow: 'none',
  borderRadius: 'initial',

  [`& .${classes.table}`]: {
    '& .MuiTableRow-root': {
      height: 60,
      '&:last-of-type': {
        height: 80,
      },
      '& .MuiTableCell-body': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
})) as typeof TableContainer;

function createData(
  name: string,
  basic: number | string,
  bronze: number | string,
  silver: number | string,
  gold: number | string,
  platinum: number | string
) {
  return { name, basic, bronze, silver, gold, platinum };
}

const rows = [
  createData('Gameplays', '-', '500', '1,000', '2,000', 'Custom'),
  createData('Reviews', '-', '125', '250', '500', 'Custom'),
  createData('Surveys', '-', '40', '75', '150', 'Custom'),
  createData('Content Creators', '-', '2', '3', '5', 'Custom'),
  createData('Media Exposure', '-', '', '', '1', 'Custom'),
];

export default function ArtDevPackagesTable() {
  return (
    <StyledTableContainer component={Paper}>
      <Table className={classes.table} aria-label="packages table" size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" sx={{ width: '207px', padding: '0 42px 0 0' }}>
                <Typography variant="subtitle1" color="text.secondary2">
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: '162px', padding: 0 }}>
                <Typography variant="subtitle1">{row.basic}</Typography>
              </TableCell>
              <TableCell sx={{ width: '188px' }}>
                <Typography variant="subtitle1">{row.bronze}</Typography>
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                <Typography variant="subtitle1">{row.silver}</Typography>
              </TableCell>
              <TableCell sx={{ width: '178px' }}>
                <Typography variant="subtitle1">{row.gold}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">{row.platinum}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
