import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { ResponseData } from 'types/ResponseType';

interface FetchResultOptions {
  /** fetch 성공 시 호출되는 callback 함수 */
  onSuccess?: (data: ResponseData) => void;
  /** fetch 실패 시 호출되는 callback 함수 */
  onError?: (data: ResponseData) => void;
  /** 성공 메시지 */
  successMessage?: string;
  /** fetch 성공 시 메시지 표출 */
  showSuccessMessage?: boolean;
  /** fetch 실패 시 메시지 표출 생략 */
  hideErrorMessage?: boolean;
}

/** API fetch 결과를 표출할 때 사용하는 공용 hook */
export function useFetchResult() {
  const { enqueueSnackbar } = useSnackbar();

  const showResult = useCallback(
    ({ status, data }: AxiosResponse<ResponseData>, options?: FetchResultOptions) => {
      // 추후 API 구조 통일되면 code 200만 성공으로 처리하고 error 객체만 남기도록
      if (status === 200 && (data.result?.code === 0 || data.result?.code === 200)) {
        options?.onSuccess?.(data);

        if (options?.showSuccessMessage) {
          enqueueSnackbar(options?.successMessage ?? 'Success', { variant: 'success' });
        }
      } else {
        options?.onError?.(data);

        if (options?.hideErrorMessage) return;

        const message = data?.error
          ? `${data.error.code} : ${data.error.message}`
          : data?.result
          ? `${data.result.code} : ${data.result.msg}`
          : data?.result_code
          ? `${data.result_code} : ${data.description}`
          : `${status} Error`;

        enqueueSnackbar(message, { variant: data.error?.level ?? 'error' });
      }
    },
    [enqueueSnackbar]
  );

  return {
    showResult,
  };
}
