import { useState } from 'react';
import { styled } from '@mui/material';

import GHashLink from '../GHashLink';
import VideoClipMedia from '../ClipCard/VideoClipMedia';

const Root = styled(GHashLink)(() => ({
  borderRadius: 5,
  width: 310,
}));

export interface ClipThumbnailContainerProps {
  srcUrl: string;
  onCardClickHandler?: () => void;
  to: string;
  duration?: number;
}

const ClipThumbnailContainer: React.FC<ClipThumbnailContainerProps> = ({
  srcUrl,
  onCardClickHandler,
  to,
  duration,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <Root
      to={to}
      onClick={() => onCardClickHandler && onCardClickHandler()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <VideoClipMedia
        hover={isHover}
        imageUrl={srcUrl || ''}
        playingTime={duration || 0}
        width={310}
        height={175}
        iconSize="SMALL"
      />
    </Root>
  );
};

export default ClipThumbnailContainer;
