import useFetch from 'api/useFetch';
import useSWRFetch from 'api/useSWRFetch';
import { DigitalGoodsDetail, DigitalGoodsList } from 'types/DigitalGoodsType';
import { storeApiUrl } from './gstoreAPI';

// 디지털 굿즈 주문 request
export interface DigitalGoodsOrderRequestBody {
  productId: number;
  productDigiOptId: number;
  qty: number;
  price: number;
}

// 디지털 굿즈 주문 response
export interface DigitalGoodsOrderResponseData {
  order_id: string;
  order_email: string;
}

export function useGetDigitalGoodsDetailAPI(goodsId: string) {
  const url = goodsId ? `${storeApiUrl}/api/v2/products/digital-goods/${goodsId}` : '';
  return useSWRFetch<DigitalGoodsDetail>({
    key: url,
    useToken: true,
  });
}

export function useGetDigitalGoodsListAPI() {
  const url = `${storeApiUrl}/api/shop/digital/goods/store/list`;

  return useSWRFetch<DigitalGoodsList>({ key: url, useToken: true });
}

// 디지털 굿즈 주문
export function useOrderDigitalGoodsAPI() {
  const url = `${storeApiUrl}/api/v2/orders/digital-goods`;

  return useFetch<DigitalGoodsOrderResponseData, DigitalGoodsOrderRequestBody>(
    url,
    { method: 'POST' },
    true
  );
}
