import { Box } from '@mui/material';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { ImageBox } from 'atoms';
import { Carousel } from 'elements';
import { useMobileVideoDialog } from 'hooks';
import playIcon from 'images/icons/ico_play.svg';
import { GameContent } from 'types/GameDetailTypes';
import { useLog } from 'utils/userLog';

const swiperParams: SwiperOptions = {
  slidesPerView: 'auto',
};

interface DetailCarouselProps {
  slideData: GameContent[];
}

export default function DetailCarousel({ slideData }: DetailCarouselProps) {
  const { gxcTag } = useLog();
  const { showVideoDialog } = useMobileVideoDialog();

  return (
    <Carousel
      swiperOption={swiperParams}
      name="detail-slider"
      paginationPosition="INSIDE"
      // TODO: 반응형으로 하려면 Carousel의 width를 설정하면 안됨
      width={375}
      height={242}
      bulletWidth={48}
      fullWidth
      hasPagination
      // fullWidth에서는 width값이 minWidth로 설정되서 제거
      style={{ minWidth: 'auto' }}
      onActiveIndexChange={() => {
        gxcTag('click', {
          area: 'detail',
          ui: 'pagination',
        });
      }}
    >
      {slideData.map?.(({ id, type, image_url, link }) => (
        <SwiperSlide key={id} style={{ width: '100%', flexBasis: '100%', position: 'relative' }}>
          {type === 'IMAGE' ? (
            <ImageBox
              src={image_url}
              alt="content image"
              resize
              resizeWidth={375}
              resizeHeight={242}
              width="100%"
              height="100%"
              format="webp"
              overlay
              sx={{ objectFit: 'cover' }}
            />
          ) : (
            // video slide upper area
            <Box
              position="relative"
              paddingTop="calc(242 / 375 * 100%)"
              onClick={() => {
                gxcTag('click', {
                  area: 'detail',
                  ui: 'thumbnail_video_play',
                });

                showVideoDialog(link, {
                  area: 'thumbnail_video_popup',
                  parameters: {
                    game_id: id,
                  },
                });
              }}
            >
              {/* thumbnail image */}
              <img
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  display: 'block',
                  objectFit: 'cover',
                  filter: 'brightness(0.8)',
                }}
                src={`https://i.ytimg.com/vi_webp/${link.substring(
                  link.length - 11
                )}/sddefault.webp`}
                alt="thumbnail"
                width="100%"
                height="100%"
              />
              {/* play icon */}
              <img
                src={playIcon}
                alt="play icon"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </Box>
          )}
        </SwiperSlide>
      ))}
    </Carousel>
  );
}
