import { Box, styled, Typography } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '12px',
  paddingBottom: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  // 임의의 값
  height: '110px',
}));

export function NoDataItem() {
  return (
    <Container>
      <Typography variant="body2" color="text.secondary2">
        No more alerts in the past 30 days.
      </Typography>
    </Container>
  );
}
