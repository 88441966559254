import { MediumChip } from '@ground/ui';
import { Box, Typography } from '@mui/material';

import { useGetDetailData } from 'views/detail/DetailProvider';
import DetailBasicInfo from './basic/DetailBasicInfo';
import DetailLanguageDropdown from './DetailLanguageDropdown';
import DetailLineBanner from './DetailLineBanner';
import DetailSlide from './DetailSlide';

export default function DetailUpper() {
  const { data } = useGetDetailData();

  return (
    <Box component="section" mt="60px" width="1200px" sx={{ position: 'relative' }}>
      {/* header 영역 */}
      <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
        <DetailLanguageDropdown />
      </Box>
      {/* title 영역 */}
      <Typography variant="subtitle3" color="text.secondary">
        {data?.studio_name}
      </Typography>

      <Box display="flex" alignItems="center" gap="12px" marginTop="8px">
        <Typography variant="h3">{data?.title}</Typography>

        {typeof data?.private_test === 'number' ? (
          <MediumChip color="primary.main" text="PRIVATE" icon="lock" />
        ) : null}
      </Box>

      {/* content 영역 */}
      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <DetailSlide />
        <DetailBasicInfo />
      </Box>
      <DetailLineBanner />
    </Box>
  );
}
