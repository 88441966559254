import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useGameDetailNewsAPI } from 'api/detail/detailAPI';
import { NewsComponent } from 'components/news';
import NoContents from './NoContents';

export default function News() {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isValidating, setSize } = useGameDetailNewsAPI(id);

  const viewMore: boolean = useMemo(() => {
    const pagination = data && data[data.length - 1]?.pagination;

    return pagination ? pagination.total_pages > pagination.current_page + 1 : false;
  }, [data]);

  return data && data[0].data.length > 0 ? (
    <Box marginTop="20px">
      {data.map((responseData) =>
        responseData.data
          .filter((item) => item.display)
          .map((news, index) => <NewsComponent key={index} {...news} />)
      )}
      {viewMore && (
        <Box display="flex" justifyContent="center" marginTop="120px">
          <LoadingButton
            loading={isValidating}
            color="dark"
            variant="contained"
            onClick={() => {
              setSize((size) => size + 1);
            }}
          >
            Load More
          </LoadingButton>
        </Box>
      )}
    </Box>
  ) : isValidating ? (
    <Box textAlign="center" mt="80px">
      <CircularProgress color="primary" size={64} />
    </Box>
  ) : (
    <Box marginTop="120px">
      <NoContents />
    </Box>
  );
}
