import { FormControlLabel } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';

import { GCheckbox, GCheckboxProps } from '../atoms';
import { FormBaseProps } from './FormType';

export interface FormCheckboxDataProps extends FormBaseProps {
  label: ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
  checkboxProps?: GCheckboxProps;
}

export default function FormCheckbox({
  label,
  defaultChecked = false,
  disabled,
  checkboxProps,
  name,
  rules = {},
}: FormCheckboxDataProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultChecked}
      control={control}
      render={({ field: { onChange, value, ref, ...field } }) => (
        <FormControlLabel
          control={
            <GCheckbox
              {...field}
              {...checkboxProps}
              inputRef={ref}
              checked={value}
              onChange={(event, checked) => {
                checkboxProps?.onChange?.(event, checked);
                onChange(checked);
              }}
              error={error}
            />
          }
          // label 스타일 조정(디자인 변경되면 바뀔 수 있음)
          slotProps={{ typography: { variant: 'body2' } }}
          disabled={disabled}
          label={label}
          sx={{
            margin: 0,
            '& .MuiFormControlLabel-label': { marginLeft: '8px' },
            // labelPlacement="start"인 경우 label margin 조정
            '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
              marginLeft: 0,
              marginRight: '8px',
            },
          }}
        />
      )}
    />
  );
}
