import { Typography, styled } from '@mui/material';

interface StyleProps {
  bgColor?: string;
  textAlign?: 'center' | 'left';
  size?: 'small' | 'medium';
}

const EventTag = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textAlign' && prop !== 'size',
})<StyleProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor ?? theme.palette.primary.main,
  padding: '14px 10px',
  minWidth: '53px',
}));

export interface EventBadgeNewProps {
  text: string;
  bg_color: string; // color string
  text_color: string; // color string
  className?: string;
  textAlign?: 'center' | 'flex-start' | 'flex-end';
  size?: 'small' | 'medium';
}

export default function EventBadgeNew({
  text,
  bg_color,
  text_color,
  textAlign = 'flex-start',
  size = 'medium',
}: EventBadgeNewProps) {
  const texts = text.split(' ');
  const upperText = texts.slice(0, Math.ceil(texts.length / 2)).join(' ');
  const lowerText = texts.slice(Math.ceil(texts.length / 2), texts.length).join(' ');

  return (
    <EventTag
      bgColor={bg_color}
      size={size}
      style={{
        display: 'flex',
        alignItems: textAlign,
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography color={text_color} variant={size === 'medium' ? 'button2' : 'button3'}>
        {upperText}
      </Typography>
      {lowerText && (
        <Typography color={text_color} variant={size === 'medium' ? 'button2' : 'button3'}>
          {lowerText}
        </Typography>
      )}
    </EventTag>
  );
}
