import { Review, ReviewPopupMenuItem } from '@ground/ui';
import { Stack } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';

import { useReviewCommentDeleteAPI } from 'api/comment/commentAPI';
import { useDialog } from 'hooks';
import type { ReviewItemDataV2 } from 'types/CommentType';
import { useGetDetailData } from 'views/detail/DetailProvider';

interface Props {
  reviewData: ReviewItemDataV2;
  mutateReviewList?: () => void;
}

export default function ReviewContainerByUser({ reviewData, mutateReviewList }: Props) {
  const { showDialog } = useDialog();

  const { files } = useGetDetailData();

  const {
    id,
    game_id,
    text,
    hidden,
    deletable,
    deleted: isDeleted,
    picked: isPicked,
    picked_issued_point: isGPIssued,
    filtered,

    user_name,
    user_pic_url,
    created_at,
    updated_at,

    user_badge,

    playing_time,
    total_playing_time,
  } = reviewData;

  // 어드민 hide, user delete, AI filter가 아닌 유효한 리뷰
  const isValidReview = !hidden && !isDeleted && !filtered;

  const showDeleteButton = deletable && isValidReview;

  // delete
  const { isLoading: loadingDelete, fetch: fetchDelete } = useReviewCommentDeleteAPI(game_id, id);

  // 본인 삭제
  const mutateDelete = useCallback(async () => {
    if (loadingDelete) return;

    const response = await fetchDelete();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      mutateReviewList?.();
    } else {
      showDialog({
        title: 'Error',
        body:
          response.data?.result?.code === 211003
            ? 'Reviews that have been issued G.P cannot be deleted.'
            : response.data?.result?.msg,
        primaryButton: {
          text: 'OK',
        },
      });
      return false;
    }
  }, [fetchDelete, loadingDelete, mutateReviewList, showDialog]);

  const handleDeleteConfirm = useCallback(async () => {
    showDialog({
      title: 'Confirmation',
      body: (
        <>
          Are you sure you want to delete your review?
          <br />
          This action cannot be undone.
        </>
      ),
      primaryButton: { text: 'Confirm', callback: mutateDelete },
      secondaryButton: { text: 'Cancel' },
    });
  }, [mutateDelete, showDialog]);

  const menuList: ReviewPopupMenuItem[] = [
    {
      label: 'Copy Link',
      value: 'copy_link',
      onClick: () =>
        copy(`${window.location.origin}/detail/${game_id}/review/reviews/?review_id=${id}`),
    },
    ...(showDeleteButton
      ? [
          { value: '', divider: true },
          { label: 'Delete', value: 'delete', onClick: handleDeleteConfirm },
        ]
      : []),
  ];

  return (
    <Stack gap="36px">
      <Review
        userInfoProps={{
          userAvatarUrl: user_pic_url ?? '',
          username: user_name,
          badge: {
            adminBadge: Boolean(user_badge & 4),
            studioBadge: Boolean(user_badge & 2),
            creatorBadge: Boolean(user_badge & 1),
          },
          timestamp: {
            deployType: files?.[0]?.deploy_type,
            playingTime: files?.[0]?.min_play ? playing_time || total_playing_time : 0,
            totalPlayingTime: total_playing_time,
          },
        }}
        statusAreaProps={{
          distributed: isGPIssued,
          status: {
            createdDate: created_at,
            editedDate: updated_at,
            purchased: false,
          },
          menuList: menuList,
        }}
        textfieldProps={{
          text: text,
          isTextSelectable: false,
          isBanned: hidden || filtered,
          isDeleted: isDeleted,
          hasHidePermission: false,
          picked: isPicked,
        }}
      />
    </Stack>
  );
}
