import PrivateThemeProvider from 'theme/PrivateThemeProvider';
import SupporterApplyRouter from './SupporterApplyRouter';

export default function SupporterApply() {
  return (
    <PrivateThemeProvider>
      <SupporterApplyRouter />
    </PrivateThemeProvider>
  );
}
