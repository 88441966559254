import { LoadingButton } from '@mui/lab';
import { useCallback, useEffect, useRef } from 'react';
import { useEventListener, useScript } from 'usehooks-ts';

import { useFetch } from 'api';
import { useError, useSign } from 'hooks';
import { useLog } from 'utils/userLog';

// AppleID에 대해 정의된 것이 없어서 any로 정의해버림
declare const AppleID: any;

interface AppleLoginProps {
  isMobile?: boolean;
  isSignup?: boolean;
}

export default function AppleLogin({ isMobile, isSignup }: AppleLoginProps) {
  const { showError } = useError();
  const { isLoading, fetch } = useFetch('login/popup_apple', { method: 'POST' });
  const documentRef = useRef<Document>(document);
  const state = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );
  const { signComplete } = useSign();
  const { gxcTag } = useLog();

  // Apple sign 성공 시
  const handleSuccess = useCallback(
    async (event: any) => {
      // Handle successful response.
      console.log('success', event?.detail);
      const response = await fetch({ ...event?.detail, device: 'P' });
      if (response.status === 200 && response.data.data) {
        // 로그인 성공한 경우
        signComplete(response.data.data);

        // event tracker
        // Requirement to add Script for Google Ads 2021.11.26
        // https://www.notion.so/gameround/c9aa543eb50443c29b0c8a8246dbebea
        gtag('event', 'conversion', { send_to: 'AW-669277837/uDS-CMudwoMDEI29kb8C' });
      } else {
        console.error(response);
        showError(response);
      }
    },
    [fetch, showError, signComplete]
  );

  // Apple sign 실패 시(error_code가 정의된 문서를 못찾음. 창이 닫혀도 에러 발생함)
  const handleFailure = useCallback((event: any) => {
    // Handle error.
    console.log('fail', event?.detail?.error);
  }, []);

  useEventListener<any>('AppleIDSignInOnSuccess', handleSuccess, documentRef);
  useEventListener<any>('AppleIDSignInOnFailure', handleFailure, documentRef);

  useEffect(() => {
    // 스크립트가 로드된 후 AppleID 초기화
    if (state === 'ready') {
      AppleID.auth.init({
        clientId: 'gameround.gxc.com',
        scope: 'name email',
        redirectURI: `${document.location.origin}`, // /api/login/oauth_apple`,
        usePopup: true,
        // state: '[STATE]',
        // nonce: '[NONCE]',
        // response_type: 'code id_token', //'code','id_token'
        // response_mode: 'fragment', //'query','fragment', 'form_post'
      });
    }
  }, [state]);

  const handleClickLogin = async () => {
    gxcTag('click', { area: 'account', ui: 'apple' });
    try {
      const data = await AppleID.auth.signIn();
      // Handle successful response.
      console.log(data);
    } catch (error) {
      // Handle error.
      console.log(error?.message ?? error);
    }
  };

  return (
    <LoadingButton
      color="light"
      disabled={state !== 'ready'}
      loading={isLoading}
      onClick={handleClickLogin}
      size="large"
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.8694 12.8858C18.84 9.88162 21.2796 8.44072 21.3887 8.36793C20.0176 6.33024 17.8816 6.05064 17.1213 6.01883C15.3049 5.8313 13.5753 7.10637 12.6538 7.10637C11.7342 7.10637 10.3105 6.04631 8.80429 6.0733C6.82284 6.10319 4.99648 7.24472 3.97683 9.04764C1.91903 12.6762 3.4504 18.0536 5.45556 20.9985C6.43585 22.438 7.60489 24.0577 9.13864 23.9984C10.6169 23.9391 11.1746 23.0266 12.9616 23.0266C14.7486 23.0266 15.2504 23.9984 16.814 23.968C18.4037 23.9386 19.4115 22.5011 20.3837 21.0559C21.5091 19.3836 21.9725 17.7643 22 17.6824C21.9654 17.666 18.9012 16.4729 18.8694 12.8858Z"
            fill="#121212"
          />
          <path
            d="M16.6899 4.14986C17.5498 3.04992 18.1324 1.52153 17.974 0C16.733 0.0528308 15.2308 0.869066 14.3398 1.96795C13.5416 2.9432 12.8436 4.4959 13.0306 5.98995C14.415 6.10301 15.827 5.24768 16.6899 4.14986Z"
            fill="#121212"
          />
        </svg>
      }
      sx={{ background: 'white', height: isMobile ? 48 : 42 }}
      variant="contained"
    >
      Sign {isSignup ? 'up' : 'in'} with Apple
    </LoadingButton>
  );
}
