import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FieldValues, useFieldArray } from 'react-hook-form';

import { GIconButton } from '../atoms';

type FieldType<T> = { id: string } & T;

export interface FormFieldArrayItemProps<T> {
  name: string;
  index: number;
  field: FieldType<T>;
}

interface FormFieldArrayProps<T> {
  /** Form Field의 Label */
  label: string;
  /** form의 field 이름 */
  fieldName: string;
  /** 각 필드의 초기값. fieldArray의 값은 항상 오브젝트 형식이 되어야 함 */
  initialValue: T;
  /** 필드의 최소 갯수 - 기본 값 0 */
  minCount?: number;
  /** 필드의 최대 갯수 - 기본 값 99 */
  maxCount?: number;
  /** fieldArray에서 사용할 row */
  children: (props: FormFieldArrayItemProps<T>) => ReactNode;
}

export default function FormFieldArray<T extends FieldValues>({
  label,
  fieldName,
  initialValue,
  minCount = 0,
  maxCount = 99,
  children,
}: FormFieldArrayProps<T>) {
  const { append, remove, fields } = useFieldArray({
    name: fieldName,
  });

  return (
    <Stack width="100%" gap="8px">
      {/* Upper Area */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* Label */}
        <Typography variant="body1" color="text.secondary">
          {label}
        </Typography>
        {/* Add Button */}
        <ButtonBase
          disabled={fields.length >= maxCount}
          onClick={() => append(initialValue)}
          sx={{
            py: '6px',
            color: 'text.secondary',
            ':hover': { color: 'white' },
            '&.Mui-disabled': { opacity: 0.3 },
          }}
        >
          <Typography variant="button3">+ Add</Typography>
        </ButtonBase>
      </Box>
      {/* Field List Area */}
      <Stack gap="12px">
        {fields.map((field, index) => (
          <Box key={field.id} display="flex" gap="4px">
            {children({
              name: `${fieldName}.${index}`,
              index: index,
              field: field as unknown as FieldType<T>,
            })}
            {/* Remove Button */}
            {index >= minCount ? (
              <GIconButton
                iconName="close"
                iconSize={16}
                size={32}
                height={40}
                backgroundColor="gray.60"
                variant="rounded"
                onClick={() => remove(index)}
              />
            ) : null}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
