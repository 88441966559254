import { Components } from '@mui/material';

const PaperTheme: Components = {
  MuiPaper: {
    defaultProps: { elevation: 0 },
    styleOverrides: { root: { borderRadius: '5px' } },
  },
};

export default PaperTheme;
