import { Box, Button, Link, Stack, Typography, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { OrderTabKey } from 'api/order';
import eventTracker from 'utils/eventTracker';
import NotFound from 'views/main/NotFound';

const Container = styled(Stack)({
  textAlign: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '200px',
  marginBottom: '540px',
});

export const GStoreOrderFailed: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const productType = state as OrderTabKey;
  if (!productType) {
    return <NotFound />;
  }

  return (
    <Container>
      <Typography variant="h3">Sorry, Order Failed</Typography>
      <Typography variant="body2" mt="12px" color="text.secondary">
        We were unable to process your payment.
        <br /> Please check your internet connection and try again.
      </Typography>

      <Box mt="40px" sx={{ display: 'flex', gap: '8px', textAlign: 'center' }}>
        <Button
          variant="ghost"
          color="light"
          size="large"
          onClick={() => {
            eventTracker('gstore_orderfailed_backtostore');
            navigate('/gpointstore');
          }}
        >
          Back to G.Point Store
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            eventTracker('gstore_orderfailed_myorder');
            navigate(-1);
          }}
        >
          Try Again
        </Button>
      </Box>
      <Typography variant="body2" color="text.secondary2" mt="40px">
        If this problem persists, please contact <br />
        <Link
          href="mailto:support@gameround.co"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          support@gameround.co
        </Link>
      </Typography>
    </Container>
  );
};
