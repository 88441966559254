import { MediumChip } from '@ground/ui';
import { Box, Divider, Paper, Skeleton, Typography } from '@mui/material';
import { format } from 'date-fns';

import { useGetProfileAPI } from 'api/profile/profileAPI';
import SummaryAvatar from './SummaryAvatar';
import SummaryList from './SummaryList';
import SummaryReferralCode from './SummaryReferralCode';

export default function ProfileSummary() {
  const { data: profileInfo } = useGetProfileAPI();

  return (
    <>
      {/* title */}
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Box display="flex" alignItems="center" gap="12px" marginBottom="20px">
          <Typography variant="h5">
            {profileInfo?.data.name ? (
              `${profileInfo.data.name}’s PAGE`
            ) : (
              <Skeleton width="300px" />
            )}
          </Typography>
          {profileInfo?.data?.supporter ? (
            <MediumChip color="private.primary" icon="flag" text="SUPPORTER" />
          ) : null}
          {profileInfo?.data?.university_type === 'educator' ? (
            <MediumChip color="text.primary" icon="educator" text="EDUCATOR" />
          ) : profileInfo?.data?.university_type === 'student' ? (
            <MediumChip color="text.primary" icon="educator" text="STUDENT" />
          ) : null}
        </Box>

        <Typography variant="body2" color="text.secondary2">
          {profileInfo?.data.registered_at ? (
            `Member since ${format(new Date(profileInfo.data.registered_at), 'MMM. dd. yyyy')}`
          ) : (
            <Skeleton width="200px" />
          )}
        </Typography>
      </Box>
      {/* summary */}
      <Paper sx={{ height: 'fit-content', padding: '36px 60px 28px' }}>
        <Box display="flex" justifyContent="space-between">
          <SummaryAvatar />
          <SummaryList />
        </Box>
        <Divider sx={{ marginTop: '20px' }} />
        <SummaryReferralCode />
      </Paper>
    </>
  );
}
