import { useMemo } from 'react';

import { useGameEventBadgeListAPI, usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import GameListSmallCard from 'components/GameList/GameListSmallCard';
import { GameStatusType } from 'types/GameCommonTypes';
import { getConvertTimeText } from 'utils/common';

interface GameCardListSmallContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  useSlider: boolean;
  includeSurveyGame?: boolean;
  likeButton?: boolean;
}

export default function GameListSmallContainer({
  cardtype,
  count = 4,
  name,
  hoverVideoPlay,
  showViewMoreCard,
  useSlider,
  includeSurveyGame,
  likeButton = false,
}: GameCardListSmallContainerProps) {
  const { data: timeData } = useGetNotificationCountAPI();
  const { isLoading, isValidating, data, error } = usePrivateGameListAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const transactionTime = timeData?.transaction_time;

  const gameList = useMemo(
    () =>
      data?.data && Array.isArray(data.data) && data.data.length > 0
        ? data.data
            .map((data) => {
              const timeText = getConvertTimeText(data.started_at, transactionTime);

              return {
                ...data,
                state: cardtype,
                hoverVideoPlay: hoverVideoPlay,
                isPrivateShow: !!timeText,
                remainingText: timeText,
              };
            })
            .slice(0, useSlider ? data.data.length : count)
        : [],
    [data?.data, cardtype, hoverVideoPlay, transactionTime, count, useSlider]
  );

  return isDataNotExist ? null : (
    <GameListSmallCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      cardtype={cardtype}
      name={name}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={likeButton}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      useSlider={useSlider}
    />
  );
}
