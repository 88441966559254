import { Skeleton } from '@mui/material';

import { usePickedReviewsAPI } from 'api/displayContent/displayContentAPI';
import { ReviewCardData } from 'types/DisplayContentType';
import PickedReviewListContainer from './PickedReviewListContainer';

const getMixedData = (arr: ReviewCardData[][]) => {
  const maxLength = Math.max(...arr.map((ar) => ar.length));
  const result = [];

  for (let i = 0; i <= maxLength; i++) {
    for (let subArr of arr) {
      if (subArr?.[i]) {
        result.push(subArr?.[i]);
      }
    }
  }

  return result;
};

export default function PickedReviewList() {
  const { isLoading, data, error, isValidating } = usePickedReviewsAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const reviewList = data?.data?.map((data) =>
    data.picked_review_list.map((review) => ({
      ...review,
      ...data,
    }))
  );

  const mixedData = getMixedData(reviewList || []);

  return isLoading || data?.data?.length === 0 ? (
    <Skeleton
      sx={{ background: 'rgba(100, 100, 100, 0.3)' }}
      variant="rectangular"
      width={1200}
      height={240}
    />
  ) : isDataNotExist ? null : (
    <PickedReviewListContainer dataList={mixedData} />
  );
}
