import { ContentsLayout } from 'components';
import GStoreDigitalGameList from './GStoreDigitalGameList';
import GStoreDigitalGoodsList from './GStoreDigitalGoodsList';
import GStoreMerchandiseList from './GStoreMerchandiseList';

export default function UseGPoints() {
  return (
    <ContentsLayout>
      <GStoreDigitalGameList />
      <GStoreDigitalGoodsList />
      <GStoreMerchandiseList />
    </ContentsLayout>
  );
}
