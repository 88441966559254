import { TextEditor } from '@ground/text-editor';
import { GIcon, GIconButton } from '@ground/ui';
import { Box, Button, ButtonBase, Divider, Link, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useBlogDetailAPI } from 'api/blog/blogAPI';
import { useDialog } from 'hooks';
import { useLog } from 'utils/userLog';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import BlogTagButton from '../blog-common/BlogTagButton';
import { ShareModal } from './ShareModal';

const AttachmentBox = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.gray[80],
  padding: '12px 20px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '3px',
  gap: '12px',
}));

export default function BlogDetailMain() {
  const { gxcTag } = useLog();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { announcementId = '' } = useParams<{ announcementId: string }>();
  const { data, isLoading } = useBlogDetailAPI(announcementId);
  const announcementData = data?.data;
  const textWrapperRef = useRef<HTMLDivElement>(null);

  const onClickCopyHandler = useCallback(() => {
    showDialog({
      title: 'Share',
      width: 500,
      body: <ShareModal isCopied title={announcementData?.detail_data?.announce_title ?? ''} />,
      secondaryButton: {
        text: 'Close',
      },
    });
  }, [announcementData?.detail_data?.announce_title, showDialog]);

  const onClickShareHandler = useCallback(() => {
    gxcTag('click', { area: 'detail', ui: 'share' });
    showDialog({
      title: 'Share',
      width: 500,
      body: (
        <ShareModal
          onClickCopyHandler={onClickCopyHandler}
          title={announcementData?.detail_data?.announce_title ?? ''}
        />
      ),
      secondaryButton: {
        text: 'Close',
        callback: () => gxcTag('click', { area: 'share_popup', ui: 'close' }),
      },
      onClickClose() {
        gxcTag('click', { area: 'share_popup', ui: 'close_x' });
      },
    });
  }, [announcementData?.detail_data?.announce_title, onClickCopyHandler, showDialog, gxcTag]);

  useEffect(() => {
    if (textWrapperRef.current && announcementData) {
      // 텍스트 에디터 내에서 사용하는 링크노드 탐색
      const elements = textWrapperRef.current.getElementsByClassName('PlaygroundEditorTheme__link');

      // 내부 도메인(gameround.co)이 아니라면 새창으로 열리도록 변경
      Array.from(elements).forEach((element) => {
        if (element instanceof HTMLAnchorElement && !element.href.includes('gameround.co')) {
          element.setAttribute('target', '_blank');
        }
      });
    }
  }, [announcementData]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {announcementData?.detail_data ? (
        <Box
          display="flex"
          p="80px 0 200px"
          width="750px"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="100%">
            <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap="28px">
              <Stack>
                <Typography variant="subtitle3" color="text.secondary">
                  {announcementData?.detail_data?.announce_type_name}
                </Typography>

                <Typography variant="h3" color="text.primary" mt="8px">
                  {announcementData?.detail_data?.announce_title}
                </Typography>

                {announcementData?.detail_data?.rgst_at && (
                  <Typography variant="subtitle3" color="text.secondary2" mt="16px">
                    {format(new Date(announcementData.detail_data.rgst_at), 'MMM d, yyyy')}
                  </Typography>
                )}
              </Stack>
              <GIconButton
                iconName="share"
                backgroundColor="gray.80"
                iconSize={24}
                size={48}
                variant="circle"
                onClick={onClickShareHandler}
              />
            </Box>

            <Divider sx={{ marginTop: '28px', marginBottom: '80px' }} />

            <Box ref={textWrapperRef} width={750} sx={{ margin: 'auto' }}>
              {announcementData?.detail_data.announce_text && (
                <TextEditor
                  readOnly
                  initialEditorState={announcementData?.detail_data?.announce_text}
                />
              )}

              <Stack direction="column" gap="4px" m="60px 0">
                {announcementData?.announce_attach.map((attach) => (
                  <AttachmentBox
                    key={attach.file_id}
                    onClick={() => {
                      gxcTag('click', { area: 'detail', ui: attach.original_name });
                    }}
                  >
                    <GIcon iconName="download" iconSize={16} iconOpacity={0.5} />
                    <Link
                      variant="body2"
                      color="text.secondary"
                      href={attach.file_location}
                      underline="hover"
                      target="_blank"
                    >
                      {attach.original_name}
                    </Link>
                  </AttachmentBox>
                ))}
              </Stack>

              {announcementData?.tag_list?.length ? (
                <Box mb="60px" display="flex" gap="4px">
                  {announcementData?.tag_list?.map((item) => (
                    <ButtonBase
                      key={item.tag_text}
                      onClick={() => {
                        gxcTag('click', { area: 'detail', ui: item.tag_text });
                        navigate({ pathname: '/blog', search: `search_tag=${item.tag_text}` });
                      }}
                    >
                      <BlogTagButton text={item.tag_text} />
                    </ButtonBase>
                  ))}
                </Box>
              ) : null}
            </Box>

            <Divider />

            <Box mt="40px">
              <Button
                color="primary"
                onClick={() => {
                  gxcTag('click', { area: 'detail', ui: 'back' });
                  navigate({
                    pathname: '/blog',
                    search: typeof state === 'string' ? state : '',
                  });
                }}
                size="large"
                startIcon={<GIcon iconName="arrow_left" iconSize={16} iconColor="inherit" />}
                variant="contained"
              >
                Back
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <NotFound />
      )}
    </>
  );
}
