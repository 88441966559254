import { Box } from '@mui/material';

import { useGetDetailData } from 'views/detail/DetailProvider';
import GameInfoOverview from './GameInfoOverview';
import GameInfoSnsChannels from './GameInfoSnsChannels';
import GameInfoScreenShotsSlider from './GameInfoScreenShotsSlider';
import GameInfoStudioInfo from './GameInfoStudioInfo';
import GameInfoMediaExposure from './GameInfoMediaExposure';
import GameInfoSystemRequirements from './GameInfoSystemRequirements';
import GameInfoDiscordBanner from './GameInfoDiscordBanner';

export default function GameInfo() {
  const { data, info } = useGetDetailData();
  return (
    <>
      <GameInfoOverview overview={info?.overview} />
      {data && <GameInfoSnsChannels data={data} />}
      <Box height="120px" />
      {info && <GameInfoScreenShotsSlider info={info} />}
      {data?.studio_id && <GameInfoStudioInfo studio_id={data.studio_id} />}
      <GameInfoMediaExposure />
      <GameInfoSystemRequirements />
      <GameInfoDiscordBanner />
    </>
  );
}
