import { Stack } from '@mui/material';
import WelcomeMobileDialog from 'views/home/dialog/WelcomeMobileDialog';
import {
  About,
  GPointStore,
  PlayingNow,
  Subscribe,
  Upcoming,
  VideoClip,
  Footer,
  Featured,
} from '..';

export default function Home() {
  return (
    <>
      <Stack gap="60px" mt="40px" mb="60px">
        <Featured />
        <PlayingNow />
        <Upcoming />
      </Stack>
      <VideoClip />
      <About />
      <GPointStore />
      <Subscribe />
      <Footer />

      <WelcomeMobileDialog />
    </>
  );
}
