import { isEmpty } from 'lodash-es';

/**
 *
 * @example
 * userId -> user_id
 */
export const camelToSnakeCase = (str: string) => {
  var result = str.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

type Obj = { [x: string]: number | string | string[] | boolean | null | undefined };

export const objectKeyToSnakeCase = (obj: Obj): Obj | undefined => {
  if (isEmpty(obj)) {
    return;
  }

  const toSnakeCase = Object.entries(obj).map(([key, value]) => {
    return [camelToSnakeCase(key), value];
  });

  return Object.fromEntries(toSnakeCase);
};
