import { Box, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { LinkButton } from 'elements';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { AppleLogin, EmailSignButton, FacebookLogin, GoogleLogin, SignLayout } from '../component';

interface LoginFormProps {
  isMobile?: boolean;
}

export default function LoginForm({ isMobile = false }: LoginFormProps) {
  const { search, state } = useLocation();
  const { gxcTag } = useLog();

  const handleClickSignup = () => {
    eventTracker('login_signup');
    gxcTag('click', { area: 'signup', ui: 'signup' });
  };

  return (
    <SignLayout isMobile={isMobile} title="Sign in">
      <Box mb="40px" textAlign="center">
        {!isMobile && (
          <Typography color="text.primary" mb="10px" variant="h3">
            Sign in
          </Typography>
        )}
        <Typography color="text.secondary" variant="subtitle2">
          Discover upcoming games
        </Typography>
      </Box>

      <Stack rowGap="12px" width="100%">
        <FacebookLogin isMobile={isMobile} />
        <GoogleLogin isMobile={isMobile} />
        <AppleLogin isMobile={isMobile} />
        <EmailSignButton isMobile={isMobile} />
      </Stack>

      <Typography align="center" color="text.secondary2" mt="40px" variant="body2">
        Are you new to G.Round?
      </Typography>
      <LinkButton
        fullWidth
        onClick={handleClickSignup}
        size={isMobile ? 'largest' : 'large'}
        state={state}
        to={`/signup${search}`}
        variant="ghost"
        sx={{ mt: '20px' }}
      >
        Sign up
      </LinkButton>
    </SignLayout>
  );
}
