import { useLocation } from 'react-router-dom';

import { LinkButton } from 'elements';
import { useLog } from 'utils/userLog';

interface EmailSignButtonProps {
  isMobile?: boolean;
  isSignup?: boolean;
}

export default function EmailSignButton({ isMobile, isSignup }: EmailSignButtonProps) {
  const { search, state } = useLocation();
  const { gxcTag } = useLog();

  const handleClickEmailSign = () => {
    gxcTag('click', { area: 'account', ui: 'email' });
  };

  return (
    <LinkButton
      color="light"
      size="large"
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7165 10.709L12.4071 13.6257H17.1191V17.1947C16.5603 17.6361 15.8899 17.9815 15.1263 18.2501C14.3627 18.5187 13.5432 18.6531 12.6679 18.6531C11.718 18.6531 10.8613 18.4804 10.0977 18.135C9.78109 18.0006 9.4831 17.828 9.20374 17.6361L7.21094 20.1498C7.65792 20.4376 8.12353 20.6871 8.64501 20.9173C9.81834 21.4162 11.122 21.6657 12.5747 21.6657C13.3756 21.6657 14.1578 21.5889 14.8842 21.4354C15.6291 21.2819 16.3182 21.0708 16.9515 20.8022C17.5847 20.5335 18.1807 20.2073 18.7394 19.8428C19.2981 19.4782 19.801 19.0944 20.248 18.7106V10.709H14.7165Z"
            fill="#25A9E0"
            stroke="#25A9E0"
          />
          <path
            d="M6.89611 9.21491C7.1941 8.3898 7.62246 7.67982 8.16257 7.08498C8.70267 6.47094 9.3359 5.99123 10.0622 5.62664C10.7886 5.26206 11.5708 5.07017 12.4089 5.07017C12.9863 5.07017 13.5264 5.12774 14.0106 5.22368C14.4948 5.31963 14.9418 5.45395 15.3329 5.62664C15.7427 5.79934 16.1152 6.01042 16.4876 6.25987C16.8601 6.50932 17.214 6.79715 17.5678 7.10416L18.4246 6.06798L19.6165 4.57127C19.1509 4.16831 18.6667 3.80373 18.1638 3.47752C17.661 3.1705 17.1395 2.90186 16.5621 2.6716C16.0034 2.46053 15.3888 2.28783 14.7369 2.1727C14.0851 2.05757 13.3401 2 12.5393 2C11.1425 2 9.876 2.26864 8.70267 2.78673C7.52934 3.30482 6.52362 4.0148 5.68553 4.91667C4.84744 5.81853 4.17696 6.8739 3.71135 8.06359C3.37611 8.8887 3.17125 9.77138 3.07812 10.6732H6.52362C6.59812 10.1743 6.70987 9.67544 6.89611 9.21491Z"
            fill="#25A9E0"
            stroke="#25A9E0"
          />
          <path
            d="M8.14031 16.7166C7.58158 16.1218 7.17185 15.3926 6.87386 14.5675C6.57587 13.7424 6.42688 12.8214 6.42688 11.8427V11.7852C6.42688 11.4014 6.46412 11.0368 6.52 10.6722H3.0745C3.03725 11.056 3 11.4398 3 11.8427V11.9003C3 13.3011 3.22349 14.5867 3.6891 15.7764C4.15471 16.9661 4.78794 18.0023 5.62603 18.8658C6.09164 19.3647 6.63174 19.7868 7.2091 20.1706L9.22052 17.6377C8.82941 17.3691 8.47555 17.062 8.14031 16.7166Z"
            fill="#25A9E0"
            stroke="#25A9E0"
          />
        </svg>
      }
      sx={{ background: 'white', height: isMobile ? 48 : 42 }}
      state={state}
      to={`../email${search}`}
      variant="contained"
      onClick={handleClickEmailSign}
    >
      Sign {isSignup ? 'up' : 'in'} with Email
    </LinkButton>
  );
}
