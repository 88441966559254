import { numberWithCommas } from '@ground/tools';
import { Review, ReviewPopupMenuItem } from '@ground/ui';
import { Collapse, Stack } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLikeCommentAPI } from 'api/comment/commentAPI';
import { useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';
import type { ReviewItemDataV2 } from 'types/CommentType';
import { useGetDetailData } from 'views/detail/DetailProvider';
import ReplyListContainer from '../reply/ReplyListContainer';

interface Props {
  reviewData: ReviewItemDataV2;
  mutateReviewList?: () => void;
}

export default function ReviewContainerPrivateAdmin({ reviewData, mutateReviewList }: Props) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();

  const { files } = useGetDetailData();

  const [openReply, setOpenReply] = useState(false);

  const { fetch: fetchLike, isLoading: loadingLike } = useLikeCommentAPI(
    reviewData.game_id,
    reviewData.id
  );

  const {
    id,
    game_id,
    text,
    replied_count: numReply,

    user_name,
    user_pic_url,
    created_at,
    updated_at,

    user_badge,
    supporter: isSupporter,

    playing_time,
    total_playing_time,

    liked,
    liked_count,

    verified,
  } = reviewData;

  const menuList: ReviewPopupMenuItem[] = [
    {
      label: 'Copy Link',
      value: 'copy_link',
      onClick: () =>
        copy(`${window.location.origin}/detail/${game_id}/review/reviews/?review_id=${id}`),
    },
  ];

  const handleClickLikeButton = useCallback(async () => {
    if (!isLogin) {
      showSignupDialog();
      return;
    }

    const response = await fetchLike();
    if (response.status === 200 && response.data.result?.code === 0) {
      reviewData.liked = !liked;
      reviewData.liked_count++;
      mutateReviewList?.();
    } else {
      showError(response, 'Failed to like review');
    }
  }, [fetchLike, isLogin, liked, mutateReviewList, reviewData, showError, showSignupDialog]);

  return (
    <Stack gap="36px">
      <Review
        userInfoProps={{
          userAvatarUrl: user_pic_url ?? '',
          username: user_name,
          badge: {
            adminBadge: Boolean(user_badge & 4),
            studioBadge: Boolean(user_badge & 2),
            creatorBadge: Boolean(user_badge & 1),
            supporterBadge: isSupporter,
          },
          timestamp: {
            deployType: files?.[0]?.deploy_type,
            playingTime: verified
              ? null
              : files?.[0]?.min_play
              ? playing_time || total_playing_time
              : 0,
            totalPlayingTime: verified ? null : total_playing_time,
          },
        }}
        statusAreaProps={{
          status: {
            createdDate: created_at,
            editedDate: updated_at,
          },
          menuList: menuList,
        }}
        textfieldProps={{
          text: text,
          isTextSelectable: true,
          isBanned: false,
          isDeleted: false,
        }}
        bottomProps={{
          likeProps: {
            isLiked: liked,
            text: liked_count > 9999 ? '+9,999' : numberWithCommas(liked_count),
            isLoading: loadingLike,
            onClick: handleClickLikeButton,
          },
          replyProps: {
            replyCount: numReply,
            onClick: () => {
              setOpenReply((v) => !v);
            },
          },
        }}
      />
      <Collapse in={openReply} unmountOnExit>
        <ReplyListContainer
          gameId={game_id}
          reviewId={id}
          numReply={numReply}
          mutateReviewList={mutateReviewList}
        />
      </Collapse>
    </Stack>
  );
}
