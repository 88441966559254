import { shuffle } from 'lodash-es';
import { useMemo } from 'react';

import { useBestGamesAPI } from 'api/displayContent/displayContentAPI';
import GameListSmallCard from 'components/GameList/GameListSmallCard';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';

interface HomeBestGamesListSmallContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  likeButton?: boolean;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  onClickCard?: (id: number) => void;
}

export default function HomeBestGamesListSmallContainer({
  cardtype,
  count = 4,
  name,
  likeButton = true,
  onLikeClicked,
  onClickCard,
}: HomeBestGamesListSmallContainerProps) {
  const { isLoading, isValidating, data, error } = useBestGamesAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const gameList: GameCardProps[] = useMemo(
    () =>
      Array.isArray(data?.data) && data?.data && data?.data.length > 0
        ? shuffle(data?.data)
            .map?.((data) => ({
              ...data,
              state: cardtype,
              likeButton: likeButton,
            }))
            .slice(0, count)
        : [],
    [data, count, cardtype, likeButton]
  );

  return isDataNotExist ? null : (
    <GameListSmallCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      cardtype={cardtype}
      name={name}
      useSlider={false}
      likeButton={likeButton}
      onClickCard={onClickCard}
      onLikeClicked={onLikeClicked}
    />
  );
}
