import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDialog } from 'hooks';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

export default function useSignupDialog() {
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { gxcTag } = useLog();

  const showSignupDialog = useCallback(() => {
    gxcTag('view', { area: 'signup_popup' });
    showDialog({
      title: 'Sign up',
      body: 'You need to register to G.Round to be able to continue!',
      primaryButton: {
        text: 'Register',
        callback: () => {
          navigate('/signup');
          eventTracker('alertpopup_goto_signup');
          gxcTag('click', { area: 'signup_popup', ui: 'register' });
        },
      },
      secondaryButton: {
        text: 'Sign in',
        callback: () => {
          navigate('/login', { state: { from: pathname + search } });
          eventTracker('alertpopup_goto_login');
          gxcTag('click', { area: 'signup_popup', ui: 'signin' });
        },
      },
      onClickClose: () => {
        gxcTag('click', { area: 'signup_popup', ui: 'close_x' });
      },
    });
  }, [gxcTag, navigate, pathname, search, showDialog]);

  return { showSignupDialog };
}
