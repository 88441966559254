import { numberWithCommas } from '@ground/tools';
import { GTab, GTabs } from '@ground/ui';
import { Box, Divider, styled, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetPublicReviewDashboardAPI } from 'api/comment/commentAPI';
import { iRootState } from 'store';
import { useGetDetailData } from '../DetailProvider';
import { useComment } from './CommentProvider';

const Root = styled('div')(({ theme }) => ({
  width: 1200,
  marginLeft: -40,
  marginRight: -20,
  paddingLeft: 40,
  paddingRight: 20,
  display: 'flex',
  backgroundColor: theme.palette.gray[90],
  borderRadius: '5px 5px 0px 0px',
  position: 'relative',
}));

// 리뷰개수
function commentCount(figure?: number, total?: number) {
  if (figure === undefined) {
    return '';
  }
  return `(${figure ? (figure >= 10000 ? '9,999+' : numberWithCommas(figure)) : 0}${
    total ? '/' + numberWithCommas(total) : ''
  })`;
}

export default function CommentTabs() {
  const { university } = useSelector((state: iRootState) => state.login);
  const { reviewsCountData } = useComment();
  const { data, isStudio, isAdmin, isOldPackage } = useGetDetailData();
  const { id = '', reviewTab = 'reviews' } = useParams<{ id: string; reviewTab: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const isEducator = university.type === 'educator';
  const { data: reviewData } = useGetPublicReviewDashboardAPI(id);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    navigate(`../${newValue}`);
  };

  const isPrivate = typeof data?.private_test === 'number';
  const tabColor = theme.palette.text.primary;
  const isReviewManager = isStudio || isAdmin;

  return (
    <Box mb="36px">
      <Root>
        <GTabs
          value={reviewTab}
          onChange={handleChange}
          height={37}
          indicatorBgColor={tabColor}
          indicatorHeight={2}
        >
          {/* public, private 컴포넌트를 분리하려했으나 Tabs는 Tab에 forwardRef로 value 값을 컨트롤하여 컴포넌트로 그냥 만들면 안되서 놔둠 */}
          <GTab
            label={
              isOldPackage
                ? `Reviews ${commentCount(isPrivate ? undefined : reviewsCountData?.data)}`
                : isPrivate
                ? isReviewManager
                  ? `Reviews ${commentCount(reviewsCountData?.data)}`
                  : 'Reviews'
                : isReviewManager
                ? `Reviews ${commentCount(
                    reviewData?.data?.review_count,
                    reviewData?.data?.goal_amount
                  )}`
                : 'Best Reviews'
            }
            value="reviews"
            color={tabColor}
            size="small"
          />
          {!isReviewManager && (
            <GTab label="My Review" value="my-activities" color={tabColor} size="small" />
          )}
          {isEducator && (
            <GTab label="Student Reviews" value="student-reviews" color={tabColor} size="small" />
          )}
        </GTabs>
      </Root>
      <Divider />
    </Box>
  );
}
