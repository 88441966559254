import { useSWRFetch } from 'api';

export interface GenreData {
  genre_id: number;
  genre_name: string;
}

export interface GameTypeData {
  genre_id: number;
  game_type_name: string;
}

//genre 데이터를 최초 한번만 호출하기
//기존 useSWR hook 과 함께 사용하기 위해 옵션 형식으로 작성, useSWRImmutable과 같은 동작
const revalidationOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetGameGenreAPI() {
  const { isLoading, data } = useSWRFetch<GenreData[]>({
    key: 'code/genre',
    swrConfig: revalidationOptions,
    useToken: true,
  });

  return { isLoading, data };
}

export function useGetGameTypeAPI() {
  const { isLoading, data } = useSWRFetch<GameTypeData[]>({
    key: 'code/game-type',
    swrConfig: revalidationOptions,
    useToken: true,
  });

  return { isLoading, data };
}
