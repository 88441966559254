import { GIcon } from '@ground/ui';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useLog } from 'utils/userLog';

interface ResultContentProps {
  search_keyword: string;
  changeQuery: (queryPrams: { search_keyword: string; search_tag: string }) => void;
  isLoading: boolean;
  totalCount: number;
}

export default function BlogSearchResultContent({
  search_keyword,
  changeQuery,
  isLoading,
  totalCount,
}: ResultContentProps) {
  const { gxcTag } = useLog();
  return (
    <Box>
      <Typography
        variant="h3"
        color="text.primary"
        mb="20px"
        width="840px"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          wordBreak: 'break-all',
        }}
      >
        {search_keyword}
      </Typography>

      <Box display="flex" alignItems="center" width={840}>
        <Button
          onClick={() => {
            gxcTag('click', { area: 'search_result', ui: 'back_to_blog' });
            changeQuery({ search_keyword: '', search_tag: '' });
          }}
          size="small"
          startIcon={<GIcon iconName="arrow_left" iconSize={16} />}
          variant="ghost"
        >
          Back to Blog
        </Button>
        <Typography ml="20px" variant="body2" color="text.secondary">
          {isLoading ? (
            <Skeleton width={180} />
          ) : (
            `${totalCount} result${totalCount > 1 ? 's' : ''} match your search.`
          )}
        </Typography>
      </Box>
    </Box>
  );
}
