import { GIconButton } from '@ground/ui';
import { styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';

import { useConstructionAPI } from 'api/construction/constructionAPI';
import { groundDiscordChannel } from 'utils/common';
import { useLog } from 'utils/userLog';

import backgroundImage from 'images/mobile/mobile-background.png';

const openLink = (href: string) => window.open(href, '_blank', 'noopener,noreferrer');

const Root = styled('div')({
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  paddingLeft: '28px',
  paddingRight: '28px',
});

const MediaBox = styled('div')({
  display: 'flex',
  gap: 16,
  marginTop: 40,
});

export default function Construction() {
  const { data: siteStateNowData } = useConstructionAPI();
  const { gxcTag } = useLog();

  useEffect(() => {
    if (siteStateNowData?.data.state === 'NORMAL') {
      window.location.replace('/');
    }
  }, [siteStateNowData?.data.state]);

  return (
    <Root>
      <img
        src={backgroundImage}
        alt="construction"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          width: '100%',
          objectFit: 'cover',
          height: '100vh',
          filter: 'brightness(0.5)',
        }}
      />
      <Typography variant="h3">Under Construction</Typography>
      {siteStateNowData?.data?.message ? (
        <Typography
          variant="body1"
          mt="10px"
          color="text.secondary"
          textAlign="center"
          sx={{ whiteSpace: 'pre-line' }}
        >
          {siteStateNowData?.data?.message}
        </Typography>
      ) : null}
      <Typography variant="subtitle1" mt="32px">
        {siteStateNowData &&
          `${format(new Date(siteStateNowData?.data.period_start), 'h:mm aaa MMM. d')} ~
              ${format(new Date(siteStateNowData?.data.period_end), 'h:mm aaa MMM. d')}`}
      </Typography>
      <Typography variant="body2" color="text.secondary" mt="4px">
        (PT)
      </Typography>
      <MediaBox>
        <GIconButton
          iconName="youtube"
          iconSize={24}
          size={36}
          onClick={() => {
            gxcTag('click', {
              ui: 'youtube',
            });
            openLink('https://www.youtube.com/channel/UCs3X1qAr8hXkBnn0v8lT9DA');
          }}
          backgroundColor="gray.60"
          variant="circle"
        />
        <GIconButton
          iconName="x"
          iconSize={24}
          size={36}
          onClick={() => {
            gxcTag('click', {
              ui: 'twitter',
            });
            openLink('https://twitter.com/gameroundco');
          }}
          backgroundColor="gray.60"
          variant="circle"
        />
        <GIconButton
          iconName="facebook"
          iconSize={24}
          size={36}
          onClick={() => {
            gxcTag('click', {
              ui: 'facebook',
            });
            openLink('https://www.facebook.com/gameround/');
          }}
          backgroundColor="gray.60"
          variant="circle"
        />
        <GIconButton
          iconName="discord"
          iconSize={24}
          size={36}
          onClick={() => {
            gxcTag('click', {
              ui: 'discord',
            });
            openLink(groundDiscordChannel);
          }}
          backgroundColor="gray.60"
          variant="circle"
        />
      </MediaBox>
    </Root>
  );
}
