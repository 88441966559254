import { FormCheckboxGroup, FormRadioGroup, SelectionProps } from '@ground/ui';
import { FormHelperText } from '@mui/material';
import { useMemo } from 'react';
import { get, useFormState } from 'react-hook-form';

import { SurveyQuestionType } from 'types/FormDataType';
import QuestionAttaches from './QuestionAttaches';
import QuestionSelection from './QuestionSelection';
import QuestionTitle from './QuestionTitle';

export default function QuestionChoice({
  id,
  question,
  detail,
  question_type,
  question_attach_type,
  survey_question_attaches,
  selection_type,
  survey_selections,
  selection_min_amount,
  selection_max_amount,
  disabled = false,
  index,
}: SurveyQuestionType & { disabled?: boolean; index: number }) {
  const { errors } = useFormState();

  const name = useMemo(() => `${question_type.toLowerCase()}-${id}`, [question_type, id]);
  const error = get(errors, name);

  // 1개 이상 선택 가능할 때 옵션 텍스트 추가
  const optionText =
    selection_max_amount > 1
      ? `*Multiple selection is possible (${
          selection_min_amount === selection_max_amount
            ? selection_max_amount
            : `${selection_min_amount} to ${selection_max_amount}`
        })`
      : undefined;

  const options: SelectionProps[] = survey_selections.map((selection, index) => ({
    id: selection.id,
    order: index + 1,
    label: <QuestionSelection type={selection_type} selection={selection} />,
  }));

  return (
    <div>
      <QuestionTitle
        order={index + 1}
        question={question}
        optionText={optionText}
        detail={detail}
        error={!!error}
      />
      <QuestionAttaches type={question_attach_type} attaches={survey_question_attaches} />

      {selection_max_amount > 1 ? (
        <FormCheckboxGroup
          disabled={disabled}
          name={name}
          options={options}
          minSelect={selection_min_amount}
          maxSelect={selection_max_amount}
          row={selection_type !== 'TEXT'}
        />
      ) : (
        <FormRadioGroup
          disabled={disabled}
          name={name}
          options={options}
          rules={{ required: 'Choose one of the examples.' }}
          sx={{ columnGap: '20px' }}
        />
      )}

      <FormHelperText error>{error?.message}</FormHelperText>
    </div>
  );
}
