import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { GameListSmallSlide } from 'components';
import { GameCardSmall, GameCardSmallSkeleton, GameCardSmallDiscord } from 'elements';
import { EventBadgeType } from 'types/EventBadgeType';
import { GameStatusType } from 'types/GameCommonTypes';
import { GameCardProps } from 'types/GameCardTypes';
import RecommendAGameCard from 'views/home/RecomendAGameCard';

export interface GameCardListSmallProps {
  data: GameCardProps[];
  loading: boolean;
  count: number;
  cardtype: GameStatusType;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  eventBadgeList?: EventBadgeType[];
  useSlider: boolean;
  likeButton?: boolean;
  hasDefaultThumbnail?: boolean;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  onClickCard?: (id: number) => void;
  onRecommendAGameClick?: () => void;
  fillHeight?: boolean;
}

export default function GameListSmallCard({
  data,
  loading,
  count,
  name,
  hoverVideoPlay,
  showViewMoreCard,
  eventBadgeList,
  useSlider,
  likeButton = true,
  hasDefaultThumbnail,
  onLikeClicked,
  onClickCard,
  onRecommendAGameClick,
  fillHeight,
}: GameCardListSmallProps) {
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);
  const shouldDefaultThumbnailDisplay = useMemo(
    () =>
      hasDefaultThumbnail && (data.length === 1 || data.length === 2 || data.length === 3) && data,
    [data, hasDefaultThumbnail]
  );

  return (
    <Grid container rowGap={'40px'} columnGap={'20px'}>
      {loading ? (
        loadingSkeletonList.map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardSmallSkeleton />
          </Grid>
        ))
      ) : useSlider && data.length > 4 ? (
        <GameListSmallSlide sliderData={data} name={name} fillHeight={fillHeight} />
      ) : data.length > 0 ? (
        <>
          {data.map((game) => (
            <Grid item key={game.id}>
              <GameCardSmall
                {...game}
                hoverVideoPlay={hoverVideoPlay}
                likeButton={likeButton}
                event_badge={eventBadgeList?.find((badge) => badge.target_id === game.id)}
                onClickCard={onClickCard}
                onLikeClicked={onLikeClicked}
                fillHeight={fillHeight}
              />
            </Grid>
          ))}
          {shouldDefaultThumbnailDisplay && (
            <RecommendAGameCard
              size={(4 - data.length) as 1 | 2 | 3}
              onRecommendAGameClick={onRecommendAGameClick}
            />
          )}
        </>
      ) : (
        // TODO: error 일 때 표시할 카드가 필요(우선 4개만 보이도록)
        loadingSkeletonList.slice(0, 4).map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardSmallSkeleton />
          </Grid>
        ))
      )}
      {data.length < 8 && showViewMoreCard && (
        <Grid item>
          <GameCardSmallDiscord />
        </Grid>
      )}
    </Grid>
  );
}
