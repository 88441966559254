import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { GameCardBig, GameCardBigDiscord, GameCardBigSkeleton } from 'elements/GameCard';
import { GameCardProps } from 'types/GameCardTypes';
import { EventBadgeType } from 'types/EventBadgeType';
import { Carousel } from 'elements';
import { SwiperSlide } from 'swiper/react';

export interface GameCardListBigProps {
  data: GameCardProps[];
  loading: boolean;
  count: number;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  eventBadgeList?: EventBadgeType[];
  likeButton?: boolean;
  onClickCard?: (params?: string) => void;
  useSlider?: boolean;
}

export default function GameListBigCard({
  data,
  loading,
  count,
  hoverVideoPlay,
  showViewMoreCard,
  eventBadgeList,
  likeButton,
  onClickCard,
  useSlider,
}: GameCardListBigProps) {
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  return (
    <Grid container rowGap="60px" columnGap="20px">
      {loading ? (
        loadingSkeletonList.map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardBigSkeleton />
          </Grid>
        ))
      ) : data.length > 0 ? (
        useSlider && data.length > 2 ? (
          <Carousel
            swiperOption={{
              slidesPerView: 2,
              autoplay: false,
              slidesPerGroup: 2,
            }}
            hasNavigation
            navigationPositionTop={165}
            width={1200}
            height={480}
            name="game-list-big-cards"
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            {data.map((game, index) => (
              <SwiperSlide key={index} style={{ width: '1200px', display: 'flex' }}>
                <GameCardBig
                  {...game}
                  hoverVideoPlay={hoverVideoPlay}
                  likeButton={likeButton}
                  event_badge={eventBadgeList?.find((badge) => badge.target_id === game.id)}
                  onClickCard={onClickCard}
                />
              </SwiperSlide>
            ))}
            {data.length % 2 === 1 && showViewMoreCard && (
              <SwiperSlide style={{ width: '1200px', display: 'flex' }}>
                <GameCardBigDiscord />
              </SwiperSlide>
            )}
          </Carousel>
        ) : (
          <>
            {data.map((game) => (
              <Grid item key={game.id}>
                <GameCardBig
                  {...game}
                  hoverVideoPlay={hoverVideoPlay}
                  likeButton={likeButton}
                  event_badge={eventBadgeList?.find((badge) => badge.target_id === game.id)}
                  onClickCard={onClickCard}
                />
              </Grid>
            ))}
            {data.length % 2 === 1 && showViewMoreCard && <GameCardBigDiscord />}
          </>
        )
      ) : (
        // TODO: error 일 때 표시할 카드가 필요
        loadingSkeletonList.map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardBigSkeleton />
          </Grid>
        ))
      )}
    </Grid>
  );
}
