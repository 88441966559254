import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useVerifyToken } from 'api/sign/signAPI';
import { Dispatch, iRootState } from 'store';
import AuthRoute from 'views/main/AuthRoute';
import { SignLayout } from '../component';
import { useSignupData } from '../signup/SignupProvider';
import SignupVerificationContent from './SignupVerificationContent';

export default function SignupVerificationForm() {
  const navigate = useNavigate();
  const { isMobile } = useSignupData();
  const dispatch = useDispatch<Dispatch>();
  const { verified } = useSelector((state: iRootState) => state.login);
  const { verifyToken } = useVerifyToken();

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  useEffect(() => {
    if (verified) {
      navigate('/', { replace: true });
    }
  }, [navigate, verified]);

  return (
    <AuthRoute>
      <SignLayout isMobile={isMobile} title="Please Verify Your Email">
        {!isMobile && (
          <Typography color="text.primary" variant="h3" mb="12px">
            Please Verify Your Email
          </Typography>
        )}
        <SignupVerificationContent
          isMobile={isMobile}
          onVerificationSuccess={() => {
            dispatch.login.updateLoginInfo({ verified: true });
          }}
        />
      </SignLayout>
    </AuthRoute>
  );
}
