import { GIconButton, LoadingWrapper } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography, styled } from '@mui/material';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useCreateReferralCodeAPI, useGetReferralCodeAPI } from 'api/profile/profileAPI';
import { useError } from 'hooks';
import eventTracker from 'utils/eventTracker';

const VerticalDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.opacity.white[10],
  height: '20px',
  marginX: '20px',
}));

export default function SummaryReferralCode() {
  const [copied, setCopied] = useState(false);
  const { showError } = useError();
  const {
    isLoading: loadingReferralCode,
    data: referralCodeData,
    mutate: mutateReferralCode,
  } = useGetReferralCodeAPI();
  const { isLoading: loadingCreateReferralCode, fetch: fetchCreateReferralCode } =
    useCreateReferralCodeAPI();

  // use referral code link
  const referralCodeLink = `https://${
    process.env.REACT_APP_ENV === 'DEV'
      ? 'dev.'
      : process.env.REACT_APP_ENV === 'STAGE'
      ? 'stage.'
      : ''
  }gameround.co/?referralcode=${referralCodeData?.data}`;

  // create user referral code
  const handleClickCreateReferralCode = async () => {
    const response = await fetchCreateReferralCode();
    if (response.status === 200 && response.data.result_code === 'OK') {
      mutateReferralCode(response.data);
    } else {
      showError(response, 'Create referral code failed');
    }
    eventTracker('profile_summary_create_referralcode');
  };

  return (
    <LoadingWrapper isLoading={loadingReferralCode} size={24}>
      {typeof referralCodeData?.data === 'string' && referralCodeData.data.length > 0 ? (
        <Box display="flex" alignItems="center" marginTop="22px" gap="20px">
          <Typography variant="body2" color="text.secondary2">
            My Referral Code
          </Typography>
          <VerticalDivider orientation="vertical" />
          <Typography variant="body2">{referralCodeLink}</Typography>
          <CopyToClipboard text={referralCodeLink} onCopy={(_, result) => setCopied(result)}>
            <GIconButton iconName="copy" iconSize={16} size={24} />
          </CopyToClipboard>
          {copied && (
            <Typography variant="button3" color="primary">
              Link copied!
            </Typography>
          )}
        </Box>
      ) : (
        <LoadingButton
          color="primary"
          loading={loadingCreateReferralCode}
          onClick={handleClickCreateReferralCode}
          size="tiny"
          variant="ghost"
          sx={{ marginTop: '22px' }}
        >
          Create Referral Code
        </LoadingButton>
      )}
    </LoadingWrapper>
  );
}
