import { Stack } from '@mui/material';

import { usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { SectionContainer } from 'components';
import { GameStatusType } from 'types/GameCommonTypes';
import GameListSmallContainer from './GameListSmallContainer';

export default function TestingPrivateSection() {
  const { data } = usePrivateGameListAPI();

  return data?.data?.length ? (
    <Stack
      sx={{
        backgroundColor: 'rgb(0, 0, 0, 0.3)',
        width: '100%',
        alignItems: 'center',
        paddingY: '100px',
      }}
    >
      <SectionContainer title="Private Test">
        <GameListSmallContainer
          cardtype={GameStatusType.TESTING}
          count={4}
          name="testing"
          useSlider={false}
        />
      </SectionContainer>
    </Stack>
  ) : null;
}
