import { useDialog } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

import { Dispatch } from 'store';
import { useWithDrawalRequestAPI } from 'api/deleteAccount/deleteAccountAPI';

export default function WithdrawalMain() {
  const { showDialog, closeDialog } = useDialog();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { fetch } = useWithDrawalRequestAPI(); // 탈퇴요청(동일api)

  const handdleClickRecoverAccount = async () => {
    const response = await fetch({
      request_state: 'CANCEL',
    });

    if (response?.status === 200 && response.data?.result_code === 'OK') {
      dispatch.login.updateLoginInfo({ withdrawal_request: false });
      closeDialog();
      navigate('/');
    }
  };

  const handdleClickCancel = () => {
    dispatch.login.logout();
    navigate('/');
  };

  showDialog({
    title: 'This account is about to be deleted',
    body: (
      <DeleteAccountRecoveryDialog
        handdleClickRecoverAccount={handdleClickRecoverAccount}
        closeDialog={handdleClickCancel}
      />
    ),
    onClose: () => handdleClickCancel(),
  });

  return <></>;
}

export interface DeleteAccountRecoveryDialogProps {
  closeDialog?: () => void;
  handdleClickRecoverAccount?: () => void;
}

// 회원탈퇴 복구 다이어로그
function DeleteAccountRecoveryDialog({
  closeDialog,
  handdleClickRecoverAccount,
}: DeleteAccountRecoveryDialogProps) {
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        The account you are currently logged into <br />
        is scheduled to be deleted. <br />
        If you want to cancel the deletion of your account <br />
        and recover your account, <br />
        please click the ‘Recover Account’ button below.
      </Typography>

      <Box mt="40px" display="flex" flexDirection="column" gap="12px">
        <Button color="primary" variant="contained" onClick={handdleClickRecoverAccount} fullWidth>
          Recover Account
        </Button>
        <Button color="primary" variant="ghost" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
      </Box>
    </>
  );
}
