import { useCallback } from 'react';
import { AxiosResponse } from 'axios';

import { ResponseData } from 'types/ResponseType';
import { DialogState, useDialog } from './DialogContext';

export function useError() {
  const { showDialog } = useDialog();

  const showError = useCallback(
    (
      response: AxiosResponse<ResponseData>,
      title = 'Error',
      props?: Omit<DialogState, 'title' | 'body' | 'open'>
    ) => {
      showDialog({
        title,
        body:
          typeof response === 'string'
            ? response
            : response.data.result
            ? `${response.data.result.code} : ${response.data.result.msg}`
            : response.data.result_code
            ? `${response.data.result_code} : ${response.data.description}`
            : `${response.status} Error`,
        primaryButton: { text: 'OK', ...props?.primaryButton },
        ...props,
      });
    },
    [showDialog]
  );

  return {
    showError,
  };
}
