import { AxiosError } from 'axios';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { useGetUserStoreDataAPI } from 'api/gstore/promotionProductDetailAPI';
import { GPointsSummaryData, useGetGPointSummary } from 'api/profile/profileAPI';
import { GStoreUserStoreType } from 'types/GStoreTypes';
import { ResponseData } from 'types/ResponseType';
import { IStoreMerchandise, useGetMerchandiseDetail } from 'api/gstore';

type ContextProps = {
  data?: ResponseData<IStoreMerchandise.GetMerchandiseDetail.MerchandiseData>;
  gpointSummaryData?: ResponseData<GPointsSummaryData>;
  isLoading: boolean;
  isSummaryLoading: boolean;
  isGoodsLoading: boolean;
  error?: AxiosError;
  gstoreId?: string;
  shippingInfoOpen?: boolean;
  setShippingInfoOpen?: Dispatch<SetStateAction<boolean>>;
  userPointData?: ResponseData<GStoreUserStoreType>;
  isUserPointLoading?: boolean;
  summaryMutate: KeyedMutator<ResponseData<GPointsSummaryData>>;
};

interface IProps {
  children: ReactNode;
}

const DetailContext = createContext<Partial<ContextProps>>({});

export const DetailProvider = ({ children }: IProps) => {
  const { gstoreId = '' } = useParams<{ gstoreId: string }>();
  const { data, isLoading, error } = useGetMerchandiseDetail(gstoreId);
  const {
    data: gpointSummaryData,
    isLoading: isSummaryLoading,
    mutate: summaryMutate,
  } = useGetGPointSummary();

  const [shippingInfoOpen, setShippingInfoOpen] = useState<boolean>(false);
  const { data: userPointData, isLoading: isUserPointLoading } = useGetUserStoreDataAPI();

  return (
    <DetailContext.Provider
      value={{
        gstoreId,
        data,
        isLoading,
        isSummaryLoading,
        gpointSummaryData,
        error,
        shippingInfoOpen,
        setShippingInfoOpen,
        userPointData,
        isUserPointLoading,
        summaryMutate,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};

export const useGStoreDetailData = () => {
  return useContext(DetailContext);
};
