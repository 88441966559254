import { useSWRFetch } from 'api';
import { useSelector } from 'react-redux';
import { iRootState } from 'store';

type CheckType = 'main' | 'game';

interface LauncherVersionCheckData {
  isPopup: boolean;
}

// main은 1.5.10 이하 버전 사용 체크, game은 최신 버전 사용 체크
export function useLauncherVersionCheckAPI(type: CheckType) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const url = `launcher/check/${type}`;

  return useSWRFetch<LauncherVersionCheckData>({
    key: isLogin ? url : null,
    useToken: true,
    swrConfig: { revalidateOnFocus: true },
  });
}
