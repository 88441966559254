import { Box, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { GameCardProps } from 'types/GameCardTypes';
import GameCardMediumLowerArea from './GameCardMediumLowerArea';
import GameCardMediumUpperArea from './GameCardMediumUpperArea';

export default function GameCardMedium(
  props: GameCardProps & {
    onClickCard?: (gameId?: number) => void;
    onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
    fillHeight?: boolean;
  }
) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const theme = useTheme();

  return (
    <Link
      style={{ cursor: props?.isPrivateShow ? 'auto' : 'cursor', textDecoration: 'auto' }}
      to={props?.isPrivateShow ? '' : `/detail/${props.id}/info`}
      onClick={() => props?.onClickCard?.(props?.id)}
    >
      <Box
        position="relative"
        width={285}
        ref={hoverRef}
        sx={{
          borderRadius: '5px',
          boxShadow: isHover && !props.isPrivateShow ? '0px 10px 10px rgba(0, 0, 0, 0.5)' : '',
        }}
      >
        <GameCardMediumUpperArea {...props} isHover={isHover} />
        <GameCardMediumLowerArea
          {...props}
          isHover={isHover}
          onLikeClicked={props?.onLikeClicked}
          fillHeight={props?.fillHeight}
        />

        {props.chipText && (
          <Box
            position="absolute"
            sx={{
              top: 12,
              right: 12,
              zIndex: 999,
              backgroundColor: theme.palette.base.white,
              padding: '0px 8px',
              borderRadius: '3px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="small2" color="gray.100">
              {props.chipText}
            </Typography>
          </Box>
        )}
      </Box>
    </Link>
  );
}
