import { isEmpty } from 'lodash-es';

import { pathLogs } from 'utils/userLog/pathLogs';
import type { PathLog } from 'utils/userLog/types/logTypes';
import { getParams } from 'utils/userLog/utils/getParams';
import { getValidQueries } from 'utils/userLog/utils/queryValidation';

/**
 * url 정보를 기반으로 log 값을 반환하는 함수
 * @returns service & page & area & parameter log values
 * @issue1 useParams() 에서 params 값을 가져올 수 없다 -> 구조적인 문제
 * @issue2 search 값에 수집에 대한 정책 정의 필요
 */

export function getUrlBasedLog({
  path,
  search,
}: {
  path: string;
  search: string;
}): PathLog | undefined {
  let log = undefined;

  for (const [key, value] of Object.entries(pathLogs)) {
    if (value.pattern.test(path)) {
      const { pattern, paramNames, ...rest } = value;

      log = { ...rest, parameters: getParams({ key, path, paramNames: value?.paramNames }) };
      break;
    }
  }

  // 존재하지 않는 path
  if (log === undefined) {
    return log;
  }

  if (!!search.length) {
    log = { ...log, parameters: { ...log.parameters, ...getValidQueries(search) } };
  }

  log = { ...log, parameters: isEmpty(log.parameters) ? 'N/A' : log.parameters };
  return log;
}
