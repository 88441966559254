import { GroundLogo } from '@ground/ui';
import { AppBar, Box, Button, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { TopLineBanner } from 'elements';
import { iRootState } from 'store';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

const GnbContainer = styled(AppBar)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  background: theme.palette.gray[100],
  borderRadius: 0,
}));

const GnbMenuContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: 60,
  paddingRight: 20,
  paddingLeft: 20,
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const GnbLogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

interface Props {
  type?: 'logo';
}

function Gnb({ type }: Props) {
  const navigate = useNavigate();
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { gxcTag } = useLog();

  const handleClickLogo = () => {
    eventTracker('gnb_logo');
    gxcTag('click', { area: 'gnb', ui: 'logo' });
  };

  const handleClickLogin = () => {
    navigate('/login');
    eventTracker('login_login');
    gxcTag('click', { area: 'gnb', ui: 'signin' });
  };

  const handleClickSignup = () => {
    navigate('/signup');
    eventTracker('login_signup');
    gxcTag('click', { area: 'gnb', ui: 'signup' });
  };

  return (
    <GnbContainer position="sticky" elevation={0}>
      {!type && <TopLineBanner type="mobile" />}
      <GnbMenuContainer>
        <GnbLogoLink to="/" onClick={handleClickLogo}>
          <GroundLogo colorType="light" />
        </GnbLogoLink>
        {!type && !isLogin && (
          <Box display="flex" gap="8px">
            <Button variant="contained" color="dark" size="small" onClick={handleClickLogin}>
              Sign in
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={handleClickSignup}>
              Sign up
            </Button>
          </Box>
        )}
      </GnbMenuContainer>
    </GnbContainer>
  );
}

export default Gnb;
