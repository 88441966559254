import { Route, Routes } from 'react-router-dom';

import DigitalGameRoute from './DigitalGameRoute';
import GStoreMain from './GStoreMain';
import GStoreDetail from './detail/GStoreDetail';
import { GStoreOrderFailed } from './detail/GStoreOrderFailed';
import { GStoreOrderSucceed } from './detail/GStoreOrderSucceed';
import GStoreDetailDigital from './detail/digital/GStoreDetailDigital';

export default function GStoreRouter() {
  return (
    <Routes>
      <Route index element={<GStoreMain />} />
      <Route path="/digital/*">
        <Route path=":gstoreId" element={<GStoreDetailDigital />} />
      </Route>
      <Route path="/merchandise/:gstoreId" element={<GStoreDetail />} />
      <Route path="digital-games/*" element={<DigitalGameRoute />} />
      <Route path="/success" element={<GStoreOrderSucceed />} />
      <Route path="/failed" element={<GStoreOrderFailed />} />
    </Routes>
  );
}
