import { EventBadgeNew, VideoBox } from '@ground/ui';
import { Box, styled } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import { ImageBox } from 'atoms';
import { Dispatch, iRootState } from 'store';
import { GameCardProps } from 'types/GameCardTypes';
import GameCardPrivateUpperArea from '../GameCardPrivateUpperArea';

const CardImgRoot = styled('div')({
  position: 'relative',
  width: 590,
  height: 330,
  overflow: 'hidden',
});

const EventTagWrap = styled('div')({
  position: 'absolute',
  left: 12,
  top: -2,
  display: 'flex',
  height: '52px',
});

export default function GameCardBigUpperArea({
  thumbnail_url,
  thumbnail_video_url,
  hoverVideoPlay,
  event_badge,
  isHover = false,
  remainingText,
  isPrivateShow,
}: GameCardProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const { mute } = useDispatch<Dispatch>();
  const muted = useSelector((state: iRootState) => state.mute);

  const handleClickMute = () => {
    mute.toggleMute();
  };

  return (
    <CardImgRoot ref={ref}>
      {isPrivateShow ? <GameCardPrivateUpperArea remainingText={remainingText} /> : null}
      {inView && (
        <>
          {/* thumbnail */}
          <div>
            <ImageBox
              src={thumbnail_url}
              alt="thumbnail"
              resize
              resizeWidth={590}
              resizeHeight={330}
              sx={{
                transition: 'filter .3s, all 0.2s ease-in ',
                filter: isHover ? 'brightness(1.4)' : '',
                transform: isHover ? 'scale(1.1)' : '',
              }}
            />
            {/* event badge */}
            {event_badge && (hoverVideoPlay && thumbnail_video_url ? !isHover : true) && (
              <EventTagWrap>
                <EventBadgeNew {...event_badge} />
              </EventTagWrap>
            )}
          </div>
          {/* thumbnail video */}
          {hoverVideoPlay && thumbnail_video_url && (
            <Box position="absolute" top={0} left={0}>
              <VideoBox
                isHover={isHover}
                muted={muted}
                onClickMute={handleClickMute}
                width="590px"
                height="330px"
                src={thumbnail_video_url}
                sx={{ objectFit: 'cover' }}
              />
            </Box>
          )}
        </>
      )}
    </CardImgRoot>
  );
}
