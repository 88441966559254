import { GIcon } from '@ground/ui';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  backdropClasses,
  dialogClasses,
  dialogContentClasses,
  styled,
} from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import SimpleBar from 'simplebar-react';

import { DialogState } from 'hooks';

import 'simplebar/dist/simplebar.min.css';
import './clip-popup-swiper.scss';

const Scrollbar = styled(SimpleBar)(({ theme }) => ({
  '& .simplebar-track.simplebar-vertical': {
    width: 6,
    '& .simplebar-scrollbar:before': {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundColor: theme.palette.gray[40],
    },

    '& .simplebar-scrollbar.simplebar-visible:before': {
      opacity: 1,
    },
  },
}));

interface DialogStyles {
  backgroundColor?: string;
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<DialogStyles>(({ theme, backgroundColor }) => ({
  '& .MuiPaper-root ': {
    overflowY: 'initial',
  },
  // Backdrop
  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  // Paper
  [`& .${dialogClasses.paper}`]: {
    background: backgroundColor ?? theme.palette.gray[80],
    borderRadius: 5,
  },

  // Content
  [`& .${dialogContentClasses.root}`]: {
    padding: 0,
    color: theme.palette.text.secondary,

    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
  },
}));

export default function ClipPopupModal({
  open = false,
  body,
  onClose,
  onClickClose,
  maxWidth = false,
  width = 880,
  height = 'auto',
  disableEscapeKeyDown,
  hasScrollBar,
  backgroundColor,
}: DialogState) {
  const handleClickClose = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (onClickClose) {
        const response = await onClickClose(e);
        if (response !== false) {
          if (onClose) onClose();
        }
      } else {
        if (onClose) onClose();
      }
    },
    [onClickClose, onClose]
  );

  return (
    <StyledDialog
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          if (onClose) onClose();
        }
      }}
      scroll="paper"
      PaperProps={{
        sx: { width, height },
      }}
      sx={{ overflowY: 'none' }}
      backgroundColor={backgroundColor}
    >
      {/* Close Button */}
      <IconButton
        onClick={handleClickClose}
        sx={{
          position: 'absolute',
          top: -42,
          right: -42,
        }}
      >
        <GIcon iconName="close" iconSize={32} iconColor="basic.white" />
      </IconButton>

      {/* Content Area */}
      <DialogContent
        id="dialog-description"
        sx={{ ...(hasScrollBar && { overflowY: 'hidden', borderRadius: '5px' }) }}
      >
        {hasScrollBar ? (
          <Scrollbar autoHide={false} sx={{ ...(hasScrollBar && { maxHeight: '100%' }) }}>
            {typeof body === 'string' ? (
              <DialogContentText color="text.secondary" variant="body2">
                {body}
              </DialogContentText>
            ) : (
              body
            )}
          </Scrollbar>
        ) : typeof body === 'string' ? (
          <DialogContentText color="text.secondary" variant="body2">
            {body}
          </DialogContentText>
        ) : (
          body
        )}
      </DialogContent>
    </StyledDialog>
  );
}
