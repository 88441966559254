import { Box, Button, CircularProgress, Divider, Grid, styled } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { useBlogListAPIInfinite } from 'api/blog/blogAPI';
import { useQueryDispatch, useQueryState } from 'context';
import { NoData } from 'elements';
import { AnnouncementListOptions } from 'types/AnnouncementTypes';
import { useLog } from 'utils/userLog';
import BlogSearchResultContent from '../blog-common/BlogSearchResultContent';
import CardList from './list-type/CardList';
import CommonList from './list-type/CommonList';
import FilterList from './list-type/FilterList';

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 474,
  width: 840,
});

export default function BlogListBox() {
  const { gxcTag } = useLog();
  const {
    blog_platform = 'b2c',
    pageSize,
    search_announce_type,
    search_keyword,
    view_type,
    search_tag,
  } = useQueryState<AnnouncementListOptions>();
  const { changeQuery } = useQueryDispatch();

  const { isLoading, isValidating, data, setSize } = useBlogListAPIInfinite(
    blog_platform,
    pageSize,
    search_keyword,
    search_announce_type,
    search_tag
  );

  const blogListData = useMemo(
    () =>
      Array.isArray(data) ? data?.map((dataElement) => dataElement.data.list_page).flat() : [],
    [data]
  );

  const totalCount = useMemo(() => data?.[0].pagination_map?.list_page?.total_count ?? 0, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search_tag]);

  return (
    <>
      {/* Blog List */}
      {blogListData?.length > 0 ? (
        <Box>
          {!search_keyword &&
            !search_tag &&
            view_type === 'common' &&
            blogListData?.map((data) => <CommonList key={data.announce_id} data={data} />)}

          {!search_keyword && !search_tag && (!view_type || view_type === 'card') && (
            <Grid container rowGap="80px" columnGap="40px" width="840px">
              {blogListData?.map((data) => (
                <Grid
                  item
                  key={data.announce_id}
                  width="400px"
                  onClick={() =>
                    gxcTag('click', {
                      area: 'list',
                      ui: 'blog_card',
                    })
                  }
                >
                  <CardList data={data} />
                </Grid>
              ))}
            </Grid>
          )}

          {(search_keyword || search_tag) && (
            <>
              <BlogSearchResultContent
                search_keyword={search_keyword || search_tag || ''}
                changeQuery={changeQuery}
                isLoading={isLoading}
                totalCount={totalCount}
              />
              {blogListData.length > 0 && <Divider sx={{ mt: '40px' }} />}
              {blogListData?.map((data) => <FilterList key={data.announce_id} data={data} />)}
            </>
          )}

          {/* 로딩중이면 로딩화면. 로딩중이 아니면 Load More 버튼 노출 */}
          {isValidating ? (
            <LoadingContainer>
              <CircularProgress color="primary" size={64} />
            </LoadingContainer>
          ) : (
            Array.isArray(data) &&
            data[data.length - 1].pagination_map?.list_page?.current_page !==
              data[data.length - 1].pagination_map?.list_page?.total_pages && (
              <Box textAlign="center" marginTop="80px">
                <Button
                  color="dark"
                  variant="contained"
                  onClick={() => {
                    setSize((size) => size + 1);
                    gxcTag('click', {
                      area: 'list',
                      ui: 'load_more',
                    });
                  }}
                >
                  Load More
                </Button>
              </Box>
            )
          )}
        </Box>
      ) : // 로딩 중이 아니고 검색어가 없는데 리스트가 없는 경우
      !isValidating ? (
        !search_keyword ? (
          <Box sx={{ mt: '150px', width: 840 }}>
            <NoData
              subTitle={
                <>
                  We are preparing something exciting to share!
                  <br />
                  Stay tuned...
                </>
              }
            />
          </Box>
        ) : (
          <BlogSearchResultContent
            search_keyword={search_keyword}
            changeQuery={changeQuery}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        )
      ) : (
        <LoadingContainer>
          <CircularProgress color="primary" size={64} />
        </LoadingContainer>
      )}
    </>
  );
}
