import { Stack } from '@mui/material';

import { usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import { GameStatusType } from 'types/GameCommonTypes';
import GameListBigContainer from './GameListBigContainer';
import GameListLargestContainer from './GameListLargestContainer';
import GameListMediumContainer from './GameListMediumContainer';

export default function HomePrivateTestGame() {
  const { data } = usePrivateGameListAPI();
  const dataCount = data?.data?.length;

  return dataCount ? (
    <Stack
      sx={{
        backgroundColor: 'rgb(0,0,0,0.3)',
        width: '100%',
        alignItems: 'center',
        py: '100px',
      }}
    >
      <SectionContainer title="Private Test">
        {dataCount === 1 ? (
          <GameListLargestContainer
            cardtype={GameStatusType.TESTING}
            count={1}
            name="survey"
            hoverVideoPlay
            showViewMoreCard
            useSlider
            includeSurveyGame
            navigationPositionTop={220}
            isLauncherImage
          />
        ) : dataCount === 2 ? (
          <GameListBigContainer count={2} hoverVideoPlay showViewMoreCard />
        ) : (
          <GameListMediumContainer
            cardtype={GameStatusType.TESTING}
            count={8}
            name="private-test"
            hoverVideoPlay
            showViewMoreCard
            useSlider
            includeSurveyGame
            navigationPositionTop={220}
            isLauncherImage
          />
        )}
      </SectionContainer>
    </Stack>
  ) : null;
}
