import { numberWithCommas } from '@ground/tools';
import { Box, Divider, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useGetOrderStatusCountAPI } from 'api/gstore/gstoreAPI';

export default function ProfileOrderStatus() {
  const { isLoading, data } = useGetOrderStatusCountAPI();

  const orderStatus = useMemo(
    () => [
      {
        name: 'Orders Placed',
        count: data?.data.find((element) => element.status === 'ordered')?.count ?? 'No Data',
      },
      {
        name: 'Packing',
        count: data?.data.find((element) => element.status === 'packing')?.count ?? 'No Data',
      },
      {
        name: 'In Transit',
        count: data?.data.find((element) => element.status === 'in_transit')?.count ?? 'No Data',
      },
      {
        name: 'Delivered',
        count: data?.data.find((element) => element.status === 'delivered')?.count ?? 'No Data',
      },
      {
        name: 'Orders Cancelled',
        count: data?.data.find((element) => element.status === 'canceled')?.count ?? 'No Data',
      },
    ],
    [data?.data]
  );

  return (
    <div>
      <Typography variant="h5" marginBottom="20px">
        Orders
      </Typography>
      <Paper sx={{ marginBottom: '80px' }}>
        {isLoading ? (
          <Skeleton animation="wave" width="100%" height={140} sx={{ borderRadius: '8px' }} />
        ) : (
          <Stack
            direction="row"
            divider={
              <Divider orientation="vertical" flexItem sx={{ margin: 'auto', height: '32px' }} />
            }
          >
            {orderStatus.map((menu, index) => (
              <Box key={index} padding="32px 40px">
                <Typography variant="body2" color="text.secondary" width="160px">
                  {menu.name}
                </Typography>
                <Typography variant="h4" marginTop="12px">
                  {numberWithCommas(menu.count)}
                </Typography>
              </Box>
            ))}
          </Stack>
        )}
      </Paper>
    </div>
  );
}
