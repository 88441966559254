import { styled, Typography } from '@mui/material';

const Root = styled('li')({
  display: 'flex',
  flexDirection: 'column',
  height: 40,
  marginBottom: 20,
});

export default function SystemRequirementsItem({ title, text }: { title: string; text: string }) {
  return (
    <Root>
      <Typography variant="body3" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body2" marginTop="4px">
        {text}
      </Typography>
    </Root>
  );
}
