import { Box, Divider, Stack, Typography } from '@mui/material';
import { useClientApplyData } from '../ClientApplyProvider';

interface CommonStepProps {
  step: number;
  clientApplyStep: number;
  text: string;
}

/** Step 영역 */
export default function ClientApplyStepper() {
  const { clientApplyStep } = useClientApplyData();

  return (
    <Stack
      alignItems="center"
      mb="40px"
      width="1200px"
      sx={{
        background: (theme) => theme.palette.gray[80],
      }}
    >
      <Box
        display="flex"
        sx={{
          textAlign: 'center',
          width: '1080px',
          height: '90px',
          gap: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CommonStep step={1} clientApplyStep={clientApplyStep} text="テスト&ゲーム情報を確認する" />

        <Divider
          sx={{
            margin: '60px 0',
            width: '170px',
            position: 'relative',
            border: (theme) =>
              `1px solid ${
                clientApplyStep > 1 ? theme.palette.base.dimWhite : 'rgba(255, 255, 255, 0.05)'
              }`,
          }}
        />

        <CommonStep step={2} clientApplyStep={clientApplyStep} text="秘密保持契約書" />

        <Divider
          sx={{
            margin: '60px 0',
            width: '170px',
            position: 'relative',
            border: (theme) =>
              `1px solid ${
                clientApplyStep > 2 ? theme.palette.base.dimWhite : 'rgba(255, 255, 255, 0.05)'
              }`,
          }}
        />

        <CommonStep step={3} clientApplyStep={clientApplyStep} text="申請完了" />
      </Box>
    </Stack>
  );
}

function CommonStep({ step, clientApplyStep, text }: CommonStepProps) {
  const { setClientApplyStep } = useClientApplyData();

  return (
    <Box
      onClick={() => {
        if (process.env.NODE_ENV !== 'production') {
          setClientApplyStep(step);
        }
      }}
    >
      <Typography
        variant="h5"
        mb="5px"
        color={clientApplyStep > step - 1 ? 'text.primary' : 'text.secondary2'}
        sx={{
          opacity: clientApplyStep > step - 1 ? 1 : 0.5,
        }}
      >
        0{step}
      </Typography>
      <Typography
        width="180px"
        variant="subtitle3"
        color={clientApplyStep === step ? 'text.primary' : 'text.secondary2'}
        sx={{
          opacity: clientApplyStep === step ? 1 : 0.5,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
