import { Stack } from '@mui/material';

import { GuideCard } from 'elements';
import { gameGuideList } from './gameGuideList';

export interface GameGuideProps {
  spacing: string;
}

export default function GameGuide({ spacing }: GameGuideProps) {
  return (
    <Stack direction="row" spacing={spacing}>
      {gameGuideList.map((guide, index) => (
        <GuideCard key={index} {...guide} />
      ))}
    </Stack>
  );
}
