import { FormCheckbox } from '@ground/ui';
import { Box, Button, Divider, Link, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { get, useFormContext, useWatch } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import { useDialog } from 'hooks';

const FERPA = () => {
  const [isHover, setIsHover] = useState(false);
  const { showDialog, closeDialog, setImmerState } = useDialog();
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const [ref, inView] = useInView({ threshold: 0.1 });
  const error = get(errors, 'FERPA_agree');
  const formValue = useWatch({
    name: 'FERPA_agree',
  });
  const theme = useTheme();

  const getBody = useCallback(
    (disabled?: boolean) => (
      <Box sx={{ display: 'flex', flexDirection: 'column' }} mt="20px">
        <Box>
          <Typography variant="h5" color="base.dimWhite">
            Game Round, Inc. FERPA Waiver and Permission For Access To Educational Records
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="20px">
            The Family Educational Rights and Privacy Act of 1974 (“FERPA” or the “Act”) establishes
            the privacy rights of students formally attending a post-secondary institution in the
            United States with regard to educational records. The Act makes provision for the
            inspection, review, and amendment of educational records by the student and requires, in
            most instances, prior consent from the student for disclosure of such records to third
            parties. The consent must be in writing, signed and dated by the student, and must
            specify the records to be released, the reason for the release, and the names of the
            parties to whom such records will be released. No exclusion is made for non-U.S. citizen
            students. The Act does not apply to a person who has applied for admission but who never
            actually enrolled in or attended the institution and deceased persons.
          </Typography>

          <Typography variant="body2" color="text.secondary" mt="40px">
            <Typography color="text.primary" variant="body2" mb="12px">
              By participating in the G.Round program, I hereby authorize any University that I
              participate with to release, and for Game Round, Inc. (“Game Round”) to access, store,
              disburse, and utilize, the following educational records and information (“Records”):
            </Typography>
            <Box
              sx={{
                paddingLeft: '7px',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-7px',
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    listStyleType: 'none',
                  }}
                >
                  my written reviews of games accessed on Game Round’s platform (“Platform”) at
                  <br />
                  <Link href="https://gameround.co" target="_blank" color="primary.main">
                    https://gameround.co
                  </Link>
                  .
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-7px',
                  }}
                />
                <Typography variant="body2" color="text.secondary" sx={{ listStyleType: 'none' }}>
                  the username of my Game Round account and any past history of game titles
                  <br />
                  played and/or reviewed,
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-9px',
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ listStyleType: 'none', display: 'block', maxWidth: '520px' }}
                >
                  the username/contact name used by me in third party communication tools such as
                  Discord.
                </Typography>
              </Box>
            </Box>
          </Typography>

          <Typography variant="body2" color="text.secondary" mt="40px">
            <Typography color="text.primary" variant="body2" mb="12px">
              These Records are being released for the purposes of:
            </Typography>
            <Box
              sx={{
                paddingLeft: '7px',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-7px',
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    listStyleType: 'none',
                  }}
                >
                  providing feedback for Game Round to convey to the developers of the games on
                  <br />
                  the Platform,
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-7px',
                  }}
                />
                <Typography variant="body2" color="text.secondary" sx={{ listStyleType: 'none' }}>
                  submitting the review content to University educators to help the student learn
                  and <br />
                  understand playtesting,
                </Typography>
              </Box>
              <Box
                sx={{
                  gap: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.text.secondary,
                    position: 'relative',
                    top: '-7px',
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ listStyleType: 'none', display: 'block' }}
                >
                  facilitating networking opportunities with game developers and publishers via{' '}
                  <br />
                  student sections on Discord and/or other third party communication tools.
                </Typography>
              </Box>
            </Box>
          </Typography>

          <Typography variant="body2" color="text.secondary" mt="40px">
            I understand that the above-identified Records will be maintained by Game Round in
            accordance with its Privacy Policy, my consent to which is manifested by my use of the
            Platform, found here:{' '}
            <Link href="/privacyPolicy.pdf" target="_blank" color="primary.main">
              https://gameround.co/privacyPolicy.pdf
            </Link>
          </Typography>

          <Typography variant="body2" color="text.secondary" mt="40px">
            I understand that I can terminate this waiver at any time by written notification to
            both parties: <br /> <br />
            <Typography color="text.primary" variant="body2">
              To Game Round:{' '}
            </Typography>
            3435 Wilshire Blvd., Ste. 1400
            <br /> Los Angeles, CA 90010 USA
            <br /> or <br />
            <Link href="mailto:support@gameround.co" target="_blank" color="primary.main">
              support@gameround.co.
            </Link>
          </Typography>

          <Typography variant="body2" color="text.secondary" mt="40px" ref={ref}>
            Upon receipt of said notification, my account on the Platform shall be closed and my
            University shall cease transmitting to Game Round any educational records related to my
            participation on the Platform. I understand that Game Round is not obligated to delete
            or return any of the Records it obtained prior to the date of Game Round’s receipt of
            the aforementioned termination notice.
          </Typography>

          <Divider sx={{ mt: '40px', mb: '20px' }} />
        </Box>
        <Box
          sx={(theme) => ({ height: 62, backgroundColor: theme.palette.gray[80], zIndex: 100 })}
        />
        <Box
          sx={(theme) => ({
            position: 'absolute',
            bottom: 28,
            width: 544,
            zIndex: 1000,
            backgroundColor: theme.palette.gray[80],
            paddingTop: '20px',
          })}
        >
          <Button
            variant="contained"
            fullWidth
            disabled={disabled}
            onClick={() => {
              setValue('FERPA_agree', true);
              closeDialog();
            }}
          >
            I agree
          </Button>
        </Box>
      </Box>
    ),
    [closeDialog, ref, setValue, theme.palette.text.secondary]
  );

  useEffect(() => {
    if (inView) {
      setImmerState((draft) => {
        draft.body = getBody();
      });
    }
  }, [getBody, inView, setImmerState]);

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        formValue
          ? setValue('FERPA_agree', false)
          : showDialog({
              width: '600px',
              height: '800px',
              title: 'FERPA terms and conditions',
              body: getBody(true),
            });
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          cursor: 'pointer',
          pointerEvents: 'none',
        }}
      >
        <FormCheckbox
          name="FERPA_agree"
          label=""
          rules={{ required: 'Thumbnail is required.' }}
          checkboxProps={{ sx: { pointerEvents: 'none' }, readOnly: true, onChange: () => {} }}
        />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 450, lineHeight: '20px' }}
          color={error && !formValue ? 'error.main' : isHover ? 'text.primary' : 'text.secondary'}
        >
          I understand and agree to{' '}
          <span style={{ textDecoration: 'underline' }}>the FERPA terms and conditions</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default FERPA;
