import { Box, Typography } from '@mui/material';

export default function GameInfoOverview({ overview }: { overview?: string }) {
  return (
    <Box marginBottom="32px" paddingTop="20px">
      <Typography variant="h5" marginBottom="40px">
        Overview
      </Typography>
      <Typography
        variant="body1"
        whiteSpace="pre-wrap"
        color="text.secondary"
        marginTop="40px"
        width="827px"
      >
        {overview}
      </Typography>
    </Box>
  );
}
