import { DetailProvider } from './GStoreDetailProvider';
import GStoreDetailMain from './GStoreDetailMain';

export default function GStoreDetail() {
  return (
    <DetailProvider>
      <GStoreDetailMain />
    </DetailProvider>
  );
}
