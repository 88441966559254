import { Dropdown, DropdownOption, DropdownOptionValue, GCheckbox, SearchField } from '@ground/ui';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

import { StudentFilterQuery } from 'api/comment/commentAPI';
import { useGetClassListAPI, useGetUniversityMemberInfoAPI } from 'api/university/universityAPI';
import { useQueryDispatch, useQueryState } from 'context';

interface StudentCountProps {
  universityId?: number;
}

// 선택한 클래스의 (작성한 리뷰 개수 / 학생 수)
const StudentCount = ({ universityId }: StudentCountProps) => {
  const { class_id } = useQueryState<StudentFilterQuery>();
  const { data } = useGetClassListAPI(universityId);

  const selectClass = data?.data?.find((classItem) => classItem.class_id === Number(class_id));

  return (
    <Typography color="text.secondary" variant="body2">
      {selectClass ? `Student ${selectClass.review_cnt ?? 0}/${selectClass.student_cnt ?? 0}` : ''}
    </Typography>
  );
};

export default function StudentReviewFilter() {
  const { data } = useGetUniversityMemberInfoAPI('educator');
  const { class_id, no_feedback, student_name } = useQueryState<StudentFilterQuery>();
  const { changeQuery } = useQueryDispatch();

  const handleChangeClass = (value: DropdownOptionValue) =>
    changeQuery({ class_id: Number(value) });

  const handleSearch = (search: string) => changeQuery({ student_name: search });

  const handleChangeNofeed = (_: ChangeEvent, checked: boolean) =>
    changeQuery({ no_feedback: checked });

  const classListOptions: DropdownOption[] =
    data?.data?.class_list?.map((classInfo) => ({
      name: `[${data.data.university_name}] ${classInfo.class_name}`,
      value: classInfo.class_id,
    })) ?? [];

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Class 선택 */}
        <Box width="468px">
          <Dropdown
            options={classListOptions}
            value={class_id}
            onChangeValue={handleChangeClass}
            withBackground
          />
        </Box>
        {/* 검색 */}
        <SearchField
          defaultValue={student_name ?? ''}
          onSearch={handleSearch}
          placeholder="Search for Student by Name"
          width={250}
          withBackground
        />
      </Box>
      <Box mt="24px" display="flex" justifyContent="space-between">
        <StudentCount universityId={data?.data.university_id} />
        <FormControlLabel
          control={
            <GCheckbox title="No Feedback" checked={no_feedback} onChange={handleChangeNofeed} />
          }
          componentsProps={{ typography: { variant: 'body2' } }}
          label="No Feedback Given"
          sx={{
            margin: 0,
            '& .MuiFormControlLabel-label': { marginLeft: '8px' },
            // labelPlacement="start"인 경우 label margin 조정
            '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
              marginLeft: 0,
              marginRight: '8px',
            },
          }}
        />
      </Box>
    </div>
  );
}
