import { Stack, Typography } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { Dropdown, DropdownProps } from '../atoms';

interface Props extends DropdownProps {
  defaultValue?: string;
  label?: string;
  name: string;
  rules?: RegisterOptions;
}

export default function FormDropdown({
  defaultValue,
  label,
  name,
  rules = {},
  size = 'medium',
  ...props
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue ?? ''}
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Stack rowGap="8px" width={props.fullWidth ? '100%' : props.width}>
          {label && (
            <Typography
              component="label"
              color="text.secondary"
              variant={size === 'large' ? 'subtitle2' : 'body1'}
            >
              {label}
            </Typography>
          )}
          <Dropdown {...field} onChangeValue={onChange} error={!!error} size={size} {...props} />
          {error?.message && (
            <Typography color="error" variant="body3">
              {error.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
}
