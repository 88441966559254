import { Box, Button, Paper, Typography } from '@mui/material';

import { useRecommendGameDialog } from 'hooks';
import recommend_game_back from 'images/profile/recommend_game_back.png';

export default function ProfileRecommendGame() {
  const { showRecommendGameDialog } = useRecommendGameDialog();

  const handleClickRecommendGameBtn = () => {
    showRecommendGameDialog();
  };

  return (
    <Paper
      sx={{
        height: '102px',
        mt: '20px',
        padding: '20px 40px',
        borderRadius: '5px',
        position: 'relative',
      }}
    >
      <img
        src={recommend_game_back}
        alt="recommend game background"
        style={{ position: 'absolute', zIndex: 1, left: 0, top: 0 }}
      />
      <Box display="flex" alignItems="center" position="relative" zIndex="2">
        <Box width="804px" flexGrow={1} pl="60px">
          <Typography variant="button1" color="base.white">
            Recommend a Game
          </Typography>
          <Typography variant="body3" color="text.primary" mt="10px">
            Have an unreleased or upcoming game you want to see on G.Round?
            <br />
            Recommend it to us! We'll do our best to bring that game onto our platform. If your
            recommendation is selected, you'll earn some G.Points!
          </Typography>
        </Box>
        <Button variant="outlined" color="light" onClick={handleClickRecommendGameBtn}>
          Recommend a Game
        </Button>
      </Box>
    </Paper>
  );
}
