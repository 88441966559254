import { styled } from '@mui/material/styles';

import ArtAboutGamerHeader from './articlesGamer/ArtAboutGamerHeader';
import ArtAboutGamerTesting from './articlesGamer/ArtAboutGamerTestingGraph';
import ArtAboutGamerBecomeAGamerList from './articlesGamer/ArtAboutGamerBecomeAGamerList';
import AboutGamerDiscoverBanner from './articlesGamer/AboutGamerDiscoverBanner';
// import AboutGamerGpointBanner from './articlesGamer/AboutGamerGpointBanner';
import ArtAboutGamerClipsAndGpoint from './articlesGamer/ArtAboutGamerClipsAndGpoint';
import ArtAboutGamerStatistics from './articlesGamer/ArtAboutGamerStatistics';
import ArtAboutGamerFAQ from './articlesGamer/ArtAboutGamerFAQ';
import ArtAboutGamerJoiningCommunity from './articlesGamer/ArtAboutGamerJoiningCommunity';
import ArtAboutContact from './articlesGamer/ArtAboutContact';

const PREFIX = 'About';

const classes = {
  article: `${PREFIX}-article`,
  articleBlue: `${PREFIX}-articleBlue`,
  articleBlack: `${PREFIX}-articleBlack`,
};

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  [`& .${classes.article}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  [`& .${classes.articleBlack}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.base.black,
  },

  [`& .${classes.articleBlue}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#3348b2',
  },
}));

export default function About() {
  return (
    <Root>
      <article className={classes.articleBlack}>
        <ArtAboutGamerHeader />
      </article>
      <article className={classes.articleBlue}>
        <ArtAboutGamerBecomeAGamerList />
      </article>
      <AboutGamerDiscoverBanner />
      <article className={classes.article}>
        <ArtAboutGamerTesting />
      </article>
      <article className={classes.article}>
        <ArtAboutGamerClipsAndGpoint />
      </article>
      {/* <AboutGamerGpointBanner /> */}
      <article className={classes.articleBlue}>
        <ArtAboutGamerStatistics />
      </article>
      <article className={classes.articleBlue}>
        <ArtAboutGamerFAQ />
      </article>
      <article className={classes.articleBlack}>
        <ArtAboutContact />
      </article>
      <article className={classes.article}>
        <ArtAboutGamerJoiningCommunity />
      </article>
    </Root>
  );
}
