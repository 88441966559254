import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/dist/types';

import { IOrder, useGetOrderDetail } from 'api/order';
import { ResponseData } from 'types/ResponseType';

interface ContextProps {
  data?: ResponseData<IOrder.GetOrderDetail.Response>;
  isLoading: boolean;
  orderId: string;
  mutate: KeyedMutator<ResponseData>;
}

const GStoreOrderDetailContext = createContext<ContextProps | undefined>(undefined);

interface IProps {
  children: ReactNode;
}

export default function GStoreOrderDetailProvider({ children }: IProps) {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const { isLoading, data, mutate } = useGetOrderDetail(orderId);

  return (
    <GStoreOrderDetailContext.Provider
      value={{
        data,
        isLoading,
        orderId,
        mutate,
      }}
    >
      {children}
    </GStoreOrderDetailContext.Provider>
  );
}

export const useGStoreOrderDetailData = () => {
  const context = useContext(GStoreOrderDetailContext);
  if (!context) throw new Error('GStoreOrderDetailData context not found');
  return context;
};
