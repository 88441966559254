import { forwardRef, Ref } from 'react';
import { Checkbox, CheckboxProps, styled } from '@mui/material';

const Icon = styled('span')<{ error?: boolean }>(({ theme, error }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.opacity.white[10],
  border: error
    ? `1px solid ${theme.palette.error.main}`
    : `1px solid ${theme.palette.opacity.white[20]}`,
  borderRadius: 3,
}));

const StyledCheckbox = styled((props: GCheckboxProps) => (
  <Checkbox
    disableRipple
    checkedIcon={
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="3" fill="currentColor" />
        <path
          d="M15 7L9 13L5 9"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...(props.disabled && { strokeOpacity: 0.2 })}
        />
      </svg>
    }
    icon={<Icon error={props?.error} />}
    {...props}
  />
))(({ theme }) => ({
  width: 20,
  height: 20,
  padding: 0,

  // label styles
  '& + .MuiFormControlLabel-label': {
    color: theme.palette.text.secondary,
  },

  '&.Mui-checked': {
    '& + .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },

  '&.Mui-disabled': {
    opacity: 0.3,
    '& + .MuiFormControlLabel-label': {
      color: theme.palette.gray[40],
    },
  },
}));

export interface GCheckboxProps extends CheckboxProps {
  error?: boolean;
}

const GCheckbox = forwardRef((props: GCheckboxProps, ref: Ref<HTMLButtonElement>) => {
  return <StyledCheckbox ref={ref} {...props} />;
});

export default GCheckbox;
