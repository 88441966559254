import { alpha, Box, Button, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ImageBox } from 'atoms';
import GHashLink from '../GHashLink';

interface StyleProps {
  isHover: boolean;
}

const ClipBlock = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isHover',
})<StyleProps>(({ theme, isHover }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 285,
  borderRadius: 5,
  padding: '12px 12px 20px',
  backgroundColor: isHover ? theme.palette.gray[80] : theme.palette.gray[90],
  position: 'relative',
}));

const StyledLink = styled(GHashLink)({ textDecoration: 'none' });

export interface ClipGameCardProps {
  studioName: string;
  gameTitle: string;
  srcUrl: string;
  numberOfVideos?: number;
  onClickClipButton: () => void;
  to: string;
}

const ClipGameCard: React.FC<ClipGameCardProps> = ({
  studioName,
  gameTitle,
  srcUrl,
  numberOfVideos,
  onClickClipButton,
  to,
}) => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const [isHover, setHover] = useState(false);

  return (
    <ClipBlock
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      isHover={isHover}
    >
      <StyledLink to={to}>
        <Box width="261px" height="169px">
          {inView && (
            <ImageBox
              src={srcUrl}
              alt="game clip"
              resize
              resizeWidth={261}
              resizeHeight={169}
              overlay={!isHover}
              sx={{ borderRadius: '5px' }}
            />
          )}
        </Box>
        <Stack marginTop="20px" alignItems="flex-start">
          <Typography variant="button1" color="text.primary" noWrap>
            {gameTitle}
          </Typography>
          <Typography variant="body3" color="text.secondary" sx={{ marginTop: '4px' }} noWrap>
            {studioName}
          </Typography>
          <Button
            color="light"
            variant="outlined"
            size="tiny"
            sx={(theme) => ({
              marginTop: '20px',
              borderColor: alpha(theme.palette.base.dimWhite, 0.2),
            })}
            onClick={onClickClipButton}
          >
            {numberOfVideos && numberOfVideos > 1
              ? `${numberOfVideos} Videos`
              : `${numberOfVideos} Video`}
          </Button>
        </Stack>
      </StyledLink>
    </ClipBlock>
  );
};

export default ClipGameCard;
