import { Typography, styled, Stack } from '@mui/material';

import { groundDiscordChannel } from 'utils/common';

import logo from './discord_logo.svg';
import moreArrow from './discord_arrow.svg';
import character from './discord_character.svg';

const CardRoot = styled(Stack)({
  width: 285,
  height: 184,
  alignItems: 'flex-start',
  borderRadius: 5,
  background: 'linear-gradient(113.4deg, #556ADD 21.19%, #7DDCC5 112.12%)',
  padding: 28,
  position: 'relative',
});

export default function GameCardSmallDiscord() {
  return (
    <a href={groundDiscordChannel} target="_blank" rel="noopener noreferrer">
      <CardRoot>
        <img src={logo} alt="discord logo" />
        <Typography variant="subtitle1" color="base.white" sx={{ marginTop: '13px' }}>
          Join Our Gaming Community
        </Typography>
        <Typography variant="body3" color="text.primary" sx={{ marginTop: '8px' }}>
          Join the G.Round Discord now to learn
          <br />
          more about our games!
        </Typography>
        <img
          src={moreArrow}
          alt="go to discord"
          width={29}
          height={14}
          style={{ marginTop: '16px' }}
        />
        <img
          src={character}
          alt="discord character"
          style={{ position: 'absolute', right: 0, bottom: 0 }}
        />
      </CardRoot>
    </a>
  );
}
