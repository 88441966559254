import { GIcon } from '@ground/ui';
import { styled, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { ImageBox } from 'atoms';

const PREFIX = 'VideoClipMedia';

const classes = {
  media: `${PREFIX}-media`,
  image: `${PREFIX}-image`,
  timeRoot: `${PREFIX}-timeRoot`,
  timeText: `${PREFIX}-timeText`,
  playIcon: `${PREFIX}-playIcon`,
};

interface StyleProps {
  width: number;
  height: number;
  iconSize?: 'LARGE' | 'SMALL';
}

const Root = styled('div', { shouldForwardProp: (prop) => prop !== 'iconSize' })<StyleProps>(
  ({ theme, width, height, iconSize }) => ({
    [`&.${classes.media}`]: {
      position: 'relative',
      width: width,
      height: height,
    },

    [`& .${classes.image}`]: {
      borderRadius: 5,
    },

    [`& .${classes.timeRoot}`]: {
      position: 'absolute',
      right: 8,
      bottom: 8,
      padding: '4px 8px',
      borderRadius: 5,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },

    [`& .${classes.timeText}`]: {
      fontSize: 11,
      lineHeight: '14px',
      color: theme.palette.base.dimWhite,
    },

    [`& .${classes.playIcon}`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: iconSize === 'LARGE' ? 40 : 32,
      height: iconSize === 'LARGE' ? 40 : 32,
    },
  })
);

// 초를 HH:MM:SS 로 변환해주는 함수
export function getClockTime(time: number) {
  const hasHour = time > 3600;

  return `${hasHour ? `${Math.floor(time / 3600)}:` : ''}${(hasHour
    ? Math.floor(time / 60) % 60
    : Math.floor(time / 60)
  )
    .toString()
    .padStart(hasHour ? 2 : 1, '0')}:${(time % 60).toString().padStart(2, '0')}`;
}

interface Props {
  hover: boolean;
  imageUrl: string;
  playingTime: number;
  width: number;
  height: number;
  iconSize?: 'LARGE' | 'SMALL';
}

export default function VideoClipMedia({ imageUrl, playingTime, width, height, iconSize }: Props) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  return (
    <Root className={classes.media} ref={ref} width={width} height={height} iconSize={iconSize}>
      {inView && (
        <ImageBox
          className={classes.image}
          src={imageUrl}
          alt="clip thumbnail"
          resize={imageUrl.startsWith('http')}
          resizeWidth={width}
          resizeHeight={height}
          width={width}
          height={height}
          overlay
          hoverEffect
          format="webp"
        />
      )}
      {Boolean(playingTime) && (
        <div className={classes.timeRoot}>
          <Typography className={classes.timeText}>{getClockTime(playingTime)}</Typography>
        </div>
      )}

      <GIcon
        sx={{ borderRadius: '50%' }}
        className={classes.playIcon}
        backgroundColor="blue.primary"
        iconName="play"
        iconSize={16}
        iconColor="white"
        size={40}
      />
    </Root>
  );
}
