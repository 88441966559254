import { forwardRef, Ref } from 'react';
import { styled, Switch, SwitchProps } from '@mui/material';

const StyledSwitch = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(
  ({ theme, checked }) => ({
    width: 94,
    height: 40,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 4,
      transitionDuration: '250ms',
      '&.Mui-checked': {
        transform: 'translateX(54px)',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: theme.palette.gray[10],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-input': {
      left: '-200%',
      width: '500%',
    },
    '& .MuiSwitch-thumb': {
      width: 32,
      height: 32,
      backgroundColor: theme.palette.base.dimWhite,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
    },
    '& .MuiSwitch-track': {
      borderRadius: 20,
      backgroundColor: theme.palette.gray[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 250,
      }),

      '&:after': {
        content: `${checked ? '"ON"' : '"OFF"'}`,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: 14,
        lineHeight: '18px',
        ...(checked ? { left: 20 } : { right: 20 }),
        color: checked ? theme.palette.text.primary : theme.palette.text.secondary,
      },
    },
  })
);

const MiniStyledSwitch = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(
  ({ theme, checked }) => ({
    width: 40,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      marginLeft: 4,
      transitionDuration: '250ms',
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: theme.palette.gray[10],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-input': {
      left: '-200%',
      width: '500%',
    },
    '& .MuiSwitch-thumb': {
      width: 20,
      height: 20,
      backgroundColor: theme.palette.base.dimWhite,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      top: '1px',
      left: '-2px',
    },
    '& .MuiSwitch-track': {
      borderRadius: 20,
      backgroundColor: theme.palette.gray[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 250,
      }),

      '&:after': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        ...(checked ? { left: 20 } : { right: 20 }),
        color: checked ? theme.palette.text.primary : theme.palette.text.secondary,
      },
    },
  })
);

const GSwitch = forwardRef((props: SwitchProps, ref: Ref<HTMLButtonElement>) => {
  return props.size === 'small' ? (
    <MiniStyledSwitch ref={ref} {...props} />
  ) : (
    <StyledSwitch ref={ref} {...props} />
  );
});

export default GSwitch;
