import { useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';

import { useCheckUserIntervalAPI } from 'api/recommendGame/recommendGameAPI';
import { RecommendGameDialog } from 'components/dialog';
import { useDialog, useLoading, useValidate } from 'hooks';

export default function useRecommendGameDialog() {
  const { showDialog } = useDialog();
  const { setLoading } = useLoading();
  const { commonValidate } = useValidate();
  const { isLoading, fetch } = useCheckUserIntervalAPI();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const showRecommendGameDialog = useCallback(async () => {
    if (commonValidate()) {
      const response = await fetch();
      //유저가 24시간 내에 게임 추천 제출이력이 있는 경우
      if (response.status === 200) {
        const data = response.data;
        const submittedCount = data && Number(data.data);

        if (submittedCount && submittedCount > 0) {
          showDialog({
            title: 'Confirmation',
            body: 'Your recommendation has already been submitted for the day. Recommendations are only available once a day.',
            secondaryButton: { text: 'Close' },
          });
        } else {
          showDialog({
            title: (
              <>
                Recommend a Game
                <Typography color="blue.primary" component="span" ml="8px" variant="body2">
                  Unreleased or upcoming games only
                </Typography>
              </>
            ),
            body: <RecommendGameDialog />,
            width: 700,
            height: 1000,
            hasScrollBar: true,
          });
        }
      } else {
        //error 처리
      }
    }
  }, [commonValidate, fetch, showDialog]);

  return { loading: isLoading, showRecommendGameDialog };
}
