import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetPointOrderDetailAPI } from 'api/payment/paymentAPI';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import GStoreOrderDetailBanner from '../profile-order/orderDetail/GStoreOrderDetailNotificationBanner';
import GStoreOrderDetailPointTitleBox from './GStoreOderDetailPointTitleBox';
import GStoreOrderDetailPointPaymentBox from './GStoreOrderDetailPointPaymentBox';

export function GStoreOrderDetailPointMain() {
  const { trxId = '' } = useParams<{ trxId: string }>();
  const { isLoading, data } = useGetPointOrderDetailAPI(trxId);

  const orderId = data?.data?.trx_id;

  return isLoading ? (
    <LoadingPage />
  ) : orderId ? (
    <Stack rowGap="60px" sx={{ padding: '60px 0 200px' }}>
      <GStoreOrderDetailPointTitleBox />
      <GStoreOrderDetailPointPaymentBox />
      <GStoreOrderDetailBanner />
    </Stack>
  ) : (
    <NotFound />
  );
}
