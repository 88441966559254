import { Typography, styled } from '@mui/material';

interface StyleProps {
  bgColor?: string;
  textAlign?: 'center' | 'left';
  size?: 'small' | 'medium';
}

const EventTagWrap = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
});

const EventTag = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textAlign' && prop !== 'size',
})<StyleProps>(({ theme, bgColor, textAlign, size }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: textAlign === 'center' ? 'center' : 'flex-start',
  padding: '8px 12px',
  borderTopLeftRadius: size === 'medium' ? 5 : 0,
  backgroundColor: bgColor ?? theme.palette.primary.main,
}));

const EventTagRightShape = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'size',
})<StyleProps>(({ theme, bgColor, size }) => ({
  borderTop: `${size === 'medium' ? 32 : 28}px solid ${bgColor ?? theme.palette.primary.main}`,
  borderRight: `${size === 'medium' ? 13 : 15}px solid transparent`,
}));

export interface EventBadgeProps {
  text: string;
  bg_color: string; // color string
  text_color: string; // color string
  className?: string;
  textAlign?: 'center' | 'left';
  size?: 'small' | 'medium';
}

export default function EventBadge({
  text,
  bg_color,
  text_color,
  className,
  textAlign = 'left',
  size = 'medium',
}: EventBadgeProps) {
  return (
    <EventTagWrap className={className}>
      <EventTag bgColor={bg_color} textAlign={textAlign} size={size}>
        <Typography color={text_color} variant={size === 'medium' ? 'button2' : 'button3'} noWrap>
          {text}
        </Typography>
      </EventTag>
      <EventTagRightShape bgColor={bg_color} size={size} />
    </EventTagWrap>
  );
}
