import { GIcon } from '@ground/ui';
import { Box, Link, Stack, Typography } from '@mui/material';

import { LinkButton } from 'elements';

export default function SupporterApplyStep4Content() {
  const toUrl = `https://gameround.co/supporter-recruitment/confirm`;

  return (
    <Stack textAlign="center" alignItems="center" p="50px 0 110px">
      <GIcon iconName="round_check" iconSize={40} />

      <Typography variant="title" mt="30px" width="852" mb="32px">
        Your application to become a G.Round Supporter has been completed. <br />
        Thank you for applying.
      </Typography>

      <Typography variant="h6" color="base.dimWhite" width="700px">
        We will send you a notification once your supporter approval is finalized.
      </Typography>

      <Box
        p="40px 0"
        mt="80px"
        sx={{ background: (theme) => theme.palette.gray[100] }}
        width="100%"
      >
        <Typography mb="20px" variant="title">
          Non-Disclosure Agreement
        </Typography>

        <Link
          href={`/supporter-recruitment/confirm`}
          color="text.private"
          rel="noopener noreferrer"
          target="_blank"
        >
          {process.env.REACT_APP_ENV === 'DEV'
            ? toUrl.replace('//gameround', '//dev.gameround')
            : toUrl}
        </Link>

        <Typography variant="body2" mt="40px">
          You can access the NDA by following the link given above. <br />
          If you are unable to access the link or wish to review the contract again, <br />
          please contact support at{` `}
          <Link
            sx={{ display: 'inline' }}
            color="text.private"
            href="mailto:support@gameround.co"
            rel="noopener noreferrer"
            target="_blank"
          >
            support@gameround.co
          </Link>
        </Typography>
      </Box>

      <Box mt="60px">
        <LinkButton to={'/'} color="private" variant="contained" size="large">
          G.Round Home
        </LinkButton>
      </Box>
    </Stack>
  );
}
