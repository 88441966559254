import { FormInput } from '@ground/ui';

interface AccountNewPasswordInputProps {
  changing: boolean;
}

export default function AccountOldPasswordInput({ changing }: AccountNewPasswordInputProps) {
  return (
    <FormInput
      label="Password"
      name="old_password"
      type="password"
      placeholder={changing ? 'Insert current password' : '●●●●●●●●●●●'}
      password={changing}
      rules={
        changing
          ? {
              required: 'Please enter a password. ',
            }
          : {}
      }
      readOnly={!changing}
      fullWidth
      withBackground
      autoComplete="off"
    />
  );
}
