import { LoadingButton } from '@mui/lab';

interface Props {
  isPicked: boolean;
  loading: boolean;
  onClick: () => void;
}

export default function ReviewPickButton({ isPicked, loading, onClick }: Props) {
  return (
    <LoadingButton
      loading={loading}
      variant="ghost"
      size="tiny"
      onClick={onClick}
      sx={{ ...(!isPicked && { color: 'primary.main', '&:hover': { color: 'primary.light' } }) }}
    >
      {isPicked ? 'Cancel a Pick' : 'Pick the Review'}
    </LoadingButton>
  );
}
