import { GroundLogo } from '@ground/ui';
import { Box, Paper, Typography } from '@mui/material';

import { LinkButton } from 'elements';

export default function Success() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Paper sx={{ width: '400px', padding: '60px' }}>
        <GroundLogo colorType="light" />
        <Typography
          color="base.white"
          variant="h6"
          sx={{ marginTop: '32px', marginBottom: '20px' }}
        >
          Sign in success!
        </Typography>
        <Typography variant="body2">
          You are logged in to your G.Round account.
          <br />
          Go back to the G.Round launcher.
        </Typography>
        <Typography color="text.secondary" variant="body2" sx={{ marginTop: '20px' }}>
          You can also close this window.
        </Typography>
        <LinkButton fullWidth color="light" variant="outlined" to="/" sx={{ marginTop: '32px' }}>
          Go to G.Round Website
        </LinkButton>
      </Paper>
    </Box>
  );
}
