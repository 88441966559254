import { FormInput } from '@ground/ui';
import { useFormContext } from 'react-hook-form';

import { OptionalQuestionType } from 'types/FormDataType';
import ReviewQuestionTitle from './ReviewQuestionTitle';
interface Props {
  disabled?: boolean;
  index: number;
  first_review: boolean;
}

export default function PrivateReviewQuestionTextField({
  question,
  seq,
  disabled,
  index,
}: OptionalQuestionType & Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const name = `optional.${index}`;

  return (
    <div>
      <input {...register(`${name}.id`)} type="hidden" />
      <ReviewQuestionTitle order={seq ?? 0} error={!!errors[name]} question={question} />

      <FormInput
        disabled={disabled}
        name={`${name}.value`}
        inputProps={{ maxLength: 10000 }}
        placeholder="Please leave your specific comments on the topics mentioned above in the designated box. For additional feedback or comments for the game developers, use this text box. We encourage you to provide constructive reviews, offering valuable insights to help improve the games."
        rules={{
          required: 'Please input at least 10 characters.',
          minLength: { value: 10, message: 'Please input at least 10 characters.' },
          maxLength: 10000,
        }}
        fullWidth
        withBackground
        multiline
        minRows={8}
        sx={{ marginTop: '8px' }}
      />
    </div>
  );
}
