import { FormRadioGroup } from '@ground/ui';
import { Box, Typography } from '@mui/material';
import { SupporterFormProps } from 'api/supporter/useSuppoterTypes';
import { get, useFormContext } from 'react-hook-form';

export default function SupporterApplyNewQuestionChoice({
  question_context,
  disabled = false,
  index,
  required,
  question_selection,
}: SupporterFormProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : undefined }}>
      <Box
        sx={{
          display: 'flex',
          marginTop: '8px',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography color={error ? 'error' : 'text.primary'} variant="body2" width="100px">
            {question_context}
          </Typography>
        </Box>

        <Typography m="0 40px" variant="body2" color="text.secondary2">
          Not Interested
        </Typography>
        <FormRadioGroup
          options={question_selection.map((item) => {
            return {
              id: item.question_seq,
              order: item.question_seq,
              label: item.question_content,
            };
          })}
          disabled={disabled}
          name={`${name}.question_answer`}
          rules={{ required: required }}
          radio={{ align: 'start', color: 'private' }}
          sx={{ display: 'flex', gap: '28px' }}
        />

        <Typography ml="40px" variant="body2" color="text.secondary2">
          Completely Interested
        </Typography>
      </Box>
    </Box>
  );
}
