import { forwardRef } from 'react';

import InputFieldBase, { InputFieldBaseProps } from '../InputFieldBase';

export type PhoneNumberInputProps = Omit<InputFieldBaseProps, 'ref'>;

const PhoneNumberInput = forwardRef(
  (props: PhoneNumberInputProps, ref: React.Ref<HTMLInputElement>) => {
    return <InputFieldBase inputRef={ref} {...props} />;
  }
);

export default PhoneNumberInput;
