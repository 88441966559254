import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  backdropClasses,
  dialogClasses,
  dialogContentClasses,
  dialogTitleClasses,
  styled,
} from '@mui/material';
import { MouseEvent, useCallback } from 'react';

import { DialogState } from 'hooks';
import MobileModalDialogActions from './MobileModalDialogActions';

import { ReactComponent as CloseIcon } from 'images/icons/icon_close.svg';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  // Backdrop
  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  // Paper
  [`& .${dialogClasses.paper}`]: {
    background: theme.palette.gray[80],
    borderRadius: 5,
    margin: 20,
    padding: '40px 28px',
  },
  [`& .${dialogClasses.paperFullWidth}`]: {
    width: 'calc(100% - 40px)',
  },
  // Title
  [`& .${dialogTitleClasses.root}`]: {
    ...theme.typography.h4,
    padding: '0 56px 0 0',
  },
  // Content
  [`& .${dialogContentClasses.root}`]: {
    marginTop: 20,
    padding: 0,
    color: theme.palette.text.secondary,
  },
}));

//props 제거
export default function MobileModalDialog({
  open = false,
  title,
  body,
  onClose,
  onClickClose,
  primaryButton,
  secondaryButton,
}: DialogState) {
  const handleClickClose = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (onClickClose) {
        const response = await onClickClose(e);
        if (response !== false) {
          if (onClose) onClose();
        }
      } else {
        if (onClose) onClose();
      }
    },
    [onClickClose, onClose]
  );

  return (
    <StyledDialog
      aria-labelledby="mobile-dialog-title"
      aria-describedby="mobile-dialog-description"
      disableEscapeKeyDown
      fullWidth
      maxWidth={false}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onClose?.();
        }
      }}
      scroll="body"
    >
      {/* Title Area */}
      <DialogTitle id="mobile-dialog-title">{title}</DialogTitle>
      {/* Close Button */}
      <IconButton
        onClick={handleClickClose}
        sx={{
          position: 'absolute',
          width: 36,
          height: 36,
          top: 8,
          right: 8,
          padding: 0,
          color: 'base.dimWhite',
          opacity: 0.5,
          '&:hover': { opacity: 1 },
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* Content Area */}
      <DialogContent id="mobile-dialog-description">
        {typeof body === 'string' ? (
          <DialogContentText variant="body2">{body}</DialogContentText>
        ) : (
          body
        )}
      </DialogContent>
      {/* Action Button Area */}
      {(primaryButton || secondaryButton) && (
        <MobileModalDialogActions
          onClose={onClose}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )}
    </StyledDialog>
  );
}
