import { numberWithCommas } from '@ground/tools';
import { GRadio } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Link, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';

import { useGetGPointSummary } from 'api/profile/profileAPI';
import { AgreeTermText } from '../AgreeTermText';

const FormBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.gray[90],
  borderRadius: 5,
  padding: 40,
  marginTop: 20,
  gap: 20,
}));

const PayOuterBox = styled(Stack)({
  gap: 8,
});

const PayInnerBox = styled(Stack)({
  gap: 4,
});

const PayItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface Props {
  loading: boolean;
  price: number;
}

export default function OrderPayInfo({ loading, price }: Props) {
  const { data: gpointSummaryData } = useGetGPointSummary();

  const [toggleAgree, setToggleAgree] = useState(false);

  const availablePoint = gpointSummaryData?.data.available_point ?? 0;
  const earnedPoint = gpointSummaryData?.data.earned_point ?? 0;
  const purchasePoint = gpointSummaryData?.data.purchase_point ?? 0;

  return (
    <Box flexBasis={472}>
      <Typography variant="h5">Pay with</Typography>

      <FormBox>
        <GRadio color="primary" label="G.Points" checked />

        <Divider />

        <PayOuterBox>
          <PayItemBox>
            <Typography variant="body1" color="text.primary">
              Available G.Points
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {`${numberWithCommas(availablePoint)} G.P`}
            </Typography>
          </PayItemBox>
          <PayInnerBox>
            <PayItemBox>
              <Typography variant="body3" color="text.secondary2">
                Earned G.Points
              </Typography>
              <Typography variant="body3" color="text.secondary2">
                {`${numberWithCommas(earnedPoint)} G.P`}
              </Typography>
            </PayItemBox>
            <PayItemBox>
              <Typography variant="body3" color="text.secondary2">
                Purchased G.Points
              </Typography>
              <Typography variant="body3" color="text.secondary2">
                {`${numberWithCommas(purchasePoint)} G.P`}
              </Typography>
            </PayItemBox>
          </PayInnerBox>
        </PayOuterBox>

        <Divider />

        <PayOuterBox>
          <PayItemBox>
            <Typography variant="body1" color="text.primary">
              Redeemed G.Points
            </Typography>
            <Typography variant="h4" color="text.primary">
              {`${numberWithCommas(price)} G.P`}
            </Typography>
          </PayItemBox>

          <PayInnerBox>
            <PayItemBox>
              <Typography variant="body3" color="text.secondary2">
                Earned G.Points
              </Typography>
              <Typography variant="body3" color="text.secondary2">
                {`${numberWithCommas(Math.min(price, earnedPoint))} G.P`}
              </Typography>
            </PayItemBox>
            <PayItemBox>
              <Typography variant="body3" color="text.secondary2">
                Purchased G.Points
              </Typography>
              <Typography variant="body3" color="text.secondary2">
                {`${numberWithCommas(price > earnedPoint ? price - earnedPoint : 0)} G.P`}
              </Typography>
            </PayItemBox>
          </PayInnerBox>
        </PayOuterBox>

        <Divider />

        <PayOuterBox>
          <PayItemBox>
            <Typography variant="body3" color="text.secondary2">
              G.Point Balance
            </Typography>
            <Typography variant="body3" color="text.secondary2">
              {`${numberWithCommas(availablePoint - price)} G.P`}
            </Typography>
          </PayItemBox>
        </PayOuterBox>

        <Divider />

        <Typography variant="body3" color="text.secondary2" mb="20px">
          · Products on the G.Point Store are non-refundable and cannot be exchanged, with the
          exception of product defects on G.Round Merchandise.
          <br />
          · Products offered on the G.Point Store may only be purchased with G.Points.
          <br />
          · Merchandise orders can no longer be canceled once packaged and shipped. Digital
          products, once ordered cannot be canceled.
          <br />
          · G.Round is not liable for incorrect addresses.
          <br />
          · The recipient is responsible for any import duties charged by their country.
          <br />· You may cancel your merchandise order before packaging in your order details page
          or by contacting{' '}
          <Link href="mailto:support@gameround.co" color="text.secondary">
            our customer support
          </Link>
          .<br />· Please visit{' '}
          <Link
            href="https://www.notion.so/gameround/G-Points-Wiki-4bedd215f2224ee7aa88f8432d3b9f1d?pvs=4"
            color="text.secondary"
            target="_blank"
          >
            G.Points Wiki
          </Link>{' '}
          for all things related to G.Point Store, Merchandise & Digital Products!
          <br /> · You may request a refund for purchasing G.pints within fourteen days of purchase
          if they were purchased on G.Round and if you have not used any of those points.
        </Typography>

        <AgreeTermText onChange={(value) => setToggleAgree(value)} />

        <Divider />

        <LoadingButton
          loading={loading}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!toggleAgree}
        >
          Place Order
        </LoadingButton>
      </FormBox>
    </Box>
  );
}
