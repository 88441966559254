import { Box, Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClipCardSmall, ClipCardSmallSkeleton } from 'elements/ClipCard';
import { VideoClipDetailType } from 'types/ClipsTypes';
import eventTracker from 'utils/eventTracker';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';
import NoVideoClip from 'views/clips/NoVideoClip';
import TestingGameClipsConainer from 'views/clips/TestingGameClipsContainer';

export interface DetailClipsCardGridProps {
  data?: VideoClipDetailType[];
  loading: boolean;
  count: number;
  id?: number;
}

export default function DetailClipsCardGrid({
  id,
  data,
  loading,
  count,
}: DetailClipsCardGridProps) {
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryGameId = searchParams.get('gameId') || '';
  const queryVideoId = searchParams.get('videoId') || '';

  const [gameId, setGameId] = useState(Number(queryGameId));
  const [videoId, setVideoId] = useState(Number(queryVideoId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (gameId: number, videoId: number) => {
    setGameId(gameId);
    setVideoId(videoId);
    setShowModal(true);
    setSearchParams({ videoId: String(videoId), gameId: String(gameId) });
  };

  const closeModal = () => {
    setShowModal(false);
    setGameId(0);
    setVideoId(0);
    setSearchParams({});
  };

  const isOpen = showModal || !!(gameId && videoId);

  return (
    <>
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="612px"
          open={isOpen}
          onClose={closeModal}
          body={
            <TestingGameClipsConainer gameId={Number(gameId)} videoId={videoId} disableThumbnail />
          }
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      <Grid container rowGap="60px" columnGap="24px">
        {loading ? (
          loadingSkeletonList.map((skeleton) => (
            <Grid item key={skeleton}>
              <ClipCardSmallSkeleton />
            </Grid>
          ))
        ) : data && data.length > 0 ? (
          data.map((element) => (
            <Grid item key={element.id}>
              <ClipCardSmall
                userName={element.streamer_name}
                country={element.country}
                videoTitle={element.title}
                srcUrl={element.thumbnail_url}
                duration={element.duration}
                streamerUrl={element.streamer_url}
                onCardClickHandler={() => {
                  openModal(element.game_id, element.id);
                  eventTracker('detailclips_video_play', {
                    event_label: element.id || '',
                  });
                }}
              />
            </Grid>
          ))
        ) : (
          <Box width="100%" marginTop="40px">
            <NoVideoClip />
          </Box>
        )}
      </Grid>
    </>
  );
}
