import { Grid } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SectionContainer, { ContainerLink } from 'components/SectionContainer';
import { ClipCardMedium } from 'elements/NewClipCard';
import { featureClips } from 'utils/clipsData';
import { useLog } from 'utils/userLog';
import { useClipPopupData } from 'views/clips//ClipsPopup/ClipPopupProvider';
import { FeatureBottom } from 'views/clips/ClipsPopup/Area/ClipBottomArea';
import { ClipUpperArea } from 'views/clips/ClipsPopup/Area/ClipUpperArea';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';

export default function HomeFeaturedClips() {
  const { gxcTag } = useLog();
  const { dataIndex, setDataIndex } = useClipPopupData();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryVideoId = searchParams.get('videoId') || '';
  const cardType = searchParams.get('popup');
  const [videoId, setGameId] = useState(Number(queryVideoId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (id: number) => {
    gxcTag('click', {
      area: 'featured_clips',
      ui: 'clips_card',
      parameters: { video_id: id },
    });
    gxcTag('view', {
      area: 'featured_clips_popup',
      ui: 'N/A',
      parameters: { video_id: id },
    });

    setGameId(id);
    setDataIndex(id);
    setSearchParams({ popup: 'feature', videoId: String(id) });
    setShowModal(true);
  };

  const closeModal = () => {
    gxcTag('click', { area: 'featured_clips_popup', ui: 'close_x' });

    setSearchParams({});
    setShowModal(false);
  };

  const isOpen = showModal || !!(String(videoId) && cardType === 'feature');

  const clipUpper = featureClips;
  const clipBottom = featureClips[dataIndex];

  return (
    <SectionContainer
      title="Featured Clips"
      containerOptions={
        <ContainerLink
          linkText="Go to Clips"
          linkUrl="/clips"
          onClick={() => gxcTag('click', { area: 'featured_clips', ui: 'view_more' })}
        />
      }
    >
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="608px"
          open={isOpen}
          onClose={closeModal}
          body={
            <>
              <ClipUpperArea initialSlide={videoId} clipsData={clipUpper} disableThumbnail={true} />
              <FeatureBottom {...clipBottom} />
            </>
          }
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      <Grid container columnSpacing="21px" rowSpacing="30px">
        {featureClips.slice(0, 3).map((element) => (
          <Grid item key={element.id} gap="60px">
            <ClipCardMedium
              title={element.title}
              videoTitle={element.description}
              srcUrl={element.thumbnail_url}
              onCardClickHandler={() => openModal(element.game_id)}
              type="feature"
            />
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
  );
}
// 임시 목 데이터
