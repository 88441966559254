import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ClipCardMedium, ClipCardMediumSkeleton } from 'elements/NewClipCard';
import { FeaturedClipsType } from 'types/ClipsTypes';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';
import TestingGameClipsConainer from 'views/clips/TestingGameClipsContainer';
export interface AllClipsCardGridProps {
  data: FeaturedClipsType[];
  loading: boolean;
  count: number;
}

export default function AllClipsListCard({ data, loading, count }: AllClipsCardGridProps) {
  const navigate = useNavigate();
  const { gxcTag } = useLog();
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryGameId = searchParams.get('gameId') || '';
  const queryVideoId = searchParams.get('videoId') || '';

  const [gameId, setGameId] = useState(Number(queryGameId));
  const [videoId, setVideoId] = useState(Number(queryVideoId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (gameId: number, videoId: number) => {
    gxcTag('view', {
      area: 'all_clips_popup',
      parameters: {
        game_id: gameId,
        clips_id: videoId,
      },
    });
    setGameId(gameId);
    setVideoId(videoId);
    setShowModal(true);
    setSearchParams({ videoId: String(videoId), gameId: String(gameId) });
  };

  const closeModal = () => {
    gxcTag('click', {
      area: 'all_clips_popup',
      ui: 'close_x',
      parameters: {
        game_id: gameId,
        clips_id: videoId,
      },
    });

    navigate(-1);
    setShowModal(false);
    setGameId(0);
    setVideoId(0);
  };

  const isOpen = showModal || !!(gameId && videoId);

  return (
    <>
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="612px"
          open={isOpen}
          onClose={closeModal}
          body={
            <TestingGameClipsConainer
              gameId={Number(gameId)}
              videoId={videoId}
              disableThumbnail
              area="all_clips_popup"
            />
          }
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      <Grid container columnSpacing="21px" pt="28px" pb="60px" sx={{ gridRowGap: '60px' }}>
        {loading
          ? loadingSkeletonList.map((skeleton) => (
              <Grid item key={skeleton}>
                <ClipCardMediumSkeleton />
              </Grid>
            ))
          : data.map((element) => (
              <Grid item key={element.video_id} gap="60px">
                <ClipCardMedium
                  userName={element.streamer_name}
                  country={element.streamer_country}
                  videoTitle={element.video_title}
                  srcUrl={element.thumbnail_url}
                  duration={element.duration}
                  streamerUrl={element.streamer_profile_url}
                  onCardClickHandler={() => {
                    gxcTag('click', {
                      area: 'list',
                      ui: 'clips_card',
                      parameters: {
                        game_id: element.game_id,
                        clips_id: element.video_id,
                      },
                    });
                    openModal(element.game_id, element.video_id);
                    eventTracker('allclips_video_play', {
                      event_label: element.video_id || '',
                    });
                  }}
                  type="all"
                />
              </Grid>
            ))}
      </Grid>
    </>
  );
}
