import { FormDropdown } from '@ground/ui';
import { Box, Paper } from '@mui/material';

import { SupporterFormProps } from 'api/supporter/useSuppoterTypes';
import { get, useFormContext } from 'react-hook-form';
import { useSupporterApplyData } from '../../SupporterApplyProvider';
import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

export default function SupporterApplyQuestionDropbox({
  question_context,
  disabled = false,
  index,
  required,
  required_option,
}: SupporterFormProps) {
  const { countryList } = useSupporterApplyData();
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);
  const subtitle = required_option === 'Mobile' ? 'モバイルテスト可能なユーザーに必須' : '';

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1 }}>
      <Box>
        <SupporterQuestionTitle
          question={question_context}
          error={!!error}
          required={required}
          subtitle={subtitle}
        />
      </Box>
      <Paper>
        <FormDropdown
          name={`${name}.question_context`}
          placeholder="Select country"
          options={countryList}
          withBackground
          onChange={(value) => {
            setValue(`${name}.question_context`, value);
          }}
          rules={{ required: true }}
        />
      </Paper>
    </Box>
  );
}
