import { Dropdown, DropdownOptionValue, GIcon, SearchField } from '@ground/ui';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useClipsAllClipAPI } from 'api/clips/clipsAPI';
import { SectionContainer } from 'components';
import { useQueryDispatch, useQueryState } from 'context';
import { useLog } from 'utils/userLog';
import AllClipsListCard from './AllClipsListCard';
import NoVideoClip from './NoVideoClip';

const allClipsDropDownOptions = [
  { name: 'Latest', value: 'createdAt' },
  { name: 'Most Viewed', value: 'viewedCount' },
  { name: 'Most Liked', value: 'likedCount' },
];

interface AllClipsListOptions {
  size: number;
  search_keyword: string;
  sort: string;
}

export default function AllClipsSection() {
  const { gxcTag } = useLog();
  const { size, search_keyword, sort } = useQueryState<AllClipsListOptions>();
  const { changeQuery } = useQueryDispatch();

  const { data, isLoading, isValidating, setSize } = useClipsAllClipAPI(
    sort,
    size,
    search_keyword,
    true
  );

  const mergedClipList = useMemo(
    () => data?.map?.((responseData) => responseData.data.map((clip) => clip)).flat() ?? [],
    [data]
  );

  const totalCount = useMemo(() => data?.[0].next_page?.total_count ?? 0, [data]);

  return (
    <SectionContainer
      title="All Clips"
      sx={{ width: '1200px' }}
      containerOptions={
        <Box display="flex" columnGap="12px">
          <SearchField
            placeholder="Search Clips or Streamer Name"
            width={300}
            hideInputFirst
            onSearch={(keyword) => {
              gxcTag('click', {
                area: 'list',
                ui: 'search',
                parameters: {
                  search_keyword: keyword ?? '',
                },
              });
              changeQuery({ search_keyword: keyword ?? '' });
            }}
            inputProps={{ maxLength: 50 }}
            defaultValue={search_keyword}
          />
          <Dropdown
            size="small"
            options={allClipsDropDownOptions}
            value={sort ? sort : allClipsDropDownOptions[0].value}
            maxWidth="154px"
            onChangeValue={(option: DropdownOptionValue) => {
              //change일떄 로그적재 parameter 추가시 index 필요 요구사항이 사용자가 보이는 name값으로 요청
              const sort = allClipsDropDownOptions?.filter((item) => item.value === option)[0]
                ?.name;

              gxcTag('click', {
                area: 'list',
                ui: 'dropbox',
                parameters: {
                  sort: sort,
                },
              });
              changeQuery({ sort: option.toString() });
            }}
          />
        </Box>
      }
    >
      {search_keyword && (
        <Box display="flex" alignItems="center" mt="28px" mb="32px">
          <Button
            onClick={() => {
              gxcTag('click', {
                area: 'list',
                ui: 'back_to_all_clips',
              });
              changeQuery({ search_keyword: '' });
            }}
            size="small"
            startIcon={<GIcon iconName="arrow_left" iconSize={16} />}
            variant="ghost"
          >
            Back to All Clips
          </Button>
          <Typography ml="20px" variant="body2" color="text.secondary">
            {isLoading ? (
              <Skeleton width={180} />
            ) : (
              `${totalCount} result${totalCount > 1 ? 's' : ''} match your search.`
            )}
          </Typography>
        </Box>
      )}
      {!isValidating && mergedClipList.length === 0 && !search_keyword ? (
        <NoVideoClip />
      ) : (
        <>
          <AllClipsListCard data={mergedClipList} loading={isLoading} count={size} />
          {!isValidating && data && data[data.length - 1]?.next_page?.has_next_page && (
            <Box textAlign="center" marginTop="20px">
              <Button
                color="dark"
                onClick={() => {
                  gxcTag('click', {
                    area: 'list',
                    ui: 'load_more',
                  });
                  setSize((size) => size + 1);
                }}
                variant="contained"
              >
                Load More
              </Button>
            </Box>
          )}
        </>
      )}
    </SectionContainer>
  );
}
