import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAxiosWithAuth, useDialog, useError } from 'hooks';
import eventTracker from 'utils/eventTracker';
import AccountNewPasswordInput from './AccountNewPasswordInput';
import AccountOldPasswordInput from './AccountOldPasswordInput';

interface FormData {
  old_password: string;
  password: string;
}

interface Props {
  email: string;
}

export default function AccountInfoPassword({ email }: Props) {
  const [changing, setChanging] = useState(false);
  const { showSimpleDialog } = useDialog();
  const { showError } = useError();

  const methods = useForm<FormData>({ mode: 'onChange' });
  const { reset, handleSubmit } = methods;
  const { isLoading: sumbmitLoading, refetch: submit } = useAxiosWithAuth(
    'sessions',
    { method: 'PATCH' },
    true
  );

  useEffect(() => {
    !changing && reset({ old_password: '', password: '' }, { keepIsSubmitted: false });
  }, [changing, reset]);

  const onSubmit = async (formData: FormData) => {
    const response = await submit({ data: { email, ...formData } });
    if (response.status === 200) {
      if (response.data.result_code === 'OK') {
        showSimpleDialog('Success', 'The password change was successful.');
        setChanging(false);
      } else if (response.data.result_code === '402') {
        showSimpleDialog('Error', 'Your current password is incorrect. Please check again.');
      }
    } else {
      showError(response);
    }
    eventTracker('profile_profile_accountinfo_save');
  };

  const handleClickChangePassword = () => {
    setChanging(!changing);
    eventTracker(`profile_profile_accountinfo_${changing ? 'cancel' : 'changepassword'}`);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DevTool control={methods.control} placement="top-right" />
        <Box display="flex" alignItems="flex-end" gap="8px">
          <AccountOldPasswordInput changing={changing} />
          <Button
            color={changing ? 'light' : 'primary'}
            variant="contained"
            onClick={handleClickChangePassword}
            sx={{ alignSelf: 'flex-start', marginTop: '32px' }}
          >
            {changing ? 'Cancel' : 'Change Password'}
          </Button>
        </Box>

        <Collapse in={changing}>
          <Box display="flex" alignItems="flex-end" marginTop="20px" gap="8px">
            <AccountNewPasswordInput />
            <LoadingButton
              color="primary"
              loading={sumbmitLoading}
              variant="contained"
              type="submit"
              sx={{ width: '87px', alignSelf: 'flex-start', marginTop: '32px' }}
            >
              Save
            </LoadingButton>
          </Box>
          <Typography variant="body3" color="text.secondary2" width="435px" marginTop="12px">
            Password must be 8-16 characters and include at least one lowercase letter, one
            uppercase letter, and a number.
          </Typography>
        </Collapse>
      </form>
    </FormProvider>
  );
}
