import { baseUrls } from 'api/baseUrls';

export const storeQueries = {
  // Merchandise
  fetchShippingCountryList(id: string) {
    return `${baseUrls.pointV2}/products/sale-products/${id}/shipping/countries`;
  },
  fetchMerchandiseSaleProduct(id: string) {
    return `${baseUrls.pointV2}/products/sale-products/${id}`;
  },

  // Digital Game
  fetchDigitalGameProductList() {
    return `${baseUrls.pointV2}/products/digital-games`;
  },
  fetchDigitalGameProduct(id: number) {
    return `${baseUrls.pointV2}/products/digital-games/${id}`;
  },

  // Digital Goods
};
