import { Box, Stack, Typography } from '@mui/material';
import { NaviLink } from 'atoms';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import VideoClipMedia from './VideoClipMedia';
export interface ClipCardLargeProps {
  videoTitle?: string;
  videoDescription?: string;
  cardThumbnailUrl: string;
  storeLink?: string;
  onCardClick: () => void;
  type?: 'feature' | 'store';
  onClickNavLink?: () => void;
}

const ClipCardLarge: React.FC<ClipCardLargeProps> = ({
  videoTitle,
  videoDescription,
  cardThumbnailUrl,
  onCardClick,
  storeLink,
  type,
  onClickNavLink,
}) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Box borderRadius="5px">
      <Box ref={hoverRef} onClick={onCardClick} sx={{ cursor: 'pointer' }}>
        <VideoClipMedia
          hover={isHover}
          imageUrl={cardThumbnailUrl}
          playingTime={0}
          width={590}
          height={332}
          iconSize="LARGE"
        />
      </Box>

      <Stack alignItems="center" direction="row" mt="24px">
        <Box width="520px" flex="1 1 0">
          <Typography variant="h5" color="text.primary" noWrap>
            {videoTitle}
          </Typography>

          <Typography
            mt="12px"
            variant="body1"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {videoDescription}
          </Typography>

          {type === 'store' && (
            <NaviLink text="Go to the Store page" to={storeLink} onClick={onClickNavLink} />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default ClipCardLarge;
