import { GCheckbox, LoadingWrapper } from '@ground/ui';
import {
  Box,
  Divider,
  FormControlLabel,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetPublicReviewDashboardAPI, useGetReviewListV2API } from 'api/comment/commentAPI';
import { NoData } from 'elements';
import { useGetDetailData } from 'views/detail/DetailProvider';
import PointIssuance from './PointIssuance';
import ReviewContainerByAdmin from './ReviewContainerByAdmin';
import ReviewDialog from './ReviewDialog';

export default function ReviewsMainAdmin() {
  const { id = '' } = useParams<{ id: string }>();
  const [filterOptions, setFilterOptions] = useState({
    picked: false,
    liked: false,
    replied: false,
    purchased: false,
  });
  const { isOldPackage } = useGetDetailData();
  const { search } = useLocation();
  const { review_id } = queryString.parse(search, { parseNumbers: true });
  const theme = useTheme();
  const { data: reviewDashboardData, mutate: mutateDashboard } = useGetPublicReviewDashboardAPI(id);
  const {
    data: reviewListData,
    isLoading,
    mutate,
  } = useGetReviewListV2API(
    id,
    filterOptions.picked,
    filterOptions.liked,
    filterOptions.replied,
    filterOptions.purchased
  );

  const reviewList = reviewListData?.data ?? [];

  const hasReviewList = Array.isArray(reviewList) && reviewList.length > 0;

  const hasReviewCount =
    typeof reviewDashboardData?.data?.review_count === 'number' &&
    reviewDashboardData?.data?.review_count > 0;

  return (
    <Box>
      <>
        {!isOldPackage && (
          <>
            <Typography variant="body1" color="text.secondary2">
              Until the testing deadline, you will receive{' '}
              <span style={{ color: theme.palette.base.white, display: 'inline-flex' }}>
                up to{' '}
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    width="20px"
                    height={24}
                    style={{ margin: '0px 4px' }}
                  />
                ) : (
                  reviewDashboardData?.data?.goal_amount
                )}{' '}
                reviews
              </span>
              . You can{' '}
              <span style={{ color: theme.palette.base.white, display: 'inline-flex' }}>
                select{' '}
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    width="20px"
                    height={24}
                    style={{ marginLeft: '4px' }}
                  />
                ) : (
                  reviewDashboardData?.data?.pick_amount
                )}
              </span>{' '}
              of these for display on the site.
              <br /> Until your selection, the 20 most recent reviews will be temporarily showcased.
              Please make your picks promptly to
              <br /> highlight the highest quality reviews!
            </Typography>
            <Divider
              sx={{
                marginTop: '28px',
                marginBottom: hasReviewCount ? '40px' : '0px',
              }}
            />
          </>
        )}
        {hasReviewCount && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '24px',
              marginBottom: '40px',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <FormControlLabel
                control={
                  <GCheckbox
                    title="Picked"
                    checked={filterOptions.picked}
                    onChange={(_, checked) => {
                      setFilterOptions((options) => ({ ...options, picked: checked }));
                    }}
                  />
                }
                componentsProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
                label="Picked"
                sx={{
                  margin: 0,
                  '& .MuiFormControlLabel-label': { marginLeft: '8px' },
                  // labelPlacement="start"인 경우 label margin 조정
                  '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                    marginLeft: 0,
                    marginRight: '8px',
                  },
                }}
              />
              <FormControlLabel
                control={
                  <GCheckbox
                    title="Liked"
                    value={filterOptions.liked}
                    onChange={(_, checked) => {
                      setFilterOptions((options) => ({ ...options, liked: checked }));
                    }}
                  />
                }
                componentsProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
                label="Liked"
                sx={{
                  margin: 0,
                  '& .MuiFormControlLabel-label': { marginLeft: '8px' },
                  // labelPlacement="start"인 경우 label margin 조정
                  '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                    marginLeft: 0,
                    marginRight: '8px',
                  },
                }}
              />
              <FormControlLabel
                control={
                  <GCheckbox
                    title="Replied"
                    value={filterOptions.replied}
                    onChange={(_, checked) => {
                      setFilterOptions((options) => ({ ...options, replied: checked }));
                    }}
                  />
                }
                componentsProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
                label="Replied"
                sx={{
                  margin: 0,
                  '& .MuiFormControlLabel-label': { marginLeft: '8px' },
                  // labelPlacement="start"인 경우 label margin 조정
                  '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                    marginLeft: 0,
                    marginRight: '8px',
                  },
                }}
              />

              {reviewDashboardData?.data?.is_purchased && (
                <FormControlLabel
                  control={
                    <GCheckbox
                      title="Purchased"
                      value={filterOptions.purchased}
                      onChange={(_, checked) => {
                        setFilterOptions((options) => ({ ...options, purchased: checked }));
                      }}
                    />
                  }
                  componentsProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
                  label="Purchased"
                  sx={{
                    margin: 0,
                    '& .MuiFormControlLabel-label': { marginLeft: '8px' },
                    // labelPlacement="start"인 경우 label margin 조정
                    '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                      marginLeft: 0,
                      marginRight: '8px',
                    },
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Box>
                <Typography variant="body1" color="text.secondary2">
                  Picked :{' '}
                  <Typography color="text.primary" variant="body1" component="span">
                    {reviewDashboardData?.data?.picked_count}
                  </Typography>
                  /{reviewDashboardData?.data?.pick_amount}
                </Typography>
              </Box>
              {/* 포인트 발행 */}
              <PointIssuance
                mutateReviewList={() => {
                  mutate();
                  mutateDashboard();
                }}
              />
            </Box>
          </Box>
        )}
      </>

      <LoadingWrapper isLoading={isLoading} sx={{ height: 474 }} size={64}>
        {hasReviewList ? (
          <Stack gap="36px" px="60px">
            {reviewList.map((review) => (
              <ReviewContainerByAdmin
                key={review.id}
                reviewData={review}
                mutateReviewList={mutate}
                mutateReviewCount={mutateDashboard}
              />
            ))}
          </Stack>
        ) : (
          <Box sx={{ height: 474 }}>
            <NoData
              title={
                <>
                  Be the first to leave a Public Review!
                  <br />
                  Let others know about your opinions!
                </>
              }
            />
          </Box>
        )}
      </LoadingWrapper>

      {/* game id, review id가 있을 때 review 팝업 보여주기 */}
      {id && Number(review_id) ? (
        <ReviewDialog gameId={Number(id)} reviewId={Number(review_id)} testType="public" isAdmin />
      ) : null}
    </Box>
  );
}
