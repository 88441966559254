import { useFetch, useSWRFetch } from 'api';
import { NotificationDetailType, NotificationReadResponse } from 'types/NotificationType';
import { stringifiedQueryParams } from './stringifiedQueryParams';

import { NotificationTarget } from 'types/NotificationType';

const baseNotiUrlv2 = 'https://api-notification2.gameround.co/api';

export function useGetNotificationListAPI() {
  const url = `${baseNotiUrlv2}/notification/notification-send/select-message-list?${stringifiedQueryParams(
    { target: NotificationTarget['PC Web_Badge'] }
  )}`;

  return useSWRFetch<NotificationDetailType>({ key: url, useToken: true, swrConfig: {} });
}

export function useGetNotificationCountAPI() {
  const url = `${baseNotiUrlv2}/notification/notification-send/select-message-list-count?${stringifiedQueryParams(
    { target: NotificationTarget['PC Web_Badge'] }
  )}`;

  return useSWRFetch<number>({
    key: url,
    useToken: true,
    swrConfig: { refreshInterval: 1000 * 30 },
  });
}

export function useReadNotificationAPI(badgeId: number) {
  return useFetch<NotificationReadResponse>(
    `${baseNotiUrlv2}/notification/notification-send/read-notification-send/${badgeId}`,
    {
      method: 'POST',
    },
    true
  );
}
