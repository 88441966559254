import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { Button } from '@mui/material';

import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

export interface GnbItemType {
  to: string;
  text: string;
}

function GnbItem({ to, text }: GnbItemType) {
  const { gxcTag } = useLog();
  const eventTrackerPageName = to.split('/')[1];
  const gxcTagUIName = text.toLocaleLowerCase();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  const handleClick = () => {
    gxcTag('click', { area: 'gnb', ui: `menu_${gxcTagUIName}` });
    eventTracker(`gnb_${eventTrackerPageName}`);
  };

  return (
    <Button
      component={Link}
      sx={(theme) => ({
        ...theme.typography.menu,
        color: match ? theme.palette.text.primary : theme.palette.text.secondary,
        '&:hover': {
          color: theme.palette.text.primary,
          backgroundColor: 'transparent',
        },
      })}
      to={to}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}

export default GnbItem;
