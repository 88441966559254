import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Base64 from 'crypto-js/enc-base64';
import hmacSHA1 from 'crypto-js/hmac-sha1';

import { useFetch } from 'api';
import { useDialog } from 'hooks';
import { LoginPayload } from 'models/login';
import { Dispatch, iRootState } from 'store';
import { LoadingPage } from 'views/main/LoadingPage';

// 시크릿키 노출 위험이 있지만 검증만 하기 위한 것이니 우선 이렇게 처리(여유가 되면 백엔드에 넘겨서 검증하는 쪽으로..)
const SECRET_KEY = 'AEIxN0NFRTI0RTRBNUE1Rjg2MUFGMUVFMzFBQkMx';
const baseUrl = 'gameround.co';

interface LauncherAuthWithTokenProps {
  /** Launcher token to send as external URL. base64(UTF-8(Launcher Token)) */
  launcherToken: string; // 문자열의 구조 - "LT"+ ":" + Timestamp + ":" + G-Token
  /** Redirect URL to state the redirect URL after the authentication. base64(redirect URL) */
  state: string;
  /** To verify the launcher token to prevent the modifications especially timestamp. base64(HMAC-SHA1(Key, Launcher Token)) */
  verify: string;
}

// 런처 토큰을 이용한 웹 자동 로그인 기능 정의
// https://www.notion.so/gameround/Login-to-the-Ground-web-from-the-launcher-8917e495ed644c748c0defffea18b8bd
export default function LauncherAuthWithToken({
  launcherToken,
  state,
  verify,
}: LauncherAuthWithTokenProps) {
  const navigate = useNavigate();
  const { identifier } = useSelector((state: iRootState) => state.login);
  const { login } = useDispatch<Dispatch>();
  const { showDialog } = useDialog();
  const { error, fetch } = useFetch<LoginPayload>('sessions/token', { method: 'POST' });

  // 로그인 완료 후 redirect할 URL
  const redirectUrl = useMemo(() => {
    const decodedRedirectUrl = window.atob(decodeURIComponent(state));
    return decodedRedirectUrl.split(baseUrl)[1] ?? decodedRedirectUrl;
  }, [state]);

  // 런처에서 받은 token으로 로그인을 처리하는 함수
  const loginWithToken = useCallback(
    async (token: string) => {
      const response = await fetch({ token });
      if (response.status === 200 && response.data.result_code === 'OK') {
        if (!identifier) {
          // 로그인 정보가 없는 경우 런처의 토큰으로 로그인 시켜준다
          login.login(response.data.data);
        } else if (identifier === response.data.data.identifier) {
          // 동일한 사용자인 경우 새로 로그인 하지 않음
          navigate(redirectUrl, { replace: true });
        } else {
          // 기존에 로그인 된 사용자와 런처의 사용자가 다른 경우
          showDialog({
            title: 'Account Confirmation',
            body: (
              <>
                You are not logged in to the launcher account.
                <br />
                Do you want to automatically sign in with that account?
              </>
            ),
            primaryButton: {
              text: 'Yes',
              callback: () => {
                // 런처의 토큰으로 로그인
                login.login(response.data.data);
              },
            },
            secondaryButton: {
              text: 'No',
              callback: () => {
                // 기 로그인 계정 사용
                navigate(redirectUrl, { replace: true });
              },
            },
          });
        }
      } else {
        // 로그인 과정에서 에러가 난 경우(토큰이 맞지 않거나 API에 에러 발생 시)
        navigate('error', { replace: true });
      }
    },
    [fetch, identifier, login, navigate, redirectUrl, showDialog]
  );

  // 런처의 로그인 토큰 검증 후 로그인 시도
  useEffect(() => {
    const decodedLauncherToken = window.atob(decodeURIComponent(launcherToken));

    const hashedToken = hmacSHA1(decodedLauncherToken, SECRET_KEY).toString(Base64);

    // 토큰 유효성 검증
    const isVerified = decodeURIComponent(verify) === hashedToken;

    if (isVerified) {
      // const timestamp = decodedLauncherToken.split(':')[1];
      const groundToken = decodedLauncherToken.split(':')[2];
      loginWithToken(groundToken);
    } else {
      navigate('error', { replace: true });
    }
  }, [launcherToken, loginWithToken, navigate, verify]);

  return error ? (
    <Typography sx={{ padding: '16px' }}>Something went wrong during login.</Typography>
  ) : (
    <LoadingPage />
  );
}
