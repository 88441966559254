import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ClipPopupProvider from 'views/clips/ClipsPopup/ClipPopupProvider';
import Clips from './clips/Clips';
import Comment from './comment/Comment';
import GameInfo from './gameinfo/GameInfo';
import HowToPlay from './howtoplay/HowToPlay';
import News from './news/News';

export default function DetailRouter() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="info" element={<GameInfo />} />
      <Route path="review/*" element={<Comment />} />
      <Route
        path="clips"
        element={
          <ClipPopupProvider>
            <Clips />
          </ClipPopupProvider>
        }
      />
      <Route path="news" element={<News />} />
      <Route path="guide" element={<HowToPlay />} />
      <Route path="*" element={<Navigate to="info" replace state={location?.state} />} />
    </Routes>
  );
}
