import { useLocalStorage } from 'usehooks-ts';

declare type SetValue<T> = (value: T) => void;

type LocalStorageKey = 'tooltip' | 'toplineBanner' | 'floatingActionBtn';
// type LocalStoageTypeAll = LocalStorageTooltip | LocalStorageTopLineBanner | LocalStorageFAB;

// 앞으로 추가될 모든 local storage type
export interface LocalStorageTooltip {
  notification: Record<string, boolean>;
}

export interface LocalStorageTopLineBanner {
  launcherReinstall: boolean;
}

export interface LocalStorageFAB {
  discordBannerStatus: boolean;
}

//
export function useGroundLocalStorage<T>(key: LocalStorageKey, initialValue: T): [T, SetValue<T>] {
  const [value, setValue] = useLocalStorage<T>(key, initialValue);

  return [
    value,
    (newValue) => {
      setValue((value) => ({ ...value, ...newValue }));
    },
  ];
}

export function useLocalStorageTooltip() {
  return useGroundLocalStorage<LocalStorageTooltip>('tooltip', {
    notification: {},
  });
}

export function useLocalStorageToplineBanner() {
  return useGroundLocalStorage<LocalStorageTopLineBanner>('toplineBanner', {
    launcherReinstall: true,
  });
}

export function useLocalStorageFAB() {
  return useGroundLocalStorage<LocalStorageFAB>('floatingActionBtn', {
    discordBannerStatus: true,
  });
}
