import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { GameCardLargest, GameCardLargestSkeleton } from 'elements/GameCard';
import { GameCardProps } from 'types/GameCardTypes';
import { EventBadgeType } from 'types/EventBadgeType';

export interface GameCardListLargestProps {
  data: GameCardProps[];
  loading: boolean;
  count: number;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  eventBadgeList?: EventBadgeType[];
  likeButton?: boolean;
  onClickCard?: (gameId?: number) => void;
}

export default function GameListLargestCard({
  data,
  loading,
  count,
  hoverVideoPlay,
  eventBadgeList,
  likeButton,
  onClickCard,
}: GameCardListLargestProps) {
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  return (
    <Grid container rowGap="60px" columnGap="20px">
      {loading
        ? loadingSkeletonList.map((skeleton) => (
            <Grid item key={skeleton}>
              <GameCardLargestSkeleton />
            </Grid>
          ))
        : data.length > 0
        ? data.map((game) => (
            <Grid item key={game.id}>
              <GameCardLargest
                {...game}
                hoverVideoPlay={hoverVideoPlay}
                likeButton={likeButton}
                event_badge={eventBadgeList?.find((badge) => badge.target_id === game.id)}
                onClick={onClickCard}
              />
            </Grid>
          ))
        : // TODO: error 일 때 표시할 카드가 필요
          loadingSkeletonList.map((skeleton) => (
            <Grid item key={skeleton}>
              <GameCardLargestSkeleton />
            </Grid>
          ))}
    </Grid>
  );
}
