import { Box, Divider, Typography } from '@mui/material';

import { format } from 'date-fns';

interface Props {
  title?: string;
  summary?: string;
  startAt?: string;
  endedAt?: string;
  deliveryAt?: string;
}

export default function ProductPanel({ title, summary, startAt, endedAt, deliveryAt }: Props) {
  return (
    <Box sx={{ width: '820px' }}>
      {title ? (
        <Typography variant="h2" sx={{ paddingTop: '60px' }}>
          {title}
        </Typography>
      ) : null}

      <Typography variant="body1" color="text.primary" mt="28px" sx={{ wordBreak: 'break-word' }}>
        {summary ?? 'No data'}
      </Typography>

      {startAt && endedAt && (
        <Typography
          variant="body3"
          color="text.secondary"
          mt="28px"
          sx={{ wordBreak: 'break-word' }}
        >
          Purchase Period : {format(new Date(startAt), 'MMM dd, yyyy')} -{' '}
          {format(new Date(endedAt), 'MMM dd, yyyy')}
          {deliveryAt ? ` · Shipping Date : ${format(new Date(deliveryAt), 'MMM dd, yyyy')}` : ''}
        </Typography>
      )}

      <Divider sx={{ marginTop: '60px', marginBottom: '120px' }} />
    </Box>
  );
}
