import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { StudentInfoRequest, usePatchStudentInfoAPI } from 'api/university/universityAPI';
import { useError } from 'hooks';
import type { StudentFormData } from 'types/UniversityType';
import StudentForm from './StudentForm';

interface Props {
  defaultValues: StudentFormData;
  mutateInfo: () => void;
}

export default function StudentDetails({ defaultValues, mutateInfo }: Props) {
  const { isLoading, fetch: submit } = usePatchStudentInfoAPI();
  const { showError } = useError();

  const methods = useForm<StudentFormData>({ defaultValues });

  const onSubmit = async (formData: StudentFormData) => {
    const request: Omit<StudentInfoRequest, 'universityId'> = {
      fullName: formData.name.trim(),
      studentNumber: formData.student_number,
      majorList: formData.major.map((v) => v.major_name),
      classList: formData.class.filter((v) => v.class_id !== null).map((v) => v.class_id as number),
    };

    const response = await submit(request);
    if (response.status === 200 && response.data.result?.code === 0) {
      mutateInfo();
    } else {
      showError(response);
    }
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="bottom-left" />
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <StudentForm />
        <Box display="flex" justifyContent="flex-end" mt="32px">
          <LoadingButton
            size="medium"
            color="primary"
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            Save Changes
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
}
