import { Box, Typography } from '@mui/material';

import { useGamelistAPI } from 'api/gamelist/gamelistAPI';
import GameListMediumContainer from 'components/GameList/GameListMediumContainer';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import { LinkButton } from 'elements';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';

export default function HomePlayingNow() {
  // below code should be same with api call in GameListMediumContainer.tsx file
  const { isLoading, data } = useGamelistAPI(GameStatusType.TESTING, true);
  const { gxcTag } = useLog();

  return (
    <SectionContainer
      title="Play Now"
      containerOptions={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isLoading && typeof data?.data?.length === 'number' && (
            <>
              <Typography variant="body1" color="text.secondary" mr="8px">
                Active Playtests
              </Typography>
              <Typography variant="subtitle1" mr="28px">
                {data?.data?.length}
              </Typography>
            </>
          )}
          <LinkButton
            to={'/testing'}
            variant="ghost"
            onClick={() => {
              gxcTag('click', { area: 'play_now', ui: 'view_more' });
            }}
          >
            Go to All Games
          </LinkButton>
        </Box>
      }
      titleSideButton={
        <Typography variant="body1" color="blue.hover">
          Help These Developers
        </Typography>
      }
      sx={{ zIndex: 1 }}
    >
      <GameListMediumContainer
        cardtype={GameStatusType.TESTING}
        name="play-now"
        hoverVideoPlay
        showViewMoreCard
        useSlider={false}
        includeSurveyGame
        isLauncherImage
        hasMoreBtn
        fillEmptyCard
        fillHeight
        onClickLeftArrow={() => {
          gxcTag('click', {
            area: 'play_now',
            ui: 'left_arrow',
          });
        }}
        onClickRightArrow={() => {
          gxcTag('click', {
            area: 'play_now',
            ui: 'right_arrow',
          });
        }}
        onClickCard={(gameId) => {
          gxcTag('click', {
            area: 'play_now',
            ui: 'game_card',
            parameters: { game_id: gameId + '' },
          });
        }}
        onLikeClicked={({ liked, id }) => {
          gxcTag('click', {
            area: 'upcoming',
            ui: 'like',
            parameters: { like_check: liked ? 1 : 0, game_id: id },
          });
        }}
      />
    </SectionContainer>
  );
}
