import { Skeleton, styled } from '@mui/material';

const PREFIX = 'ClipCardSmallSkeleton';

const classes = {
  root: `${PREFIX}-root`,
  textWrap: `${PREFIX}-textWrap`,
  picture: `${PREFIX}-picture`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 285,
    padding: '12px 12px 20px',
    background: theme.palette.gray[90],
  },
  [`& .${classes.textWrap}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 20,
    flexDirection: 'column',
  },
  [`& .${classes.picture}`]: {
    borderRadius: 5,
  },
}));

export default function ClipCardSmallSkeleton() {
  return (
    <Root className={classes.root}>
      <Skeleton className={classes.picture} width={261} height={169} variant="rectangular" />

      <div className={classes.textWrap}>
        <Skeleton width="80%" height={18} />
        <Skeleton width="80%" height={17} sx={{ marginTop: '4px' }} />
        <Skeleton width={75} height={28} sx={{ marginTop: '20px' }} />
      </div>
    </Root>
  );
}
