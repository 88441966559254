import { FormAutocompleteOption, GIcon } from '@ground/ui';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';

export interface FormFieldListItemProps {
  name: string;
  index: number;
  remove?: () => void;
  defaultValue: FieldValues;
  gameLinkTypeList: FormAutocompleteOption[];
}

interface FormFieldListProps {
  name: string;
  component: ComponentType<FormFieldListItemProps>;
  gameLinkTypeList: FormAutocompleteOption[];
  initialValue: Record<string, any>;
  minCount?: number;
  maxCount?: number;
}

export default function RecommendGameAddWebsiteFieldList({
  name,
  component: Component,
  gameLinkTypeList,
  initialValue,
  minCount = 0,
  maxCount = 99,
}: FormFieldListProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    keyName: 'key',
  });

  useEffect(() => {
    if (fields.length < minCount) {
      append(initialValue);
    }
  }, [append, fields.length, initialValue, minCount]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ minHeight: '32px' }}
      >
        <Typography variant="body1" color="text.secondary">
          Website
        </Typography>

        {maxCount > fields.length && (
          <Button
            endIcon={<GIcon iconName="plus" iconSize={16} iconColor="base.black" />}
            variant="contained"
            onClick={() => append(initialValue)}
            size="small"
            color="light"
          >
            Add
          </Button>
        )}
      </Box>

      <List sx={{ pt: 0 }}>
        {fields.map((item, index) => (
          <Box key={item.key}>
            <ListItem disableGutters>
              <Component
                name={`${name}.${index}`}
                index={index}
                remove={minCount < fields.length ? () => remove(index) : undefined}
                defaultValue={item}
                gameLinkTypeList={gameLinkTypeList}
              />
            </ListItem>
          </Box>
        ))}
      </List>
    </>
  );
}
