import { useGamelistAPI } from 'api/gamelist/gamelistAPI';
import GameListSmallContainer from 'components/GameList/GameListSmallContainer';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';

export default function HomeUpcomingGames() {
  const { gxcTag } = useLog();
  const { isLoading, isValidating, data, error } = useGamelistAPI(GameStatusType.UPCOMING, false);
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  return isDataNotExist ? null : (
    <SectionContainer title="Upcoming Games">
      <GameListSmallContainer
        cardtype={GameStatusType.UPCOMING}
        count={4}
        name="upcoming"
        hoverVideoPlay
        useSlider
        hasDefaultThumbnail
        onClickCard={(game_id) =>
          gxcTag('click', { area: 'upcoming', ui: 'game_card', parameters: { game_id } })
        }
        onLikeClicked={({ liked, id }) =>
          gxcTag('click', {
            area: 'upcoming',
            ui: 'like',
            parameters: { like_check: liked ? 1 : 0, game_id: id },
          })
        }
        onRecommendAGameClick={() => gxcTag('click', { area: 'upcoming', ui: 'suggest_games' })}
      />
    </SectionContainer>
  );
}
