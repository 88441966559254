import { LoadingWrapper } from '@ground/ui';
import { Box, Button, Typography } from '@mui/material';

import { useLauncherUserSummaryAPI } from 'api/detail/detailAPI';
import { useLauncherDownloadRecordAPI, useLauncherVersionCheckAPI } from 'api/launcher';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import LauncherReinstallText from './LauncherReinstallText';
import { PlayGameDivider, PlayGameItemRow } from './PlayGameComponents';

interface PlayGameDialogBodyGeneralProps {
  data?: GameDetailProps;
}

export default function PlayGameDialogBodyGeneral({ data }: PlayGameDialogBodyGeneralProps) {
  const { fetch } = useLauncherDownloadRecordAPI();
  const { data: launcherInstallData, isLoading: loadingLauncherInstall } =
    useLauncherVersionCheckAPI('game');
  const { data: launcherUserSummary, isLoading: launcherUserSummaryLoading } =
    useLauncherUserSummaryAPI(data?.id);

  const gameFile = data?.game_files?.[0];

  const hasGameKey = !!(data && gameFile?.has_game_key);
  const installed = !!launcherUserSummary?.data?.installed_at;

  const handleClickDownload = async () => {
    // launcher download 횟수 기록
    fetch({ game_id: data?.id ?? null });
    // 런처 설치파일 다운로드
    window.open(gameFile?.main_url, '_self', 'noopener noreferrer');

    data?.id && eventTracker('playgamepopup_ground_download', { event_label: data.id });
    twttr?.conversion.trackPid('o91dm', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/HcjxCOOdsMQDEI29kb8C' });
  };

  const handleClickPlay = () => {
    if (data?.id) {
      window.open(`GameRoundLauncher://GameRound/start/${data.id}`, '_self', 'noopener noreferrer');
      eventTracker('playgamepopup_ground_playgame', { event_label: data.id });
    }
    twttr?.conversion.trackPid('o91dn', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/V1iDCOadsMQDEI29kb8C' });
  };

  return (
    <LoadingWrapper isLoading={loadingLauncherInstall || launcherUserSummaryLoading}>
      {launcherInstallData?.data.isPopup ? (
        <LauncherReinstallText />
      ) : (
        <Typography variant="body2" color="text.secondary">
          {hasGameKey
            ? 'This game required a game key. You will need to claim and activate the game key to start playing. Have you installed the G.Round Launcher?'
            : 'Have you installed the G.Round launcher?'}
        </Typography>
      )}
      <Box marginTop="20px" padding="20px" bgcolor="gray.60">
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            No, I need the G.Round Launcher.
          </Typography>
          <Button
            disabled={gameFile?.main_off}
            onClick={handleClickDownload}
            variant="ghost"
            size="small"
          >
            Download
          </Button>
        </PlayGameItemRow>
        <PlayGameDivider />
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            {hasGameKey
              ? installed
                ? 'Yes, I will activate a game key on game.'
                : 'Yes, I need to claim and activate a game key.'
              : 'Yes, the G.Round Launcher is installed'}
          </Typography>
          <Button
            disabled={gameFile?.sub_off}
            onClick={handleClickPlay}
            variant="contained"
            size="small"
          >
            {hasGameKey && !installed ? 'Activate in Launcher' : 'Play Game'}
          </Button>
        </PlayGameItemRow>
      </Box>
    </LoadingWrapper>
  );
}
