import { GameListSmallContainer, SectionContainer } from 'components';
import { GameStatusType } from 'types/GameCommonTypes';

export default function TestingSection() {
  return (
    <SectionContainer title="Ongoing Games">
      <GameListSmallContainer
        cardtype={GameStatusType.TESTING}
        name="testing"
        useSlider={false}
        includeSurveyGame
        useAll
        fillHeight
      />
    </SectionContainer>
  );
}
