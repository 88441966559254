import { Box } from '@mui/material';

import { Typography } from '@mui/material';

import { useGameInfoStudioInfoAPI } from 'api/detail/detailAPI';

import GameInfoStudioInfoPaper from './GameInfoStudioInfoPaper';

export default function GameInfoStudioInfo({ studio_id }: { studio_id: number }) {
  const { data } = useGameInfoStudioInfoAPI(studio_id);

  return (
    <Box marginTop="120px" marginBottom={5}>
      <Typography variant="h5" marginBottom="40px">
        Studio Info
      </Typography>
      {data && <GameInfoStudioInfoPaper {...data?.data} />}
    </Box>
  );
}
