import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/dist/types';

import { useGetAllReviewsCountAPI } from 'api/comment/commentAPI';
import { ResponseData } from 'types/ResponseType';

export interface CommentProps {
  gameId: number;
  reviewsCountData?: ResponseData<number>;
  reviewsCountFetch: KeyedMutator<ResponseData<number>>;
}

const CommentStateContext = createContext<CommentProps | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export default function CommentInGameDetailProvider({ children }: Props) {
  const { id: gameId = '' } = useParams<{ id: string }>();

  const { data: reviewsCountData, mutate: reviewsCountFetch } = useGetAllReviewsCountAPI(gameId);

  return (
    <CommentStateContext.Provider
      value={{
        gameId: Number(gameId),
        reviewsCountData,
        reviewsCountFetch,
      }}
    >
      {children}
    </CommentStateContext.Provider>
  );
}

export const useComment = () => {
  const context = useContext(CommentStateContext);
  if (!context) throw new Error('CommentData context not found');
  return context;
};
