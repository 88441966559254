import { Stack, alpha, inputBaseClasses, type TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isAfter, isBefore, isValid } from 'date-fns';
import { ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

import FormLabel from './FormLabel';

interface FormDatePickerProps {
  /** form name */
  name: string;
  /** check required and invalid date */
  required?: boolean;
  /**
   * Min selectable date
   * @default Date(1900-01-01)
   */
  minDate?: Date | number;
  /**
   * Max selectable date
   * @default Date(2100-01-01)
   */
  maxDate?: Date | number;
  label?: ReactNode;
  size?: TextFieldProps['size'];
  helperText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  // rules?: RegisterOptions;
  /**
   * paper와 같이 background color 값에 따라 사용
   * @default false
   */
  withBackground?: boolean;
}

export default function FormDatePicker({
  name,
  required = false,
  minDate = new Date('1900-01-01'),
  maxDate = new Date('2100-01-01'),
  label,
  size,
  helperText,
  disabled,
  readOnly,
  withBackground,
}: FormDatePickerProps) {
  const rules: RegisterOptions = {
    required: { value: required, message: 'Date is required.' },
    validate: {
      invalidate: (date) => !date || isValid(date) || 'Please enter a valid date.',
      isAfter: (date) =>
        !date ||
        isAfter(date, minDate) ||
        `The date must be after ${format(minDate, 'dd/MM/yyyy')}.`,
      isBefore: (date) =>
        !date ||
        isBefore(date, maxDate) ||
        `The date must be before ${format(maxDate, 'dd/MM/yyyy')}.`,
    },
  };

  const {
    field,
    fieldState: { error },
    formState,
  } = useController({ name, disabled, rules });

  return (
    <Stack>
      {label ? <FormLabel label={label} size={size} /> : null}
      <DatePicker
        {...field}
        format="dd/MM/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          openPickerButton: {
            sx: ({ palette }) => ({
              color: palette.base.dimWhite,
              opacity: 0.5,
              ':hover': { opacity: 1 },
            }),
          },
          openPickerIcon: {
            sx: {
              width: '16px',
              height: '16px',
            },
          },
          textField: (props) => ({
            variant: 'outlined',
            error: !!error || props.error,
            helperText: error?.message || helperText,
            hiddenLabel: true,
            size,
            // InputFieldBase와 동일한 형태를 유지하기 위해 적용
            sx: ({ palette }) => ({
              [`& .${inputBaseClasses.root}`]: {
                borderRadius: '3px',
                border: '1px solid transparent',
                backgroundColor: alpha(palette.gray[withBackground ? 60 : 80], readOnly ? 0.3 : 1),

                ':hover': {
                  borderColor: palette.gray[withBackground ? 50 : 60],
                  [`&.${inputBaseClasses.disabled}`]: {
                    borderColor: 'transparent',
                  },
                },

                [`&.${inputBaseClasses.focused}`]: {
                  borderColor: palette.gray[withBackground ? 50 : 60],
                },

                [`&.${inputBaseClasses.error}`]: {
                  borderColor: palette.error.main,
                },

                [`&.Mui-success`]: {
                  borderColor: palette.success.main,
                },
              },

              // inner input styles
              [`& .${inputBaseClasses.input}`]: {
                padding: 'unset',
                '::placeholder': {
                  color: palette.opacity.white[20],
                  opacity: 1,
                },
              },
              // outlined input의 border를 제거하기 위해 적용
              fieldset: {
                display: 'none',
              },
            }),
          }),
        }}
        disabled={disabled || formState.disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}
