import { FormDropdown } from '@ground/ui';

import { genderOptions } from 'utils/commonData';
import { useLog } from 'utils/userLog';

interface Props {
  isMobile?: boolean;
  logArea: string;
}

export default function SignupGender({ isMobile, logArea }: Props) {
  const { gxcTag } = useLog();

  const handleClickGender = () => {
    gxcTag('click', { area: logArea, ui: 'select_gender' });
  };

  return (
    <FormDropdown
      label="Gender"
      name="sex"
      placeholder="Select gender"
      options={genderOptions}
      rules={{ required: 'Please select a gender' }}
      size={isMobile ? 'large' : 'medium'}
      withBackground
      onClick={handleClickGender}
    />
  );
}
