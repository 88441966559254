import { Components, linkClasses } from '@mui/material';

const TypographyTheme: Components = {
  MuiTypography: {
    styleOverrides: {
      root: {
        textUnderlinePosition: 'under',
        [`&.${linkClasses.underlineAlways}`]: { textDecorationColor: 'currentColor' },
      },
    },
    defaultProps: {
      variantMapping: {
        title: 'h4',
        subtitle3: 'h6',
        button1: 'p',
        button2: 'p',
        button3: 'p',
        body3: 'p',
        small1: 'p',
        small2: 'p',
        menu: 'p',
      },
    },
  },
};

export default TypographyTheme;
