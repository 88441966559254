import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import GStoreRouter from './GStoreRouter';

export default function GStore() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // redirect from gpointstore to store
  useEffect(() => {
    if (pathname.startsWith('/gpointstore')) {
      navigate(`/${pathname.substring(7)}`, { replace: true });
    }
  }, [navigate, pathname]);

  return <GStoreRouter />;
}
