import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import lottie from 'lottie-web/build/player/lottie_light';
import { useEffect, useRef } from 'react';

import likeIcon from 'images/icons/like.svg';
import unlikeIcon from 'images/icons/unlike.svg';
import lottieLikeButton from 'lotties/like-button.json';

const LottieImage = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  width: 32,
  height: 32,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

interface IconButtonFormProps {
  isActivated?: boolean;
  readonly icon: 'favorite' | string;
  readonly size?: 'medium' | 'small';
  readonly text?: string | number;
  readonly onClick?: () => void;
}

/** 아직 Like Button 밖에 없어 다양한 아이콘을 사용하기 위해서는 기능 확장이 필요함 */
export default function IconButtonForm({
  isActivated = false,
  icon,
  size = 'medium',
  text,
  onClick,
}: IconButtonFormProps) {
  // 로티 애니메이션으로 구성되는 element
  const element = useRef<HTMLDivElement>(null);
  const prevIsActivated = useRef(isActivated);

  const height = size === 'small' ? 16 : 24;

  // 상태가 false였다가 true로 바뀐 경우에만 애니메이션 재생
  useEffect(() => {
    if (icon === 'favorite' && prevIsActivated.current === false && isActivated) {
      if (element.current) {
        const lottieItem = lottie.loadAnimation({
          container: element.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: lottieLikeButton,
        });
        lottieItem.addEventListener('complete', function () {
          lottieItem.removeEventListener('complete');
          lottie.destroy();
        });
      }
    }
    // 이전 상태값을 업데이트하여 상태변경시에 정상 작동하도록 함
    prevIsActivated.current = isActivated;
  }, [icon, isActivated]);

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={onClick} disableRipple size="small" sx={{ padding: 0 }}>
        {icon === 'favorite' ? (
          <>
            <img height={height} src={isActivated ? likeIcon : unlikeIcon} alt="favorite" />
            <LottieImage ref={element} />
          </>
        ) : (
          <img height={height} src={icon} alt={icon} />
        )}
      </IconButton>
      <Typography
        variant={size === 'medium' ? 'body2' : 'body3'}
        color="text.secondary"
        marginLeft="8px"
      >
        {text}
      </Typography>
    </Box>
  );
}
