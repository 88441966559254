import { useDispatch } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';

import { LinkButton } from 'elements';
import { Dispatch } from 'store';

export default function NotSupportPrivate() {
  const dispatch = useDispatch<Dispatch>();

  const handleClickDesktop = async () => {
    dispatch.mobile.transformDesktopView();
  };

  return (
    <Stack alignItems="center" sx={{ padding: '120px 20px 40px' }}>
      <Box textAlign="center">
        <Typography color="text.secondary" mt="20px" variant="body2">
          This site is restricted to the mobile browser.
          <br />
          Please access this website through a desktop browser instead.
        </Typography>
      </Box>
      <Stack gap="20px" mt="60px" width="100%">
        <Button color="primary" onClick={handleClickDesktop} size="largest" variant="contained">
          Take me to the Desktop Site
        </Button>
        <LinkButton size="largest" to="/" variant="ghost">
          G.Round Home
        </LinkButton>
      </Stack>
    </Stack>
  );
}
