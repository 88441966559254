import { GIcon } from '@ground/ui';
import { Box, Button, styled } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

const Cover = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 9999,
  left: 0,
  right: 0,
  bottom: 0,
  height: '192px',
  background: `linear-gradient(0deg, #121212 0%, rgba(18, 18, 18, 0.00) 100%)`,
}));

interface Props {
  height: string | number;
}

export const HaveMoreContainer = ({ children, height }: PropsWithChildren<Props>) => {
  const [shortText, setShortText] = useState(true);

  return (
    <Box
      position="relative"
      height={shortText ? height : undefined}
      width="100%"
      zIndex={999}
      sx={{ overflow: 'hidden', paddingBottom: shortText ? undefined : '82px' }}
    >
      {children}
      {shortText && <Cover />}
      <Box textAlign="center" position="absolute" bottom={0} width="100%" sx={{ zIndex: 9999999 }}>
        <Button
          color="light"
          size="large"
          variant="outlined"
          endIcon={
            !shortText ? (
              <GIcon iconName="arrow_up" iconSize={16} />
            ) : (
              <GIcon iconName="arrow_down" iconSize={16} />
            )
          }
          onClick={() => setShortText(!shortText)}
          sx={{
            borderRadius: '3px',
            padding: '12px 24px',
            border: '1px solid rgba(255,255,255,0.3)',
          }}
        >
          View {shortText ? 'more' : 'less'}
        </Button>
      </Box>
    </Box>
  );
};
