import { CSSProperties, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { TableCell, Typography } from '@mui/material';

import { TypographyColor } from 'types/CommonTypes';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'initial',
}));

interface DataTableHeadCellProps {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?: TypographyColor;
  children: ReactNode;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
}

export default function DataTableHeadCell({
  align = 'center',
  color,
  children,
  style,
  headerStyle,
}: DataTableHeadCellProps) {
  return (
    <StyledTableCell align={align} variant="head" style={headerStyle}>
      <Typography variant="body3" color={color ?? 'text.secondary'} style={style}>
        {children}
      </Typography>
    </StyledTableCell>
  );
}
