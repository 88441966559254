import { Route, Routes } from 'react-router-dom';

import AuthFacebook from './AuthFacebook';

export default function Auth() {
  return (
    <Routes>
      <Route path="facebook" element={<AuthFacebook />} />
    </Routes>
  );
}
