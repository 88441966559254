import { numberWithCommas } from '@ground/tools';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useGetPointOrderDetailAPI } from 'api/payment/paymentAPI';
import { format } from 'date-fns';
import { isNumber } from 'lodash';
import { useParams } from 'react-router-dom';

interface Props {
  title: string;
  content?: string;
  hasCapitalLetter?: boolean;
}

function SectionBox({ title, content, hasCapitalLetter }: Props) {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="body3" color="text.secondary" mb="8px">
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ textTransform: hasCapitalLetter ? 'capitalize' : undefined }}
      >
        {content}
      </Typography>
    </Box>
  );
}

export default function GStoreOrderDetailPointPaymentBox() {
  const { trxId = '' } = useParams<{ trxId: string }>();
  const { data } = useGetPointOrderDetailAPI(trxId);
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h5" mb="20px">
        Payment Information
      </Typography>

      <Stack
        direction="row"
        columnGap="40px"
        divider={<Divider flexItem orientation="vertical" />}
        sx={{ background: theme.palette.gray[90], borderRadius: '5px', padding: '40px' }}
      >
        <Stack gap="40px" flex={1}>
          <SectionBox
            title="Date Purchased"
            content={
              data?.data?.created_at
                ? `${format(new Date(data.data.created_at), 'MMM dd, yyyy hh:mm a')}(PT)`
                : ''
            }
          />
          <SectionBox title="Stripe Payment ID" content={data?.data?.pg_id} />
        </Stack>
        <Stack gap="40px" flex={1}>
          <SectionBox title="Payment Method" content="Credit / Debit Card" />
          <SectionBox title="Payment Status" content={data?.data?.pay_status} hasCapitalLetter />
        </Stack>
        <Stack flex={1}>
          <SectionBox
            title="Total"
            content={
              (typeof data?.data?.pay_amount !== 'undefined' && isNumber(data?.data?.pay_amount)
                ? numberWithCommas(data?.data?.pay_amount?.toFixed(2))
                : '') + ` ${data?.data?.currency?.toUpperCase()}`
            }
          />
        </Stack>
      </Stack>
    </Box>
  );
}
