import { ContentsLayout, PageLayout } from 'components';
import ClipPopupProvider from 'views/clips/ClipsPopup/ClipPopupProvider';
import HomeAbout from './HomeAbout';
import HomeBanner from './HomeBanner';
import HomeBestGames from './HomeBestGames';
import HomeFeatured from './HomeFeatured';
import HomeFeaturedClips from './HomeFeaturedClips';
import HomeGPointBanner from './HomeGPointBanner';
import HomePickedReview from './HomePickedReview';
import HomePlayingNow from './HomePlayingNow';
import HomePopupLayer from './HomePopupLayer';
import HomeUpcomingGames from './HomeUpcomingGames';
import WelcomeDialog from './dialog/WelcomeDialog';
import HomePrivateTestGame from './private-game/HomePrivateTestGame';

function Home() {
  return (
    <>
      <PageLayout>
        {/* banner */}
        <HomeBanner />
        {/* guide */}
        <ContentsLayout>
          {/* Featured */}
          <HomeFeatured />
          {/* Playing Now */}
          <HomePlayingNow />
          {/* Private Test Games */}
          <HomePrivateTestGame />
          {/* Upcoming */}
          <HomeUpcomingGames />
          {/* Best Rated Games */}
          <HomeBestGames />
          {/* G.Points Banner */}
          <HomeGPointBanner />
          {/* Popular Clips */}
          <ClipPopupProvider>
            <HomeFeaturedClips />
          </ClipPopupProvider>
          {/* Picked Review */}
          <HomePickedReview />
        </ContentsLayout>
      </PageLayout>
      {/* About */}
      <HomeAbout />
      {/* Popup Layer */}
      <HomePopupLayer />
      {/* Signup complete Dialog */}
      <WelcomeDialog />
    </>
  );
}

export default Home;
