import { FormAutocompleteOption } from '@ground/ui';
import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useRecommendGameAPI } from 'api/recommendGame/recommendGameAPI';
import { useDialog, useError } from 'hooks';
import { CheckcData } from './RecommendGameDuplicateCheck';
import RecommendGamePaper from './RecommendGamePaper';

export interface RecommendGameOriginGenreType {
  name: string;
  value: string;
}

export interface SubmitGameLinkType {
  submit_game_link_type: string;
  submit_game_link_url: string;
}

export interface FormOriginData {
  submit_game_title: string;
  game_platform: string;
  submit_reason: string;
  submit_studio: string;
  studio_contact: string;
  submit_game_genre: RecommendGameOriginGenreType[];
  submit_game_link: SubmitGameLinkType[];
  platform: string;
  submit_studio_country: FormAutocompleteOption;
}

export interface RecommendGameEditedGenreType {
  genre_id: number;
}

export interface RecommendGameEditedGameType {
  game_type_id: number;
}

export interface FormEditedData {
  submit_game_title: string;
  game_platform: string;
  submit_reason: string;
  submit_studio: string;
  studio_contact: string;
  submit_game_genre: RecommendGameEditedGenreType[];
  submit_game_game_type: RecommendGameEditedGameType[];
  submit_game_link: SubmitGameLinkType[];
  platform: string;
  submit_studio_country: string;
}

export default function RecommendGameForm() {
  const { showDialog } = useDialog();
  const { showError } = useError();

  const [duplicateCheckStatus, setDuplicateCheckStatus] = useState<
    CheckcData['game_type'] | undefined
  >(undefined);

  //유저가 게임 중복 체크 후에 다시 게임 타이틀 입력을 변경했다면 게임 중복 체크를 하지 않은 상태로 변경
  const [isDuplicateChecked, setIsDuplicateChecked] = useState<boolean>(false);

  const { isLoading: submitLoading, fetch: submitApi } = useRecommendGameAPI();

  const methods = useForm<FormOriginData>({
    defaultValues: {
      submit_game_title: '',
      game_platform: '1',
      submit_game_genre: [],
      submit_game_link: [],
      submit_reason: '',
      submit_studio: '',
      submit_studio_country: undefined,
      studio_contact: '',
    },
  });

  const onSubmit = async (formData: FormOriginData) => {
    // 게임 중복 체크를 하지 않았거나,게임 검색 결과가 등록 불가능한 게임 응답값이면 제출을 막는다.
    if (
      isDuplicateChecked === false ||
      duplicateCheckStatus === undefined ||
      duplicateCheckStatus === 'game' ||
      duplicateCheckStatus === 'recommended' ||
      duplicateCheckStatus === 'rejected' ||
      duplicateCheckStatus === 'upcoming'
    ) {
      methods.setFocus('submit_game_title');
      return;
    }

    const formEdited: FormEditedData = {
      ...formData,
      game_platform:
        formData.game_platform === '1'
          ? 'pc'
          : formData.game_platform === '2'
          ? 'console'
          : 'mobile',
      submit_game_genre: formData.submit_game_genre
        .filter((item) => item.value.startsWith('genre_'))
        .map((item) => ({
          genre_id: Number(item.value.split('_')[1]),
        })),
      submit_game_game_type: formData.submit_game_genre
        .filter((item) => item.value.startsWith('type_'))
        .map((item) => ({
          game_type_id: Number(item.value.split('_')[1]),
        })),
      submit_studio_country: formData.submit_studio_country?.value.toString() ?? '',
    };

    const response = await submitApi(formEdited);
    if (response?.status === 200 && response.data?.result_code === 'OK') {
      showDialog({
        title: 'Thank you!',
        body: (
          <>
            Thank you for recommending this game.
            <br />
            We will do our best to bring this game onto our platform!
          </>
        ),
        primaryButton: { text: 'OK' },
      });
    } else {
      showError(response, 'Recommend a Game');
    }
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="bottom-left" />
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        <Typography color="text.secondary" variant="body2" marginBottom="40px">
          Have an unreleased or upcoming game you want to see on G.Round?
          <br />
          Recommend it to us! We'll do our best to bring that game onto our platform.
          <br />
          If your recommendation is selected, you'll earn G.Points!
          <br />
          <Typography component="span" color="text.primary" variant="body2">
            Recommended games must be unreleased and not releasing in the next 2-3 months.
          </Typography>
        </Typography>

        <RecommendGamePaper
          isDuplicateChecked={isDuplicateChecked}
          setIsDuplicateChecked={setIsDuplicateChecked}
          setDuplicateCheckStatus={setDuplicateCheckStatus}
        />
        <Divider sx={{ marginTop: '32px', marginBottom: '40px' }} />
        {/* Submit Button Area */}
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton loading={submitLoading} type="submit" variant="contained" size="large">
            Submit
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
}
