import { Link, Typography } from '@mui/material';

import { GameFile } from 'types/GameDetailTypes';
import HowToPlayControlGuide from './HowToPlayControlGuide';
import HowToPlayGridList from './HowToPlayGridList';

interface Props {
  gamefileData: GameFile;
}

export default function HowToPlayEpic({ gamefileData }: Props) {
  const contents = [...gamefileData.guide_contents]
    .sort((a, b) => a.seq - b.seq)
    .map((guide, index) => ({
      id: index + 1,
      title: guide.content,
      image: guide.file_url,
    }));

  return (
    <>
      {Array.isArray(gamefileData.game_control_guides) &&
        gamefileData.game_control_guides.length > 0 && (
          <HowToPlayControlGuide game_control_guides={gamefileData.game_control_guides} />
        )}
      <Typography variant="h5" marginBottom="40px">
        Install Guide
      </Typography>
      <Typography variant="body2">
        *Please install Epic Games Launcher first if you currently do not have Epic Games Launcher
        installed.
      </Typography>
      <Link
        variant="body2"
        color="primary.main"
        href={`https://store.epicgames.com/en-US/p/${gamefileData.app_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to Epic Games Store &gt;
      </Link>
      <HowToPlayGridList contentList={contents} />
    </>
  );
}
