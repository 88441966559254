import { Route, Routes } from 'react-router-dom';

import { OOrderTab } from 'api/order';
import OrderListTableContainer from './OrderListTableContainer';
// TODO: Digital Goods도 OrderListTableContainer로 통합
import OrderStatusDigitalProductTableContainer from './OrderStatusDigitalProductTableContainer';

export default function ProfileOrderList() {
  return (
    <Routes>
      <Route
        path={OOrderTab.MERCHANDISE}
        element={<OrderListTableContainer type="PHYSICAL_GOODS" />}
      />
      <Route
        path={OOrderTab.DIGITAL_GAMES}
        element={<OrderListTableContainer type="DIGITAL_GAMES" />}
      />
      <Route
        path={OOrderTab.DIGITAL_PRODUCTS}
        element={<OrderStatusDigitalProductTableContainer />}
      />
    </Routes>
  );
}
