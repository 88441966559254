import { SearchField } from '@ground/ui';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useBlogListAPI } from 'api/blog/blogAPI';
import { useQueryDispatch } from 'context';
import { useLog } from 'utils/userLog';
import BlogSnsLink from '../blog-common/BlogSnsLink';
import BlogTagButton from '../blog-common/BlogTagButton';

export default function BlogFilter() {
  const { gxcTag } = useLog();
  const { changeQuery } = useQueryDispatch();

  const { data } = useBlogListAPI('b2c', undefined, undefined, true);

  const tagListData = useMemo(
    () => (data?.data && Array.isArray(data?.data.tag_list) ? data?.data.tag_list : []),
    [data]
  );

  return (
    <Box className="filter" width="300px">
      <SearchField
        placeholder="Search"
        width={300}
        onSearch={(keyword) => {
          gxcTag('click', { area: 'sidebar', ui: 'search' });
          changeQuery({
            search_keyword: keyword ?? '',
            search_announce_type: '',
            view_type: '',
            search_tag: '',
          });
        }}
        inputProps={{ maxLength: 50 }}
        defaultValue={''}
      />

      <Box p="24px" mt="20px" width="300px" borderRadius="3px" sx={{ backgroundColor: 'gray.80' }}>
        <Typography variant="h5" color="text.primary" textAlign="center">
          Tags
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '12px 4px',
          }}
          mt="24px"
        >
          {tagListData.map((item) => (
            <ButtonBase
              key={item.tag_text}
              onClick={() => {
                gxcTag('click', { area: 'sidebar', ui: 'tag' });
                changeQuery({
                  search_keyword: '',
                  search_announce_type: '',
                  view_type: '',
                  search_tag: item.tag_text,
                });
              }}
            >
              <BlogTagButton text={item.tag_text} backgroundColor="gray.60" />
            </ButtonBase>
          ))}
        </Box>
      </Box>

      <Box mt="24px" flexDirection="column" gap="4px" display="flex">
        <BlogSnsLink
          iconType="youtube"
          text="G.Round Youtube"
          linkUrl="https://www.youtube.com/channel/UCs3X1qAr8hXkBnn0v8lT9DA"
        />
        <BlogSnsLink iconType="x" text="G.Round X" linkUrl="https://twitter.com/gameroundco" />
        <BlogSnsLink
          iconType="facebook"
          text="G.Round Facebook"
          linkUrl="https://www.facebook.com/gameround/"
        />
      </Box>

      <Box mt="36px">
        <iframe
          title="G.Round"
          src="https://discord.com/widget?id=601726723947757571&theme=dark"
          width="300"
          height="375"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </Box>
    </Box>
  );
}
