import React from 'react';
import { styled, Typography } from '@mui/material';

import MobileFooterSubscribe from './MobileFooterSubscribe';

const SubscribeContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  padding: 20,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  height: 160,
}));

const Subscribe: React.FC = () => {
  return (
    <SubscribeContainer>
      <Typography variant="body2" color="text.secondary" mb="20px">
        Subscribe to the G.Round Newsletter!
      </Typography>
      <MobileFooterSubscribe />
    </SubscribeContainer>
  );
};

export default Subscribe;
