import queryString from 'query-string';
import { useSelector } from 'react-redux';

import { useFetch, useSWRFetch, useSWRInfiniteFetch } from 'api';
import { iRootState } from 'store';
import type { ReviewItemDataV1, ReviewItemDataV2 } from 'types/CommentType';
import { SortTypeEnum } from 'views/detail/comment/reviewCommon';

export interface GPIssuedResultType {
  id: number;
  result: { code: number; msg: string };
}

// GP issue post method's return type
export interface GPIssuedReturnData {
  fail_count: number;
  results: GPIssuedResultType[];
  success_count: number;
  target_count: number;
}
export interface GameInfoData {
  item_id: number;
  game_id: number;
  category: 'REVIEW' | 'GLOBALSURVEY' | 'SURVEY' | 'OTHER';
  type: string;
  amount: number;
  point: number;
  pay_amount: number;
  remain_amount: number;
}

export function useGameReviewAPI(gameId: number, reviewId: number) {
  return useSWRFetch<ReviewItemDataV1>({
    key: `game/${gameId}/comment/${reviewId}`,
    useToken: true,
  });
}

export function useGameReviewV2API(
  gameId: number,
  reviewId: number,
  testType: 'public' | 'private'
) {
  return useSWRFetch<ReviewItemDataV2>({
    key: `v2/games/${testType}/${gameId}/detail/reviews/${reviewId}`,
    useToken: true,
  });
}

// export function useGameMyReviewListV1API(gameId: number | string) {
//   return useSWRFetch<ReviewItemDataV1[]>({
//     key: gameId ? `game/${gameId}/comment/list/my?size=30` : '',
//     useToken: true,
//   });
// }

export interface ReviewDashboardData {
  available_purchase: boolean; // period deadline 지났는지 여부
  review_count: number; // confirmed + purchased
  reward_amount: number; // 선착순 리뷰 포인트 총 갯수
  pick_amount: number; // 선택 리뷰 포인트 총 갯수
  picked_count: number; // 선택된 리뷰 갯수
  is_paid: boolean; // 선택된 리뷰 지급 여부
  total_review_count: number; // 히든된것을 제외한 모든 리뷰
  goal_amount?: number; // confirmed 총 목표 갯수
  purchased_amount: number; // 구입한 리뷰 갯수
  additional_amount: number; // total_review_count - goal_amount - purchased_amount
  purchase_deadline: string; // 구입 기간 데드라인
  server_date: string; // 서버시간
  dday: string; // 구입 가능 잔여 날짜
  is_purchased: boolean; // 구입 진행 여부
  is_un_limited: boolean; // 리뷰 구매 제한 false: 구매 못함, true : 구매할 수 있음
  // purchased_deadline 까지. if purchased_dead_line 이 null 이면 영원히 구입 가능
}

export function useGetPublicReviewDashboardAPI(gameId: string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  // 로그인 시에만 dashboard API 호출 가능
  return useSWRFetch<ReviewDashboardData>({
    key: isLogin && gameId ? `v2/games/public/${gameId}/reviews/dashboard` : '',
    useToken: true,
  });
}

export function useGetReviewListV2API(
  gameId: string,
  isPicked?: boolean,
  isLiked?: boolean,
  isReplied?: boolean,
  isPurchased?: boolean
) {
  return useSWRFetch<ReviewItemDataV2[]>({
    key: gameId
      ? `v2/games/public/${gameId}/detail/reviews?picked=${isPicked ? true : false}&liked=${
          isLiked ? true : false
        }&replied=${isReplied ? true : false}&purchased=${isPurchased ? true : false}`
      : '',
    useToken: true,
  });
}

export function usePublicGameMyReviewListV2API(gameId: number | string) {
  return useSWRFetch<ReviewItemDataV2[]>({
    key: gameId ? `v2/games/public/${gameId}/detail/reviews/my` : '',
    useToken: true,
  });
}

export function useGetPrivateGameReviewListAPI(
  gameId: string,
  isLiked?: boolean,
  isReplied?: boolean
) {
  return useSWRFetch<ReviewItemDataV2[]>({
    key: gameId
      ? `v2/games/private/${gameId}/detail/reviews?&liked=${isLiked ? true : false}&replied=${
          isReplied ? true : false
        }`
      : '',
    useToken: true,
  });
}

export function usePrivateGameMyReviewListV2API(gameId: number | string) {
  return useSWRFetch<ReviewItemDataV2[]>({
    key: gameId ? `v2/games/private/${gameId}/detail/reviews/my` : '',
    useToken: true,
  });
}

export interface StudentFilterQuery {
  sort: keyof typeof SortTypeEnum;
  class_id: number;
  no_feedback: boolean;
  student_name: string;
}

// 학생 리뷰 리스트 API(다른 처리를 편하게 하려고 1000개씩 가져옴..)
export function useStudentReviewListAPI(gameId: string, query: StudentFilterQuery) {
  const filterQueries = queryString.stringify(query);
  const key = `game/${gameId}/comment/list/review?class_review=true&size=1000${
    filterQueries ? `&${filterQueries}` : ''
  }`;

  return useSWRInfiniteFetch<ReviewItemDataV1[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        const next_page = previousPageData.next_page;

        if (next_page?.has_next_page) {
          return `${key}&next_value=${next_page?.next_values}`;
        } else {
          return null;
        }
      } else {
        return key;
      }
    },
    useToken: true,
  });
}

// export const useGetMyCommentsCountAPI = (gameId: string | number) => {
//   return useSWRFetch<number>({
//     key: `game/${gameId}/comment/count?type=my`,
//     useToken: true,
//   });
// };

// export const useGetCommentsCountAPI = (gameId: string | number) => {
//   return useSWRFetch<number>({
//     key: `game/${gameId}/comment/count?type=comment`,
//     useToken: true,
//   });
// };

export const useGetAllReviewsCountAPI = (gameId: string | number) => {
  return useSWRFetch<number>({
    key: `game/${gameId}/comment/count?type=review`,
    useToken: true,
  });
};

/** @deprecated */
export const useGetPickedReviewsCountAPI = (gameId: string | number) => {
  return useSWRFetch<number>({
    key: `game/${gameId}/comment/count?type=review&picked=true`,
    useToken: true,
  });
};

export const useGetReplyListAPI = (
  gameId: string | number,
  commentId: string | number,
  size: number
) => {
  return useSWRInfiniteFetch<ReviewItemDataV1[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        if (previousPageData.next_page?.has_next_page) {
          return `game/${gameId}/comment/${commentId}/reply/list?size=${size}&next_value=${previousPageData?.next_page?.next_values}`;
        } else {
          return null;
        }
      } else {
        return `game/${gameId}/comment/${commentId}/reply/list?size=${size}`;
      }
    },
    useToken: true,
  });
};

export function useReviewCommentDeleteAPI(gameId: number | string, commentId: number | string) {
  return useFetch(`game/${gameId}/comment/${commentId}`, { method: 'DELETE' }, true);
}

export function useReviewCommentHideAPI(gameId: number | string, commentId: number | string) {
  return useFetch<number>(`game/${gameId}/comment/${commentId}/hide`, { method: 'POST' }, true);
}

export function useReviewCommentUnHideAPI(gameId: number | string, commentId: number | string) {
  return useFetch<number>(`game/${gameId}/comment/${commentId}/hide`, { method: 'DELETE' }, true);
}

export function useLikeCommentAPI(gameId: number | string, commentId: number | string) {
  return useFetch(`game/${gameId}/comment/${commentId}/like`, { method: 'POST' }, true);
}

export function usePickReviewAPI(gameId: number | string, commentId: number | string) {
  return useFetch<number>(`game/${gameId}/comment/${commentId}/pick`, { method: 'POST' }, true);
}

export function useUnpickReviewAPI(gameId: number | string, commentId: number | string) {
  return useFetch<number>(`game/${gameId}/comment/${commentId}/pick`, { method: 'DELETE' }, true);
}

/** @deprecated */
export function useGetMaxPickAPI(gameId: number | string) {
  return useSWRFetch<number>({
    key: `game/${gameId}/comment/count/pick/max`,
    useToken: true,
  });
}

/** @deprecated */
export function useGetNotPaidGPCountAPI(gameId: number | string) {
  return useSWRFetch<number>({
    key: `game/${gameId}/comment/count/pick/paid/not`,
    useToken: true,
  });
}

export function useIssueGPPointsToAllAPI(gameId: number | string) {
  return useFetch<GPIssuedReturnData>(
    `v2/games/public/${gameId}/comments/points`,
    { method: 'POST' },
    true
  );
}

export function useGetGameInfoAPI(gameId: number | string) {
  return useSWRFetch<GameInfoData[]>({ key: `games/${gameId}/rewards`, useToken: true });
}

export function useCommentPostInGameDetailAPI(gameId: number | string) {
  return useFetch(`game/${gameId}/comment`, { method: 'POST' }, true);
}

export function useReplyPostInGameDetailAPI(gameId: number | string, commentId: number | string) {
  return useFetch(`game/${gameId}/comment/${commentId}/reply`, { method: 'POST' }, true);
}
