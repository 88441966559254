import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormHelperText,
  Link,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import useFetch from 'api/useFetch';
import { useDialog } from 'hooks';
import { Dispatch } from 'store';
import eventTracker from 'utils/eventTracker';

const PaperWrap = styled(Paper)(({ theme }) => ({
  padding: 20,
  background: theme.palette.gray[60],
}));

const termsInfoData = [
  {
    infoHeader: 'Updated version',
    title: 'G.Round Terms of Use & Privacy Policy',
  },
  {
    infoHeader: 'Reason for update',
    title: 'Additions and changes to G.Round Store related Terms of Use & Privacy Policy',
  },
  {
    infoHeader: 'Updated on',
    title: 'March 29, 2022',
  },
];

interface Props {
  onClose: () => void;
}
function TermsConfirmDialogBody({ onClose }: Props) {
  const [error, setError] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const { isLoading, fetch: postTerms } = useFetch('users/terms/agreed', { method: 'POST' }, true);

  // Agree 버튼 클릭 시 약관 동의 전송. 성공 시 창 닫기. 실패 시 에러 표출
  const handleClickAgree = async () => {
    eventTracker('termspopup_agree');
    const response = await postTerms();
    if (response?.data?.result_code === 'OK') {
      if (error) setError(false);
      dispatch.login.updateLoginInfo({ agreed_terms: true });
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        Please accept G.Round's updated Terms of Use & Privacy Policy
      </Typography>

      <PaperWrap sx={{ marginTop: '20px' }}>
        <Stack divider={<Divider flexItem />} spacing="16px">
          {termsInfoData.map((item, index) => (
            <Box key={index} display="flex" flexDirection="column">
              <Typography variant="body3" color="text.secondary">
                {item.infoHeader}
              </Typography>
              <Typography variant="body2" marginTop="4px">
                {item.title}
              </Typography>
            </Box>
          ))}
        </Stack>
      </PaperWrap>

      <Typography variant="body3" color="text.secondary" marginTop="20px">
        By selecting 'I Agree', you accept and agree to{' '}
        <Link href="/userTerms.pdf" target="_blank" color="text.secondary">
          Terms of Use
        </Link>{' '}
        &{' '}
        <Link href="/privacyPolicy.pdf" target="_blank" color="text.secondary">
          Privacy Policy
        </Link>
      </Typography>

      <LoadingButton
        loading={isLoading}
        onClick={handleClickAgree}
        color="primary"
        variant="contained"
        fullWidth
        sx={{ marginTop: '40px' }}
      >
        <Typography variant="button2">I Agree</Typography>
      </LoadingButton>
      {error && <FormHelperText error>An error has occurred. please try again.</FormHelperText>}
      <Typography variant="small2" color="text.secondary2" marginTop="12px">
        *If you fail to agree to G.Round's updated Terms of Use & Privacy Policy, the use of the
        service will be restricted
      </Typography>
    </>
  );
}

export default function useTermsConfirmDialog() {
  const { showDialog, closeDialog } = useDialog();

  const showTermsConfirm = useCallback(
    () =>
      showDialog({
        title: 'Notice',
        body: <TermsConfirmDialogBody onClose={closeDialog} />,
        width: 500,
      }),
    [closeDialog, showDialog]
  );

  return {
    showTermsConfirmDialog: showTermsConfirm,
    closeDialog,
  };
}
