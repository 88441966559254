import { useAxios, useDialog } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LoginPayload } from 'models/login';
import { Dispatch } from 'store';
import { LoadingPage } from 'views/main/LoadingPage';
import { MeData } from './AuthFacebookMe';

interface AuthLoginProps {
  accessToken: string;
  state: string;
  data: MeData;
}

export default function AuthGroundWithFacebook({
  accessToken,
  state,
  data: meData,
}: AuthLoginProps) {
  const { showDialog, showSimpleDialog } = useDialog();
  const { isLoading: loadingLogin, refetch: signin } = useAxios<LoginPayload>(
    'sessions/facebook',
    { method: 'POST' },
    true
  );
  const { isLoading: loadingSignup, refetch: signup } = useAxios<LoginPayload>(
    'users/facebook',
    { method: 'POST' },
    true
  );

  const { login } = useDispatch<Dispatch>();

  const loginSuccess = useCallback(
    (data: LoginPayload) => {
      // 페이스북 로그인 성공한 경우
      login.login(data);
      // redirect to launcher
      window.location.replace(
        `${window.atob(decodeURIComponent(state))}?access_token=${data.access_token}&state=${state}`
      );
    },
    [login, state]
  );

  const groundLogin = useCallback(async () => {
    if (meData?.email) {
      // 이메일 정보가 있는 경우
      const { data: loginData, status } = await signin({
        data: {
          access_token: accessToken,
          account: meData.id,
        },
      });

      if (status === 200 && loginData?.result_code === 'OK') {
        // 페이스북 로그인 성공한 경우
        loginSuccess(loginData.data);
      } else {
        // 페이스북으로 가입된 정보가 없다면 연동 없이 가입 시도
        if (loginData?.result_code === '400') {
          const { data: signupData, status } = await signup({
            data: {
              access_token: accessToken,
              account: meData.id,
              name: meData.name,
              email: meData.email,
              picture: meData.picture?.data?.url,
              agree: false,
            },
          });

          if (status === 200 && signupData?.result_code === 'OK') {
            // 가입에 성공했다면 로그인 시켜준다
            loginSuccess(signupData.data);

            // Requirement to add Script for Google Ads 2021.11.26
            // https://www.notion.so/gameround/c9aa543eb50443c29b0c8a8246dbebea
            gtag('event', 'conversion', { send_to: 'AW-669277837/uDS-CMudwoMDEI29kb8C' });
          } else if (signupData?.result_code === '403') {
            // 이미 가입된 유저
            showSimpleDialog('Facebook Signup', 'Already registered user.');
          } else if (signupData?.result_code === '402') {
            // 같은 이메일을 사용하는 계정이 있는 경우 페이스북 연동으로 다시 가입 시도(에러 코드로 변경해야됨)
            // '일반계정이 있습니다. 연동 수락 체크를 해주십시요'
            showDialog({
              title: 'Facebook Sign Up',
              body: `${meData.email} account already exists. Link your Facebook account to your account?`,
              secondaryButton: { text: 'cancel' },
              primaryButton: {
                text: 'OK',
                callback: async () => {
                  const { data, status } = await signup({
                    data: {
                      access_token: accessToken,
                      account: meData.id,
                      name: meData.name,
                      email: meData.email,
                      picture: meData.picture?.data?.url,
                      agree: true,
                    },
                  });

                  if (status === 200 && data?.result_code === 'OK') {
                    loginSuccess(data.data);
                  } else {
                    showSimpleDialog('Facebook Sign Up', data?.description);
                  }
                },
              },
            });
          } else if (signupData?.result_code === '400') {
            showDialog({
              title: 'Error',
              body: 'The code you entered does not exist. Please try again.',
              primaryButton: { text: 'Back to Sign in' },
            });
          } else {
            showSimpleDialog('Facebook Sign Up', signupData?.description);
          }
          // 페이스북 계정은 있으나 일반계정이 없는 상태(탈퇴했거나 페이스북 이메일을 변경했거나 등)
        } else if (loginData?.result_code === '402') {
          showSimpleDialog(
            'Facebook Sign in',
            'Invalid account creation. Please contact our support team.'
          );
        } else if (loginData?.result_code === '410') {
          showSimpleDialog(
            'Facebook Sign in',
            'Email account exist. Send you a password reset email.'
          );
        } else {
          showSimpleDialog(
            'Facebook Sign in',
            loginData?.description || (
              <>
                Unknown error.
                <br />
                {`statusCode: ${status} resultCode: ${loginData?.result_code}`}
              </>
            )
          );
        }
      }
    } else {
      showSimpleDialog(
        'Facebook Sign in',
        'Ready up gamers! We need your email permission for Facebook sign up or you can go with regular email.'
      );
    }
  }, [accessToken, signin, loginSuccess, meData, showDialog, showSimpleDialog, signup]);

  useEffect(() => {
    groundLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingLogin || loadingSignup ? <LoadingPage /> : <p>Sign in success</p>;
}
