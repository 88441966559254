import { numberWithCommas } from '@ground/tools';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCreateReceiptUrl, useGetPointOrderDetailAPI } from 'api/payment/paymentAPI';
import { iRootState } from 'store';

export default function GStoreOrderDetailPointTitleBox() {
  const { trxId = '' } = useParams<{ trxId: string }>();
  const { data, isLoading } = useGetPointOrderDetailAPI(trxId);

  const { data: receiptData, isLoading: isCreatingReceipt } = useCreateReceiptUrl(
    data?.data?.pg_id
  );
  const { email } = useSelector((state: iRootState) => state.login);

  return (
    <Stack>
      <Typography variant="h5" mb="20px">
        {data?.data?.pg_id ? 'G.Points Purchase Detail' : 'Order Detail'}
      </Typography>
      {!isLoading && (
        <Box
          sx={({ palette }) => ({
            padding: '20px',
            display: 'flex',
            background: palette.gray[90],
            borderRadius: '5px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            position: 'relative',
          })}
        >
          {data?.data?.pg_id && (
            <Box
              sx={{ position: 'absolute', top: '20px', right: '40px', display: 'flex', gap: '8px' }}
            >
              <LoadingButton
                loading={isCreatingReceipt}
                variant="ghost"
                size="small"
                disabled={!receiptData?.data?.receipt_url}
                onClick={() => {
                  if (receiptData?.data?.receipt_url) {
                    window.open(receiptData?.data?.receipt_url, '_blank');
                  }
                }}
              >
                Receipt
              </LoadingButton>
              <Button
                variant="ghost"
                size="small"
                href={`mailto:support@gameround.co?subject=Inquiry about G.Points Purchase: ${data
                  ?.data?.trx_id}&body=• G.Round Order ID: ${data?.data
                  ?.trx_id}%0D%0A• Stripe Payment ID: ${data?.data?.pg_id}%0D%0A• Order Time: ${
                  data?.data.created_at
                    ? format(new Date(data?.data.created_at), 'MMM dd, yyyy hh:mm a')
                    : '???'
                }(PT)%0D%0A• User Login ID: ${email}%0D%0A------------------------------------------------%0D%0APlease write your inquiry starting from here.`}
              >
                Support
              </Button>
            </Box>
          )}
          <Box display="flex" columnGap="40px">
            {data?.data?.product_images?.[0] && (
              <img
                style={{
                  width: 150,
                  height: 120,
                  borderRadius: 3,
                }}
                src={data?.data?.product_images?.[0]}
                alt="product"
              />
            )}

            <Box>
              <Typography variant="h6">{`Purchased G.Points ${
                typeof data?.data?.pay_amount === 'number'
                  ? numberWithCommas(data?.data?.pay_amount * 10)
                  : 0
              } G.P`}</Typography>
              <Typography variant="body2" color="text.secondary" mt="8px">
                Payment Cost : {data?.data?.pay_amount?.toFixed(2)}{' '}
                {data?.data?.currency?.toUpperCase()}
              </Typography>

              <Box mt="24px" sx={{ display: 'flex', gap: '20px' }}>
                <Typography variant="body3" color="text.secondary">
                  Transaction ID : {data?.data?.trx_id}
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Date Purchased :{' '}
                  {data?.data?.created_at
                    ? `${format(new Date(data.data.created_at), 'MMM dd, yyyy hh:mm a')}(PT)`
                    : ''}
                </Typography>
                <Typography variant="body3" color="text.secondary">
                  Purchase Status : {data?.data?.pay_status === 'succeeded' ? 'Done' : 'Canceled'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}
