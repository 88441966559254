import { SwiperSlide } from 'swiper/react';

import PickedReviewCard from 'components/PickedReviewCard/PickedReviewCard';
import { Carousel } from 'elements';
import { ReviewCardData } from 'types/DisplayContentType';
import { useLog } from 'utils/userLog';

interface Props {
  dataList: ReviewCardData[];
}

const PickedReviewListContainer = ({ dataList }: Props) => {
  const { gxcTag } = useLog();

  return (
    <Carousel
      hasNavigation={dataList.length > 4}
      width={1200}
      name="picked-review"
      swiperOption={{
        slidesPerView: dataList.length >= 4 ? 4 : dataList.length,
        spaceBetween: 20,
        allowTouchMove: false,
        simulateTouch: true,
      }}
      onClickLeftArrow={() => gxcTag('click', { area: 'picked_review', ui: 'left_arrow' })}
      onClickRightArrow={() => gxcTag('click', { area: 'picked_review', ui: 'right_arrow' })}
      style={{ paddingBottom: '20px', marginBottom: '-20px' }}
    >
      {dataList?.map?.((reviewData) => (
        <SwiperSlide key={reviewData.game_comment_id}>
          <PickedReviewCard
            profileUrl={reviewData.reviewer_profile_url}
            reviewerName={reviewData.reviewer_name}
            reviewText={reviewData.picked_review_text}
            gameTitle={reviewData.game_title}
            gameThumbnailUrl={reviewData.game_thumbnail_url}
            reviewPoint={25}
            onClick={() => {
              gxcTag('click', {
                area: 'picked_review',
                ui: 'review_card',
                parameters: { game_id: reviewData.game_id },
              });
            }}
            to={`/detail/${reviewData.game_id}/review/reviews/?review_id=${reviewData.game_comment_id}#detail-tab`}
          />
        </SwiperSlide>
      ))}
    </Carousel>
  );
};

export default PickedReviewListContainer;
