import { Button, Divider, Typography, styled } from '@mui/material';

import ArtDevPackages from './component/ArtDevPackages';
import ArtDevPackagesTable from './component/ArtDevPackagesTable';

const PREFIX = 'ArtDevAboutPackages';

const classes = {
  contentsAreaWrap: `${PREFIX}-contentsAreaWrap`,
  contentsArea: `${PREFIX}-contentsArea`,
};

const Root = styled('div')(() => ({
  position: 'relative',
  width: 1080,
  paddingTop: 100,
  paddingBottom: 100,

  [`& .${classes.contentsAreaWrap}`]: {
    display: 'flex',
    marginTop: 40,
    columnGap: 40,
  },

  [`& .${classes.contentsArea}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 26,
  },
}));

export default function ArtDevAboutPackages() {
  return (
    <Root id="gameTestingPackages">
      <Typography variant="h4">Game Testing Packages</Typography>
      <Divider sx={{ marginTop: '40px', marginBottom: '32px' }} />
      <ArtDevPackages />
      <Divider sx={{ marginTop: '32px' }} />
      <ArtDevPackagesTable />
      <div className={classes.contentsAreaWrap}>
        <div className={classes.contentsArea} id="submitYourGame">
          <Button
            variant="contained"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe0lqFCXEBhRRgpAPHGlP_qGXXsOOMdcZV_ou8DZG53ojCEoA/viewform"
            onClick={() => twq?.('event', 'tw-o8sf9-oci0v', {})}
            sx={{ marginTop: '4px' }}
          >
            Submit Your Game
          </Button>
          <Typography variant="body2" color="text.secondary2" sx={{ width: '341px' }}>
            For Studios, Publishers, Incubators.
            <br /> (Our team will review your submission and get back to you within 14 days if you
            get a green light.)
          </Typography>
        </div>
        <div className={classes.contentsArea}>
          <Button
            variant="contained"
            color="primary"
            href="mailto:info@gameround.co"
            sx={{ marginTop: '4px' }}
          >
            Request Partnership
          </Button>
          <Typography variant="body2" color="text.secondary2" sx={{ width: '341px' }}>
            For Governments, <br />
            Publishers, Investors, Solution Partners, <br /> Game Event Organizers etc
          </Typography>
        </div>
      </div>
    </Root>
  );
}
