import { useFetch, useSWRFetch } from 'api/';

interface CodeGroupType {
  code: string;
  code_name: string;
  code_order: number;
}

interface SubmitGameLinkTypeData {
  submit_game_link_type: CodeGroupType[];
}

interface GamePlatformTypeData {
  game_platform: CodeGroupType[];
}

export function useRecommendGameAPI() {
  return useFetch('recommendGame/submit', { method: 'POST' }, true);
}

export function useCheckUserIntervalAPI() {
  const url = 'recommendGame/checkUserInterval';
  return useFetch<number>(url, {}, true);
}

export function useSubmitGameLinkTypeAPI() {
  const url = 'code/simple?code_group=submit_game_link_type';
  return useSWRFetch<SubmitGameLinkTypeData>({ key: url, useToken: true });
}

export function useGamePlatformTypeAPI() {
  const url = 'code/simple?code_group=game_platform';
  return useSWRFetch<GamePlatformTypeData>({ key: url, useToken: true });
}
