import queryString from 'query-string';

export const stringifiedQueryParams = (params?: { [key: string]: any }) => {
  return params
    ? queryString.stringify(params, {
        arrayFormat: 'comma',
        skipEmptyString: true,
      })
    : '';
};
