import { useState } from 'react';
import { RegisterOptions, get, useFormContext } from 'react-hook-form';

import { GIconButton, InputField, InputFieldProps } from '../atoms';

interface FormInputProps extends Omit<InputFieldProps, 'value' | 'onChange'> {
  password?: boolean;
  name: string;
  rules?: RegisterOptions;
}

function FormInput({
  className,
  disabled,
  fullWidth,
  readOnly = false,
  inputProps,
  // form props
  label,
  name,
  type = 'text',
  placeholder,
  password,
  required,
  rules,
  defaultValue = '',
  size = 'medium',
  ...props
}: FormInputProps) {
  const {
    formState: { errors },
    register,
    getValues,
    setValue,
  } = useFormContext();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [clearIcon, setClearIcon] = useState(!!getValues(name));

  const error = get(errors, name);

  const handleClickVisible = () => {
    setVisiblePassword(!visiblePassword);
  };

  return (
    <InputField
      {...register(name, rules)}
      className={className}
      type={visiblePassword ? 'text' : type}
      placeholder={placeholder}
      readOnly={readOnly}
      defaultValue={defaultValue}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      helperText={error ? error?.message ?? 'Invalid' : undefined}
      label={label}
      size={size}
      endAdornment={
        !disabled &&
        !readOnly &&
        password && (
          <GIconButton
            iconName={visiblePassword ? 'eyes_on' : 'eyes_off'}
            iconSize={16}
            onClick={handleClickVisible}
          />
        )
      }
      clearIcon={clearIcon}
      onClickClear={() => setValue(name, '')}
      inputProps={{
        ...inputProps,
        onChange: (e) => {
          setClearIcon(!!e.currentTarget.value);
          inputProps?.onChange?.(e);
        },
      }}
      {...props}
    />
  );
}

export default FormInput;
