import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';

import { EducatorInfoRequest, usePatchEducatorInfoAPI } from 'api/university/universityAPI';
import { ClassForm, Fullname, MajorForm } from 'components/university';
import type { EducatorFormData } from 'types/UniversityType';
import { useLog } from 'utils/userLog';

interface SignupEducatorFormProps {
  defaultValues: EducatorFormData;
  onSubmitSuccess?: (responseData: AxiosResponse) => Promise<void>;
  onSubmitError?: (response: AxiosResponse) => void;
}
export default function SignupEducatorForm({
  defaultValues,
  onSubmitSuccess,
  onSubmitError,
}: SignupEducatorFormProps) {
  const { isLoading, fetch } = usePatchEducatorInfoAPI();
  const methods = useForm<EducatorFormData>({ defaultValues });
  const { gxcTag } = useLog();

  const { major: defaultMajor, class: defaultClass } = defaultValues;

  const onSubmit = async (formData: EducatorFormData) => {
    // 수정 및 추가 Major
    let updateMajorList: { major_name: string; major_id: number }[] = [];
    let insertMajorList: string[] = [];

    formData.major.forEach((formMajor) => {
      if (formMajor.major_id) {
        if (
          defaultMajor.find(
            (ori) => ori.major_id === formMajor.major_id && ori.major_name !== formMajor.major_name
          )
        ) {
          updateMajorList.push({ major_id: formMajor.major_id, major_name: formMajor.major_name });
        }
      } else {
        insertMajorList.push(formMajor.major_name);
      }
    });

    // 수정 및 추가 클래스
    let updateClassList: { class_name: string; class_id: number }[] = [];
    let insertClassList: string[] = [];

    formData.class.forEach((formClass) => {
      if (formClass.class_id) {
        if (
          defaultClass.find(
            (ori) => ori.class_id === formClass.class_id && ori.class_name !== formClass.class_name
          )
        ) {
          updateClassList.push({ class_id: formClass.class_id, class_name: formClass.class_name });
        }
      } else {
        insertClassList.push(formClass.class_name);
      }
    });

    // 삭제할 클래스
    let deleteClassList: number[] = [];
    defaultClass.forEach((classItem) => {
      if (typeof classItem.class_id === 'number') {
        if (!formData.class.find((formClass) => formClass.class_id === classItem.class_id)) {
          deleteClassList.push(classItem.class_id);
        }
      }
    });

    const request: EducatorInfoRequest = {
      fullName: formData.name.trim(),
      updateMajorList,
      insertMajorList,
      updateClassList,
      insertClassList,
      deleteClassList,
    };

    const response = await fetch(request);
    if (response.status === 200 && response.data.result?.code === 0) {
      onSubmitSuccess?.(response);
    } else {
      onSubmitError?.(response);
    }
  };

  const handleClickComplete = () => {
    gxcTag('click', { area: 'detail', ui: 'complete_signup' });
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form style={{ width: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack gap="20px">
          <Fullname />
          <MajorForm />
          <ClassForm />
        </Stack>

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          size="large"
          sx={{ marginTop: '40px' }}
          type="submit"
          variant="contained"
          onClick={handleClickComplete}
        >
          Complete Sign up
        </LoadingButton>
      </form>
    </FormProvider>
  );
}
