import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import discoverBannerImg from 'images/about/aboutGamer/about_ground_bg_discover.png';
import { useLog } from 'utils/userLog';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: 250,
  paddingTop: 88,
  backgroundSize: 'contain',
  background: `url(${discoverBannerImg}) no-repeat center`,
  backgroundColor: theme.palette.base.black,
}));

export default function AboutGamerDiscoverBanner() {
  const { gxcTag } = useLog();

  return (
    <Root>
      <Typography variant="h4">Browse the Latest G.Round Games Now</Typography>
      <Button
        variant="outlined"
        color="light"
        size="medium"
        component={Link}
        to="/testing"
        sx={{ marginTop: '28px' }}
        onClick={() =>
          gxcTag('click', {
            area: 'detail',
            ui: 'playtest_now',
          })
        }
      >
        Playtest Now!
      </Button>
    </Root>
  );
}
