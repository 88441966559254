import { ReactNode, createContext, useContext, useMemo, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

type QueryType = Record<string, any>;

interface DispatchProps {
  changeQuery: (query: QueryType, overwriteAll?: boolean) => void;
}

const StateContext = createContext<QueryType>({});
const DispatchContext = createContext<DispatchProps | undefined>(undefined);

interface Props {
  /**
   * @description query 초기값
   */
  defaultQuery?: QueryType;
  /** Provider를 사용할 컴포넌트 */
  children: ReactNode;
}

/** URL의 Query Parameter를 사용하여 Data를 관리하는 Provider */
export function QuerystringProvider({ defaultQuery, children }: Props) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const defaultParams = useRef(defaultQuery);

  const params = useMemo(
    () => queryString.parse(search, { arrayFormat: 'comma', parseBooleans: true }),
    [search]
  );

  const changeQuery = useCallback(
    (query: QueryType, overwriteAll?: boolean) => {
      const newQuery = overwriteAll
        ? { ...defaultParams.current, ...query }
        : { ...params, ...query };

      navigate({
        search: queryString.stringify(newQuery, {
          arrayFormat: 'comma',
          skipEmptyString: true,
        }),
      });
    },
    [navigate, params]
  );

  return (
    <StateContext.Provider value={{ ...defaultParams.current, ...params }}>
      <DispatchContext.Provider value={{ changeQuery }}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useQueryState<P = QueryType>() {
  const state = useContext(StateContext) as P;
  if (!state) throw new Error('Query state context not found');
  return state;
}

export function useQueryDispatch() {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) throw new Error('Query dispatch context not found');
  return dispatch;
}
