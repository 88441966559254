import { differenceInDays, differenceInHours, differenceInMilliseconds } from 'date-fns';

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
export const groundDiscordChannel = 'https://discord.gg/gameround';
export const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;

export const launcherDownloadUrl =
  'https://ground-launcher-packages.s3.us-west-1.amazonaws.com/GrLauncher/build/GRLauncherInstaller/G.RoundLauncher.exe';

// function getYoutubeId(url: string): string {
//   const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
//   const match = url.match(regExp);
//   return match && match[7].length === 11 ? match[7] : '';
// }

/**
 * 환경에 맞게 url을 컨버팅해주는 함수
 * https://api-statistics.gameround.co/daily/user 같이 full url인 경우
 * dev 서버에서는 https://dev-api-statistics.gameround.co/daily/user 처럼 'dev-'을 추가해준다.
 *
 * TODO: default base url은 별개 처리되고 있어 추후 더 좋은 방법으로 개선이 필요하다
 */
export const convertUrlEnv = (url?: string) => {
  if (!url) return '';

  if (url.startsWith('http')) {
    if (process.env.REACT_APP_ENV === 'DEV') {
      return url.replace('//', '//dev-');
    } else if (process.env.REACT_APP_ENV === 'STAGE') {
      return url.replace('//', '//stage-');
    } else {
      return url;
    }
  } else {
    return url;
  }
};

// export const digitalGameIdList = [
//   // Terracotta
//   '12d168ae-d6ed-4a0c-8a6e-677f5dc6a33e', // stage, live
//   '7c573d47-6399-432a-a728-3f35c8dcc939', // dev
//   // Stray Blade
//   'd76ae9b7-66a5-4a26-8200-55ff05a3fa69', // stage, live
//   '17a8ca17-ff33-499c-8816-bdd5617ff497', // dev
//   // Relic Hunters Legend
//   '199fcf61-a53a-43ba-907d-57d29a1f2977', // dev
//   '3d0b7f84-aa89-458c-9c03-3e58fb0f3f13', // stage
//   '2a141b43-6ae7-4925-8b94-38438735dd15', // live
//   // Hell Throne
//   '9e7e289a-aff8-4dac-a1c5-176214900ee6', // dev
//   'ce7e8d25-daa0-4fdf-bf42-24ef5e10ca96', // stage
//   'ba554283-ce6e-4368-9baf-47e54b28ab30', // live
//   // Test용 Game sale id
//   '2fbd9648-4e95-40f6-9482-beeb205d3870', // dev
// ];

// export const strayBladeIdList = [
//   'd76ae9b7-66a5-4a26-8200-55ff05a3fa69',
//   '17a8ca17-ff33-499c-8816-bdd5617ff497',
// ];

// privateGame에서 사용되는 문구
export function getConvertTimeText(startDate: string, current?: string) {
  if (!current) return '';
  if (typeof current !== 'string' || typeof startDate !== 'string') return '';

  const today = new Date(current);
  const dday = new Date(startDate);
  let result = differenceInDays(dday, today);

  if (result >= 1) {
    return `${result} Days To Go`;
  } else if (result === 0) {
    // 24시간 이하, 한시간 이하일 때
    result = differenceInHours(dday, today);
    if (result <= 0) return '';
    return result < 1 ? 'Starting soon!' : `Starting in ${result} hours!`;
  } else {
    return '';
  }
}

// recruit_end,오늘날짜 비교하여 차이값 출력
export function getDdayInfo(current: string, date: string) {
  const today = new Date(current);
  const recruit_end = new Date(date);
  let result = differenceInDays(today, recruit_end);
  return result;
}

export function getRecentInfo(current: string | undefined, date: string) {
  const today = new Date(String(current));
  const referenceDay = new Date(date);
  let result = differenceInMilliseconds(referenceDay, today);
  return Math.abs(result);
}

/** 초를 HH:MM 로 변환해주는 함수 */
export function getPlayTime(time: number) {
  const hasHour = time >= 3600;

  return `${hasHour ? `${Math.floor(time / 3600)}h ` : ''}${(Math.floor(time / 60) % 60)
    .toString()
    .padStart(hasHour ? 2 : 1, '0')}m`;
}
