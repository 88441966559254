import { Box } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { GameCardProps } from 'types/GameCardTypes';
import GameCardLikeButtonContainer from '../GameCardLikeButton/GameCardLikeButtonContainer';
import GameCardBigLowerArea from './GameCardBigLowerArea';
import GameCardBigUpperArea from './GameCardBigUpperArea';

export default function GameCardBig(
  props: GameCardProps & { onClickCard?: (params?: string) => void }
) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Link
      to={`/detail/${props.id}/info`}
      style={{ textDecoration: 'auto', pointerEvents: props.remainingText ? 'none' : 'auto' }}
      onClick={() => {
        props?.onClickCard?.(String(props?.id));
      }}
    >
      <Box
        position="relative"
        width={590}
        ref={hoverRef}
        sx={{
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: isHover ? '0px 10px 10px rgba(0, 0, 0, 0.5)' : '',
          overflow: 'hidden',
        }}
      >
        <GameCardBigUpperArea {...props} isHover={isHover} />
        <GameCardBigLowerArea {...props} isHover={isHover} />

        {props.likeButton && (
          <Box position="absolute" sx={{ top: 12, right: 12 }}>
            <GameCardLikeButtonContainer gameId={props.id} likeCheck={props.like_check} />
          </Box>
        )}
      </Box>
    </Link>
  );
}
