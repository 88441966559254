import { useCallback } from 'react';

import { ModalDialog } from 'elements';
import { useGetDetailData } from '../DetailProvider';
import { useImportDraftData } from '../ImportDraftProvider';
import Review from './Review';
import ReviewProvider from './ReviewProvider';

export default function DetailReview() {
  const { openReview, setOpenReview } = useGetDetailData();
  const { setShouldImportReviewDraftData } = useImportDraftData();

  const closeHandler = useCallback(() => {
    setOpenReview(false);
    setShouldImportReviewDraftData(false);
  }, [setOpenReview, setShouldImportReviewDraftData]);

  return (
    <ModalDialog
      open={openReview}
      onClose={closeHandler}
      title="Review"
      hasScrollBar
      body={
        <ReviewProvider>
          <Review />
        </ReviewProvider>
      }
      width={710}
      height={1000}
      disableEscapeKeyDown
    />
  );
}
