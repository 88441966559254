import { alpha, buttonClasses, Components } from '@mui/material';

const MuiButton: Components = {
  MuiButton: {
    defaultProps: { disableElevation: true, disableRipple: true },
    styleOverrides: {
      root: {
        padding: '12px 24px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '16px',
        [`& .${buttonClasses.startIcon}`]: {
          marginLeft: 0,
          '.MuiSvgIcon-root': {
            fontSize: '16px',
          },
        },
        [`& .${buttonClasses.endIcon}`]: {
          marginRight: 0,
          '.MuiSvgIcon-root': {
            fontSize: '16px',
          },
        },
      },
    },
    variants: [
      // size 기본 값 설정
      {
        props: { size: 'tiny' },
        style: {
          borderRadius: 5,
          padding: '8px 16px',
          fontWeight: 500,
          fontSize: 10,
          lineHeight: '12px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          padding: '8px 16px',
        },
      },
      {
        props: { size: 'large' },
        style: {
          padding: '12px 24px',
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '18px',
        },
      },
      {
        props: { size: 'largest' },
        style: {
          padding: '15px 24px',
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '18px',
        },
      },
      // variant: contained 설정
      {
        props: { variant: 'contained' },
        style: {
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
          },
        },
      },
      // variant: outlined 설정
      {
        props: { variant: 'outlined' },
        style: {
          padding: '11px 23px',
          '&:hover': { backgroundColor: 'transparent' },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            border: '1px solid #464646',
            opacity: 0.4,
          },
        },
      },
      {
        props: { size: 'tiny', variant: 'outlined' },
        style: { padding: '7px 15px' },
      },
      {
        props: { size: 'small', variant: 'outlined' },
        style: { padding: '7px 15px' },
      },
      {
        props: { size: 'large', variant: 'outlined' },
        style: { padding: '11px 23px' },
      },
      {
        props: { size: 'largest', variant: 'outlined' },
        style: { padding: '14px 23px' },
      },
      // color: light 설정
      {
        props: { color: 'light', variant: 'text' },
        style: {
          color: '#D2D7DD',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: alpha('#000000', 0.1),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: alpha('#a1a1a1', 0.4),
          },
        },
      },
      {
        props: { color: 'light', variant: 'outlined' },
        style: {
          color: '#D2D7DD',
          borderColor: '#D2D7DD',
          '&:hover': {
            color: '#FFFFFF',
            borderColor: '#FFFFFF',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            border: '1px solid #464646',
            opacity: 0.4,
          },
        },
      },
      {
        props: { color: 'light', variant: 'contained' },
        style: {
          color: '#000000',
          backgroundColor: '#D2D7DD',
          '&:hover': {
            color: '#000000',
            backgroundColor: '#FFFFFF',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
          },
        },
      },
      // color: dark 설정
      {
        props: { color: 'dark', variant: 'text' },
        style: {
          color: '#1E1E1E',
          '&:hover': {
            color: '#000000',
            backgroundColor: alpha('#000000', 0.1),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
          },
        },
      },
      {
        props: { color: 'dark', variant: 'outlined' },
        style: {
          color: '#1E1E1E',
          borderColor: '#1E1E1E',
          '&:hover': {
            color: '#000000',
            borderColor: '#000000',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            border: '1px solid #464646',
            opacity: 0.4,
          },
        },
      },
      {
        props: { color: 'dark', variant: 'contained' },
        style: {
          color: '#D2D7DD',
          backgroundColor: '#181818',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#222222',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
          },
        },
      },
      // color: primary 설정
      {
        props: { color: 'primary', variant: 'text' },
        style: {
          color: '#3C66FB',
          '&:hover': {
            color: '#5786FF',
            backgroundColor: alpha('#3C66FB', 0.1),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: alpha('#a1a1a1', 0.4),
          },
        },
      },
      // color: private 설정
      {
        props: { color: 'private' },
        style: {
          color: '#FFFFFF',
          backgroundColor: '#00A184',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#00BB99',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: alpha('#a1a1a1', 0.4),
          },
        },
      },
      {
        props: { color: 'primary', variant: 'outlined' },
        style: {
          color: '#3C66FB',
          borderColor: '#3C66FB',
          '&:hover': {
            color: '#5786FF',
            borderColor: '#5786FF',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            border: '1px solid #464646',
            opacity: 0.4,
          },
        },
      },
      {
        props: { color: 'primary', variant: 'contained' },
        style: {
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#5786FF',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
          },
        },
      },
      // variant: ghost 설정
      {
        props: { variant: 'ghost' },
        style: {
          color: '#D2D7DD',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          padding: '11px 23px',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            borderColor: 'rgba(255, 255, 255, 0.2)',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
            border: '1px solid #464646',
          },
        },
      },
      {
        props: { variant: 'ghost', size: 'tiny' },
        style: { padding: '7px 15px' },
      },
      {
        props: { variant: 'ghost', size: 'small' },
        style: { padding: '7px 15px' },
      },
      {
        props: { variant: 'ghost', size: 'large' },
        style: { padding: '11px 23px' },
      },
      {
        props: { variant: 'ghost', size: 'largest' },
        style: { padding: '14px 23px' },
      },
      // 위에껀 ground ui theme에 있는것!
      {
        props: { color: 'primary', variant: 'text' },
        style: {
          color: '#00A184',
          '&:hover': {
            color: '#00BB99',
            backgroundColor: alpha('#00A184', 0.1),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: alpha('#a1a1a1', 0.4),
          },
        },
      },
      {
        props: { color: 'primary', variant: 'outlined' },
        style: {
          color: '#00A184',
          borderColor: '#00A184',
          '&:hover': {
            color: '#00BB99',
            borderColor: '#00BB99',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            border: '1px solid #464646',
            opacity: 0.4,
          },
        },
      },
      {
        props: { color: 'primary', variant: 'contained' },
        style: {
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#00BB99',
          },
          [`&.${buttonClasses.disabled}`]: {
            color: '#a1a1a1',
            opacity: 0.4,
            backgroundColor: '#464646',
          },
        },
      },
    ],
  },
};

export default MuiButton;
