import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';

export default styled(SnackbarProvider)(({ theme }) => ({
  '&.SnackbarItem-contentRoot': {
    backgroundColor: theme.palette.gray[60],
    color: theme.palette.text.primary,
  },
  '&.SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.main,
  },
  '&.SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.main,
  },
  '&.SnackbarItem-variantWarning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.SnackbarItem-variantInfo': {
    backgroundColor: theme.palette.blue.primary,
  },
}));
