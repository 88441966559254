import { FormInput } from '@ground/ui';

export default function Fullname() {
  return (
    <FormInput
      fullWidth
      label="Full name"
      name="name"
      placeholder="Enter Full name"
      rules={{
        validate: {
          minLength: (v) => v?.trim().length > 1 || 'min 2 characters',
          maxLength: (v) => v?.trim().length < 51 || 'max 50 characters',
          required: (v) => !!v?.trim(),
        },
      }}
      withBackground
    />
  );
}
