import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGameEventBadgeListAPI, useGamelistAPI } from 'api/gamelist/gamelistAPI';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';
import { GameCardMobile } from 'elements/GameCard';

import PlayingNowSkeleton from './PlayingNowSkeleton';

import './playing-now-swiper.scss';

const PlayingNow: React.FC = () => {
  const { gxcTag } = useLog();

  const { isLoading, data } = useGamelistAPI(GameStatusType.TESTING, true);
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();

  const loadingSkeletonList = Array.from({ length: 8 }, (_, i) => i);

  const slideData = data && Array.isArray(data?.data) ? data.data : [];

  return (
    <Box width="100%" position="relative">
      <Box width="100%" p="0px 20px 32px 20px">
        <Typography variant="h4" height="35px">
          Play Now
        </Typography>
        <Typography variant="subtitle2" mt="8px" color="text.secondary">
          Register to G.Round to start playing games and earn rewards!
        </Typography>
      </Box>
      <Swiper spaceBetween={16} style={{ paddingLeft: 20, paddingRight: 20 }} slidesPerView="auto">
        {isLoading
          ? loadingSkeletonList.map((_, index) => (
              <SwiperSlide key={index} style={{ width: 285, flexBasis: 285 }}>
                <PlayingNowSkeleton />
              </SwiperSlide>
            ))
          : slideData.map((data, index) => (
              <SwiperSlide
                key={data.id}
                style={{ width: 285, flexBasis: 285, marginRight: '16px' }}
                onClick={() =>
                  gxcTag('click', {
                    area: 'play_now',
                    ui: 'big_game_card',
                    parameters: { game_id: data.id },
                  })
                }
              >
                <GameCardMobile
                  {...data}
                  event_badge={eventBadgeListData?.data.find(
                    (badge) => badge.target_id === data.id
                  )}
                  state={GameStatusType.TESTING}
                  hoverVideoPlay
                  isPrivateShow={false}
                  gameId={data?.id}
                  havePlayBtn
                  size="small"
                />
              </SwiperSlide>
            ))}
      </Swiper>
    </Box>
  );
};

export default PlayingNow;
