import { getLanguageNativeName } from '@ground/tools';
import { Dropdown, DropdownOptionValue } from '@ground/ui';

import { useGetDetailData } from 'views/detail/DetailProvider';

export default function DetailLanguageDropdown() {
  const { languageSet, selectLanguageCode, setSelectLanguageCode } = useGetDetailData();

  const languageOptions = languageSet?.map((language) => ({
    name: getLanguageNativeName(language.toLowerCase()),
    value: language,
  }));

  const handleChangeSelect = (option: DropdownOptionValue) => {
    setSelectLanguageCode(option.toString());
  };

  return languageOptions ? (
    <Dropdown
      size="small"
      width={120}
      placeholder="Language"
      options={languageOptions}
      onChangeValue={handleChangeSelect}
      value={selectLanguageCode}
    />
  ) : null;
}
