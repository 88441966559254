import { LoadingWrapper } from '@ground/ui';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGameReviewV2API } from 'api/comment/commentAPI';
import { useDialog } from 'hooks';
import ReviewContainerByAdmin from './ReviewContainerByAdmin';
import ReviewContainerByUser from './ReviewContainerByUser';
import ReviewContainerPrivateAdmin from './ReviewContainerPrivateAdmin';

interface Props {
  gameId: number;
  reviewId: number;
  testType: 'public' | 'private';
  isAdmin: boolean; // studio나 admin user인 경우
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReviewDialogBodyV2({
  gameId,
  reviewId,
  testType,
  isAdmin,
  setOpen,
}: Props) {
  const { isLoading, data, mutate } = useGameReviewV2API(gameId, reviewId, testType);
  const { setImmerState, showDialog } = useDialog();
  const navigate = useNavigate();
  const isNotAccessible =
    // 1. 데이터가 없음
    // 2. private test는 스튜디오나 어드민인 경우만 볼 수 있음(현재는 이 로직을 탈 일이 없음)
    (!isLoading && !data?.data) || (testType === 'private' && !isAdmin);

  useEffect(() => {
    if (!isLoading && isNotAccessible) {
      setOpen(false);
      showDialog({
        title: 'Review',
        body: (
          <Typography variant="body2" color="text.secondary">
            Sorry, this review is not available for viewing.
          </Typography>
        ),
        primaryButton: {
          text: 'G.Round Home',
          callback: () => navigate('/'),
        },
        secondaryButton: {
          text: 'Close',
        },
      });
    }
  }, [isLoading, isNotAccessible, navigate, setImmerState, setOpen, showDialog]);

  if (isNotAccessible) {
    return null;
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <LoadingWrapper isLoading={isLoading} size={64}>
        {data?.data ? (
          testType === 'public' ? (
            isAdmin ? (
              <ReviewContainerByAdmin reviewData={data.data} mutateReviewList={mutate} />
            ) : (
              <ReviewContainerByUser reviewData={data.data} mutateReviewList={mutate} />
            )
          ) : (
            <ReviewContainerPrivateAdmin reviewData={data.data} mutateReviewList={mutate} />
          )
        ) : null}
      </LoadingWrapper>
    </Box>
  );
}
