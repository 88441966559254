import { Typography, styled } from '@mui/material';

import { LinkButton } from 'elements';

import banImage from 'images/notfound/ban.svg';
import lockImage from 'images/notfound/lock.svg';
import waitImage from 'images/notfound/wait.svg';

const Root = styled('div')({
  width: '100%',
  minHeight: 719,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
});

const privateAuthTextList = {
  CLOSED: {
    icon: lockImage,
    title: 'Test Closed',
    content1: 'This playtest has come to an end.',
    content2: 'Explore other games from G.Round now!',
  },

  UPCOMING: {
    icon: waitImage,
    title: 'Starting Soon',
    content1: 'The playtest has not started.',
    content2: `We can't wait for you to try it!`,
  },

  AUTH: {
    icon: banImage,
    title: 'Access Denied',
    content1: 'This is a restricted page.',
    content2: 'This page can only be accessed by authorized accounts.',
  },
};

interface Props {
  code: keyof typeof privateAuthTextList;
}

export default function DetailAuth({ code }: Props) {
  const authInfo = privateAuthTextList[code];

  return (
    <Root>
      <img src={authInfo.icon} alt={code} />

      <Typography variant="h3" mb="12px" mt="30px">
        {authInfo.title}
      </Typography>

      <Typography variant="body1" color="text.secondary" textAlign="center" mb="40px">
        {authInfo.content1}
        <br />
        {authInfo.content2}
      </Typography>

      <LinkButton color="primary" variant="contained" to="/">
        Back to G.Round
      </LinkButton>
    </Root>
  );
}
