import { Navigate, Route, Routes } from 'react-router-dom';

import GPoint from './gpoint/GPoint';
import ProfileOrder from './profile-order/ProfileOrder';
import Profile from './profile/Profile';

export default function ProfileRouter() {
  return (
    <Routes>
      {/* <Route path="mygame" element={<div>My Games</div>} /> */}
      {/* <Route path="review" element={<div>Reviews & Surveys</div>} /> */}
      <Route path="gpoint" element={<GPoint />} />
      <Route path="profile" element={<Profile />} />
      <Route path="orders/*" element={<ProfileOrder />} />
      {/* <Route path="notification" element={<div>Notification</div>} /> */}
      <Route path="*" element={<Navigate to="profile" />} />
    </Routes>
  );
}
