import { QuerystringProvider } from 'context';
import { Route, Routes } from 'react-router-dom';
import NotFound from 'views/main/NotFound';
import SupporterApplyMain from './apply/SupporterApplyMain';
import SupporterApplyProvider from './apply/SupporterApplyProvider';

import SuppoterApplyConfirmMain from './confirm/SuppoterApplyConfirmMain';

const defaultQuery = { step: 1 };

export default function SupporterApply() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <QuerystringProvider defaultQuery={defaultQuery}>
            <SupporterApplyProvider>
              <SupporterApplyMain />
            </SupporterApplyProvider>
          </QuerystringProvider>
        }
      />
      <Route path="/confirm" element={<SuppoterApplyConfirmMain />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
