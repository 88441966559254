import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { ImageBox } from 'atoms';
import { Carousel } from 'elements';
import { NewsImageProps } from '.';

const swiperParams: SwiperOptions = {
  slidesPerView: 1,
};

interface Props {
  name: string;
  images: NewsImageProps[];
}

export default function NewsImageSlider({ name, images }: Props) {
  return (
    <Carousel
      swiperOption={swiperParams}
      name={name}
      navigationPosition="TOP"
      width={560}
      hasNavigation
    >
      {images?.map((image, index) => (
        <SwiperSlide key={index} style={{ width: '560px', height: '398px' }}>
          <ImageBox
            src={image.file_url}
            alt={`news image ${index}`}
            resize
            resizeWidth={560}
            resizeHeight={398}
            format="webp"
          />
        </SwiperSlide>
      ))}
    </Carousel>
  );
}
