import { Typography, styled, Stack, Box } from '@mui/material';

import joystickImage from 'images/banner/enjoy_joystick.png';

const CardRoot = styled(Stack)(({ theme }) => ({
  width: 285,
  height: 482,
  alignItems: 'flex-start',
  background: theme.palette.gray[90],
  borderRadius: 5,
  padding: '60px 28px',
  position: 'relative',
  border: '1px solid #FFFFFF0D',
}));

export default function EnjoyCard() {
  return (
    <CardRoot>
      <Typography color="base.dimWhite" sx={{ fontSize: '28px', lineHeight: '38px' }}>
        Explore New <br />
        Adventures
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ marginTop: '20px' }}>
        Discover more games awaiting <br /> for you!
      </Typography>

      <Box style={{ position: 'absolute', right: 42, bottom: 51 }}>
        <img src={joystickImage} alt="joy-stick" style={{ width: '139px', height: '167px' }} />
      </Box>
    </CardRoot>
  );
}
