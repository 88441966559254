import { Box } from '@mui/material';

import DetailRouter from './DetailRouter';

export default function DetailLower() {
  return (
    <Box padding="40px 0 200px" width="1200px">
      <DetailRouter />
    </Box>
  );
}
