import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, Ref, useMemo } from 'react';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';

import DataTableCell, { DataTableCellProps } from 'components/dataTable/DataTableCell';
import DataTableHeadCell from 'components/dataTable/DataTableHeadCell';
import GPagination, { GPaginationProps } from 'components/pagination/GPagination';

const PREFIX = 'DataReactTable';

const classes = {
  container: `${PREFIX}-container`,
  tableHeaderRow: `${PREFIX}-tableHeaderRow`,
  tableRow: `${PREFIX}-tableRow`,
  loadingData: `${PREFIX}-loadingData`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    backgroundColor: 'transparent',
  },

  [`& .${classes.tableHeaderRow}`]: {
    borderBottom: `2px solid ${theme.palette.background.default}`,
    background: theme.palette.gray[90],

    '& th': {
      padding: '0 20px',
    },
    '& th:first-of-type': {
      paddingLeft: 40,
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& th:nth-of-type(2)': {
      textAlign: 'left',
    },
    '& th:last-of-type': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },

  [`& .${classes.tableRow}`]: {
    borderBottom: `2px solid ${theme.palette.background.default}`,
    background: theme.palette.gray[90],
    '& td': {
      padding: '0 20px',
    },
    '& td:first-of-type': {
      width: 160,
      paddingLeft: 40,
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& td:nth-of-type(2)': {
      width: 450,
    },
    '& td:nth-of-type(3)': {
      width: 120,
    },
    '& td:nth-of-type(4)': {
      width: 120,
    },
    '& td:nth-of-type(5)': {
      width: 190,
    },
    '& td:last-of-type': {
      width: 180,
      paddingRight: 40,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[80],
      '& td > .MuiTypography-root:first-of-type': {
        color: theme.palette.base.dimWhite,
      },
      '& td > div > .MuiTypography-root:first-of-type': {
        color: theme.palette.base.dimWhite,
      },
    },
  },

  [`& .${classes.loadingData}`]: {
    margin: '0 16px',
  },
}));

export interface CellData {
  id: string;
  props?: DataTableCellProps;
  content: ReactNode;
}

interface Props {
  isLoading?: boolean;
  headers: HeaderGroup[] | Column[];
  cellData: {}[];
  paginationProps?: GPaginationProps;
  observedRef?: Ref<HTMLTableRowElement>;
}

export default function ProfileOrderDataReactTable({
  isLoading,
  headers,
  cellData,
  paginationProps,
  observedRef,
}: Props) {
  const loadingData = useMemo(() => Array.from({ length: 10 }, (_, i) => i), []);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns: headers,
    data: cellData,
  });

  return (
    <Root>
      <TableContainer className={classes.container} component={Paper} square>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow className={classes.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header) => (
                  <DataTableHeadCell {...header.getHeaderProps()} headerStyle={{ height: 57 }}>
                    {header.render('Header')}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoading
              ? loadingData.map((data) => (
                  <TableRow key={data} className={classes.tableRow}>
                    <TableCell padding="none" variant="body" colSpan={headers.length}>
                      <Skeleton className={classes.loadingData} height={80} />
                    </TableCell>
                  </TableRow>
                ))
              : rows?.map((row: Row, index: number) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className={classes.tableRow}
                      ref={index === cellData.length - 1 ? observedRef : undefined}
                      {...row.getRowProps()}
                    >
                      {row?.cells.map((cell: Cell, index: number) => (
                        <DataTableCell {...cell.getCellProps()} key={index}>
                          {cell.render('Cell')}
                        </DataTableCell>
                      ))}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationProps && (
        <Box display="flex" justifyContent="center" mt="48px">
          <GPagination {...paginationProps} />
        </Box>
      )}
    </Root>
  );
}
