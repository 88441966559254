import { Typography, Box } from '@mui/material';

import UserAvatar from 'atoms/UserAvatar';

export interface StudioMemberData {
  id: number;
  studio_id: number;
  name: string;
  title: string;
  file_id: number | null;
  file_url: string | null;
  seq: number;
  user_id: number | null; // linked user id
  email: string | null; // member email
  permission_adopt_review: boolean; // studio member permission for adopting review
  permission_issue_point: boolean; // studio member permission for issuing point
  status: 'IN' | 'OUT' | 'FRONT_LINKED' | 'ADMIN';
}

export function GameInfoStudioMember({ id, file_url, name, title }: StudioMemberData) {
  return (
    <Box key={id} display="flex" width="176px">
      <UserAvatar width={72} src={file_url ?? ''} alt={name} />

      <Box width="92px" marginLeft="12px" paddingTop="4px">
        <Typography variant="small1" color="text.primary">
          {name}
        </Typography>
        <Typography variant="small2" color="text.secondary" marginTop="4px">
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

interface Props {
  memberList: Array<StudioMemberData>;
}

export default function GameInfoStudioMembers({ memberList }: Props) {
  const sortedMemberList = Array.isArray(memberList)
    ? [...memberList]?.sort((a, b) => a.seq - b.seq)
    : [];

  return (
    <Box display="flex" flexWrap="wrap" gap="40px" marginTop="60px">
      {sortedMemberList.map((member) => (
        <GameInfoStudioMember key={member.id} {...member} />
      ))}
    </Box>
  );
}
