import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { isFirefox } from 'react-device-detect';

import { useAxios, useDialog, useSign } from 'hooks';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

interface Props {
  isMobile?: boolean;
  isSignup?: boolean;
}

export default function FacebookLogin({ isMobile, isSignup }: Props) {
  const [fbLoading, setFbLoading] = useState(false);
  const { showDialog, showSimpleDialog } = useDialog();
  const { signComplete } = useSign();
  const { gxcTag } = useLog();

  const { isLoading, refetch: login } = useAxios('sessions/facebook', { method: 'POST' }, true);
  const { refetch: signup } = useAxios('users/facebook', { method: 'POST' }, true);
  // const { refetch: connect } = useAxios('users/facebook', { method: 'PATCH' }, true);

  const facebookLogin = async (authResponse: fb.AuthResponse, meResponse: any) => {
    // 페이스북 API에서 에러 발생한 경우
    if (meResponse.error) {
      showSimpleDialog(
        'An error happened when you tried to login with Facebook',
        'Disable your ad blockers and pop-up blockers, and try again.'
      );
    } else if (meResponse.email) {
      // 이메일 정보가 있는 경우
      const { data, status } = await login({
        data: {
          access_token: authResponse.accessToken,
          account: authResponse.userID,
        },
      });

      if (status === 200 && data?.result_code === 'OK') {
        // 페이스북 로그인 성공한 경우
        // 2022-12-29 firefox에서 로그인 후 멈추는 문제 강제 리로드를 통해 임시 해결
        signComplete(data.data, isFirefox);
      } else {
        // 페이스북으로 가입된 정보가 없다면 연동 없이 가입 시도
        if (data?.result_code === '400') {
          const { data, status } = await signup({
            data: {
              access_token: authResponse.accessToken,
              account: authResponse.userID,
              name: meResponse.name,
              email: meResponse.email,
              picture: meResponse.picture?.data?.url,
              agree: false,
            },
          });

          if (status === 200 && data?.result_code === 'OK') {
            // 가입에 성공했다면 로그인 시켜준다
            signComplete(data.data, isFirefox);
            // Requirement to add Script for Google Ads 2021.11.26
            // https://www.notion.so/gameround/c9aa543eb50443c29b0c8a8246dbebea
            gtag('event', 'conversion', { send_to: 'AW-669277837/uDS-CMudwoMDEI29kb8C' });
          } else if (data?.result_code === '403') {
            // 이미 가입된 유저
            showSimpleDialog('Facebook Signup', 'Already registered user.');
          } else if (data?.result_code === '402') {
            // 같은 이메일을 사용하는 계정이 있는 경우 페이스북 연동으로 다시 가입 시도(에러 코드로 변경해야됨)
            // '일반계정이 있습니다. 연동 수락 체크를 해주십시요'
            showDialog({
              title: 'Facebook Sign Up',
              body: `${meResponse.email} account already exists. Link your Facebook account to your account?`,
              secondaryButton: { text: 'cancel' },
              primaryButton: {
                text: 'OK',
                callback: async () => {
                  const { data, status } = await signup({
                    data: {
                      access_token: authResponse.accessToken,
                      account: authResponse.userID,
                      name: meResponse.name,
                      email: meResponse.email,
                      picture: meResponse.picture?.data?.url,
                      agree: true,
                    },
                  });

                  if (status === 200 && data?.result_code === 'OK') {
                    signComplete(data.data, isFirefox);
                  } else {
                    showSimpleDialog('Facebook Sign Up', data?.description);
                  }
                },
              },
            });
          } else if (data?.result_code === '400') {
            showDialog({
              title: 'Error',
              body: 'The code you entered does not exist. Please try again.',
              primaryButton: { text: 'Back to Sign in' },
            });
          } else {
            showSimpleDialog('Facebook Sign Up', data?.description);
          }
          // 페이스북 계정은 있으나 일반계정이 없는 상태(탈퇴했거나 페이스북 이메일을 변경했거나 등)
        } else if (data?.result_code === '402') {
          showSimpleDialog(
            'Facebook Sign in',
            'Invalid account creation. Please contact our support team.'
          );
        } else if (data?.result_code === '410') {
          showSimpleDialog(
            'Facebook Sign in',
            'Email account exist. Send you a password reset email.'
          );
        } else {
          showSimpleDialog(
            'Facebook Sign in',
            data?.description || (
              <>
                Unknown error.
                <br />
                {`statusCode: ${status} resultCode: ${data?.result_code}`}
              </>
            )
          );
        }
      }
    } else {
      showSimpleDialog(
        'Facebook Sign in',
        'Ready up gamers! We need your email permission for Facebook sign up or you can go with regular email.'
      );
    }
  };

  const handleFacebookLogin = () => {
    eventTracker(`${isSignup ? 'signup_signup' : 'login_login'}_facebook`);
    gxcTag('click', { area: 'account', ui: 'facebook' });

    setFbLoading(true);

    FB.login(
      (response: fb.StatusResponse) => {
        const { authResponse, status } = response;

        if (status === 'connected') {
          console.log('Welcome!  Fetching your information.... ');

          // get user info
          FB.api('/me', { fields: ['id', 'email', 'name', 'picture'] }, (meResponse) => {
            if (authResponse && meResponse) {
              facebookLogin(authResponse, meResponse);
            } else {
              showDialog({
                title: 'Facebook Sign in',
                body: 'Facebook Sign in data could not be obtained.',
              });
            }
          });
        } else if (status === 'not_authorized') {
          // 로그인 거부이니 아무 동작도 하지 않는다.
          console.log('User cancelled sign in or did not fully authorize.');
        } else {
          // 창을 닫았거나 기타 사유 이므로 아무 것도 표시하지 않는다.
          // showDialog({ title: 'Facebook Sign in', body: 'Sign in failed by unknown reason.' });
        }
        setFbLoading(false);
      },
      { scope: 'public_profile, email', return_scopes: true, auth_type: 'rerequest' }
    );
  };

  return (
    <LoadingButton
      fullWidth
      color="light"
      variant="contained"
      size={isMobile ? 'largest' : 'large'}
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2" y="2" width="20" height="20" rx="3" fill="#1877F2" />
          <path
            d="M19 7.75304V5H16.3333C14.2 5 13.6667 7.44714 13.6667 8.67072V11.4238H11V14.1768H13.6667V22H16.3333V14.1768H18.1111L19 11.4238H16.3333V9.58839C16.3333 8.12011 17.2222 7.75304 17.6667 7.75304H19Z"
            fill="white"
          />
        </svg>
      }
      sx={{ background: 'white', height: isMobile ? 48 : 42 }}
      loading={fbLoading || isLoading}
      onClick={handleFacebookLogin}
    >
      Sign {isSignup ? 'up' : 'in'} with Facebook
    </LoadingButton>
  );
}
