import { Paper, Typography } from '@mui/material';

import { useGetDetailData } from '../DetailProvider';
import HowtoPlayGeneral from './HowToPlayGeneral';
import HowToPlaySteam from './HowToPlaySteam';
import HowToPlayEpic from './HowToPlayEpic';
import HowToPlaySelf from './HowToPlaySelf';

export default function HowToPlay() {
  const { files } = useGetDetailData();

  // 테스트 플랫폼이 두개 이상인 경우에 파일데이터가 두개 이상이 존재 하는데 이부분은 아직 고려하지 않고 구현되어 있음
  const firstFile = files?.[0];

  return (
    <Paper sx={{ padding: '40px 40px', borderRadius: '5px', backgroundColor: 'gray.90' }}>
      {firstFile ? (
        firstFile.deploy_type === 'GENERAL' ? (
          <HowtoPlayGeneral gamefileData={firstFile} />
        ) : firstFile.deploy_type === 'STEAM' ? (
          <HowToPlaySteam gamefileData={firstFile} />
        ) : firstFile.deploy_type === 'EPIC' ? (
          <HowToPlayEpic gamefileData={firstFile} />
        ) : (
          <HowToPlaySelf  gamefileData={firstFile} />
        )
      ) : (
        <Typography>The game file does not exist.</Typography>
      )}
    </Paper>
  );
}
