import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useLog } from 'utils/userLog';
import ArtAboutSns from './component/ArtAboutSns';

import ContactBGImg from 'images/about/aboutDev/about_contact_bg.png';

const Root = styled('div')(({ theme }) => ({
  width: 1440,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 80,
  paddingBottom: 80,
  textAlign: 'center',
  backgroundImage: `url(${ContactBGImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export default function ArtAboutContact() {
  const { gxcTag } = useLog();
  return (
    <Root id="contactUs">
      <Typography variant="h4">I have more questions!</Typography>
      <Typography variant="body1" sx={{ width: '481px', marginTop: '28px' }}>
        If you have any questions, concerns or would like <br />
        assistance with anything G.Round
      </Typography>
      <Typography variant="body1" mt="12px" mb="36px">
        Please get in touch!
      </Typography>
      <Button
        size="medium"
        variant="outlined"
        color="light"
        component="a"
        onClick={() =>
          gxcTag('click', {
            area: 'contact_us',
            ui: 'email',
          })
        }
        href="mailto:support@gameround.co?subject=[Support Ticket] I have a question"
      >
        Contact us
      </Button>
      <ArtAboutSns />
    </Root>
  );
}
