import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useSurveyData } from './SurveyProvider';
import SurveyPaper from './SurveyPaper';

export default function Survey() {
  const { state } = useLocation();
  const { loading, data, dataResult } = useSurveyData();

  // 데이터 로딩 후 scroll이 필요한 경우 탭 위치로 스크롤 이벤트 발생
  useEffect(() => {
    if (data && (state as { scroll?: boolean })?.scroll) {
      document.getElementById('gameDetailTab')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data, state]);

  return loading ? (
    <Box textAlign="center" height={600}>
      <CircularProgress color="primary" size={64} />
    </Box>
  ) : (
    <>
      {dataResult ? (
        dataResult.code === 'OK' ? (
          <SurveyPaper />
        ) : (
          <Typography>
            {dataResult.code === '402'
              ? 'Please complete the review first.'
              : dataResult.description}
          </Typography>
        )
      ) : (
        <Typography>Survey was closed.</Typography>
      )}
    </>
  );
}
