import { Models } from '@rematch/core';

import { login } from './login';
import { mute } from './mute';
import { mobile } from './mobile';

export interface RootModel extends Models<RootModel> {
  login: typeof login;
  mute: typeof mute;
  mobile: typeof mobile;
}

export const models: RootModel = { login, mute, mobile };
