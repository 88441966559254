import { useState } from 'react';
import { Box, styled, Stack, Typography } from '@mui/material';

import { UserAvatar } from 'atoms';
import { GHashLink } from 'elements';
import VideoClipMedia from './VideoClipMedia';

import circleIcon from 'images/icons/grey-circle.svg';

const ClipCardLargeBlock = styled('div')(() => ({
  borderRadius: 5,
}));

export interface ClipCardLargeProps {
  userName: string;
  country: string;
  videoTitle?: string;
  srcUrl: string;
  streamerUrl: string;
  onCardClickHandler: () => void;
  to: string;
  duration?: number;
}

const ClipCardLarge: React.FC<ClipCardLargeProps> = ({
  userName,
  country,
  videoTitle,
  srcUrl,
  streamerUrl,
  onCardClickHandler,
  to,
  duration,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <ClipCardLargeBlock>
      <GHashLink
        to={to}
        onClick={onCardClickHandler}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <VideoClipMedia
          hover={isHover}
          imageUrl={srcUrl || ''}
          playingTime={duration || 0}
          width={590}
          height={332}
          iconSize="LARGE"
        />
      </GHashLink>

      <Stack alignItems="center" direction="row" mt="20px">
        <UserAvatar width={40} src={streamerUrl} alt={userName} />
        <Box mx="12px" width="300px" flex="1 1 0">
          <Stack alignItems="center" direction="row" whiteSpace="nowrap">
            <Typography noWrap variant="small1">
              {userName}
            </Typography>
            <img src={circleIcon} alt="circle" style={{ marginLeft: 4, marginRight: 4 }} />
            <Typography variant="small1" color="text.secondary">
              {country}
            </Typography>
          </Stack>
          <Typography mt="4px" variant="subtitle3" color="text.secondary" noWrap>
            {videoTitle}
          </Typography>
        </Box>
      </Stack>
    </ClipCardLargeBlock>
  );
};

export default ClipCardLarge;
