
/**
 * @description 구글 gtag, 페이스북 pixel의 fgq 함수를 실행하여 발생한 이벤트를 추적하는 공용 함수이다.
 * @param {string} eventName : 이벤트 이름, 최대 50자
 * @param {object} params : 이벤트가 발생한 category의 ID, 링크의 url 등 이벤트 식별에 필요한 추가 정보
 */

export default function eventTracker(eventName: string, params?: Record<string, string | number>) {
  if (process.env.REACT_APP_ENV === 'PROD') {
    if (params === undefined) {
      gtag('event', eventName);
    } else {
      gtag('event', eventName, params);
    }
    fbq('trackCustom', eventName);
  }
}