import useSWRFetch from 'api/useSWRFetch';
import { BannerDataType } from 'types/BannerType';
import { EventBadgeType } from 'types/EventBadgeType';

interface Props {
  sectionId: '301' | '302';
}

export function useGStoreBannerAPI({ sectionId }: Props) {
  return useSWRFetch<BannerDataType[]>({ key: `banner/site/${sectionId}/list/activated` });
}

export function useGStoreBadgeListAPI() {
  return useSWRFetch<EventBadgeType[]>({ key: 'banner/badge/list' });
}
