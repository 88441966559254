// import HomePopupLayerRecommend from './HomePopupLayerRecommend';
import HomePopupLayerLauncherReinstall from './HomePopupLayerLauncherReinstall';

// 지금은 임시로 작성하였고 추후 레이어 관리자를 만들어서 어드민에서 설정할 수 있게 만들 것임
export default function HomePopupLayer() {
  return (
    <>
      {/* 더이상 띄우지 않기로 하여 제거 */}
      {/* <HomePopupLayerRecommend /> */}
      <HomePopupLayerLauncherReinstall />
    </>
  );
}
