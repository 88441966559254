import { GIcon } from '@ground/ui';
import { Box, Paper, Popper, PopperProps, SxProps, Theme, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren<PopperProps> {
  direction: 'left' | 'center' | 'right';
  hasCloseIcon?: boolean;
  handleClose?: () => void;
  paperStyle?: SxProps<Theme>;
}

export const GPopper = (props: Props) => {
  const { direction, hasCloseIcon, handleClose, paperStyle, modifiers = [], ...rest } = props;
  const { palette } = useTheme();

  return (
    <Popper
      placement={direction === 'right' ? 'top-end' : direction === 'center' ? 'top' : 'top-start'}
      {...rest}
      sx={{ zIndex: 999 }}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 5],
          },
        },
        ...modifiers,
      ]}
    >
      <Box
        sx={{
          position: 'relative',
          paddingBottom: '10px',
          left: direction === 'center' ? 4 : undefined,
          overflow: 'hidden',
          zIndex: 0,
          ...paperStyle,
        }}
      >
        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            bottom: -4,
            right:
              direction === 'center' ? 'calc(50% - 12px)' : direction === 'right' ? 0 : undefined,
            left: direction === 'left' ? 0 : undefined,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="8"
            viewBox="0 0 35 8"
            fill="none"
          >
            <path
              d="M23 4.76837e-07L18.6982 7.32134C18.4481 7.74662 18.0009 8 17.5006 8C17.0004 8 16.5532 7.74662 16.3031 7.32134L12 -4.84814e-07L23 4.76837e-07Z"
              fill="#464646"
            />
          </svg>
        </Box>

        <Paper
          sx={(theme) => ({
            background: theme.palette.gray[40],
            borderRadius: '5px',
            p: '8px 10px',
            position: 'relative',
            paddingRight: hasCloseIcon ? '28px' : undefined,
          })}
        >
          {hasCloseIcon && (
            <GIcon
              iconName="close"
              iconSize={16}
              iconColor={palette.base.dimWhite}
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: '6px',
                right: '6px',
                opacity: 0.5,
              }}
            />
          )}
          {props.children}
        </Paper>
      </Box>
    </Popper>
  );
};
