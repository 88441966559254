import { DropdownOption, FormDropdown, FormFieldArray } from '@ground/ui';
import { useWatch } from 'react-hook-form';

import {
  useGetClassListAPI,
  useGetUniversityInfoAPI,
  useGetUniversityMemberInfoAPI,
} from 'api/university/universityAPI';
import type { StudentFormData } from 'types/UniversityType';

interface ClassSelectField {
  class_id: number | null;
}

interface Props {
  uniqueKey?: string | null;
}

// 학생 멀티 클래스 선택 폼
export default function ClassSelectForm({ uniqueKey }: Props) {
  // 최초 가입인 경우에 university id 가져오기
  const { data: universityInfo } = useGetUniversityInfoAPI(uniqueKey);
  // 정보 수정인 경우 university id 가져오기
  const { data: studentInfo } = useGetUniversityMemberInfoAPI(uniqueKey ? undefined : 'student');

  // university id로 class list 가져오기
  const { data: classInfoList } = useGetClassListAPI(
    uniqueKey ? universityInfo?.data.university_id : studentInfo?.data.university_id
  );

  // 현재 선택한 클래스 리스트
  const selectClasses = useWatch<StudentFormData, 'class'>({ name: 'class' });

  return (
    <FormFieldArray<ClassSelectField>
      label="Class"
      fieldName="class"
      initialValue={{ class_id: null }}
      minCount={1}
      maxCount={20}
    >
      {({ name, index }) => {
        // class list 생성
        const classListOptions: DropdownOption[] | undefined = classInfoList?.data?.map(
          (classInfo) => ({
            name: `${classInfo.class_name} (${classInfo.educator_fullname})`,
            value: classInfo.class_id,
            disabled:
              // 다른 필드에서 선택된 값은 disabled로 보여주지만 현재 선택되어 있는 값은 disabled 안되도록 처리
              selectClasses?.[index]?.class_id !== classInfo.class_id &&
              !!selectClasses.find((v) => v.class_id === classInfo.class_id),
          })
        );

        //list가 생성되기 전에 form이 그려지면 defaultValue가 보이지 않음
        return classListOptions ? (
          <FormDropdown
            name={`${name}.class_id`}
            fullWidth
            withBackground
            options={classListOptions}
            placeholder="Select Class"
            rules={{ required: true }}
          />
        ) : null;
      }}
    </FormFieldArray>
  );
}
