import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import noDataIcon from 'images/no_data_icon.svg';

interface NoDataProps {
  imageSrc?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
}

export default function NoData({ imageSrc, title, subTitle }: NoDataProps) {
  return (
    <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
      <img src={imageSrc ? imageSrc : noDataIcon} alt="no data" style={{ marginBottom: '20px' }} />
      {title ? (
        <Typography variant="body1" color="text.secondary2" textAlign="center">
          {title}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography
          variant="body2"
          color="text.secondary2"
          marginTop={title ? '4px' : undefined}
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {subTitle}
        </Typography>
      ) : null}
    </Stack>
  );
}
