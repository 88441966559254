// profile > order list 상단의 탭에서 사용하는 값들
export const OOrderTab = {
  MERCHANDISE: 'merchandise',
  DIGITAL_GAMES: 'digital-games',
  DIGITAL_PRODUCTS: 'digital-products',
} as const;
export type OrderTabKey = keyof typeof OOrderTab;
export type OrderTab = (typeof OOrderTab)[OrderTabKey];

export const OMerchandiseOrderStatus = {
  REQUEST: 'Order Requested',
  ORDERED: 'Order Placed',
  CONFIRMED: 'Order Confirmed',
  PACKING: 'Packing',
  IN_TRANSIT: 'In Transit',
  DELIVERED: 'Delivered',
  CANCELED_ORDER: 'Order Cancelled',
  CANCELED_PAYMENT: 'Refund Completed',
  FAILED: 'Order Failed',
} as const;
export type MerchandiseOrderStatusKey = keyof typeof OMerchandiseOrderStatus;
export type MerchandiseOrderStatus = (typeof OMerchandiseOrderStatus)[MerchandiseOrderStatusKey];

// 디지털 상품(digital game, digital goods)의 주문 상태
export const ODigitalOrderStatus = {
  ORDERED: 'Order Placed',
  DELIVERED: 'Delivered',
  CANCELED_ORDER: 'Order Cancelled',
  CANCELED_PAYMENT: 'Refund Completed',
  FAILED: 'Order Failed',
} as const;
export type DigitalOrderStatusKey = keyof typeof ODigitalOrderStatus;
export type DigitalOrderStatus = (typeof ODigitalOrderStatus)[DigitalOrderStatusKey];

export const OOrderType = {
  PHYSICAL_GOODS: 'physical-goods',
  DIGITAL_GAMES: 'digital-games',
  DIGITAL_GOODS: 'digital-goods',
} as const;
export type OrderTypeKey = keyof typeof OOrderType;
export type OrderType = (typeof OOrderType)[OrderTypeKey];

// 주문 타입 정의
export namespace IOrder {
  // 주문 개수
  export namespace GetOrderCount {
    export interface OrderCount {
      physical_goods: number;
      digital_goods: number;
      digital_games: number;
    }

    export type Response = OrderCount;
  }

  // 주문 상세
  export namespace GetOrderDetail {
    interface Product {
      // 주문한 상품 정보
      sale_product_id: string | null; // 판매 아이디
      digital_goods_id: number | null;
      digital_game_id: number | null;
      title: string; // 상품 이름
      quantity: number; // 주문 수량
      thumbnail_url: string;
      card_pin: string | null;
      price: number; // 개당 가격
    }

    interface Shipping {
      // 배송정보
      id: string; // 배송 고유번호
      email: string; // 수신자 이메일
      name: string; // 수신자 이름
      international_no: string; // 국제 전화 번호
      phone: string; // 수신자 전화번호
      address1: string; // 주소1
      address2?: string; // 주소2
      city: string; // 도시명
      state: string; // 주 또는 Area 이름
      country_code: string; // 국가 코드
      country_name: string; // 국가명
      zipcode: string; // 우편번호
      channel_code: string; // 배송업체 코드
      channel_name: string; // 배송업체명
      tracking_no: string; // 송장번호
      id_card_item: number | null; // 국가 추가 입력 아이템 아이디
      id_card_title: string | null; // 국가 추가 입력 아이템 타이틀
      id_card_no: string | null; // 국가 추가 입력값
      id_card_required: boolean; // 국가 추가 정보 입력 필수여부 (true: 필수, false: 옵션)
      create_at: string;
      create_by: number;
    }

    interface Payment {
      // 결제 정보
      point_type: 'EARNED' | 'PURCHASED';
      amount: number; // 결제 금액
      payment_at: string; // 결제일시
      // payment_method: 'POINT'; // 결제 방법 (현재는 POINT 하나 뿐이라 필요 없음)
    }

    interface History {
      id: number;
      status: MerchandiseOrderStatusKey;
      date: string;
    }

    export interface OrderDetail {
      id: string; // 주문번호
      status: MerchandiseOrderStatusKey; // 주문상태
      type: OrderTypeKey; // 주문 타입
      order_user_email: string; // 사용자 Email (주문당시)
      order_amount: number;
      order_date: string; // 주문일시
      product: Product;
      shipping: Shipping;
      payments: Payment[];
      histories: History[];
    }

    export type Response = OrderDetail;
  }

  // 상품 주문목록
  export namespace GetOrderList {
    export interface Order {
      order_id: string;
      order_date: string;
      product_thumbnail_url: string;
      quantity: number;
      product_title: string;
      order_price: number;
      order_type: OrderTypeKey;
      order_status: MerchandiseOrderStatusKey;
    }

    export type Response = Order[];
  }

  // 디지털 게임 상품 주문
  export namespace CreateOrder {
    export interface Response {
      order_id: string;
      order_status: number;
    }

    export interface Request {
      digital_game_id: number;
      quantity: number;
      price: number;
    }
  }
}
