import { Box } from '@mui/material';

import GpointTitleUsePointSection from './GpointTitleUsePointSection';
import GPointTableContainer from './point-table/GPointTableContainer';
import GPointSummary from './point-summary/GPointSummary';

export default function GPoint() {
  return (
    <>
      <GpointTitleUsePointSection />
      <GPointSummary />
      <Box height={60} />
      <GPointTableContainer />
    </>
  );
}
