import { Box, Skeleton, styled, Typography } from '@mui/material';

import { AxiosError } from 'axios';
import GameInfoSystemRequirementItem from './GameInfoSystemRequirementItem';

const StyledList = styled('ul')({
  marginBlockEnd: 0,
  paddingInlineStart: 0,
  '& > *:last-type-of': {
    marginBottom: 0,
  },
});

const categoryNames: Record<string, string> = {
  OS: 'OS',
  PROCESSOR: 'Processor',
  MEMORY: 'Memory',
  GRAPHICS: 'Graphics',
  VIDEOREM: 'Video RAM',
  STORAGE: 'Storage',
  NETWORK: 'Network',
  ADDITIONAL: 'Additional',
};

interface SystemRequirementsType {
  id: number;
  category: string;
  minimum: string;
  recommended: string;
}

interface DetailInfoSystemRequirementsProps {
  type: 'Minimum' | 'Recommended';
  isLoading?: boolean;
  data?: SystemRequirementsType[];
  error?: AxiosError | null | boolean;
  titleColor?: string;
}

const SkeletonItem = () => (
  <Skeleton
    variant="text"
    width={320}
    height={40}
    animation="wave"
    sx={{ background: '#88919e', opacity: 0.3 }}
  />
);

export default function DetailInfoSystemRequirements({
  type,
  isLoading,
  data,
  error,
  titleColor = 'text.secondary',
}: DetailInfoSystemRequirementsProps) {
  const isMinType = type === 'Minimum';

  return (
    <Box width="100%">
      <Typography variant="subtitle1" color={titleColor} marginBottom="36px">
        {type}
      </Typography>
      <StyledList>
        {isLoading ? (
          <>
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </>
        ) : error ? (
          <Typography>Something went wrong. Please try again.</Typography>
        ) : (
          data?.map?.((system: SystemRequirementsType) => (
            <GameInfoSystemRequirementItem
              key={system.id}
              title={categoryNames[system.category]}
              text={isMinType ? system.minimum : system.recommended}
            />
          ))
        )}
      </StyledList>
    </Box>
  );
}
