import { SelectionProps } from '@ground/ui';
import { Box, Paper } from '@mui/material';
import { SupporterFormProps } from 'api/supporter/useSuppoterTypes';
import { get, useFormContext } from 'react-hook-form';
import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

import FormCheckboxGroup from './form/FormCheckboxGroup';

export default function SupporterApplyQuestionCheckBox({
  question_context,
  disabled = false,
  index,
  required,
  question_selection,
  required_option,
}: SupporterFormProps) {
  const {
    formState: { errors },
    // register,
  } = useFormContext();

  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);

  const options: SelectionProps[] = question_selection?.map((selection, index) => ({
    id: selection.question_id + index,
    order: selection.question_seq,
    label: selection.question_content,
  }));

  const subtitle = required_option === 'Mobile' ? 'モバイルテスト可能なユーザーに必須' : '';

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : undefined }}>
      <SupporterQuestionTitle
        question={question_context}
        error={
          !!(
            error?.question_answers?.filter((item: { type: string }) => item?.type === 'validate')
              ?.length === options.length
          )
        }
        required={required}
        subtitle={subtitle}
      />
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          marginTop: '-10px',
        }}
      >
        <FormCheckboxGroup
          minSelect={1}
          disabled={disabled}
          name={`${name}.question_answers`}
          options={options}
          required={required}
          index={index}
        />
      </Paper>
    </Box>
  );
}
