import { LoadingWrapper } from '@ground/ui';
import { Box } from '@mui/material';
import { useEffect } from 'react';

import { useGameDetailVideoClipAPI } from 'api/detail/detailAPI';
import { useLog } from 'utils/userLog';
import { TestingGame } from './ClipsPopup/Area/ClipBottomArea';
import { ClipUpperArea } from './ClipsPopup/Area/ClipUpperArea';
import { useClipPopupData } from './ClipsPopup/ClipPopupProvider';
import NoVideoClip from './NoVideoClip';
interface Props {
  gameId: number;
  videoId?: number;
  disableThumbnail?: boolean;
  area?: string;
}

export default function TestingGameClipsContainer({
  gameId,
  videoId,
  disableThumbnail,
  area,
}: Props) {
  const { gxcTag } = useLog();
  const { dataIndex, setDataIndex } = useClipPopupData();
  const {
    data: clipsData,
    isLoading,
    mutate,
  } = useGameDetailVideoClipAPI(gameId, 'sortingFilterType=UPLOAD');
  const findIndex = clipsData?.data.findIndex((item) => item.id === videoId) ?? 0;

  useEffect(() => {
    return () => setDataIndex(0);
  }, [setDataIndex]);

  const onLikeChange = (liked: boolean) => {
    gxcTag('click', {
      area,
      ui: 'like',
      parameters: { game_id: gameId },
    });

    if (clipsData?.data) {
      clipsData.data[dataIndex].like_check = liked;
      clipsData.data[dataIndex].like++;

      mutate(clipsData, false);
    }
  };

  return (
    <LoadingWrapper isLoading={isLoading} size={80}>
      <ClipUpperArea
        area={area}
        initialSlide={Number(findIndex)}
        clipsData={clipsData?.data}
        disableThumbnail={disableThumbnail}
      />
      {clipsData?.data && clipsData.data.length > 0 ? (
        <TestingGame
          area={area}
          gameId={gameId}
          clipInfo={clipsData.data[dataIndex]}
          onLikeChange={onLikeChange}
        />
      ) : (
        <Box width="800px" height="772px">
          <NoVideoClip />
        </Box>
      )}
    </LoadingWrapper>
  );
}
