import { GIconButton } from '@ground/ui';
import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { last } from 'lodash-es';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { OMerchandiseOrderStatus } from 'api/order';
import { LinkButton } from 'elements';
import { useGStoreOrderDetailData } from './GStoreOrderDetailProvider';
import GetOrderDetailStepper from './GStoreOrderDetailStepper';

const PREFIX = 'GStoreOrderDetailDelivery';

const classes = {
  productInfoContainer: `${PREFIX}-productInfoContainer`,
  trackingInfoBox: `${PREFIX}-trackingInfoBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.productInfoContainer}`]: {
    padding: 40,
    backgroundColor: theme.palette.gray[90],
    borderRadius: 5,
  },
  [`& .${classes.trackingInfoBox}`]: {
    position: 'relative',
    padding: '16px 20px',
    width: '100%',
    borderRadius: 5,
    backgroundColor: theme.palette.gray[80],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '28px',
  },
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.gray[20],
  marginRight: '8px',
}));

export default function GStoreOrderDetailDelivery() {
  const { data } = useGStoreOrderDetailData();
  const [copied, setCopied] = useState<boolean>(false);

  const orderShipData = data?.data?.shipping;

  const historyData = [
    ...(data?.data?.histories?.filter((history) => history.status !== 'CONFIRMED') ?? []).reverse(),
  ];
  const currentHistory = last(historyData);

  return (
    <Root>
      <Typography variant="h5" mb="20px">
        {currentHistory ? OMerchandiseOrderStatus[currentHistory.status] : ''}
      </Typography>
      <Stack className={classes.productInfoContainer}>
        <GetOrderDetailStepper />

        <Divider sx={{ mt: '40px', mb: '28px' }} />

        <Stack gap="12px">
          {historyData.map((history, index) => (
            <Box key={history.id} sx={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Dot />
                <Typography variant="body1" color="text.secondary">
                  {history?.date
                    ? `${format(new Date(history.date), 'MMM dd, yyyy hh:mm a')} (PT)`
                    : ''}
                </Typography>
              </Box>

              <Typography
                variant="subtitle1"
                color={index === 0 ? 'text.primary' : 'text.secondary2'}
              >
                {OMerchandiseOrderStatus[history.status]}
              </Typography>
            </Box>
          ))}
        </Stack>

        {orderShipData?.tracking_no && (
          <Box className={classes.trackingInfoBox}>
            <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" sx={{ flexBasis: 340, position: 'relative' }}>
                <Typography variant="body1" color="text.secondary" mr="20px">
                  Shipping Company
                </Typography>
                <Typography variant="subtitle1" color="text.primary">
                  {orderShipData.channel_name}
                </Typography>
              </Box>
              <Divider
                flexItem
                orientation="vertical"
                sx={{ backgroundColor: ({ palette }) => palette.opacity.white[10], mx: '40px' }}
              />
              <Box display="flex" alignItems="center" sx={{ gap: '20px' }}>
                <Typography variant="body1" color="text.secondary">
                  Tracking number
                </Typography>
                <Typography variant="subtitle1" color="text.primary">
                  {orderShipData.tracking_no}
                </Typography>
              </Box>

              <CopyToClipboard
                text={orderShipData.tracking_no ?? ''}
                onCopy={() => {
                  setCopied(true);
                }}
              >
                <GIconButton iconName="copy" iconSize={24} size={32} ml="10px" />
              </CopyToClipboard>
              {copied && (
                <Typography variant="button3" color="blue.hover" ml="10px">
                  Link copied!
                </Typography>
              )}
            </Box>

            <LinkButton
              color="primary"
              variant="contained"
              target="_blank"
              rel="noreferrer"
              to={`https://t.17track.net/en#nums=${orderShipData.tracking_no}`}
            >
              Track your Shipment
            </LinkButton>
          </Box>
        )}
      </Stack>
      <Divider />
    </Root>
  );
}
