import { Box, CircularProgress, styled } from '@mui/material';
import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import { useDialog } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { getDdayInfo } from 'utils/common';
import NotFound from 'views/main/NotFound';
import DetailAuth from '../detail/DetailAuth';
import ClientApplyLanguageDropdown from './ClientApplyLanguageDropdown';
import { useClientApplyData } from './ClientApplyProvider';

import JpnClientApplyStepper from './jpn/ClientApplyStepper';
import JpnClientApplyCotents from './jpn/contents/ClientApplyCotents';

import ClientApplyStepper from './ClientApplyStepper';
import ClientApplyContents from './contents/ClientApplyCotents';

// 프린트 display: none 처리
const RootBox = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  '@media print': {
    display: 'none',
  },
}));

export default function ClientApplyMain() {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { data: timeData } = useGetNotificationCountAPI();
  const { loading, error, recruitmentData } = useClientApplyData();
  const { selectLanguageCode } = useClientApplyData();

  if (loading) {
    return (
      <Box width="100%" height="500px" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  // (접근가능여부)
  if (recruitmentData?.confirm_yn === false) {
    return <DetailAuth code="AUTH" />;
  }

  // 모집기간이 아닐때
  if (
    getDdayInfo(String(timeData?.transaction_time), String(recruitmentData?.recruit_end)) > 0 ||
    getDdayInfo(String(timeData?.transaction_time), String(recruitmentData?.recruit_start)) < 0
  ) {
    showDialog({
      title: 'Alert!',
      body: 'The recruitment period is currently closed',
      primaryButton: {
        text: 'OK',
        callback: () => navigate('/'),
      },
      onClose: () => navigate('/'),
    });
  }

  // (신청여부)
  if (recruitmentData?.apply_yn === 1) {
    showDialog({
      title: 'Alert!',
      body: 'You have already applied',
      primaryButton: {
        text: 'OK',
        callback: () => navigate('/'),
      },
      onClose: () => navigate('/'),
    });
  }

  return error ? (
    <NotFound />
  ) : (
    <RootBox
      display="flex"
      width="1200px"
      alignItems="center"
      flexDirection="column"
      onContextMenu={(e) => {
        e.preventDefault();
        alert('Prevent right-click');
      }}
    >
      <ClientApplyLanguageDropdown />

      {selectLanguageCode === 'JA' ? (
        <>
          <JpnClientApplyStepper />
          <JpnClientApplyCotents />
        </>
      ) : (
        <>
          <ClientApplyStepper />
          <ClientApplyContents />
        </>
      )}
    </RootBox>
  );
}
