import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import ArtDevPackagesCard from './ArtDevPackagesCard';

import groundLogo from 'images/about/aboutDev/ground_logo_pack.svg';

const PREFIX = 'ArtDevPackages';

const classes = {
  nameArea: `${PREFIX}-nameArea`,
  indieContents: `${PREFIX}-indieContents`,
  packTitle: `${PREFIX}-packTitle`,
  cardsWrap: `${PREFIX}-cardsWrap`,
  premiumContents: `${PREFIX}-premiumContents`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',

  [`& .${classes.nameArea}`]: {
    marginTop: 68,
    marginRight: 56,
  },

  [`& .${classes.indieContents}`]: {
    width: 328,
    marginRight: 40,
  },

  [`& .${classes.packTitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    marginBottom: 32,
    borderRadius: 3,
    backgroundColor: theme.palette.gray[80],
  },

  [`& .${classes.cardsWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 28,
  },

  [`& .${classes.premiumContents}`]: {
    width: 506,
  },
}));

const startupPackList = [
  {
    title: 'Starter/Basic',
    price: 'Talk to us',
    desc: (
      <>
        <Typography variant="body2" color="text.secondary2">
          Young team on a steady diet of cup noodles? Ask us about R&D slot availability
        </Typography>
        <Typography variant="small2" color="text.secondary2" sx={{ marginTop: '12px' }}>
          * Starter/Basic package does not guarantee any numbers
        </Typography>
      </>
    ),
  },
  {
    title: 'Bronze',
    price: '$10,000',
    desc: 'Initial package for pre-alpha or alpha games looking to obtain gamer feedback and improve game development direction',
  },
];

const premiumPackList = [
  {
    title: 'Silver',
    price: '$20,000',
    desc: 'Advanced package for alpha or pre-beta games, aiming to match proper publishers or investors based on test results',
  },
  {
    title: 'Gold',
    price: '$30,000',
    desc: 'Boost-up package for pre-beta games with more user and content creators  involvement, identifying target countries',
  },
  {
    title: 'Platinum',
    price: '$50,000',
    desc: 'Customized package for beta games with global exposure via media and content creators, identifying target countries and preparing official launch',
  },
];

export default function ArtDevPackages() {
  return (
    <Root>
      <div className={classes.nameArea}>
        <img src={groundLogo} alt="ground" style={{ display: 'block' }} />
        <Typography variant="subtitle3" color="text.secondary2" sx={{ margin: '12px 0' }}>
          By ordering a package from G.Round, you’re eligible for a
        </Typography>
        <Typography variant="subtitle3">$10,000 AWS voucher.</Typography>
        <Typography variant="body3" color="text.secondary2" marginTop="4px">
          (from Bronze Package)
        </Typography>
      </div>
      <div className={classes.indieContents}>
        <Typography variant="subtitle1" color="text.secondary" className={classes.packTitle}>
          Startup Packages
        </Typography>
        <div className={classes.cardsWrap}>
          {startupPackList.map((item, index) => (
            <ArtDevPackagesCard key={index} {...item} />
          ))}
        </div>
      </div>
      <div className={classes.premiumContents}>
        <Typography variant="subtitle1" color="text.secondary" className={classes.packTitle}>
          Premium Packages
        </Typography>
        <div className={classes.cardsWrap}>
          {premiumPackList.map((item, index) => (
            <ArtDevPackagesCard key={index} {...item} />
          ))}
        </div>
      </div>
    </Root>
  );
}
