import { Avatar, GIconButton } from '@ground/ui';
import { Badge, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUploadUserImageAPI } from 'api/profile/profileAPI';
import { useDialog, useError } from 'hooks';
import { Dispatch, iRootState } from 'store';

export default function SummaryAvatar() {
  const ref = useRef<HTMLInputElement>(null);
  const { picture, name } = useSelector((state: iRootState) => state.login);
  const dispatch = useDispatch<Dispatch>();
  const { isLoading, fetch } = useUploadUserImageAPI();
  const { showSimpleDialog } = useDialog();
  const { showError } = useError();

  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files || e.target.files?.length === 0) return;

    const file = e.target.files?.[0];
    if (file.size > 1024 * 1024) {
      showSimpleDialog(
        'Image Upload Error',
        <>
          Image file size greater than 1MB.
          <br />
          Upload and image of less than or equal to 1MB.
        </>
      );
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(formData);
    const { status, data } = response;
    if (status === 200 && data?.result_code === 'OK') {
      dispatch.login.updateLoginInfo({
        access_token: data?.data?.access_token,
        picture: data?.data?.picture,
        filled: data?.data?.filled,
      });
    } else {
      showError(response, 'Image Upload Error');
    }
  };

  return (
    <Stack alignItems="center">
      <Badge
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent={
          <>
            <input
              ref={ref}
              accept="image/*"
              id="upload-picture"
              type="file"
              onChange={handleUploadChange}
              style={{ display: 'none' }}
            />
            <GIconButton
              onClick={() => ref?.current?.click()}
              aria-label="upload picture"
              iconName="plus"
              iconSize={16}
              size={24}
              backgroundColor="blue.primary"
              backgroundHoverColor="blue.hover"
              variant="circle"
              disableHoverEffect
              disabled={isLoading}
            />
          </>
        }
        overlap="circular"
      >
        <Avatar size="largest" src={picture ?? ''} alt="user profile" />
      </Badge>
      <Typography variant="button2" color="text.secondary" marginTop="8px" maxWidth={92} noWrap>
        {name}
      </Typography>
    </Stack>
  );
}
