import { VerificationInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useEmailCertificationCodeAPI, useVerifyCodeAPI } from 'api/deleteAccount/deleteAccountAPI';
import { iRootState } from 'store';

export interface VerificationContentProps {
  onVerificationSuccess?: () => void;
}

export default function VerificationContent({ onVerificationSuccess }: VerificationContentProps) {
  const { email } = useSelector((state: iRootState) => state.login);
  // resend
  const { isLoading, fetch } = useEmailCertificationCodeAPI();
  // post
  const { isLoading: loadingVerification, fetch: fetchVerification } = useVerifyCodeAPI();
  const [code, setCode] = useState<Array<string>>([]);
  const [error, setError] = useState<string | null>(null);
  const [resendAvailable, setResendAvailable] = useState(false);

  // 30초가 지난다음 resend code 가능
  useEffect(() => {
    const timerId = window.setTimeout(() => setResendAvailable(true), 30000);
    return () => window.clearTimeout(timerId);
  }, []);

  const handleClickResend = async () => {
    // 코드 발송 후 30초가 안지나서 재발송 불가능한 경우
    if (!resendAvailable) {
      setError('Verification code is already sent. Please try again later.');
      return;
    }

    setResendAvailable(false);
    window.setTimeout(() => setResendAvailable(true), 30000);
    // 혹시 오류 발생하면 clearTimeout 추가

    const response = await fetch({
      email: email,
      type: 'withdraw',
      device: 'P',
    });
    if (response.status === 200 && response.data.result_code === 'OK') {
      // 이메일 발송 성공
      setError(null);
    } else {
      setError(
        'An error occurred while sending verification email or email address does not exist.'
      );
    }
  };

  const handleVerification = async (code: Array<string>) => {
    const verificationCode = code.join('');
    if (verificationCode.length !== 6) {
      setError('Please enter a vaild code.');
      return;
    }

    const response = await fetchVerification({ sent_code: code.join('') });
    if (response.status === 200) {
      if (response.data.result_code === 'OK' && response.data.data === 'PASS') {
        setError(null);
        onVerificationSuccess?.();
      } else if (response.data.result_code === 'NOT') {
        // 코드가 잘못되었을 때
        setError('Please enter a vaild code.');
        // 확인해보니 만료에 따른 에러 구분 없이 넘어옴
        // } else if (response.data.result_code === 'NOT') {
        //   // 만료된 코드일 떄
        //   setError('Your code has expired.');
      } else {
        // 서버 error code 확인할것
        setError('Please enter a valid code');
      }
    }
  };

  return (
    <>
      <Typography align={'left'} color="text.secondary" variant="subtitle2">
        An email with a verification code was just sent to
        <Box color="text.primary" component="span">
          {' '}
          {email ? email : 'sample@gameround.co'}
        </Box>
        . Please check your email then enter the verification code below.
        <br />
        (The verification code is valid for 1 hour)
      </Typography>

      <Divider flexItem sx={{ mt: '40px', mb: '20px' }} />

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography color="text.primary" variant="body1" mb="20px">
          Verification Code
        </Typography>

        <VerificationInput
          error={!!error}
          onChangeValues={(values) => setCode(values)}
          onComplete={(values) => handleVerification(values)}
        />
        <Typography
          color="error.main"
          variant="button2"
          sx={{ minHeight: '16px', marginY: '12px' }}
        >
          {error}
        </Typography>
        <Divider flexItem sx={{ mb: '20px' }} />

        <Typography color="text.secondary2" variant="body3">
          Didn’t receive the email? Check your spam folder.
        </Typography>

        <LoadingButton
          color="light"
          loading={isLoading}
          onClick={handleClickResend}
          size="large"
          sx={{
            lineHeight: '20px',
            marginTop: '12px',
            padding: 0,
            textDecoration: 'underline',
            ':hover': { textDecoration: 'underline' },
          }}
          variant="text"
        >
          Resend Verification Email
        </LoadingButton>

        <LoadingButton
          disabled={code.join('').length !== 6}
          loading={loadingVerification}
          fullWidth
          onClick={() => handleVerification(code)}
          size={'large'}
          sx={{ marginTop: '40px' }}
          variant="contained"
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}
