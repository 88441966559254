import { Alert, Box, Divider, Paper, Typography } from '@mui/material';

import { ProfileData } from 'api/profile/profileAPI';
import { AccountInfoEmail, AccountInfoFacebookConnect, AccountInfoPassword } from '.';

interface Props {
  profileData: ProfileData;
  reload: () => void;
}

export default function AccountInfo({ profileData, reload }: Props) {
  return (
    <div>
      <Typography variant="h5" marginBottom="20px">
        Account Info
      </Typography>

      {!profileData.verified && (
        <Alert severity="error" sx={{ marginBottom: '12px' }}>
          Your email address have not been verified.
        </Alert>
      )}

      <Paper sx={{ padding: '40px' }}>
        <Box display="flex" justifyContent="space-between">
          <Box width={530}>
            <AccountInfoEmail
              email={profileData?.email}
              verify={!!profileData?.verified}
              reload={reload}
            />
          </Box>
          <Box width={530}>
            <AccountInfoPassword email={profileData?.email} />
          </Box>
        </Box>
        <Divider sx={{ marginTop: '40px' }} />
        <Box display="flex" mt="20px" justifyContent="flex-end">
          <AccountInfoFacebookConnect connected={profileData?.facebook_connect} reload={reload} />
        </Box>
      </Paper>
    </div>
  );
}
