import { isNumber } from 'lodash-es';
import { useMemo } from 'react';

import { useGameEventBadgeListAPI, usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import GameListLargestCard from 'components/GameList/GameListLargestCard';
import { GameStatusType } from 'types/GameCommonTypes';
import { getConvertTimeText } from 'utils/common';
import { useLog } from 'utils/userLog';

interface GameCardListMediumContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  useSlider: boolean;
  includeSurveyGame?: boolean;
  likeButton?: boolean;
  navigationPositionTop?: number;
  isLauncherImage?: boolean;
}

export default function GameListLargestContainer({
  cardtype,
  count = 1,
  hoverVideoPlay,
  showViewMoreCard,
  useSlider,
}: GameCardListMediumContainerProps) {
  const { data: timeData } = useGetNotificationCountAPI();
  const { isLoading, isValidating, data, error } = usePrivateGameListAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const privateGameData = data?.data?.sort((a, b) =>
    isNumber(a?.order_seq) && isNumber(b?.order_seq) ? a?.order_seq - b?.order_seq : 1
  );
  const { gxcTag } = useLog();

  const isDataNotExist =
    (Array.isArray(privateGameData) &&
      privateGameData.length === 0 &&
      !isLoading &&
      !isValidating) ||
    error;

  const transactionTime = timeData?.transaction_time;

  const gameList = useMemo(
    () =>
      privateGameData && Array.isArray(privateGameData) && privateGameData.length > 0
        ? privateGameData
            .map((data) => {
              const timeText = getConvertTimeText(data.started_at, transactionTime);

              return {
                ...data,
                state: cardtype,
                hoverVideoPlay: hoverVideoPlay,
                isPrivateShow: !!timeText,
                remainingText: timeText,
              };
            })
            .slice(0, useSlider ? privateGameData.length : count)
        : [],
    [privateGameData, cardtype, hoverVideoPlay, transactionTime, count, useSlider]
  );

  return isDataNotExist ? null : (
    <GameListLargestCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={false}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      onClickCard={(gameId) => {
        gxcTag('click', {
          area: 'private_test',
          ui: 'play_now',
          parameters: { game_id: gameId + '' },
        });
      }}
    />
  );
}
