import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Carousel, GameCardMedium } from 'elements';
import { GameCardProps } from 'types/GameCardTypes';

interface GameListSmallSlideProps {
  sliderData?: GameCardProps[];
  name: string;
  navigationPositionTop?: number;
  isLauncherImage?: boolean;
  onClickCard?: (gameId?: number) => void;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  fillHeight?: boolean;
}

export default function GameListMediumSlide({
  sliderData,
  name,
  navigationPositionTop,
  isLauncherImage,
  onClickCard,
  onClickLeftArrow,
  onClickRightArrow,
  onLikeClicked,
  fillHeight,
}: GameListSmallSlideProps) {
  //slider
  const swiperParams: SwiperOptions = {
    pagination: false,
    spaceBetween: 20,
    slidesPerGroup: 1,
    slidesPerView: 4,
    allowTouchMove: false,
    simulateTouch: true,
    touchRatio: 0,
  };

  return (
    <Carousel
      {...swiperParams}
      hasNavigation
      width={1200}
      name={name}
      navigationPositionTop={navigationPositionTop}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClickLeftArrow={onClickLeftArrow}
      onClickRightArrow={onClickRightArrow}
    >
      {sliderData?.map((content) => (
        <SwiperSlide
          key={content.id}
          style={{ width: '1200px', display: 'flex', paddingTop: '2px', paddingBottom: '16px' }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          <GameCardMedium
            {...content}
            isLauncherImage={isLauncherImage}
            onClickCard={onClickCard}
            onLikeClicked={onLikeClicked}
            fillHeight={fillHeight}
          />
        </SwiperSlide>
      ))}
    </Carousel>
  );
}
