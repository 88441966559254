import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { LinkButton } from 'elements';
import { groundDiscordChannel } from 'utils/common';
import eventTracker from 'utils/eventTracker';

import character from './discord_character.svg';
import logo from './discord_logo.svg';

type BannerType = 'gameinfo' | 'comment' | 'survey';

interface Props {
  discord_channel: string | null | undefined;
  type?: BannerType;
  onClick?: () => void;
}

export default function DiscordBanner({ discord_channel, type, onClick }: Props) {
  const { id = '' } = useParams<{ id: string }>();

  const handleClick = () => {
    onClick?.();
    eventTracker(`gamedetail_tab_info_join_discord`, { event_label: id });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      width={'100%'}
      height={type === 'comment' ? '74px' : '120px'}
      px="60px"
      sx={{
        background: 'linear-gradient(113.4deg, #556ADD 21.19%, #7DDCC5 112.12%)',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      <img
        src={character}
        width={type === 'comment' ? 85 : 154}
        alt="discord character"
        style={{
          position: 'absolute',
          top: type === 'comment' ? 28 : 36,
          left: type === 'comment' ? 745 : 605,
        }}
      />
      <Box display="flex" alignItems="center">
        <Box minWidth="222px">
          <img
            src={logo}
            width={type === 'comment' ? 128 : 160}
            alt="discord logo"
            style={{ verticalAlign: 'middle' }}
          />
        </Box>
        <Box width={type === 'comment' ? '100%' : '283px'}>
          <Typography color="#FFF" variant="subtitle1">
            Join Our Gaming Community
          </Typography>
          <Typography color="text.primary" variant="body2" mt={type === 'comment' ? '4px' : '8px'}>
            Join the G.Round Discord now to learn more about our games!
          </Typography>
        </Box>
      </Box>

      <LinkButton
        color="light"
        newWindow
        onClick={handleClick}
        sx={{ borderColor: '#FFF', color: '#FFF' }}
        to={discord_channel || groundDiscordChannel}
        variant="outlined"
      >
        Go to Discord channel
      </LinkButton>
    </Box>
  );
}
