import { FormBaseProps, PhoneNumberInput, PhoneNumberInputProps } from '@ground/ui';
import { Box, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput, { Country } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

interface FormPhoneNumberInputProps extends PhoneNumberInputProps {
  label?: string;
  defaultCountry?: string;
}

/** 전화번호 입력 폼 */
export default function FormPhoneNumberInput({
  label,
  name,
  rules = {},
  defaultCountry,
  defaultValue,
  ...props
}: FormPhoneNumberInputProps & FormBaseProps) {
  const { control } = useFormContext();

  return (
    <div>
      {/* 상단 label 영역 */}
      {label && (
        <Typography
          component="label"
          color="text.secondary"
          variant={props.size === 'large' ? 'subtitle2' : 'body1'}
          marginBottom="8px"
        >
          {label}
        </Typography>
      )}

      {/* input 영역 */}
      <Controller
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Box marginTop="8px">
            <PhoneInput
              {...field}
              defaultCountry={defaultCountry as Country}
              inputComponent={PhoneNumberInput}
              international
              numberInputProps={{ error: !!error, ...props }}
            />
            {error?.message && (
              <Typography color="error.main" mt="8px" variant="body3">
                {error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </div>
  );
}
