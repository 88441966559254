import useSWRFetch, { useSWRInfiniteFetch } from 'api/useSWRFetch';
import {
  AnnouncementDetailType,
  AnnouncementListType,
  BlogCategoryListType,
} from 'types/AnnouncementTypes';

type blogType = 'b2c' | 'bcb';

export function useBlogCategoryListApi(type: blogType) {
  const url = `code/detail/option?code_group=announce_type&code_option=${type}`;
  return useSWRFetch<BlogCategoryListType>({
    key: url,
  });
}

export function useBlogListAPIInfinite(
  blog_platform: string,
  pageSize: number,
  searchKeyword?: string,
  searchAnnounceType?: 'all' | string,
  search_tag?: string
) {
  return useSWRInfiniteFetch<AnnouncementListType>({
    getKey: (pageIndex, previousPageData) => {
      // 리스트의 끝이면 return null
      if (pageIndex === previousPageData?.pagination?.total_pages) {
        return null;
      }

      let url = `/announcement/list?blog_platform=${blog_platform}&current_page=${
        pageIndex + 1
      }&pageSize=${pageSize}`;

      if (searchAnnounceType) {
        url += `&search_announce_type=${searchAnnounceType}`;
      }

      if (searchKeyword) {
        url += `&search_title=${searchKeyword}`;
      }

      if (search_tag) {
        url += `&search_tag=${search_tag}`;
      }

      return url;
    },
  });
}

// 하이라이트 배너
export function useBlogListAPI(
  blog_platform: string,
  searchTerm?: string,
  searchAnnounceType?: string,
  isSearchHighlight?: boolean
) {
  let url =
    isSearchHighlight ||
    (typeof searchAnnounceType === 'string' && searchAnnounceType !== 'all') ||
    searchTerm
      ? `/announcement/list?blog_platform=${blog_platform}&`
      : `/announcement/list?blog_platform=${blog_platform}`;

  if (isSearchHighlight) {
    url += 'search_highlight=true&order_type=highlight_rgst_asc';
  }

  if (typeof searchAnnounceType === 'string' && searchAnnounceType !== 'all') {
    url += isSearchHighlight
      ? `&search_announce_type=${searchAnnounceType}`
      : `search_announce_type=${searchAnnounceType}`;
  }

  if (searchTerm) {
    url +=
      (typeof searchAnnounceType === 'string' && searchAnnounceType !== 'all') || isSearchHighlight
        ? `&search_title=${searchTerm}`
        : `search_title=${searchTerm}`;
  }

  return useSWRFetch<AnnouncementListType>({
    key: url,
  });
}

export function useBlogDetailAPI(announcementId: string) {
  const url = `announcement/${announcementId}/detail`;

  return useSWRFetch<AnnouncementDetailType>({ key: url, useToken: true });
}
