import { AutoResizeImage } from '@ground/tools';
import { Avatar } from '@ground/ui';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useHover } from 'usehooks-ts';

import { GHashLink } from 'elements';

interface Props {
  profileUrl: string;
  reviewerName: string;
  reviewText: string;
  gameTitle: string;
  gameThumbnailUrl: string;
  reviewPoint: number;
  onClick?: () => void;
  to: string;
}

const PickedReviewCard = ({
  profileUrl,
  reviewPoint,
  reviewText,
  reviewerName,
  gameThumbnailUrl,
  gameTitle,
  onClick,
  to,
}: Props) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);
  const [textWidth, setTextWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (hoverRef?.current?.offsetWidth) {
      setTextWidth(hoverRef?.current?.offsetWidth - 150);
    }
  }, []);

  return (
    <GHashLink to={to} onClick={onClick}>
      <Stack
        sx={(theme) => ({
          padding: '20px',
          backgroundColor: theme.palette.gray[isHover ? 70 : 80],
          borderRadius: '5px',
          boxShadow: isHover ? '0px 10px 10px rgba(0, 0, 0, 0.50)' : undefined,
          gap: '20px',
        })}
        ref={hoverRef}
      >
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Avatar src={profileUrl} size="small" />
          <Typography variant="subtitle1" color={isHover ? 'white' : 'text.primary'}>
            {reviewerName}
          </Typography>
        </Box>
        <Box
          sx={{
            height: '168px',
          }}
        >
          <Typography
            color={isHover ? 'white' : 'text.secondary'}
            variant="body2"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 7,
            }}
          >
            {reviewText}
          </Typography>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <AutoResizeImage
              style={{ borderRadius: '5px' }}
              src={gameThumbnailUrl}
              resizeWidth={40}
              resizeHeight={40}
              format="webp"
              alt="game thumbnail"
            />
            <Typography
              variant="body3"
              color={isHover ? 'white' : 'text.primary'}
              sx={{
                width: textWidth,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {gameTitle}
            </Typography>
          </Box>
          {/* <Typography variant="button2" color={isHover ? 'white' : 'text.primary'}>
            +{reviewPoint} G.P
          </Typography> */}
        </Box>
      </Stack>
    </GHashLink>
  );
};

export default PickedReviewCard;
