import { FormInput } from '@ground/ui';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { get, useFormContext } from 'react-hook-form';

import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

interface Props {
  question_context: string;
  disabled?: boolean;
  index: number;
  required: boolean;
}

export default function SupporterApplyQuestionPcInfo({
  question_context,
  disabled = false,
  index,
  required,
}: Props) {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const pcInfo = [
    { label: 'CPU', word: 'Processor:', split: ',', value: '', questionNumber: 15 },
    { label: 'Memory', word: 'Available OS Memory:', split: 'RAM', value: '', questionNumber: 16 },
    { label: 'GPU', word: 'Card name: ', split: 'Manufacturer', value: '', questionNumber: 17 },
    { label: 'VRAM', word: 'Display Memory:', split: 'MB', value: '', questionNumber: 18 },
  ];

  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0] || null; // 선택한 파일
      pcInfo.map(async (item, index) => {
        extractContentFromWordToCharacter(file, item.word, item.split).then((result) => {
          setValue(`supporter_question_vos.${item.questionNumber}.question_context`, result);
        });
      });
    }
  };

  function readFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result); // 파일 내용을 반환
      };
      reader.onerror = reject;
      reader.readAsText(file); // 파일을 읽음
    });
  }

  function extractContentFromWordToCharacter(file: File, word: string, character: string) {
    return readFile(file).then((content: any) => {
      const startIndex = content.indexOf(word); // 특정 단어의 시작 인덱스를 구함

      if (startIndex === -1) {
        throw new Error('It does not exist in that tag file.');
      }

      const endIndex = content.indexOf(character, startIndex + word.length); // 특정 문자의 인덱스를 구함

      if (endIndex === -1) {
        throw new Error('It does not exist in that tag file.');
      }

      if (word === 'Card name: ' || word === 'Display Memory:') {
        const words = content.split('\n');
        const extractedWords = words.filter((w: string) => w.includes(word));
        return extractedWords.join(',').replaceAll(word, '');
      }

      const extractedContent = content.substring(startIndex, endIndex); // 시작 인덱스부터 종료 인덱스까지의 내용을 추출
      return extractedContent.replaceAll(word, '');
    });
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : undefined }}>
      <Box sx={{ display: 'none' }}>
        <FormInput
          name={`${name}.question_context`}
          rules={{ required: index !== 14 ? required : false }}
          placeholder="Enter your answer"
          sx={{ width: '0px', height: '0px', opacity: 0, margin: 0, padding: 0 }}
          withBackground
        />
      </Box>
      <SupporterQuestionTitle
        question={question_context}
        error={!!error}
        required={required}
        subtitle="Required for PC testable users"
      />

      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box display="flex" gap="20px" alignItems={'center'}>
          <Button component="label" variant="ghost" size="small">
            <Typography color="text.primary" variant="button2">
              Upload file
            </Typography>
            <input
              type="file"
              onChange={handleUpload}
              onClick={handleButtonClick}
              hidden
              ref={fileInputRef}
              name={`${name}.question_answer`}
            />
          </Button>
          <Box>
            <Link
              href="https://support.microsoft.com/en-us/windows/open-and-run-dxdiag-exe-dad7792c-2ad5-f6cd-5a37-bf92228dfd85"
              target="_blank"
              color="text.private"
            >
              Guide
            </Link>
          </Box>
        </Box>

        <Box m="8px 0" p="10px 16px" sx={{ backgroundColor: 'gray.60' }}>
          {pcInfo.map((item) => (
            <Box display={'flex'} gap="3px" mb="4px">
              <Typography color="text.secondary2" variant="body3">
                {item.label} :
              </Typography>

              <Typography color="text.primary" variant="body3">
                {getValues(`supporter_question_vos.${item.questionNumber}.question_context`)}
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography color="text.secondary2" variant="body3" width="776px">
          *To obtain this file, you'll need to run a Diagnostics Tool on your device, which will
          generate a test result. This file should then be uploaded here. Please refer to the guide
          to learn how to get the .txt file.
        </Typography>
      </Paper>
    </Box>
  );
}
