import { FormInput } from '@ground/ui';
import { Box } from '@mui/material';
import { SupporterFormProps } from 'api/supporter/useSuppoterTypes';
import { get, useFormContext } from 'react-hook-form';
import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

export default function SupporterApplyQuestionInputField({
  question_context,
  disabled = false,
  index,
  required,
  required_option,
}: SupporterFormProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);
  const subtitle = required_option === 'Mobile' ? 'モバイルテスト可能なユーザーに必須' : '';

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : undefined }}>
      <Box>
        <SupporterQuestionTitle
          question={question_context}
          error={!!error}
          required={required}
          subtitle={subtitle}
        />
      </Box>

      <FormInput
        name={`${name}.question_context`}
        rules={{ required: required }}
        placeholder="回答を入力してください"
        fullWidth
        withBackground
      />
    </Box>
  );
}
