import { Link, Typography } from '@mui/material';

export const featureClips = [
  {
    game_id: 0,
    id: 0,
    link: 'https://youtu.be/sGQtPrwaCoo',
    thumbnail_url: '/images/clips/featured_0_perfectus.png',
    title: 'G.Round NovaBeat Wireless Headphone VS React Headphone (Review & Comparison)',
    created_at: 'Sep 19, 2023',
    description:
      'Check out the G.Round NovaBeat and REACT Headphones in this review by one of our very own community members PERFECTUS!',
    additional: (
      <Typography variant="body2" color="text.secondary" m={'20px 0'}>
        Cop these headphones for yourself by clicking the links down below:
        <br />
        <br />
        G.Round NovaBeat Wireless Headset
        <br />
        <Link
          href="https://gameround.co/store/72d76629-6d47-4c69-b52b-2b46231dedaf"
          target="_blank"
        >
          https://gameround.co/store/72d76629-6d47-4c69-b52b-2b46231dedaf
        </Link>
        <br />
        <br />
        G.Round React Headphones
        <br />
        <Link
          href="https://gameround.co/store/ca9579d1-686d-4218-8ade-538a70d6f4b4"
          target="_blank"
        >
          https://gameround.co/store/ca9579d1-686d-4218-8ade-538a70d6f4b4
        </Link>
        <br />
        <br />
        G.Point Store
        <br />
        <Link href="https://gameround.co/gpointstore" target="_blank">
          https://gameround.co/gpointstore
        </Link>
      </Typography>
    ),
  },
  {
    game_id: 1,
    id: 1,
    link: 'https://youtu.be/lp3Obduo0a4',
    thumbnail_url: '/images/clips/featured_1_unboxing.png',
    title: 'G.Round Product Unboxing | New Best Gamer Gear!?',
    created_at: 'Jan 27, 2023',
    description:
      'Check out our unboxing video as our stream host Camms showcases the epic loot you can get by taking part in G.Round playtests!',
  },
  {
    game_id: 2,
    id: 2,
    link: 'https://youtu.be/jMgy1tYuBpw',
    thumbnail_url: '/images/clips/featured_2_promo.png',
    title: 'What is G.Round?',
    created_at: 'Jul 14, 2023',
    description:
      'Find out what G.Round is all about! From what we do for players and developers to the amazing loot you can earn from being a part of the community.',
  },
  {
    game_id: 3,
    id: 3,
    link: 'https://youtu.be/vC3Btkm787k',
    thumbnail_url: '/images/clips/featured_3_madshot.png',
    title: 'Madshot: John Wick vs Cthulu',
    created_at: 'May 18, 2023',
    description:
      'Explore the evolution of Madshot and see how the Lovecraftian John Wick has changed over the course of playtesting on G.Round!',
  },
];

export const storeClips = [
  {
    game_id: 0,
    id: 4,
    link: 'https://youtu.be/fNYuojcK_Eo',
    thumbnail_url: '/images/clips/store_4_hell_throne.webp',
    created_at: 'Dec 18, 2023',
    game_title: 'Hell Throne',
    studio_name: 'BoomBit',
    title: 'Get Hell Throne at the G.Point Store!',
    gameLink: '/detail/419/info',
    storeLink: '/store/ba554283-ce6e-4368-9baf-47e54b28ab30',
    productId: 'ba554283-ce6e-4368-9baf-47e54b28ab30',
    gameId: 419,
  },
  {
    game_id: 1,
    id: 3,
    link: 'https://youtu.be/E887NTEaAEQ',
    thumbnail_url: '/images/clips/store_3_relic_hunters_legend.webp',
    created_at: 'Sep 26, 2023',
    game_title: 'Relic Hunters Legend',
    studio_name: 'Rogue Snail',
    title: 'Get Relic Hunters Legend at the G.Point Store!',
    gameLink: '/detail/26/info',
    storeLink: '/store/2a141b43-6ae7-4925-8b94-38438735dd15',
    productId: '2a141b43-6ae7-4925-8b94-38438735dd15',
    gameId: 26,
  },
  {
    game_id: 2,
    id: 2,
    link: 'https://youtu.be/m6Qhl-jWA3c',
    thumbnail_url: '/images/clips/store_2_stray.webp',
    created_at: 'May 3, 2023',
    game_title: 'Stray Blade',
    studio_name: 'Point Blank Games',
    title: 'Get Stray Blade at the G.Point Store!',
    gameLink: '/detail/104/info',
    storeLink: '/store/d76ae9b7-66a5-4a26-8200-55ff05a3fa69',
    productId: 'd76ae9b7-66a5-4a26-8200-55ff05a3fa69',
    gameId: 104,
  },
  {
    game_id: 3,
    id: 1,
    link: 'https://youtu.be/cTdlXuyc4co',
    thumbnail_url: '/images/clips/store_1_terracotta.webp',
    created_at: 'Jun 15, 2023',
    game_title: 'Terracotta',
    studio_name: 'Appnormals Team',
    title: 'Get Terracotta at the G.Point Store!',
    gameLink: '/detail/126/info',
    storeLink: '/store/12d168ae-d6ed-4a0c-8a6e-677f5dc6a33e',
    productId: '12d168ae-d6ed-4a0c-8a6e-677f5dc6a33e',
    gameId: 126,
  },
];
