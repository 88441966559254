import { FormDropdown, FormInput } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { IStoreMerchandise } from 'api/gstore';
import { FormPhoneNumberInput } from 'components/form';
import { iRootState } from 'store';
import { emailPattern } from 'utils/common';

const InputBox = styled(Box)({
  marginTop: 32,

  '&:nth-of-type(1)': {
    marginTop: 0,
  },
});

interface IProps {
  countries: IStoreMerchandise.GetShippingCountryList.ShippingCountry[];
}

export default function OrderShippingInfoForm({ countries }: IProps) {
  const { trigger, watch } = useFormContext();
  const { country_code } = useSelector((state: iRootState) => state.login);

  const selectedCountryCode = watch('ship_to_country_code');

  // 초기 값 및 선택 국가 변경했을 때 에러 표시를 위해 mount 시 강제로 validation trigger 발동 시킴
  useEffect(() => {
    trigger();
  }, [selectedCountryCode, trigger]);

  const findCountry = countries?.find((country) => country.country_code === selectedCountryCode);

  const countryList = countries
    ? countries.map((country) => ({
        name: country.country_name,
        value: country.country_code,
      }))
    : [];

  const rules: RegisterOptions =
    findCountry?.country_code === 'CN' || findCountry?.country_code === 'HK'
      ? {
          required: 'Name must be at least 1-20 characters',
          maxLength: { value: 20, message: 'Name must be at least 1-20 characters' },
          pattern: {
            value: /^[\u4e00-\u9fa5]+$/g,
            message: 'Please enter your Chinese name',
          },
        }
      : {
          required: 'Name must be at least 1-20 characters',
          maxLength: { value: 20, message: 'Name must be at least 1-20 characters' },
          pattern: { value: /^[A-Za-z\s]+$/g, message: 'Please enter only letters and spaces' },
        };

  return (
    <Box>
      <InputBox>
        <FormInput
          name="ship_to_name"
          label="Recipient’s Name"
          placeholder="Your Name"
          required
          rules={rules}
          inputProps={{ maxLength: 20 }}
          fullWidth
          withBackground
        />
      </InputBox>
      <InputBox>
        <FormInput
          name="ship_to_email"
          type="email"
          label="Email*"
          placeholder="Your Email"
          required
          rules={{
            required: 'Please enter a valid email.',
            pattern: { value: emailPattern, message: 'Please enter a valid email.' },
          }}
          fullWidth
          withBackground
          readOnly
        />
        <Typography variant="body3" color="text.secondary2" mt="8px">
          Order details will be sent to the email address connected to your G.Round account.
        </Typography>
      </InputBox>
      <InputBox>
        <FormPhoneNumberInput
          name="ship_to_phone"
          label="Phone Number*"
          rules={{
            required: 'Please enter a valid phone number.',
            maxLength: { value: 20, message: 'max 20 characters' },
          }}
          defaultCountry={country_code ?? undefined}
          placeholder="Your Phone Number"
          required
          withBackground
        />
        <Typography variant="body3" color="text.secondary2" mt="8px">
          Contact information is very important when shipping, so please enter it correctly
        </Typography>
      </InputBox>
      <InputBox>
        <Typography variant="body1" color="text.secondary" mb="8px">
          Country*
        </Typography>
      </InputBox>
      <FormDropdown
        name="ship_to_country_code"
        options={countryList}
        placeholder="Select your Country"
        rules={{
          required: 'Please enter a valid country.',
          // 선택된 국가가 리스트에 없는 경우 에러 표시
          validate: (selCountry) => !!countryList?.find((country) => country.value === selCountry),
        }}
        withBackground
      />
      <Typography variant="body3" color="text.secondary2" mt="8px">
        You can only select countries that can be delivered.
      </Typography>
      <InputBox>
        <FormInput
          name="ship_to_state"
          label="Province/State*"
          placeholder="Your Province/State"
          required
          rules={{ required: 'Please enter a valid province/state.' }}
          fullWidth
          withBackground
        />
      </InputBox>
      <InputBox>
        <FormInput
          name="ship_to_city"
          label="City*"
          placeholder="Your City"
          required
          rules={{ required: 'Please enter a valid city.' }}
          fullWidth
          withBackground
        />
      </InputBox>
      <InputBox>
        <FormInput
          name="ship_to_address1"
          label="Address Line 1*"
          placeholder="Your Address Line 1"
          required
          rules={{
            required: 'Please enter a valid address.',
            maxLength: { value: 200, message: 'max 200 characters' },
          }}
          inputProps={{ maxLength: 200 }}
          fullWidth
          withBackground
        />
      </InputBox>
      <InputBox>
        <FormInput
          name="ship_to_address2"
          label="Address Line 2"
          placeholder="Your Address Line 2"
          fullWidth
          withBackground
        />
      </InputBox>
      <InputBox>
        <FormInput
          name="ship_to_zipcode"
          label="Zip Code*"
          placeholder="Your Zip Code"
          required
          rules={{
            required: 'Please enter a valid zip code.',
            maxLength: { value: 50, message: 'max 50 characters' },
          }}
          inputProps={{ maxLength: 50 }}
          fullWidth
          withBackground
        />
      </InputBox>
      {findCountry?.id_card_item && (
        <InputBox>
          <FormInput
            name="id_card_no"
            label={`${findCountry.id_card_title}*`}
            placeholder={`Your ${findCountry.id_card_title}`}
            required={findCountry.id_card_required}
            rules={{
              required: {
                value: findCountry.id_card_required,
                message: `Please enter a valid ${findCountry.id_card_title}.`,
              },
            }}
            fullWidth
            withBackground
          />
        </InputBox>
      )}
    </Box>
  );
}
