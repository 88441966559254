import { numberWithCommas } from '@ground/tools';
import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import eventTracker from 'utils/eventTracker';
import AddGpointButton from 'views/gstore/payment/AddGpointButton';

interface Props {
  text: string | number;
  subtitle: string;
  tab?: string;
  hasPurchaseBtn?: boolean;
  onComplete?: () => void;
}

export default function SummaryItem({ text, subtitle, tab, hasPurchaseBtn, onComplete }: Props) {
  const innerItem = () => (
    <Stack minWidth="180px" gap="12px">
      <Typography variant="body2" color="text.secondary">
        {subtitle}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Typography variant="h3" color="text.primary">
          {typeof text === 'number' ? numberWithCommas(text) : text}
        </Typography>

        {hasPurchaseBtn && <AddGpointButton onComplete={onComplete} />}
      </Box>
    </Stack>
  );

  const handleClick = () => {
    eventTracker(`profile_summary${tab ? '_' + tab : ''}`);
  };

  return tab ? (
    <Link to={`/profile/${tab}`} onClick={handleClick}>
      {innerItem()}
    </Link>
  ) : (
    innerItem()
  );
}
