import { useNavigate } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';

import { DiscordBanner } from 'components';
import { Footer } from 'views/mobile';

import backgroundImage from 'images/mobile/not-found-background.png';
import { useLog } from 'utils/userLog';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  padding: '160px 28px 40px',
});

export default function NotFound() {
  const navigate = useNavigate();
  const { gxcTag } = useLog();

  return (
    <>
      <Container>
        <img
          src={backgroundImage}
          alt="not found"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'brightness(0.25)',
          }}
        />

        <Typography variant="h3" mb="20px">
          Error 404
        </Typography>

        <Typography variant="subtitle1" color="text.secondary" mb="32px" textAlign="center">
          Oops, The page you were looking for doesn't exist.
        </Typography>

        <Typography variant="small2" color="text.secondary" textAlign="center">
          The page does not exist. Before the door closes, <br />
          Please check the URL or{' '}
          <Typography
            color="primary"
            variant="small2"
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              gxcTag('click', { ui: 'go_back' });
              navigate(-1);
            }}
            component="a"
          >
            go back
          </Typography>{' '}
          to check the direction.
        </Typography>

        <Box sx={{ mt: '155px' }}>
          <DiscordBanner size="mobile" />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
