import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  defaultValue?: string | number | readonly string[] | undefined;
}

export default function FormHiddenInput({ name, defaultValue }: Props) {
  const { register } = useFormContext();

  return <input type="hidden" {...register(name)} defaultValue={defaultValue} />;
}
