import useFetch from 'api/useFetch';
import useSWRFetch from 'api/useSWRFetch';
import type { UniversityUserType } from 'types/UniversityType';

export interface UniversityInfo {
  university_id: number;
  university_name: string;
  country_code: string;
  created_at: string;
  created_by: number;
  updated_by: number;
  sign_up_url: string;
}

/** 학교 정보 조회 */
export function useGetUniversityInfoAPI(universityKey?: string | null) {
  return useSWRFetch<UniversityInfo>({
    key: universityKey ? `university/url/key?signUpUrl=${universityKey}` : null,
  });
}

export interface UniversityMemberInfo {
  university_id: number; // 학교 ID
  university_name: string; // 학교 명
  educator?: {
    educator_id: number; // 교수 ID
    educator_fullname: string; // 교수 fullname
  };
  student?: {
    student_id: number; // 학생 ID
    student_number: string; // 학번
    student_fullname: string; // 학생 fullname
    student_majors: string[]; // 학생의 전공 리스트
  };
  major_list: {
    major_id: number; // 전공 ID
    major_name: string; // 전공 명
  }[];
  class_list: {
    class_id: number; // 수업 ID
    class_name: string; // 수업 명
  }[];
}

/** 학생, 교수의 학교 정보 */
export function useGetUniversityMemberInfoAPI(universityType?: UniversityUserType) {
  return useSWRFetch<UniversityMemberInfo>({
    key: universityType ? `university/${universityType}/info` : null,
    useToken: true,
  });
}

export interface ClassInfo {
  student_cnt: number;
  review_cnt: number;
  survey_cnt: number;
  educator_id: number; // 교수 ID
  educator_fullname: string; // 교수 명
  class_id: number; // 수업 id
  class_name: string; // 수업 명
}

/** 해당 학교의 클래스 리스트 */
export function useGetClassListAPI(universityId?: number) {
  return useSWRFetch<ClassInfo[]>({
    key: universityId ? `university/class/list/${universityId}` : null,
    useToken: true,
  });
}

export interface EducatorInfoRequest {
  fullName: string;
  updateMajorList?: { major_name: string; major_id: number }[];
  insertMajorList?: string[];
  updateClassList?: { class_name: string; class_id: number }[];
  deleteClassList?: number[];
  insertClassList?: string[];
}

/** 교수 정보 수정 */
export function usePatchEducatorInfoAPI() {
  return useFetch<{}, EducatorInfoRequest>('university/educator/update', { method: 'PATCH' }, true);
}

export interface StudentInfoRequest {
  universityId: number;
  fullName: string;
  studentNumber: string;
  majorList: string[];
  classList: number[];
}

/** 학생 정보 등록 */
export function usePostStudentInfoAPI() {
  return useFetch<{}, EducatorInfoRequest>('university/student/register', { method: 'POST' }, true);
}

/** 학생 정보 수정 */
export function usePatchStudentInfoAPI() {
  return useFetch<{}, Omit<EducatorInfoRequest, 'universityId'>>(
    'university/student/update',
    { method: 'PATCH' },
    true
  );
}
