import { Components, Theme } from '@mui/material';

const InputBaseTheme: Components<Theme> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        margin: '8px 0 0',
        ...theme.typography.body3,
      }),
      disabled: ({ theme }) => ({
        color: theme.palette.text.disabled,
      }),
    },
  },
};

export default InputBaseTheme;
