import { FormInput } from '@ground/ui';
import { useMemo } from 'react';
import { useFormState } from 'react-hook-form';

import { SurveyQuestionType } from 'types/FormDataType';
import QuestionAttaches from './QuestionAttaches';
import QuestionTitle from './QuestionTitle';

export default function QuestionTextField({
  id,
  question,
  detail,
  question_type,
  question_attach_type,
  survey_question_attaches,
  disabled = false,
  index,
}: SurveyQuestionType & { disabled?: boolean; index: number }) {
  const { errors } = useFormState();

  const name = useMemo(() => `${question_type.toLowerCase()}-${id}`, [question_type, id]);

  return (
    <div>
      <QuestionTitle
        order={index + 1}
        question={question}
        detail={detail}
        error={!!errors?.[name]}
      />
      <QuestionAttaches type={question_attach_type} attaches={survey_question_attaches} />
      <FormInput
        disabled={disabled}
        name={name}
        inputProps={{ maxLength: 8000 }}
        placeholder="Leave your feedback here (up to 8,000 characters)"
        rules={{
          required: { value: true, message: 'answer is required.' },
          maxLength: { value: 8000, message: 'max 8,000 characters.' },
        }}
        fullWidth
        withBackground
        multiline
        minRows={6}
      />
    </div>
  );
}
