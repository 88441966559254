import { baseUrls } from 'api/baseUrls';
import useFetch from 'api/useFetch';
import useSWRFetch, { useSWRInfiniteFetch } from 'api/useSWRFetch';
import { useAxiosWithAuth } from 'hooks';
import {
  IGPointHistoryData,
  IGStoreOrderFormData,
  IGStorePromotionCountry,
} from 'types/GStoreTypes';

// physical digital 공통으로 사용하는 타입이나 변수들을 하나의 파일로 분리해야할 듯
export const storeApiUrl = 'https://api-shop.gameround.co';

export interface IGStoreSavedAddress {
  user_id: number; // 사용자 아이디
  email: string; // 배송받는 사람 이메일
  name: string; // 배송받는 사람 이름
  phone: string; // 배송받는 사람 전화번호
  international_no: string; // 배송 받는 사람 국가번호
  address1: string; // 배송지 주소 1
  address2: string; // 배송지 주소 2
  city: string; // 배송지 도시명
  states: string; // 배송지 State or Area
  zipcode: string; // 우편번호
  country_code: string; // 배송국가
  country_name: string; // 배송국가명
  id_card_item: number; // 국가 추가 입력 아이템 아이디
  id_card_title: string; // 국가 추가 입력 아이템 타이틀
  id_card_no: string; // 국가 추가 입력값
  id_card_required: boolean; // 국가 추가 정보 입력 필수여부 (true: 필수, false: 옵션)
}

export interface IGStoreOrderStatusCount {
  status: string;
  count: number;
}

export interface IGstoreCountStatus {
  type_cnt: number;
  order_type: 'M' | 'D';
}

export enum GStoreOrderStatusEnum {
  ORDERED = 'ORDERED', // 주문완료
  CONFIRMED = 'CONFIRMED', // 주문확인
  PACKING = 'PACKING', // 배송준비중
  IN_TRANSIT = 'IN_TRANSIT', // 배송중
  DELIVERED = 'DELIVERED', // 배송완료
  CANCELED_ORDER = 'CANCELED_ORDER', // 주문취소
  CANCELED_PAYMENT = 'CANCELED_PAYMENT', // 환불완료
}

export enum GStoreDigitalProductOrderStatusEnum {
  ORDERED_REQUESTED = '10000', // 주문완료
  ORDERED = '10010', // 주문완료
  CONFIRMED = '10011', // 주문확인
  PACKING = '10020', // 배송준비중
  IN_TRANSIT = '10021', // 배송중
  DELIVERED = '10100', // 배송완료
  CANCELED_ORDER = '99910', // 주문취소
  CANCELED_PAYMENT = '99920', // 환불완료
  CANCELED_FAILED = '99999', // 환불완료
}

export enum GStoreOrderStatusCodeEnum {
  ORDERED_REQUESTED = 10000, // 주문완료
  ORDERED = 10010, // 주문완료
  CONFIRMED = 10011, // 주문확인
  PACKING = 10020, // 배송준비중
  IN_TRANSIT = 10021, // 배송중
  DELIVERED = 10100, // 배송완료
  CANCELED_ORDER = 99910, // 주문취소
  CANCELED_PAYMENT = 99920, // 환불완료
  CANCELED_FAILED = 99999, // 환불완료
}

export interface IGStoreOrderDetailList {
  order_id: string;
  promotion_id: number;
  promotion_title: string;
  created_at: string;
  product_name: string;
  total_point: number;
  username: string;
  useremail: string;
  tracking_no: string;
  ship_to_country_name: string;
  status: keyof typeof GStoreOrderStatusEnum;
  quantity: number;
  main_media_url: string;
}

export interface IGStoreOrderDetailHistory {
  // 설명이 힘들어서 Sample Data 확인하시고 나중에 따로 설명 드립니다. (아직 정리가 조금 덜 됐어요)
  item: string;
  description: string;
  adjust_way: string;
  created_at: Date;
  note: {
    editor: string;
    edited: string;
    editor_name: string;
    editor_id: number;
  };
}

export interface IGStoreOrderDetail {
  order_id: string; // 주문번호
  promotion_id: number; // 프로모션 아이디
  promotion_title: string; // 프로모션 타이틀
  created_at: Date; // 주문일시
  order_user_id: number; // 사용자 고유번호
  order_user_name: string; // 사용자 이름 (주문당시)
  order_user_email: string; // 사용자 Email (주문당시)
  total_point: number; // 주문 총금액
  total_weight: number; // 주문 총무게
  product: {
    // 주문한 판매 정보
    promotion_id: number; // 프로모션 아이디
    product_id: string; // 판매 아이디
    product_name: string; // 판매 이름
    quantity: number; // 주문 수량
    price: number; // 개당 가격
    main_media_url: string; // 대표 이미지
    countries: IGStorePromotionCountry[];
  };
  payment: {
    // 결제 정보
    payment_method: string; // 결제 방법 (POINT 고정)
    payment_at: Date; // 결제일시
    amount: number; // 결제 금액
  };
  order_ship: {
    // 배송정보
    order_ship_id: string; // 배송 고유번호
    ship_to_email: string; // 수신자 이메일
    ship_to_name: string; // 수신자 이름
    ship_to_phone: string; // 수신자 전화번호
    ship_to_address1: string; // 주소1
    ship_to_address2: string; // 주소2
    ship_to_city: string; // 도시명
    ship_to_state: string; // 주 또는 Area 이름
    ship_to_country_code: string; // 국가 코드
    ship_to_country_name: string; // 국가명
    ship_to_zipcode: string; // 우편번호
    ship_channel: string; // 배송업체 코드
    ship_channel_name: string; // 배송업체명
    tracking_no: string; // 송장번호
    ship_to_international_no: string; // 국제 번호
    id_card_item: number; // 국가 추가 입력 아이템 아이디
    id_card_title: string; // 국가 추가 입력 아이템 타이틀
    id_card_no: string; // 국가 추가 입력값
    id_card_required: boolean; // 국가 추가 정보 입력 필수여부 (true: 필수, false: 옵션)
  };
  histories: IGStoreOrderDetailHistory[];
  status: GStoreOrderStatusEnum; // 주문상태
}

export interface IGStoreDigitalProductOrderDetail {
  order_id: string;
  promotion_id: any;
  promotion_title: string;
  created_at: string;
  order_user_id: number;
  order_user_name: string;
  order_user_email: string;
  total_point: number;
  total_weight: number;
  product: {
    // 주문한 판매 정보
    promotion_id: number; // 프로모션 아이디
    product_id: string; // 판매 아이디
    product_name: string; // 판매 이름
    quantity: number; // 주문 수량
    price: number; // 개당 가격
    main_media_url: string;
    countries: {
      country_code: string; // 국가코드
      country_name: string; // 국가명
      id_card_item: number; // 국가 추가 입력 아이템 아이디
      id_card_title: string; // 국가 추가 입력 아이템 타이틀
      id_card_required: boolean; // 국가 추가 입력 필수 여부 (true : 필수, false : 옵션)
    }[];
  };
  payment: {
    payment_method: string;
    payment_at: string;
    amount: number;
  };
  order_ship: {
    // 배송정보
    order_ship_id: string; // 배송 고유번호
    ship_to_email: string; // 수신자 이메일
    ship_to_name: string; // 수신자 이름
    ship_to_phone: string; // 수신자 전화번호
    ship_to_address1: string; // 주소1
    ship_to_address2: string; // 주소2
    ship_to_city: string; // 도시명
    ship_to_state: string; // 주 또는 Area 이름
    ship_to_country_code: string; // 국가 코드
    ship_to_country_name: string; // 국가명
    ship_to_zipcode: string; // 우편번호
    ship_channel: string; // 배송업체 코드
    ship_channel_name: string; // 배송업체명
    tracking_no: string; // 송장번호
    ship_to_international_no: string; // 국제 번호
    id_card_item: number; // 국가 추가 입력 아이템 아이디
    id_card_title: string; // 국가 추가 입력 아이템 타이틀
    id_card_no: string; // 국가 추가 입력값
    id_card_required: boolean; // 국가 추가 정보 입력 필수여부 (true: 필수, false: 옵션)
  };
  status: string;
  history: {
    order_id: string;
    data: Array<{
      item: string;
      description: string;
      adjust_way: string;
      created_at: string;
      note: {
        editor: string;
        'editor note': string;
        editor_name: string;
        editor_id: number;
      };
    }>;
  };
  shopProductDigitalOption: {
    product_digi_opt_id: number;
    product_id: number;
    digital_goods_EK: string;
    digital_goods_depth_top: string;
    digital_goods_depth_top_EK: string;
    digital_goods_depth_mid: string;
    digital_goods_depth_mid_EK: string;
    digital_goods_depth_bottom: string;
    digital_goods_depth_bottom_EK: string;
    qty: number;
    point_price: number;
    created_at: string;
    created_by: number;
    updated_at: string;
    updated_by: string;
  };
  shopProductDigital: {
    product_id: number;
    digital_goods_type_cd: string;
    language_code: string;
    digital_goods_name: string;
    display_point: string;
    point_no_display: boolean;
    main_media_url: string;
    summary: string;
    digital_goods_note: string;
    description: string;
    sales_status_cd: string;
    created_at: string;
    created_by: number;
    updated_at: string;
    updated_by: string;
  };
  shopOrderDigital: {
    shop_order_digital_id: number;
    order_id: string;
    product_digi_opt_id: number;
    card_pin: string;
  };
  shopOrderHistoryVO: {
    data: {
      adjust_way: string;
      created_at: string;
      description: string;
      item: string;
    }[];
  };
}

export interface GStorePointOrderDetail {
  id: string;
  description: string;
  issue_account_id: number;
  status: string;
  refer_values: any;
  canceled: string;
  point_issue_id: number;
  point_val: number;
  earned_point_val: number;
  purchased_point_val: number;
  event_type: string;
  trx_type: string;
  trx_date_time: number;
  game_id: number;
  point_reward_item_id: number;
  point_reward_id: number;
  product_id: number;
  order_id: number;
  original_trx_id: string;
  canceled_at: string;
  canceled_by: string;
  package_id: number;
  balance: number;
  user_id: number;
  form_id: number;
  created_at: string;
  created_by: string;
}

export type DigitalProductOrderDataList = {
  pagination: {
    total_count: number;
    total_pages: number;
    current_page: number;
    list_count: number;
  };
  list: Array<DigitalProductOrder>;
};

export interface DigitalProductOrder {
  order_id: string;
  promotion_id: null;
  promotion_title: string;
  created_at: Date;
  order_user_id: number;
  order_user_name: string;
  order_user_email: string;
  total_point: number;
  total_weight: number;
  product: null;
  payment: null;
  order_ship: {
    // 배송정보
    order_ship_id: string; // 배송 고유번호
    ship_to_email: string; // 수신자 이메일
    ship_to_name: string; // 수신자 이름
    ship_to_phone: string; // 수신자 전화번호
    ship_to_address1: string; // 주소1
    ship_to_address2: string; // 주소2
    ship_to_city: string; // 도시명
    ship_to_state: string; // 주 또는 Area 이름
    ship_to_country_code: string; // 국가 코드
    ship_to_country_name: string; // 국가명
    ship_to_zipcode: string; // 우편번호
    ship_channel: string; // 배송업체 코드
    ship_channel_name: string; // 배송업체명
    tracking_no: string; // 송장번호
    ship_to_international_no: string; // 국제 번호
    id_card_item: number; // 국가 추가 입력 아이템 아이디
    id_card_title: string; // 국가 추가 입력 아이템 타이틀
    id_card_no: string; // 국가 추가 입력값
    id_card_required: boolean; // 국가 추가 정보 입력 필수여부 (true: 필수, false: 옵션)
  };
  histories: any[];
  status: GStoreDigitalProductOrderStatusEnum;
  transfer_id: null;
  transfer_status: null;
  transfer_at: null;
  history: null;
  shopProductDigitalOption: ShopProductDigitalOption;
  shopProductDigital: ShopProductDigital;
  shopOrderDigital: ShopOrderDigital;
}

export interface ShopOrderDigital {
  shop_order_digital_id: number;
  order_id: string;
  product_digi_opt_id: number;
  order_qty: null;
  card_pin: string;
}

export interface ShopProductDigital {
  product_id: number;
  digital_goods_type_cd: string;
  language_code: string;
  digital_goods_name: string;
  display_point: string;
  point_no_display: boolean;
  main_media_url: string;
  summary: string;
  digital_goods_note: string;
  description: string;
  sales_status_cd: string;
  created_at: string;
  created_by: number;
  updated_at: null;
  updated_by: null;
}

export interface ShopProductDigitalOption {
  product_digi_opt_id: number;
  product_id: number;
  digital_goods_EK: string;
  digital_goods_depth_top: string;
  digital_goods_depth_top_EK: string;
  digital_goods_depth_mid: string;
  digital_goods_depth_mid_EK: string;
  digital_goods_depth_bottom: string;
  digital_goods_depth_bottom_EK: string;
  qty: number;
  point_price: number;
  created_at: string;
  created_by: number;
  updated_at: null;
  updated_by: null;
}

// 주문 취소 response
export interface CancelOrderResponseData {
  order_id: string;
  result: keyof typeof GStoreOrderStatusEnum;
  refund: boolean;
}

export function useGetSavedAddressAPI() {
  const url = `${storeApiUrl}/api/v2/shipping/me`;

  return useSWRFetch<IGStoreSavedAddress>({ key: url, useToken: true });
}

export function useGetPointHistoryAPI() {
  const url = `${storeApiUrl}/api/v2/point/account/trx/histories`;

  return useSWRInfiniteFetch<IGPointHistoryData[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        if (previousPageData.next_page?.has_next_page) {
          return `${url}?nextValue=${previousPageData?.next_page?.next_value}`;
        } else {
          return null;
        }
      } else {
        return url;
      }
    },
    useToken: true,
  });
}

export function useGetDigitalProductHistoryInfiniteAPI() {
  const listApiUrl = storeApiUrl + '/api/shop/digital/goods/order/my/list';

  return useSWRInfiniteFetch<DigitalProductOrderDataList>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        const { total_pages, current_page } = previousPageData?.data?.pagination;
        const isLastPage = current_page === total_pages;

        if (isLastPage) {
          return null;
        } else {
          return `${listApiUrl}?list_count=10&current_page=${
            previousPageData?.data?.pagination?.current_page + 1
          }`;
        }
      } else {
        return `${listApiUrl}?list_count=10&current_page=1`;
      }
    },
    useToken: true,
  });
}

/** @deprecated */
export function useGetOrderHistoryAPI() {
  return useSWRInfiniteFetch<IGStoreOrderDetailList[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        if (previousPageData.next_page?.has_next_page) {
          return `${storeApiUrl}/api/store/order/my?&next_values=${previousPageData?.next_page?.next_values}`;
        } else {
          return null;
        }
      } else {
        return `${storeApiUrl}/api/store/order/my`;
      }
    },
    useToken: true,
  });
}

export function useGetOrderStatusCountAPI() {
  const url = `${storeApiUrl}/api/store/order/my/dashboard/status`;

  return useSWRFetch<IGStoreOrderStatusCount[]>({ key: url, useToken: true });
}

/** @deprecated */
export function useGetMerchandiseProductCountAPI() {
  const url = `${storeApiUrl}/api/shop/digital/goods/order/type/count`;

  return useSWRFetch<IGstoreCountStatus[]>({ key: url, useToken: true });
}

/** @deprecated */
export function useGetOrderDetailAPI(orderId: string) {
  const url = orderId ? `${baseUrls.pointV2}/orders/${orderId}` : '';

  return useSWRFetch<IGStoreOrderDetail>({ key: url, useToken: true });
}

export function useGetDigitalProductOrderDetailAPI(orderId: string) {
  const url = orderId ? `${storeApiUrl}/api/shop/digital/goods/order/${orderId}` : '';

  return useSWRFetch<IGStoreDigitalProductOrderDetail>({ key: url, useToken: true });
}

export function useGetPinCardNumberAPI(orderId: string) {
  const url = orderId ? `${storeApiUrl}/api/shop/digital/goods/order/cardpin/${orderId}` : '';

  return useAxiosWithAuth<string>(url, { method: 'POST' }, true);
}

// 물리 상품 주문
export function useOrderPhysicalGoodsAPI() {
  const url = `${storeApiUrl}/api/v2/orders/physical-goods`;
  return useFetch<any, IGStoreOrderFormData>(url, { method: 'POST' }, true);
}

// 주문 취소
export function useCancelOrderAPI(orderId?: string) {
  const url = `${storeApiUrl}/api/v2/orders/${orderId}`;
  return useFetch<CancelOrderResponseData>(url, { method: 'DELETE' }, true);
}

interface PaymentDetailType {
  amount: number;
  payment_at: string;
  payment_type: 'Earned G.Points' | 'Purchased G.Points';
}

export function useGetPaymentDetailAPI(orderId?: string) {
  const url = orderId ? `${storeApiUrl}/api/v2/orders/${orderId}/payments` : '';

  return useSWRFetch<PaymentDetailType[]>({
    key: url,
    useToken: true,
  });
}
