import { useMemo } from 'react';

import { useGameEventBadgeListAPI, useGamelistAPI } from 'api/gamelist/gamelistAPI';
import GameListSmallCard from 'components/GameList/GameListSmallCard';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';

interface GameCardListSmallContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  useSlider: boolean;
  includeSurveyGame?: boolean;
  likeButton?: boolean;
  hasDefaultThumbnail?: boolean;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  onClickCard?: (id: number) => void;
  onRecommendAGameClick?: () => void;
  useAll?: boolean;
  fillHeight?: boolean;
}

export default function GameListSmallContainer({
  cardtype,
  count = 4,
  name,
  hoverVideoPlay,
  showViewMoreCard,
  useSlider,
  includeSurveyGame,
  likeButton = true,
  hasDefaultThumbnail,
  onLikeClicked,
  onClickCard,
  onRecommendAGameClick,
  useAll,
  fillHeight,
}: GameCardListSmallContainerProps) {
  const { isLoading, isValidating, data, error } = useGamelistAPI(cardtype, includeSurveyGame);
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();

  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const gameList: GameCardProps[] = useMemo(
    () =>
      Array.isArray(data?.data) && data?.data && data?.data.length > 0
        ? data.data
            .map?.((data) => ({
              ...data,
              state: cardtype,
              likeButton: likeButton,
              hoverVideoPlay: hoverVideoPlay,
            }))
            .slice(0, useSlider || useAll ? data.data.length : count)
        : [],
    [data?.data, useSlider, useAll, count, cardtype, likeButton, hoverVideoPlay]
  );

  return isDataNotExist ? null : (
    <GameListSmallCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      cardtype={cardtype}
      name={name}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={likeButton}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      useSlider={useSlider}
      hasDefaultThumbnail={hasDefaultThumbnail}
      onClickCard={onClickCard}
      onLikeClicked={onLikeClicked}
      onRecommendAGameClick={onRecommendAGameClick}
      fillHeight={fillHeight}
    />
  );
}
