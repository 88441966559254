import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { EducatorInfoRequest, usePatchEducatorInfoAPI } from 'api/university/universityAPI';
import { useError } from 'hooks';
import type { EducatorFormData } from 'types/UniversityType';
import EducatorForm from './EducatorForm';

interface Props {
  defaultValues: EducatorFormData;
  mutateInfo: () => void;
}

export default function EducatorDetails({ defaultValues, mutateInfo }: Props) {
  const { isLoading, fetch: submit } = usePatchEducatorInfoAPI();
  const { showError } = useError();

  const methods = useForm<EducatorFormData>({ defaultValues });

  const { major: defaultMajor, class: defaultClass } = defaultValues;

  const onSubmit = async (formData: EducatorFormData) => {
    // 수정 및 추가 Major
    let updateMajorList: { major_name: string; major_id: number }[] = [];
    let insertMajorList: string[] = [];

    formData.major.forEach((formMajor) => {
      if (formMajor.major_id) {
        if (
          defaultMajor.find(
            (ori) => ori.major_id === formMajor.major_id && ori.major_name !== formMajor.major_name
          )
        ) {
          updateMajorList.push({ major_id: formMajor.major_id, major_name: formMajor.major_name });
        }
      } else {
        insertMajorList.push(formMajor.major_name);
      }
    });

    // 수정 및 추가 클래스
    let updateClassList: { class_name: string; class_id: number }[] = [];
    let insertClassList: string[] = [];

    formData.class.forEach((formClass) => {
      if (formClass.class_id) {
        if (
          defaultClass.find(
            (ori) => ori.class_id === formClass.class_id && ori.class_name !== formClass.class_name
          )
        ) {
          updateClassList.push({ class_id: formClass.class_id, class_name: formClass.class_name });
        }
      } else {
        insertClassList.push(formClass.class_name);
      }
    });

    // 삭제할 클래스
    let deleteClassList: number[] = [];
    defaultClass.forEach((classItem) => {
      if (typeof classItem.class_id === 'number') {
        if (!formData.class.find((formClass) => formClass.class_id === classItem.class_id)) {
          deleteClassList.push(classItem.class_id);
        }
      }
    });

    const request: EducatorInfoRequest = {
      fullName: formData.name.trim(),
      updateMajorList,
      insertMajorList,
      updateClassList,
      insertClassList,
      deleteClassList,
    };

    const response = await submit(request);
    if (response.status === 200 && response.data.result?.code === 0) {
      mutateInfo();
    } else {
      showError(response);
    }
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="bottom-left" />
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <EducatorForm majorCount={defaultMajor.length} />
        <Box display="flex" justifyContent="flex-end" mt="32px">
          <LoadingButton
            size="medium"
            color="primary"
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            Save Changes
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
}
