import { createModel } from '@rematch/core';

import { RootModel } from '.';

type MobileState = {
  isDesktopTransformed: boolean;
};

const getLoginData = () => {
  const retData: MobileState = {
    isDesktopTransformed: false,
  };

  const viewDesktopData = sessionStorage.getItem('isViewDesktop');
  if (viewDesktopData) {
    const viewData: MobileState = JSON.parse(viewDesktopData);
    if (!viewData) {
      sessionStorage.removeItem('isViewDesktop');
      return retData;
    }

    retData.isDesktopTransformed = true;
  }

  return retData;
};

export const mobile = createModel<RootModel>()({
  state: getLoginData(),
  reducers: {
    transformDesktopView: (state: MobileState) => {
      sessionStorage.setItem('isViewDesktop', 'true');
      return {
        ...state,
        isDesktopTransformed: true,
      };
    },
  },
  effects: {},
});
