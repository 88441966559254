import { useGStoreBannerAPI } from 'api/gstore/useGStoreBannerAPI';
import { SubmainBannerList } from 'components';

export default function GStoreBanner() {
  const { data: bannerData, isLoading } = useGStoreBannerAPI({ sectionId: '301' });

  return (
    <SubmainBannerList
      name="gstore-banner"
      banners={bannerData?.data || []}
      isLoading={isLoading}
    />
  );
}
