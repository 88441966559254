import { Typography, Card, CardContent, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import { format } from 'date-fns';

const StyledCard = styled(Card)({
  width: 392,
  height: 112,
  borderRadius: 5,
});

export interface MediaExposureItem {
  title: string;
  link: string;
  display: boolean;
  date: Date;
}

export default function GameInfoMediaExposureItem({
  title,
  link,
  display,
  date,
}: MediaExposureItem) {
  return display ? (
    <StyledCard>
      <ButtonBase
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ width: '100%', height: '100%' }}
      >
        <CardContent sx={{ width: '100%', height: '100%', padding: '20px' }}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {title}
          </Typography>
          <Typography variant="subtitle3" color="text.secondary2" marginTop="8px">
            {format(new Date(date), 'MMM-dd-yyyy')}
          </Typography>
        </CardContent>
      </ButtonBase>
    </StyledCard>
  ) : null;
}
