import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useLog } from 'utils/userLog';

export default function AboutGamerGetGpointButton() {
  const { gxcTag } = useLog();
  const navigate = useNavigate();
  const handleClickBtn = () => {
    gxcTag('click', {
      area: 'detail',
      ui: 'learn_more',
    });

    navigate('/store');
  };

  return (
    <Typography
      variant="subtitle2"
      color="blue.primary"
      onClick={handleClickBtn}
      marginTop="40px"
      sx={{ cursor: 'pointer' }}
    >
      Learn More &gt;
    </Typography>
  );
}
