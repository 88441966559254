import { GTab, GTabs } from '@ground/ui';
import { Skeleton, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { OOrderTab, useGetOrderCount } from 'api/order';

export default function ProfileOrderTab() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { type = OOrderTab.MERCHANDISE } = useParams();
  const { isLoading: isCountLoading, data: countData } = useGetOrderCount();

  return (
    <>
      {isCountLoading ? (
        <Skeleton
          animation="wave"
          width="100%"
          height={45}
          sx={{ borderRadius: '8px', marginBottom: '40px' }}
        />
      ) : (
        <GTabs
          id="order-tab"
          value={type}
          size="medium"
          onChange={(_, newValue) => {
            navigate(`../${newValue}`);
          }}
          aria-label="order nav tabs"
          height={37}
          indicatorBgColor={palette.text.primary}
          indicatorHeight={2}
          sx={{ marginBottom: '40px' }}
        >
          <GTab
            label={`Merchandise (${countData?.data?.physical_goods ?? 0})`}
            value={OOrderTab.MERCHANDISE}
            size="medium"
          />
          <GTab
            label={`Digital Games (${countData?.data?.digital_games ?? 0})`}
            value={OOrderTab.DIGITAL_GAMES}
            size="medium"
          />
          <GTab
            label={`Digital Products (${countData?.data?.digital_goods ?? 0})`}
            value={OOrderTab.DIGITAL_PRODUCTS}
            size="medium"
          />
        </GTabs>
      )}
    </>
  );
}
