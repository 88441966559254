import { GIconButton } from '@ground/ui';
import { styled } from '@mui/material';
import lottie from 'lottie-web/build/player/lottie_light';
import { useEffect, useRef } from 'react';

import lottieLikeButton from 'lotties/like-button.json';

const LottieImage = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  width: 32,
  height: 32,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

interface GameCardLikeButtonProps {
  isActivated?: boolean;
  readonly onClick?: () => void;
}

export default function GameCardLikeButton({
  isActivated = false,
  onClick,
}: GameCardLikeButtonProps) {
  // 로티 애니메이션으로 구성되는 element
  const element = useRef<HTMLDivElement>(null);
  const prevIsActivated = useRef(isActivated);

  // 상태가 false였다가 true로 바뀐 경우에만 애니메이션 재생
  useEffect(() => {
    if (prevIsActivated.current === false && isActivated) {
      if (element.current) {
        const lottieItem = lottie.loadAnimation({
          container: element.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: lottieLikeButton,
        });
        lottieItem.addEventListener('complete', function () {
          lottieItem.removeEventListener('complete');
          lottie.destroy();
        });
      }
    }
    // 이전 상태값을 업데이트하여 상태변경시에 정상 작동하도록 함
    prevIsActivated.current = isActivated;
  }, [isActivated]);

  return (
    <>
      <GIconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.();
        }}
        size={32}
        iconColor={isActivated ? 'error.main' : undefined}
        iconName={isActivated ? 'heart_on' : 'heart_off'}
        iconSize={16}
        disableHoverEffect={isActivated}
      />
      <LottieImage ref={element} />
    </>
  );
}
