import type { FormAutocompleteOption } from '@ground/ui';
import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { differenceInYears } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSaveProfileAPI, type ProfileData, type ProfileFormData } from 'api/profile/profileAPI';
import { useDialog, useError, type CountryData } from 'hooks';
import eventTracker from 'utils/eventTracker';
import {
  AccountInfoEmail,
  PersonalDetailsBirthday,
  PersonalDetailsCountry,
  PersonalDetailsGender,
  PersonalDetailsName,
} from '.';

export default function ProfileForm({
  countriesData,
  profileData,
  reload,
}: {
  countriesData: CountryData[];
  profileData: ProfileData;
  reload: () => void;
}) {
  const { showDialog } = useDialog();
  const { showError } = useError();
  const navigate = useNavigate();
  const { isLoading: submitLoading, fetch: submit } = useSaveProfileAPI();

  const countries = countriesData ?? [];

  const countryList: FormAutocompleteOption[] = countries.map((country) => ({
    name: country.name,
    value: country.code,
  }));

  const methods = useForm<Omit<ProfileFormData, 'subscribe_email'>>({
    mode: 'onChange',
    defaultValues: {
      name: profileData.name ?? '',
      birthday:
        profileData.birth_year && profileData.birth_month && profileData.birth_day
          ? new Date(
              `${profileData.birth_year}-${profileData.birth_month}-${profileData.birth_day}`
            )
          : null,
      country: countryList.find((country) => country.value === profileData?.country_code) || null,
      sex: profileData?.sex ?? undefined,
    },
  });

  const onSubmit = async (formData: Omit<ProfileFormData, 'subscribe_email'>) => {
    if (!formData.birthday) return;

    //나이 13년 차이 체크
    const resultYears = differenceInYears(new Date(), formData.birthday);

    if (resultYears < 13) {
      showDialog({
        title: 'Warning',
        body: 'To ensure your safety, we restricted access to G.Round for younger audiences. We apologize for the inconvenience.',
        primaryButton: { text: 'Ok' },
      });
      return;
    }

    const response = await submit({
      name: formData.name.trim(),
      birth_month: formData.birthday.getMonth() + 1,
      birth_day: formData.birthday.getDate(),
      birth_year: formData.birthday.getFullYear(),
      country_code: formData.country?.value
        ? formData.country.value.toString()
        : profileData?.country_code ?? '',
      sex: formData.sex ?? '',
    });
    if (response.status === 200 && response.data.result_code === 'OK') {
      // 이름이 중복되는 경우 새 이름으로 바꿔줘야 함
      methods.setValue('name', response.data.data.name);
      reload();
      showDialog({
        title: 'Profile Updated',
        body: 'Your profile has been successfully updated! You can edit your profile on your PC after verifying your email.',
        primaryButton: { text: 'Ok', callback: () => navigate('/') },
      });
    } else {
      showError(response);
    }
    eventTracker('profile_profile_personaldetails_savechanges');
    twttr?.conversion.trackPid('o91dq', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/tv48CM6dsMQDEI29kb8C' });
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="bottom-right" />
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Box mb="20px">
            <AccountInfoEmail email={profileData?.email} verify={!!profileData?.verified} />
            {!profileData?.verified && (
              <Typography variant="body3" color="error.main" mt="8px">
                *Your email address hasn't been verified.
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <PersonalDetailsName />
            <Box height={20} />
            <PersonalDetailsCountry
              countryList={countryList}
              disabled={!!profileData?.country_code}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box mt="20px">
              <PersonalDetailsBirthday />
            </Box>
            <Box height={20} />
            <PersonalDetailsGender />
          </Box>
        </Box>
        <LoadingButton
          color="primary"
          type="submit"
          variant="contained"
          loading={submitLoading}
          fullWidth
          size="largest"
          sx={{ marginTop: '40px' }}
        >
          Save
        </LoadingButton>
      </form>
    </FormProvider>
  );
}
