import { baseUrls } from 'api';

export const emailQueries = {
  fetchEmailSubscribes() {
    return `${baseUrls.client}/subscribes`;
  },
  fetchEmailSendcode() {
    return `${baseUrls.client}/sendcode`;
  },
  fetchEmailCheckVerificationCode() {
    return `${baseUrls.client}/chksentcode`;
  },
};
