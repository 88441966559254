import { LoadingWrapper } from '@ground/ui';
import { Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateCheckoutSessionAPI } from 'api/payment/paymentAPI';
import { useDialog } from 'hooks';
import PaymentElement from './PaymentElement';

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === 'DEV'
    ? 'pk_test_51N5rQ6IaEtlFXFPqfIKLboKdcywkPQ8JYFrFWJ0OH4mzOwuSqCLmYOX7pfa14o4DODVuAOY4yZiybTSeU10QUbz700FGwRq4My'
    : 'pk_live_51N5rQ6IaEtlFXFPqUeBsXgnpMV9VsCJ8MtB2wzDQQigPspp4dIs4a4jYsNRwGAQhlZrnzQh2zFSs7701IFgsiAPK00SD7Ndd3N'
);

interface Props {
  options: { amount: number; currency: 'USD'; priceId: string; productId: string };
  onComplete?: () => void;
}

export default function PaymentWrapper({ options, onComplete }: Props) {
  const [checkoutSessionData, setCheckoutSessionData] = useState({ id: '', cs: '' });
  const { setImmerState } = useDialog();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const {
    fetch: createCheckoutSession,
    isLoading: isCreatingCheckoutLoading,
    error: checkoutError,
  } = useCreateCheckoutSessionAPI();

  useEffect(() => {
    async function fetchPaymentIntent() {
      const checkoutResult = await createCheckoutSession({
        price_id: options.priceId,
        product_id: options.productId,
      });

      if (checkoutResult?.data?.data?.id) {
        setCheckoutSessionData({
          id: checkoutResult?.data?.data?.id,
          cs: checkoutResult?.data?.data?.client_secret,
        });
      }

      if (checkoutResult?.data?.error) {
        setIsError(true);
      }
    }

    fetchPaymentIntent();
  }, [createCheckoutSession, options.amount, options.currency, options.priceId, options.productId]);

  useEffect(() => {
    if (checkoutError || isError) {
      setImmerState((draft) => {
        draft.backgroundColor = undefined;
        draft.title = 'Payment Processing Failed';
        draft.body = (
          <Typography variant="body1" color="text.primary">
            Ooops, something went wrong
          </Typography>
        );
        draft.secondaryButton = {
          text: 'Close',
        };
        draft.width = '380px';
      });
    }
  }, [checkoutError, isError, navigate, setImmerState]);

  return (
    <LoadingWrapper
      isLoading={
        !checkoutError &&
        (isCreatingCheckoutLoading || Boolean(!checkoutSessionData.cs && !checkoutSessionData.id))
      }
      sx={{ height: '600px' }}
    >
      <Elements stripe={stripePromise}>
        <PaymentElement
          clientSecret={checkoutSessionData.cs}
          checkoutSessionId={checkoutSessionData.id}
          onComplete={onComplete}
        />
      </Elements>
    </LoadingWrapper>
  );
}
