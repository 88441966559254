import { ReactNode, Ref, forwardRef } from 'react';

export interface GIconBaseProps {
  icon: ReactNode;
  iconSize: number;
  iconColor?: string;
  opacity?: number;
}

const GIconBase = forwardRef((props: GIconBaseProps, ref: Ref<SVGSVGElement>) => {
  const { icon, iconSize, iconColor, opacity } = props;

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 32 32"
      color={iconColor}
      opacity={opacity}
    >
      {icon}
    </svg>
  );
});

export default GIconBase;
