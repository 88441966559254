import { Box, Divider, Typography } from '@mui/material';
import { useClientApplyData } from '../../ClientApplyProvider';
import Step1Contents from './ClientApplyStep1Content';
import Step2Contents from './ClientApplyStep2Content';
import Step3Contents from './ClientApplyStep3Content';

/** Contents 영역 */
export default function ClientApplyContents() {
  const { clientApplyStep } = useClientApplyData();

  return (
    <Box width="1200px" mb="200px" p="60px" sx={{ background: (theme) => theme.palette.gray[80] }}>
      <Box textAlign="center">
        <Typography variant="h3" mb="20px">
          プライベートテストを申し込む
        </Typography>
        <Typography variant="body1" color="text.secondary">
          ※このプライベートテスト申請ページは限られたユーザーのみがアクセスできる画面であり、
          <br />
          内容が漏洩すると法的責任を問われる可能性があります。
        </Typography>
        <Divider sx={{ margin: '40px 0 60px' }} />
      </Box>

      {clientApplyStep === 1 ? <Step1Contents /> : null}
      {clientApplyStep === 2 ? <Step2Contents /> : null}
      {clientApplyStep === 3 ? <Step3Contents /> : null}
    </Box>
  );
}
