import { Grid } from '@mui/material';

import HowToPlayGridItem, { HowToPlayContentProps } from './HowToPlayGridItem';

interface Props {
  contentList: HowToPlayContentProps[];
}

export default function HowToPlayGridList({ contentList }: Props) {
  return (
    <Grid container justifyContent="space-between">
      {contentList.map((content) => (
        <HowToPlayGridItem key={content.id} content={content} />
      ))}
    </Grid>
  );
}
