import useFetch from 'api/useFetch';
import { emailQueries } from './emailQueries';

// TODO: request type 추가하기
export function useEmailSubscribeAPI() {
  return useFetch(emailQueries.fetchEmailSubscribes(), { method: 'POST' });
}

// TODO: request type 추가하기
export function useSendEmailVerificationCodeAPI() {
  const state = useFetch(emailQueries.fetchEmailSendcode(), { method: 'POST' }, true);
  // device 값이 필수여서 fetch 함수 변환함. 'P'는 'PC' 약자
  return { ...state, fetch: () => state.fetch({ device: 'P' }) };
}

// TODO: request type 추가하기
export function useCheckVerificationCodeAPI() {
  return useFetch(emailQueries.fetchEmailCheckVerificationCode(), { method: 'POST' }, true);
}
