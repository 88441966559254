import useFetch from 'api/useFetch';
import useSWRFetch from 'api/useSWRFetch';
import { paymentQueries } from './paymentQueries';

export interface GPointProductType {
  product_id: string;
  price_id: string;
  product_name: string;
  ground_point: number;
  cost: number;
}

export interface CreateCheckoutSessionResponseData {
  id: string;
  client_secret: string;
  livemode: boolean;
  currency: string;
  amount_subtotal: number;
  amount_total: number;
  created: number;
  expired_at: number;
  payment_method_types: string[];
  payment_status: string;
  url: string;
  status: string;
  mode: string;
  success_url: string;
  cancel_url: string;
  ui_mode: string;
}

export interface PointOrderDetailDataResponseType {
  pg_id: string;
  trx_id: string;
  product_name: string;
  pay_amount: number;
  pay_status: string;
  currency: string;
  product_images: any[];
  payment_method: string;
  created_at: string;
  canceled: boolean;
}

export function useGPointProductsAPI() {
  return useSWRFetch<GPointProductType[]>({
    key: paymentQueries.getGPointProducts(),
    useToken: true,
  });
}

export function useCreateCheckoutSessionAPI() {
  return useFetch<CreateCheckoutSessionResponseData>(
    paymentQueries.createCheckoutSession(),
    { method: 'POST' },
    true
  );
}

export function useCheckUserIsAdultAPI() {
  return useFetch<{ id: number; adult: boolean }>(
    paymentQueries.checkUserAdult(),
    { method: 'GET' },
    true
  );
}

export function useGetPointOrderDetailAPI(trxId: string) {
  return useSWRFetch<PointOrderDetailDataResponseType>({
    key: trxId ? paymentQueries.getGPointOrderDetail(trxId) : '',
    useToken: true,
  });
}

export function useCreateReceiptUrl(pdId?: string) {
  return useSWRFetch<{ receipt_url: string }>({
    key: pdId ? paymentQueries.createReceiptUrl(pdId) : '',
    useToken: true,
  });
}

export function useCheckPaymentStatus(sessionId?: string) {
  return useFetch<{ purchase_success: boolean }>(
    sessionId ? paymentQueries.checkPaymentStatus(sessionId) : '',
    undefined,
    true
  );
}
