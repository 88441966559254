import { SubmainBannerList } from 'components';

export default function ClipBanner() {
  return (
    <SubmainBannerList
      name="clip-banner"
      banners={[
        {
          site_banner_id: 0,
          title: 'Clips',
          image_url: '/images/clips/clip_main_banner.webp',
          image_link_url: null,
          headline: 'Clips',
          body_copy: 'Watch some clips to learn about the games on G.Round',
          btn_name: null,
          btn_link_url: null,
          sortation: null,
          exist_option_btn: false,
          option_btn_name: null,
          option_btn_link_url: null,
          site_banner_display_id: 0,
          target_id: null,
        },
      ]}
    />
  );
}
