import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { GameCardProps } from 'types/GameCardTypes';
import GameCardSmallLowerArea from './GameCardSmallLowerArea';
import GameCardSmallUpperArea from './GameCardSmallUpperArea';
import GameCardLikeButtonContainer from '../GameCardLikeButton/GameCardLikeButtonContainer';

export default function GameCardSmall({
  onLikeClicked,
  onClickCard,
  fillHeight,
  ...props
}: {
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  onClickCard?: (id: number) => void;
  fillHeight?: boolean;
} & GameCardProps) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Link
      style={{ cursor: props?.isPrivateShow ? 'auto' : 'cursor', textDecoration: 'auto' }}
      to={props?.isPrivateShow ? '' : `/detail/${props.id}/info`}
      onClick={() => onClickCard?.(props.id)}
    >
      <Box
        position="relative"
        width={285}
        ref={hoverRef}
        sx={{
          borderRadius: '5px',
          boxShadow: isHover && !props.isPrivateShow ? '0px 10px 10px rgba(0, 0, 0, 0.5)' : '',
        }}
      >
        <GameCardSmallUpperArea {...props} isHover={isHover} />
        <GameCardSmallLowerArea {...props} isHover={isHover} fillHeight={fillHeight}>
          {props.likeButton && (
            <Box position="absolute" sx={{ top: 13, right: 11 }}>
              <GameCardLikeButtonContainer
                gameId={props.id}
                likeCheck={props.like_check}
                onLikeClicked={onLikeClicked}
              />
            </Box>
          )}
        </GameCardSmallLowerArea>
        {props.chipText && (
          <Box
            position="absolute"
            sx={{
              top: 12,
              right: 12,
              zIndex: 999,
              backgroundColor: '#FFF',
              padding: '0px 8px',
              borderRadius: '3px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="small2" color="gray.100">
              {props.chipText}
            </Typography>
          </Box>
        )}
      </Box>
    </Link>
  );
}
