import { Reply, ReviewPopupMenuItem } from '@ground/ui';
import { ComponentProps, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  useLikeCommentAPI,
  useReviewCommentDeleteAPI,
  useReviewCommentHideAPI,
  useReviewCommentUnHideAPI,
} from 'api/comment/commentAPI';
import { useGetPermissionAPI } from 'api/detail/permissionAPI';
import { useDialog, useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';
import { ReviewItemDataV1 } from 'types/CommentType';

interface Props {
  reply: ReviewItemDataV1;
  mutateReplyList: () => void;
}

export default function ReplyContainer({ reply, mutateReplyList }: Props) {
  const {
    game_id: gameId,
    id: replyId,
    hidden,
    liked,
    liked_count,
    deletable,
    created_at,
    user_badge,
    user_name,
    user_pic_url,
    supporter,
    stat,
    text,
  } = reply;

  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showDialog } = useDialog();
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();

  const isDeleted = stat === 0;
  const { fetch: fetchDelete } = useReviewCommentDeleteAPI(gameId, replyId);
  const showDeleteButton = !isDeleted && !hidden && deletable;

  // 삭제되지 않은 reply에만 like 버튼 표출
  const showLikeButton = !hidden && !isDeleted;
  const { fetch: fetchLike, isLoading: loadingLike } = useLikeCommentAPI(gameId, replyId);

  const { data: permissionData } = useGetPermissionAPI(reply.game_id);
  const permission = permissionData?.data;
  // 본인이 삭제한 reply가 아니면서 하이드 권한이 있는 경우
  const showBlindButton = !isDeleted && !!permission?.hide_comment_permission;
  const { isLoading: loadingHide, fetch: fetcHide } = useReviewCommentHideAPI(gameId, replyId);
  const { isLoading: loadingUnHide, fetch: fetchUnHide } = useReviewCommentUnHideAPI(
    gameId,
    replyId
  );

  // 본인 삭제
  const mutateDelete = useCallback(async () => {
    const response = await fetchDelete();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      // reply list 갱신 필요
      mutateReplyList();
    } else {
      showDialog({
        title: 'Error',
        body: response.data?.result?.msg,
        primaryButton: { text: 'OK' },
      });
    }
  }, [fetchDelete, mutateReplyList, showDialog]);

  const handleDeleteConfirm = useCallback(async () => {
    showDialog({
      title: 'Confirmation',
      body: (
        <>
          Are you sure you want to delete your reply?
          <br />
          This action cannot be undone.
        </>
      ),
      primaryButton: { text: 'Confirm', callback: mutateDelete },
      secondaryButton: { text: 'Cancel' },
    });
  }, [mutateDelete, showDialog]);

  const menuList: ReviewPopupMenuItem[] = showDeleteButton
    ? [{ label: 'Delete', value: 'delete', onClick: handleDeleteConfirm }]
    : [];

  // like
  const handleClickLikeButton = useCallback(async () => {
    if (!isLogin) {
      showSignupDialog();
      return;
    }

    const response = await fetchLike();
    if (response.status === 200 && response.data.result?.code === 0) {
      reply.liked = !liked;
      reply.liked_count++;
      mutateReplyList();
    } else {
      showError(response, 'Failed to like reply');
    }
  }, [fetchLike, isLogin, liked, mutateReplyList, reply, showError, showSignupDialog]);

  // admin 삭제
  const mutateHide = useCallback(async () => {
    const response = hidden ? await fetchUnHide() : await fetcHide();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      mutateReplyList();
      // reply list 갱신
    } else {
      showDialog({
        title: 'Hide Failed',
        body: response.data?.result?.msg,
        primaryButton: { text: 'OK' },
      });
    }
  }, [fetcHide, fetchUnHide, hidden, mutateReplyList, showDialog]);

  const handleBlindComment = useCallback(async () => {
    showDialog({
      title: 'Review Hide',
      body: hidden ? 'Do you want to show this reply?' : 'Do you want to hide this reply?',
      secondaryButton: { text: 'Cancel' },
      primaryButton: {
        text: 'OK',
        callback: mutateHide,
        isLoadingButton: true,
      },
    });
  }, [showDialog, hidden, mutateHide]);

  const replyProps: ComponentProps<typeof Reply> = {
    userInfoProps: {
      userAvatarUrl: user_pic_url ?? '',
      username: user_name,
      badge: {
        adminBadge: Boolean(user_badge & 4),
        studioBadge: Boolean(user_badge & 2),
        creatorBadge: Boolean(user_badge & 1),
        supporterBadge: supporter,
      },
    },
    statusAreaProps: {
      status: {
        createdDate: created_at,
      },
      menuList: menuList,
    },
    textfieldProps: {
      isBanned: hidden,
      isDeleted: isDeleted && !hidden,
      isTextSelectable: true,
      text: text,
    },
    bottomProps: {
      ...(showLikeButton && {
        likeProps: {
          isLiked: liked,
          text: liked_count.toString(),
          isLoading: loadingLike,
          onClick: () => {
            handleClickLikeButton();
          },
        },
      }),
      ...(showBlindButton && {
        blindProps: {
          isBlind: hidden,
          loading: loadingHide || loadingUnHide,
          onClick: handleBlindComment,
        },
      }),
    },
  };

  return <Reply {...replyProps} />;
}
