import { Route, Routes } from 'react-router-dom';

import LoginForm from 'views/sign/signin/LoginForm';
import LoginEmailForm from 'views/sign/signin/LoginEmailForm';

export default function Login() {
  return (
    <Routes>
      <Route index element={<LoginForm isMobile />} />
      <Route path="email" element={<LoginEmailForm isMobile />} />
    </Routes>
  );
}
