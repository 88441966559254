import { GIcon } from '@ground/ui';
import { Box, Typography } from '@mui/material';

export default function DetailArchiveArea() {
  return (
    <Box
      marginTop="26px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p="20px 0"
      sx={{ backgroundColor: 'gray.90', borderRadius: '5px' }}
    >
      <GIcon
        iconName="exclamation"
        iconSize={16}
        backgroundColor="gray.60"
        iconColor="gray.20"
        size={24}
        variant="circle"
      />

      <Typography
        variant="button2"
        color="text.secondary2"
        sx={{
          width: '173px',
          textAlign: 'center',
          marginTop: '9px',
        }}
      >
        This game is part of our archive and cannot be played.
      </Typography>
    </Box>
  );
}
