import { Route, Routes } from 'react-router-dom';

import NotFound from 'views/main/NotFound';
import DetailMain from './DetailMain';
import { DetailProvider } from './DetailProvider';

export default function Detail() {
  return (
    <Routes>
      <Route
        path=":id/*"
        element={
          <DetailProvider>
            <DetailMain />
          </DetailProvider>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
