import { styled, Typography, Link } from '@mui/material';

const Root = styled('div')(() => ({
  width: 320,
  maxHeight: 314,
}));

export interface ArtDevTestimonialCardProps {
  id: number;
  title: string;
  link?: string;
  subTitle: string;
  desc: string;
}

export default function ArtDevTestimonialCard({
  title,
  link,
  subTitle,
  desc,
}: ArtDevTestimonialCardProps) {
  return (
    <Root>
      <Typography variant="h6">{title} </Typography>
      <Typography variant="body3" color="text.secondary2" sx={{ marginTop: '4px' }}>
        {subTitle}
      </Typography>
      <Typography variant="body2" color="text.secondary2" sx={{ margin: '20px 0 24px' }}>
        {desc}
      </Typography>
      <Link href={link} target="_blank" variant="subtitle2" color="text.secondary2">
        Visit Game
      </Link>
    </Root>
  );
}
