import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useFetch } from 'api';
import { useDialog, useError, useSign } from 'hooks';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { SignLayout } from '../component';
import SignupEmail from './SignupEmail';
import SignupPassword from './SignupPassword';
import { SignupBasicFormData, useSignupData } from './SignupProvider';

export default function SignupEmailForm() {
  const { showDialog } = useDialog();
  const { showError } = useError();
  const { signComplete } = useSign();
  const { gxcTag } = useLog();
  const { isMobile, basicFormData, setBasicFormData } = useSignupData();
  const methods = useForm<SignupBasicFormData>({ defaultValues: basicFormData, mode: 'onChange' });
  const { isLoading, fetch } = useFetch('users', { method: 'POST' });

  const onSubmit = async (formData: SignupBasicFormData) => {
    setBasicFormData(formData);
    const response = await fetch(formData);
    if (response?.status === 200 && response?.data.result_code === 'OK') {
      signComplete(response.data.data);
      // event tracker
      // Requirement to add Script for Google Ads 2021.11.26
      // https://www.notion.so/gameround/c9aa543eb50443c29b0c8a8246dbebea
      gtag('event', 'conversion', { send_to: 'AW-669277837/uDS-CMudwoMDEI29kb8C' });
    } else if (response?.data?.result_code === '403') {
      // 이미 존재하는 계정
      gxcTag('view', { area: 'already_registered_popup' });
      showDialog({
        title: 'Error',
        body: 'An account with this email address already exists.',
        primaryButton: {
          text: 'Back to Sign up',
          callback: () =>
            gxcTag('click', { area: 'already_registered_popup', ui: 'back_to_signup' }),
        },
        onClickClose: () => {
          gxcTag('click', { area: 'already_registered_popup', ui: 'close_x' });
        },
      });
    } else {
      showError(response, 'Signup');
    }
  };

  const handleClickSendVerificationMail = () => {
    eventTracker('signup_sendverificationmail');
    gxcTag('click', { area: 'detail', ui: 'send_email' });
  };

  return (
    <SignLayout isMobile={isMobile} title="Sign up with Email">
      <Box mb="40px" textAlign="center">
        {!isMobile && (
          <Typography color="text.primary" mb="10px" variant="h3">
            Sign up with Email
          </Typography>
        )}
        <Typography color="text.secondary" variant="subtitle2">
          A verification email will be sent.
          <br />
          Please enter a valid email address.
        </Typography>
      </Box>

      <FormProvider {...methods}>
        <DevTool control={methods.control} placement="top-right" />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack gap="20px">
            <SignupEmail />
            <SignupPassword />
          </Stack>

          <LoadingButton
            color="primary"
            fullWidth
            loading={isLoading}
            onClick={handleClickSendVerificationMail}
            size={isMobile ? 'largest' : 'large'}
            sx={{ marginTop: '40px' }}
            type="submit"
            variant="contained"
          >
            Send Verification Mail
          </LoadingButton>
        </form>
      </FormProvider>
    </SignLayout>
  );
}
