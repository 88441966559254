import { FormRadioGroup } from '@ground/ui';
import { Box } from '@mui/material';
import { SupporterFormProps } from 'api/supporter/useSuppoterTypes';
import { get, useFormContext } from 'react-hook-form';
import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

interface Props {
  detail_url: string;
}

export default function SupporterApplyQuestionChoice({
  question_context,
  disabled = false,
  index,
  required,
  question_selection,
  required_option,
  detail_url,
}: SupporterFormProps & Props) {
  const {
    formState: { errors },
    // register,
  } = useFormContext();

  const name = `supporter_question_vos.${index}`;
  const error = get(errors, name);
  const subtitle = required_option === 'Mobile' ? 'Required for Mobile testable users' : '';

  return (
    <Box sx={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : undefined }}>
      <SupporterQuestionTitle
        question={question_context}
        error={!!error}
        required={required}
        subtitle={subtitle}
        detail_url={detail_url}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '8px',
        }}
      >
        <FormRadioGroup
          options={question_selection.map((item) => {
            return {
              id: item.question_seq,
              order: item.question_seq,
              label: item.question_content,
            };
          })}
          disabled={disabled}
          name={`${name}.question_answer`}
          rules={{ required: required }}
          radio={{ align: 'start', color: 'private' }}
          sx={{ display: 'flex', gap: '40px' }}
        />
      </Box>
    </Box>
  );
}
