import { useSelector } from 'react-redux';
import { Method } from 'axios';

import useFetch from 'api/useFetch';
import { useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';

function useLikeAPI(url: string, method: Method) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();

  const { fetch } = useFetch(url, { method }, true);

  return {
    fetchLike: async () => {
      if (isLogin) {
        const response = await fetch();
        if (
          response?.status === 200 &&
          (response?.data?.result_code === 'OK' || response?.data?.result?.code === 0)
        ) {
          // like 성공 시 detail 데이터 갱신? 굳이 필요 없을 듯
        } else {
          showError(response);
        }
      } else {
        showSignupDialog();
      }
    },
  };
}

export function useGameLikeAPI(gameId: number | string) {
  return useLikeAPI(`games/${gameId}/like`, 'PATCH');
}

export function useVideoLikeAPI(gameId: number | string) {
  return useLikeAPI(`videos/${gameId}/like`, 'PATCH');
}

export function useGameLikeToggleAPI(gameId: number | string) {
  return useLikeAPI(`games/${gameId}/likeToggle`, 'POST');
}
