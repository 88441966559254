import { Tabs, styled } from '@mui/material';

export interface GTabsProps {
  /** bottom divider 유무 */
  disableDivider?: boolean;
  /** Tabs의 전체 높이 */
  height?: number;
  /** Indicator 색상 */
  indicatorBgColor?: string;
  /** Indicator 두께 */
  indicatorHeight?: number;
  /** Tab의 크기와 간격에 영향 */
  size?: 'medium' | 'large';
}

const GTabs = styled(Tabs, {
  shouldForwardProp: (prop) =>
    prop !== 'disableDivider' &&
    prop !== 'height' &&
    prop !== 'indicatorBgColor' &&
    prop !== 'indicatorHeight' &&
    prop !== 'size',
})<GTabsProps>(
  ({
    theme,
    disableDivider,
    height,
    indicatorHeight = 2,
    indicatorBgColor = theme.palette.primary.main,
    size = 'medium',
  }) => ({
    minHeight: 'auto',
    height: height || 'auto',
    ...(!disableDivider && { borderBottom: `1px solid ${theme.palette.opacity.white[5]}` }),

    '& .MuiTabs-flexContainer': {
      gap: size === 'medium' ? 40 : 28,
    },

    '& .MuiTabs-indicator': {
      height: indicatorHeight,
      backgroundColor: indicatorBgColor,
    },
  })
);

export default GTabs;
