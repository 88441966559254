import { FormInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAxios, useDialog, useError } from 'hooks';
import { emailPattern } from 'utils/common';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

type FormData = {
  email: string;
};

export default function RequestResetPassword() {
  const navigate = useNavigate();
  const { isLoading, refetch } = useAxios('mails/passwords', { method: 'POST' }, true);
  const { showDialog } = useDialog();
  const { showError } = useError();
  const { gxcTag } = useLog();
  const methods = useForm<FormData>({ mode: 'onChange' });
  const { handleSubmit } = methods;

  const onSubmit = async (formData: FormData) => {
    const response = await refetch({ params: formData });
    if (response?.status === 200 && response?.data.result_code === 'OK') {
      //리셋 이메일 발송
      gxcTag('view', { area: 'send_pw_popup' });
      showDialog({
        title: 'Additional Steps Required',
        body: 'We just sent you an email to reset your password! Please check your inbox or spam folder.',
        primaryButton: {
          text: 'Back to Sign in',
          callback: () => {
            gxcTag('click', { area: 'send_pw_popup', ui: 'back_to_signin' });
            navigate('/login');
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'send_pw_popup', ui: 'close_x' });
        },
      });
    } else if (response.data?.result_code === '400') {
      gxcTag('view', { area: 'invalid_email_popup' });
      showDialog({
        title: 'Error',
        body: 'The address you provided is not associated with a G.Round account. Please try with a different email address.',
        primaryButton: {
          text: 'Close',
          callback: () => {
            gxcTag('click', { area: 'invalid_email_popup', ui: 'close' });
          },
        },
        secondaryButton: {
          text: 'Sign in',
          callback: () => {
            gxcTag('click', { area: 'invalid_email_popup', ui: 'signin' });
            navigate('/login');
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'invalid_email_popup', ui: 'close_x' });
        },
      });
    } else {
      showError(response, 'Reset password');
    }
    eventTracker('resetpassword_resetpassword');
  };

  const handleClickResetPassword = () => {
    gxcTag('click', { area: 'detail', ui: 'send' });
  };

  const handleClickLogin = () => {
    eventTracker('resetpassword_login');
    gxcTag('click', { area: 'signin', ui: 'signin' });
    navigate('/login');
  };

  return (
    <Box
      sx={{
        padding: '40px 28px',
        width: '420px',
        backgroundColor: 'gray.90',
        marginTop: '100px',
        marginBottom: '200px',
        borderRadius: '5px',
      }}
    >
      <Typography variant="h3" mb="8px" textAlign="center">
        Reset Password
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" textAlign="center">
        <>
          Enter your email, so we can send a link
          <br />
          to reset your password!
        </>
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '40px' }}>
          <FormInput
            label="Email"
            name="email"
            type="email"
            data-cy="login-email"
            placeholder="Your email"
            rules={{
              required: 'Please enter a valid email.',
              pattern: { value: emailPattern, message: 'Please enter a valid email.' },
            }}
            fullWidth
            withBackground
          />
          <LoadingButton
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            sx={{ marginTop: '40px' }}
            type="submit"
            onClick={handleClickResetPassword}
          >
            Reset Password
          </LoadingButton>
        </form>
      </FormProvider>

      <Divider sx={{ mt: '40px', mb: '20px' }}>
        <Typography variant="small1">Remembered your password?</Typography>
      </Divider>

      <Button fullWidth variant="ghost" size="large" onClick={handleClickLogin}>
        Sign in
      </Button>
    </Box>
  );
}
