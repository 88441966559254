import { numberWithCommas } from '@ground/tools';
import { GIcon } from '@ground/ui';
import { Box, Button, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useGameDetailBasicInfoAPI } from 'api/detail/detailAPI';
import useSWRFetch from 'api/useSWRFetch';
import eventTracker from 'utils/eventTracker';
import GpointsListCard, { GpointsListCardProps } from './GpointsListCard';

const PaperWrap = styled(Paper)(({ theme }) => ({
  padding: 20,
  borderRadius: '5px',
  background: theme.palette.gray[60],
}));

interface HowtoGetGpointsDialogProps {
  gameId: string;
  hideDialog: () => void;
}

interface GpointPopupData {
  amount: number;
  //OTHER 는 오타.백엔드에 확인 요청하였음. 차후 수정 예정
  category: 'REVIEW' | 'GLOBALSURVEY' | 'SURVEY' | 'OTEHR' | 'PLAYED';
  game_id: number;
  item_id: number;
  pay_amount: number;
  point: number;
  remain_amount: number;
  type: 'FIRSTCOME' | 'SELECTION';
}

export default function HowtoGetGpointsDialog({ gameId, hideDialog }: HowtoGetGpointsDialogProps) {
  const { data } = useSWRFetch<GpointPopupData[]>({ key: `games/${gameId}/rewards` });
  const { data: gameData } = useGameDetailBasicInfoAPI(gameId);
  const minPlay = gameData?.data.game_files?.[0].min_play;

  const downloadPlayed = data?.data.find(
    (reward) => reward.category === 'PLAYED' && reward.type === 'FIRSTCOME'
  );

  const reviewComplete = data?.data.find(
    (reward) => reward.category === 'REVIEW' && reward.type === 'FIRSTCOME'
  );
  const reviewSelect = data?.data.find(
    (reward) => reward.category === 'REVIEW' && reward.type === 'SELECTION'
  );
  const globalSurveyComplete = data?.data.find(
    (reward) => reward.category === 'GLOBALSURVEY' && reward.type === 'FIRSTCOME'
  );
  const globalSurveySelect = data?.data.find(
    (reward) => reward.category === 'GLOBALSURVEY' && reward.type === 'SELECTION'
  );

  const cardData: GpointsListCardProps[] = [
    ...(minPlay
      ? [
          {
            title: 'Download & Played',
            point: downloadPlayed?.point ?? 0,
            isTBA: downloadPlayed?.point ? false : true,
            description: `First ${numberWithCommas(downloadPlayed?.amount ?? 0)} users`,
          },
        ]
      : []),
    {
      title: 'Completed Reviews',
      point: reviewComplete?.point ?? 0,
      description: `First ${numberWithCommas(reviewComplete?.amount ?? 0)} users`,
    },
    {
      title: 'Review Selected by Studio',
      point: reviewSelect?.point ?? 0,
      description: `Up to ${numberWithCommas(reviewSelect?.amount ?? 0)} users`,
    },
    {
      title: 'Completed Surveys',
      point: globalSurveyComplete?.point ?? 0,
      description: `First ${numberWithCommas(globalSurveyComplete?.amount ?? 0)} users`,
    },
    {
      title: 'Survey Selected by Studio',
      point: globalSurveySelect?.point ?? 0,
      description: `Up to ${numberWithCommas(globalSurveySelect?.amount ?? 0)} users`,
    },
  ];

  const earnMaxPoint =
    (minPlay ? downloadPlayed?.point ?? 0 : 0) +
    (reviewComplete?.point ?? 0) +
    (reviewSelect?.point ?? 0) +
    (globalSurveyComplete?.point ?? 0) +
    (globalSurveySelect?.point ?? 0);

  const stepData = [
    {
      stepNo: 1,
      title: 'Download the game and join the playtest.',
    },
    ...(minPlay
      ? [
          {
            stepNo: 2,
            title: 'Complete the requirement for minimum playtime.',
            subtitle: `*Minimum Playtime - ${minPlay} minutes.`,
          },
        ]
      : []),
    {
      stepNo: 3,
      title: 'Write a review and submit.',
      subtitle: '*Earn extra points if the studio picks your review!',
    },
    {
      stepNo: 4,
      title: 'Fill and submit the survey.',
      subtitle: '*Earn extra points if the studio selects your survey!',
    },
  ];

  const handleClickGoToGpoint = () => {
    hideDialog();
    eventTracker('gamedetail_popup_gotogpoint', { event_label: gameId });
  };

  return (
    <Box marginTop="20px">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" color="text.primary">
          G.Points Tier List
        </Typography>
        <Typography variant="body2" color="text.secondary">
          *You can earn up to “
          <Typography
            component="span"
            variant="body2"
            color="text.primary"
            sx={{ display: 'inline' }}
          >
            {earnMaxPoint}G.P
          </Typography>
          ” through this playtest
        </Typography>
      </Stack>
      <PaperWrap sx={{ marginTop: '20px' }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing="20px">
          {cardData.map((item, index) => (
            <Box width="160px">
              <GpointsListCard key={index} {...item} />
            </Box>
          ))}
        </Stack>
      </PaperWrap>

      <Typography variant="subtitle1" color="text.primary" marginTop="40px">
        How to get G.Points
      </Typography>

      <PaperWrap sx={{ marginTop: '20px' }}>
        <Stack divider={<Divider flexItem />} spacing="20px">
          {stepData.map((step, index) => (
            <Box key={step.stepNo} display="flex">
              <Typography variant="body1" color="text.secondary">
                Step {index + 1}
              </Typography>
              <Box marginLeft="40px">
                <Typography variant="body1">{step.title}</Typography>
                {step.subtitle && (
                  <Typography variant="body3" color="text.secondary" marginTop="4px">
                    {step.subtitle}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Stack>
      </PaperWrap>
      <Typography variant="body3" color="text.secondary" marginTop="20px">
        Reviews and Surveys allocated by the studios will be provided within 30 days after Survey is
        completed.
      </Typography>
      <Box display="flex" alignItems="center" marginTop="16px" gap="12px">
        <GIcon
          iconName="exclamation"
          iconSize={16}
          iconColor="warning.main"
          size={32}
          backgroundColor="gray.100"
          variant="circle"
          sx={{ flexShrink: 0 }}
        />
        <Typography variant="body2" color="text.primary">
          We love genuine reviews and opinions, as such fake reviews and cross posted reviews from
          other websites will result in G.Point removal and may even lead to a permanent ban!
        </Typography>
      </Box>

      <PaperWrap sx={{ marginTop: '40px', padding: '12px 20px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            You can spend your G.Points earned through the playtest on a variety of goods!
          </Typography>
          <Button
            component={Link}
            onClick={handleClickGoToGpoint}
            size="small"
            to="/store"
            variant="contained"
          >
            Go To Store
          </Button>
        </Box>
      </PaperWrap>
    </Box>
  );
}
