import useSWRFetch, { useSWRInfiniteFetch } from 'api/useSWRFetch';

import { ClipsMainVideoClipType } from 'types/ClipsTypes';
import { VideoClipType, TestingGameVideoCollectionType } from 'types/ClipsTypes';

export function useClipsMainVideoClipAPI() {
  return useSWRFetch<ClipsMainVideoClipType>({
    key: 'games/TESTING/videos?pageSize=6',
    useToken: true,
  });
}

export function useTestingGameVideoCollectionAPI() {
  const url = 'videos/testing_game/clip_collection';

  return useSWRFetch<TestingGameVideoCollectionType[]>({ key: url });
}

export function useClipsAllClipAPI(
  sort: string = 'createdAt',
  size: number,
  search_keyword: string = '',
  getTotalCount: boolean = false // total count 정보를 데이터에 포함할지 유무
) {
  const basicKey = `videos/game/list?sort=${sort}&size=${size}&search_keyword=${search_keyword}&get_count=${getTotalCount}`;

  return useSWRInfiniteFetch<VideoClipType[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        if (previousPageData.next_page?.has_next_page) {
          return `${basicKey}&next_value=${previousPageData?.next_page?.next_values}`;
        } else {
          return null;
        }
      } else {
        return basicKey;
      }
    },
    useToken: true,
  });
}
