import { FormInput, FormRadioGroup } from '@ground/ui';
import { Paper, Stack, Typography } from '@mui/material';
import { get, useFormContext, useWatch } from 'react-hook-form';

import { EssentialQuestionType } from 'types/FormDataType';
import ReviewQuestionTitle from './ReviewQuestionTitle';

interface Props {
  disabled?: boolean;
  index: number;
  isPrivate: boolean;
}

export default function ReviewQuestionChoice({
  low_mark,
  max_mark,
  question,
  seq,
  disabled = false,
  index,
  isPrivate,
}: EssentialQuestionType & Props) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const name = `essential.${index}`;
  const error = get(errors, name);
  const currentValue = useWatch({ name: `${name}.value` });

  const [questionText, description] = question.split('-');

  return (
    <Stack gap="8px">
      <input {...register(`${name}.id`)} type="hidden" />
      <ReviewQuestionTitle
        order={seq}
        question={questionText}
        description={description}
        error={!!error}
      />
      <Paper
        sx={(theme) => ({
          display: 'flex',
          background: theme.palette.gray[70],
          padding: '12px 20px',
        })}
      >
        <Typography
          color="text.secondary2"
          component="span"
          variant="body2"
          align="right"
          width="74px"
          noWrap
        >
          {low_mark}
        </Typography>

        <FormRadioGroup
          disabled={disabled}
          name={`${name}.value`}
          rules={{ required: true }}
          radio={{ width: 80, align: 'center' }}
          sx={{ marginX: '20px', columnGap: '4px', flexWrap: 'nowrap' }}
        />

        <Typography color="text.secondary2" component="span" variant="body2" width="74px" noWrap>
          {max_mark}
        </Typography>
      </Paper>
      {isPrivate && currentValue ? (
        <FormInput
          disabled={disabled}
          name={`${name}.opinion`}
          inputProps={{ maxLength: 10000 }}
          placeholder={`Please share your thoughts on your rating for ${questionText}. (Optional)`}
          rules={{
            minLength: { value: 10, message: 'Please input at least 10 characters.' },
            maxLength: 10000,
          }}
          fullWidth
          withBackground
          multiline
          rows={7}
        />
      ) : null}
    </Stack>
  );
}
