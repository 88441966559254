import { Box, Divider, Link } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSupporterApplyData } from '../../SupporterApplyProvider';
import SupporterApplyQuestionChoice from '../question/SupporterApplyQuestionChoice';
import SupporterApplyQuestionInputField from '../question/SupporterApplyQuestionInputField';

// 3섹션
export default function ExperienceSection() {
  const { supporterQuestionData } = useSupporterApplyData();
  const { watch } = useFormContext();
  const data = supporterQuestionData?.filter((item) => item.question_section_id === 30);
  const isOption = watch('supporter_question_vos.28');

  return (
    <Box p="40px" sx={{ backgroundColor: 'gray.90' }} mb="20px">
      {data?.map((question, index) => (
        <Box key={question.question_id}>
          {question.question_type === 'RADIO' ? (
            <SupporterApplyQuestionChoice
              {...question}
              required={Boolean(question.required)}
              disabled={false}
              index={index + 24}
            />
          ) : (
            <>
              <SupporterApplyQuestionInputField
                {...question}
                key={question.question_id}
                disabled={isOption?.question_answer !== '10'}
                index={index + 24}
                required={isOption?.question_answer === '10'}
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                mt="20px"
                sx={{
                  opacity: isOption?.question_answer !== '10' ? 0.2 : 1,
                  pointerEvents: isOption?.question_answer !== '10' ? 'none' : 'auto',
                }}
              >
                <Link
                  href="https://gameround.co/blog/156"
                  target="_blank"
                  color="text.private"
                  variant="button1"
                >
                  G.Round レビュー ガイド
                </Link>
              </Box>
            </>
          )}

          {data.length - 1 !== index && (
            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
          )}
        </Box>
      ))}
    </Box>
  );
}
