import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useDialog } from 'hooks';
import eventTracker from 'utils/eventTracker';
import HowtoGetGpointsDialog from 'views/detail/dialog/HowtoGetGpointsDialog';

export default function DetailGpointButton() {
  const { showDialog, closeDialog } = useDialog();
  const { id = '' } = useParams<{ id: string }>();

  const handleClickBtn = () => {
    showDialog({
      title: 'How to Get G.Points',
      body: <HowtoGetGpointsDialog hideDialog={closeDialog} gameId={id} />,
      width: 800,
    });
    eventTracker('gamedetail_upper_howtoget_gpoint', { event_label: id });
  };

  return (
    <Typography
      color="text.secondary"
      marginTop="26px"
      onClick={handleClickBtn}
      variant="subtitle3"
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
    >
      How to Get G.Points
    </Typography>
  );
}
