import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface GuideCardProps {
  srcInfo: string;
  title: string;
  description: ReactNode;
  isMobile?: boolean;
}

export default function GuideCard({ srcInfo, title, description, isMobile }: GuideCardProps) {
  return (
    <Stack alignItems="center" textAlign="center" width="260px">
      <img width="50px" height="50px" src={srcInfo} alt="guide icon" />
      <Typography
        variant="h6"
        color="base.dimWhite"
        fontWeight="700"
        mt={isMobile ? '12px' : '16px'}
      >
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" mt={isMobile ? '8px' : '12px'}>
        {description}
      </Typography>
    </Stack>
  );
}
