import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { TableCell, Link, Typography } from '@mui/material';

import { TypographyColor } from 'types/CommonTypes';

const PREFIX = 'DataTableCell';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: 0,
    borderBottom: 'initial',
    height: 80,
  },
}));

export interface DataTableCellProps {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?: TypographyColor;
  link?: string; // inner router link
  padding?: 'normal' | 'checkbox' | 'none';
  rowSpan?: number;
  children?: ReactNode;
}

export default function DataTableCell({
  align = 'center',
  color,
  link,
  padding,
  rowSpan,
  children = '',
}: DataTableCellProps) {
  return (
    <StyledTableCell
      className={classes.root}
      align={align}
      variant="body"
      padding={padding}
      rowSpan={rowSpan}
    >
      {link ? (
        <Link color="inherit" component={RouterLink} to={link}>
          {children}
        </Link>
      ) : typeof children === 'string' || typeof children === 'number' ? (
        <Typography variant="body2" color={color ?? 'text.secondary'}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </StyledTableCell>
  );
}
