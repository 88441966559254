import { ContentsLayout, PageLayout } from 'components';
import { QuerystringProvider } from 'context';
import ArchiveSection from './ArchiveSection';
import TestingSection from './TestingSection';
import TestingTopBannerContainer from './TestingTopBannerContainer';
import TestingPrivateSection from './private-game/TestingPrivateSection';

const defaultQuery = { search_keyword: '', sort: 'createdAt', size: 12 };

export default function Testing() {
  return (
    <PageLayout>
      <TestingTopBannerContainer />

      <ContentsLayout>
        <TestingSection />

        <TestingPrivateSection />

        <QuerystringProvider defaultQuery={defaultQuery}>
          <ArchiveSection />
        </QuerystringProvider>
      </ContentsLayout>
    </PageLayout>
  );
}
