import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGetSavedAddressAPI } from 'api/gstore/gstoreAPI';
import { iRootState } from 'store';
import { IGStoreShippingFormData } from 'types/GStoreTypes';
import Order from 'views/gstore/order/Order';
import { LoadingPage } from 'views/main/LoadingPage';

export default function OrderPageMerchandise() {
  const { isValidating: isValidatingSavedAddress, data: savedAddress } = useGetSavedAddressAPI();
  const { email } = useSelector((state: iRootState) => state.login);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (savedAddress && savedAddress.result?.code !== 200) {
      // v2 api에서 200번으로 성공코드 변경
      enqueueSnackbar(`${savedAddress.result?.code}: ${savedAddress.result?.msg}`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, savedAddress]);

  const savedAddressData = savedAddress?.data;

  const defaultValues: IGStoreShippingFormData = {
    ship_to_name: savedAddressData?.name ?? '',
    ship_to_email: (email || savedAddressData?.email) ?? '', // email이 ''인 경우가 있어 ??에서 ||로 변경
    // +가 없는 번호로 저장된 경우 +를 붙여서 넣어준다.
    ship_to_phone: savedAddressData?.phone
      ? savedAddressData?.phone.startsWith('+')
        ? savedAddressData?.phone
        : `+${savedAddressData?.phone}`
      : '',
    ship_to_address1: savedAddressData?.address1 ?? '',
    ship_to_address2: savedAddressData?.address2 ?? '',
    ship_to_city: savedAddressData?.city ?? '',
    ship_to_state: savedAddressData?.states ?? '',
    ship_to_zipcode: savedAddressData?.zipcode ?? '',
    ship_to_country_code: savedAddressData?.country_code ?? '',
    id_card_no: savedAddressData?.id_card_no ?? '',
    save_destination: true,
  };

  return isValidatingSavedAddress ? <LoadingPage /> : <Order defaultValues={defaultValues} />;
}
