import { useSelector } from 'react-redux';

import useFetch from 'api/useFetch';
import { useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';

function useLikeMobileApi(url: string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();
  const { fetch } = useFetch(url, { method: 'PATCH' }, true);

  return {
    fetchLike: async () => {
      if (isLogin) {
        const response = await fetch();
        if (
          response?.status === 200 &&
          (response?.data?.result_code === 'OK' || response?.data?.result?.code === 0)
        ) {
          // like 성공 시 detail 데이터 갱신? 굳이 필요 없을 듯
        } else {
          showError(response);
        }
      } else {
        showSignupDialog();
      }
    },
  };
}

export function useGameMobileLikeAPI(gameId: number | string) {
  return useLikeMobileApi(`games/${gameId}/like`);
}

export function useVideoMobileLikeAPI(gameId: number | string) {
  return useLikeMobileApi(`videos/${gameId}/like`);
}
