import { ReactNode } from 'react';
import { Typography, Box, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
}));

const StyledImg = styled('img')(({ theme }) => ({
  paddingBottom: 10,
}));

export interface AboutGamerBecomeListProps {
  icon: string;
  title: string;
  desc: string | ReactNode;
}

export default function AboutGamerBecomeList({ icon, title, desc }: AboutGamerBecomeListProps) {
  return (
    <Root>
      <StyledImg src={icon} alt={title}></StyledImg>

      <Box marginLeft="28px">
        <Typography variant="h6" marginBottom="12px">
          {title}
        </Typography>
        <Typography variant="body1" width="400px" sx={{ opacity: 0.6 }}>
          {desc}
        </Typography>
      </Box>
    </Root>
  );
}
