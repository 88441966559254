import { Box, Typography, styled } from '@mui/material';

import { ImageBox } from 'atoms';
import { LinkButton } from 'elements';
import type { BannerDataType } from 'types/BannerType';

const WIDTH = 1920;
const HEIGHT = 300;

const LeftOverLay = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: '250px',
  background: 'linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
}));

const RightOverLay = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  width: '250px',
  background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
}));

interface SubmainBannerProps extends BannerDataType {
  onClickButton?: () => void;
}

export default function SubmainBanner({
  title,
  image_url,
  headline,
  body_copy,
  btn_name,
  btn_link_url,
  onClickButton,
}: SubmainBannerProps) {
  return (
    <Box sx={{ position: 'relative', width: WIDTH, height: HEIGHT }}>
      <ImageBox
        src={image_url}
        alt={title}
        resize
        resizeWidth={WIDTH}
        resizeHeight={HEIGHT}
        fadeIn={false} // 이미지 로드될 때 fade 효과 제거
      />
      <Box sx={{ position: 'absolute', bottom: 80, left: 360 }}>
        <Typography variant="h3" color="text.primary" width="550px">
          {headline}
        </Typography>
        <Typography variant="subtitle3" color="base.dimWhite" mt="4px" width="450px">
          {body_copy}
        </Typography>
        <Box display="flex" marginTop="28px">
          {btn_name && btn_link_url && (
            <LinkButton
              variant="contained"
              color="light"
              size="small"
              to={btn_link_url}
              onClick={onClickButton}
            >
              {btn_name}
            </LinkButton>
          )}
        </Box>
      </Box>
      <LeftOverLay />
      <RightOverLay />
    </Box>
  );
}
