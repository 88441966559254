import { FormDatePicker } from '@ground/ui';
import { subYears } from 'date-fns';

export default function PersonalDetailsBirthday() {
  return (
    <FormDatePicker
      name="birthday"
      label="Date of birth"
      minDate={subYears(new Date(), 100)}
      maxDate={subYears(new Date(), 13)}
      required
      withBackground
      size="large"
    />
  );
}
