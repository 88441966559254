import { Divider, DividerProps } from '@mui/material';
import { Ref, forwardRef } from 'react';

export interface GDividerProps extends DividerProps {
  opacity?: 5 | 10 | 15 | 20;
}

const GDivider = forwardRef(({ opacity, sx, ...props }: GDividerProps, ref: Ref<HTMLHRElement>) => {
  const borderColor = { borderColor: `opacity.white.${opacity}` };

  return (
    <Divider
      ref={ref}
      sx={{
        ...(opacity && borderColor),
        '&::before, &::after': { ...(opacity && borderColor) },
        ...sx,
      }}
      {...props}
    />
  );
});

export default GDivider;
