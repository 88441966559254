import { useSelector } from 'react-redux';
import { iRootState } from 'store';

import { IGStoreDigitalOrderFormData } from 'types/GStoreTypes';
import OrderDigital from './OrderDigital';

export default function OrderPageDigital() {
  const { email } = useSelector((state: iRootState) => state.login);

  const defaultValues: IGStoreDigitalOrderFormData = {
    ship_to_email: email ?? '',
  };

  return <OrderDigital defaultValues={defaultValues} />;
}
