import { numberWithCommas } from '@ground/tools';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import ordinal from 'ordinal';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGameMobileLikeAPI } from 'api/like/likeMobileApi';
import { ReviewGauge } from 'components/ReviewGauge';
import { IconButtonForm } from 'elements';
import { iRootState } from 'store';
import { GameStatusType } from 'types/GameCommonTypes';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { LoadingPage } from 'views/main/LoadingPage';
import NotSupportPrivate from 'views/mobile/NotSupport/NotSupportPrivate';
import { NotFound } from '..';
import DetailCarousel from './DetailCarousel';
import { useGetDetailData } from './DetailProvider';

const ContentContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: 20,
});

const Divider = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#FFF',
  opacity: 0.1,
});

const VerticalDivider = styled('div')({
  width: 1,
  height: 10,
  backgroundColor: '#fff',
  opacity: 0.1,
  marginLeft: 12,
  marginRight: 12,
});

const DotDivider = styled('div')(({ theme }) => ({
  width: 4,
  height: 4,
  backgroundColor: theme.palette.gray[30],
  marginLeft: 8,
  marginRight: 8,
  borderRadius: '50%',
}));

const Chip = styled('div')({
  background: '#D2D7DD',
  borderRadius: 3,
  color: 'black',
  fontSize: 10,
  lineHeight: '14px',
  padding: '0px 6px',
});

const CancelButton = styled('div')({
  width: 36,
  height: 36,
  borderRadius: '50%',
  backgroundColor: 'rgba(0,0,0,0.3)',
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface GameLikeButtonProps {
  data: GameDetailProps;
}

function GameLikeButton({ data }: GameLikeButtonProps) {
  const { gxcTag } = useLog();
  const { id = '' } = useParams<{ id: string }>();
  const [liked, setLiked] = useState(!!data?.like_check);
  const [numLike, setUserLike] = useState(data?.num_like || 0);
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { fetchLike } = useGameMobileLikeAPI(id);

  const handleClickFavorite = async () => {
    fetchLike();

    gxcTag('click', {
      area: 'detail',
      ui: 'like',
      parameters: { game_id: id, like_check: !liked },
    });

    if (isLogin) {
      setLiked(!liked);
      setUserLike(numLike + (liked ? -1 : 1));
      eventTracker('gamedetail_upper_like', { event_label: id });
    }
  };

  return (
    <Box mt="20px">
      <IconButtonForm
        icon="favorite"
        isActivated={liked}
        text={numberWithCommas(numLike)}
        onClick={handleClickFavorite}
        size="medium"
      />
    </Box>
  );
}

export default function DetailMain() {
  const { gxcTag } = useLog();
  const { id = '' } = useParams<{ id: string }>();
  const { data, loading, files } = useGetDetailData();
  const [trigger, setTrigger] = useState<boolean>(false);
  const navigate = useNavigate();
  const slideData = data?.game_contents ?? [];
  const { enqueueSnackbar } = useSnackbar();
  const hasGauge =
    typeof data?.required_review === 'number' && typeof data?.progressed_review === 'number';

  if (loading) return <LoadingPage />;

  if (typeof data?.private_test === 'number') {
    return <NotSupportPrivate />;
  }

  return data?.display ? (
    <Stack
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
        },
      }}
    >
      <CancelButton
        onClick={() => {
          navigate('/');
          gxcTag('click', {
            area: 'detail',
            ui: 'close_x',
          });
        }}
      >
        <CloseIcon sx={{ color: 'base.dimWhite' }} />
      </CancelButton>

      <DetailCarousel slideData={slideData} />

      <ContentContainer>
        <Typography variant="h5">{data.title}</Typography>
        <Typography variant="body2" color="text.secondary" mt="4px">
          {data.studio_name}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }} mt="12px">
          {files && <Chip>{files[0].platform}</Chip>}
          <Typography variant="body3" color="text.secondary2" ml="8px">
            {ordinal(data?.round || 1)} Test
          </Typography>
          <VerticalDivider />
          <Typography variant="body3" color="text.secondary2">
            Version {data?.version ?? ''}
          </Typography>
        </Box>

        <Divider sx={{ marginTop: '20px' }} />
        {data && data.started_at && data.ended_at && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '12px',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2" color="text.secondary2">
                Playtesting Period
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="light.main">
                  {format(new Date(data.started_at), 'MMM d, yyyy')}
                </Typography>
                <Typography ml="2px" mr="2px">
                  -
                </Typography>
                <Typography variant="body2" color="light.main">
                  {format(new Date(data.ended_at), 'MMM d, yyyy')}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ marginTop: '12px' }} />
          </>
        )}
        <Box my="20px">
          {hasGauge && (
            <ReviewGauge
              count={data?.progressed_review}
              rate={data?.progress_rate}
              goal={data?.required_review}
              haveReachingPopup={
                data?.state === GameStatusType.TESTING &&
                typeof data?.progress_rate === 'number' &&
                data?.progress_rate < 150
              }
              isMobile
              onClick={() => {
                enqueueSnackbar("Achieved the studio's target number of reviews!", {
                  variant: 'info',
                  autoHideDuration: 2000,
                });
              }}
            />
          )}
          <GameLikeButton data={data} />
        </Box>

        {/* intro */}
        <Typography variant="body1" color="text.secondary" mt="20px">
          {data?.intro}
        </Typography>
        {/* genre */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} mt="32px">
          {data.genre_lists.map((genre, index) => (
            <Fragment key={genre}>
              <Typography variant="body3" color="text.secondary">
                {genre}
              </Typography>
              {index !== data.genre_lists.length - 1 && <DotDivider />}
            </Fragment>
          ))}
        </Box>
      </ContentContainer>
      {/* Play Button */}
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.gray[100],
          bottom: 0,
          padding: '8px 20px 20px',
          position: 'sticky',
          zIndex: 1,
        }}
      >
        <Button
          variant="ghost"
          fullWidth
          onClick={() => {
            setTrigger(true);
            setTimeout(() => {
              setTrigger(false);
            }, 3000);
            gxcTag('click', {
              area: 'detail',
              ui: 'play_now',
              parameters: { game_id: id },
            });
          }}
          size="largest"
        >
          Play Now On PC
        </Button>

        {trigger && (
          <Typography
            variant="body3"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: '3px',
              bottom: 76,
              left: 20,
              right: 20,
              padding: '12px 16px',
              position: 'absolute',
            }}
          >
            <strong>Boot up your PC!</strong> You can playtest these interesting
            <br />
            games and get rewards on your PC.
          </Typography>
        )}
      </Box>
    </Stack>
  ) : (
    <NotFound />
  );
}
