import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ReactElement } from 'react';

import { useGameClipsDetailBasicInfoAPI } from 'api/detail/detailAPI';
import { UserAvatar } from 'atoms';
import { LinkButton } from 'elements';
import { useDialog, useValidate } from 'hooks';
import circleIcon from 'images/icons/grey-circle.svg';
import { VideoClipDetailType } from 'types/ClipsTypes';
import { useLog } from 'utils/userLog';
import VideoClipLikeButton from 'views/detail/clips/detail/VideoClipLikeButton';
import PlayGameDialogBody from 'views/detail/dialog/PlayGameDialogBody';

interface FeaturedBottomProps {
  title: string;
  created_at: string;
  description: string;
  additional?: ReactElement;
}

interface StoreBotomProps {
  studio_name: string;
  created_at: string;
  title: string;
  gameLink: string;
  storeLink: string;
  area: string;
  gameId: number;
  productId: string;
}

/* Feature 일떄 */
export function FeatureBottom({ title, description, created_at, additional }: FeaturedBottomProps) {
  return (
    <Box p="20px 32px" display="flex" justifyContent={'space-between'}>
      <Box>
        <Typography variant="subtitle1" color="text.primary">
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          m={additional ? '20px 0px 0px 0px' : '20px 0'}
        >
          {description}
        </Typography>
        {additional}
        <Typography variant="subtitle2" color="text.secondary2">
          Uploaded on {created_at}
        </Typography>
      </Box>
    </Box>
  );
}

/* Store 일떄 */
export function StoreBottom({
  studio_name,
  title,
  created_at,
  gameLink,
  storeLink,
  area,
  gameId,
  productId,
}: StoreBotomProps) {
  const { gxcTag } = useLog();

  return (
    <Box p="20px 32px" display="flex" justifyContent={'space-between'}>
      <Box>
        <Box>
          <Typography variant="subtitle3" color="primary">
            {studio_name}
          </Typography>
          <Typography variant="subtitle1" color="text.primary" mt="8px">
            {title}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary2" mt="12px">
            Uploaded on {created_at}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column" gap="12px" width="166px">
          <LinkButton
            variant="ghost"
            to={gameLink}
            onClick={() => {
              gxcTag('click', {
                area: area,
                ui: 'game_page',
                parameters: { game_id: gameId },
              });
            }}
          >
            Go to the Game page
          </LinkButton>

          <LinkButton
            variant="contained"
            to={storeLink}
            onClick={() => {
              gxcTag('click', {
                area: area,
                ui: 'product_page',
                parameters: { product_id: productId },
              });
            }}
          >
            Go to the Store
          </LinkButton>
        </Box>
      </Box>
    </Box>
  );
}

interface TestingGameProps {
  area?: string;
  gameId: number;
  clipInfo: VideoClipDetailType;
  onLikeChange: (liked: boolean) => void;
}

/* 실제 Api 존재할 때 */
export function TestingGame({ area, gameId, clipInfo, onLikeChange }: TestingGameProps) {
  const { gxcTag } = useLog();

  return (
    <Box p="20px 32px" display="flex" justifyContent="space-between">
      <Box>
        <Box>
          <Typography variant="subtitle3" color="primary">
            {clipInfo?.game_title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.primary"
            mt="8px"
            display="flex"
            alignItems="center"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
            width="530px"
          >
            {clipInfo?.title}

            <Box ml="12px" component="span">
              <VideoClipLikeButton
                id={clipInfo?.id}
                liked={clipInfo.like_check}
                height={12}
                onLikeChange={onLikeChange}
              />
            </Box>
          </Typography>

          <Typography variant="subtitle2" color="text.secondary2" mt="12px">
            Uploaded on {format(new Date(clipInfo?.created_at), 'MMM d, yyyy')}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" columnGap="8px" mt="16px">
          <UserAvatar width={28} src={clipInfo?.streamer_url ?? ''} alt={clipInfo?.streamer_name} />
          <Box display="flex" alignItems="center" columnGap="4px" overflow="hidden">
            <Typography variant="small1" noWrap>
              {clipInfo?.streamer_name}
            </Typography>

            <img src={circleIcon} alt="circle" />
            <Typography variant="small1" color="text.secondary">
              {clipInfo?.country}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" mt="15px" flexDirection="column" gap="12px" width="166px">
        <LinkButton
          variant="ghost"
          to={`/detail/${gameId}/info`}
          onClick={() =>
            gxcTag('click', {
              area: area,
              ui: 'game_page',
              parameters: { game_id: gameId },
            })
          }
        >
          Go to the Game page
        </LinkButton>

        <PlayButtonArea gameId={gameId} area={area} />
      </Box>
    </Box>
  );
}

function PlayButtonArea({ gameId, area }: { gameId: number; area?: string }) {
  const { gxcTag } = useLog();
  const { data, mutate } = useGameClipsDetailBasicInfoAPI(gameId);
  const { showDialog } = useDialog();
  const { commonValidate } = useValidate();
  const detailData = data?.data;

  const handleClickPlayBtn = () => {
    gxcTag('click', {
      area: area,
      ui: 'play_now',
      parameters: { game_id: gameId },
    });
    if (commonValidate()) {
      setTimeout(() => {
        showDialog({
          title: 'It’s time to play!',
          body: <PlayGameDialogBody id={Number(gameId)} data={detailData} reload={mutate} />,
          width: 500,
        });
      }, 100);
    }
  };

  return (
    <>
      <Button fullWidth color="primary" variant="contained" onClick={handleClickPlayBtn}>
        Play Now
      </Button>
    </>
  );
}
