import { Box, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGamelistAPI } from 'api/gamelist/gamelistAPI';
import { ImageBox } from 'atoms';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';
import UpcomingSkeleton from './UpcomingSkeleton';

const UpcomingContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  paddingBottom: 80,
}));

const TextContainer = styled('div')(() => ({
  width: '100%',
  paddingLeft: 20,
  paddingRight: 20,
  marginBottom: 32,
}));

const StartDateWrap = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: 16,
  left: 16,
  width: 30,
  height: 40,
});

const DateDivider = styled('div')(({ theme }) => ({
  width: 30,
  marginTop: 4,
  marginBottom: 4,
  borderTop: `1px solid ${theme.palette.base.dimWhite}`,
}));

const PlayingNow: React.FC = () => {
  const { gxcTag } = useLog();
  const { isValidating, data, error, isLoading } = useGamelistAPI(GameStatusType.UPCOMING);

  const loadingSkeletonList = Array.from({ length: 8 }, (_, i) => i);
  const slideData = data && data.data && Array.isArray(data?.data) ? data.data : [];
  const navigate = useNavigate();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  return isDataNotExist ? null : (
    <UpcomingContainer>
      <TextContainer>
        <Typography variant="h4">Upcoming</Typography>
        <Typography variant="subtitle2" mt="8px" color="text.secondary">
          Play new games every week and provide <br />
          constructive feedback!
        </Typography>
      </TextContainer>

      <Swiper spaceBetween={16} style={{ paddingLeft: 20, paddingRight: 20 }} slidesPerView="auto">
        {isLoading
          ? loadingSkeletonList.map((_, index) => (
              <SwiperSlide key={index} style={{ width: 285, flexBasis: 285 }}>
                <UpcomingSkeleton />
              </SwiperSlide>
            ))
          : slideData.map((data) => (
              <SwiperSlide
                key={data.id}
                style={{ width: 285, flexBasis: 285 }}
                onClick={() => {
                  navigate(`/detail/${data?.id}`);
                  gxcTag('click', {
                    area: 'upcoming',
                    ui: 'game_card',
                    parameters: { game_id: data?.id },
                  });
                }}
              >
                <Box
                  sx={{
                    width: 285,
                    height: 184,
                    borderRadius: 2.5,
                    marginBottom: 2.5,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <ImageBox
                    src={data.thumbnail_url}
                    alt="upcoming thumbnail"
                    resize
                    resizeWidth={285}
                    resizeHeight={184}
                    overlay
                    sx={{ borderRadius: '5px' }}
                  />

                  <StartDateWrap>
                    <Typography variant="small1">
                      {format(new Date(data?.started_at), 'MMM')}
                    </Typography>
                    <DateDivider />
                    <Typography variant="small1">
                      {format(new Date(data?.started_at), 'd')}
                    </Typography>
                  </StartDateWrap>
                </Box>

                <Typography variant="subtitle1" mb="8px">
                  {data.title}
                </Typography>
                <Typography variant="subtitle3" color="text.secondary2">
                  {data.studio_name}
                </Typography>
              </SwiperSlide>
            ))}
      </Swiper>
    </UpcomingContainer>
  );
};

export default PlayingNow;
