import { createContext, ReactNode, useContext } from 'react';


export interface CommonFileType {
    folder: string;
    file_name: string;
    org_file_name: string;
    file_url: string;
  }

export interface EditorAppState {
  imageUploadHandler?: (files: File[]) => Promise<CommonFileType[]>;
  innerWidth?: number;
}

const EditorAppDispatchContext = createContext<EditorAppState | undefined>(undefined);

interface Props {
  children: ReactNode;
  imageUploadHandler?: (files: File[]) => Promise<CommonFileType[]>;
  innerWidth?: number;
}

export function EditorAppContext({ children, imageUploadHandler, innerWidth }: Props) {
  return (
    <EditorAppDispatchContext.Provider value={{ imageUploadHandler, innerWidth }}>
      {children}
    </EditorAppDispatchContext.Provider>
  );
}

export function useEditorAppContext() {
  const context = useContext(EditorAppDispatchContext);
  if (!context) throw new Error('EditorAppProvider not found');
  return context;
}
