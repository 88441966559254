import { GIcon, PopupButton, PopupMenuItem } from '@ground/ui';
import { Button, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLauncherDownloadRecordAPI } from 'api/launcher/launcherDownloadAPI';
import { useRecommendGameDialog } from 'hooks';
import { Dispatch, iRootState } from 'store';
import { launcherDownloadUrl } from 'utils/common';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import GnbGameGuideButton from './GnbGameGuideButton';
import GnbGPointText from './GnbGpointText';
import GnbNotification from './Notification/GnbNotification';

const LoginContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

export default function GnbLogin() {
  const { name, isLogin } = useSelector((state: iRootState) => state.login);
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { fetch } = useLauncherDownloadRecordAPI();
  const { showRecommendGameDialog } = useRecommendGameDialog();
  const { gxcTag } = useLog();

  const handleClickLogin = () => {
    navigate('/login', { state: { from: pathname + search } });
    eventTracker('gnb_login');
    gxcTag('click', { area: 'gnb', ui: 'signin' });
  };

  const handleClickSignUp = () => {
    navigate('/signup', { state: { from: pathname + search } });
    eventTracker('gnb_signup');
    gxcTag('click', { area: 'gnb', ui: 'signup' });
  };

  const handleClickDownload = async () => {
    // 런처 설치파일 다운로드
    window.open(launcherDownloadUrl, '_self', 'noopener noreferrer');

    // launcher download 횟수 기록
    fetch({});

    // button custom event
    eventTracker('gnb_download_launcher');

    // https://docs.google.com/spreadsheets/d/1ZkvA798GN0EIGMN1V-JJR9PxbnWlAuMiIDxzbQwRerU/edit#gid=2061408393
    // Reddit event tracking
    rdt?.('track', 'Custom', { customEventName: 'DownloadLauncher' });

    // Twitter single-event website tag code
    twttr?.conversion.trackPid('o8sfg', { tw_sale_amount: 0, tw_order_quantity: 0 });
    // google event tracking
    gtag('event', 'conversion', { send_to: 'AW-669277837/HdadCJ38nr4DEI29kb8C' });
    gxcTag('click', { area: 'gnb', ui: 'download_launcher' });
  };

  const handleClickRecommendGameBtn = useCallback(() => {
    gxcTag('click', { area: 'gnb', ui: 'user_recommend_a_game' });
    showRecommendGameDialog();
  }, [gxcTag, showRecommendGameDialog]);

  const menuList: PopupMenuItem[] = useMemo(
    () => [
      {
        label: <GnbGPointText />,
        value: 'gpoint',
        onClick: () => {
          gxcTag('click', { area: 'gnb', ui: 'user_gpoint' });
          navigate('/profile/gpoint');
        },
      },
      {
        label: 'Profile',
        value: 'profile',
        onClick: () => {
          gxcTag('click', { area: 'gnb', ui: 'user_profile' });
          navigate('/profile/profile');
        },
      },
      {
        label: 'Order',
        value: 'order',
        onClick: () => {
          gxcTag('click', { area: 'gnb', ui: 'user_order' });
          navigate('/profile/orders');
        },
      },
      {
        label: 'Recommend a Game',
        value: 'recommendGame',
        onClick: handleClickRecommendGameBtn,
      },
      { value: 0, divider: true },
      {
        label: 'Sign out',
        value: 'signout',
        onClick: () => {
          dispatch.login.logout();
          eventTracker('gnb_logout');
          gxcTag('click', { area: 'gnb', ui: 'user_signout' });
        },
      },
    ],
    [handleClickRecommendGameBtn, navigate, gxcTag, dispatch.login]
  );

  return (
    <LoginContainer>
      {isLogin && (
        <>
          <GnbGameGuideButton />
          <GnbNotification />
        </>
      )}

      {isLogin ? (
        <PopupButton
          startIcon={<GIcon iconName="profile" iconSize={16} />}
          buttonText={name ?? 'unknown'}
          width={166}
          menuList={menuList}
          onClick={() => gxcTag('click', { area: 'gnb', ui: 'user_name' })}
        />
      ) : (
        <Button
          color="dark"
          size="small"
          startIcon={<GIcon iconName="profile" iconSize={16} />}
          variant="contained"
          onClick={handleClickLogin}
        >
          Sign in
        </Button>
      )}
      {isLogin ? (
        <Button color="primary" size="small" variant="contained" onClick={handleClickDownload}>
          Download Launcher
        </Button>
      ) : (
        <Button color="primary" size="small" variant="contained" onClick={handleClickSignUp}>
          Sign up
        </Button>
      )}
    </LoginContainer>
  );
}
