import { Box, Divider, Stack, Typography } from '@mui/material';
import { useQueryDispatch, useQueryState } from 'context';

interface CommonStepProps {
  step: number;
  supporterApplyStep: number;
  text: string;
}

/** Step 영역 */
export default function SupporterApplyStepper() {
  const { step } = useQueryState<{ step: number }>();

  return (
    <Stack
      display="flex"
      alignItems="center"
      flexDirection="column"
      mb="20px"
      sx={{
        background: (theme) => theme.palette.gray[80],
      }}
    >
      <Box
        display="flex"
        sx={{
          textAlign: 'center',
          width: '1200px',
          height: '106px',
          padding: '0 40px',
          gap: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CommonStep step={1} supporterApplyStep={step} text="Supporter Details" />

        <Divider
          sx={{
            margin: '60px 0',
            width: '100px',
            position: 'relative',
            border: (theme) =>
              `1px solid ${
                Number(step) > 1 ? theme.palette.base.dimWhite : 'rgba(255, 255, 255, 0.05)'
              }`,
          }}
        />

        <CommonStep step={2} supporterApplyStep={step} text="Payment Information" />

        <Divider
          sx={{
            margin: '60px 0',
            width: '100px',
            position: 'relative',
            border: (theme) =>
              `1px solid ${
                Number(step) > 2 ? theme.palette.base.dimWhite : 'rgba(255, 255, 255, 0.05)'
              }`,
          }}
        />

        <CommonStep step={3} supporterApplyStep={step} text="Non-Disclosure / GDPR Agreement" />

        <Divider
          sx={{
            margin: '60px 0',
            width: '100px',
            position: 'relative',
            border: (theme) =>
              `1px solid ${
                Number(step) > 3 ? theme.palette.base.dimWhite : 'rgba(255, 255, 255, 0.05)'
              }`,
          }}
        />

        <CommonStep step={4} supporterApplyStep={step} text="Application Completed" />
      </Box>
    </Stack>
  );
}

function CommonStep({ step, supporterApplyStep, text }: CommonStepProps) {
  const { changeQuery } = useQueryDispatch();

  const changeRouter = (step: number) => {
    if (process.env.NODE_ENV !== 'production') {
      changeQuery({ step: step });
    }
  };

  return (
    <Box
      onClick={() => changeRouter(step)}
      width="145px"
      height="50px"
      position="relative"
      top="-10px"
    >
      <Typography
        variant="h5"
        mb="5px"
        color={supporterApplyStep > step - 1 ? 'text.primary' : 'text.secondary2'}
        sx={{
          opacity: supporterApplyStep > step - 1 ? 1 : 0.5,
        }}
      >
        0{step}
      </Typography>
      <Typography
        variant="subtitle3"
        color={Number(supporterApplyStep) === Number(step) ? 'text.primary' : 'text.secondary2'}
        sx={{
          opacity: Number(supporterApplyStep) === Number(step) ? 1 : 0.5,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
