import { Box, Divider } from '@mui/material';
import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import { TextEditor } from '@ground/text-editor';

import { useDialog, useValidate } from 'hooks';
import eventTracker from 'utils/eventTracker';

import { FloatingWrapper } from './FloatingWrapper';
import { useGStoreDetailData } from './GStoreDetailProvider';
import ProductDescription from './ProductDescription';
import ProductPanel from './ProductPanel';
import ContactBanner from './ContactBanner';
import PaymentWidget from './PaymentWidget';
import ProductSlide from './ProductSlide';
import { OProductStatus } from 'api/gstore';
import { ProductImages } from './ProductImages';

export default function GStoreDetailPage() {
  const { data, setShippingInfoOpen, userPointData, gpointSummaryData, summaryMutate } =
    useGStoreDetailData();
  const { commonValidate } = useValidate();
  const { showDialog } = useDialog();

  const productSaleStatus = data?.data?.status;
  const userPoint = gpointSummaryData?.data?.available_point;
  const isAlreadyBoughtProductInCurrentPromotion = !!userPointData?.data?.promotion_order_count;
  const productPoint = data?.data?.point;
  // 잔액 부족 체크
  const isPointLack = isNumber(userPoint) && isNumber(productPoint) && userPoint < productPoint;
  const isDisabledButton =
    isPointLack || productSaleStatus !== OProductStatus.ENABLED || data?.data?.avail_count === 0;

  const orderButtonClickHandler = useCallback(() => {
    eventTracker('gstore_product_order', { event_label: data?.data.sale_product_id ?? '' });

    // 주문 가능 여부 체크
    if (!commonValidate()) return;

    if (isAlreadyBoughtProductInCurrentPromotion) {
      showDialog({
        title: 'Order Limit Reached',
        body: (
          <>
            You have already placed an order during this Purchase Period, and therefore, additional
            orders are not possible.
            <br />
            (Please refer to the top of the product page for Purchase Period details.)
          </>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    // 주문 정보 입력 페이지 오픈
    setShippingInfoOpen && setShippingInfoOpen(true);
  }, [
    commonValidate,
    data?.data.sale_product_id,
    isAlreadyBoughtProductInCurrentPromotion,
    setShippingInfoOpen,
    showDialog,
  ]);

  return (
    <Box component="section" p="80px 0px 200px" width="1200px">
      <ProductSlide contents={data?.data?.top_contents || []} />
      <Box width="1200px" position="relative">
        <FloatingWrapper>
          <PaymentWidget
            isDisabledButton={isDisabledButton}
            orderButtonClickHandler={orderButtonClickHandler}
            orderButtonLabel={
              productSaleStatus === OProductStatus.ENABLED // SALE_ENABLEDD의 경우
                ? typeof data?.data?.avail_count === 'number' && data?.data?.avail_count > 0
                  ? 'Order Now' // 남은 판매 가능 수량이 있는 경우
                  : 'Sold Out' // 판매 가능 수량이 0인 경우
                : productSaleStatus === OProductStatus.UPCOMING
                ? 'Coming Soon' // 판매 예정
                : productSaleStatus === OProductStatus.SOLD_OUT
                ? 'Sold Out' // SALE_SOLD_OUT의 경우
                : productSaleStatus === OProductStatus.DISABLED
                ? 'Not Available' // SALE_DISABLED의 경우
                : 'Unknown'
            }
            isPointLack={isNumber(userPoint) && isNumber(productPoint) && userPoint < productPoint}
            userPoint={userPoint}
            productPoint={productPoint}
            onComplete={summaryMutate}
            isDisplayPrice={!data?.data?.point_no_display}
            isOrderAvailable={data?.data.promotion?.status === 'IN_PROGRESS'}
          />
        </FloatingWrapper>
        <Box maxWidth="1200px">
          <ProductPanel
            title={data?.data?.name}
            summary={data?.data?.summary}
            startAt={data?.data?.promotion?.started_at}
            endedAt={data?.data?.promotion?.ended_at}
            deliveryAt={data?.data?.promotion?.delivery_at}
          />
          {/* <MyButtonComponent /> */}
          {data?.data?.editor_content && (
            <TextEditor
              readOnly
              initialEditorState={data?.data?.editor_content}
              innerWidth={1200}
            />
          )}
        </Box>

        {data?.data?.editor_content && (
          <Divider sx={{ marginTop: '250px', marginBottom: '120px' }} />
        )}

        <ProductDescription description={data?.data?.description} />
      </Box>
      <ProductImages contents={data?.data?.detail_contents || []} />

      <ContactBanner />
    </Box>
  );
}
