import SectionContainer from 'components/SectionContainer/SectionContainer';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';
import HomeBestGamesListSmallContainer from './HomeBestGamesListSmallContainer';

export default function HomeBestGames() {
  const { gxcTag } = useLog();

  return (
    <SectionContainer title="Best of G.Round">
      <HomeBestGamesListSmallContainer
        cardtype={GameStatusType.BESTGAMES}
        count={4}
        name="best-game"
        likeButton
        onClickCard={(game_id) =>
          gxcTag('click', { area: 'best_games', ui: 'game_card', parameters: { game_id } })
        }
        onLikeClicked={({ liked, id }) =>
          gxcTag('click', {
            area: 'best_games',
            ui: 'like',
            parameters: { like_check: liked ? 1 : 0, game_id: id },
          })
        }
      />
    </SectionContainer>
  );
}
