import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, Typography } from '@mui/material';
import format from 'date-fns/format';
import isPlainObject from 'lodash-es/isPlainObject';
import { useEffect, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetReviewSurveyState, usePrivateReviewRegisterAPI } from 'api/review/reviewAPI';
import { useDialog, useError } from 'hooks';
import { iRootState } from 'store';
import { ReviewFormData } from 'types/FormDataType';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from '../DetailProvider';
import { useImportDraftData } from '../ImportDraftProvider';
import ReviewPaper from './ReviewPaper';
import { useReviewData } from './ReviewProvider';

export default function PrivateReviewForm() {
  const { showSimpleDialog, showDialog } = useDialog();
  const { showError } = useError();
  const { id = '' } = useParams<{ id: string }>();
  const { data, mutate } = useReviewData();
  const { shouldImportReviewDraftData, setShouldImportReviewDraftData } = useImportDraftData();
  const { data: reviewSurveyData, mutate: reviewStateReload } = useGetReviewSurveyState(id);
  const reviewState = reviewSurveyData?.data?.review_status;
  const { setOpenReview } = useGetDetailData();
  const { identifier } = useSelector((state: iRootState) => state.login);
  const [hasUserInput, setHasUserInput] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useBeforeunload((event) => {
    event.preventDefault();
  });

  const localStorageKey = `draft-${identifier}-${id}-review`;

  const savedDraftDataInLocalStorage = localStorage.getItem(localStorageKey) ?? '{}';

  const [draftData, setDraftData] = useState<any[]>(JSON.parse(savedDraftDataInLocalStorage));

  const savedTimeInDraft =
    draftData && Array.isArray(draftData) && draftData.length > 1
      ? new Date((draftData[1] as { time: Date }).time)
      : null;

  const [savedTime, setSavedTime] = useState<Date | null>(savedTimeInDraft);
  const defaultValues: ReviewFormData = useMemo(
    () => ({
      essential:
        data?.essential_questions.map((essential) => ({
          id: essential.id,
          value: essential.answer?.toString() ?? '',
          opinion: essential.opinion,
        })) ?? [],
      optional:
        data?.optional_questions.map((optional) => ({
          id: optional.id,
          value: optional.answer ?? '',
          agree: true,
        })) ?? [],
    }),
    [data?.essential_questions, data?.optional_questions]
  );

  const { isLoading: submitLoading, fetch: submitPrivateApi } = usePrivateReviewRegisterAPI(id);
  const methods = useForm<ReviewFormData>({ defaultValues, reValidateMode: 'onSubmit' });

  const dirtyFields = methods?.formState?.dirtyFields;
  const isDirty = isPlainObject(dirtyFields) && Object.keys(dirtyFields)?.length > 0;

  // Review Submit
  const onSubmit = (formData: ReviewFormData) => {
    checkOptionalTextLength(formData);
  };

  // 리뷰 글자 수 체크 후 submit 여부 확인
  const checkOptionalTextLength = (formData: ReviewFormData) => {
    // 10글자 미만 체크
    const isReviewsHasLessThan10 = formData?.optional?.some(
      (input) => input?.value && input.value.trim().length < 10
    );

    // 프라이빗 10 미만일때
    if (isReviewsHasLessThan10) {
      showDialog({
        title: 'Leave a Detailed Review',
        body: `The studio values your feedback! Please ensure your review is at least 10 characters long to help improve the game with your insights.`,
        primaryButton: {
          text: 'OK',
          ButtonProps: {
            color: 'private',
            variant: 'contained',
          },
        },
      });
      return;
    }

    // 모든 조건 만족한 경우
    submit(formData);
    // 데이터를 전송한 뒤, 임시 저장된 값을 지워준다.
    localStorage.removeItem(localStorageKey);
    // 저장값을 불러오주는 기능을 off 한다.
    setShouldImportReviewDraftData(false); // TODO : onClose 로 빼야함
  };

  // formdata submit
  const submit = async (formData: ReviewFormData) => {
    const essentialList =
      formData.essential?.map((essential) => ({
        question_id: essential.id,
        type: 'POINT',
        answer: essential.value,
        opinion: essential.opinion,
      })) || [];

    const optionalList =
      formData.optional?.map((optional) => ({
        question_id: optional.id,
        type: 'ANSWER',
        answer: optional.value,
        agree: optional.agree ?? false,
      })) || [];

    const response = await submitPrivateApi({ answers: [...essentialList, ...optionalList] });

    if (response.status === 200) {
      // 에러 코드 처리 변경 필요
      if (response.data.result?.code === 200) {
        setOpenReview(false);
        setIsSubmitted(true);
        eventTracker(`review_${reviewSubmitted ? 'update' : 'submit'}`, { event_label: id });
        mutate && mutate();

        reviewStateReload();

        showDialog({
          title: 'Success',
          body: 'Your review has been sent.',
          primaryButton: {
            text: 'OK',
            ButtonProps: {
              color: 'private',
              variant: 'contained',
            },
          },
          onClose: () => localStorage.removeItem(localStorageKey),
        });
      } else if (response.data.result_code === '401') {
        if (response.data.data.error_code.code === 'U001') {
          // showDialog({
          //   title: 'Alert',
          //   body: `Your profile is incomplete. Head over to your profile page and connect to Facebook. Don't forget to add any other missing information, like country and gender!`,
          //   primaryButton: {
          //     text: 'Go to Profile',
          //     ButtonProps: {
          //       color: isPrivate ? 'private' : 'primary',
          //       variant: 'contained',
          //     },
          //     callback: () => {
          //       navigate('/profile/profile');
          //       eventTracker('alertpopup_goto_profile');
          //       twttr?.conversion.trackPid('o91dr', { tw_sale_amount: 0, tw_order_quantity: 0 });
          //       gtag('event', 'conversion', { send_to: 'AW-669277837/ySe-CMidsMQDEI29kb8C' });
          //     },
          //   },
          //   secondaryButton: { text: 'Close' },
          // });
        } else if (response.data.data.error_code.code === 'P003') {
          showSimpleDialog('Error', 'Failed to earn point.');
        }
      } else if (response.data.result_code === '402') {
        showSimpleDialog('Error', 'Reward does not exist.');
      } else {
        showError(response);
      }
    } else {
      showError(response);
    }
  };

  useEffect(() => {
    const dirtyFields = methods.formState.dirtyFields;
    setHasUserInput(Object.keys(dirtyFields).length > 0);
  }, [methods.formState.dirtyFields]);

  useEffect(() => {
    const interval = hasUserInput
      ? setInterval(() => {
          const currentTime = new Date();
          const formData = JSON.stringify([methods.getValues(), { time: currentTime }]);
          // 값을 임시 저장한다.
          if (isDirty) {
            localStorage.setItem(localStorageKey, formData);
            setDraftData([methods.getValues(), { time: currentTime }]);
            // 현재 시간을 표시하여 준다.
            setSavedTime(currentTime);
            // 저장이 되었으면 10초마다 자동저장 기능을 중단한다.
          }
        }, 10000)
      : null;

    return interval ? () => clearInterval(interval) : () => {};
  }, [hasUserInput, isDirty, localStorageKey, methods]);

  useEffect(() => {
    if (draftData && shouldImportReviewDraftData) {
      methods.setValue('essential', draftData[0].essential);
      methods.setValue('optional', draftData[0].optional);
    }
  }, [draftData, shouldImportReviewDraftData, methods]);

  useEffect(() => {
    // 모달이 닫힐 때, 값을 임시저장
    return () => {
      if (!isSubmitted) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify([methods.watch(), { time: new Date() }])
        );
      }
    };
  }, [isSubmitted, localStorageKey, methods]);

  // 리뷰 제출 전과 완료 상태 확인
  const reviewSubmitted = useMemo(() => reviewState?.complete, [reviewState]);

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        {reviewSubmitted ? (
          <Typography color="text.secondary" variant="body2" marginBottom="20px">
            You have already submitted a review. If you want to change your review, please click the
            [Update] button at the bottom of this page.
          </Typography>
        ) : (
          <Typography color="text.secondary" variant="body2" marginBottom="20px">
            Interested in writing better reviews and getting picked more often? Then check out these
            guides on <br />
            <Link
              href="https://gameround.co/blog/156"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              ‘How to write a video game review?’
            </Link>{' '}
            and How to{' '}
            <Link
              href="https://gameround.co/blog/155"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              ‘Become the Ultimate Playtester’
            </Link>
          </Typography>
        )}

        <ReviewPaper />

        {/* Submit Button Area */}
        <Box
          marginTop="40px"
          display="flex"
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gap="2px">
            {hasUserInput && savedTime && (
              <>
                <Typography variant="button3" color="text.secondary2">
                  Last auto-saved :
                </Typography>
                <Typography variant="button3" color="text.secondary">
                  {format(savedTime, 'MMM dd, yyyy HH:mm:ss')}
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" gap="8px">
            <Button onClick={() => setOpenReview(false)} variant="ghost" size="large">
              Cancel
            </Button>
            <LoadingButton
              loading={submitLoading}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              {reviewSubmitted ? 'Update' : 'Submit'}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
