import { GroundLogo } from '@ground/ui';
import { AppBar, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { GnbItem, GnbItemType, GnbLogin } from 'components/Gnb';
import TopLineBanner from 'elements/TopLineBanner';
import { Dispatch } from 'store';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

const GnbContainer = styled(AppBar)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.base.black,
  borderRadius: 'unset',
}));

const GnbMenuContainer = styled('div')(() => ({
  display: 'flex',
  height: 56,
  width: 1200,
  justifyContent: 'space-between',
}));

const GnbLogoContainer = styled('div')(() => ({
  display: 'flex',
}));

const GnbLogoLink = styled(Link)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GnbList = styled('div')(() => ({
  display: 'flex',
  marginLeft: 76,
  alignItems: 'center',
}));

interface Props {
  items: GnbItemType[];
  isSign: boolean;
}

function Gnb({ items, isSign }: Props) {
  const { pathname } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const { gxcTag } = useLog();

  const exceptionUrl = pathname.match('/recruitment') || pathname.match('/supporter-recruitment');

  const handleClickSignGnbLogo = () => {
    // 페이지 이탈 시 로그아웃
    isSign && dispatch.login.logout();
    gxcTag('click', { area: 'gnb', ui: 'logo' });
    eventTracker('gnb_logo');
  };

  const handleClickLogo = () => {
    gxcTag('click', { area: 'gnb', ui: 'logo' });
    eventTracker('gnb_logo');
  };

  return (
    <GnbContainer id="gnb-container" position="sticky" elevation={0}>
      {isSign || exceptionUrl ? (
        <GnbLogoContainer sx={{ height: 50 }}>
          <GnbLogoLink to="/" onClick={handleClickSignGnbLogo}>
            <GroundLogo colorType="light" />
          </GnbLogoLink>
        </GnbLogoContainer>
      ) : (
        <>
          <TopLineBanner />
          <GnbMenuContainer>
            <GnbLogoContainer>
              <GnbLogoLink to="/" onClick={handleClickLogo}>
                <GroundLogo colorType="light" />
              </GnbLogoLink>
              <GnbList>
                {items.map((item) => (
                  <GnbItem key={item.text} {...item} />
                ))}
              </GnbList>
            </GnbLogoContainer>
            <GnbLogin />
          </GnbMenuContainer>
        </>
      )}
    </GnbContainer>
  );
}

export default Gnb;
