import { Components } from '@mui/material';

const AlertTheme: Components = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        error: (
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="11" fill="black" fillOpacity="0.2" />
            <path
              d="M11.1381 6.5C11.2153 6.5 11.2924 6.5 11.37 6.5C11.8994 6.65942 12.1722 7.00665 12.1403 7.46704C12.1037 7.9943 12.0714 8.52156 12.0427 9.04922C11.988 10.047 11.9372 11.0447 11.8844 12.0421C11.8651 12.4033 11.6018 12.6662 11.2554 12.6716C10.8929 12.6771 10.6083 12.4263 10.589 12.0608C10.5434 11.1979 10.5075 10.3351 10.4678 9.47188C10.4398 8.86646 10.4257 8.26027 10.3812 7.65601C10.3324 7.00471 10.5323 6.67925 11.1381 6.5Z"
              fill="white"
            />
            <path
              d="M10.25 14.9813C10.3496 14.5081 10.6117 14.1943 11.1046 14.1088C11.6332 14.017 12.141 14.3794 12.2343 14.9133C12.3303 15.4639 11.98 15.9717 11.4506 16.0809C11.0097 16.1719 10.3823 15.9332 10.25 15.2131C10.25 15.1361 10.25 15.0587 10.25 14.9813Z"
              fill="white"
            />
          </svg>
        ),
      },
    },
    styleOverrides: {
      root: {
        paddingLeft: 20,
        paddingTop: 2,
        paddingBottom: 2,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0,
        color: '#DDE2E8',
        [`& .MuiAlert-icon`]: {
          marginRight: '8px',
        },
      },
      standardError: {
        backgroundColor: '#E94040',
      },
    },
  },
};

export default AlertTheme;
