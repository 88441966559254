import { useSelector } from 'react-redux';
import { iRootState } from 'store';

import useSWRFetch, { useSWRInfiniteFetch } from 'api/useSWRFetch';
import { EventBadgeType } from 'types/EventBadgeType';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';

export function useGamelistAPI(cardtype: GameStatusType, includeSurveyGame: boolean = false) {
  const url = `${cardtype === 'TESTING' ? 'v2/' : ''}games/${cardtype}${
    includeSurveyGame ? '?&include_survey_game=true' : ''
  }`;

  return useSWRFetch<GameCardProps[]>({ key: url, useToken: true });
}

export function useClosedGamelistAPI(
  sort: string = 'createdAt',
  size: number,
  search_keyword: string,
  getTotalCount: boolean = false // total count 정보를 데이터에 포함할지 유무
) {
  const basicKey = `games/CLOSED?sort=${sort}&size=${size}&search_keyword=${search_keyword}&get_count=${getTotalCount}`;

  return useSWRInfiniteFetch<GameCardProps[]>({
    getKey: (_index, previousPageData) => {
      if (previousPageData) {
        if (previousPageData.next_page?.has_next_page) {
          return `${basicKey}&next_value=${previousPageData?.next_page?.next_values}`;
        } else {
          return null;
        }
      } else {
        return basicKey;
      }
    },
    useToken: true,
  });
}

export function useGameEventBadgeListAPI() {
  const url = 'game/badge/list';
  return useSWRFetch<EventBadgeType[]>({ key: url });
}

/**
 *
 * @returns Private 게임 List
 */
export function usePrivateGameListAPI() {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const url = isLogin ? 'games/list/privatetest' : null;

  return useSWRFetch<GameCardProps[]>({
    key: url,
    useToken: true,
  });
}
