import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Carousel, GameCardSmall } from 'elements';
import { GameCardProps } from 'types/GameCardTypes';

interface GameListSmallSlideProps {
  sliderData?: GameCardProps[];
  name: string;
  fillHeight?: boolean;
}

export default function GameListSmallSlide({
  sliderData,
  name,
  fillHeight,
}: GameListSmallSlideProps) {
  //slider
  const swiperParams: SwiperOptions = {
    pagination: false,
    spaceBetween: 20,
    slidesPerGroup: 4,
    slidesPerView: 4,
    allowTouchMove: false,
    simulateTouch: false,
    touchRatio: 0,
  };

  return (
    <Carousel {...swiperParams} hasNavigation width={1200} navigationPositionTop={92} name={name}>
      {sliderData?.map((content) => (
        <SwiperSlide
          key={content.id}
          style={{ width: '1200px', display: 'flex', paddingBottom: '16px' }}
        >
          <GameCardSmall {...content} fillHeight={fillHeight} />
        </SwiperSlide>
      ))}
    </Carousel>
  );
}
