import { GCheckbox } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { useQueryDispatch } from 'context';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import WebFont from 'webfontloader';
import { suppoterApplyContents } from '../../suppoterApplyContents';

export default function SupporterApplyStep3Content({ isLoading }: { isLoading: boolean }) {
  const { getValues } = useFormContext();
  const { changeQuery } = useQueryDispatch();
  const [isShow, setIsShow] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    WebFont.load({
      google: {
        families: ['La Belle Aurore'],
      },
    });
  }, []);

  return (
    <Box>
      <Box
        mb="60px"
        p="40px"
        sx={{
          userSelect: 'none',
          background: (theme) => theme.palette.gray[100],
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          alert('Prevent right-click');
        }}
      >
        <Box p="60px" sx={{ background: 'white' }} height="1400px" overflow="auto">
          <Typography variant="h5" color="gray.100" mb="40px">
            Non-Disclosure and Confidentiality Agreement
          </Typography>
          <Typography variant="body1" color="gray.100" mb="40px">
            This Nondisclosure Agreement (the “Agreement”) is entered into by and between{' '}
            <Typography fontWeight={700} component="span">
              G.Round Inc.
            </Typography>{' '}
            (“
            <Typography color="primary.main" component="span">
              Disclosing Party
            </Typography>
            “) andand User{' '}
            <Typography fontWeight={700} component="span">
              {getValues('fullname')}
            </Typography>{' '}
            (“
            <Typography color="primary.main" component="span">
              Receiving Party
            </Typography>
            “) for the purpose of preventing the unauthorized disclosure of Confidential Information
            as defined below. The parties agree to enter into a confidential relationship with
            respect to the disclosure of certain proprietary and confidential information
            (“Confidential Information”).
          </Typography>

          <Box type="1" component="ol" pl="30px">
            {suppoterApplyContents.map((text, index) => (
              <Box mb="28px" key={index}>
                <Typography variant="body1" color="gray.100" component="li">
                  <Typography variant="body1" color="primary.main" component="span">
                    {text?.title}
                  </Typography>
                  {` `}
                  <Typography variant="body1" color="gray.100" component="span">
                    {text?.content}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography variant="body1" color="gray.100" mb="80px">
            This Agreement and each party’s obligation shall be binding on the representatives,
            assigns and successors of such party. Each party has signed this Agreement through its
            authorized representative.
          </Typography>

          <Box mb="80px">
            <Typography variant="h5" color="gray.100" mb="20px">
              当事者を開示します
            </Typography>
            <Box display="flex" mb="20px">
              <Typography variant="subtitle1" color="primary.main">
                G.Round Inc.
              </Typography>
              <Typography variant="subtitle1" color="gray.20" m="0 12px">
                |
              </Typography>
              <Typography variant="subtitle1" color="primary.main">
                James Choi Chief Business Officer
              </Typography>
            </Box>

            <Box display="flex" gap="55px" mb="20px">
              <Typography variant="body1" color="gray.100">
                署名:
              </Typography>
              <Box display="flex" position="relative">
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  James Choi
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                日付:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(new Date(), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" color="gray.100" mb="20px">
              受信側です
            </Typography>
            <Typography variant="subtitle1" color="primary.main" mb="20px">
              {getValues('fullname')}
            </Typography>

            <Box display="flex" gap="55px" mb="20px">
              <Typography variant="body1" color="gray.100">
                署名:
              </Typography>
              <Box
                display="flex"
                position="relative"
                width="400px"
                justifyContent={'space-between'}
              >
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {isShow ? getValues('fullname') : null}
                </Typography>

                {!isShow ? (
                  <Button
                    onClick={() => setIsShow((value) => !value)}
                    variant="contained"
                    size="tiny"
                    sx={{ position: 'absolute', bottom: 8, right: '10px', zIndex: 9 }}
                  >
                    <Typography variant="button3">署名します</Typography>
                  </Button>
                ) : null}

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                日付:
              </Typography>

              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {isShow ? `${format(new Date(), 'MM/dd/yyyy')}` : null}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mb="40px">
        <Typography variant="subtitle1" mb="20px">
          GDPR 契約です
        </Typography>

        <Typography variant="body2" color="text.secondary">
          支援プログラムは、Gによって提供されています。ラウンドは、あなたのプライバシーを大切にするプライベートプレイテストサービスです。
          一般データ保護規則 （GDPR）および当社のプライバシーポリシー {` `}
          <Link href="/privacyPolicy.pdf?v=20230515" target="_blank">
            (https://gameround.co/privacyPolicy.pdf?v=20230515)
          </Link>
          , ） に従い、特定の個人データを収集および処理する前に、お客様の明示的な同意が必要です。The
          Supporters Program
          に参加することにより、お客様は当社のプライバシーポリシーに従って提供するサービスに参加するためのお客様のデータの収集と処理に同意するものとします。
          これには、お名前、Eメールアドレス、年齢、居住国などの情報が含まれますが、これに限定されません。これに同意することにより、お客様は上記の内容を読んで理解したことを認め、お客様の個人データの収集および処理に同意するものとします。
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" marginTop="12px">
        <GCheckbox value={check} onChange={() => setCheck((value) => !value)} />
        <Typography variant="body2" color="text.primary" ml="8px">
          同意します
        </Typography>
      </Box>

      <Divider sx={{ margin: '60px 0 40px' }} />

      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => changeQuery({ step: 2 })} variant="ghost" size="large">
          前のページです
        </Button>

        <LoadingButton
          disabled={!isShow || !check}
          type="submit"
          variant="contained"
          size="large"
          loading={isLoading}
        >
          適用します
        </LoadingButton>
      </Box>
    </Box>
  );
}
