import { EventBadgeNew, VideoBox } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import playIcon from 'images/icons/ico_play.svg';
import { MouseEvent } from 'react';

import { ImageBox } from 'atoms';
import { Dispatch, iRootState } from 'store';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';
import { useMobileVideoDialog } from 'hooks';

const CardImgRoot = styled('div')<{ fullWidth?: boolean; isSmall?: boolean }>(
  ({ fullWidth, isSmall }) => ({
    position: 'relative',
    width: fullWidth ? '100%' : 285,
    height: fullWidth ? undefined : isSmall ? 184 : 323,
    borderRadius: '5px 5px 0px 0px',
    aspectRatio: fullWidth ? '335/360' : undefined,
  })
);

const EventTagWrap = styled('div')({
  position: 'absolute',
  left: 12,
  top: -2,
  display: 'flex',
  height: '52px',
});

const StartDateWrap = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: 16,
  left: 16,
  width: 30,
  height: 40,
});

const DateDivider = styled('div')(({ theme }) => ({
  width: 30,
  marginTop: 4,
  marginBottom: 4,
  borderTop: `1px solid ${theme.palette.base.dimWhite}`,
}));

interface Props extends GameCardProps {
  gameId?: number;
  havePlayBtn?: boolean;
  fullWidth?: boolean;
  size?: 'medium' | 'small';
}

export default function GameCardMobileUpperArea({
  state = GameStatusType.TESTING,
  started_at,
  thumbnail_url,
  thumbnail_video_url,
  hoverVideoPlay,
  event_badge,
  survey_opened,
  isHover = false,
  isPrivateShow,
  remainingText,
  gameId,
  havePlayBtn,
  fullWidth,
  size,
}: Props) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const { mute } = useDispatch<Dispatch>();
  const muted = useSelector((state: iRootState) => state.mute);
  const { showVideoDialog } = useMobileVideoDialog();
  const isSmall = size === 'small';

  const handleClickPlayVideo = (url: string) => (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    e.preventDefault();
    showVideoDialog(url);
  };

  const handleClickMute = () => {
    mute.toggleMute();
  };

  return (
    <CardImgRoot ref={ref} fullWidth={fullWidth} isSmall={isSmall}>
      {inView && thumbnail_url && (
        <Box
          sx={{
            overflow: 'hidden',
            borderRadius: '5px 5px 0px 0px',
            width: fullWidth ? '100%' : undefined,
            aspectRatio: fullWidth ? '335/360' : isSmall ? '285/184' : undefined,
          }}
        >
          <ImageBox
            resize
            resizeWidth={fullWidth ? (isSmall ? 285 : 335) : 285}
            resizeHeight={fullWidth ? (isSmall ? 184 : 360) : isSmall ? 184 : 323}
            style={{
              transition: 'filter .3s, all 0.2s ease-in ',
              filter: isHover ? 'brightness(1.4)' : '',
              transform: isHover ? 'scale(1.1)' : '',
              borderRadius: '5px 5px 0px 0px',
              width: fullWidth ? '100%' : undefined,
              height: fullWidth ? '100%' : undefined,
              objectFit: fullWidth ? 'cover' : undefined,
            }}
            src={thumbnail_url}
            alt={fullWidth ? 'game-card-mobile' : 'thumbnail'}
          />
        </Box>
      )}

      {(state === GameStatusType.TESTING || state === GameStatusType.SURVEY) &&
        event_badge &&
        (hoverVideoPlay && thumbnail_video_url ? !isHover : true) && (
          <EventTagWrap>
            <EventBadgeNew {...event_badge} />
          </EventTagWrap>
        )}

      {state === GameStatusType.UPCOMING && (
        <StartDateWrap>
          <Typography variant="small1" color="text.primary">
            {format(new Date(started_at), 'MMM')}
          </Typography>
          <DateDivider />
          <Typography variant="small1" color="text.primary">
            {format(new Date(started_at), 'd')}
          </Typography>
        </StartDateWrap>
      )}
      {hoverVideoPlay && thumbnail_video_url && !isPrivateShow && (
        <Box position="absolute" top={0} left={0}>
          <VideoBox
            isHover={isHover}
            muted={muted}
            onClickMute={handleClickMute}
            width="285px"
            height={isSmall ? '184px' : '323px'}
            src={thumbnail_video_url}
            sx={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px' }}
          />
        </Box>
      )}
      {thumbnail_video_url && havePlayBtn && (
        <img
          src={playIcon}
          alt="play icon"
          width={32}
          height={32}
          style={{
            position: 'absolute',
            right: '16px',
            bottom: '16px',
          }}
          onClick={handleClickPlayVideo(thumbnail_video_url)}
        />
      )}
    </CardImgRoot>
  );
}
