import { useEffect, useMemo } from 'react';

import { useAxios } from 'hooks';
import AuthGroundWithFacebook from './AuthGroundWithFacebook';

export interface MeData {
  id: string;
  name: string;
  email?: string;
  picture?: {
    data?: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

export default function AuthFacebookMe() {
  const searchParams = useMemo(() => new URLSearchParams(window.location.hash.substring(1)), []);

  const accessToken = searchParams.get('access_token');
  const state = searchParams.get('state');

  // 페이스북 유저 정보 가져오기
  const { error: meError, data: meData } = useAxios(
    `https://graph.facebook.com/v13.0/me?fields=id,name,email,picture&access_token=${accessToken}`
  );

  useEffect(() => {
    // 에러가 발생한 경우 런처로 redirect
    if (meError && state) {
      window.location.replace(
        `${window.atob(decodeURIComponent(state))}?&error=${meError.message}&state=${state}`
      );
      console.log(meError.message);
    }
  }, [meError, state]);

  return accessToken && state && meData ? (
    <AuthGroundWithFacebook
      accessToken={accessToken}
      state={state}
      data={meData as unknown as MeData}
    />
  ) : (
    <p>Error</p>
  );
}
