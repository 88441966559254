import { Breakpoint, ButtonProps } from '@mui/material';
import { createContext, MouseEvent, ReactElement, ReactNode, useCallback, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';

import { MobileModalDialog, ModalDialog } from 'elements';

export interface DialogActionButtonProps {
  /** 버튼명 */
  text?: string;
  /** 버튼 클릭 시 실행되는 함수. `false`를 리턴하면 다이얼로그가 닫히지 않는다. */
  callback?: () => boolean | void | Promise<boolean | void>;
  /** callback 함수가 실행되는 동안 로딩이 필요한 버튼인 경우 `true` */
  isLoadingButton?: boolean;
  /** 버튼의 속성 값들을 변경할 수 있는 함수 */
  ButtonProps?: ButtonProps;
}

export interface DialogState {
  /** 다이얼로그 노출 상태 `true`이면 보여진다. */
  open: boolean;
  /** 다이얼로그 상단 영역 */
  title?: ReactNode;
  /** 다이얼로그 메인 컨텐츠 영역 */
  body?: ReactNode;
  /** 다이얼로그가 닫힐 때 실행 될 함수 */
  onClose?: () => void;
  /** 닫기 버튼 클릭 시 실행 될 함수 */
  onClickClose?: (event?: MouseEvent<HTMLElement>) => boolean | void | Promise<boolean | void>;
  /** 메인 버튼에 들어가는 텍스트와 클릭 시 실행 될 콜백 함수.
   * callback의 return 값이 false이면 다이얼로그가 닫히지 않는다. */
  primaryButton?: DialogActionButtonProps;
  /** 서브 버튼에 들어가는 텍스트와 클릭 시 실행 될 콜백 함수.
   * callback의 return 값이 false이면 다이얼로그가 닫히지 않는다. */
  secondaryButton?: DialogActionButtonProps;
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   * @default false
   */
  maxWidth?: Breakpoint | false;
  /** 다이얼로그 width */
  width?: number | string;
  /** 다이얼로그 height */
  height?: number | string;
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   * @default false
   */
  disableEscapeKeyDown?: boolean;
  helperText?: string | ReactElement;
  buttonOrder?: 'COLUMN' | 'ROW';
  hasScrollBar?: boolean;
  hasNoCloseIcon?: boolean;
  backgroundColor?: string;
}

const DialogDispatchContext = createContext<Updater<DialogState> | undefined>(undefined);

interface Props {
  children: ReactNode;
  isMobile?: boolean;
}

export function DialogContextProvider({ children, isMobile = false }: Props) {
  const [state, setState] = useImmer<DialogState>({ open: false, title: '', body: '' });

  const handleClose = useCallback(() => {
    if (state.onClose) state.onClose();
    setState((draft) => {
      draft.open = false;
    });
  }, [setState, state]);

  return (
    <DialogDispatchContext.Provider value={setState}>
      {children}
      {isMobile ? (
        <MobileModalDialog {...state} onClose={handleClose} />
      ) : (
        <ModalDialog {...state} onClose={handleClose} />
      )}
    </DialogDispatchContext.Provider>
  );
}

function useDialogContext() {
  const context = useContext(DialogDispatchContext);
  if (!context) throw new Error('DialogProvider not found');
  return context;
}

/**
 * useDialog는 간단한 Dialog를 매 번 만들지 않고 쉽게 사용하기 위해 만든 hook 입니다.
 * useContext의 state 안에 있는 값들은 임의로 변경해도 rerendering이 발생하지 않습니다.
 * 값을 변경할 때는 showDialog로 다이얼로그를 다시 갱신하면서 호출하거나 setImmerState를 이용하세요.
 * 동적으로 텍스트를 변경하거나 폼이 복잡한 경우엔 별도의 Dialog를 만드는 것을 추천합니다. */
export function useDialog() {
  const setState = useDialogContext();

  return {
    /**
     * 일부 state만 변경하고 싶을 때 사용
     * @example
     * setImmerState((draft) => {
     *   draft.title = 'loading';
     *   if (draft.primaryButton) draft.primaryButton.ButtonProps.disabled = true;
     * });
     */
    setImmerState: setState,
    /** Dialog를 설정한 값들을 포함하여 연다.
     * 이미 열려있는 Dialog가 있으면 props만 갱신하여 rerender를 발생시킨다. */
    showDialog: useCallback(
      (props: Omit<DialogState, 'open'>) => setState({ ...props, open: true }),
      [setState]
    ),
    /** 열려있는 Dialog를 닫는다 */
    closeDialog: useCallback(() => setState({ open: false }), [setState]),
    /** 간단한 알림용 Dialog 호출할 때 사용 */
    showSimpleDialog: (title: ReactNode, body: ReactNode) =>
      setState({
        open: true,
        title,
        body,
        primaryButton: { text: 'OK' },
        width: 380,
      }),
  };
}
