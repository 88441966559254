import React, { useMemo, useRef } from 'react';

import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { CLEAR_HISTORY_COMMAND, LexicalEditor } from 'lexical';
import PlaygroundEditorTheme from './PlaygroundEditorTheme';

import './Editor.css';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { FlashMessageContext } from './context/FlashMessageContext';
import { SettingsContext } from './context/SettingsContext';
import Editor from './Editor';
import { CommonFileType, EditorAppContext } from './context/EditorAppContext';
import Viewer from './Viewer';
import { editorStateFromSerializedDocument } from '@lexical/file';

export interface TextEditorProps {
  editorRef?: React.MutableRefObject<LexicalEditor | null>;
  initialEditorState?: string | null;
  readOnly?: boolean;
  /**
   * Custom image upload handler.
   *
   * @param {File[]} files The array of files to be uploaded.
   * @returns {Promise<CommonFileType[]>} A promise that resolves to an array of uploaded file types.
   */
  imageUploadHandler?: (files: File[]) => Promise<CommonFileType[]>;
  innerWidth?: number;
}

function TextEditor({
  editorRef,
  initialEditorState = null,
  readOnly,
  imageUploadHandler,
  innerWidth,
}: TextEditorProps) {
  const initialEditorStateRef = useRef(initialEditorState);

  const editorState = useMemo(() => {
    // initialData가 깨지거나 포맷에 맞지 않는 경우를 확인
    try {
      if (initialEditorStateRef.current) {
        const json = JSON.parse(initialEditorStateRef.current);
        // 내용이 비어있으면 error 발생하므로 empty를 미리 체크하여 비어있으면 null로 변경
        // if (editorRef?.current?.parseEditorState(JSON.stringify(json.editorState)).isEmpty()) {
        //   console.log(
        //     "%csetEditorState: the editor state is empty. Ensure the editor state's root node never becomes empty.",
        //     'color: red'
        //   );
        //   return null;
        // }
        return JSON.stringify(json);
      } else {
        return null;
      }
    } catch (e: any) {
      console.log('%cinitialData parsing error!', 'color: red');
      console.log(`%c${e.name}: ${e.message}`, 'color: red');
      return null;
    }
  }, []);

  const initialConfig: InitialConfigType = {
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: any) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editorState(editor) {
      editorState && editor.setEditorState(editorStateFromSerializedDocument(editor, editorState));
      editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
    },
    editable: !readOnly,
  };

  return (
    <EditorAppContext imageUploadHandler={imageUploadHandler} innerWidth={innerWidth || 750}>
      <SettingsContext>
        <FlashMessageContext>
          <LexicalComposer initialConfig={initialConfig}>
            <SharedHistoryContext>
              <TableContext>
                <SharedAutocompleteContext>
                  <div className={readOnly ? undefined : 'editor-shell'}>
                    {readOnly ? (
                      <Viewer />
                    ) : (
                      <>
                        <SharedHistoryContext>
                          <TableContext>
                            <FlashMessageContext>
                              <Editor editorRef={editorRef} />
                            </FlashMessageContext>
                          </TableContext>
                        </SharedHistoryContext>
                      </>
                    )}
                  </div>
                  {/* <Settings /> */}
                  {/* {isDevPlayground ? <DocsPlugin /> : null} */}
                  {/* {isDevPlayground ? <PasteLogPlugin /> : null} */}
                  {/* {isDevPlayground ? <TestRecorderPlugin /> : null} */}
                  {/* {measureTypingPerf ? <TypingPerfPlugin /> : null} */}
                </SharedAutocompleteContext>
              </TableContext>
            </SharedHistoryContext>
          </LexicalComposer>
        </FlashMessageContext>
      </SettingsContext>
    </EditorAppContext>
  );
}

export default TextEditor;
