import { numberWithCommas } from '@ground/tools';
import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  gpoint: number;
  textColor?: 'text.secondary' | 'text.primary';
}

export default function GPointSummaryItem({ title, gpoint, textColor = 'text.primary' }: Props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingX="40px"
      width="100%"
    >
      <Typography variant="body2" color="text.secondary" component="span">
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="h4" color={textColor}>
          {numberWithCommas(gpoint)}
        </Typography>
        <Typography variant="small1" color="text.secondary2" component="span" marginLeft="8px">
          G.P
        </Typography>
      </Box>
    </Box>
  );
}
