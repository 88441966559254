import { LoadingCounter } from '@ground/ui';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useAboutCountryStatisticsAPI } from 'api/about/aboutCountryStatisticsAPI';
import { useLog } from 'utils/userLog';

import aboutImage from 'images/banner/home-about-bg.webp';
import aboutImage2x from 'images/banner/home-about-bg@2x.webp';
import aboutImage3x from 'images/banner/home-about-bg@3x.webp';

interface SummaryProps {
  loading: boolean;
  title: string;
  count: number;
  suffix: string;
}

function Summary({ loading, title, count, suffix }: SummaryProps) {
  return (
    <Box textAlign="center">
      <Typography variant="small2">{title}</Typography>
      <Typography variant="h5" color="base.white" mt="1px">
        <LoadingCounter id={`counter_${suffix}`} loading={loading} endCount={count} />
        {suffix === 'Supporters' && <span>+</span>}
      </Typography>
      <Typography variant="subtitle2" color="base.white" mt="1px">
        {suffix}
      </Typography>
    </Box>
  );
}

export default function HomeAbout() {
  const { data, isLoading } = useAboutCountryStatisticsAPI();
  const summary = data?.data?.summary;
  const { gxcTag } = useLog();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        width: '100%',
        height: 438,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          height: '100%',
          background: '#0C6DA0',
        }}
      />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          background: '#BCBD97',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundPositionX: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `image-set(url(${aboutImage}) 1x, url(${aboutImage2x}) 2x, url(${aboutImage3x}) 3x)`,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Box
          sx={{
            maxWidth: '1200px',
            width: '1200px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack>
            <Typography variant="title">About G.Round</Typography>

            <Box mt="32px" display="flex">
              <Summary
                loading={isLoading}
                title="Featured"
                count={summary?.game_count ?? 0}
                suffix="Games"
              />
              <Divider
                orientation="vertical"
                sx={{ margin: '0 28px', borderColor: 'rgba(255, 255, 255, 0.15)' }}
              />
              <Summary
                loading={isLoading}
                title="Over"
                count={summary?.user_count ?? 0}
                suffix="Users"
              />
              <Divider
                orientation="vertical"
                sx={{ margin: '0 28px', borderColor: 'rgba(255, 255, 255, 0.15)' }}
              />
              <Summary
                loading={isLoading}
                title="Users in"
                count={summary?.country_count ?? 0}
                suffix="Regions"
              />
              <Divider
                orientation="vertical"
                sx={{ margin: '0 28px', borderColor: 'rgba(255, 255, 255, 0.15)' }}
              />
              <Summary loading={isLoading} title="Over" count={1000} suffix="Supporters" />
            </Box>
            <Typography align="left" variant="body2" mt="40px">
              We find the best upcoming games for you! Play our selection of curated
              <br />
              games every week from 2D platformers to Souls-like incredible titles.
              <br /> We scour the Earth to find new unpublished games. New games are updated every
              Thursday at 12:00 AM(PT)
            </Typography>
            <Button
              component={Link}
              to="/about"
              variant="outlined"
              color="light"
              size="large"
              sx={{ mt: '40px', width: '116px' }}
              onClick={() => gxcTag('click', { area: 'about', ui: 'view_more' })}
            >
              View More
            </Button>
          </Stack>
          <iframe
            title="G.Round"
            src="https://discord.com/widget?id=601726723947757571&theme=dark"
            width="265"
            height="318"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}
