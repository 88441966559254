import { useSelector } from 'react-redux';

import useSWRFetch from 'api/useSWRFetch';
import { iRootState } from 'store';
import { UserGamePermissionType } from 'types/UserPermissionTypes';

export function useGetPermissionAPI(gameId: number | string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  const url = `game/${gameId}/permission`;

  return useSWRFetch<UserGamePermissionType>({ key: isLogin ? url : null, useToken: true });
}
