import { Box, Typography } from '@mui/material';

interface QuestionTitleProps {
  order: number;
  question: string;
  optionText?: string;
  detail?: string;
  error?: boolean;
}

export default function QuestionTitle({
  order,
  question,
  optionText,
  detail,
  error,
}: QuestionTitleProps) {
  return (
    <Box marginBottom="28px">
      <Typography variant="subtitle1" color={error ? 'error' : 'text.primary'}>
        {order}. {question}
        {optionText && (
          <Typography variant="body3" component="span" color="text.secondary" marginLeft="20px">
            {optionText}
          </Typography>
        )}
      </Typography>
      <Typography variant="body3" color="text.secondary" marginTop="8px">
        {detail}
      </Typography>
    </Box>
  );
}
