import { Button, Typography } from '@mui/material';

import { GameFile } from 'types/GameDetailTypes';
import HowToPlayControlGuide from './HowToPlayControlGuide';
import HowToPlayGridList from './HowToPlayGridList';

interface Props {
  gamefileData: GameFile;
}

export default function HowToPlaySelf({ gamefileData }: Props) {
  const contents = [...gamefileData.guide_contents]
    .sort((a, b) => a.seq - b.seq)
    .map((guide, index) => ({
      id: index + 1,
      title: guide.content,
      image: guide.file_url,
    }));

  return (
    <>
      {Array.isArray(gamefileData.game_control_guides) &&
        gamefileData.game_control_guides.length > 0 && (
          <HowToPlayControlGuide game_control_guides={gamefileData.game_control_guides} />
        )}
      <Typography variant="h5" marginBottom="40px">
        Install Guide
      </Typography>
      <Button
        size="large"
        color="primary"
        variant="contained"
        href={gamefileData.main_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to Register &gt;
      </Button>
      <HowToPlayGridList contentList={contents} />
    </>
  );
}
