import { Navigate, Route, Routes } from 'react-router-dom';

import BlogDetailMain from './blog-detail/BlogDetailMain';
import BlogListMain from './blog-list/BlogListMain';

export default function BlogRouter() {
  return (
    <Routes>
      <Route index element={<BlogListMain />} />
      <Route path=":announcementId/*" element={<BlogDetailMain />} />
      <Route path="*" element={<Navigate to="blog" replace />} />
    </Routes>
  );
}
