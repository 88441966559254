import * as Sentry from '@sentry/react';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import useSWRFetch from 'api/useSWRFetch';
import { MobileVideoDialogProvider, useTermsConfirmDialog } from 'hooks';
import { Dispatch, iRootState } from 'store';
import ErrorPage from './ErrorPage';
import AboutDeveloper from './about/AboutDeveloper';
import Blog from './blog/Blog';
import NoAuthRoute from './main/NoAuthRoute';
import {
  GameDetail,
  Gnb,
  Home,
  Login,
  NotFound,
  NotSupportPrivate,
  Profile,
  ResetPassword,
  Signup,
} from './mobile';

interface TermsProps {
  agreed_terms: boolean;
  agreed_terms_at: string;
}

function MobileAppContainer() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { isLogin, agreed_terms, filled, verified } = useSelector(
    (state: iRootState) => state.login
  );
  const dispatch = useDispatch<Dispatch>();
  const { showTermsConfirmDialog } = useTermsConfirmDialog();
  const { data } = useSWRFetch<TermsProps>({
    key: isLogin && agreed_terms === null ? 'users/terms' : '',
    useToken: true,
  });

  useEffect(() => {
    // 약관 동의 여부가 없는 경우 동의 여부를 API를 통해 읽어온 후 업데이트 한다.
    if (agreed_terms === null && data?.data) {
      dispatch.login.updateLoginInfo({ agreed_terms: data.data.agreed_terms });
    }
  }, [agreed_terms, data?.data, dispatch.login, pathname, search]);

  useEffect(() => {
    if (isLogin) {
      // 인증이나 프로필 입력 안된 경우 입력 페이지로 이동
      if (!verified) {
        navigate('/signup/verification', { replace: true });
      } else if (!filled) {
        navigate('/signup/profile', { replace: true });
      } else if (agreed_terms === false) {
        // 페이지 이동 시 약관 동의를 하지 않았으면 약관 동의 다이얼로그 띄움
        showTermsConfirmDialog();
      }
    }
  }, [isLogin, agreed_terms, showTermsConfirmDialog, pathname, search, verified, filled, navigate]);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => <ErrorPage {...props} />}
      beforeCapture={(scope) => {
        scope.setTag('platform', 'mobile');
      }}
    >
      <MobileVideoDialogProvider>
        <Routes>
          <Route index element={<Gnb />} />
          <Route path="/login/*" element={null} />
          <Route path="/signup/*" element={null} />
          <Route path="/request-reset-password" element={<Gnb type="logo" />} />
          <Route path="/reset-password" element={<Gnb type="logo" />} />
          <Route path="/detail/*" element={null} />
          <Route path="/profile/*" element={null} />
          <Route path="*" element={<Gnb />} />
        </Routes>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/signup/*" element={<Signup />} />
          <Route
            path="/login/*"
            element={
              <NoAuthRoute>
                <Login />
              </NoAuthRoute>
            }
          />
          <Route path="/request-reset-password" element={<ResetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/detail/*" element={<GameDetail />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/devabout" element={<AboutDeveloper />} />
          <Route path="/blog/*" element={<Blog />} />
          <Route path="/404" element={<NotFound />} />

          {/* 모바일에서 지원하지 않는 페이지 */}
          {/* <Route path="/testing" element={<NotSupport />} />
          <Route path="/gpointstore/*" element={<NotSupport />} />
          <Route path="/store/*" element={<NotSupport />} />
          <Route path="/clips/*" element={<NotSupport />} />
          <Route path="/about" element={<NotSupport />} />
          <Route path="/recruitment/*" element={<NotSupportPrivate />} /> */}
          <Route path="*" element={<NotSupportPrivate />} />
        </Routes>
      </MobileVideoDialogProvider>
    </Sentry.ErrorBoundary>
  );
}

export default memo(MobileAppContainer);
