import { Typography, Button } from '@mui/material';

import { styled } from '@mui/material/styles';

import ArtAboutSns from '../articlesGamer/component/ArtAboutSns';
import ContactBGImg from 'images/about/aboutDev/about_contact_bg.png';

const Root = styled('div')({
  width: 1440,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 80,
  paddingBottom: 80,
  textAlign: 'center',
  backgroundImage: `url(${ContactBGImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

export default function ArtDevAboutContact() {
  return (
    <Root id="contactUs">
      <Typography variant="h4">I have more questions!</Typography>
      <Typography variant="body1" sx={{ width: '481px', marginTop: '28px', marginBottom: '40px' }}>
        Have a game that you want to submit, but have more questions? <br /> Please feel free to get
        in touch with us. <br /> Our sales team will contact you once they get their hands on them!
      </Typography>
      <Button
        size="medium"
        variant="outlined"
        color="light"
        component="a"
        href="mailto:info@gameround.co"
      >
        Contact us
      </Button>
      <ArtAboutSns />
    </Root>
  );
}
