import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/dist/types';

import {
  useGameDetailBasicInfoAPI,
  useGameDetailInfoAPI,
  useGameSupportLanguageListAPI,
} from 'api/detail/detailAPI';
import { useGetPermissionAPI } from 'api/detail/permissionAPI';
import { GameContent, GameDetailProps, GameFile, GameInfo } from 'types/GameDetailTypes';
import { ResponseData } from 'types/ResponseType';
import { UserGamePermissionType } from 'types/UserPermissionTypes';

type ContextProps = {
  data?: GameDetailProps;
  files?: GameFile[];
  contents?: GameContent[];
  info?: GameInfo;
  languageSet?: string[];
  selectLanguageCode: string;
  setSelectLanguageCode: Dispatch<SetStateAction<string>>;
  loading: boolean;
  reload: KeyedMutator<ResponseData<GameDetailProps>>;
  permissionData?: ResponseData<UserGamePermissionType>;
};

const DetailContext = createContext<ContextProps | undefined>(undefined);

export const DetailProvider = ({ children }: { children: ReactNode }) => {
  const { id = '' } = useParams();
  const [language, setLanguage] = useState(
    navigator.language?.substring(0, 2)?.toUpperCase() ?? 'EN'
  );
  const { data: languageData, isLoading: languageLoading } = useGameSupportLanguageListAPI(id);
  // 선택한 언어가 지원되는 언어 리스트에 있는지 확인
  const isIncludeLanguage = !languageLoading && languageData?.data?.includes(language);
  const { data, isLoading, mutate } = useGameDetailBasicInfoAPI(id);
  const { data: infoData } = useGameDetailInfoAPI(id, isIncludeLanguage ? language : null);
  const { data: permissionData } = useGetPermissionAPI(id);

  useEffect(() => {
    // 사용가능한 언어 리스트를 가져와서 리스트에 선택한 언어가 없으면 기본 언어인 EN로 바꾼다
    if (!languageLoading && !isIncludeLanguage) {
      setLanguage('EN');
    }
  }, [isIncludeLanguage, languageLoading]);

  const detailData = data?.data;
  const gameInfo = infoData?.data;

  return (
    <DetailContext.Provider
      value={{
        data: detailData,
        files: detailData?.game_files,
        contents: detailData?.game_contents,
        info: gameInfo?.find?.((info) => info.language_code === language),
        languageSet: languageData?.data,
        selectLanguageCode: language,
        setSelectLanguageCode: setLanguage,
        loading: isLoading,
        reload: mutate,
        permissionData,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};

export const useGetDetailData = () => {
  const state = useContext(DetailContext);
  if (!state) throw new Error('detail data not found');

  return state;
};
