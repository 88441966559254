import { LoadingButton } from '@mui/lab';

import { useCheckUserIsAdultAPI } from 'api/payment/paymentAPI';
import { useDialog, useFetchResult } from 'hooks';
import GPointsDialogBody from './GPointsDialogBody';

interface Props {
  onComplete?: () => void;
  label?: string;
}

export default function AddGpointButton({ onComplete, label }: Props) {
  const { showDialog } = useDialog();
  const { showResult } = useFetchResult();
  const { fetch: fetchCheckAdult, isLoading: isAdultLoading } = useCheckUserIsAdultAPI();

  const onAddGPointClick = async () => {
    const response = await fetchCheckAdult();
    showResult(response, {
      onSuccess: () => {
        const isNotAdult = !response.data?.data.adult;

        if (isNotAdult) {
          showDialog({
            title: 'Sorry, you can’t add G.Points now',
            body: 'You must be over 18 years of age to add G.Points.',
            secondaryButton: { text: 'Close' },
          });
          return;
        }

        showDialog({
          width: '440px',
          title: 'To add G.Points, make a purchase',
          body: <GPointsDialogBody onComplete={onComplete} />,
        });
      },
    });
  };

  return (
    <LoadingButton
      loading={isAdultLoading}
      size="tiny"
      variant="ghost"
      color="dark"
      onClick={onAddGPointClick}
    >
      {label || `Purchase Your G.Points`}
    </LoadingButton>
  );
}
