import { Card, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Card)({
  width: 590,
  height: 391,
  borderRadius: 5,
});

const ItemWrap = styled('div')({
  padding: 20,
});

export default function GameCardBigSkeleton() {
  return (
    <Root elevation={0}>
      <Skeleton width={590} height={297} variant="rectangular" />
      <ItemWrap>
        <Skeleton width={100} height={20} />
        <Skeleton width={150} height={20} />
        <Skeleton width={200} height={20} />
      </ItemWrap>
    </Root>
  );
}
