import { ReactNode, useState } from 'react';

import {
  Button,
  Menu,
  MenuItem,
  MenuListProps,
  MenuItemProps,
  Divider,
  createSvgIcon,
  Typography,
} from '@mui/material';

const ArrowDropDown = createSvgIcon(
  <path d="M3.5 3L6.53109 0L0.468911 0L3.5 3Z" fill="#D2D7DD" />,
  'ArrowDropDown'
);

export interface PopupMenuItem {
  label?: ReactNode;
  value: number | string;
  /** divider로 사용할 땐 value는 겹치지 않는 임의 값 사용 */
  divider?: boolean;
  onClick?: (value: number | string) => void;
}

export interface PopupButtonProps {
  /** 좌측 아이콘 */
  startIcon: ReactNode;
  /** 버튼에 들어갈 텍스트 */
  buttonText: string;
  /** 메뉴 리스트 정의 */
  menuList: PopupMenuItem[];
  /** PopupButton의 width 값 */
  width: number;
  /** 메뉴 선택 상태 표시 유무 */
  enableSelected?: boolean;
  /** Menu List's props */
  MenuListProps?: Partial<MenuListProps>;
  /** Menu Item's props */
  MenuItemProps?: Partial<MenuItemProps>;
  /** Popup 버튼 클릭시, Callback 함수 */
  onClick?: () => void;
  /** TODO: 메뉴 리스트 스크롤 바, 선택 및 호버 시 색상 변경 */
}

const PopupButton = ({
  startIcon,
  buttonText,
  menuList,
  width,
  enableSelected = false,
  MenuListProps,
  MenuItemProps,
  onClick,
}: PopupButtonProps) => {
  const [selectedValue, setSelectedValue] = useState<null | number | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickButton = () => {
    setOpen(true);
    onClick?.();
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickMenuItem = (menuItem: PopupMenuItem) => {
    enableSelected && setSelectedValue(menuItem.value);
    menuItem.onClick?.(menuItem.value);
    setOpen(false);
  };

  return (
    <div>
      <Button
        ref={setAnchorEl}
        color="dark"
        size="small"
        startIcon={startIcon}
        endIcon={<ArrowDropDown sx={{ width: 7, height: 3 }} viewBox="0 0 7 3" />}
        variant="contained"
        onClick={handleClickButton}
        sx={{ width: width }}
      >
        <Typography flexGrow={1} noWrap textAlign="left" variant="button">
          {buttonText}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ ...MenuListProps }}
        PaperProps={{ elevation: 0, sx: { bgcolor: 'gray.80', mt: '4px', width: width } }}
      >
        {menuList.map((menu) =>
          menu.divider ? (
            <Divider key={menu.value} sx={{ mx: '16px' }} />
          ) : (
            <MenuItem
              key={menu.value}
              sx={{ minHeight: 'auto', py: '8px' }}
              onClick={() => handleClickMenuItem(menu)}
              selected={enableSelected && menu.value === selectedValue}
              {...MenuItemProps}
            >
              {typeof menu.label === 'string' ? (
                <Typography noWrap variant="button">
                  {menu.label}
                </Typography>
              ) : (
                menu.label
              )}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default PopupButton;
