import { useNavigate } from 'react-router-dom';
import { Typography, styled, useTheme } from '@mui/material';

import { DiscordBanner } from 'components';
import { useLog } from 'utils/userLog';
import backgroundImage from 'images/notfound/background-image-desktop.png';

const Root = styled('div')({
  width: '100%',
  minHeight: 719,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
});

export default function NotFound() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { gxcTag } = useLog();

  return (
    <Root>
      <Typography variant="h2" mb="20px">
        Error 404
      </Typography>

      <Typography variant="subtitle1" color="text.secondary" mb="32px" textAlign="center">
        Oops, the page you were looking for doesn't exist.
      </Typography>

      <Typography variant="body2" color="text.secondary" textAlign="center" mb="100px">
        The page does not exist. Before the door closes, <br />
        Please check the URL or{' '}
        <span
          style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
          onClick={() => {
            gxcTag('click', { ui: 'go_back' });
            navigate(-1);
          }}
        >
          go back
        </span>{' '}
        to check the direction.
      </Typography>
      <DiscordBanner size="small" />
    </Root>
  );
}
