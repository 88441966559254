import { Box, Button, ImageList, ImageListItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetDigitalGameProductList } from 'api/gstore';
import { SectionContainer } from 'components';
import GStoreItem from './GStoreItem';
import GStoreTitleButton from './components/GStoreTitleButton';
import PurchaseSaleBox from './components/PurchaseSaleBox';

const GStoreDigitalGameList = () => {
  const { data } = useGetDigitalGameProductList();
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const digitalGameList = data?.data ?? [];

  const digitalGameListSliced =
    digitalGameList.sort((a, b) => a.seq - b.seq).slice(0, showAll ? digitalGameList?.length : 4) ||
    [];

  const isDigitalGamesCountOdd = digitalGameListSliced.length % 2 !== 0;

  return digitalGameList.length > 0 ? (
    <SectionContainer
      title={`Digital Games (${digitalGameList.length})`}
      containerOptions={
        <GStoreTitleButton
          onClick={() => {
            navigate('/blog/185');
          }}
        >
          How do I purchase Game Keys?
        </GStoreTitleButton>
      }
    >
      <ImageList rowHeight={300} gap={30} cols={2} sx={{ margin: 0 }}>
        {[...digitalGameListSliced].map((product) => (
          <ImageListItem
            cols={1}
            rows={1}
            key={product.id}
            sx={{ borderRadius: '5px', overflow: 'hidden' }}
          >
            <GStoreItem
              productName={product.title}
              price={product.price}
              imageUrl={product.thumbnail_url}
              to={`digital-games/${String(product.id)}`}
            />
          </ImageListItem>
        ))}
        {isDigitalGamesCountOdd && (
          <ImageListItem cols={1} rows={1} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
            <PurchaseSaleBox />
          </ImageListItem>
        )}
      </ImageList>

      {!showAll && digitalGameList.length > 4 && (
        <Box textAlign="center" marginTop="80px">
          <Button color="dark" variant="contained" onClick={() => setShowAll(true)}>
            Load More
          </Button>
        </Box>
      )}
    </SectionContainer>
  ) : null;
};

export default GStoreDigitalGameList;
