import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';

import MuiButton from 'theme/privateButton';

interface Props {
  children: ReactNode;
  isPrivate?: boolean;
}

export default function PrivateThemeProvider({ children, isPrivate = true }: Props) {
  return isPrivate ? (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          components: {
            ...theme.components,
            ...MuiButton,
          },
          palette: {
            ...theme.palette,
            primary: {
              main: '#00A184',
              light: '#33B39C',
              dark: '#00705C',
              contrastText: '#FFFFFF',
            },
          },
        })
      }
    >
      {children}
    </ThemeProvider>
  ) : (
    <>{children}</>
  );
}
