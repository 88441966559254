import { DebounceSettings, throttle } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';

export function useThrottle<T extends (...args: any[]) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings
) {
  const funcRef = useRef(func);
  funcRef.current = func;

  const throttleFunc = useMemo(() => throttle(funcRef.current, wait, options), [options, wait]);

  useEffect(() => {
    return () => throttleFunc.cancel();
  }, [throttleFunc]);

  return throttleFunc;
}
