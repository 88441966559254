import { FormInput } from '@ground/ui';
import { useFormState } from 'react-hook-form';

import { passwordPattern } from 'utils/common';

export default function AccountNewPasswordInput() {
  const { dirtyFields, errors } = useFormState();

  return (
    <FormInput
      label="New Password"
      name="password"
      type="password"
      autoComplete="new-password"
      placeholder="Insert new password"
      withBackground
      password
      success={dirtyFields['password'] && !errors['password']}
      rules={{
        required: 'Please enter a password.',
        pattern: { value: passwordPattern, message: 'Invalid format' },
      }}
      fullWidth
    />
  );
}
