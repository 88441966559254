import { Typography, Box, Grid } from '@mui/material';

import about_testing_img1 from 'images/about/aboutGamer/about_testing_img1.png';
import about_testing_img2 from 'images/about/aboutGamer/about_testing_img2.png';
import about_testing_img3 from 'images/about/aboutGamer/about_testing_img3.png';
import about_testing_img4 from 'images/about/aboutGamer/about_testing_img4.png';

export default function ArtAboutGamerTesting() {
  return (
    <Box display="flex" flexDirection="column" width={1080} m={'120px 0'}>
      <Typography variant="h4" mb="40px">
        Testing
      </Typography>
      <Grid container rowGap="60px" columnGap="60px">
        {testingData.map((item) => (
          <Box width="510px" key={item.id}>
            <img src={item.imageSrc} alt="testing card" width="510px" height="300px" />
            <Typography mt="24px" variant="body1" color="text.secondary" noWrap>
              {item.title}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
}
const testingData = [
  {
    id: 1,
    imageSrc: about_testing_img1,
    title: (
      <>
        Every week Thursday,
        <br /> we have two new games featured on our platform.
      </>
    ),
  },
  {
    id: 2,
    imageSrc: about_testing_img2,
    title: 'Every game stays on the platform for period of four weeks.',
  },
  {
    id: 3,
    imageSrc: about_testing_img3,
    title: 'Reviews are available right from the start of the playtest.',
  },
  {
    id: 4,
    imageSrc: about_testing_img4,
    title: 'Surveys are open from the start of the playtest as well.',
  },
];
