import { InputField } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import { useEmailSubscribeAPI } from 'api/email/emailAPI';
import { useDialog, useError } from 'hooks';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

interface FormData {
  email: string;
}

export default function MobileFooterSubscribe() {
  const { isLoading, fetch } = useEmailSubscribeAPI();
  const { gxcTag } = useLog();
  const { showDialog } = useDialog();
  const { showError } = useError();
  const { register, handleSubmit, reset } = useForm<FormData>();

  const onSubmit = async (formData: FormData) => {
    gxcTag('click', { area: 'Newsletter', ui: 'subscribe' });

    const response = await fetch(formData);
    if (response?.status === 200 && response.data?.result_code === 'OK') {
      gxcTag('view', { area: 'subscribe_success_popup' });

      showDialog({
        title: 'You have subscribed to the newsletter!',
        body: 'Newsletter subscription successful',
        onClickClose: () => {
          gxcTag('click', { area: 'subscribe_success_popup', ui: 'close_x' });
        },
        primaryButton: {
          text: 'OK',
          callback: () => {
            gxcTag('click', { area: 'subscribe_success_popup', ui: 'ok' });
          },
        },
      });

      reset();
    } else {
      gxcTag('view', { area: 'subscribe_error_popup' });
      showError(response, 'Subscribe to newsletter', {
        primaryButton: {
          text: 'OK',
          callback: () => {
            gxcTag('click', { area: 'subscribe_error_popup', ui: 'ok' });
          },
        },
      });
    }
    eventTracker('footer_subscribe');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', width: '100%' }}>
      <InputField
        type="email"
        placeholder="Email Address"
        data-cy="ground-footer-scribe-input"
        required
        withBackground
        size="small"
        sx={{ marginRight: '8px', flex: 1 }}
        fullWidth
        {...register('email', { required: true, minLength: 1 })}
      />
      <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
        Subscribe
      </LoadingButton>
    </form>
  );
}
