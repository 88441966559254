import { GIconButton, GroundLogo } from '@ground/ui';
import { Box, Link, Typography, styled } from '@mui/material';

import SitemapList, { SitemapProps } from 'components/sitemap/SitemapList';
import FooterSubscribe from './FooterSubscribe';

import FooterScrollToTopIcon from 'images/footer/footer_top_btn_hover.svg';
import { useLog } from 'utils/userLog';

const FooterRoot = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.gray[80],
}));

const FooterContainer = styled('div')({
  width: 1200,
  padding: '60px 0 50px',
});

const LogoAndScrollTopWrap = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ScrollTopBtn = styled('img')({
  position: 'absolute',
  right: 0,
  top: -10,
  cursor: 'pointer',
  opacity: 0.6,
  '&:hover': {
    opacity: 'initial',
  },
});

const ListWrap = styled('div')({
  display: 'flex',
  marginTop: 60,
  justifyContent: 'space-between',
});

const SnsWrap = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
});

const TopInfo = styled('div')({
  position: 'relative',
});

const BottomInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 64,
  alignItems: 'center',
});

const sitemap: SitemapProps[] = [
  {
    title: 'Information',
    dataList: [
      {
        text: 'Privacy Policy',
        url: '/privacyPolicy.pdf',
        target: '_blank',
        logCode: 'privacy_policy',
      },
      {
        text: 'Terms & Conditions',
        url: '/userTerms.pdf',

        target: '_blank',
        logCode: 'terms_of_use',
      },
      {
        text: 'Contact Us',
        url: '/about/#contactUs',
        logCode: 'contact_us',
      },
    ],
  },
  {
    title: 'For Developer',
    dataList: [
      {
        text: 'G.Round Intro',
        url: 'https://developer.gameround.co/',
        target: '_blank',
        logCode: 'b2b_intro',
      },
      {
        text: 'Submit a Game',
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSe0lqFCXEBhRRgpAPHGlP_qGXXsOOMdcZV_ou8DZG53ojCEoA/viewform',
        target: '_blank',
        logCode: 'b2b_submit_a_game',
      },
    ],
  },
];

const handleClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export default function Footer() {
  const { gxcTag } = useLog();

  return (
    <FooterRoot>
      <FooterContainer data-cy="ground-footer">
        <TopInfo>
          <LogoAndScrollTopWrap>
            <GroundLogo colorType="dark" width={107} height={17} />
            <ScrollTopBtn
              src={FooterScrollToTopIcon}
              alt="scroll to top"
              onClick={() => {
                gxcTag('click', { area: 'footer', ui: 'go_to_top' });
                handleClick();
              }}
            />
          </LogoAndScrollTopWrap>
          <ListWrap>
            <Box display="flex">
              {sitemap.map((data, idx) => (
                <SitemapList key={idx} {...data} />
              ))}
            </Box>
            <Box component="dl" sx={{ margin: 0 }}>
              <Typography component="dt" variant="small1" color="text.secondary2">
                Subscribe to the G.Round Newsletter!
              </Typography>
              <Box component="dd" sx={{ marginLeft: 0, marginTop: '19px' }}>
                <FooterSubscribe />
              </Box>
            </Box>
          </ListWrap>
        </TopInfo>

        <BottomInfo>
          <Box display="flex" paddingTop="2px">
            <Typography variant="small2" color="text.secondary2" sx={{ width: 275 }}>
              ⓒ G.Round. All Rights Reserved.
            </Typography>
            <Typography variant="small2" color="text.secondary2">
              In collaboration with: Global Top Round | Round Ventures
            </Typography>
          </Box>
          <SnsWrap>
            <Link
              href="https://www.youtube.com/channel/UCs3X1qAr8hXkBnn0v8lT9DA"
              target="_blank"
              onClick={() => gxcTag('click', { area: 'footer', ui: 'youtube' })}
            >
              <GIconButton
                iconName="youtube"
                iconSize={16}
                size={32}
                variant="circle"
                backgroundColor="gray.60"
              />
            </Link>
            <Link
              href="https://twitter.com/gameroundco"
              target="_blank"
              onClick={() => gxcTag('click', { area: 'footer', ui: 'twitter' })}
            >
              <GIconButton
                iconName="x"
                iconSize={16}
                size={32}
                variant="circle"
                backgroundColor="gray.60"
              />
            </Link>
            <Link
              href="https://www.facebook.com/gameround/"
              target="_blank"
              onClick={() => gxcTag('click', { area: 'footer', ui: 'facebook' })}
            >
              <GIconButton
                iconName="facebook"
                iconSize={16}
                size={32}
                variant="circle"
                backgroundColor="gray.60"
              />
            </Link>
          </SnsWrap>
        </BottomInfo>
      </FooterContainer>
    </FooterRoot>
  );
}
