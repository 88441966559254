import { forwardRef, Ref } from 'react';
import { Button, ButtonProps, useThemeProps } from '@mui/material';

export interface GButtonProps extends ButtonProps {}

const GButton = forwardRef((inProps: GButtonProps, ref: Ref<HTMLButtonElement>) => {
  const props = useThemeProps({ props: inProps, name: 'MuiGButton' });
  const { children, ...other } = props;

  return (
    <Button {...other} ref={ref}>
      {children}
    </Button>
  );
});

export default GButton;
