import { Box, Fade, Popper, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

interface ReviewStatusProps {
  createdDate: string;
  editedDate?: string | null;
  purchased?: boolean;
}

export default function ReviewStatus({ createdDate, editedDate, purchased }: ReviewStatusProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" gap="8px" alignItems="center">
      <Typography variant="body3" color="text.secondary2">
        {format(new Date(createdDate), 'MMM d, yyyy')}
      </Typography>

      {editedDate ? (
        <>
          <Typography variant="body3" color="text.secondary2">
            ·
          </Typography>
          <Typography
            ref={setAnchorEl}
            variant="body3"
            color="text.secondary2"
            aria-owns="edited-date-popper"
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{ transition: 'color 300ms', '&:hover': { color: 'text.primary' } }}
          >
            Edited
          </Typography>
          {/* popper edited date */}
          <Popper
            id="edited-date-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            transition
            disablePortal
            popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 6] } }] }}
            sx={{ zIndex: 1 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={300}>
                <Typography
                  variant="button3"
                  color="text.secondary"
                  sx={{ borderRadius: '3px', backgroundColor: 'gray.40', padding: '8px 12px' }}
                >
                  {format(new Date(editedDate), "MMM d, yyyy 'at' hh:mm a")}
                </Typography>
              </Fade>
            )}
          </Popper>
        </>
      ) : null}
      {purchased ? (
        <>
          <Typography variant="body3" color="text.secondary2">
            ·
          </Typography>
          <Typography variant="body3" color="success.main">
            Purchased
          </Typography>
        </>
      ) : null}
    </Box>
  );
}
