import { styled } from '@mui/material';

import ArtAboutSnsIcon from './ArtAboutSnsIcon';

import { useLog } from 'utils/userLog';

import { ReactComponent as DiscordIcon } from 'images/icons/sns/discord_default_icon.svg';
import { ReactComponent as FacebookIcon } from 'images/icons/sns/facebook_default_icon.svg';
import { ReactComponent as LinkedinIcon } from 'images/icons/sns/linkedin_default_icon.svg';
import { ReactComponent as TwitterIcon } from 'images/icons/sns/twitter_default_icon.svg';
import { ReactComponent as YoutubeIcon } from 'images/icons/sns/youtube_default_icon.svg';

const PREFIX = 'ArtAboutSns';

const classes = {
  snsWrap: `${PREFIX}-snsWrap`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: 16,

  [`& .${classes.snsWrap}`]: {
    height: 76,
    paddingTop: 40,
  },
}));

export default function ArtAboutSns() {
  const { gxcTag } = useLog();
  const snsData = [
    {
      name: 'facebook',
      link: 'https://www.facebook.com/gameround/',
      img: <FacebookIcon />,
    },
    {
      name: 'discord',
      link: 'https://bit.ly/grounddiscord',
      img: <DiscordIcon />,
    },
    {
      name: 'twitter',
      link: 'https://twitter.com/gameroundco',
      img: <TwitterIcon />,
    },
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/gameround/',
      img: <LinkedinIcon />,
    },
    {
      name: 'youtube',
      link: 'https://www.youtube.com/channel/UCs3X1qAr8hXkBnn0v8lT9DA',
      img: <YoutubeIcon />,
    },
  ];

  return (
    <Root>
      {snsData.map((item, index) => (
        <div
          className={classes.snsWrap}
          key={index}
          onClick={() => {
            gxcTag('click', {
              area: 'contact_us',
              ui: item.name,
            });
          }}
        >
          <ArtAboutSnsIcon link={item.link} img={item.img} />
        </div>
      ))}
    </Root>
  );
}
