import { Typography, Box } from '@mui/material';

const privateDayStyleProps = {
  textShadow: '3px 3px 3px rgba(0, 0, 0, 0.55)',
};

type GameCardPrivateUpperAreaProps = {
  remainingText?: string;
};

// medium,small 공통 카드에서 사용
export default function GameCardPrivateUpperArea({ remainingText }: GameCardPrivateUpperAreaProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        zIndex: 5,
        width: '100%',
        height: '100%',
        fontSize: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(18, 18, 18, 0.6)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Typography variant="h5" color="white" textAlign="center" sx={privateDayStyleProps}>
        {remainingText}
      </Typography>
    </Box>
  );
}
