import { useState, useEffect } from 'react';
import ReactPlayer, { YouTubePlayerProps } from 'react-player/youtube';

interface IYoutubePlayerProps extends YouTubePlayerProps {
  url: string;
  globalPause: boolean;
  height?: number | string;
  width?: number | string;
  autoplay?: boolean;
}

export default function YoutubePlayer({
  url,
  globalPause,
  height,
  width,
  autoplay = false,
  ...props
}: IYoutubePlayerProps) {
  const [playing, setPlaying] = useState(autoplay);

  // 슬라이더가 swipe 될 때 모든 재생 중인 유튜브 pause
  useEffect(() => {
    if (globalPause) {
      setPlaying(false);
    }

    if (autoplay) {
      setPlaying(autoplay);
    }
  }, [globalPause, autoplay]);

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleEnded = () => {
    setPlaying(false);
  };

  return (
    <ReactPlayer
      url={url}
      width={width ?? 827}
      height={height ?? 465}
      controls
      playing={playing}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnded={handleEnded}
      {...props}
    />
  );
}
