import { GIcon } from '@ground/ui';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { LinkButton } from 'elements';
import { useClientApplyData } from '../ClientApplyProvider';

export default function Step3Contents() {
  const { applyStatus } = useClientApplyData();
  const { id: key = '' } = useParams<{ id: string }>();

  const conFirmUrl = `https://${
    process.env.REACT_APP_ENV === 'DEV'
      ? 'dev.'
      : process.env.REACT_APP_ENV === 'STAGE'
      ? 'stage.'
      : ''
  }gameround.co/recruitment/${key}/confirm`;

  return (
    <Stack textAlign="center" alignItems="center" p="50px 0 110px">
      <GIcon iconName="round_check" iconSize={40} />

      <Typography variant="h5" mt="30px" width="610px" mb="32px">
        {applyStatus
          ? 'Your application for a private test has been completed. Thank you for your application.'
          : ' Your application for a private test has been completed.'}
      </Typography>

      <Typography variant="h6" color={applyStatus ? 'base.dimWhite' : 'error.main'} width="700px">
        {applyStatus
          ? 'We will send you a notification again when the test begins.'
          : 'We have registered you on the waiting list since we have exceeded the number of maximum applicants for this test. We will send you a notification if you are accepted.'}
      </Typography>

      {/* 3차때는 지워짐  */}
      <Box
        p="40px 0"
        mt="80px"
        sx={{ backgroundColor: (theme) => theme.palette.gray[100] }}
        width="100%"
      >
        <Typography mb="20px" variant="title">
          Non-Disclosure Agreement
        </Typography>

        <Link href={`/recruitment/${key}/confirm`} rel="noopener noreferrer" target="_blank">
          {conFirmUrl}
        </Link>

        <Typography variant="body2" mt="40px">
          You can access the NDA by following the link given above. <br />
          If you are unable to use the link or want to see the contract again, <br />
          please contact support. (
          <Link href="mailto:support@gameround.co" rel="noopener noreferrer" target="_blank">
            support@gameround.co
          </Link>
          )
        </Typography>
      </Box>

      <Box mt="60px">
        <LinkButton to={'/'} variant="contained" size="large">
          G.Round Home
        </LinkButton>
      </Box>
    </Stack>
  );
}
