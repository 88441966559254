import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  styled,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { groundDiscordChannel } from 'utils/common';

import { ReactComponent as ExpandMoreIcon } from 'images/about/aboutGamer/arrow_right.svg';
import { useLog } from 'utils/userLog';

const PREFIX = 'ArtAboutGamerFAQ';

const classes = {
  panelWrap: `${PREFIX}-panelWrap`,
  panel: `${PREFIX}-panel`,
  desc: `${PREFIX}-desc`,
};

const Root = styled('div')(({ theme }) => ({
  width: 1080,
  height: 'auto',
  paddingBottom: 80,

  '& .MuiAccordion-root:before': {
    backgroundColor: 'transparent',
  },

  //panel이 펼쳐진 상태
  '& .MuiAccordion-root.Mui-expanded': {
    margin: '20px auto 0',
  },
  '& .MuiAccordion-root.Mui-expanded:first-of-type': {
    marginTop: 20,
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 62,
  },

  //panel
  [`& .${classes.panelWrap}`]: {
    margin: '20px auto 0',
    border: `1px solid ${alpha(theme.palette.base.white, 0.15)}`,
    borderRadius: 5,
    backgroundColor: '#3348b2',
    backgroundImage: 'none',
    boxShadow: 'none',

    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
      paddingBottom: 8,
      paddingLeft: 12,
    },
  },

  [`& .${classes.panel}`]: {
    height: 62,
    padding: '0 30px 0 12px',
    alignItems: 'center',
    transition: 'none',

    //panel 확장 아이콘
    '& svg': {
      opacity: 0.2,
    },
    '&:hover': {
      opacity: 'initial',
      '& svg': {
        opacity: 'initial',
      },
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 20,
    },
  },

  [`& .${classes.desc}`]: {
    maxWidth: 976,
    marginTop: 8,
    marginBottom: 0,
    paddingLeft: 20,
  },
}));

const faqText = [
  {
    title: 'What kind of games can I find? And when will new games be uploaded?',
    desc: [
      <>
        You can find <strong>a wide genre of upcoming and unreleased PC & Console games</strong> for
        testing. We are currently not supporting mobile, but plan to test mobile games in the
        future.
      </>,
      'We have two new games every Thursday at 12:00 AM(PT)! You have the freedom to choose which game to play and participate in a review or a survey.',
    ],
  },
  {
    title: 'How do I install the games?',
    desc: [
      <>
        On the game page, click on the 'Play Game' button. A steam key will be given for you to
        redeem or the G.Round Launcher will launch.
      </>,
      <>
        <strong>For Steam,</strong> launch steam and click on 'Add a game' in the bottom left corner
        of the client, select 'Activate a Product on Steam' and fill in the Steam Key provided on
        the G.Round website.
      </>,
      <>
        <strong>For the G.Round Launcher,</strong> please install the launcher first. Boot up the
        launcher, click on the game you want to play, and click install for your download to start.
        After installing the game, you can launch the game via our launcher.
      </>,
    ],
  },
  {
    title: 'How long should I play the game? And how to fill out a review or a survey?',
    desc: [
      <>
        We recommend that you <strong>play the game for at least 1 hour</strong> or{' '}
        <strong>finish the whole demo</strong> that is available, this is of course entirely up to
        you, the player. You can fill out a survey after submitting the review.
      </>,
      'We encourage you to use your native language so you can fully express your opinion and ideas in your reviews and surveys, providing suggestions and constructive criticism that is useful for developers to improve their games.',
    ],
  },
  {
    title: 'Does G.Round have a Discord server?',
    desc: [
      <>
        We have our own Discord community where gamers, game developers, content creators, and our
        staff can connect with each other. If you are looking for other gamers or want to get in
        contact with game developers from around the world, then be sure to join our awesome
        community at:
        <br />
        <a
          href={groundDiscordChannel}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: '#0089f2' }}
        >
          {` ${groundDiscordChannel}`}
        </a>
      </>,
      'We use English as our main language within the server, but we also have a wide array of international lounges where gamers can talk to each other in their own language. Currently, we only open new language channels when there are a lot of users joining the server that speak the same language.',
    ],
  },
  {
    title: 'What benefits do I get by using G.Round?',
    desc: [
      <>
        You are able to get <strong>G.Points</strong> by submitting reviews and/or surveys, and you
        can get even more G.Points when your feedback is selected by the game studio.
      </>,
      <>
        We also host our own <strong>giveaways</strong> and <strong>special events,</strong> where
        you can win different prizes like Steam Cards or goodies provided by G.Round or by our
        partners. You need to follow the giveaway steps mentioned for your entries to be valid.
      </>,
    ],
  },
  {
    title: 'Where can I check the giveaway winners? And when can I expect to get my prize?',
    desc: [
      <>
        The giveaway winners will be announced on our Discord server as well as on our Facebook and
        Twitter pages.
      </>,
      <>
        It varies on the type of prize when you can expect your prize. For{' '}
        <strong>game keys,</strong> these will be distributed after the giveaway winners'
        announcement. For <strong>Steam gift cards,</strong> it will take up to 3 days after the
        announcement as we need to add you as a friend on Steam. For{' '}
        <strong>physical goodies,</strong> it can take 10 to 30 days depending on the country where
        you live, to receive your prize.
      </>,
    ],
  },
];

export default function ArtAboutGamerFAQ() {
  const { gxcTag } = useLog();
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    gxcTag('click', {
      area: 'statistics',
      ui: 'arrow',
    });
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Root>
      <Typography variant="h4" marginTop="80px" marginBottom="32px" textAlign="center">
        FAQ
      </Typography>

      {faqText.map((row, idx) => (
        <Accordion
          key={idx}
          className={classes.panelWrap}
          square
          expanded={expanded === idx}
          onChange={handleChange(idx)}
        >
          <AccordionSummary
            className={classes.panel}
            expandIcon={<ExpandMoreIcon />}
            sx={{ opacity: expanded === idx ? 'initial' : 0.6 }}
          >
            <Typography variant="h6">{row.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul className={classes.desc}>
              {row.desc.map((item, index) => (
                <Typography
                  component="li"
                  variant="body1"
                  sx={{ listStyleType: 'none' }}
                  key={index}
                >
                  {item}
                  <br />
                  <br />
                </Typography>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </Root>
  );
}
