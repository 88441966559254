import { useFetch } from 'api';
import useSWRFetch from 'api/useSWRFetch';

export interface RecruitmentData {
  game_id: string;
  discord_channel: string;
  confirm_yn: boolean;
  saved: number;
  num_comment: number;
  description: string;
  created_at: string;
  screenshot: Screenshot[];
  title: string;
  confirmed: number;
  screenshots: string;
  platform: string;
  recruit_start: string;
  studio_name: string;
  apply_yn: number;
  updated_at: string;
  system_requirements: string;
  intro: string;
  genre: string;
  num_like: number;
  recruit_end: string;
  genre_name: string;
  user_info: UserInfo;
  recruit_period: string;
  thumbnail_video_url: string;
  overview: string;
  url_link: string;
  images: Image[];
  cover_vidimg: string;
  lock_version: number;
  studio_name_yn: string;
  display: number;
  private_test: number;
  game_title: string;
  version: string;
  created_by: number;
  thumbnail_launcher_id: number;
  thumbnail_id: number;
  video_link: string;
  round: number;
  updated_by: number;
  started_at: string;
  test_period: string;
  studio_id: number;
  recruits: string;
  game_code: string;
  ended_at: string;
  interview_request_enable: boolean;
}

export interface Screenshot {
  game_id: number;
  language_code: string;
  description: string;
  overview: string;
  game_info_id: number;
  file_id: number;
  title: string;
  seq: number;
  original_name: string;
  folder: string;
  name: string;
  extension: string;
}

export interface UserInfo {
  id: number;
  google_id: string;
  birth_month: number;
  birth_date: number;
  cname: string;
  birth_year: number;
  language_code: string;
  influencer: boolean;
  browser: string;
  studiomember: string;
  email: string;
  identifier: string;
  loggedin_at: string;
  os: string;
  level: number;
  join_ip: string;
  subscribe_email: number;
  sex: string;
  verified: number;
  agreed_terms_at: string;
  filled: number;
  permission: number;
  ciso: string;
  supporter: string;
  birth_day: number;
  country_code: string;
  deleted: number;
  name: string;
  agreed_terms: boolean;
  device: string;
  auth_start_at: any;
  auth_end_at: any;
  sup_contract: any;
  reg_sup_at: any;
  fullname: any;
}

export interface Image {
  extension: string;
  image_url: string;
  type: string;
}

// 모집 신청 (요청) api
export function useRecruitmentApplyAPI(recruitmentCode: string) {
  return useFetch(
    `v2/game/recruitment/${recruitmentCode}`,
    {
      method: 'POST',
    },
    true
  );
}

// NDA 계약서 확인
export function useRecruitmentApplyConfirmAPI(key: string) {
  return useFetch(
    `game/recruitment/${key}/confirm`,
    {
      method: 'POST',
    },
    true
  );
}

export function useRecruitmentAPI(recruitmentCode: string) {
  const url = `/v2/game/recruitment/${recruitmentCode}`;
  return useSWRFetch<RecruitmentData>({ key: url, useToken: true });
}
