import { numberWithCommas } from '@ground/tools';
import { Review, ReviewPopupMenuItem } from '@ground/ui';
import { Collapse, Stack } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLikeCommentAPI, useReviewCommentDeleteAPI } from 'api/comment/commentAPI';
import { useDialog, useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';
import type { ReviewItemDataV1 } from 'types/CommentType';
import { useGetDetailData } from 'views/detail/DetailProvider';
import ReplyListContainer from '../reply/ReplyListContainer';

interface Props {
  reviewData: ReviewItemDataV1;
  mutateReviewList?: () => void;
  mutateReviewCount?: () => void;
}

export default function StudentReviewContainer({
  reviewData,
  mutateReviewList,
  mutateReviewCount,
}: Props) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showDialog } = useDialog();
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();

  const { data, files } = useGetDetailData();

  const [openReply, setOpenReply] = useState(false);

  const { fetch: fetchLike, isLoading: loadingLike } = useLikeCommentAPI(
    reviewData.game_id,
    reviewData.id
  );

  const isPrivate = typeof data?.private_test === 'number';

  const {
    id,
    game_id,
    text,
    replied_count: numReply,
    hidden,
    stat, // 0이면 삭제
    deletable,
    picked: isPicked,
    picked_issued_point: isGPIssued,

    user_name,
    user_pic_url,
    created_at,
    updated_at,

    user_badge,
    supporter: isSupporter,
    // university
    student_fullname,
    student_major,
    student_number,

    playing_time,
    total_playing_time,

    liked,
    liked_count,
  } = reviewData;

  const isDeleted = stat === 0 && !hidden;

  const showDeleteButton = !hidden && !isDeleted && deletable;
  const showLikeButton = !hidden && !isDeleted;
  const showReplyButton = !isDeleted;

  // delete
  const { isLoading: loadingDelete, fetch: fetchDelete } = useReviewCommentDeleteAPI(game_id, id);

  // 본인 삭제
  const mutateDelete = useCallback(async () => {
    if (loadingDelete) return;

    const response = await fetchDelete();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      mutateReviewList?.();
      mutateReviewCount?.();
    } else {
      showDialog({
        title: 'Error',
        body:
          response.data?.result?.code === 211003
            ? 'Reviews that have been issued G.P cannot be deleted.'
            : response.data?.result?.msg,
        primaryButton: {
          text: 'OK',
          ButtonProps: {
            color: isPrivate ? 'private' : 'primary',
            variant: 'contained',
          },
        },
      });
      return false;
    }
  }, [fetchDelete, isPrivate, loadingDelete, mutateReviewCount, mutateReviewList, showDialog]);

  const handleDeleteConfirm = useCallback(async () => {
    showDialog({
      title: 'Confirmation',
      body: (
        <>
          Are you sure you want to delete your review?
          <br />
          This action cannot be undone.
        </>
      ),
      primaryButton: { text: 'Confirm', callback: mutateDelete },
      secondaryButton: { text: 'Cancel' },
    });
  }, [mutateDelete, showDialog]);

  const menuList: ReviewPopupMenuItem[] = [
    {
      label: 'Copy Link',
      value: 'copy_link',
      onClick: () =>
        copy(`${window.location.origin}/detail/${game_id}/review/reviews/?review_id=${id}`),
    },
    ...(showDeleteButton
      ? [
          { value: '', divider: true },
          { label: 'Delete', value: 'delete', onClick: handleDeleteConfirm },
        ]
      : []),
  ];

  const handleClickLikeButton = useCallback(async () => {
    if (!isLogin) {
      showSignupDialog();
      return;
    }

    const response = await fetchLike();
    if (response.status === 200 && response.data.result?.code === 0) {
      reviewData.liked = !liked;
      reviewData.liked_count++;
      mutateReviewList?.();
    } else {
      showError(response, 'Failed to like review');
    }
  }, [fetchLike, isLogin, liked, mutateReviewList, reviewData, showError, showSignupDialog]);

  return (
    <Stack gap="36px">
      <Review
        userInfoProps={{
          userAvatarUrl: user_pic_url ?? '',
          username: user_name,
          university: {
            name: student_fullname ?? '',
            studentId: student_number ?? '',
            majorList: student_major ?? [],
          },
          badge: {
            adminBadge: Boolean(user_badge & 4),
            studioBadge: Boolean(user_badge & 2),
            creatorBadge: Boolean(user_badge & 1),
            supporterBadge: isSupporter,
            studentBadge: true, // studentReview에는 학생 리뷰만 나옴
          },
          timestamp: {
            deployType: files?.[0]?.deploy_type,
            playingTime: files?.[0]?.min_play ? playing_time || total_playing_time : 0,
            totalPlayingTime: total_playing_time,
          },
        }}
        statusAreaProps={{
          distributed: isGPIssued,
          status: {
            createdDate: created_at,
            editedDate: updated_at,
          },
          menuList: menuList,
        }}
        textfieldProps={{
          text: text,
          isTextSelectable: true,
          isBanned: hidden,
          isDeleted: isDeleted,
          hasHidePermission: false,
          picked: isPicked,
        }}
        bottomProps={{
          ...(showLikeButton && {
            likeProps: {
              isLiked: liked,
              text: liked_count > 9999 ? '+9,999' : numberWithCommas(liked_count),
              isLoading: loadingLike,
              onClick: handleClickLikeButton,
            },
          }),
          ...(showReplyButton && {
            replyProps: {
              replyCount: numReply,
              onClick: () => {
                setOpenReply((v) => !v);
              },
            },
          }),
        }}
      />
      <Collapse in={openReply} unmountOnExit>
        <ReplyListContainer
          gameId={game_id}
          reviewId={id}
          numReply={numReply}
          mutateReviewList={mutateReviewList}
        />
      </Collapse>
    </Stack>
  );
}
