import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFetch } from 'api';
import { LoginPayload } from 'models/login';
import { Dispatch, iRootState } from 'store';

export function useSigninWithTokenAPI() {
  return useFetch<LoginPayload, { token: string }>('sessions/token', { method: 'POST' });
}

export function useUpdateSignDataWithToken() {
  const { login } = useDispatch<Dispatch>();
  const { isLoading, fetch } = useSigninWithTokenAPI();

  const updateSignData = useCallback(
    async (token: string) => {
      const response = await fetch({ token });
      if (response?.status === 200 && response.data.result_code === 'OK') {
        login.login(response.data.data);
      } else if (response?.status === 462) {
        login.logout();
      }
    },
    [fetch, login]
  );

  return { isLoading, updateSignData };
}

// 유효한 토큰 검증 후 유효하지 않으면 logout 시키는 hook
export function useVerifyToken() {
  const navigate = useNavigate();
  const { access_token } = useSelector((state: iRootState) => state.login);
  const { login } = useDispatch<Dispatch>();
  const { fetch } = useSigninWithTokenAPI();

  const verifyToken = useCallback(async () => {
    if (access_token) {
      const response = await fetch({ token: access_token });
      // 유효한 토큰이 아니면 로그아웃 및 홈으로 이동
      // 존재하지 않는 계정이면 result_code 401, 기타 오류
      if (response.data.result_code !== 'OK') {
        login.logout();

        navigate('/', { replace: true });
      }
    }
  }, [access_token, fetch, login, navigate]);

  return { verifyToken };
}
