import { numberWithCommas } from '@ground/tools';
import { Review, ReviewPopupMenuItem } from '@ground/ui';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useGetPublicReviewDashboardAPI,
  useLikeCommentAPI,
  usePickReviewAPI,
  useReviewCommentDeleteAPI,
  useReviewCommentHideAPI,
  useUnpickReviewAPI,
} from 'api/comment/commentAPI';
import { useDialog, useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';
import type { ReviewItemDataV2 } from 'types/CommentType';
import { useGetDetailData } from 'views/detail/DetailProvider';
import ReplyListContainer from '../reply/ReplyListContainer';

interface Props {
  reviewData: ReviewItemDataV2;
  mutateReviewList?: () => void;
  mutateReviewCount?: () => void;
}

export default function ReviewContainerByAdmin({
  reviewData,
  mutateReviewList,
  mutateReviewCount,
}: Props) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showDialog } = useDialog();
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();

  const { files, permissionData, isAdmin } = useGetDetailData();

  const [openReply, setOpenReply] = useState(false);

  const { fetch: fetchLike, isLoading: loadingLike } = useLikeCommentAPI(
    reviewData.game_id,
    reviewData.id
  );

  const permission = permissionData?.data;

  const {
    id,
    game_id,
    text,
    replied_count: numReply,
    hidden,
    deletable,
    deleted: isDeleted,
    picked: isPicked,
    picked_issued_point: isGPIssued,
    purchased,
    filtered,

    user_name,
    user_pic_url,
    created_at,
    updated_at,

    user_badge,
    student: isStudent,
    supporter: isSupporter,

    playing_time,
    total_playing_time,

    liked,
    liked_count,
  } = reviewData;

  // delete
  const { isLoading: loadingDelete, fetch: fetchDelete } = useReviewCommentDeleteAPI(game_id, id);

  // pick
  const { isLoading: loadingPick, fetch: pickReview } = usePickReviewAPI(game_id, id);
  const { isLoading: loadingUnPick, fetch: unPickReview } = useUnpickReviewAPI(game_id, id);
  const { data: reviewDashboardData, mutate: mutateDashboard } = useGetPublicReviewDashboardAPI(
    String(game_id)
  );

  // hide
  const { isLoading: loadingHide, fetch: fetcHide } = useReviewCommentHideAPI(game_id, id);

  // 어드민 hide, user delete, AI filter가 아닌 유효한 리뷰
  const isValidReview = !hidden && !isDeleted && !filtered;

  const showDeleteButton = deletable && isValidReview;
  const showLikeButton = isValidReview;
  const showReplyButton = !isDeleted;
  // 포인트 지급 이전 && 픽 권한이 있어야 함 && 히든되거나 삭제되지 않은 리뷰
  const showPickButton =
    !reviewDashboardData?.data.is_paid && !!permission?.pick_game_review_permission && isValidReview;
  const showBlindButton = isValidReview && !!permission?.hide_comment_permission;

  // 본인 삭제
  const mutateDelete = useCallback(async () => {
    if (loadingDelete) return;

    const response = await fetchDelete();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      mutateReviewList?.();
      mutateReviewCount?.();
    } else {
      showDialog({
        title: 'Error',
        body:
          response.data?.result?.code === 211003
            ? 'Reviews that have been issued G.P cannot be deleted.'
            : response.data?.result?.msg,
        primaryButton: {
          text: 'OK',
        },
      });
      return false;
    }
  }, [fetchDelete, loadingDelete, mutateReviewCount, mutateReviewList, showDialog]);

  const handleDeleteConfirm = useCallback(async () => {
    showDialog({
      title: 'Confirmation',
      body: (
        <>
          Are you sure you want to delete your review?
          <br />
          This action cannot be undone.
        </>
      ),
      primaryButton: { text: 'Confirm', callback: mutateDelete },
      secondaryButton: { text: 'Cancel' },
    });
  }, [mutateDelete, showDialog]);

  const menuList: ReviewPopupMenuItem[] = [
    {
      label: 'Copy Link',
      value: 'copy_link',
      onClick: () =>
        copy(`${window.location.origin}/detail/${game_id}/review/reviews/?review_id=${id}`),
    },
    ...(showDeleteButton
      ? [
          { value: '', divider: true },
          { label: 'Delete', value: 'delete', onClick: handleDeleteConfirm },
        ]
      : []),
  ];

  const handleClickLikeButton = useCallback(async () => {
    if (!isLogin) {
      showSignupDialog();
      return;
    }

    const response = await fetchLike();
    if (response.status === 200 && response.data.result?.code === 0) {
      reviewData.liked = !liked;
      reviewData.liked_count++;
      mutateReviewList?.();
    } else {
      showError(response, 'Failed to like review');
    }
  }, [fetchLike, isLogin, liked, mutateReviewList, reviewData, showError, showSignupDialog]);

  //
  const maxPick = reviewDashboardData?.data.pick_amount;
  const pickedReviewsCount = reviewDashboardData?.data.picked_count;

  const pickReviewHandler = useCallback(async () => {
    const response = isPicked ? await unPickReview() : await pickReview();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      reviewData.picked = !isPicked;
      mutateReviewList?.();
      mutateDashboard();
    } else {
      if (response?.data?.result?.code === 999006 || response?.data?.result?.code === 999013) {
        showDialog({
          title: 'Account Permission Error',
          body: 'You need permission to use the selected function because your studio member account has been unlinked. Please contact the studio page administrator or G.Round manager for permission',
          primaryButton: { text: 'OK' },
        });
      } else if (response.data.result?.code) {
        showDialog({
          title: isPicked ? 'Canceling Review Selection Fail' : 'Review Selection Fail',
          body: response.data.result.msg,
          primaryButton: { text: 'OK' },
        });
      } else {
        showDialog({
          title: 'Unknown Error',
          body: 'An unknown error has occurred. Please check your network connection and try again later.',
          primaryButton: { text: 'OK' },
        });
      }
      return false;
    }
  }, [
    isPicked,
    unPickReview,
    pickReview,
    reviewData,
    mutateReviewList,
    mutateDashboard,
    showDialog,
  ]);

  const onClickPickButton = useCallback(() => {
    // 이미 pick 개수가 다 차있는데 더 픽을 하려하는 경우 에러
    if (
      !isPicked &&
      typeof maxPick === 'number' &&
      typeof pickedReviewsCount === 'number' &&
      pickedReviewsCount >= maxPick
    ) {
      showDialog({
        title: 'Error',
        body: (
          <>
            <Typography variant="body1" color="text.primary">
              You have exceeded the maximum limit of selected reviews.
            </Typography>
            <Typography variant="body2" color="text.secondary2" mt="12px">
              If you wish to select this review, please deselect one of the currently chosen reviews
              and try again.
            </Typography>
          </>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    showDialog({
      title: isPicked ? 'Cancel Review Selection' : 'Confirm Review Selection',
      body: isPicked ? (
        <Typography variant="body2" color="text.secondary">
          Do you wish to cancel your selection? Once canceled, the review will no longer appear in
          the 'Picked Reviews' tab, and the user will not receive G.Points for this review.
        </Typography>
      ) : (
        <>
          <Typography variant="body2" color="text.secondary">
            Do you want to select this review?
          </Typography>
          <Box mt="20px" sx={{ display: 'flex', gap: '10px' }}>
            <Typography variant="body2" color="text.secondary2">
              Review Selected
            </Typography>
            <Typography variant="body2" color="text.primary">
              {pickedReviewsCount ?? 0}
            </Typography>
          </Box>
          <Box mt="4px" sx={{ display: 'flex', gap: '10px' }}>
            <Typography variant="body2" color="text.secondary2">
              Remaining Selections Available
            </Typography>
            <Typography variant="body2" color="text.primary">
              {typeof maxPick === 'number' && typeof pickedReviewsCount === 'number'
                ? maxPick - pickedReviewsCount
                : 0}
            </Typography>
          </Box>
        </>
      ),
      secondaryButton: { text: 'Cancel' },
      primaryButton: { text: 'OK', callback: pickReviewHandler, isLoadingButton: true },
    });
  }, [isPicked, maxPick, pickReviewHandler, pickedReviewsCount, showDialog]);

  // admin 삭제
  const mutateHide = useCallback(async () => {
    const response = await fetcHide();
    if (response.status === 200 && response?.data?.result?.code === 0) {
      reviewData.hidden = !hidden;
      mutateReviewList?.();
      mutateReviewCount?.();
    } else {
      showDialog({
        title: 'Hide Failed',
        body: response.data?.result?.msg,
        primaryButton: { text: 'OK' },
      });
      return false;
    }
  }, [fetcHide, hidden, mutateReviewCount, mutateReviewList, reviewData, showDialog]);

  const handleBlindComment = useCallback(async () => {
    showDialog({
      title: 'Review Hide',
      body: 'Do you want to hide this review?',
      secondaryButton: { text: 'Cancel' },
      primaryButton: {
        text: 'OK',
        callback: mutateHide,
        isLoadingButton: true,
      },
    });
  }, [showDialog, mutateHide]);

  return (
    <Stack gap="36px">
      <Review
        userInfoProps={{
          userAvatarUrl: user_pic_url ?? '',
          username: user_name,
          badge: {
            adminBadge: Boolean(user_badge & 4),
            studioBadge: Boolean(user_badge & 2),
            creatorBadge: Boolean(user_badge & 1),
            supporterBadge: isSupporter,
            studentBadge: isStudent && isAdmin, // review에서 student 뱃지는 어드민만 보여야 함
          },
          timestamp: {
            deployType: files?.[0]?.deploy_type,
            playingTime: files?.[0]?.min_play ? playing_time || total_playing_time : 0,
            totalPlayingTime: total_playing_time,
          },
        }}
        statusAreaProps={{
          distributed: isGPIssued,
          status: {
            createdDate: created_at,
            editedDate: updated_at,
            purchased: purchased,
          },
          menuList: menuList,
        }}
        textfieldProps={{
          text: text,
          isTextSelectable: true,
          isBanned: hidden || filtered,
          isDeleted: isDeleted,
          hasHidePermission: permission?.hide_comment_permission,
          picked: isPicked,
        }}
        bottomProps={{
          ...(showLikeButton && {
            likeProps: {
              isLiked: liked,
              text: liked_count > 9999 ? '+9,999' : numberWithCommas(liked_count),
              isLoading: loadingLike,
              onClick: handleClickLikeButton,
            },
          }),
          ...(showReplyButton && {
            replyProps: {
              replyCount: numReply,
              onClick: () => {
                setOpenReply((v) => !v);
              },
            },
          }),
          ...(showPickButton && {
            pickProps: {
              isPicked: isPicked,
              loading: loadingPick || loadingUnPick,
              onClick: onClickPickButton,
            },
          }),
          ...(showBlindButton && {
            blindProps: {
              isBlind: false,
              loading: loadingHide,
              onClick: handleBlindComment,
            },
          }),
        }}
      />
      <Collapse in={openReply} unmountOnExit>
        <ReplyListContainer
          gameId={game_id}
          reviewId={id}
          numReply={numReply}
          mutateReviewList={mutateReviewList}
        />
      </Collapse>
    </Stack>
  );
}
