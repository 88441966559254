import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import { ImageBox } from 'atoms';
import { YoutubePlayer } from 'components';
import { Carousel } from 'elements';
import { useGetDetailData } from 'views/detail/DetailProvider';

const WIDTH = 827;
const HEIGHT = 465;

export default function DetailSlide() {
  const { contents } = useGetDetailData();
  const [pause, setPause] = useState(false);

  const handleTransitionStart = () => {
    setPause(true);
  };
  const handleTransitionEnd = () => {
    setPause(false);
  };

  return contents ? (
    <Carousel
      width={WIDTH}
      height={HEIGHT}
      name="detail-contents"
      bulletWidth={48}
      hasPagination
      onSlideChangeTransitionStart={handleTransitionStart}
      onSlideChangeTransitionEnd={handleTransitionEnd}
    >
      {contents?.map((content) =>
        content.type === 'VIDEO' ? (
          <SwiperSlide key={content.id}>
            <YoutubePlayer url={content.link} globalPause={pause} />
          </SwiperSlide>
        ) : (
          <SwiperSlide key={content.id} style={{ width: WIDTH, height: HEIGHT }}>
            <ImageBox
              key={content.id}
              src={content.image_url}
              alt="cover image"
              resize
              resizeWidth={WIDTH}
              resizeHeight={HEIGHT}
              format="webp"
              sx={{ borderRadius: '5px' }}
            />
          </SwiperSlide>
        )
      )}
    </Carousel>
  ) : (
    <Skeleton
      width={WIDTH}
      height={HEIGHT}
      animation="wave"
      variant="rectangular"
      sx={{ marginBottom: '45px', background: '#88919e', opacity: 0.3 }}
    />
  );
}
