import { Route, Routes } from 'react-router-dom';

import LauncherAuthMain from './LauncherAuthMain';
import Success from './Success';
import Error from './Error';

// launcher oauth 공용 페이지
export default function LauncherAuth() {
  return (
    <Routes>
      <Route path="success" element={<Success />} />
      <Route path="error" element={<Error />} />
      <Route path="*" element={<LauncherAuthMain />} />
    </Routes>
  );
}
