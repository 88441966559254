import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import type { AxiosResponse } from 'axios';
import { differenceInYears } from 'date-fns';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useSaveProfileAPI, type ProfileData, type ProfileFormData } from 'api/profile/profileAPI';
import { useDialog } from 'hooks';
import type { ResponseData } from 'types/ResponseType';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import SignupBirthday from './SignupBirthday';
import SignupCountry from './SignupCountry';
import SignupGender from './SignupGender';
import SignupNewsletter from './SignupNewsletter';
import SignupUsername from './SignupUsername';

interface SignupProfileFormProps {
  defaultValues?: Partial<ProfileFormData>;
  isMobile?: boolean;
  includeNewsletter?: boolean;
  submitText?: string;
  type?: 'page' | 'dialog'; // 페이지에서 사용될 때와 다이얼로그에서 사용될 때 스타일 구분
  onSubmitSuccess?: (responseData: ProfileData) => Promise<void>;
  onSubmitError?: (response: AxiosResponse<ResponseData>) => void;
}
export default function SignupProfileForm({
  defaultValues,
  isMobile,
  includeNewsletter,
  submitText,
  type = 'page',
  onSubmitSuccess,
  onSubmitError,
}: SignupProfileFormProps) {
  const { fetch } = useSaveProfileAPI();
  const methods = useForm<ProfileFormData>({
    // 뉴스레터 체크폼이 있을 때만 뉴스레터의 기본 값을 true로 넣어준다.
    defaultValues: { ...defaultValues, ...(includeNewsletter && { subscribe_email: true }) },
    mode: 'onChange',
  });
  const { showDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const { gxcTag } = useLog();

  const onSubmit = async (formData: ProfileFormData) => {
    if (!formData.birthday) return;

    //나이 13년 차이 체크
    const resultYears = differenceInYears(new Date(), formData.birthday);

    if (resultYears < 13) {
      showDialog({
        title: 'Warning',
        body: 'To ensure your safety, we restricted access to G.Round for younger audiences. We apologize for the inconvenience.',
        primaryButton: { text: 'Ok' },
      });
      return;
    }

    setLoading(true);

    const response = await fetch({
      name: formData.name.trim(),
      birth_month: formData.birthday.getMonth() + 1,
      birth_day: formData.birthday.getDate(),
      birth_year: formData.birthday.getFullYear(),
      country_code: formData.country?.value ? formData.country.value.toString() : undefined,
      sex: formData.sex,
      ...(includeNewsletter && { subscribe_email: formData.subscribe_email }),
    });
    if (response?.status === 200) {
      if (response?.data.result_code === 'OK') {
        await onSubmitSuccess?.(response.data.data);
      } else {
        if (response.data.result_code === '408') {
          // '408', 'U010', 'User name already exists!'
          // if (response.data.data.error_code.code === 'U010')
          methods.setError(
            'name',
            { message: 'This username is already taken.' },
            { shouldFocus: true }
          );
        }
        onSubmitError?.(response);
      }
    } else {
      onSubmitError?.(response);
    }

    setLoading(false);
  };

  const logArea = type === 'page' ? 'detail' : 'personal_detail_popup';

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack gap="20px">
          <SignupUsername isMobile={isMobile} defaultValue={defaultValues?.name} />
          <SignupCountry isMobile={isMobile} logArea={logArea} />
          <SignupGender isMobile={isMobile} logArea={logArea} />
          <SignupBirthday isMobile={isMobile} logArea={logArea} />
          {/* subscribe email checkbox */}
          {includeNewsletter && <SignupNewsletter />}
        </Stack>

        <LoadingButton
          color="primary"
          fullWidth
          loading={loading}
          onClick={() => {
            eventTracker('signup_completesignup');
            gxcTag('click', {
              area: logArea,
              ui:
                type === 'dialog'
                  ? 'complete'
                  : submitText
                  ? submitText.toLocaleLowerCase()
                  : 'complete_signup',
            });
          }}
          size={isMobile ? 'largest' : 'large'}
          sx={{ marginTop: '40px' }}
          type="submit"
          variant="contained"
        >
          {submitText ?? 'Complete Sign up'}
        </LoadingButton>
      </form>
    </FormProvider>
  );
}
