import { Box, Stack, styled } from '@mui/material';
import NotFound from 'views/main/NotFound';

import { LoadingWrapper } from '@ground/ui';
import SupporterApplyCotents from './SupporterApplyCotents';
import { useSupporterApplyData } from './SupporterApplyProvider';
import SupporterApplyStepper from './SupporterApplyStepper';

import { Dropdown, DropdownOptionValue } from '@ground/ui';

import JpnSupporterApplyCotents from './jpn/SupporterApplyCotents';
import JpnSupporterApplyStepper from './jpn/SupporterApplyStepper';

// 프린트 display: none 처리
const RootBox = styled(Stack)(({ theme }) => ({
  '@media print': {
    display: 'none',
  },
}));

const options = [
  { name: 'English', value: 'EN' },
  { name: '日本語', value: 'JA' },
];

export default function SupporterApplyMain() {
  const { language, setLanguage, loading, error } = useSupporterApplyData();

  const handleChangeSelect = (option: DropdownOptionValue) => {
    setLanguage(option.toString());
  };

  return error ? (
    <NotFound />
  ) : (
    <LoadingWrapper isLoading={loading} sx={{ height: '500px' }}>
      <RootBox display="flex" width="1200px">
        <Box display={'flex'} justifyContent="flex-end" width="100%" m="20px 0">
          <Dropdown
            size="small"
            width={120}
            placeholder="Language"
            options={options}
            onChangeValue={handleChangeSelect}
            value={language}
          />
        </Box>

        {language === 'JA' ? (
          <>
            <JpnSupporterApplyStepper />
            <JpnSupporterApplyCotents />
          </>
        ) : (
          <>
            <SupporterApplyStepper />
            <SupporterApplyCotents />
          </>
        )}
      </RootBox>
    </LoadingWrapper>
  );
}
