import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'dark',

  primary: {
    light: '#5786FF',
    main: '#3C66FB',
    dark: '#003CC7',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#3a5073',
  },
  error: {
    light: '#FF6262',
    main: '#E94040',
    dark: '#D93C3C',
  },
  warning: {
    light: '#FFBC1F',
    main: '#F6A609',
    dark: '#E89806',
  },
  info: {
    main: '#00E0FF',
  },
  success: {
    light: '#34F353',
    main: '#2AC769',
    dark: '#098E40',
  },
  light: {
    light: '#FFFFFF',
    main: '#D2D7DD',
    dark: '#ACB5C0',
    contrastText: '#000000',
  },
  dark: {
    light: '#222222',
    main: '#181818 ',
    dark: '#121212',
    contrastText: '#FFFFFF',
  },
  base: {
    dimWhite: '#D2D7DD',
    white: '#FFFFFF',
    black: '#000000',
  },
  blue: {
    primary: '#3C66FB',
    hover: '#5786FF',
    pointBG: '#3348B2',
  },
  private: {
    primary: '#00A184',
    hover: '#00BB99',
  },
  gray: {
    10: '#8D8D8D',
    20: '#767676',
    30: '#555555',
    40: '#464646',
    50: '#333333',
    60: '#292929',
    70: '#222222',
    80: '#1E1E1E',
    90: '#181818',
    100: '#121212',
  },
  opacity: {
    black: {
      5: 'rgba(0, 0, 0, 0.05)',
      10: 'rgba(0, 0, 0, 0.1)',
      15: 'rgba(0, 0, 0, 0.15)',
      20: 'rgba(0, 0, 0, 0.2)',
    },
    white: {
      5: 'rgba(255, 255, 255, 0.05)',
      10: 'rgba(255, 255, 255, 0.1)',
      15: 'rgba(255, 255, 255, 0.15)',
      20: 'rgba(255, 255, 255, 0.2)',
    },
  },
  background: {
    default: '#121212',
    paper: '#181818',
  },

  text: {
    primary: '#DDE2E8',
    secondary: '#B6B6B6',
    secondary2: '#707070',
    disabled: '#5D6572',
    private: '#00A184',
  },
  divider: 'rgba(255, 255, 255, 0.05)',
};

export default palette;
