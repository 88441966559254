import { GTab, GTabs } from '@ground/ui';
import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GameStatusType } from 'types/GameCommonTypes';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from 'views/detail/DetailProvider';

// private 게임일 때 Clips 안보이게 처리
export default function DetailTabs() {
  const { data } = useGetDetailData();
  const { id = '', tab = 'info' } = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(tab);
  const currentRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isPrivate = typeof data?.private_test === 'number';

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
    navigate(`../${newValue}`);
    eventTracker(`gamedetail_tab_${newValue}`, { event_label: id });
  };

  const tabColor = theme.palette.primary.main;

  // url의 tab 값이 변경된 경우에도 탭이 이동하도록 로직 추가
  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  return (
    <GTabs
      id="detail-tab"
      value={tabValue}
      onChange={handleChange}
      aria-label="detail nav tabs"
      ref={currentRef}
      indicatorBgColor={tabColor}
      height={37}
    >
      <GTab label="Game Info" value="info" color={tabColor} />
      <GTab label="Review" value="review" color={tabColor} />
      {!isPrivate && <GTab label="Clips" value="clips" color={tabColor} />}
      <GTab label="News & Updates" value="news" color={tabColor} />
      {/* 게임 테스트 중 일 때만 guide tab 노출 */}
      {(data?.state === GameStatusType.TESTING ||
        data?.state === GameStatusType.SURVEY ||
        data?.survey_type === 'GLOBAL') && (
        <GTab label="How to Play" value="guide" color={tabColor} />
      )}
    </GTabs>
  );
}
