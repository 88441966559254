import { numberWithCommas } from '@ground/tools';
import {
  Box,
  Button,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

import { CountryStatisticsDataType } from 'types/AboutStatisticsType';

const StyledRegionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 229,
    maxWidth: 300,
    padding: 20,
    backgroundColor: '#3c53c6',
    borderRadius: 5,
  },
}));

const RegionButton = styled(Button)({
  width: 96,
  height: 96,
  borderRadius: '50%',
  backgroundColor: 'rgba(60, 83, 198, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(60, 83, 198, 1)',
    '& h6': {
      opacity: 'initial',
    },
  },
});

export default function ArtDevAboutStatisticsTooptip({
  region_name,
  country_count,
  user_count,
  game_count,
}: CountryStatisticsDataType) {
  return (
    <StyledRegionTooltip
      title={
        <>
          <Typography variant="subtitle1">{region_name}</Typography>
          <Box display="flex" sx={{ marginTop: '16px' }}>
            <Box sx={{ marginRight: '20px' }}>
              <Typography variant="subtitle3">Total Regions</Typography>
              <Typography variant="subtitle3" sx={{ margin: '12px 0 12px' }}>
                Total Users
              </Typography>
              <Typography variant="subtitle3">Total Game Tested</Typography>
            </Box>
            <Box>
              <Typography variant="small1">{country_count}</Typography>
              <Typography variant="small1" sx={{ margin: '12px 0 12px' }}>
                {numberWithCommas(user_count)}
              </Typography>
              <Typography variant="small1">{game_count}</Typography>
            </Box>
          </Box>
        </>
      }
    >
      <RegionButton>
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{
            whiteSpace: 'normal',
            opacity: 0.5,
          }}
        >
          {region_name}
        </Typography>
      </RegionButton>
    </StyledRegionTooltip>
  );
}
