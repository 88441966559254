import { LoadingWrapper } from '@ground/ui';
import { useCallback } from 'react';

import { useGetReplyListAPI } from 'api/comment/commentAPI';
import ReplyList from './ReplyList';

const REPLY_COUNT = 30;

interface Props {
  gameId: number;
  reviewId: number;
  numReply: number;
  mutateReviewList?: () => void;
}

export default function ReplyListContainer({
  gameId,
  reviewId,
  numReply,
  mutateReviewList,
}: Props) {
  const {
    data,
    isLoading,
    isValidating,
    size,
    setSize,
    mutate: mutateReplyList,
  } = useGetReplyListAPI(gameId, reviewId, REPLY_COUNT);

  // 최대 페이지 사이즈
  const maxPageSize = Math.ceil(numReply / REPLY_COUNT);
  // 마지막 페이지 확인
  const isSizeLessMaxPageSize = size < maxPageSize;

  // reply list 갱신 함수
  const reloadReplyHandler = useCallback(() => {
    mutateReplyList();
  }, [mutateReplyList]);

  // post reply
  const postReplyHandler = useCallback(() => {
    mutateReviewList?.();
  }, [mutateReviewList]);

  // 다음 페이지 불러오기
  const increaseSizeHandler = useCallback(() => {
    setSize((size) => size + 1);
  }, [setSize]);

  return (
    <LoadingWrapper isLoading={isLoading} size={48}>
      <ReplyList
        gameId={gameId}
        reviewId={reviewId}
        reloadReplyHandler={reloadReplyHandler}
        postReplyHandler={postReplyHandler}
        onViewMore={increaseSizeHandler}
        replyListData={data?.map((v) => v.data.map((v) => v)).flat() ?? []}
        showViewMore={isSizeLessMaxPageSize}
        loadingViewMore={isValidating}
      />
    </LoadingWrapper>
  );
}
