import { Stack } from '@mui/material';

import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import GStoreOrderDetailDelivery from './GStoreOrderDetailDelivery';
import GStoreOrderDetailNotificationBanner from './GStoreOrderDetailNotificationBanner';
import GStoreOrderDetailPaymentBox from './GStoreOrderDetailPaymentBox';
import { useGStoreOrderDetailData } from './GStoreOrderDetailProvider';
import GStoreOrderDetailShippingInfoBox from './GStoreOrderDetailShippingInfoBox';
import GStoreOrderDetailTitleBox from './GStoreOrderDetailTitleBox';

export function GStoreOrderDetailMain() {
  const { isLoading, data } = useGStoreOrderDetailData();
  const orderId = data?.data?.id;

  return isLoading ? (
    <LoadingPage />
  ) : orderId ? (
    <Stack rowGap="60px" sx={{ padding: '60px 0 200px' }}>
      <GStoreOrderDetailTitleBox />
      <GStoreOrderDetailDelivery />
      <GStoreOrderDetailPaymentBox />
      <GStoreOrderDetailShippingInfoBox />
      <GStoreOrderDetailNotificationBanner />
    </Stack>
  ) : (
    <NotFound />
  );
}
