import { LoadingCounter } from '@ground/ui';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import { Swiper } from 'swiper/react';

import { useAboutCountryStatisticsAPI } from 'api/about/aboutCountryStatisticsAPI';

import aboutBackgroundImage from 'images/mobile/home/about_bg.webp';
import aboutBackgroundImage2x from 'images/mobile/home/about_bg@2x.webp';
import aboutBackgroundImage3x from 'images/mobile/home/about_bg@3x.webp';

const AboutContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  paddingTop: 60,
  paddingBottom: 60,
}));

interface CountItemProps {
  isLoading: boolean;
  count: number;
  title: string;
  subtitle: string;
}

const CountItem = ({ isLoading, count, title, subtitle }: CountItemProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography variant="small2" color="rgba(255, 255, 255, 0.4)">
      {title}
    </Typography>
    <Typography variant="h5">
      <LoadingCounter id={`counter_${title.toLowerCase()}`} loading={isLoading} endCount={count} />
    </Typography>
    <Typography variant="subtitle2">{subtitle}</Typography>
  </Box>
);

const About: React.FC = () => {
  const { data, isLoading } = useAboutCountryStatisticsAPI();

  return (
    <AboutContainer>
      <img
        src={aboutBackgroundImage}
        srcSet={`${aboutBackgroundImage}, ${aboutBackgroundImage2x} 2x, ${aboutBackgroundImage3x} 3x`}
        alt="background"
        style={{
          objectFit: 'cover',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          zIndex: -1,
        }}
      />
      <Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant="h4" mb="40px">
          About G.Round
        </Typography>
        <Stack
          direction="row"
          divider={
            <Divider
              flexItem
              orientation="vertical"
              sx={{ borderColor: 'rgba(255,255,255,0.15)' }}
            />
          }
          justifyContent="space-evenly"
          sx={{ marginBottom: '40px' }}
        >
          <CountItem
            isLoading={isLoading}
            count={data?.data?.summary?.game_count ?? 0}
            title="Featured"
            subtitle="Games"
          />
          <CountItem
            isLoading={isLoading}
            count={data?.data?.summary?.user_count ?? 0}
            title="Over"
            subtitle="Users"
          />
          <CountItem
            isLoading={isLoading}
            count={data?.data?.summary?.country_count ?? 0}
            title="Users in"
            subtitle="Regions"
          />
        </Stack>
        <Box sx={{ width: '280px' }}>
          <Typography variant="body2" color="text.primary" mb="8px">
            We find the best upcoming games for you! Play our selection of curated games every week
            from 2D platformers to Souls-like incredible titles.
          </Typography>
          <Typography variant="body2" color="text.primary">
            We scour the Earth to find new unpublished games. Come and check out our new games that
            are released every Thursday!
          </Typography>
        </Box>
      </Box>
      <Swiper
        spaceBetween={16}
        style={{
          paddingRight: 20,
          paddingLeft: 20,
          marginTop: 40,
        }}
        slidesPerView="auto"
      >
        <iframe
          title="G.Round"
          src="https://discord.com/widget?id=601726723947757571&theme=dark"
          width="100%"
          height="360"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </Swiper>
    </AboutContainer>
  );
};

export default About;
