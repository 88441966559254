import { InputField } from '@ground/ui';
import { Typography } from '@mui/material';

import { useSendEmailVerificationCodeAPI } from 'api/email/emailAPI';
import { useDialog, useVerificationDialog } from 'hooks';
import eventTracker from 'utils/eventTracker';

interface Props {
  email?: string;
  verify: boolean;
}

export default function AccountInfoEmail({ email, verify }: Props) {
  const { showDialog } = useDialog();
  const { isLoading, fetch } = useSendEmailVerificationCodeAPI();
  const { showVerificationDialog } = useVerificationDialog(true);

  const handleClickVerify = async () => {
    if (isLoading) return;

    const response = await fetch();
    if (response.status === 200 && response.data.result_code === 'OK') {
      showVerificationDialog();
    } else {
      showDialog({
        title: 'Error',
        body: 'An error occurred while sending verification email or email address does not exist.',
        primaryButton: { text: 'Close' },
      });
    }
    eventTracker('profile_profile_accountinfo_verifyemail');
    twttr?.conversion.trackPid('o91dp', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/5nEKCMudsMQDEI29kb8C' });
  };

  return (
    <>
      <Typography variant="subtitle2" color="text.secondary" mb="8px">
        Email
      </Typography>
      <InputField
        defaultValue={email}
        fullWidth
        readOnly
        withBackground
        size="large"
        error={!verify}
        endAdornment={
          verify ? (
            <Typography color="primary.main" variant="body2">
              VERIFIED
            </Typography>
          ) : (
            <Typography
              color="text.primary"
              variant="body2"
              onClick={handleClickVerify}
              sx={{
                textDecorationLine: 'underline',
                opacity: isLoading ? 0.5 : 1,
                cursor: isLoading ? 'auto' : 'pointer',
                pointerEvents: isLoading ? 'none' : 'auto',
              }}
            >
              Verify Email
            </Typography>
          )
        }
      />
    </>
  );
}
