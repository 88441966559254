import { Typography, Button, Stack } from '@mui/material';

interface DeleteAccountProps {
  closeDialog: () => void;
  onClickDeleteConfirmHandler: () => void;
}
// 설문 전 탈퇴 진행 Dialog (단순 UI)
export default function DeleteAccountDialog({
  closeDialog,
  onClickDeleteConfirmHandler, 
}: DeleteAccountProps) {
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        Initiating account deletion gives you 30 days before your account, data, and any G.Points
        balance are permanently removed.{' '}
        <Typography variant="body2" component="span" color="text.primary">
          Deleting your G.Round account also erases your purchased G.Points balance.
        </Typography>
      </Typography>
      <br />
      <Typography variant="body2" color="text.secondary">
        This action cannot be undone.
      </Typography>
      <br />
      <Typography variant="body2" color="text.secondary">
        To reactivate your account before deletion, log back into G.Round within 30 days.
      </Typography>

      <Typography variant="small1" color="text.primary" mt="40px">
        Are you sure saying goodbye to us?
      </Typography>

      <Stack direction="row" gap="10px" mt="40px">
        <Button color="primary" variant="ghost" onClick={closeDialog} fullWidth>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={onClickDeleteConfirmHandler} fullWidth>
          Confirm
        </Button>
      </Stack>
    </>
  );
}
