import { Box, Typography } from '@mui/material';

const tierListText = [
  { info: 'First 200 people~', title: 'Download & Played', price: '5 G.P~' },
  { info: 'First 150 people~', title: 'Completed Reviews', price: '15 G.P~' },
  { info: 'Up to 20 people~', title: 'Review Selected by Studio', price: '25 G.P~' },
  { info: 'First 100 people~', title: 'Completed Surveys', price: '20 G.P~' },
  { info: 'Up to 10 people~', title: 'Survey Selected by Studio', price: '35 G.P~' },
];

const stepListText = [
  { info: 'Step 1', title: 'Download the game and join the playtest.' },
  {
    info: 'Step 2',
    title: 'Complete the requirement for minimum playtime.',
    helper: '*The minimum playtime varies for each game.',
  },
  {
    info: 'Step 3',
    title: 'Write a review and submit.',
    helper: '*Earn extra points if the studio picks your review!',
  },
  {
    info: 'Step 4',
    title: 'Fill and submit the survey.',
    helper: '*Earn extra points if the studio selects your survey!',
  },
];

export default function EarnGPoints() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.gray[90],
        width: '100%',
        padding: '100px 0',
      })}
    >
      <Box sx={{ width: '1200px', margin: 'auto' }}>
        <Typography variant="h5">How to Get G.Points</Typography>

        <Box
          sx={(theme) => ({
            display: 'flex',
            mt: '40px',
            backgroundColor: theme.palette.gray[90],
            gap: '200px',
          })}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" color="text.secondary2" mb="32px">
              G.Points Tier List
            </Typography>
            <Box>
              {tierListText.map((tierText, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: ({ palette }) =>
                      index !== tierListText.length - 1
                        ? `1px solid ${palette.opacity.white[10]}`
                        : undefined,
                    width: '380px',
                    marginBottom: '15px',
                  }}
                >
                  <Typography variant="subtitle3" color="text.secondary2">
                    {tierText.info}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt="4px" pb="15px">
                    <Typography variant="subtitle1">{tierText.title}</Typography>
                    <Typography variant="subtitle1">{tierText.price}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" color="text.secondary2">
              How to get G.Points
            </Typography>
            <Box mt="40px">
              {stepListText.map((text, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: ({ palette }) =>
                      index !== stepListText.length - 1
                        ? `1px solid ${palette.opacity.white[10]}`
                        : undefined,
                    width: '620px',
                    marginBottom: '20px',
                    display: 'flex',
                    paddingBottom: '20px',
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {text.info}
                  </Typography>
                  <Box ml="42px">
                    <Typography variant="subtitle1">{text.title}</Typography>
                    {text.helper && (
                      <Typography variant="body2" color="text.secondary2" mt="4px">
                        {text.helper}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
