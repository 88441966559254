import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { useDialog } from 'hooks';
import { iRootState } from 'store';
import { useLog } from 'utils/userLog';
import ProfileDialog from './ProfileDialog';

export default function useProfileDialog() {
  const { closeDialog, showDialog } = useDialog();
  const { isDesktopTransformed } = useSelector((state: iRootState) => state.mobile);
  const { gxcTag } = useLog();
  const isMobile = isMobileOnly && !isDesktopTransformed;

  const showProfileDialog = useCallback(async () => {
    gxcTag('view', { area: 'personal_detail_popup' });
    showDialog({
      title: 'Personal Details',
      body: <ProfileDialog isMobile={isMobile} onSubmitSuccess={() => closeDialog()} />,
      width: 380,
      onClickClose: () => {
        gxcTag('click', { area: 'personal_detail_popup', ui: 'close_x' });
      },
    });
  }, [closeDialog, gxcTag, isMobile, showDialog]);

  return { showProfileDialog };
}
