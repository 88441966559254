import { Box, Typography, styled } from '@mui/material';
import lottie from 'lottie-web/build/player/lottie_light';
import { useRef } from 'react';

import { GIconButton } from '../../atoms';

import lottieLikeButton from './like-button.json';

const LottieDiv = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  width: 32,
  height: 32,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

interface Props {
  isLiked: boolean;
  isLoading?: boolean;
  text: string;
  onClick?: () => boolean | void | Promise<boolean | void>;
}

export default function ReviewLikeButton({ isLiked, isLoading, text, onClick }: Props) {
  // 로티 애니메이션으로 구성되는 element
  const element = useRef<HTMLDivElement>(null);

  const handleClick = async () => {
    if (isLoading) return;

    const result = await onClick?.();
    if (result === false) return;

    // 좋아요 애니메이션 재생
    if (element.current) {
      const lottieItem = lottie.loadAnimation({
        container: element.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: lottieLikeButton,
      });
      lottieItem.addEventListener('complete', function () {
        lottieItem.removeEventListener('complete');
        lottie.destroy();
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <GIconButton
          iconName={isLiked ? 'heart_on' : 'heart_off'}
          iconSize={16}
          iconColor="error.main"
          onClick={handleClick}
          disableHoverEffect
          ButtonProps={{ disabled: isLiked }}
        />
        <LottieDiv ref={element} />
      </Box>
      <Typography variant="body3" component="span" color="text.secondary">
        {text}
      </Typography>
    </Box>
  );
}
