import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import { GPointProductType, useCheckUserIsAdultAPI } from 'api/payment/paymentAPI';
import { useDialog } from 'hooks';
import PaymentWrapper from 'views/gstore/payment/PaymentWrapper';
import { AgreeTermText } from '../order/AgreeTermText';
import { GPointsTable } from './GPointsTable';

interface Props {
  onComplete?: () => void;
}

export default function GPointsDialogBody({ onComplete }: Props) {
  const { showDialog } = useDialog();
  const [toggleAgree, setToggleAgree] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<GPointProductType | undefined>(undefined);
  const { isLoading: isAdultLoading } = useCheckUserIsAdultAPI();
  const theme = useTheme();

  const initializeStates = () => {
    setSelectedProduct(undefined);
    setToggleAgree(false);
  };

  return (
    <Box mt="20px">
      <Typography variant="body2" color="text.secondary">
        Select G.Points amount & payment.
      </Typography>
      <GPointsTable
        selectedProduct={selectedProduct}
        onItemClick={(item) => {
          setSelectedProduct(item);
        }}
        isAdultLoading={isAdultLoading}
      />
      {/* <Box
        sx={{
          padding: '20px',
          borderRadius: '5px',
          marginBottom: '40px',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 0 }}>
          <svg
            width="384"
            height="217"
            viewBox="0 0 384 217"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="384" height="217" rx="5" fill="#2F3A53" />
            <rect opacity="0.4" width="384" height="217" rx="5" fill="#4D30C5" />
            <rect
              x="384"
              y="217"
              width="384"
              height="217"
              rx="5"
              transform="rotate(180 384 217)"
              fill="url(#paint0_linear_1_2829)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_2829"
                x1="714.24"
                y1="434"
                x2="580.923"
                y2="423.109"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#364595" />
                <stop offset="1" stop-color="#364595" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
        <Stack sx={{ position: 'relative', zIndex: 1 }}>
          <Typography variant="subtitle1" color="text.primary">
            PROMO EVENT
          </Typography>

          <Typography variant="button1" color="text.primary" mt="12px">
            The event runs from{' '}
            <span style={{ color: theme.palette.info.main }}>May 16, 2024, to May 31, 2024.</span>{' '}
            Event Points will be credited within{' '}
            <span style={{ color: theme.palette.info.main }}>24 hours of payment.</span> (Pacific
            Standard Time based)
          </Typography>

          <Divider sx={{ marginY: '12px' }} />

          <Typography
            color="text.primary"
            sx={{ fontSize: '12px', lineHeight: '14px', opacity: 0.5 }}
          >
            This promotional event may be concluded early without prior notice, depending on budget
            depletion.
          </Typography>

          <Typography
            color="text.primary"
            sx={{ fontSize: '12px', lineHeight: '14px', opacity: 0.5 }}
            mt="10px"
          >
            If you do not receive them within this timeframe, please contact{' '}
            <Link
              href="mailto:support@gameround.co"
              target="_blank"
              rel="noopener noreferrer"
              color="text.primary"
            >
              support@gameround.co
            </Link>
            .
          </Typography>
        </Stack>
      </Box> */}
      <Box mt="40px">
        <AgreeTermText onChange={(value) => setToggleAgree(value)} />
      </Box>
      <Button
        variant="contained"
        disabled={!toggleAgree || !selectedProduct}
        sx={{ marginTop: '20px' }}
        fullWidth
        onClick={() => {
          showDialog({
            width: '500px',
            backgroundColor: theme.palette.base.white,
            body: (
              <PaymentWrapper
                options={{
                  amount: selectedProduct?.cost || 0,
                  currency: 'USD',
                  priceId: selectedProduct?.price_id || '',
                  productId: selectedProduct?.product_id || '',
                }}
                onComplete={onComplete}
              />
            ),
            onClose: initializeStates,
          });
        }}
      >
        Payment
      </Button>
    </Box>
  );
}
