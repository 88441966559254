import { GIconButton } from '@ground/ui';
import { Box, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dispatch } from 'store';
import { useLog } from 'utils/userLog';

interface MobileGnbProps {
  title?: string;
  backButton?: boolean;
}

const MobileGnb = ({ title, backButton = true }: MobileGnbProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { gxcTag } = useLog();

  const handleClickBack = () => {
    // 페이지 이탈 시 로그아웃
    dispatch.login.logout();
    navigate('/');
    gxcTag('click', { area: 'header', ui: 'back' });
  };

  return (
    <Box
      component="header"
      sx={{
        position: 'sticky',
        background: (theme) => theme.palette.gray[100],
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: 60,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {backButton && (
          <GIconButton
            iconName="arrow_left"
            iconSize={24}
            disableHoverEffect
            onClick={handleClickBack}
            ButtonProps={{ sx: { left: '14px', position: 'absolute' } }}
          />
        )}
        <Typography color="text.primary" variant="h5" noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

interface SignLayoutProps extends MobileGnbProps {
  isMobile?: boolean;
}

export default function SignLayout({
  isMobile,
  children,
  ...mobileGnbProps
}: PropsWithChildren<SignLayoutProps>) {
  return (
    <>
      {isMobile && <MobileGnb {...mobileGnbProps} />}
      <Stack
        sx={{
          alignItems: 'center',
          ...(isMobile
            ? { padding: '20px' }
            : {
                backgroundColor: 'gray.90',
                borderRadius: '5px',
                padding: '40px 28px',
                width: '420px',
              }),
        }}
      >
        {children}
      </Stack>
    </>
  );
}
