import { Box, Stack, styled, Typography } from '@mui/material';

import { useGetDetailData } from 'views/detail/DetailProvider';

const Delimiter = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.gray[30],
}));

export default function DetailGenre() {
  const { data } = useGetDetailData();

  return (
    <Box mt="12px" minHeight="38px">
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        columnGap="8px"
        rowGap="4px"
        divider={<Delimiter />}
      >
        {data?.genre_lists &&
          data.genre_lists?.map((genre, index) => (
            <Typography key={index} variant="body3" color="text.secondary">
              {genre}
            </Typography>
          ))}
      </Stack>
    </Box>
  );
}
