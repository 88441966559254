import { numberWithCommas } from '@ground/tools';
import { LoadingWrapper } from '@ground/ui';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { useGetPointHistoryAPI } from 'api/gstore/gstoreAPI';
import GPointDataTable, { CellData, HeaderData } from './GPointDataTable';

const headers: HeaderData[] = [
  { id: 'activity', label: 'Activity' },
  { id: 'detail', label: 'Detail info' },
  { id: 'transaction', label: 'Transactions (G.P)' },
  { id: 'balance', label: 'Balance (G.P)' },
  { id: 'date', label: 'Date / Time' },
];

export default function GPointTableContainer() {
  const { data: pointHistoryData, isLoading, setSize } = useGetPointHistoryAPI();
  const [ref, inView] = useInView({ threshold: 0.1 });
  const isNotDataLast =
    pointHistoryData?.length &&
    pointHistoryData[pointHistoryData.length - 1]?.next_page?.has_next_page;

  useEffect(() => {
    if (inView) {
      setSize((size: number) => size + 1);
    }
  }, [inView, setSize]);

  const cellData: CellData[][] = Array.isArray(pointHistoryData)
    ? pointHistoryData
        .map(
          (responseData) =>
            responseData?.data?.map((gpointData) => [
              {
                id: 'activity',
                content: (
                  <Typography variant="body2" color="text.secondary">
                    {gpointData.activity}
                  </Typography>
                ),
              },
              {
                id: 'detail',
                content:
                  gpointData.detail.category === 'PURCHASED_POINT' ? (
                    gpointData?.activity.includes('Cancel') ? (
                      <Typography variant="body2" color="text.secondary">
                        Purchase G.Points
                        <br />
                        {gpointData.trx_id}
                      </Typography>
                    ) : (
                      <Typography
                        component={Link}
                        variant="body2"
                        color="text.secondary"
                        to={`/profile/point/${gpointData.trx_id}`}
                      >
                        Purchase G.Points
                        <br />
                        {gpointData.trx_id}
                      </Typography>
                    )
                  ) : gpointData.detail.category === 'ORDER' ? (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {gpointData.detail.title}
                      </Typography>
                      {gpointData.detail.ref_id && (
                        <Typography
                          component={Link}
                          variant="body2"
                          color="text.secondary"
                          to={`/profile/order/${
                            // digital goods는 경로를 다르게 처리. 추후 v2 api 바뀌면 하나의 url로 사용할 예정
                            gpointData.detail.order_type === 'DIGITAL_GOODS' ? 'digital/' : ''
                          }${gpointData.detail.ref_id}`}
                        >
                          {gpointData.detail.ref_id}
                        </Typography>
                      )}
                    </>
                  ) : gpointData.detail.category === 'GAME' ? (
                    <Typography
                      component={Link}
                      variant="body2"
                      color="text.secondary"
                      to={`/detail/${gpointData.detail.ref_id}`}
                    >
                      {gpointData.detail.title}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      {gpointData.detail.title}
                    </Typography>
                  ),
              },
              {
                id: 'transaction',
                content: (
                  <Typography variant="body2" color="text.secondary">
                    {gpointData.transactions_gp > 0 ? '+ ' : '- '}
                    {numberWithCommas(Math.abs(gpointData.transactions_gp))}
                  </Typography>
                ),
              },
              {
                id: 'balance',
                content: (
                  <Typography variant="body2" color="text.secondary">
                    {numberWithCommas(gpointData.balance)}
                  </Typography>
                ),
              },
              {
                id: 'date',
                content: (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      {format(new Date(gpointData.created_at), 'MMM d, yyyy')}
                    </Typography>
                    <Typography variant="small2" color="text.secondary2">
                      {format(new Date(gpointData.created_at), 'h:mm aa')}(PT)
                    </Typography>
                  </>
                ),
              },
            ])
        )
        .flat()
    : [];

  return (
    <LoadingWrapper isLoading={isLoading} size={64}>
      <GPointDataTable
        headers={headers}
        cellData={cellData}
        observedRef={isNotDataLast ? ref : undefined}
      />
    </LoadingWrapper>
  );
}
