import { GSwitch } from '@ground/ui';
import { Paper, Typography } from '@mui/material';
import { useState } from 'react';

import useFetch from 'api/useFetch';
import eventTracker from 'utils/eventTracker';

interface Props {
  subscribeEmail: boolean;
}

export default function SubscribeEmail({ subscribeEmail }: Props) {
  const [checked, setChecked] = useState(subscribeEmail);
  const { isLoading, fetch } = useFetch('users/subscribe/update', { method: 'PUT' }, true);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;

    setChecked(event.target.checked);
    eventTracker('profile_profile_subscribe_email', {
      event_label: event.target.checked.toString(),
    });

    const response = await fetch({ subscribe: event.target.checked });
    if (response.status !== 200 || response.data.result_code !== 'OK') {
      setChecked(!event.target.checked);
    }
  };

  return (
    <div>
      <Typography variant="h5" marginBottom="20px">
        Subscribe to Marketing Email
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '14px',
          padding: '40px',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Subscribe to our newsletter to receive G.Round news, giveaway information, events, and new
          game updates in your inbox.
        </Typography>
        <GSwitch checked={checked} onChange={handleChange} />
      </Paper>
      <Typography variant="button2" color="text.secondary2">
        *If you decide to unsubscribe from our marketing newsletter, G.Round account-related
        notifications such as email verification, password reset, and store orders notifications
        will still be sent.
      </Typography>
    </div>
  );
}
