// import { lazy } from 'react';
import { styled } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

// sync import
import ScrollToTop from 'views/ScrollToTop';
import About from 'views/about/About';
import AboutDeveloper from 'views/about/AboutDeveloper';
import Blog from 'views/blog/Blog';
import Clips from 'views/clips/Clips';
import Detail from 'views/detail/Detail';
import GStore from 'views/gstore/GStore';
import Home from 'views/home/Home';
import NotFound from 'views/main/NotFound';
import Profile from 'views/profile/Profile';
import ClientApply from 'views/recruitment/ClientApply';
import ConfirmResetPassword from 'views/sign/ConfirmResetPassword';
import RequestResetPassword from 'views/sign/RequestResetPassword';
import Login from 'views/sign/signin/Login';
import Signup from 'views/sign/signup/Signup';
import SupporterApply from 'views/supporters-recruitment/SupporterApply';
import Testing from 'views/testing/Testing';
import Withdrawal from 'views/withdrawal/Withdrawal';
import AuthRoute from './AuthRoute';
import NoAuthRoute from './NoAuthRoute';

// async import
// const Home = lazy(() => import('views/home/Home'));
// const Testing = lazy(() => import('views/testing/Testing'));
// const Clips = lazy(() => import('views/clips/Clips'));
// const About = lazy(() => import('views/about/About'));
// const AboutDeveloper = lazy(() => import('views/about/AboutDeveloper'));
// const Login = lazy(() => import('views/sign/Login'));
// const Signup = lazy(() => import('views/sign/Signup'));
// const Profile = lazy(() => import('views/profile/Profile'));
// const Detail = lazy(() => import('views/detail/Detail'));
// const GStore = lazy(() => import('views/gstore/GStore'));
// const Announcement = lazy(() => import('views/announcement/Announcement'));

// const RequestResetPassword = lazy(() => import('views/sign/RequestResetPassword'));
// const ConfirmResetPassword = lazy(() => import('views/sign/ConfirmResetPassword'));

const MainRoot = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default function Main() {
  return (
    <MainRoot>
      <Routes>
        <Route index element={<ScrollToTop />} />
        <Route path="/:menu/*" element={<ScrollToTop />} />
      </Routes>
      <Routes>
        <Route
          path="/login/*"
          element={
            <NoAuthRoute>
              <Login />
            </NoAuthRoute>
          }
        />
        <Route path="/signup/*" element={<Signup />} />
        <Route
          path="/request-reset-password"
          element={
            <NoAuthRoute>
              <RequestResetPassword />
            </NoAuthRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <NoAuthRoute>
              <ConfirmResetPassword />
            </NoAuthRoute>
          }
        />
        <Route
          path="/profile/*"
          element={
            <AuthRoute>
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/recruitment/*"
          element={
            <AuthRoute>
              <ClientApply />
            </AuthRoute>
          }
        />
        <Route
          path="/supporter-recruitment/*"
          element={
            <AuthRoute>
              <SupporterApply />
            </AuthRoute>
          }
        />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/detail/*" element={<Detail />} />
        <Route path="/testing" element={<Testing />} />
        <Route path="/store/*" element={<GStore />} />
        {/* redirect to store */}
        <Route path="/gpointstore/*" element={<GStore />} />
        <Route path="/clips/*" element={<Clips />} />
        <Route path="/about" element={<About />} />
        <Route path="/devabout" element={<AboutDeveloper />} />
        <Route path="/blog/*" element={<Blog />} />
        <Route path="/announcement/*" element={<Blog />} />
        <Route path="/" element={<Home />} />
        <Route path="/index.html" element={<Home />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </MainRoot>
  );
}
