import { Box, Typography } from '@mui/material';

import { LinkButton } from 'elements';
import { groundDiscordChannel } from 'utils/common';
import { useLog } from 'utils/userLog';
import mobileLogo from 'images/banner/discord/discord_mobile_logo.png';
import mobileLogo2x from 'images/banner/discord/discord_mobile_logo@2x.png';
import mobileLogo3x from 'images/banner/discord/discord_mobile_logo@3x.png';
import mobileCharacter from 'images/banner/discord/discord_mobile_character.svg';

import smallBg from 'images/banner/discord/discord_small_bg.png';
import smallBg2x from 'images/banner/discord/discord_small_bg@2x.png';
import smallBg3x from 'images/banner/discord/discord_small_bg@3x.png';

import mediumBg from 'images/banner/discord/discord_medium_bg.png';
import mediumBg2x from 'images/banner/discord/discord_medium_bg@2x.png';
import mediumBg3x from 'images/banner/discord/discord_medium_bg@3x.png';

interface Props {
  size: 'mobile' | 'small' | 'medium';
}

interface DiscoreBannerProps {
  handleGxcTag: () => void;
}

const DiscordBannerMobile = ({ handleGxcTag }: DiscoreBannerProps) => (
  <Box
    sx={{
      padding: '24px',
      width: '303px',
      backgroundImage: `url(${mobileCharacter})`,
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px',
      border: (theme) => `1px solid ${theme.palette.opacity.white[10]}`,
    }}
  >
    <img
      src={mobileLogo}
      srcSet={`${mobileLogo}, ${mobileLogo2x} 2x, ${mobileLogo3x} 3x`}
      alt="discord logo"
    />
    <Typography variant="body3" color="text.primary" mt="12px">
      Join Our Gaming Community
    </Typography>
    <Typography variant="small2" color="text.secondary" mt="8px">
      Join the G.Round Discord now
      <br />
      to learn more about our games!
    </Typography>
    <LinkButton
      size="small"
      sx={{ mt: '16px' }}
      to={groundDiscordChannel}
      variant="ghost"
      onClick={handleGxcTag}
    >
      Go To Discord Server
    </LinkButton>
  </Box>
);

const DiscordBannerSmall = ({ handleGxcTag }: DiscoreBannerProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '24px',
      width: '420px',
      backgroundImage: `image-set(url(${smallBg}) 1x, url(${smallBg2x}) 2x, url(${smallBg3x}) 3x)`,
    }}
  >
    <Box sx={{ width: '212px' }}>
      <Typography variant="body3" color="text.primary">
        Join Our Gaming Community
      </Typography>
      <Typography variant="small2" color="text.secondary" mt="8px">
        Join the G.Round Discord now to learn more about our games!
      </Typography>
      <LinkButton
        size="small"
        sx={{ mt: '16px' }}
        to={groundDiscordChannel}
        variant="ghost"
        onClick={handleGxcTag}
      >
        Go To Discord Server
      </LinkButton>
    </Box>
  </Box>
);

const DiscordBannerMedium = ({ handleGxcTag }: DiscoreBannerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: '60px',
        width: '1080px',
        height: '120px',
        backgroundImage: `image-set(url(${mediumBg}) 1x, url(${mediumBg2x}) 2x, url(${mediumBg3x}) 3x)`,
      }}
    >
      <Box sx={{ ml: '240px' }}>
        <Typography variant="subtitle1" color="text.primary">
          Join Our Gaming Community
        </Typography>
        <Typography variant="body2" color="text.secondary" mt="8px">
          Join the G.Round Discord now to learn more
          <br />
          about our games!
        </Typography>
      </Box>
      <LinkButton size="medium" to={groundDiscordChannel} variant="ghost" onClick={handleGxcTag}>
        Go To Discord Server
      </LinkButton>
    </Box>
  );
};

export default function DiscordBanner({ size }: Props) {
  const { gxcTag } = useLog();

  const handleGxcTag = () => {
    gxcTag('click', { ui: 'discord' });
  };

  return size === 'mobile' ? (
    <DiscordBannerMobile handleGxcTag={handleGxcTag} />
  ) : size === 'small' ? (
    <DiscordBannerSmall handleGxcTag={handleGxcTag} />
  ) : size === 'medium' ? (
    <DiscordBannerMedium handleGxcTag={handleGxcTag} />
  ) : null;
}
