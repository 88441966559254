import { createRef, useMemo, useState } from 'react';
import { Box, OutlinedInput } from '@mui/material';
import deepEqual from 'deep-equal';

export interface VerificationInputProps {
  /** error 상태 표시 */
  error?: boolean;
  /** 입력칸 간의 거리 (px) */
  gap?: number;
  /** 입력 가능한 글자의 길이 */
  length?: number;
  /** 값이 변경될 때마다 호출되는 이벤트 함수 */
  onChangeValues?: (value: string[]) => void;
  /** 값이 모두 입력됐을 때 호출되는 이벤트 함수 */
  onComplete?: (value: string[]) => void;
}

export default function VerificationInput({
  error,
  gap = 8,
  length = 6,
  onChangeValues,
  onComplete,
}: VerificationInputProps) {
  const [values, setValues] = useState(Array.from({ length }, () => ''));

  // 입력창의 ref를 갖고 있는 list
  const inputList = useMemo(
    () => Array.from({ length }, () => createRef<HTMLInputElement>()),
    [length]
  );

  // 이전 입력창의 ref
  const getPrev = (index: number) => inputList[index - 1];
  // 다음 입력창의 ref
  const getNext = (index: number) => inputList[index + 1];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    // 숫자가 아닌 입력은 제거
    const inputValues = event.target.value.replace(/[^\d]/gi, '');

    // 필터링하고 남은 입력된 글자가 없거나 이전 값과 같은 경우
    if (inputValues === '' || values[index] === inputValues) {
      requestAnimationFrame(() => {
        event.target.select();
      });
      return;
    }

    // 기존 values에 입력한 문자를 넣어서 values를 업데이트 해준다.
    const copyValues = [...values];
    inputValues.split('').forEach((v, i) => (copyValues[index + i] = v));
    const updateValues = copyValues.slice(0, length);
    setValues(updateValues);

    // 값이 변한 경우 이벤트 함수 호출
    if (!deepEqual(values, updateValues)) {
      onChangeValues?.(updateValues);
      // 모든 칸을 채우면서 마지막 숫자 입력 시 완료 함수 호출
      if (index === length - 1 && updateValues.every((value) => value.length === 1)) {
        onComplete?.(updateValues);
      }
    }

    // 입력 후 다음 칸으로 이동
    requestAnimationFrame(() => {
      const next = getNext(index);
      if (next?.current) {
        next.current.focus();
        next.current.select();
      }
    });
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    event.target.select();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const prev = getPrev(index);
    const next = getNext(index);

    switch (event.key) {
      case 'Backspace':
      case 'Delete':
        event.preventDefault();

        const newValues = [...values];
        newValues[index] = '';
        setValues(newValues);

        // 값이 변한 경우 이벤트 함수 호출
        if (!deepEqual(values, newValues)) {
          onChangeValues?.(newValues);
        }

        requestAnimationFrame(() => {
          if (prev?.current) {
            prev.current.focus();
            prev.current.select();
          }
        });
        break;

      case 'ArrowLeft':
        requestAnimationFrame(() => {
          if (prev?.current) {
            prev.current.focus();
            prev.current.select();
          }
        });
        break;

      case 'ArrowRight':
        requestAnimationFrame(() => {
          if (next?.current) {
            next.current.select();
            next.current.focus();
          }
        });
        break;

      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        break;

      default:
        // input과 같은 값을 입력한 경우 포커스만 이동
        if (event.key === values[index]) {
          requestAnimationFrame(() => {
            if (next?.current) {
              next.current.select();
              next.current.focus();
            }
          });
        }
        break;
    }
  };

  return (
    <Box sx={{ columnGap: `${gap}px`, display: 'flex' }}>
      {inputList.map((ref, index) => (
        <OutlinedInput
          autoComplete="one-time-code"
          autoFocus={index === 0}
          error={error}
          key={index}
          inputProps={{ type: 'tel' }}
          inputRef={ref}
          onChange={(event) => handleChange(event, index)}
          onFocus={handleFocus}
          onKeyDown={(event) => handleKeyDown(event, index)}
          size="small"
          sx={{
            '& .MuiOutlinedInput-input': {
              padding: '12px 8px',
              textAlign: 'center',
            },
            fontSize: '18px',
            padding: 0,
            width: 38,
            height: 48,
          }}
          value={values[index]}
        />
      ))}
    </Box>
  );
}
