import { useFetch } from 'api';
import useSWRFetch from 'api/useSWRFetch';
import { SuppoterQuestionData } from './useSuppoterTypes';

// 서포터즈 신청 폼 정보(질문)
export function useSupporterApplyFormAPI() {
  const url = 'privatetest/supporter/register/question';

  return useSWRFetch<{ data: SuppoterQuestionData[] }>({ key: url, useToken: true });
}

// 서포터즈 신청 api
export function useSupporterRegisterAPI() {
  return useFetch(
    'privatetest/supporter/register',
    {
      method: 'POST',
    },
    true
  );
}

// 디스코드 id 체크 - 임시보류
export function useDiscordIdCheckAPI(discordId: string | null) {
  const url = `discord/user?discordId=${discordId}`;

  return useSWRFetch<{ data: any }>({ key: discordId ? url : null, useToken: true });
}

// 서포터즈 신청 확인 api
export function useSupporterApplyConfirmAPI(key?: string) {
  return useSWRFetch<{ fullname: string; ndacompleted_at: string }>({
    key:
      key !== 'null'
        ? `privatetest/supporter/contract/confirm?userId=${key}`
        : 'privatetest/supporter/contract/confirm',
    useToken: true,
  });
}
