import { List, ListSubheader, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useBeforeunload } from 'react-beforeunload';

import { useSurveyData } from './SurveyProvider';
import SurveyListItem from './SurveyListItem';

export default function SurveyList() {
  const { data, answerList } = useSurveyData();

  // Find unfinished survey
  const firstUnfinishedSurvey = data?.survey_question_lists?.find((questionList) =>
    // 서베이 하나의 파트에 속해있는 질문들 중에 답변이 하나라도 완성되지 않았다면 완성되지 않은 것으로 간주한다.
    questionList.survey_questions?.some(
      (question) =>
        // 답변 리스트가 없거나 해당 질문의 답변이 없으면 return true
        !(
          answerList &&
          Object.entries(answerList).find(
            // 답변 리스트에서 현재 질문에 해당되는 답변이 있는지 찾는다.
            ([questionId, answerValue]) => Number(questionId) === question.id && answerValue
          )
        )
    )
  );

  useBeforeunload((event) => {
    event.preventDefault();
  });

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          component="div"
          disableGutters
          disableSticky
          id="survey-subheader"
          sx={{ display: 'flex', paddingY: '20px', justifyContent: 'space-between' }}
        >
          <Typography variant="body2">
            Questions from the developer. Active participation helps in launching the game to fit
            your country.
          </Typography>
          <Typography variant="body2" textAlign="right">
            {data &&
              `Survey Period : ${format(new Date(data.started_at), 'MMM dd, yyyy')} - ${format(
                new Date(data.ended_at),
                'MMM dd, yyyy'
              )}`}
          </Typography>
        </ListSubheader>
      }
    >
      {data?.survey_question_lists.map((questionList, index) => (
        <SurveyListItem
          key={questionList.id}
          defaultOpen={questionList === firstUnfinishedSurvey}
          index={index}
          surveyId={data.id}
          surveyPartId={questionList.id}
          {...questionList}
        />
      ))}
    </List>
  );
}
