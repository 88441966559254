import useSWRFetch from 'api/useSWRFetch';
import { storeQueries } from './storeQueries';
import { IStoreDigitalGame, IStoreMerchandise } from './storeTypes';

/** Merchandise */
// 판매 상품 배송 국가 목록 조회
export function useGetShippingCountryList(id?: string) {
  return useSWRFetch<IStoreMerchandise.GetShippingCountryList.Response>({
    key: id ? storeQueries.fetchShippingCountryList(id) : null,
  });
}

export function useGetMerchandiseDetail(id?: string) {
  return useSWRFetch<IStoreMerchandise.GetMerchandiseDetail.Response>({
    key: id ? storeQueries.fetchMerchandiseSaleProduct(id) : null,
  });
}

/** Digital Game */
// 디지털 게임 상품 목록 조회
export function useGetDigitalGameProductList() {
  return useSWRFetch<IStoreDigitalGame.GetProductList.Response>({
    key: storeQueries.fetchDigitalGameProductList(),
  });
}

// 디지털 게임 상품 단건 조회
export function useGetDigitalGameProduct(id: number) {
  return useSWRFetch<IStoreDigitalGame.GetProduct.Response>({
    key: id ? storeQueries.fetchDigitalGameProduct(id) : null,
  });
}
