import type { FormAutocompleteOption } from '@ground/ui';
import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Paper, Typography } from '@mui/material';
import { differenceInYears } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useSaveProfileAPI, type ProfileData, type ProfileFormData } from 'api/profile/profileAPI';
import { CountryData, useDialog, useError } from 'hooks';
import { Dispatch } from 'store';
import eventTracker from 'utils/eventTracker';
import {
  PersonalDetailsBirthday,
  PersonalDetailsCountry,
  PersonalDetailsGender,
  PersonalDetailsName,
} from '.';

interface Props {
  profileData: ProfileData;
  reload: () => void;
  countries: CountryData[];
}

export default function PersonalDetails({ profileData, reload, countries }: Props) {
  const { isLoading: sumbmitLoading, fetch: submit } = useSaveProfileAPI();
  const dispatch = useDispatch<Dispatch>();

  const countryList: FormAutocompleteOption[] = countries.map((country) => ({
    name: country.name,
    value: country.code,
  }));

  const filled = !!(
    profileData.name &&
    profileData.country_code &&
    profileData.birth_month &&
    profileData.birth_day &&
    profileData.birth_year &&
    profileData.sex
  );

  const methods = useForm<Omit<ProfileFormData, 'subscribe_email'>>({
    mode: 'onChange',
    defaultValues: {
      name: profileData.name,
      birthday:
        profileData.birth_year && profileData.birth_month && profileData.birth_day
          ? new Date(
              `${profileData.birth_year}-${profileData.birth_month}-${profileData.birth_day}`
            )
          : null,
      country: countryList.find((country) => country.value === profileData.country_code),
      sex: profileData.sex ?? undefined,
    },
  });
  const { showDialog } = useDialog();
  const { showError } = useError();

  const [usedTrigger, setUsedTrigger] = useState(false);

  // 초기 값 에러 표시를 위해 mount 시 강제로 validation trigger 발동 시킴
  useEffect(() => {
    if (!usedTrigger) {
      setUsedTrigger(true);
      methods.trigger();
    }
  }, [methods, usedTrigger]);

  const onSubmit = async (formData: Omit<ProfileFormData, 'subscribe_email'>) => {
    if (!formData.birthday) return;

    //나이 13년 차이 체크
    const resultYears = differenceInYears(new Date(), formData.birthday);

    if (resultYears < 13) {
      showDialog({
        title: 'Warning',
        body: 'To ensure your safety, we restricted access to G.Round for younger audiences. We apologize for the inconvenience.',
        primaryButton: { text: 'Ok' },
      });
      return;
    }

    const response = await submit({
      name: formData.name.trim(),
      birth_month: formData.birthday.getMonth() + 1,
      birth_day: formData.birthday.getDate(),
      birth_year: formData.birthday.getFullYear(),
      country_code: formData.country?.value
        ? formData.country.value.toString()
        : profileData.country_code ?? '',
      sex: formData.sex,
    });
    if (response.status === 200 && response.data.result_code === 'OK') {
      // 이름이 중복되는 경우 새 이름으로 바꿔줘야 함
      methods.setValue('name', response.data.data.name);
      reload();

      const profileData = response.data.data;
      // 프로필이 변경되었을 때 업데이트 되는 로그인 정보
      dispatch.login.updateLoginInfo({
        access_token: profileData.access_token,
        name: profileData.name,
        country_code: profileData?.country_code,
        filled: profileData?.filled,
      });
    } else {
      showError(response);
    }
  };

  const handleClickSubmit = () => {
    eventTracker('profile_profile_personaldetails_savechanges');
    twttr?.conversion.trackPid('o91dq', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/tv48CM6dsMQDEI29kb8C' });
  };

  return (
    <div>
      <Typography variant="h5" marginBottom="20px">
        Personal Details
      </Typography>
      {!filled && (
        <Alert severity="error" sx={{ marginBottom: '12px' }}>
          Your personal details have not been completed.
        </Alert>
      )}
      <Paper sx={{ padding: '40px' }}>
        <FormProvider {...methods}>
          <DevTool control={methods.control} placement="bottom-right" />
          <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            <Box display="flex" justifyContent="space-between">
              <Box width={530}>
                <PersonalDetailsName />
                <Box height={32} />
                <PersonalDetailsCountry
                  countryList={countryList}
                  disabled={!!profileData.country_code}
                />
              </Box>
              <Box width={530}>
                <PersonalDetailsBirthday />
                <Box height={32} />
                <PersonalDetailsGender />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt="32px">
              <LoadingButton
                size="medium"
                color="primary"
                loading={sumbmitLoading}
                type="submit"
                variant="contained"
                onClick={handleClickSubmit}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </div>
  );
}
