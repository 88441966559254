import { FormInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAxios, useDialog, useError } from 'hooks';
import { emailPattern, passwordPattern } from 'utils/common';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

interface RequestFormData {
  email: string;
}

interface ConfirmFormData {
  password: string;
}

/** 기능이 2개면 2개로 나누자.. */
export default function ResetPassword() {
  const methods = useForm<RequestFormData | ConfirmFormData>({ mode: 'onChange' });
  const { showDialog, showSimpleDialog } = useDialog();
  const { showError } = useError();
  const navigate = useNavigate();
  const { gxcTag } = useLog();
  const [isReset, setIsReset] = useState(false);
  const { search } = useLocation();
  const { code } = queryString.parse(search || '', { parseNumbers: true });
  const { isLoading, refetch } = useAxios(`sessions/${code}`, { method: 'PATCH' }, true);
  const { isLoading: isRequestRefetch, refetch: requestRefetch } = useAxios(
    'mails/passwords',
    { method: 'POST' },
    true
  );

  const onRequestSubmit = async (formData: RequestFormData | ConfirmFormData) => {
    const response = await requestRefetch({ params: formData });
    if (response?.status === 200 && response?.data.result_code === 'OK') {
      //리셋 이미일 발송
      gxcTag('view', { area: 'send_pw_popup' });
      showDialog({
        title: 'Additional Steps Required',
        body: (
          <>
            We just sent you an email to reset your password! <br /> Please check your inbox or spam
            folder.
          </>
        ),
        primaryButton: {
          text: 'Back To Sign in',
          callback: () => {
            gxcTag('click', { area: 'send_pw_popup', ui: 'back_to_signin' });
            navigate('/login');
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'send_pw_popup', ui: 'close_x' });
        },
      });
    } else if (response.data?.result_code === '400') {
      gxcTag('view', { area: 'invalid_email_popup' });
      showDialog({
        title: 'Error',
        body: 'The address you provided is not associated with a G.Round account. Please try with a different email address.',
        primaryButton: {
          text: 'Close',
          callback: () => {
            gxcTag('click', { area: 'invalid_email_popup', ui: 'close' });
          },
        },
        secondaryButton: {
          text: 'Sign in',
          callback: () => {
            gxcTag('click', { area: 'invalid_email_popup', ui: 'signin' });
            navigate('/login');
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'invalid_email_popup', ui: 'close_x' });
        },
      });
    } else {
      showError(response, 'Reset password');
    }
    eventTracker('resetpassword_resetpassword');
  };

  const onResetSubmit = async (formData: RequestFormData | ConfirmFormData) => {
    const response = await refetch({ data: formData });
    if (response?.status === 200 && response?.data.result_code === 'OK') {
      gxcTag('view', { area: 'new_pw_success_popup' });
      // 리셋 성공
      showDialog({
        title: 'Reset password',
        body: 'Password reset complete. Try to login.',
        primaryButton: {
          callback: () => {
            gxcTag('click', { area: 'new_pw_success_popup', ui: 'ok' });
          },
        },
        onClose: () => navigate('/login'),
        onClickClose: () => {
          gxcTag('click', { area: 'new_pw_success_popup', ui: 'close_x' });
        },
      });
    } else if (response.data?.result_code === '400') {
      showSimpleDialog('Reset password', 'Not found user.');
    } else if (response.data?.result_code === '452') {
      showSimpleDialog('Reset password', 'Invalid code.');
    } else if (response.data?.result_code === '453') {
      showSimpleDialog('Reset password', 'Expired reset password url.');
    } else if (response.data?.result_code === '455') {
      showSimpleDialog('Reset password', 'Already used reset code.');
    } else {
      gxcTag('view', { area: 'new_pw_failed_popup' });
      showError(response, 'Reset password', {
        primaryButton: {
          callback: () => {
            gxcTag('click', { area: 'new_pw_failed_popup', ui: 'ok' });
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'new_pw_failed_popup', ui: 'close_x' });
        },
      });
    }
  };

  const handleClickLogin = () => {
    eventTracker('resetpassword_login');
    gxcTag('click', { area: 'signin', ui: 'signin' });
    navigate('/login');
  };

  const handleClickResetPassword = () => {
    gxcTag('click', { area: 'detail', ui: 'send' });
  };

  const handleClickSupport = () => {
    gxcTag('click', { area: 'detail', ui: 'support' });
  };

  useEffect(() => {
    if (search) {
      setIsReset(true);
    }
  }, [search]);

  return (
    <>
      <Box sx={{ padding: '40px 20px' }}>
        <div>
          <Typography variant="h4" mb="8px">
            Reset Password
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {isReset ? (
              'You are resetting the password'
            ) : (
              <>
                Enter your email, so we can send a link
                <br />
                to reset your password!
              </>
            )}
          </Typography>
        </div>
        {isReset ? (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onResetSubmit)} style={{ marginTop: '32px' }}>
              <FormInput
                label="Password"
                name="password"
                type="password"
                password
                placeholder="Your password"
                rules={{
                  required: 'Please enter a password.',
                  minLength: { value: 8, message: 'Invalid format' },
                  pattern: { value: passwordPattern, message: 'Invalid format' },
                }}
                fullWidth
                withBackground
                size="large"
              />
              <Typography variant="body3" mt="8px" color="text.secondary2">
                8-16 characters / Include at least one lowercase letter, one uppercase letter, and a
                number.
              </Typography>

              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                size="largest"
                sx={{ marginTop: '32px' }}
                type="submit"
                loading={isLoading}
                onClick={handleClickResetPassword}
              >
                Reset Password
              </LoadingButton>
            </form>
          </FormProvider>
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onRequestSubmit)} style={{ marginTop: '32px' }}>
              <FormInput
                label="Email"
                name="email"
                type="email"
                data-cy="login-email"
                placeholder="Your email"
                rules={{
                  required: 'Please enter a valid email.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email.',
                  },
                }}
                fullWidth
                withBackground
                size="large"
              />

              <LoadingButton
                loading={isRequestRefetch}
                fullWidth
                variant="contained"
                color="primary"
                size="largest"
                sx={{ marginTop: '32px' }}
                type="submit"
              >
                Reset Password
              </LoadingButton>
            </form>
          </FormProvider>
        )}

        {isReset ? (
          <Typography variant="body3" color="text.secondary2" mt="40px">
            If you are experiencing any issues, please contact us at{' '}
            <Link
              href="mailto:support@gameround.co"
              target="_blank"
              rel="noopener noreferrer"
              color="text.secondary"
              onClick={handleClickSupport}
            >
              support@gameround.co
            </Link>{' '}
            so we can assist you.
          </Typography>
        ) : (
          <Box mt="40px">
            <Divider sx={{ mb: '20px' }}>
              <Typography variant="small1">Remembered your password?</Typography>
            </Divider>
            <Button fullWidth variant="ghost" size="largest" onClick={handleClickLogin}>
              Sign in
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
