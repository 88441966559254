import { useParams, useSearchParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import ClientApplyCotents from './ClientApplyConfirmCotents';
import { useRecruitmentApplyConfirmAPI } from 'api/recruitment/useRecruitmentAPI';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'hooks';

// 프린트 display: none 처리
const RootBox = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  '@media print': {
    display: 'none',
  },
}));

export default function ClientApplyConfirmMain() {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const [ndaData, setNdaData] = useState({
    fullname: '',
    nda_agree_at: '',
    studio_name: '',
    title: '',
  });

  const [searchParams] = useSearchParams();
  const { id: key = '' } = useParams<{ id: string }>();
  const userId = searchParams?.get('key');
  const { fetch, data } = useRecruitmentApplyConfirmAPI(key);

  const getNdaData = useCallback(async () => {
    const formData = new FormData();
    formData.append('reqUserId', userId !== null ? userId : '');

    const response = await fetch(formData);

    if (response.status === 200 && response.data.result?.code === 0) {
      if (response.data.data === null) {
        showDialog({
          title: 'Alert!',
          body: 'Data not exist',
          primaryButton: {
            text: 'OK',
            callback: () => navigate('/'),
          },
          onClose: () => navigate('/'),
        });
      } else if (response.data.data.code === 451) {
        showDialog({
          title: 'Alert!',
          body: response.data.data.msg,
          primaryButton: {
            text: 'OK',
            callback: () => navigate('/'),
          },
          onClose: () => navigate('/'),
        });
      } else {
        setNdaData(response.data.data);
      }
    } else {
      showDialog({
        title: 'Alert!',
        body: 'Server Error Occured',
        primaryButton: {
          text: 'OK',
          callback: () => navigate('/'),
        },
        onClose: () => navigate('/'),
      });
    }
  }, [fetch, userId, navigate, showDialog]);

  useEffect(() => {
    getNdaData();
  }, [getNdaData]);

  return (
    <RootBox
      display="flex"
      width="1200px"
      mt="40px"
      alignItems="center"
      flexDirection="column"
      onContextMenu={(e) => {
        e.preventDefault();
        alert('Prevent right-click');
      }}
    >
      {data?.data ? <ClientApplyCotents ndaData={ndaData} /> : null}
    </RootBox>
  );
}
