import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

import { UserAvatar } from 'atoms';
import VideoClipMedia from './VideoClipMedia';
// import { GIcon } from '@ground/ui';
import circleIcon from 'images/icons/grey-circle.svg';

export interface ClipCardMediumProps {
  title?: string;
  userName?: string;
  country?: string;
  videoTitle?: string;
  srcUrl: string;
  streamerUrl?: string;
  onCardClickHandler: () => void;
  duration?: number;
  videoDescription?: string;
  type: 'all' | 'feature';
}

// todo
// (개선과제) 디자인은 매우 흡사하나 하나는 클릭시 모달팝업, 하나는 링크이동
// 두개로 분리할까 하나로 쓸까 다시 생각해봐야 할듯
const ClipCardMedium: React.FC<ClipCardMediumProps> = (props: ClipCardMediumProps) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const { videoTitle, srcUrl, onCardClickHandler, duration, videoDescription, type } = props;

  return (
    <Box borderRadius="5px" width="386px">
      <Box ref={hoverRef} onClick={onCardClickHandler} sx={{ cursor: 'pointer' }}>
        <VideoClipMedia
          hover={isHover}
          imageUrl={srcUrl || ''}
          playingTime={duration || 0}
          width={386}
          height={212}
          iconSize="SMALL"
        />
      </Box>

      <Box display="flex" alignItems="center" mt="20px" columnGap="8px">
        {type === 'all' ? <AllBottomArea {...props} /> : <FeatureBottomArea {...props} />}
      </Box>

      <Typography
        mt="8px"
        variant="body1"
        color="text.secondary"
        width="370px"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {videoTitle || videoDescription}
      </Typography>
    </Box>
  );
};

export default ClipCardMedium;

// all일때
function AllBottomArea({ userName, country, streamerUrl }: ClipCardMediumProps) {
  return (
    <>
      <UserAvatar width={28} src={streamerUrl ?? ''} alt={userName} />
      <Box display="flex" alignItems="center" columnGap="4px" overflow="hidden">
        <Typography variant="small1" noWrap>
          {userName}
        </Typography>
        {/* 토르여기 확인 */}
        <img src={circleIcon} alt="circle" />
        <Typography variant="small1" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
          {country}
        </Typography>
      </Box>
    </>
  );
}

// featured 일때
function FeatureBottomArea({ title }: ClipCardMediumProps) {
  return (
    <Box display="flex" alignItems="center" columnGap="4px" overflow="hidden">
      <Typography variant="subtitle1" noWrap>
        {title}
      </Typography>
    </Box>
  );
}
