import { Box, Button, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';

import { useGameDetailInfoAPI } from 'api/detail/detailAPI';
import client_apply_description_back1 from 'images/recruitment/client_apply_description_back1.png';
import client_apply_description_back2 from 'images/recruitment/client_apply_description_back2.png';
import client_apply_description_back3 from 'images/recruitment/client_apply_description_back3.png';
import { useClientApplyData } from '../ClientApplyProvider';
import ClientApply1Slide from './ClientApply1Slide';
import ClientApplyTabs from './ClientApplyTabs';

const descriptionInfoList = [
  {
    backImg: client_apply_description_back1,
    title: 'Step 1',
    description: 'Gameplay & Recording',
  },
  {
    backImg: client_apply_description_back2,
    title: 'Step 2',
    description: 'Writing a review',
  },
  {
    backImg: client_apply_description_back3,
    title: 'Step 3',
    description: 'Fill up survey',
  },
];

export default function Step1Contents() {
  const { setClientApplyStep, recruitmentData, gameId, selectLanguageCode } = useClientApplyData();

  const { data: info } = useGameDetailInfoAPI(gameId, selectLanguageCode);
  const description = info?.data[0]?.description;

  return (
    <Box>
      <Typography variant="h5" mb="12px">
        Test Info
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">
          Please provide feedback through review and survey to help developers. <br />
          All missions must be completed to complete the test of this game.
        </Typography>
        {/* 임시 3차떄 개발 */}
        {/* <Button variant="ghost" size="small">
          How to upload a video
        </Button> */}
      </Box>

      <Box display="flex" gap="15px" mt="40px">
        {descriptionInfoList.map((item) => {
          const { backImg, title, description } = item;

          return (
            <Box
              key={title}
              display="flex"
              gap="2px"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex={1}
              width="350px"
              height="120px"
              sx={{
                backgroundImage: `url(${backImg})`,
                backgroundSize: 'cover',
              }}
            >
              <Typography variant="button1">{title}</Typography>
              <Typography variant="h5">{description}</Typography>
            </Box>
          );
        })}
      </Box>

      <Divider sx={{ margin: '60px 0' }} />

      <Typography variant="h5" mb="20px">
        Game Info
      </Typography>

      <Box
        display="flex"
        mb="20px"
        p="20px 60px"
        sx={{
          background: (theme) => theme.palette.gray[100],
        }}
      >
        <Box display="flex">
          <Box p="10px 0" textAlign="center" width="240px">
            <Typography variant="button1" color="text.secondary2" mb="8px">
              Application Period
            </Typography>

            {recruitmentData?.recruit_period === 'y' && recruitmentData?.recruit_start ? (
              <Typography variant="subtitle1">{`${format(
                new Date(recruitmentData.recruit_start),
                'MMM d, yyyy'
              )}  - ${format(new Date(recruitmentData.recruit_end), 'MMM d, yyyy')}`}</Typography>
            ) : (
              '-'
            )}
          </Box>

          <Divider orientation="vertical" sx={{ margin: '0 60px' }} />

          <Box p="10px 0" textAlign="center" width="240px">
            <Typography variant="button1" color="text.secondary2" mb="8px">
              Test Period
            </Typography>
            {recruitmentData?.test_period === 'y' && recruitmentData?.started_at ? (
              <Typography variant="subtitle1">{`${format(
                new Date(recruitmentData.started_at),
                'MMM d, yyyy'
              )}  - ${format(new Date(recruitmentData.ended_at), 'MMM d, yyyy')}`}</Typography>
            ) : (
              '-'
            )}
          </Box>

          <Divider orientation="vertical" sx={{ margin: '0 60px' }} />

          <Box p="10px 0" textAlign="center" width="240px">
            <Typography variant="button1" color="text.secondary2" mb="8px">
              Applicants
            </Typography>
            {recruitmentData?.recruits === 'y' ? (
              <Typography variant="subtitle1">{recruitmentData?.private_test}</Typography>
            ) : (
              '-'
            )}
          </Box>
        </Box>
      </Box>

      <Box
        p="30px"
        sx={{
          background: (theme) => theme.palette.gray[100],
        }}
      >
        <Box>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
        </Box>

        <Box display="flex">
          <Box width="362px" mr="40px">
            <ClientApply1Slide />
          </Box>

          <Box width="100%">
            <Box display="flex" gap="36px">
              <Typography variant="subtitle1" color="text.secondary" width="100px">
                Game title
              </Typography>

              <Typography variant="subtitle1">
                {recruitmentData?.game_title === 'y'
                  ? recruitmentData?.title
                  : 'This is undisclosed information.'}
              </Typography>
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
            <Box display="flex" gap="36px">
              <Typography variant="subtitle1" color="text.secondary" width="100px">
                Studio name
              </Typography>
              <Typography variant="subtitle1">
                {recruitmentData?.studio_name_yn === 'y'
                  ? recruitmentData?.studio_name
                  : 'This is undisclosed information.'}
              </Typography>
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
            <Box display="flex" gap="36px">
              <Typography variant="subtitle1" color="text.secondary" width="100px">
                Genre
              </Typography>

              <Typography variant="subtitle1" width="450px">
                {recruitmentData?.genre === 'y'
                  ? recruitmentData?.genre_name
                  : 'This is undisclosed information.'}
              </Typography>
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
            <Box display="flex" gap="36px">
              <Typography variant="subtitle1" color="text.secondary" width="100px">
                Platform
              </Typography>
              <Typography variant="subtitle1">
                {recruitmentData?.platform !== 'X'
                  ? recruitmentData?.platform
                  : 'This is undisclosed information.'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {recruitmentData?.overview === 'n' &&
      recruitmentData?.screenshots === 'n' &&
      recruitmentData?.system_requirements === 'n' ? null : (
        <ClientApplyTabs />
      )}

      <Divider sx={{ margin: '60px 0 40px' }} />

      <Box display="flex" justifyContent="end">
        <Button onClick={() => setClientApplyStep(2)} variant="contained" size="small">
          Next
        </Button>
      </Box>
    </Box>
  );
}
