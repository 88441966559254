import { AutoResizeImage } from '@ground/tools';
import { SmallChip } from '@ground/ui';
import { Box, Skeleton, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { useBlogListAPI } from 'api/blog/blogAPI';
import { SectionContainer } from 'components';
import { useQueryState } from 'context';
import { Carousel } from 'elements';
import { AnnouncementListOptions } from 'types/AnnouncementTypes';
import { useLog } from 'utils/userLog';

const CarouselWrapper = styled(Carousel)(({ theme }) => ({
  borderRadius: 5,
  '.swiper-pagination': {
    paddingBottom: 28,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[80],
  width: 1200,
  display: 'flex',
  height: '440px',
}));

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'end',
  position: 'relative',
  background: `linear-gradient(360deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 97%)`,
  zIndex: 2,
  paddingBottom: 59,
});

const ImageWrapper = styled(AutoResizeImage)({
  position: 'absolute',
  cursor: 'pointer',
});

export default function BlogtHighlightBannerSlide() {
  const { gxcTag } = useLog();
  const { search_keyword, search_tag } = useQueryState<AnnouncementListOptions>();
  const { data, isLoading } = useBlogListAPI('b2c', undefined, undefined, true);
  const navigate = useNavigate();

  const blogListData = useMemo(
    () => (data?.data && Array.isArray(data?.data.list_page) ? data?.data.list_page : []),
    [data]
  );

  return (
    <>
      {!search_keyword && !search_tag && (
        <SectionContainer title="G.Round Blog" sx={{ mb: '80px' }}>
          {isLoading ? (
            <Skeleton width={1200} height={440} variant="rectangular" />
          ) : (
            <CarouselWrapper
              width={1200}
              height={440}
              navigationPositionTop={220}
              name="ground-blog-highlight"
              hasNavigation={blogListData && blogListData.length > 1}
              swiperOption={{ slidesPerView: 1 }}
              hasPagination
              bulletWidth={48}
              paginationPosition="INSIDE"
              onActiveIndexChange={() => {
                gxcTag('click', {
                  area: 'top_banners',
                  ui: 'pagination',
                });
              }}
              onClickLeftArrow={() => {
                gxcTag('click', {
                  area: 'top_banners',
                  ui: 'left_arrow',
                });
              }}
              onClickRightArrow={() => {
                gxcTag('click', {
                  area: 'top_banners',
                  ui: 'right_arrow',
                });
              }}
            >
              {blogListData?.map((blog) => (
                <SwiperSlide
                  key={blog.announce_id}
                  onClick={() =>
                    gxcTag('click', {
                      area: 'top_banners',
                      ui: 'banner',
                      parameters: { blog_id: blog.announce_id },
                    })
                  }
                >
                  <Link to={`/blog/${blog.announce_id}`}>
                    <Container>
                      <ImageWrapper
                        alt="image"
                        src={blog.announce_highlight_location}
                        resizeWidth={1200}
                        resizeHeight={440}
                      />

                      <TextContainer>
                        <Box width="700px" ml="60px">
                          <SmallChip
                            bgColor="white"
                            color="gray.100"
                            text={blog.announce_type_name}
                          />
                          <Typography
                            color="base.white"
                            variant="h3"
                            mt="12px"
                            onClick={() => navigate(`/blog/${blog.announce_id}`)}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {blog.announce_title}
                          </Typography>
                          <Typography
                            color="base.white"
                            variant="body1"
                            mt="12px"
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 3,
                            }}
                          >
                            {blog.announce_desc}
                          </Typography>
                          <Typography color="base.white" variant="body3" mt="20px">
                            {format(new Date(blog.rgst_at), 'MMM dd, yyyy')}
                          </Typography>
                        </Box>
                      </TextContainer>
                    </Container>
                  </Link>
                </SwiperSlide>
              ))}
            </CarouselWrapper>
          )}
        </SectionContainer>
      )}
    </>
  );
}
