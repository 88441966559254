import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import HomeLoginAfterBanner from './HomeLoginAfterBanner';
import HomeLoginBeforeBanner from './HomeLoginBeforeBanner';

export default function HomeBanner() {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  return isLogin ? <HomeLoginAfterBanner /> : <HomeLoginBeforeBanner />;
}
