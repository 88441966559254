import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';

import { useGStoreOrderDetailDigitalData } from './GStoreOrderDetailDigitalProvider';
import GStoreOrderDetailDigitalStepper from './GStoreOrderDetailDigitalStepper';

const PREFIX = 'GStoreOrderDetailDeliveryDigital';

const classes = {
  productInfoContainer: `${PREFIX}-productInfoContainer`,
  historyContainer: `${PREFIX}-historyContainer`,
  historyBox: `${PREFIX}-historyBox`,
  historyTimeBox: `${PREFIX}-historyTimeBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.productInfoContainer}`]: {
    display: 'flex',
    padding: 40,
    backgroundColor: theme.palette.gray[90],
    borderRadius: 5,
  },

  [`& .${classes.historyContainer}`]: {
    marginTop: 28,
  },
  [`& .${classes.historyBox}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.historyTimeBox}`]: {
    minWidth: 180,
    marginRight: 32,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.gray[20],
  marginRight: '8px',
}));

export default function GStoreOrderDetailDelivery() {
  const { data } = useGStoreOrderDetailDigitalData();

  const historyData = data?.data?.shopOrderHistoryVO?.data.filter(
    (history) => history.item === 'Order Status' && history.description !== 'Order Confirmed'
  );

  return (
    <Root>
      {Array.isArray(historyData) && (
        <Typography variant="h5" mb="20px">
          {historyData?.[0].description}
        </Typography>
      )}
      <Box className={classes.productInfoContainer}>
        <Stack sx={{ flex: 1 }}>
          <GStoreOrderDetailDigitalStepper />
          <Divider sx={{ marginTop: '40px' }} />
          {Array.isArray(historyData) && (
            <Box className={classes.historyContainer}>
              {historyData.map((history, index) => (
                <Box key={index} className={classes.historyBox}>
                  <Box className={classes.historyTimeBox}>
                    <Dot />
                    <Typography variant="body1" color="text.secondary">
                      {history?.created_at
                        ? `${format(new Date(history.created_at), 'MMM dd, yyyy hh:mm a')} (PT)`
                        : ''}
                    </Typography>
                  </Box>

                  <Typography
                    variant="subtitle1"
                    color={index === 0 ? 'text.primary' : 'text.secondary2'}
                  >
                    {history.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Stack>
      </Box>
      <Divider />
    </Root>
  );
}
