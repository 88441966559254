import { Components } from '@mui/material';

const InputBaseTheme: Components = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0,
        padding: '9px 15px',
        [`&.Mui-disabled`]: {
          color: 'unset',
          opacity: 0.4,
        },
      },
      input: {
        padding: 'unset',
        height: 'unset',
      },
      multiline: {
        alignItems: 'flex-start',
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
          padding: '11px 15px',
        },
      },
      {
        props: { size: 'large' },
        style: {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          padding: '11px 15px',
        },
      },
    ],
  },
};

export default InputBaseTheme;
