import { AutoResizeImage, AutoResizeImageProps } from '@ground/tools';
import { Box, styled } from '@mui/material';
import { useState } from 'react';

import groundDefaultImg from 'images/default_ground_bg.svg';

export interface ImageImageComponentProps extends AutoResizeImageProps {
  resize?: boolean;
  fadeIn?: boolean;
}

function ImageComponent({
  resize,
  resizeWidth,
  resizeHeight,
  quality,
  alt,
  format = 'webp',
  fadeIn = true,
  ...props
}: ImageImageComponentProps) {
  const [imgLoaded, setImgLoaded] = useState(false);

  const imageOnLoadHandler = () => {
    setImgLoaded(true);
  };

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.srcset = '';
    event.currentTarget.src = groundDefaultImg;
  };

  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        ...(fadeIn && {
          opacity: imgLoaded ? 1 : 0,
          transition: 'opacity 0.3s',
        }),
      }}
    >
      {resize ? (
        <AutoResizeImage
          alt={alt}
          resizeWidth={resizeWidth}
          resizeHeight={resizeHeight}
          format={format}
          quality={quality}
          onLoad={imageOnLoadHandler}
          onError={imageOnErrorHandler}
          {...props}
        />
      ) : (
        <img alt={alt} onLoad={imageOnLoadHandler} onError={imageOnErrorHandler} {...props} />
      )}
    </Box>
  );
}

export interface ImageBoxProps extends ImageImageComponentProps {
  overlay?: boolean;
  hoverEffect?: boolean;
}

/** G.Round web 전반적으로 사용하는 이미지 컴포넌트
 *
 * resize `true`이면 AutoResizeImage 적용(resizeWidth or resizeHeight 값 필수)
 *
 * resize `false`이면 <img> tag 적용
 *
 * overlay `true`이면 이미지 밝기를 0.8로 조절
 * (rgba(0, 0, 0, 0.2) overlay 영역 적용 대신 사용)
 *
 * fadeIn `true(기본값)` 이면 이미지가 로드 완료 (imgLoaded 값으로 판별) 후 서서히 나타난다.
 *
 * 컴포넌트를 styled로 감쌌기 때문에 sx 속성 사용가능
 *
 * 기능은 계속 추가될 수 있음
 *
 */
const ImageBox = styled(ImageComponent, {
  shouldForwardProp: (prop) => prop !== 'overlay' && prop !== 'hoverEffect' && prop !== 'sx',
})<ImageBoxProps>(({ overlay, hoverEffect, theme }) => ({
  display: 'block',
  background: theme.palette.gray[60],
  ...(overlay && {
    filter: 'brightness(0.8)',
    transition: 'filter .3s',
    ...(hoverEffect && {
      '&:hover': {
        filter: 'none',
      },
    }),
  }),
}));

export default ImageBox;
