import { useState } from 'react';
// import { GIcon } from '@ground/ui';
import { FormDropdown, FormInput } from '@ground/ui';
import { Box, Button, Divider, ListItemButton, ListItemText, Typography } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useSupporterApplyData } from '../SupporterApplyProvider';
// import { useDiscordIdCheckAPI } from 'api/supporter/useSupporterAPI';

import {
  SupporterApplySection1,
  SupporterApplySection2,
  SupporterApplySection3,
  SupporterApplySection4,
} from '../section';

const sectionTitle = [
  '1. Genres',
  '2. System and Gaming Information',
  '3. Experience in Gaming',
  '4. Etc.',
];

export default function SupporterApplyStep1Content() {
  const { userCountryCode, countryList } = useSupporterApplyData();

  // const [discordId, setDiscordId] = useState<string | null>(null);
  // const handdleCheckDiscordId = () => {
  //   console.log(discordId);
  // };

  return (
    <Box>
      <Box mb="40px">
        <Box display="flex" justifyContent="space-between" mb="40px">
          <Box width={510} display="flex" flexDirection="column" gap="8px">
            <LabelComponent label="Full Legal Name" required />
            <FormInput
              name="fullname"
              required
              rules={{ required: true }}
              placeholder="Enter Full Legal Name"
              fullWidth
              withBackground
            />
          </Box>

          <Box width={510} display="flex" flexDirection="column" gap="8px">
            <LabelComponent label="Discord Username" required />
            <Box display="flex" gap="15px">
              <FormInput
                name="discord_id"
                required
                rules={{ required: true }}
                // sx={{ width: '292px' }}
                placeholder="Discord Username"
                fullWidth
                withBackground
                // inputProps={{
                //   onChange: (e) => {
                //     setDiscordId(e.currentTarget.value);
                //   },
                // }}
              />
              {/* <LoadingButton
                size="large"
                variant="contained"
                color="primary"
                startIcon={<GIcon iconName="discord" iconSize={16} />}
                onClick={handdleCheckDiscordId}
                loading={isLoading}
                style={{ background: '#5B67E2' }}
                // disableRipple={connected}
              >
                <Typography variant="button2">Check Discord username</Typography>
              </LoadingButton> */}
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="8px" flexDirection="column">
            <LabelComponent label="Country" required />

            <FormDropdown
              defaultValue={String(userCountryCode)}
              name="country"
              placeholder="Select country"
              options={countryList}
              withBackground
              rules={{ required: true }}
            />
          </Box>

          <Typography color="text.secondary" variant="body2" mt="8px">
            {`*Please select your Country of Residence. (Changing the country will also change the information under My Page > Profile's 'Country' section.)`}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" flexDirection="column">
        {sectionTitle.map((item, index) => (
          <SectionList key={index} title={item} index={index} />
        ))}
      </Box>

      <Box display="flex" justifyContent="end" mt="40px">
        <Button color="private" variant="contained" size="large" type="submit">
          Next
        </Button>
      </Box>
    </Box>
  );
}

// todo 컴포넌트명 수정
function LabelComponent({ label, required }: { label: string; required?: boolean }) {
  return (
    <Box display="flex" gap="8px">
      <Typography color="text.primary" variant="body1">
        {label}
      </Typography>

      <Typography color="text.private" variant="body1" mt="3px">
        {required && ' *'}
      </Typography>
    </Box>
  );
}

function SectionList({ title, index }: { title: string; index: number }) {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen((value) => !value);
  };

  return (
    <>
      <ListItemButton
        disableGutters
        onClick={handleClick}
        sx={{ color: !open ? 'text.primary' : 'text.secondary2', padding: '7px 20px' }}
      >
        <ListItemText
          disableTypography
          primary={
            <Box display="flex" alignItems="center" gap="15px">
              <Typography variant="h6">{title}</Typography>
            </Box>
          }
          sx={{ marginY: '8px' }}
        />

        {!open ? (
          <ExpandLess sx={{ fontSize: '40px' }} />
        ) : (
          <ExpandMore sx={{ fontSize: '40px' }} />
        )}
      </ListItemButton>

      <Box>
        {!open && index === 0 ? <SupporterApplySection1 /> : null}
        {!open && index === 1 ? <SupporterApplySection2 /> : null}
        {!open && index === 2 ? <SupporterApplySection3 /> : null}
        {!open && index === 3 ? <SupporterApplySection4 /> : null}
      </Box>

      <Divider />
    </>
  );
}
