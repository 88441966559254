import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Typography, Button, Box, Stack } from '@mui/material';

import ArtDevTestimonialCard from './component/ArtDevTestimonialCard';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 1080,
  paddingTop: 100,
  paddingBottom: 100,
}));

const testimonialList = [
  {
    id: 1,
    title: 'BulletVille',
    link: 'https://gameround.co/detail/137',
    subTitle: 'NOWWA (Liechtenstein)',
    desc: '“G.Round was of great help to us. We chose G.Round initially just to make our life easier around the organization and marketing of our first crowdfunding campaign and to have less distraction from the actual game development. What turned out to be a really great tool was the G.Round launcher which worked very smoothly and the user data we received was very valuable, by the aggregated numbers as well as by detailed feedback by single players.”',
  },
  {
    id: 2,
    title: 'Stray Blade',
    link: 'https://gameround.co/detail/104',
    subTitle: 'Point Blank Games (Germany)',
    desc: '“The G.Round play-test provided invaluable insights to improve the game early in development and the results took us over the finish line to close the AAA publishing deal we just signed for Stray Blade. Looking forward to future test runs. The player base was very constructive, good climate, no trolls. Very good quality feedback. Overall very cool format, just really need 1000+ players to make good arguments from the data.”',
  },
  {
    id: 3,
    title: 'Cyberforge',
    link: 'https://gameround.co/detail/103',
    subTitle: 'Tater Games (Slovakia)',
    desc: '“Testing on G.Round was the best thing we could have done at this early stage of the project. It was admittedly much earlier than a lot of games that find their way here but the early state was welcomed and the feedback and data we received answered all of our questions. We cannot recommend G.Round enough. If you have questions for your players, and you absolutely should, this is a perfect place to ask them, no matter the state of your project.',
  },
  {
    id: 4,
    title: 'Misc. A Tiny Tale',
    link: 'https://gameround.co/detail/140',
    subTitle: 'Tinyware Games (Australia)',
    desc: '“G.Round helped our small indie studio get word of our game out there and capture a larger audience in a short amount of time. The support, collaborative ideas and connections made the whole experience smooth and enjoyable. From marketing to the friendly staff, they went above and beyond to support us little guys.”',
  },
  {
    id: 5,
    title: 'Second Hand: Frankie’s Revenge',
    link: 'https://gameround.co/detail/6',
    subTitle: 'Rikodu Games (Romania)',
    desc: '“G.Round allowed us to test our game with real players while getting ready for launch. In addition to focusing our QA efforts, we gained some good qualitative feedback and the G.Round team made the whole experience seamless. I’m looking forward to working with them for our next projects.”',
  },
  {
    id: 6,
    title: 'Jack Axe',
    link: 'https://gameround.co/detail/5',
    subTitle: 'Keybol Games (Philippines)',
    desc: '“G.Round is a great platform for developers and gamers. As a developer they were able to connect us with influencers and find our playerbase in a crowded PC market. As a gamer I discovered unreleased games and was able to experience them first on their platform. Installation is easy, I just downloaded the game using their app and I was able to try it within minutes!”',
  },
  {
    id: 7,
    title: 'Terracotta',
    link: 'https://gameround.co/detail/126',
    subTitle: 'Appnormals Team (Spain)',
    desc: '“G.Round has been an outstanding platform to get quality feedback from our game in development. Exceptional IT support, and a very active user community that will keep growing over time for sure. Definitely recommend it to any developer, whatever the stage of development is!”',
  },
  {
    id: 8,
    title: 'Tiny Little Bastards',
    link: 'https://gameround.co/detail/2',
    subTitle: 'Overlord Game Studio (Brazil)',
    desc: 'G.Round was, without any doubt, a game-changer for Tiny Little Bastards and for Overlord Game Studio. I would also like to mention the support team that was extremely helpful in every situation. I would definitely recommend G.Round as a public testing platform but also as a playtesting (bug finding and basic feedback) platform to any company in need of such services.',
  },
];

export default function ArtDevAboutTestimonials() {
  const [fold, setFold] = useState(true);

  const handleClick = () => {
    setFold && setFold(!fold);
  };

  return (
    <Root>
      <Typography variant="h4">Testimonials</Typography>
      <Stack gap="60px" direction="row" flexWrap="wrap" marginTop="60px">
        {(fold ? testimonialList.slice(0, 3) : testimonialList).map((item) => (
          <ArtDevTestimonialCard key={item.id} {...item} />
        ))}
      </Stack>
      <Box marginTop="48px" textAlign="center">
        <Button endIcon={fold ? <ExpandMore /> : <ExpandLess />} onClick={handleClick} size="large">
          {fold ? 'See More' : 'See Less'}
        </Button>
      </Box>
    </Root>
  );
}
