import { Box, Divider, styled, Typography, useTheme } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import { SettingItem } from '.';

const BackButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '70px',
});

interface Props {
  onSettingClose: () => void;
}

export function SettingItemList({ onSettingClose }: Props) {
  const theme = useTheme();

  return (
    <Box sx={{ paddingRight: '28px' }}>
      <BackButtonBox onClick={onSettingClose}>
        <ChevronLeftRoundedIcon style={{ color: theme.palette.gray[10] }} />
        <Typography variant="subtitle1" color="#8e8e8e">
          Back
        </Typography>
      </BackButtonBox>
      <Box mt="20px">
        <Typography variant="subtitle2">Notification Settings</Typography>
      </Box>
      <Divider sx={{ marginTop: '12px' }} />
      <SettingItem text="다른 유저가 나의 Referral code 입력 후 가입 시 알림 받기" />
      <SettingItem text="Follow Game 컨텐츠 업데이트 알림 받기 (Clips, News&Updates)" />
      <SettingItem text="G.Point Store 구매 기간 시작 시 알림 받기" />
      <SettingItem text="G.Round 공지/이벤트 소식 알림 받기" />
      <SettingItem text="나의 공개 Review 및 Comment에 리플이 달렸을 때 알림 받기" />
    </Box>
  );
}
