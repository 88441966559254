import { FormDropdown, FormInput, GIconButton } from '@ground/ui';
import { Box } from '@mui/material';

import { FormFieldListItemProps } from './RecommendGameAddWebsiteFieldList';

export default function RecommendGameWebsiteForm({
  name,
  index,
  remove,
  gameLinkTypeList,
}: FormFieldListItemProps) {
  const handleRemove = async () => {
    remove && remove();
  };

  return (
    <Box display="flex" width="100%">
      <Box display="flex" width="100%" gap="8px">
        <FormDropdown
          width="160px"
          name={`${name}.submit_game_link_type`}
          placeholder="Official site"
          options={gameLinkTypeList}
          withBackground
          rules={{ required: true }}
        />

        <FormInput
          name={`${name}.submit_game_link_url`}
          placeholder="Enter URL"
          required
          rules={{
            required: 'This is a required question',
            maxLength: { value: 1000, message: 'max 1,000 characters' },
          }}
          inputProps={{ maxLength: 1000 }}
          withBackground
          fullWidth
        />
      </Box>

      {index !== 0 && (
        <GIconButton
          iconName="close"
          iconSize={16}
          size={32}
          height={40}
          backgroundColor="gray.60"
          variant="rounded"
          onClick={handleRemove}
          ButtonProps={{ sx: { marginLeft: '4px' } }}
        />
      )}
    </Box>
  );
}
