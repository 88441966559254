import { Navigate, Route, Routes } from 'react-router-dom';

import ProfileOrderList from './ProfileOrderList';
import ProfileOrderStatus from './ProfileOrderStatus';
import ProfileOrderTab from './ProfileOrderTab';

export default function ProfileOrder() {
  return (
    <div>
      <ProfileOrderStatus />
      <Routes>
        <Route path=":type/*" element={<ProfileOrderTab />} />
        <Route path="*" element={<Navigate to="merchandise" replace />} />
      </Routes>
      <ProfileOrderList />
    </div>
  );
}
