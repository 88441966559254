import { AutoResizeImage } from '@ground/tools';
import { SmallChip } from '@ground/ui';
import { Box, Stack, Typography } from '@mui/material';
import format from 'date-fns/format';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { AnnouncementType } from 'types/AnnouncementTypes';

interface AnnouncementListItemProps {
  data: AnnouncementType;
}

export default function CommonListItem({ data }: AnnouncementListItemProps) {
  const { search } = useLocation();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Box
      ref={hoverRef}
      width="840px"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        py: '28px',
        '&:nth-child(1)': {
          paddingTop: '0',
        },
      })}
    >
      <Link to={`/blog/${data.announce_id}`} state={search}>
        <Box sx={{ columnGap: '28px', display: 'flex' }}>
          <Box width="282px" height="169px" overflow="hidden" borderRadius="5px">
            <AutoResizeImage
              style={{
                transition: 'filter .3s, all 0.2s ease-in ',
                transform: isHover ? 'scale(1.1)' : '',
              }}
              src={data.announce_thumb_location}
              alt="blog_image"
              resizeWidth={282}
              resizeHeight={169}
            />
          </Box>

          <Stack justifyContent="space-between" width="460px">
            <Box>
              <SmallChip bgColor="white" color="gray.100" text={data.announce_type_name} />
              <Typography
                color={isHover ? 'base.white' : 'text.primary'}
                variant="h5"
                mt="12px"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                {data.announce_title}
              </Typography>
              <Typography
                color={isHover ? 'base.white' : 'text.secondary'}
                variant="body2"
                mt="12px"
                whiteSpace="pre-wrap"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {data.announce_desc}
              </Typography>
            </Box>
            {data.rgst_at && (
              <Typography
                color={isHover ? 'base.white' : 'text.secondary2'}
                variant="body3"
                mt="20px"
              >
                {format(new Date(data.rgst_at), 'MMM d, yyyy')}
              </Typography>
            )}
          </Stack>
        </Box>
      </Link>
    </Box>
  );
}
