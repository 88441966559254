import { LoadingWrapper } from '@ground/ui';
import { last } from 'lodash-es';

import { useGetUniversityMemberInfoAPI } from 'api/university/universityAPI';
import { QuerystringProvider } from 'context';
import { SortTypeEnum } from '../reviewCommon';
import StudentReviewFilter from './StudentReviewFilter';
import StudentReviewList from './StudentReviewList';

export default function StudentReviewsMain() {
  const { isLoading, data } = useGetUniversityMemberInfoAPI('educator');

  return (
    <LoadingWrapper isLoading={isLoading} size={48}>
      <QuerystringProvider
        defaultQuery={{
          class_id: last(data?.data.class_list)?.class_id,
          sort: SortTypeEnum.createdAt,
        }}
      >
        <div>
          {/* 상단 필터 영역 (클래스 선택 및 검색, 정렬) */}
          <StudentReviewFilter />
          {/* 리뷰 리스트. 로딩 및 리뷰 0개 상태 추가 */}
          <StudentReviewList />
        </div>
      </QuerystringProvider>
    </LoadingWrapper>
  );
}
