import { FormControlLabel, FormControlLabelProps, Radio, RadioProps, styled } from '@mui/material';
import { forwardRef, Ref } from 'react';

const Icon = styled('span')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.opacity.white[10],
  border: `1px solid ${theme.palette.opacity.white[20]}`,
  borderRadius: '50%',
}));

const CheckedIcon = styled(Icon)<{ readOnly?: boolean }>(({ theme, readOnly }) => ({
  backgroundColor: readOnly ? theme.palette.base.dimWhite : theme.palette.base.white,
  border: `5px solid ${readOnly ? theme.palette.gray[30] : 'currentColor'}`,

  // disabled color state
  'input:disabled ~ &': {
    backgroundColor: theme.palette.opacity.white[10],
    border: `1px solid ${theme.palette.opacity.white[20]}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
      border: `5px solid ${theme.palette.opacity.white[20]}`,
      borderRadius: '50%',
    },
  },
}));

const StyledRadio = styled((props: RadioProps) => (
  <Radio
    disableRipple
    checkedIcon={<CheckedIcon readOnly={props.readOnly} />}
    icon={<Icon />}
    {...props}
  />
))(({ theme }) => ({
  width: 20,
  height: 20,
  padding: 0,

  // radio label styles
  '& + .MuiFormControlLabel-label': {
    marginLeft: '8px',
    color: theme.palette.text.secondary,
  },

  '&.Mui-checked': {
    '& + .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },

  '&.Mui-disabled': {
    opacity: 0.3,
    '& + .MuiFormControlLabel-label': {
      color: theme.palette.gray[40],
    },
  },
}));

export interface GRadioProps extends Omit<FormControlLabelProps, 'control'> {
  readOnly?: boolean;
  RadioProps?: RadioProps;
}

const GRadio = forwardRef((props: GRadioProps, ref: Ref<HTMLLabelElement>) => {
  const { label = '', sx, readOnly, RadioProps, ...inProps } = props;

  return (
    <FormControlLabel
      ref={ref}
      control={<StyledRadio {...RadioProps} readOnly={readOnly} />}
      componentsProps={{ typography: { variant: 'body2' } }}
      sx={{ margin: 0, ...sx }}
      label={label}
      {...inProps}
    />
  );
});

export default GRadio;
