import { Box } from '@mui/material';

import NoData from 'elements/NoData/NoData';

export default function NoContents() {
  return (
    <Box width="100%" height="100%">
      <NoData
        title="No Content"
        subTitle={
          <>
            Seems like the developers were too busy perfecting their games.
            <br />
            No news for now!
          </>
        }
      />
    </Box>
  );
}
