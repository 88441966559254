import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import { GameStatusType } from 'types/GameCommonTypes';
import { useGetDetailData } from '../DetailProvider';
import PrivateReviewForm from './PrivateReviewForm';
import ReviewForm from './ReviewForm';
import { useReviewData } from './ReviewProvider';

export default function Review() {
  const { loading, data } = useReviewData();
  const { data: detailData } = useGetDetailData();
  const { filled, agreed_terms } = useSelector((state: iRootState) => state.login);
  const isPrivate = typeof detailData?.private_test === 'number';

  return !agreed_terms ? (
    <Typography>Please agree to the terms and conditions first.</Typography>
  ) : !filled ? (
    <Typography>
      Your profile is incomplete. Head over to your profile page and connect to Facebook. Don't
      forget to add any other missing information, like country and gender!
    </Typography>
  ) : detailData?.state !== GameStatusType.TESTING ? (
    <Typography>Review was closed</Typography>
  ) : loading ? (
    <Box textAlign="center" minHeight={1000}>
      <CircularProgress color="primary" size={64} />
    </Box>
  ) : data?.essential_questions ? (
    isPrivate ? (
      <PrivateReviewForm />
    ) : (
      <ReviewForm />
    )
  ) : (
    <Typography>Review was not ready</Typography>
  );
}
