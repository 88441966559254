import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGameLikeToggleAPI } from 'api/like/likeAPI';
import { useSignupDialog, useThrottle } from 'hooks';
import { iRootState } from 'store';
import GameCardLikeButton from './GameCardLikeButton';

interface GameCardLikeButtonContainerProps {
  gameId: number | string;
  likeCheck: boolean;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
}

export default function GameCardLikeButtonContainer({
  gameId,
  likeCheck,
  onLikeClicked,
}: GameCardLikeButtonContainerProps) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showSignupDialog } = useSignupDialog();

  const { fetchLike } = useGameLikeToggleAPI(gameId ?? '');
  const [liked, setLiked] = useState(!!likeCheck);

  // props에서 likeCheck 값이 변경된 경우(캐쉬 갱신된 경우)
  useEffect(() => {
    setLiked(likeCheck);
  }, [likeCheck]);

  const handleClickFavorite = (liked: boolean) => {
    if (isLogin) {
      fetchLike();
      // 빠른 처리를 위해 결과와 상관없이 미리 좋아요 처리
      setLiked(!liked);
      onLikeClicked?.({ liked: !liked, id: gameId });
    } else {
      showSignupDialog();
    }
  };

  const throttleHandleClickFavorite = useThrottle(handleClickFavorite, 500);

  return (
    <GameCardLikeButton isActivated={liked} onClick={() => throttleHandleClickFavorite(liked)} />
  );
}
