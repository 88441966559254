export const NotificationTarget = {
  'PC Web_Badge': 1,
  'PC Web_Push': 2,
  'Mobile Web_Badge': 4,
  'Mobile Web_Push': 8,
  'iOS APP_Badge': 16,
  'iOS APP_Push': 32,
  'Android APP_Badge': 64,
  'Android APP_Push': 128,
  'Window (Launcher APP)_Badge': 256,
  'Window (Launcher APP)_Push': 512,
  Email: 1024,
} as const;

export const NotificationCategory = {
  Playtest: 'PLT',
  Account: 'ACC',
  'G.Point': 'GPS',
  Store: 'STO',
  Official: 'OFI',
  Event: 'EVT',
  Blog: 'BLG',
  Etc: 'ETC',
} as const;

export const NotificationType = {
  Auto: 'A',
  Manual: 'M',
} as const;

export const NotificationStatus = {
  Sending: 'I',
  Scheduled: 'R',
  Sent: 'S',
  Draft: 'D',
  Error: 'E',
} as const;

export module NotificationTypes {
  export type Type = (typeof NotificationType)[keyof typeof NotificationType];
  export type Category = (typeof NotificationCategory)[keyof typeof NotificationCategory];
  export type Status = (typeof NotificationStatus)[keyof typeof NotificationStatus];
  export type Target = (typeof NotificationTarget)[keyof typeof NotificationTarget];
}

export interface Notification {
  /**
   * 뱃지 고유값
   */
  notification_send_id: number;

  notification_type: NotificationTypes.Type;
  notification_category: NotificationTypes.Category;

  badge_title: string;
  badge_message: string;
  push_title: string;
  push_message: string;

  thumbnail_img_url: string;

  pc_landing_url: string | null;
  ios_landing_url: string | null;
  and_landing_url: string | null;
  mo_landing_url: string | null;
  win_landing_url: string | null;

  /**
   * 뱃지 읽음 여부
   */
  read_flag: boolean;
  send_reservation_date: string | null;

  /**
   * 뱃지 생성일
   */
  created_at: string;

  /**
   * 사용하지 않는 데이터
   */
  user_id: number;
  notification_id: string;
  target: NotificationTypes.Target;
  top_flag: boolean;
  display_end_dt: string;
  display_period: number | null;
  read_count: number;
  created_by: number;
  updated_at: string | null;
  updated_by: number | null;
}

export interface NotificationDetailType {
  notification_send_list: Notification[];
  email_send_list: string[] | null;
  result_count: number;
}

export interface NotificationReadResponse {
  log_result: number;
  result: number;
}
