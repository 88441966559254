import { FormAutocomplete, FormAutocompleteOption } from '@ground/ui';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useLog } from 'utils/userLog';

interface Props {
  isMobile?: boolean;
  logArea: string;
}

export default function SignupCountry({ isMobile, logArea }: Props) {
  const { isLoading, data: countriesData } = useGetCountriesAPI();
  const { gxcTag } = useLog();

  const countryList: FormAutocompleteOption[] = useMemo(
    () =>
      countriesData?.map((country) => ({
        name: country.name,
        value: country.code,
      })) ?? [],
    [countriesData]
  );

  const handleClickCountry = () => {
    gxcTag('click', { area: logArea, ui: 'select_country' });
  };

  return (
    <div>
      <FormAutocomplete
        label="Country"
        loading={isLoading}
        name="country"
        placeholder="Select your country"
        options={countryList}
        rules={{ required: 'Please select a country' }}
        size={isMobile ? 'large' : 'medium'}
        withBackground
        onClick={handleClickCountry}
      />
      <Typography color="text.secondary2" mt="8px" variant="body3">
        *Your country cannot be changed after you submit.
      </Typography>
    </div>
  );
}
