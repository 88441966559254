import { MouseEvent, useRef, useState, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';

import MuteIcon from './MuteIcon';

export interface VideoBoxProps extends BoxProps<'video'> {
  isHover: boolean;
  muted?: boolean;
  onClickMute?: () => void;
}

export const VideoBox: React.FC<VideoBoxProps> = ({
  isHover,
  muted = true,
  onClickMute,
  ...props
}) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [show, setShow] = useState(false);
  const [controls, setControls] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);

    const playPromise = ref.current?.play();
    if (playPromise !== undefined) {
      playPromise.catch((error) => {
        if (error.name === 'NotAllowedError') {
          // 자동재생이 허용되지 않는 경우 control을 보여줘서 수동재생
          setControls(true);
        } else {
          // 에러가 발생하는 경우 숨김
          setShow(false);
          console.log(error);
        }
      });
    }
  };

  const handleOnMouseLeave = () => {
    setShow(false);
    ref.current?.pause();
  };

  const handleClickMute = (e: MouseEvent) => {
    e.preventDefault();
    onClickMute?.();
  };

  useEffect(() => {
    isHover ? handleOnMouseEnter() : handleOnMouseLeave();
  }, [isHover]);

  return (
    <Box position="relative" sx={{ opacity: show ? 1 : 0, transition: 'opacity 0.5s' }}>
      <Box
        component="video"
        display="block"
        ref={ref}
        muted={muted}
        controls={controls}
        onEnded={() => setShow(false)}
        {...props}
      />
      {show && (
        <MuteIcon
          muted={muted}
          onClick={handleClickMute}
          style={{ position: 'absolute', right: '12px', bottom: '16px' }}
        />
      )}
    </Box>
  );
};
export default VideoBox;
