import { GTab, GTabs } from '@ground/ui';
import { Box, CircularProgress, Paper, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { useGameDetailInfoAPI, useGameInfoSystemRequirementsAPI } from 'api/detail/detailAPI';
import { ImageBox } from 'atoms';
import { Carousel } from 'elements';
import { useClientApplyData } from '../../ClientApplyProvider';
import ClientSystemRequirementBox from '../../ClientSystemRequirementBox';

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  height: 300,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '0 48px 48px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
}));

const swiperParams: SwiperOptions = {
  slidesPerView: 1,
  lazy: true,
};

const RootCarousel = styled(Carousel)({
  marginTop: 40,
});

export default function ClientApplyTabs() {
  const { gameId, recruitmentData } = useClientApplyData();
  const { data: info, isLoading } = useGameDetailInfoAPI(gameId, 'JA');
  const { data: system, isLoading: systemLoading } = useGameInfoSystemRequirementsAPI(gameId);
  const [selectTab, setSelectTab] = useState('overview');

  useEffect(() => {
    if (recruitmentData?.overview !== 'y') {
      recruitmentData?.screenshots === 'y' ? setSelectTab('screenshots') : setSelectTab('system');
    }
  }, [recruitmentData]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setSelectTab(newValue);
  };

  if (isLoading && systemLoading)
    return (
      <Box textAlign="center" mt="40px">
        <CircularProgress color="primary" size={64} />
      </Box>
    );

  const overview = info?.data[0]?.overview;
  const screenshotsList = Array.isArray(info?.data[0]?.screenshots)
    ? info?.data[0].screenshots
    : [];

  return (
    <>
      <GTabs
        value={selectTab}
        onChange={handleChange}
        aria-label="client apply nav tabs"
        height={37}
        sx={{ marginY: '40px' }}
      >
        {recruitmentData?.overview === 'y' ? <GTab label="概要" value="overview" /> : null}
        {recruitmentData?.screenshots === 'y' ? (
          <GTab label="スクリーンショット" value="screenshots" />
        ) : null}
        {recruitmentData?.system_requirements === 'y' ? (
          <GTab label="システム要求" value="system" />
        ) : null}
      </GTabs>

      <Box>
        {recruitmentData?.overview === 'y' && selectTab === 'overview' ? (
          <>
            <Typography variant="body1" color="text.secondary">
              {overview}
            </Typography>
          </>
        ) : null}

        {recruitmentData?.screenshots === 'y' && selectTab === 'screenshots' ? (
          <RootCarousel
            sx={{
              paddingBottom: screenshotsList?.length === 1 ? '0px' : '45px',
            }}
            swiperOption={swiperParams}
            name="game-detail-screenshots"
            width={1080}
            hasNavigation
            hasPagination
            bulletWidth={48}
            navBtnIconType="arrow"
            navBtnIconWidth={48}
            navBtnRowPosition={12}
          >
            {screenshotsList
              ?.sort((a, b) => a.seq - b.seq)
              .map((screenshot, index: number) => (
                <SwiperSlide
                  key={index}
                  style={{
                    position: 'relative',
                    width: '1080px',
                    height: '608px',
                  }}
                >
                  <ImageBox
                    src={screenshot.file_url}
                    alt={`screenshot ${index}`}
                    resize
                    resizeWidth={1080}
                    resizeHeight={608}
                    overlay
                    format="webp"
                    quality={70} // 이미지가 커서 리사이징 안되는 친구들이 있어서 퀄리티 낮춤
                    sx={{ borderRadius: '5px' }}
                  />

                  <TextContainer>
                    {Boolean(screenshot?.title) && (
                      <Typography variant="subtitle1" width="827px">
                        {screenshot.title}
                      </Typography>
                    )}
                    {Boolean(screenshot?.description) && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        marginTop="4px"
                        width="827px"
                      >
                        {screenshot.description}
                      </Typography>
                    )}
                  </TextContainer>
                </SwiperSlide>
              ))}
          </RootCarousel>
        ) : null}

        {recruitmentData?.system_requirements === 'y' && selectTab === 'system' ? (
          <Box>
            <Paper sx={{ background: 'inherit' }}>
              <Box display="flex" justifyContent="space-between" columnGap="40px">
                <ClientSystemRequirementBox
                  titleColor="basic.white"
                  type="最小限です"
                  data={system?.data}
                />
                <ClientSystemRequirementBox
                  titleColor="basic.white"
                  type="要件です"
                  data={system?.data}
                />
              </Box>
            </Paper>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
