/**
 *
 * pathname 값으로부터 params id 값을 반환하는 함수
 *
 * @description
 * 웹 최상단에서 react-router의 useParams hook을 이용하여 params id 값을 가져올 수 없어서 구현한 함수.
 */

interface Props {
  path: string; // pathname
  key: string; // pathname format
  paramNames: string[] | undefined; // pathname paramNames
}

export const getParams = ({ key, path, paramNames }: Props) => {
  let params = {};

  if (!!paramNames?.length) {
    paramNames.forEach((paramName) => {
      const paramIndex = key
        .replace(':', '')
        .split('/')
        .findIndex((item) => item === paramName);

      const paramValue = path.split('/')[paramIndex];

      params = { ...params, [paramName]: paramValue };
    });
  }

  return params;
};
