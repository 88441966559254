import { GroundLogo } from '@ground/ui';
import { Box, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { LinkButton } from 'elements';

export default function Error() {
  const { state } = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Paper sx={{ width: '400px', padding: '60px' }}>
        <GroundLogo colorType="light" />
        <Typography color="error" variant="h6" sx={{ marginTop: '32px', marginBottom: '20px' }}>
          Error
        </Typography>
        <Typography color="text.secondary" variant="body2">
          An error occurred while logging in.
          <br /> Please try again.
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ mt: '8px', wordBreak: 'break-word' }}
        >
          {state as string}
        </Typography>
        <LinkButton fullWidth color="light" variant="outlined" to="/" sx={{ marginTop: '32px' }}>
          Go to G.Round Website
        </LinkButton>
      </Paper>
    </Box>
  );
}
