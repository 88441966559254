import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';
import WebFont from 'webfontloader';

import { suppoterApplyContents } from '../apply/suppoterApplyContents';

interface ClientApplyContentsProps {
  ndaData: {
    fullname: string;
    ndacompleted_at: string;
  };
}

/** nda 계약서 */
export default function SuppoterApplyConfirmCotents(props: ClientApplyContentsProps) {
  const { fullname, ndacompleted_at } = props.ndaData;

  useEffect(() => {
    window.scrollTo(0, 0);
    WebFont.load({
      google: {
        families: ['La Belle Aurore'],
      },
    });
  }, []);

  return (
    <Box width="1200px" mb="40px" p="60px" sx={{ background: (theme) => theme.palette.gray[80] }}>
      <Box
        mb="60px"
        p="40px"
        sx={{
          background: (theme) => theme.palette.gray[100],
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          alert('Prevent right-click');
        }}
      >
        <Box p="60px" sx={{ background: 'white' }} height="1400px" overflow="auto">
          <Typography variant="h5" color="gray.100" mb="40px">
            Non-Disclosure and Confidentiality Agreement
          </Typography>
          <Typography variant="body1" color="gray.100" mb="40px">
            This Nondisclosure Agreement (the “Agreement”) is entered into by and between{' '}
            <Typography fontWeight={700} component="span">
              G.Round Inc.
            </Typography>{' '}
            (“
            <Typography color="text.private" component="span">
              Disclosing Party
            </Typography>
            “) andand User{' '}
            <Typography fontWeight={700} component="span">
              {fullname}
            </Typography>{' '}
            (“
            <Typography color="text.private" component="span">
              Receiving Party
            </Typography>
            “) for the purpose of preventing the unauthorized disclosure of Confidential Information
            as defined below. The parties agree to enter into a confidential relationship with
            respect to the disclosure of certain proprietary and confidential information
            (“Confidential Information”).
          </Typography>

          <Box type="1" component="ol" pl="30px">
            {suppoterApplyContents.map((text, index) => (
              <Box mb="28px" key={index}>
                <Typography variant="body1" color="gray.100" component="li">
                  <Typography variant="body1" color="text.private" component="span">
                    {text?.title}
                  </Typography>
                  {` `}
                  <Typography variant="body1" color="gray.100" component="span">
                    {text?.content}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography variant="body1" color="gray.100" mb="80px">
            This Agreement and each party’s obligation shall be binding on the representatives,
            assigns and successors of such party. Each party has signed this Agreement through its
            authorized representative.
          </Typography>

          <Box mb="80px">
            <Typography variant="h5" color="gray.100" mb="20px">
              DISCLOSING PARTY
            </Typography>
            <Box display="flex" mb="20px">
              <Typography variant="subtitle1" color="text.private">
                G.Round Inc.
              </Typography>
              <Typography variant="subtitle1" color="gray.20" m="0 12px">
                |
              </Typography>
              <Typography variant="subtitle1" color="text.private">
                James Choi Chief Business Officer
              </Typography>
            </Box>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box display="flex" position="relative">
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  James Choi
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(new Date(ndacompleted_at || new Date()), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" color="gray.100" mb="20px">
              RECEIVING PARTY
            </Typography>

            <Typography variant="subtitle1" color="text.private" mb="20px">
              {fullname}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box
                display="flex"
                position="relative"
                width="400px"
                justifyContent={'space-between'}
              >
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {fullname}
                </Typography>
                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(new Date(ndacompleted_at), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ margin: '40px 0 60px' }} />
    </Box>
  );
}
