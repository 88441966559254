import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/dist/types';

import { useGetReviewDataAPI } from 'api/review/reviewAPI';
import { ReviewDataProps } from 'types/FormDataType';
import { ResponseData } from 'types/ResponseType';

export interface ReviewProps {
  data?: ReviewDataProps;
  loading: boolean;
  mutate?: KeyedMutator<ResponseData<ReviewDataProps>>;
}

const ReviewStateContext = createContext<ReviewProps | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export default function ReviewProvider({ children }: Props) {
  const { id = '' } = useParams<{ id: string }>();
  const { isLoading, data, mutate } = useGetReviewDataAPI(id);
  const dataList = useMemo(() => data?.data, [data]);

  return (
    <ReviewStateContext.Provider
      value={{
        data: dataList,
        loading: isLoading,
        mutate,
      }}
    >
      {children}
    </ReviewStateContext.Provider>
  );
}

export const useReviewData = () => {
  const context = useContext(ReviewStateContext);
  if (!context) throw new Error('reviewData context not found');
  return context;
};
