import { GIconButton } from '@ground/ui';
import { Box, ClickAwayListener, styled, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import { useLocalStorageTooltip } from 'hooks';
import { iRootState } from 'store';
import { useLog } from 'utils/userLog';
import { NotificationItemList } from '.';

import triangleGrayImg from 'images/notification/triangle-gray.svg';
import triangleImg from 'images/notification/triangle.svg';

import 'simplebar/dist/simplebar.min.css';

const AlertIcon = styled(Box)(({ theme }) => ({
  width: '10px',
  height: '10px',
  position: 'absolute',
  right: '7px',
  top: '7px',
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.base.black}`,
  pointerEvents: 'none',
}));

const NotificationListBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.gray[80],
  boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.3)',
  width: 460,
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '5px',
  padding: '24px 0 0 28px',
}));

const NotificationContainer = styled(Box)({
  position: 'relative',
  top: 16,
});

const InformationBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.gray[40],
  width: 300,
  padding: '12px 16px',
  boxShadow: `10px 10px 30px rgba(0, 0, 0, 0.3)`,
  borderRadius: '5px',
  top: 52,
  left: '50%',
  transform: 'translateX(-50%)',
}));

const TriangleImage = styled('img')({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%)',
  top: -10,
  zIndex: 1,
});

const GnbNotification = () => {
  const { gxcTag } = useLog();
  const { identifier } = useSelector((state: iRootState) => state.login);
  // const [isSettingOpen, setIsSettingOpen] = useState(false);

  const [isListOpen, setIsListOpen] = useState(false);
  const [tooltip, setTooltip] = useLocalStorageTooltip();

  const { data: countData, mutate } = useGetNotificationCountAPI();

  const openTooltip = !tooltip.notification[identifier];

  const setTooltipNotificationDisabled = () =>
    setTooltip({
      notification: { ...tooltip.notification, [identifier]: true },
    });

  const handleClickNotification = () => {
    gxcTag('click', { area: 'gnb', ui: 'notification' });
    setIsListOpen((c) => !c);
    if (openTooltip) setTooltipNotificationDisabled();
  };

  // const onSettingClose = () => setIsSettingOpen((c) => !c);

  const handleCloseTooltip = () => {
    setTooltipNotificationDisabled();
  };

  const handleClickAway = useCallback(() => {
    setIsListOpen(false);
  }, []);

  const haveNewNotification = typeof countData?.data === 'number' && countData?.data > 0;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ marginRight: '12px', position: 'relative' }}>
        {openTooltip && (
          <InformationBox>
            <TriangleImage src={triangleGrayImg} alt="triangle" />

            <Box sx={{ display: 'flex', position: 'absolute', top: '4px', right: '4px' }}>
              <GIconButton onClick={handleCloseTooltip} iconName="close" iconSize={16} size={24} />
            </Box>

            <Typography variant="body3">
              Now, you can get notifications about your
              <br /> game testing and important G.Round news!
            </Typography>
          </InformationBox>
        )}
        <Box position="relative">
          <GIconButton
            iconName="notification"
            iconSize={24}
            size={36}
            onClick={handleClickNotification}
          />
          {haveNewNotification && <AlertIcon />}
        </Box>

        {isListOpen && (
          <NotificationContainer>
            <TriangleImage src={triangleImg} alt="triangle" />
            <NotificationListBox>
              <NotificationItemList revalidateCount={mutate} setIsListOpen={setIsListOpen} />
              {/* {isSettingOpen ? (
                <SettingItemList onSettingClose={onSettingClose} />
              ) : (
                <NotificationItemList />
              )} */}
            </NotificationListBox>
          </NotificationContainer>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default GnbNotification;
