import { GTab, GTabs } from '@ground/ui';
import { Badge } from '@mui/material';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { iRootState } from 'store';
import eventTracker from 'utils/eventTracker';

export default function ProfileTab() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { filled } = useSelector((state: iRootState) => state.login);

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    navigate(newValue);
    eventTracker(`profile_upper_tab_${newValue}`);
  };

  const value = pathname.split('/')[2];

  return (
    <GTabs
      value={value}
      onChange={handleChange}
      aria-label="profile nav tabs"
      height={37}
      sx={{ marginTop: '80px', marginBottom: '40px' }}
    >
      <GTab label="G.Points" value="gpoint" />
      <GTab
        label={
          <Badge
            color="error"
            invisible={!!filled}
            variant="dot"
            sx={{
              '& .MuiBadge-badge': { top: 2, right: -2 },
              '& .MuiBadge-dot': { borderRadius: '2px', height: 4, minWidth: 4 },
            }}
          >
            Profile
          </Badge>
        }
        value="profile"
        sx={{ overflow: 'visible' }}
      />
      <GTab label="Orders" value="orders" />
    </GTabs>
  );
}
