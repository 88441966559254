import { AxiosError } from 'axios';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetDigitalGoodsDetailAPI } from 'api/gstore/digitalGoodsAPI';
import { useGetUserStoreDataAPI } from 'api/gstore/promotionProductDetailAPI';
import { DigitalGoodsDetail } from 'types/DigitalGoodsType';
import { GStoreUserStoreType } from 'types/GStoreTypes';
import { ResponseData } from 'types/ResponseType';
import { GPointsSummaryData, useGetGPointSummary } from 'api/profile/profileAPI';
import { KeyedMutator } from 'swr';

type OrderOption = {
  item: number | string;
  amount: string;
  id: number;
  qty: number;
  price: number;
  title?: string;
};

type ContextProps = {
  gpointSummaryData?: ResponseData<GPointsSummaryData>;
  isLoading: boolean;
  isSummaryLoading: boolean;
  data?: ResponseData<DigitalGoodsDetail>;
  error?: AxiosError;
  gstoreId?: string;
  shippingInfoOpen?: boolean;
  setShippingInfoOpen?: Dispatch<SetStateAction<boolean>>;
  userPointData?: ResponseData<GStoreUserStoreType>;
  isUserPointLoading?: boolean;
  selectedOption: OrderOption;
  setSelectedOption: React.Dispatch<React.SetStateAction<OrderOption>>;
  summaryMutate: KeyedMutator<ResponseData<GPointsSummaryData>>;
};

interface IProps {
  children: ReactNode;
}

const DetailContext = createContext<Partial<ContextProps>>({});

export const DetailDigitalProvider = ({ children }: IProps) => {
  const { gstoreId = '' } = useParams<{ gstoreId: string }>();
  const { data, isLoading, error } = useGetDigitalGoodsDetailAPI(gstoreId);
  const {
    data: gpointSummaryData,
    isLoading: isSummaryLoading,
    mutate: summaryMutate,
  } = useGetGPointSummary();

  const [shippingInfoOpen, setShippingInfoOpen] = useState<boolean>(false);
  const { data: userPointData, isLoading: isUserPointLoading } = useGetUserStoreDataAPI();

  const [selectedOption, setSelectedOption] = useState<OrderOption>({
    item: 0,
    amount: '',
    id: 0,
    qty: 0,
    price: 0,
  });

  return (
    <DetailContext.Provider
      value={{
        gpointSummaryData,
        isSummaryLoading,
        gstoreId,
        shippingInfoOpen,
        setShippingInfoOpen,
        userPointData,
        isUserPointLoading,
        selectedOption,
        setSelectedOption,
        isLoading,
        data,
        error,
        summaryMutate,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};

export const useGStoreDetailDigitalData = () => {
  return useContext(DetailContext);
};
