import { RadioGroup, RadioGroupProps } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { GRadio } from '../atoms';
import { SelectionProps } from './FormType';
import { defaultOptions } from './RadioGroupDefaultOptions';

interface Props extends RadioGroupProps {
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  options?: Array<SelectionProps>;
  rules?: RegisterOptions;
  radio?: {
    color?: string;
    width?: number;
    align?: 'start' | 'center' | 'end';
  };
}

export default function FormRadioGroup({
  defaultValue = '',
  disabled = false,
  name,
  options = defaultOptions,
  rules = { required: true },
  radio,
  ...props
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <RadioGroup {...field} aria-label={name} row {...props}>
          {options.map((option) => (
            <GRadio
              key={option.id}
              ref={ref}
              disabled={disabled || option.disabled}
              readOnly={option.readOnly}
              label={option.label ?? ''}
              value={option.order.toString()}
              sx={{ width: radio?.width, justifyContent: radio?.align }}
              color={radio?.color}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
}
