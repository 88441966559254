import { FormInput } from '@ground/ui';

import { useFormContext } from 'react-hook-form';

import { OptionalQuestionType } from 'types/FormDataType';
import ReviewQuestionTitle from './ReviewQuestionTitle';

interface Props {
  disabled?: boolean;
  index: number;
  first_review: boolean;
}

export default function ReviewQuestionTextField({
  question,
  seq,
  disabled,
  index,
}: OptionalQuestionType & Props) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const name = `optional.${index}`;

  return (
    <div>
      <input {...register(`${name}.id`)} type="hidden" />
      <ReviewQuestionTitle
        order={seq ?? 0}
        error={!!errors[name]}
        question={question}
        optional
        optionalText="* Share your ideas! Posting your review to Comments will earn you additional G.P if selected by studios."
      />
      <FormInput
        disabled={disabled}
        name={`${name}.value`}
        inputProps={{ maxLength: 10000 }}
        placeholder="What did you really like about the game? What could be improved? Please refrain from reposting feedback from other reviews. Only constructive reviews of over 300 characters will be displayed, as these are the most helpful to developers!"
        rules={{
          required: 'min 300 characters',
          maxLength: 10000,
        }}
        fullWidth
        withBackground
        multiline
        minRows={8}
        sx={{ marginTop: '8px' }}
      />
      {/* cross posting 동의 여부 체크박스 */}
      {/* 제거 요청있었음 사이드 이펙트가 뭐가 발생할지 몰라 임시주석 - 11.07 배포이후 주석 처리할것*/}
      {/* 임시주석 - 나중에 삭제할것 */}
      {/* {first_review && sync_comment === true && (
        <Box display="flex" justifyContent="flex-end" marginTop="12px">
          <FormCheckbox name={`${name}.agree`} label="Make review public" />
        </Box>
      )} */}
    </div>
  );
}
