import { Box, Button, Typography } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from 'usehooks-ts';
import { format } from 'date-fns';

import { GameCardProps } from 'types/GameCardTypes';
import GameCardLikeButtonContainer from '../GameCardLikeButton/GameCardLikeButtonContainer';
import { ImageBox } from 'atoms';
import GameCardPrivateUpperArea from '../GameCardPrivateUpperArea';
import { useInView } from 'react-intersection-observer';
import { VideoBox } from '@ground/ui';
import { useDispatch, useSelector } from 'react-redux';
import { iRootState, Dispatch } from 'store';

export default function GameCardLargest(
  props: GameCardProps & { onClick?: (gameId?: number) => void }
) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const { mute } = useDispatch<Dispatch>();
  const muted = useSelector((state: iRootState) => state.mute);

  const handleClickMute = () => {
    mute.toggleMute();
  };

  return (
    <Link
      to={`/detail/${props.id}/info`}
      style={{ textDecoration: 'auto', pointerEvents: props.remainingText ? 'none' : 'auto' }}
      ref={ref}
      onClick={() => props?.onClick?.(props?.id)}
    >
      <Box
        position="relative"
        width={1200}
        height={420}
        ref={hoverRef}
        sx={{
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: isHover ? '0px 10px 10px rgba(0, 0, 0, 0.5)' : '',
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        <Box sx={{ width: '752px', height: '100%', overflow: 'hidden', position: 'relative' }}>
          {props.isPrivateShow ? (
            <GameCardPrivateUpperArea remainingText={props.remainingText} />
          ) : null}
          {inView && (
            <>
              {/* thumbnail */}
              <ImageBox
                src={props.thumbnail_url}
                alt="thumbnail"
                resize
                resizeWidth={752}
                resizeHeight={420}
                sx={{
                  transition: 'filter .3s, all 0.2s ease-in ',
                  filter: isHover ? 'brightness(1.4)' : '',
                  transform: isHover ? 'scale(1.1)' : '',
                }}
              />
              {/* thumbnail video */}
              {props?.hoverVideoPlay && props?.thumbnail_video_url && (
                <Box position="absolute" top={0} left={0}>
                  <VideoBox
                    isHover={isHover}
                    muted={muted}
                    onClickMute={handleClickMute}
                    width="752px"
                    height="420px"
                    src={props?.thumbnail_video_url}
                    sx={{ objectFit: 'cover' }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            flex: 1,
            background: isHover ? theme.palette.gray[70] : theme.palette.gray[80],
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          })}
        >
          <Typography
            variant="h4"
            color={isHover && !props.remainingText ? 'base.white' : 'text.primary'}
            sx={{ opacity: props.remainingText ? 0.3 : undefined }}
          >
            {props?.title}
          </Typography>
          <Typography
            variant="body1"
            color={isHover && !props.remainingText ? 'base.white' : 'text.secondary'}
            sx={{ opacity: props.remainingText ? 0.3 : undefined }}
          >
            {props.studio_name}
          </Typography>
          <Typography
            variant="body1"
            color={isHover && !props.remainingText ? 'base.white' : 'text.secondary'}
            sx={{ opacity: props.remainingText ? 0.3 : undefined }}
          >
            Period: {format(new Date(props.started_at), 'MMM dd, yyyy')} -{' '}
            {format(new Date(props.ended_at), 'MMM dd, yyyy')}
          </Typography>
          <Button
            variant="ghost"
            size="large"
            sx={{ position: 'absolute', bottom: 40 }}
            disabled={!!props?.remainingText}
          >
            {props.remainingText ? 'Coming Soon' : 'Play Now'}
          </Button>
        </Box>
        {props.likeButton && (
          <Box position="absolute" sx={{ top: 12, right: 12 }}>
            <GameCardLikeButtonContainer gameId={props.id} likeCheck={props.like_check} />
          </Box>
        )}
      </Box>
    </Link>
  );
}
