import { numberWithCommas } from '@ground/tools';
import { DevTool } from '@hookform/devtools';
import { Box, Typography, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useOrderPhysicalGoodsAPI } from 'api/gstore/gstoreAPI';
import { OOrderTab } from 'api/order';
import { useFetchResult } from 'hooks';
import { IGStoreOrderFormData, IGStoreShippingFormData } from 'types/GStoreTypes';
import eventTracker from 'utils/eventTracker';
import OrderPayInfo from './OrderPayInfo';
import OrderShippingInfo from './OrderShippingInfo';
import { useGetMerchandiseDetail } from 'api/gstore';

const PREFIX = 'Order';

const classes = {
  container: `${PREFIX}-container`,
  productInfoBox: `${PREFIX}-productInfoBox`,
  productInfoBoxImage: `${PREFIX}-productInfoBoxImage`,
  productInfoBoxContent: `${PREFIX}-productInfoBoxContent`,
  shippingInfoContainer: `${PREFIX}-shippingInfoContainer`,
};

const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginTop: 100,
    marginBottom: 200,
    width: 1200,
  },
  [`& .${classes.productInfoBox}`]: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[90],
    padding: 20,
    marginTop: 20,
    borderRadius: 5,
  },
  [`& .${classes.productInfoBoxImage}`]: {
    width: 150,
    height: 120,
    borderRadius: 5,
    marginRight: 40,
  },
  [`& .${classes.productInfoBoxContent}`]: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },

  [`& .${classes.shippingInfoContainer}`]: {
    marginTop: 60,
    display: 'flex',
    width: '100%',
    gap: 40,
  },
}));

interface Props {
  defaultValues: IGStoreShippingFormData;
}

export default function Order({ defaultValues }: Props) {
  const methods = useForm<IGStoreShippingFormData>({ defaultValues, shouldUnregister: true });
  const navigate = useNavigate();
  const { showResult } = useFetchResult();

  const { gstoreId = '' } = useParams<{ gstoreId: string }>();
  const { data: promotionData } = useGetMerchandiseDetail(gstoreId);
  const { isLoading: loadingOrderPhysicalGoods, fetch: orderPhysicalGoods } =
    useOrderPhysicalGoodsAPI();

  const onSubmit = async (formData: IGStoreShippingFormData) => {
    if (!promotionData) return;

    eventTracker('gstore_order_placeorder', {
      event_label: promotionData?.data?.sale_product_id ?? '',
    });

    const quantity = 1;
    const payment = { type: 'POINT', amount: promotionData?.data.point || 0 * quantity };
    const product = {
      product_id: promotionData?.data.sale_product_id,
      quantity,
      price: promotionData?.data.point,
    };
    const promotion_id = promotionData?.data.promotion?.id;
    const agree_bailment = true;
    const agree_store = true;

    const data: IGStoreOrderFormData = {
      shipping: formData,
      payment,
      product,
      promotion_id,
      agree_bailment,
      agree_store,
    };

    const response = await orderPhysicalGoods(data);
    showResult(response, {
      onSuccess: () => {
        navigate('/store/success', { state: OOrderTab.MERCHANDISE });
      },
      onError: () => {
        navigate('/store/failed', { state: OOrderTab.MERCHANDISE });
      },
    });
  };

  return (
    <Root className={classes.container}>
      <Typography variant="h5">Order/Payment</Typography>
      <Typography variant="body2" color="text.secondary" mt="4px">
        Please enter your shipping information and proceed with payment.
      </Typography>
      <Box className={classes.productInfoBox}>
        <img
          src={promotionData?.data?.main_media_url}
          alt="product"
          className={classes.productInfoBoxImage}
        />
        <div className={classes.productInfoBoxContent}>
          <Typography variant="h6" color="text.primary">
            {promotionData?.data?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="8px">
            Quantity : 1
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="4px">
            Price :{' '}
            {promotionData?.data?.point ? numberWithCommas(promotionData?.data.point) + ' G.P' : ''}
          </Typography>
        </div>
      </Box>
      <FormProvider {...methods}>
        <DevTool control={methods.control} placement="top-right" />
        <form
          className={classes.shippingInfoContainer}
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <OrderShippingInfo countries={promotionData?.data?.countries} />
          <OrderPayInfo loading={loadingOrderPhysicalGoods} />
        </form>
      </FormProvider>
    </Root>
  );
}
