import { Paper, Divider, styled } from '@mui/material';

import GPointSummaryItem from './GPointSummaryItem';
import { useGetGPointSummary } from 'api/profile/profileAPI';

const PREFIX = 'GPointSummary';

const classes = {
  paper: `${PREFIX}-paper`,
  divider: `${PREFIX}-divider`,
};

const StyledPaper = styled(Paper)({
  [`&.${classes.paper}`]: {
    display: 'flex',
    borderRadius: 5,
    justifyContent: 'space-evenly',
    height: 110,
    marginTop: 20,
  },
  [`& .${classes.divider}`]: {
    height: 32,
    margin: 'auto',
  },
});

export default function GPointSummary() {
  const { data } = useGetGPointSummary();

  return (
    <StyledPaper className={classes.paper}>
      <GPointSummaryItem title="Earned G.Points" gpoint={data?.data?.earned_point || 0} />
      <Divider className={classes.divider} component="div" flexItem orientation="vertical" />
      <GPointSummaryItem title="Purchased G.Points" gpoint={data?.data?.purchase_point || 0} />
      <Divider className={classes.divider} component="div" flexItem orientation="vertical" />
      <GPointSummaryItem title="Available G.Points" gpoint={data?.data?.available_point || 0} />
    </StyledPaper>
  );
}
