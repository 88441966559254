import { Box, Typography } from '@mui/material';
import { ComponentProps } from 'react';

import { ChipIconList } from './ChipIconList';

interface ChipProps {
  withBg?: boolean;
  bgColor?: ComponentProps<typeof Box>['bgcolor'];
  color?: ComponentProps<typeof Box>['color'];
  icon?: keyof typeof ChipIconList;
  text: string;
}

export function SmallChip({ withBg = false, bgColor, color, icon, text }: ChipProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '3px 8px',
        borderRadius: '3px',
        backgroundColor: bgColor ? bgColor : withBg ? 'gray.60' : 'gray.80',
        alignItems: 'center',
        width: 'fit-content',
        columnGap: '2px',
        color: color,
      }}
    >
      {icon ? ChipIconList[icon] : null}
      <Typography color={color} variant="small2" noWrap>
        {text}
      </Typography>
    </Box>
  );
}

export default SmallChip;
