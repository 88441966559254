import { LoadingWrapper } from '@ground/ui';
import { Box, Stack, Typography } from '@mui/material';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { useGetReviewListV2API } from 'api/comment/commentAPI';
import { NoData } from 'elements';
import { GameStatusType } from 'types/GameCommonTypes';
import { useGetDetailData } from 'views/detail/DetailProvider';
import ReviewContainerByUser from './ReviewContainerByUser';
import ReviewDialog from './ReviewDialog';
import ReviewPickGuide from './ReviewPickGuide';

export default function ReviewsMain() {
  const { id = '' } = useParams<{ id: string }>();

  const { search } = useLocation();
  const { review_id } = queryString.parse(search, { parseNumbers: true });
  const { data: gameDetailData } = useGetDetailData();

  const {
    data: bestReviewList,
    isLoading: isBestReviewLoading,
    mutate: mutateBestReviewList,
  } = useGetReviewListV2API(id);

  const hasBestReviewList =
    bestReviewList?.data && Array.isArray(bestReviewList.data) && bestReviewList?.data.length > 0;

  return (
    <Box>
      {gameDetailData?.state !== GameStatusType.CLOSED && (
        <Box mb="40px">
          <ReviewPickGuide />
        </Box>
      )}

      <LoadingWrapper isLoading={isBestReviewLoading} sx={{ height: 474 }} size={64}>
        {hasBestReviewList ? (
          gameDetailData?.state === GameStatusType.CLOSED ? (
            <Box sx={{ height: 474 }}>
              <NoData
                title={
                  <>
                    <Typography variant="body1" color="text.secondary2">
                      Private Contents
                    </Typography>
                    <Typography mt="4px" variant="body2" color="text.secondary2">
                      Sorry, you can’t view reviews as the testing period for the game has ended.
                    </Typography>
                  </>
                }
              />
            </Box>
          ) : (
            <Stack gap="36px" px="60px">
              {bestReviewList.data.map((review) => (
                <ReviewContainerByUser
                  key={review.id}
                  reviewData={review}
                  mutateReviewList={mutateBestReviewList}
                />
              ))}
            </Stack>
          )
        ) : (
          <Box sx={{ height: 474 }}>
            <NoData
              title={
                <>
                  Be the first to leave a Public Review!
                  <br />
                  Let others know about your opinions!
                </>
              }
            />
          </Box>
        )}
      </LoadingWrapper>

      {/* game id, review id가 있을 때 review 팝업 보여주기 */}
      {id && Number(review_id) ? (
        <ReviewDialog
          gameId={Number(id)}
          reviewId={Number(review_id)}
          testType="public"
          isAdmin={false}
        />
      ) : null}
    </Box>
  );
}
