import { Box, Dialog, IconButton } from '@mui/material';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import FilePlayer from 'react-player/file';
import YoutubePlayer from 'react-player/youtube';

import { MATCH_URL_YOUTUBE } from 'utils/common';
import { useLog } from 'utils/userLog';

import { ReactComponent as CloseIcon } from 'images/icons/icon_close.svg';

interface LogInfoTypes {
  id?: number | null;
  area?: string;
  parameters?: any;
}
export interface MobileVideoDialogState {
  /** 다이얼로그 노출 상태 `true`이면 보여진다. */
  open: boolean;
  /** 다이얼로그 메인 컨텐츠 영역 */
  videoUrl?: string;
  logInfo?: LogInfoTypes;
}

const VideoDialogDispatchContext = createContext<
  Dispatch<SetStateAction<MobileVideoDialogState>> | undefined
>(undefined);

export function MobileVideoDialogProvider({ children }: { children: ReactNode }) {
  const { gxcTag } = useLog();
  const [state, setState] = useState<MobileVideoDialogState>({
    open: false,
    videoUrl: '',
    logInfo: {
      id: null,
      area: '',
      parameters: {},
    },
  });

  const isYoutubeVideo = MATCH_URL_YOUTUBE.test(state.videoUrl ?? '');

  return (
    <VideoDialogDispatchContext.Provider value={setState}>
      {children}
      <Dialog
        open={state.open}
        onClose={() => {
          setState({ open: false });
        }}
        // fullWidth
        fullScreen
        PaperProps={{
          sx: {
            background: 'black',
            // fullwith시 옵션
            // margin: 0,
            // width: '100%',
            // borderRadius: 0,
            // '* > iframe': { display: 'block' },

            // fullscreen 옵션
            // height: '100%',
            // justifyContent: 'center',
          },
        }}
      >
        {/* title 영역 */}
        <Box display="flex" justifyContent="flex-end" width="100%" height="52px" padding="8px">
          <IconButton
            onClick={() => {
              setState({ open: false });
              gxcTag('click', {
                area: state?.logInfo?.area,
                ui: 'close_x',
                parameters: state?.logInfo?.parameters,
              });
            }}
            sx={{
              width: 36,
              height: 36,
              padding: 0,
              color: 'base.dimWhite',
              opacity: 0.5,
              '&:hover': { opacity: 1 },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* <Box height="100%" display="flex" alignItems="center"> */}
        {isYoutubeVideo ? (
          <YoutubePlayer
            width="100%"
            height="100%"
            controls
            muted
            playsinline
            playing
            style={{ paddingBottom: 52 }}
            url={state.videoUrl}
          />
        ) : (
          <FilePlayer
            width="100%"
            height="100%"
            controls
            muted
            playsinline
            playing
            style={{ paddingBottom: 52 }}
            url={state.videoUrl}
          />
        )}
        {/* </Box> */}
      </Dialog>
    </VideoDialogDispatchContext.Provider>
  );
}

function useMobileVideoDialogContext() {
  const context = useContext(VideoDialogDispatchContext);
  if (!context) throw new Error('MobileDialogProvider not found');
  return context;
}

/** useDialog는 간단한 Dialog를 매 번 만들지 않고 쉽게 사용하기 위해 만든 hook 입니다. */
export function useMobileVideoDialog() {
  const { gxcTag } = useLog();
  const setState = useMobileVideoDialogContext();

  return {
    /** Dialog를 설정한 값들을 포함하여 연다. */
    showVideoDialog: useCallback(
      (videoUrl: string, logInfo?: LogInfoTypes) => {
        setState({ open: true, videoUrl, logInfo });
        gxcTag('view', {
          area: logInfo?.area,
          parameters: logInfo?.parameters,
        });
      },
      [setState, gxcTag]
    ),
    /** 열려있는 Dialog를 닫는다 */
    closeVideoDialog: useCallback(() => setState({ open: false }), [setState]),
  };
}
