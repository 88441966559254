import { Navigate, Route, Routes } from 'react-router-dom';

import NoAuthRoute from 'views/main/NoAuthRoute';
import SignupProfile from 'views/sign/profile/SignupProfile';
import SignupBasicForm from 'views/sign/signup/SignupBasicForm';
import SignupEmailForm from 'views/sign/signup/SignupEmailForm';
import SignupProvider from 'views/sign/signup/SignupProvider';
import SignupVerificationForm from 'views/sign/verification/SignupVerificationForm';

export default function Signup() {
  return (
    <SignupProvider isMobile>
      <Routes>
        <Route
          index
          element={
            <NoAuthRoute>
              <SignupBasicForm />
            </NoAuthRoute>
          }
        />
        <Route
          path="email"
          element={
            <NoAuthRoute>
              <SignupEmailForm />
            </NoAuthRoute>
          }
        />
        <Route path="verification" element={<SignupVerificationForm />} />
        <Route path="profile" element={<SignupProfile />} />
        <Route path="*" element={<Navigate replace to={'..'} />} />
      </Routes>
    </SignupProvider>
  );
}
