import AuthRoute from 'views/main/AuthRoute';
import ProfileMain from './ProfileMain';

export default function Profile() {
  return (
    <AuthRoute>
      <ProfileMain />
    </AuthRoute>
  );
}
