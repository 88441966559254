import axios from 'axios';
import { useCallback, useEffect } from 'react';

import { convertUrlEnv } from 'utils/common';
import type { Log } from 'utils/userLog/types/logTypes';
import { getCurrentTime } from 'utils/userLog/utils/dateFns';
import { useLog } from './hooks/useLog';

const debug = process.env.NODE_ENV === 'development' ? console.debug : () => null;
// 대기중인 log timer
let timerId: number | null = null;

/**
 *
 * Log를 주기적으로 서버에 저장하는 컴포넌트
 * @param url 로그 API
 * @param timer 로그 API 호출되는 주기(seconds 단위)
 */

interface LogSentryProps {
  url: string;
  timer: number;
}

export default function LogSentry({ url, timer }: LogSentryProps) {
  const { getLogs, clearLogs } = useLog();

  const converUrl = convertUrlEnv(url);

  const handleError = useCallback(
    (logsToClear: Log[]) => {
      debug('## logs deleted:', logsToClear);
      clearLogs(logsToClear);
    },
    [clearLogs]
  );

  const callLogApi = useCallback(
    async (logs: Log[]) => {
      return axios
        .post(converUrl, { logs: logs })
        .then((response) => {
          if (response.status === 200) {
            debug('<--- API is called/ sent logs --->', logs);
          } else {
            debug('API error:', response.status);
          }
        })
        .catch((error) => {
          debug('API error:', error);
        })
        .finally(() => {
          handleError(logs);
        });
    },
    [converUrl, handleError]
  );

  const handleCallback = useCallback(async () => {
    const logs = getLogs();
    // let validLogs: Log[] = [];
    // let expiredLogs: Log[] = [];

    debug(`## total logs: [${getCurrentTime()}]`, logs);

    if (!logs?.length) {
      // 저장된 로그 데이터 X
      debug('<--- 로그 데이터 X --->');

      // do nothing
      window.setTimeout(() => handleCallback(), timer * 1000);
      return;
    }
    // 2024.01.22 BC 유효 로그 검증할 필요가 없어짐. 추후 제거
    /*
    // 유효한 로그인지 검증
    // e.g. 유저의 서비스 이탈로 전송되지 않은 로그에 대한 처리
    const validLogIndex = logs.findIndex((log) => isWithinLastSeconds(log.created_at, timer));

    if (validLogIndex === -1) {
      // 모든 로그가 유효하지 않은 데이터 (적재 주기를 벗어남)
      // = 서버에 저장할 수 있는 유효한 데이터 없는 상태
      debug('<--- 모든 로그가 유효하지 않은 데이터 --->');

      expiredLogs = logs;
      handleError(expiredLogs);
      window.setTimeout(() => handleCallback(), timer * 1000);
      return;
    }

    if (validLogIndex === 0) {
      // 모든 로그가 유효한 데이터
      debug('<--- 모든 로그가 유효한 데이터 --->');

      validLogs = logs;
    } else {
      // 유효하지 않은 데이터 + 유효한 데이터
      debug('<--- 유효하지 않은 데이터 + 유효한 데이터 --->');

      expiredLogs = logs.slice(0, validLogIndex);
      validLogs = logs.slice(validLogIndex);

      handleError(expiredLogs);
    }
    
    debug('## validLogs:', validLogs);
    */

    try {
      await callLogApi(logs);
    } catch (error) {
      debug('Error in API call:', error);

      handleError(logs);
    } finally {
      timerId = window.setTimeout(() => handleCallback(), timer * 1000);
    }
  }, [callLogApi, getLogs, handleError, timer]);

  // 시작 시 이전에 쌓여있던 로그가 있을 수 있으니 바로 전송한다.
  useEffect(() => {
    handleCallback();
    // 대기중인 timer가 있으면 제거
    return () => {
      if (timerId) {
        window.clearTimeout(timerId);
      }
    };
  }, [handleCallback]);

  return null;
}
