import { ReactNode } from 'react';
import { Link, useTheme } from '@mui/material';

interface ArtAboutSnsIconProps {
  link: string;
  img: ReactNode;
}

export default function ArtAboutSnsIcon({ link, img }: ArtAboutSnsIconProps) {
  const theme = useTheme();

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      sx={{
        width: 36,
        height: 36,
        '& > svg': {
          '&:hover circle': {
            fill: theme.palette.gray[40],
          },
        },
      }}
    >
      {img}
    </Link>
  );
}
