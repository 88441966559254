import { useTestingBannerAPI } from 'api/testing/testingAPI';
import { SubmainBannerList } from 'components';

export default function TestingTopBannerContainer() {
  const { isLoading, data } = useTestingBannerAPI();

  return (
    <SubmainBannerList name="testing-banner" banners={data?.data || []} isLoading={isLoading} />
  );
}
