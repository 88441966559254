import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { GameListMediumSlide } from 'components';
import { GameCardMediumSkeleton, GameCardMedium } from 'elements';
import { EventBadgeType } from 'types/EventBadgeType';
import { GameStatusType } from 'types/GameCommonTypes';
import { GameCardProps } from 'types/GameCardTypes';
import RedeemCard from 'elements/GameCard/GameCardMedium/RedeemCard';
import EnjoyCard from 'elements/GameCard/GameCardMedium/EnjoyCard';

export interface GameCardListMediumProps {
  data: GameCardProps[];
  loading: boolean;
  count: number;
  cardtype: GameStatusType;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  eventBadgeList?: EventBadgeType[];
  useSlider: boolean;
  likeButton?: boolean;
  navigationPositionTop?: number;
  isLauncherImage?: boolean;
  onClickCard?: (gameId?: number) => void;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  fillEmptyCard?: boolean;
  fillHeight?: boolean;
}

export default function GameListMediumCard({
  data,
  loading,
  count,
  name,
  hoverVideoPlay,
  eventBadgeList,
  useSlider,
  navigationPositionTop,
  likeButton = true,
  isLauncherImage,
  onClickLeftArrow,
  onClickRightArrow,
  onClickCard,
  onLikeClicked,
  fillEmptyCard,
  fillHeight,
}: GameCardListMediumProps) {
  const loadingSkeletonList = useMemo(
    () => Array.from({ length: useSlider && data.length > 4 ? 4 : count }, (_, i) => i),
    [count, data.length, useSlider]
  );

  return (
    <Grid container rowGap={'40px'} columnGap={'20px'}>
      {loading ? (
        loadingSkeletonList.map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardMediumSkeleton />
          </Grid>
        ))
      ) : useSlider && data.length > 4 ? (
        <GameListMediumSlide
          sliderData={data}
          name={name}
          navigationPositionTop={navigationPositionTop}
          isLauncherImage={isLauncherImage}
          onClickCard={(gameId) => {
            onClickCard?.(gameId);
          }}
          onLikeClicked={onLikeClicked}
          onClickLeftArrow={onClickLeftArrow}
          onClickRightArrow={onClickRightArrow}
          fillHeight={fillHeight}
        />
      ) : data.length > 0 ? (
        <>
          {data.map((game) => (
            <Grid item key={game.id}>
              <GameCardMedium
                {...game}
                hoverVideoPlay={hoverVideoPlay}
                likeButton={likeButton}
                event_badge={eventBadgeList?.find((badge) => badge.target_id === game.id)}
                isLauncherImage={isLauncherImage}
                onClickCard={(gameId) => {
                  onClickCard?.(gameId);
                }}
                onLikeClicked={onLikeClicked}
                fillHeight={fillHeight}
              />
            </Grid>
          ))}
          {(data.length % 4 === 1 || data.length % 4 === 2) && fillEmptyCard && (
            <Grid item>
              <RedeemCard />
            </Grid>
          )}
          {(data.length % 4 === 1 || data.length % 4 === 3) && fillEmptyCard && (
            <Grid item>
              <EnjoyCard />
            </Grid>
          )}
        </>
      ) : (
        // TODO: error 일 때 표시할 카드가 필요(우선 4개만 보이도록)
        loadingSkeletonList.slice(0, 4).map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardMediumSkeleton />
          </Grid>
        ))
      )}
    </Grid>
  );
}
