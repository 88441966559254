import { ReactNode, Ref, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Box,
} from '@mui/material';
import { Skeleton } from '@mui/material';

import { TypographyColor } from 'types/CommonTypes';
import DataTableHeadCell from 'components/dataTable/DataTableHeadCell';
import DataTableCell, { DataTableCellProps } from 'components/dataTable/DataTableCell';
import GPagination, { GPaginationProps } from 'components/pagination/GPagination';

const PREFIX = 'DataTable';

const classes = {
  container: `${PREFIX}-container`,
  tableHeaderRow: `${PREFIX}-tableHeaderRow`,
  tableRow: `${PREFIX}-tableRow`,
  loadingData: `${PREFIX}-loadingData`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    backgroundColor: 'transparent',
  },

  [`& .${classes.tableHeaderRow}`]: {
    borderBottom: `2px solid ${theme.palette.background.default}`,
    background: theme.palette.gray[90],

    '& th:first-of-type': {
      paddingLeft: 40,
      textAlign: 'left',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& th:nth-of-type(2)': {
      paddingLeft: 20,
      textAlign: 'left',
    },
    '& th:last-of-type': {
      paddingLeft: 20,
      '& > p': {
        width: 100,
      },
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },

  [`& .${classes.tableRow}`]: {
    borderBottom: `2px solid ${theme.palette.background.default}`,
    background: theme.palette.gray[90],
    '& td:first-of-type': {
      width: 320,
      paddingLeft: 40,
      paddingRight: 20,
      textAlign: 'left',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& td:nth-of-type(2)': {
      width: 440,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'left',
    },
    '& td:nth-of-type(3)': {
      width: 140,
    },
    '& td:nth-of-type(4)': {
      width: 140,
    },
    '& td:last-of-type': {
      paddingLeft: 20,
      '& > p': {
        width: 100,
      },
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[80],
      '& td > .MuiTypography-root:first-of-type': {
        color: theme.palette.base.dimWhite,
      },
    },
  },

  [`& .${classes.loadingData}`]: {
    margin: '0 16px',
  },
}));

export interface HeaderData {
  id: string;
  label: string;
  color?: TypographyColor;
}

export interface CellData {
  id: string;
  props?: DataTableCellProps;
  content: ReactNode;
}

interface Props {
  isLoading?: boolean;
  headers: HeaderData[];
  cellData: CellData[][];
  paginationProps?: GPaginationProps;
  observedRef?: Ref<HTMLTableRowElement>;
}

export default function GPointDataTable({
  isLoading,
  headers,
  cellData,
  paginationProps,
  observedRef,
}: Props) {
  const loadingData = useMemo(() => Array.from({ length: 10 }, (_, i) => i), []);

  return (
    <Root>
      <TableContainer className={classes.container} component={Paper} square>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {headers.map((header) => (
                <DataTableHeadCell key={header.id} headerStyle={{ height: 57 }}>
                  {header.label}
                </DataTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? loadingData.map((data) => (
                  <TableRow key={data} className={classes.tableRow}>
                    <TableCell padding="none" variant="body" colSpan={headers.length}>
                      <Skeleton className={classes.loadingData} height={56} />
                    </TableCell>
                  </TableRow>
                ))
              : cellData?.map((data, index) => (
                  <TableRow
                    key={index}
                    className={classes.tableRow}
                    ref={index === cellData.length - 1 ? observedRef : undefined}
                  >
                    {data?.map((cell) => (
                      <DataTableCell key={cell.id} {...cell.props}>
                        {cell.content}
                      </DataTableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationProps && (
        <Box display="flex" justifyContent="center" mt="48px">
          <GPagination {...paginationProps} />
        </Box>
      )}
    </Root>
  );
}
