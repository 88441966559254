import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, To, useLocation } from 'react-router-dom';

import { iRootState } from 'store';

interface NoAuthRouteProps {
  children: ReactElement;
  to?: To;
}

export default function NoAuthRoute({ children, to = '/' }: NoAuthRouteProps) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { state } = useLocation();

  return isLogin ? <Navigate to={(state as any)?.from ?? to} replace /> : children;
  // return (
  //   <Route
  //     {...routeProps}
  //     render={() =>
  //       isLogin ? <Navigate to={location.state?.from || '/'} replace /> : routeProps.children
  //     }
  //     // children={({ location }: RouteChildrenProps<{}, { from?: string }>) =>
  //     //   isLogin ? <Navigate to={location.state?.from || '/'} replace /> : routeProps.children
  //     // }
  //   />
  // );
}
