import { Box, Stack, Typography } from '@mui/material';

interface QuestionTitleProps {
  order: number;
  question: string;
  description?: string;
  error?: boolean;
  optional?: boolean;
  optionalText?: string;
}

export default function ReviewQuestionTitle({
  order,
  question,
  description,
  error,
  optional,
  optionalText,
}: QuestionTitleProps) {
  return (
    <Stack gap="8px">
      <Box display="flex" gap="10px">
        <Typography color={error ? 'error' : 'text.primary'} variant="button1">
          {order}. {question}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </Box>
      {optional && (
        <Typography variant="small2" color="text.secondary2">
          {optionalText ? optionalText : '* Optional'}
        </Typography>
      )}
    </Stack>
  );
}
