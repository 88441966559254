import { isNumber } from 'lodash-es';
import { useMemo } from 'react';

import { useGameEventBadgeListAPI, usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import GameListMediumCard from 'components/GameList/GameListMediumCard';
import { GameStatusType } from 'types/GameCommonTypes';
import { getConvertTimeText } from 'utils/common';
import { useLog } from 'utils/userLog';

interface GameCardListMediumContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  useSlider: boolean;
  includeSurveyGame?: boolean;
  likeButton?: boolean;
  navigationPositionTop?: number;
  isLauncherImage?: boolean;
}

export default function GameListMediumContainer({
  cardtype,
  count = 4,
  name,
  hoverVideoPlay,
  showViewMoreCard,
  useSlider,
  navigationPositionTop,
  isLauncherImage,
}: GameCardListMediumContainerProps) {
  const { data: timeData } = useGetNotificationCountAPI();
  const { isLoading, isValidating, data, error } = usePrivateGameListAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const privateGameData = data?.data?.sort((a, b) =>
    isNumber(a?.order_seq) && isNumber(b?.order_seq) ? a?.order_seq - b?.order_seq : 1
  );
  const { gxcTag } = useLog();

  const isDataNotExist =
    (Array.isArray(privateGameData) &&
      privateGameData.length === 0 &&
      !isLoading &&
      !isValidating) ||
    error;

  const transactionTime = timeData?.transaction_time;

  const gameList = useMemo(
    () =>
      privateGameData && Array.isArray(privateGameData) && privateGameData.length > 0
        ? privateGameData
            .map((data) => {
              const timeText = getConvertTimeText(data.started_at, transactionTime);

              return {
                ...data,
                state: cardtype,
                hoverVideoPlay: hoverVideoPlay,
                isPrivateShow: !!timeText,
                remainingText: timeText,
              };
            })
            .slice(0, useSlider ? privateGameData.length : count)
        : [],
    [privateGameData, cardtype, hoverVideoPlay, transactionTime, count, useSlider]
  );

  return isDataNotExist ? null : (
    <GameListMediumCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      cardtype={cardtype}
      name={name}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={false}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      useSlider={useSlider}
      navigationPositionTop={navigationPositionTop}
      isLauncherImage={isLauncherImage}
      onClickLeftArrow={() => {
        gxcTag('click', {
          area: 'private_test',
          ui: 'left_arrow',
        });
      }}
      onClickRightArrow={() => {
        gxcTag('click', {
          area: 'private_test',
          ui: 'right_arrow',
        });
      }}
      onClickCard={(gameId) => {
        gxcTag('click', {
          area: 'private_test',
          ui: 'game_card',
          parameters: {
            game_id: gameId + '',
          },
        });
      }}
    />
  );
}
