import { GIcon } from '@ground/ui';
import { Box, Link, Typography } from '@mui/material';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

import { useLog } from 'utils/userLog';
interface BlogSnsLinkProps {
  iconType: 'youtube' | 'x' | 'facebook';
  text: string;
  linkUrl: string;
}

export default function BlogSnsLink({ iconType, text, linkUrl }: BlogSnsLinkProps) {
  const { gxcTag } = useLog();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Link
      onClick={() => gxcTag('click', { area: 'sidebar', ui: iconType })}
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      <Box
        p="18px 24px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          backgroundColor: isHover ? 'gray.60' : 'gray.80',
        }}
        ref={hoverRef}
      >
        <GIcon
          iconName={iconType}
          iconSize={24}
          iconColor={isHover ? 'base.white' : 'base.dimWhite'}
        />

        <Typography variant="body2" color={isHover ? 'text.primary' : 'text.secondary'}>
          {text}
        </Typography>
      </Box>
    </Link>
  );
}
