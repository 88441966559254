import { Box, Divider, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';

import { GStoreDigitalProductOrderStatusEnum } from 'api/gstore/gstoreAPI';
import { useGStoreOrderDetailDigitalData } from './GStoreOrderDetailDigitalProvider';

const steps = ['Order Placed', 'In Transit', 'Delivered'];

const getActiveStep = (orderStatus?: string) => {
  switch (orderStatus) {
    case GStoreDigitalProductOrderStatusEnum.ORDERED:
      return 0;
    case GStoreDigitalProductOrderStatusEnum.IN_TRANSIT:
      return 1;
    case GStoreDigitalProductOrderStatusEnum.DELIVERED:
      return 2;
    default:
      return -1;
  }
};

// 코드가 개판임.. 물리 상품과 디지털 굿즈 합치면서 새로 만들어야 함
export default function GStoreOrderDetailDigitalStepper() {
  const { isLoading, data } = useGStoreOrderDetailDigitalData();

  const orderStatus =
    data?.data?.status === GStoreDigitalProductOrderStatusEnum.CONFIRMED
      ? GStoreDigitalProductOrderStatusEnum.ORDERED
      : data?.data?.status;

  const activeStep = getActiveStep(orderStatus);

  return !isLoading ? (
    <Stack flexDirection="row" sx={{ alignItems: 'center', justifyContent: 'space-beteen' }}>
      {steps.map((label, index) => (
        <Fragment key={index}>
          <Box sx={{ width: 120 }}>
            <Typography
              variant="h5"
              color={activeStep >= index ? 'text.primary' : 'gray.40'}
              textAlign="center"
              mb="4px"
            >
              {String(index + 1).padStart(2, '0')}
            </Typography>
            <Typography
              variant="subtitle3"
              color={activeStep === index ? 'text.primary' : 'text.secondary2'}
              textAlign="center"
            >
              {label}
            </Typography>
          </Box>
          {index !== steps.length - 1 && (
            <Divider
              sx={{
                backgroundColor: (theme) =>
                  activeStep < index ? theme.palette.opacity.white[5] : theme.palette.base.dimWhite,
                flex: 1,
                margin: 'auto',
                height: '1px',
              }}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  ) : null;
}
