import { Routes, Route } from 'react-router-dom';

import ClipsMain from './ClipsMain';
import AllClipsContainer from './AllClipsContainer';
import ClipPopupProvider from 'views/clips/ClipsPopup/ClipPopupProvider';

export default function Clips() {
  return (
    <Routes>
      <Route
        index
        element={
          <ClipPopupProvider>
            <ClipsMain />
          </ClipPopupProvider>
        }
      />
      <Route
        path="allclip"
        element={
          <ClipPopupProvider>
            <AllClipsContainer />
          </ClipPopupProvider>
        }
      />
    </Routes>
  );
}
