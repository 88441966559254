import { baseUrls } from 'api';

export const paymentQueries = {
  getGPointProducts() {
    return `${baseUrls.pointV2}/point/stripe/products`;
  },
  createCheckoutSession() {
    return `${baseUrls.pointV2}/point/stripe/checkout-session`;
  },
  checkUserAdult() {
    return `${baseUrls.pointV2}/point/users/check-adult`;
  },
  getGPointOrderDetail(trxId: string) {
    return `${baseUrls.pointV2}/point/purchases/${trxId}`;
  },
  createReceiptUrl(pdId: string) {
    return `${baseUrls.pointV2}/point/stripe/receipt?pgId=${pdId}`;
  },
  checkPaymentStatus(sessionId: string) {
    return `${baseUrls.pointV2}/point/purchase/check?sessionId=${sessionId}`;
  },
};
