import { FormInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { useAxios, useDialog, useError, useSign } from 'hooks';
import { emailPattern } from 'utils/common';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { SignLayout } from '../component';

interface LoginEmailFormData {
  email: string;
  password: string;
}

interface LoginEmailFormProps {
  isMobile?: boolean;
}

export default function LoginEmailForm({ isMobile = false }: LoginEmailFormProps) {
  const methods = useForm<LoginEmailFormData>({ mode: 'onChange' });
  const { showDialog } = useDialog();
  const { showError } = useError();
  const { signComplete } = useSign();
  const { isLoading, refetch } = useAxios('sessions', { method: 'POST' }, true);
  const { gxcTag } = useLog();

  const onSubmit = async (formData: LoginEmailFormData) => {
    const response = await refetch({ data: formData });
    if (response?.status === 200 && response?.data?.result_code === 'OK') {
      signComplete(response.data.data);
    } else if (response?.data?.result_code === '401') {
      gxcTag('view', { area: 'invalid_email_popup' });
      showDialog({
        title: 'Error',
        body: "We couldn't find an account with this email address, please try again with a different address.",
        primaryButton: {
          text: 'Back to Sign in',
          callback: () => {
            gxcTag('click', { area: 'invalid_email_popup', ui: 'back_to_signin' });
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'invalid_email_popup', ui: 'close_x' });
        },
      });
    } else if (response?.data?.result_code === '402') {
      gxcTag('view', { area: 'wrong_pw_popup' });
      showDialog({
        title: 'Error',
        body: 'Your password does not match with this email address, please try again.',
        primaryButton: {
          text: 'Back to Sign in',
          callback: () => {
            gxcTag('click', { area: 'wrong_pw_popup', ui: 'back_to_signin' });
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'wrong_pw_popup', ui: 'close_x' });
        },
      });
    } else {
      showError(response, 'Sign in');
    }
  };

  const handleClickForgotPassword = () => {
    eventTracker('login_forgot_password');
    gxcTag('click', { area: 'detail', ui: 'reset_pw' });
  };

  const handleClickSignin = () => {
    gxcTag('click', { area: 'detail', ui: 'signin' });
  };

  return (
    <SignLayout isMobile={isMobile} title="Sign in with Email">
      <Box mb={isMobile ? '20px' : '40px'} textAlign="center">
        {!isMobile && (
          <Typography color="text.primary" variant="h3">
            Sign in with Email
          </Typography>
        )}
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormInput
            label="Email"
            name="email"
            type="email"
            data-cy="login-email"
            placeholder="Enter your email"
            rules={{
              required: 'Please enter a valid email.',
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email.',
              },
            }}
            fullWidth
            size={isMobile ? 'large' : 'medium'}
            withBackground={!isMobile}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            password
            placeholder="Enter your password"
            rules={{ required: 'Please enter a password.' }}
            fullWidth
            size={isMobile ? 'large' : 'medium'}
            sx={{ mt: '20px' }}
            withBackground={!isMobile}
          />

          <Box display="flex" justifyContent="flex-end">
            <Link
              component={RouterLink}
              variant="subtitle2"
              color="primary"
              mt="8px"
              to="/request-reset-password"
              onClick={handleClickForgotPassword}
            >
              Forgot your password?
            </Link>
          </Box>

          <LoadingButton
            color="primary"
            fullWidth
            loading={isLoading}
            size={isMobile ? 'largest' : 'large'}
            sx={{ marginTop: '40px' }}
            type="submit"
            variant="contained"
            onClick={handleClickSignin}
          >
            Sign in
          </LoadingButton>
        </form>
      </FormProvider>
    </SignLayout>
  );
}
