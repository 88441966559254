import { FormInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import queryString from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAxios, useDialog, useError } from 'hooks';
import { passwordPattern } from 'utils/common';
import { useLog } from 'utils/userLog';

type FormData = {
  password: string;
};

export default function ConfirmResetPassword() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { code } = queryString.parse(search || '', { parseNumbers: true });
  const { isLoading, refetch } = useAxios(`sessions/${code}`, { method: 'PATCH' }, true);
  const { showDialog, showSimpleDialog } = useDialog();
  const { showError } = useError();
  const { gxcTag } = useLog();
  const methods = useForm<FormData>({ mode: 'onChange' });
  const { handleSubmit } = methods;

  const onSubmit = async (formData: FormData) => {
    const response = await refetch({ data: formData });
    if (response?.status === 200 && response?.data.result_code === 'OK') {
      gxcTag('view', { area: 'new_pw_success_popup' });
      // 리셋 성공
      showDialog({
        title: 'Reset password',
        body: 'Password reset complete. Try to login.',
        primaryButton: {
          callback: () => {
            gxcTag('click', { area: 'new_pw_success_popup', ui: 'ok' });
          },
        },
        onClose: () => navigate('/login'),
        onClickClose: () => {
          gxcTag('click', { area: 'new_pw_success_popup', ui: 'close_x' });
        },
      });
    } else if (response.data?.result_code === '400') {
      showSimpleDialog('Reset password', 'Not found user.');
    } else if (response.data?.result_code === '452') {
      showSimpleDialog('Reset password', 'Invalid code.');
    } else if (response.data?.result_code === '453') {
      showSimpleDialog('Reset password', 'Expired reset password url.');
    } else if (response.data?.result_code === '455') {
      showSimpleDialog('Reset password', 'Already used reset code.');
    } else {
      gxcTag('view', { area: 'new_pw_failed_popup' });
      showError(response, 'Reset password', {
        primaryButton: {
          callback: () => {
            gxcTag('click', { area: 'new_pw_failed_popup', ui: 'ok' });
          },
        },
        onClickClose: () => {
          gxcTag('click', { area: 'new_pw_failed_popup', ui: 'close_x' });
        },
      });
    }
  };

  const handleClickResetPassword = () => {
    gxcTag('click', { area: 'detail', ui: 'reset_pw' });
  };

  const handleClickSupport = () => {
    gxcTag('click', { area: 'detail', ui: 'support' });
  };

  return (
    <Box
      sx={{
        padding: '40px 28px',
        width: '420px',
        backgroundColor: 'gray.90',
        marginTop: '100px',
        marginBottom: '200px',
        borderRadius: '5px',
      }}
    >
      <Typography variant="h4" mb="8px" textAlign="center">
        Reset Password
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" textAlign="center">
        You are resetting your password
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '40px' }}>
          <FormInput
            label="Password"
            name="password"
            type="password"
            placeholder="Your password"
            password
            rules={{
              required: 'Please enter a password.',
              minLength: { value: 8, message: 'Invalid format' },
              pattern: { value: passwordPattern, message: 'Invalid format' },
            }}
            fullWidth
            withBackground
          />
          <Typography variant="body3" mt="8px" color="text.secondary2">
            8-16 characters / Include at least one lowercase letter, one uppercase letter, and a
            number.
          </Typography>

          <LoadingButton
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            sx={{ marginTop: '40px' }}
            type="submit"
            onClick={handleClickResetPassword}
          >
            Reset Password
          </LoadingButton>
        </form>
      </FormProvider>

      <Typography variant="body3" color="text.secondary2" mt="20px">
        If you are experiencing any issues, please contact us at
        <br />
        <Link
          href="mailto:support@gameround.co"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          onClick={handleClickSupport}
        >
          support@gameround.co
        </Link>{' '}
        so we can assist you.
      </Typography>
    </Box>
  );
}
