import { SelectionProps } from './FormType';

export const defaultOptions: SelectionProps[] = [
  {
    id: 1,
    order: 1,
    label: '1',
  },
  {
    id: 2,
    order: 2,
    label: '2',
  },
  {
    id: 3,
    order: 3,
    label: '3',
  },
  {
    id: 4,
    order: 4,
    label: '4',
  },
  {
    id: 5,
    order: 5,
    label: '5',
  },
];
