import { Typography, styled } from '@mui/material';

import { GameCardTinyProps } from '.';

const GameCardLowerAreaRoot = styled('div')({
  marginTop: 12,
});

export default function GameCardTinyLowerArea({ studio_name, title }: GameCardTinyProps) {
  return (
    <GameCardLowerAreaRoot>
      <Typography variant="button1" color="text.primary" noWrap>
        {title}
      </Typography>
      <Typography variant="body3" color="text.secondary" noWrap sx={{ marginTop: '4px' }}>
        {studio_name}
      </Typography>
    </GameCardLowerAreaRoot>
  );
}
