import { GIcon } from '@ground/ui';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

import {
  useGetGameInfoAPI,
  useGetPublicReviewDashboardAPI,
  useIssueGPPointsToAllAPI,
} from 'api/comment/commentAPI';
import { DialogState, useDialog } from 'hooks';
import { GPopper } from '../../../../components/comment/GPopper';

interface IProps {
  gameId: string;
  getPickedCountCallback?: () => void;
  disabled?: boolean;
  pickedCount?: number;
  additionalCount?: number;
  pickableCount: number;
}

export default function GPDistributionButton({
  gameId,
  getPickedCountCallback,
  disabled,
  pickedCount,
  additionalCount,
  pickableCount = 20,
}: IProps) {
  const { showDialog } = useDialog();
  const { data: reviewData } = useGetPublicReviewDashboardAPI(gameId);
  const { fetch: issueGPoints } = useIssueGPPointsToAllAPI(gameId);
  const { data: gameInfoData } = useGetGameInfoAPI(gameId);
  const pointPerReviewInGame = gameInfoData?.data.find(
    (gameInfo) => gameInfo.category === 'REVIEW' && gameInfo.type === 'SELECTION'
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const needMoreCountWithoutFullyPicked =
    typeof additionalCount === 'number' &&
    additionalCount <= 0 &&
    typeof pickedCount === 'number' &&
    pickedCount < pickableCount;

  const pointIssuance = useCallback(async () => {
    const response = await issueGPoints();

    if (response.status === 200 && response?.data?.result?.code === 200) {
      getPickedCountCallback?.();

      // showDialog({
      //   title: 'G.Points Have Been Issued',
      //   body: (
      //     <>
      //       G.Points have been issued.
      //       <br />
      //       <br />
      //       Selected Reviews: {response.data.data?.target_count}
      //       <br />
      //       Reviews Issued G.Points: {response.data.data?.success_count}
      //       <br />
      //       {pointPerReviewInGame &&
      //         `G.Points Issued: ${
      //           pointPerReviewInGame.point * Number(response.data.data?.success_count)
      //         } G.P`}
      //     </>
      //   ),
      //   primaryButton: { text: 'OK' },
      // });
      // } else if (response?.data?.data?.fail_count > 0) {
    } else {
      showDialog({
        title: 'System Error',
        body: (
          <Typography variant="body2" color="text.secondary">
            There was a technical issue while distributing G.Points. Please contact support if the
            problem persists.
          </Typography>
        ),
        primaryButton: { text: 'OK' },
      });
      // } else {
      // TODO : 백엔드에서 메세지를 넘겨주어야함. 현재 API 값으로는 Zeplin과 같이 구현 불가능

      // 테스트 기간 지남 에러 모달
      // if (response?.data?.result?.code === 201002) {
      //   showDialog({
      //     title: 'Failed to Issue G.Points',
      //     body: (
      //       <>
      //         You can only issue G.Points after the testing period (
      //         {response?.data?.data
      //           ? format(new Date(String(response?.data?.data)), 'MMM, dd, yyyy')
      //           : null}
      //         ).
      //       </>
      //     ),

      //     primaryButton: { text: 'OK' },
      //   });
      // } else if (response?.data?.result?.code === 212103) {
      //   // 포인트 지급 가능한 리뷰 개수를 초과한 경우
      //   showDialog({
      //     title: 'System Error',
      //     body: (
      //       <>
      //         Maximum issuable G.Points amount reached. To request more G.Points or information
      //         regarding G.Points, please contact support.
      //         <br />
      //         <br />
      //         Selected Reviews: {response.data.data?.target_count}
      //         <br />
      //         Reviews Issued G.Points: {response.data.data?.success_count}
      //         <br />
      //         {pointPerReviewInGame &&
      //           `G.Points Issued: ${
      //             pointPerReviewInGame.point * Number(response.data.data?.success_count)
      //           }G.P`}
      //       </>
      //     ),
      //     primaryButton: { text: 'OK' },
      //   });
      // } else if (response?.data?.result?.code === 212105) {
      //   // 선택한 리뷰가 없는 경우
      //   showDialog({
      //     title: 'Failed to Issue G.Points',
      //     body: 'No reviews have been selected to award G.Points. Please select a review first in order to issue G.Points',
      //     secondaryButton: { text: 'Close' },
      //   });
      // } else if (response?.data?.result?.code === 999013) {
      //   // 권한이 없는 경우
      //   showDialog({
      //     title: 'Account Function Access Error',
      //     body: response.data.result?.msg,
      //     secondaryButton: { text: 'Close' },
      //   });
      // } else {
      // showDialog({
      //   title: 'Failed to Issue G.Points',
      //   body:
      //     response?.data?.result?.msg || response?.data?.error?.message
      //       ? response?.data?.result?.msg || response?.data?.error?.message
      //       : Array.isArray(response?.data?.data?.results)
      //       ? response.data.data.results.map((result) => (
      //           <p>
      //             Review ID [ {result?.id} ] : {result?.result?.msg}
      //           </p>
      //         ))
      //       : 'Something went wrong',
      //   secondaryButton: { text: 'Close' },
      // });
      // }
    }
  }, [getPickedCountCallback, issueGPoints, showDialog]);

  const purchaseDialog = (): Omit<DialogState, 'open'> => ({
    title: 'Confirm Distribution of G.Points',
    width: '400px',
    body: (
      <>
        {needMoreCountWithoutFullyPicked ? (
          <Typography variant="body1" color="error.main">
            The number of reviews currently selected is less than the maximum allowed. Are you sure
            you want to proceed with distributing points to only these selected reviews?
          </Typography>
        ) : (
          <Typography variant="body1" color="text.primary">
            Do you want to distribute G.Points to the selected reviews?
          </Typography>
        )}
        {needMoreCountWithoutFullyPicked ? (
          <Typography mt="12px" variant="body2" color="text.secondary2">
            You have to be careful because you can only pay points once.
            <br /> And issued G.Points cannot be canceled after confirmation. <br />
            Please press the 'Confirm' button to complete the final G.P distribution.
          </Typography>
        ) : (
          <Typography mt="12px" variant="body2" color="text.secondary2">
            Please note that G.Points can only be awarded once and cannot be canceled after
            confirmation. Press the 'Confirm' button to finalize the distribution of G.Points.
          </Typography>
        )}
        <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Typography variant="body2" color="text.primary">
            Picked Reviews
          </Typography>
          <Typography variant="subtitle1" style={{ color: 'info.main', fontWeight: 700 }}>
            {typeof pickedCount === 'number' && `${pickedCount}`}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} mt="8px">
          <Typography variant="body2" color="text.primary">
            Total G.Points distributed
          </Typography>
          <Typography variant="subtitle1" style={{ color: 'info.main', fontWeight: 700 }}>
            {pointPerReviewInGame &&
              typeof pickedCount === 'number' &&
              `${pointPerReviewInGame.point * pickedCount} G.P`}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: '20px' }} />
      </>
    ),
    primaryButton: {
      text: 'Confirm',
      callback: () => {
        window.setTimeout(pointIssuance, 200);
      },
    },
    secondaryButton: { text: 'Cancel' },
  });

  // 버튼 클릭 시 확인 창 띄우고 OK 시 포인트 지급
  const onClickHandler = async () => {
    if (typeof pickedCount === 'number' && pickedCount === 0) {
      showDialog({
        title: 'Error',
        body: 'No reviews have been selected to award G.Points. Please select a review before attempting to distribute G.Points.',
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    if (typeof pickedCount === 'number' && pickedCount > pickableCount) {
      showDialog({
        title: 'Error',
        body: (
          <>
            <Typography variant="body1" color="text.primary">
              You have exceeded the maximum limit of selected reviews.
            </Typography>
            <Typography variant="body2" color="text.secondary2" mt="12px">
              If you wish to select this review, please deselect one of the currently chosen reviews
              and try again.
            </Typography>
          </>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    const isAdditionalReviewPurchasable =
      reviewData?.data?.additional_amount && reviewData?.data?.additional_amount > 0;

    isAdditionalReviewPurchasable
      ? showDialog({
          width: '400px',
          title: 'Confirm Distribution of G.Points',
          body: (
            <>
              <Typography variant="body1" color="text.primary">
                Do you want to distribute G.Points to the
                <br /> Picked Reviews?
              </Typography>
              <Typography mt="12px" variant="body2" color="text.secondary2">
                Do you want to distribute G.Points to the selected reviews? There are still reviews
                that you haven't reviewed yet!
                <br />
                Are you sure you want to finalize the 'Review Selection' without examining the
                additional reviews? To feature more meaningful and high-quality reviews on your
                game's review page, we recommend purchasing additional reviews to refine your final
                selection.
              </Typography>
              <Divider sx={{ marginTop: '20px', marginBottom: '18px' }} />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <GIcon
                  iconName="exclamation"
                  iconSize={16}
                  iconColor="gray.100"
                  size={24}
                  variant="circle"
                  backgroundColor="base.dimWhite"
                />

                <Typography variant="body2" color="text.primary">
                  Additional reviews available for purchase
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'info.main' }}>
                  {Math.abs(reviewData?.data?.additional_amount ?? 0)}
                </Typography>
              </Box>

              <Divider sx={{ marginTop: '18px' }} />

              <Divider />
            </>
          ),
          secondaryButton: { text: 'Cancel' },
          primaryButton: {
            text: 'Proceed',
            callback: () => {
              setTimeout(() => {
                showDialog(purchaseDialog());
              }, 300);
            },
          },
        })
      : showDialog(purchaseDialog());
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <GPopper direction="right" open={open} anchorEl={anchorEl}>
        <Typography variant="button2" fontWeight={500}>
          Please distribute the G.Points
          <br />
          to the <span style={{ color: theme.palette.info.main }}>Selected Reviews!</span>
        </Typography>
      </GPopper>
      <Button
        onClick={onClickHandler}
        variant="contained"
        size="tiny"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disabled={disabled}
      >
        G.P Distribution
      </Button>
    </>
  );
}
