import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import AboutGamerBecomeList from './component/AboutGamerBecomeList';

import testIcon from 'images/about/aboutGamer/about_ico1.svg';
import reviewIcon from 'images/about/aboutGamer/about_ico2.svg';
import globalIcon from 'images/about/aboutGamer/about_ico3.svg';
import takeIcon from 'images/about/aboutGamer/about_ico4.svg';

const PREFIX = 'ArtAboutGamerBecomeCardsList';

const classes = {
  cardsWrap: `${PREFIX}-cardsWrap`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 1080,
  paddingTop: 120,
  paddingBottom: 120,

  [`& .${classes.cardsWrap}`]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: 32,
    rowGap: 60,
  },
}));

const cardInfoList = [
  {
    id: 1,
    icon: testIcon,
    title: 'Help Developers with Upcoming Games',
    desc: (
      <>
        Playtest and contribute to the next big indie game and <br /> discuss titles with
        developers.
      </>
    ),
  },
  {
    id: 2,
    icon: reviewIcon,
    title: 'Get Rewards',
    desc: 'Earn G.Points by filling in reviews and surveys after playing the game to get exclusive gaming goods!',
  },
  {
    id: 3,
    icon: globalIcon,
    title: 'Valuable Networking',
    desc: (
      <>
        Network within our global community of aspiring game
        <br />
        designers, players and indie game developers.
      </>
    ),
  },
  {
    id: 4,
    icon: takeIcon,
    title: 'Gain Valuable Experience',
    desc: 'Join the game production industry, network with game devs, and contribute to the success of new titles.',
  },
];

export default function ArtAboutGamerBecomeAGamerList() {
  return (
    <Root>
      <Box width="960px">
        <Typography textAlign="center" variant="h4">
          Help Support Indie Game Developers
        </Typography>

        <Typography
          textAlign="center"
          variant="body1"
          marginTop="28px"
          marginBottom="60px"
          sx={{ opacity: 0.6 }}
        >
          Game developers around the world need gamers like you to provide them with critical
          feedback in order to
          <br />
          make their dreams a reality. G.Round is a platform that connects gamers with developers
          and
          <br />
          rewards the players for providing feedback on early builds of games.
        </Typography>
        <div className={classes.cardsWrap}>
          {cardInfoList.map((item) => (
            <AboutGamerBecomeList key={item.id} {...item} />
          ))}
        </div>
      </Box>
    </Root>
  );
}
