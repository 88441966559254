import { numberWithCommas } from '@ground/tools';
import { LoadingWrapper } from '@ground/ui';
import { Box, Stack, Typography, styled } from '@mui/material';

import { GPointProductType, useGPointProductsAPI } from 'api/payment/paymentAPI';

// const bonusPoints = [0, 15, 50, 150, 400];

const Row = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  height: '60px',
  borderRadius: '3px',
  backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.gray[60],
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 30px',

  '&:hover': !isSelected ? { backgroundColor: theme.palette.gray[50] } : undefined,
}));

interface GPointsTableProps {
  onItemClick: (item: GPointProductType) => void;
  selectedProduct?: GPointProductType;
  isAdultLoading?: boolean;
}

export const GPointsTable = ({
  onItemClick,
  selectedProduct,
  isAdultLoading,
}: GPointsTableProps) => {
  const { data, isValidating } = useGPointProductsAPI();

  const products = data?.data || [];

  return (
    <LoadingWrapper isLoading={isValidating || Boolean(isAdultLoading)} sx={{ height: '376px' }}>
      <Stack my="20px" gap="4px">
        {products
          .sort((a, b) => a.cost - b.cost)
          .map((data, index) => {
            const isSelected = selectedProduct?.price_id === data?.price_id;

            return (
              <Row key={data?.price_id} isSelected={isSelected} onClick={() => onItemClick(data)}>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="subtitle1"
                    color={isSelected ? 'white' : 'text.primary'}
                    mr="10px"
                  >
                    {numberWithCommas(data?.ground_point)} G.P
                  </Typography>
                  <Typography variant="subtitle2" color={isSelected ? 'white' : 'text.secondary2'}>
                    ({data?.cost.toFixed(2)} USD)
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    display: 'flex',
                    width: '134px',
                    justifyContent: data?.ground_point !== 100 ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {data?.ground_point !== 100 && (
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="Vector"
                          d="M6 0C2.69 0 0 2.69 0 6C0 9.31 2.69 12 6 12C9.31 12 12 9.31 12 6C12 2.69 9.31 0 6 0ZM9 7H7V9H5V7H3V5H5V3H7V5H9V7Z"
                          fill={isSelected ? theme.palette.base.white : theme.palette.info.main}
                        />
                      </svg>
                      <Typography
                        variant="button1"
                        sx={{
                          color: isSelected ? theme.palette.base.white : theme.palette.info.main,
                        }}
                      >
                        {bonusPoints[index]} G.P
                      </Typography>
                    </Box>
                  )}
                  <GIcon iconName="check" iconSize={24} disabled={!isSelected} />
                </Box> */}
              </Row>
            );
          })}
      </Stack>
    </LoadingWrapper>
  );
};
