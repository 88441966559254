import { Components } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

const DataGridTheme: Components = {
  MuiDataGrid: {
    styleOverrides: {
      root: {},
    },
  },
};

export default DataGridTheme;
