import { GIconButton, type FormAutocompleteOption } from '@ground/ui';
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useGetProfileAPI, type ProfileFormData } from 'api/profile/profileAPI';
import ProfileForm from './ProfileForm';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100dvh',
  padding: '15px 20px',
  backgroundColor: theme.palette.gray[80],
}));

const Header = styled('div')({
  marginBottom: 35,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Loading = () => (
  <Box
    sx={{
      width: '100%',
      height: '100dvh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress color="primary" size={32} />
  </Box>
);

export default function ProfileMain() {
  const { isLoading, data, mutate } = useGetProfileAPI();
  const { isLoading: loadingCountries, data: countriesData } = useGetCountriesAPI();
  const navigate = useNavigate();

  const profileData = data?.data;
  const countries = countriesData ?? [];

  const countryList: FormAutocompleteOption[] = countries.map((country) => ({
    name: country.name,
    value: country.code,
  }));

  const methods = useForm<Omit<ProfileFormData, 'subscribe_email'>>({
    mode: 'onChange',
    defaultValues: {
      name: profileData?.name ?? '',
      birthday:
        profileData?.birth_year && profileData?.birth_month && profileData?.birth_day
          ? new Date(
              `${profileData.birth_year}-${profileData.birth_month}-${profileData.birth_day}`
            )
          : null,
      country: countryList.find((country) => country.value === profileData?.country_code) || null,
      sex: profileData?.sex ?? undefined,
    },
  });

  const [usedTrigger, setUsedTrigger] = useState(false);

  // 초기 값 에러 표시를 위해 mount 시 강제로 validation trigger 발동 시킴
  useEffect(() => {
    if (!usedTrigger) {
      setUsedTrigger(true);
      methods.trigger();
    }
  }, [methods, usedTrigger]);

  // loading
  if (loadingCountries || isLoading) {
    return <Loading />;
  }

  // data 오류
  if (!profileData || !countriesData) {
    return <Loading />;
  }

  return (
    <Root>
      <Header>
        <Typography variant="h4">Profile</Typography>
        <GIconButton iconName="close" size={36} iconSize={24} onClick={() => navigate('/')} />
      </Header>
      <ProfileForm profileData={profileData} countriesData={countriesData} reload={mutate} />
    </Root>
  );
}
