import { numberWithCommas } from '@ground/tools';
import { GIcon } from '@ground/ui';
import { DevTool } from '@hookform/devtools';
import { Box, Link, Typography, styled } from '@mui/material';
import { isNumber } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useOrderDigitalGoodsAPI } from 'api/gstore/digitalGoodsAPI';
import { OOrderTab } from 'api/order';
import { useDialog, useFetchResult } from 'hooks';
import { IGStoreDigitalOrderFormData } from 'types/GStoreTypes';
import eventTracker from 'utils/eventTracker';
import { useGStoreDetailDigitalData } from 'views/gstore/detail/digital/GStoreDetailDigitalProvider';
import OrderPayInfoDigital from './OrderPayInfoDigital';
import OrderShippingInfoDigital from './OrderShippingInfoDigital';

// TODO: sx로 바꿀 것. 복붙 금지
const PREFIX = 'OrderDigital';

const classes = {
  container: `${PREFIX}-container`,
  productInfoBox: `${PREFIX}-productInfoBox`,
  productInfoBoxImage: `${PREFIX}-productInfoBoxImage`,
  productInfoBoxContent: `${PREFIX}-productInfoBoxContent`,
  shippingInfoContainer: `${PREFIX}-shippingInfoContainer`,
};

const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginTop: 100,
    marginBottom: 200,
    width: 1200,
  },
  [`& .${classes.productInfoBox}`]: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[90],
    padding: 20,
    marginTop: 20,
    borderRadius: 5,
  },
  [`& .${classes.productInfoBoxImage}`]: {
    width: 150,
    height: 120,
    borderRadius: 5,
    marginRight: 40,
  },
  [`& .${classes.productInfoBoxContent}`]: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },

  [`& .${classes.shippingInfoContainer}`]: {
    marginTop: 60,
    display: 'flex',
    width: '100%',
    gap: 40,
  },
}));

interface Props {
  defaultValues: IGStoreDigitalOrderFormData;
}

export default function OrderDigital({ defaultValues }: Props) {
  const methods = useForm<IGStoreDigitalOrderFormData>({ defaultValues, shouldUnregister: true });
  const { showDialog, setImmerState } = useDialog();
  const { showResult } = useFetchResult();
  const navigate = useNavigate();

  const { gstoreId = '' } = useParams<{ gstoreId: string }>();
  const { selectedOption, data, setShippingInfoOpen } = useGStoreDetailDigitalData();

  const { fetch: fetchPlaceOrder, isLoading: isPlaceOrderLoading } = useOrderDigitalGoodsAPI();

  const onDigitalProductClickHandler = useCallback(async () => {
    if (!selectedOption) return;

    try {
      const response = await fetchPlaceOrder({
        productId: Number(gstoreId),
        productDigiOptId: selectedOption.id,
        qty: 1,
        price: selectedOption.price,
      });

      showResult(response, {
        onSuccess: (data) => {
          eventTracker('gstore_order_placeorder', {
            event_label: data.data.order_id ?? '',
          });
          navigate('/store/success', { state: OOrderTab.DIGITAL_PRODUCTS });
        },
        onError: (data) => {
          if (data.error?.code === 'S011') {
            setTimeout(() => {
              showDialog({
                title: (
                  <>
                    Product Information <br /> Updated
                  </>
                ),
                body: (
                  <Typography variant="body2" color="text.secondary">
                    Please check the updated information <br />
                    before proceeding. You'll be taken back to the
                    <br /> product details page.
                  </Typography>
                ),
                primaryButton: {
                  text: 'Confirm',
                },
                onClose: () => {
                  setShippingInfoOpen?.(false);
                },
              });
            }, 100);
            return false;
          } else {
            navigate('/store/failed', { state: OOrderTab.DIGITAL_PRODUCTS });
          }
        },
      });
    } catch (error) {
      navigate('/store/failed', { state: OOrderTab.DIGITAL_PRODUCTS });
    }
  }, [
    fetchPlaceOrder,
    gstoreId,
    navigate,
    selectedOption,
    setShippingInfoOpen,
    showDialog,
    showResult,
  ]);

  const onSubmit = async () => {
    eventTracker('gstore_order_placeorder', {
      event_label: data?.data?.id?? '',
    });

    showDialog({
      title: 'Order Confirmation',
      width: 500,
      body: (
        <Box sx={{ fontSize: '14px', lineHeight: '20px' }}>
          <Typography color="text.secondary">
            Please note that{' '}
            <Typography color="text.primary" component="span">
              {selectedOption?.item}
            </Typography>{' '}
            of{' '}
            <Typography color="text.primary" component="span">
              {selectedOption?.amount.split('(')[0]}
            </Typography>{' '}
            available on the G.Point Store is region locked and will not be redeemable anywhere else
            other than the specified region. <br />
            <br />
            All{' '}
            <Typography color="text.primary" component="span">
              {selectedOption?.title}
            </Typography>{' '}
            redemptions are final, they are non-refundable and cannot be exchanged. <br />
            <br />
            Please take a moment to visit{' '}
            <Link
              href="https://www.notion.so/gameround/G-Points-Wiki-4bedd215f2224ee7aa88f8432d3b9f1d?pvs=4"
              color="text.primary"
              target="_blank"
            >
              G.Points Wiki
            </Link>{' '}
            to check region and product specific requirements.
          </Typography>
          <Box mt="40px" sx={{ display: 'flex', alignItems: 'center' }}>
            <GIcon
              iconName="exclamation"
              iconSize={16}
              size={32}
              backgroundColor="gray.100"
              variant="circle"
            />
            <Typography variant="body3" ml="12px" color="text.primary">
              Usually arrives within an hour.
              <br /> After processing, you can find the item in My Page &gt; Order Detail
            </Typography>
          </Box>
        </Box>
      ),
      primaryButton: {
        text: 'Place Order',
        callback: onDigitalProductClickHandler,
        isLoadingButton: true,
      },
      secondaryButton: { text: 'Cancel' },
    });
  };

  useEffect(() => {
    if (isPlaceOrderLoading) {
      setImmerState((draft) => {
        draft.primaryButton = {
          text: 'Processing',
          callback: onDigitalProductClickHandler,
          isLoadingButton: true,
        };
        draft.secondaryButton = {
          text: 'Cancel',
          ButtonProps: {
            disabled: true,
          },
        };
        draft.hasNoCloseIcon = true;
      });
    } else {
      setImmerState((draft) => {
        draft.primaryButton = {
          text: 'Place Order',
          callback: onDigitalProductClickHandler,
          isLoadingButton: true,
        };
        draft.secondaryButton = {
          text: 'Cancel',
          ButtonProps: {
            disabled: false,
          },
        };
        draft.hasNoCloseIcon = true;
      });
    }
  }, [isPlaceOrderLoading, onDigitalProductClickHandler, setImmerState]);

  return (
    <Root className={classes.container}>
      <Typography variant="h5">Order/Payment</Typography>
      <Box className={classes.productInfoBox}>
        <img
          src={data?.data?.main_media_url}
          alt="product"
          className={classes.productInfoBoxImage}
        />
        <div className={classes.productInfoBoxContent}>
          <Typography variant="h6" color="text.primary">
            {data?.data?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="8px">
            Item : {selectedOption?.item}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="4px">
            Amount : {selectedOption?.amount}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="4px">
            Quantity : 1
          </Typography>
          <Typography variant="body2" color="text.secondary" mt="4px">
            Price :{' '}
            {isNumber(selectedOption?.price)
              ? numberWithCommas(Number(selectedOption?.price)) + ' G.P'
              : ''}
          </Typography>
        </div>
      </Box>
      <FormProvider {...methods}>
        <DevTool control={methods.control} placement="top-right" />
        <form
          className={classes.shippingInfoContainer}
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <OrderShippingInfoDigital />
          <OrderPayInfoDigital loading={isPlaceOrderLoading} />
        </form>
      </FormProvider>
    </Root>
  );
}
