import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { alpha, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useAxiosWithAuth, useError, useDialog } from 'hooks';
import eventTracker from 'utils/eventTracker';
import FacebookIcon from 'images/profile/facebook_icon.svg';

const PREFIX = 'AccountInfoFacebookConnect';

const classes = {
  facebookBtn: `${PREFIX}-facebookBtn`,
  disconnect: `${PREFIX}-disconnect`,
};

const StyledLoadingButton = styled(LoadingButton)(() => ({
  [`&.${classes.facebookBtn}`]: {
    maxWidth: 195,
    height: 40,
    background: '#3B5998',
    '&:hover': {
      backgroundColor: alpha('#3B5998', 0.6),
    },
  },

  [`& .${classes.disconnect}`]: {
    marginLeft: 16,
  },
}));

interface FacebookConnectProps {
  connected: boolean;
  reload: () => void;
}

export default function AccountInfoFacebookConnect({ connected, reload }: FacebookConnectProps) {
  const [fbLoading, setFbLoading] = useState(false);
  const { isLoading, refetch: fbConnent } = useAxiosWithAuth(
    'users/facebook',
    { method: connected ? 'DELETE' : 'PATCH' },
    true
  );

  const { showSimpleDialog, showDialog } = useDialog();
  const { showError } = useError();

  // const facebookDisconnect = async () => {
  //   const { data, status } = await fbConnent();

  //   if (status === 200 && data?.result_code === 'OK') {
  //     // 연결해제 성공
  //     reload();
  //   } else {
  //     showDialog({ title: 'Facebook Disconnect', body: 'Error occurred in facebook disconnect.' });
  //   }
  // };

  const facebookLogin = async (authResponse: fb.AuthResponse, meResponse: any) => {
    // 페이스북 연동시도
    const response = await fbConnent({
      data: {
        access_token: authResponse.accessToken,
        account: authResponse.userID,
        name: meResponse.name,
        email: meResponse.email,
        picture: meResponse.picture?.data?.url,
        agree: true,
      },
    });

    if (response.status === 200 && response.data?.result_code === 'OK') {
      // 페이스북 연결 성공
      reload();
    } else if (response.data?.result_code === '400') {
      showSimpleDialog('Facebook Connect', 'Email address does not match.');
    } else if (response.data?.result_code === '401') {
      showSimpleDialog('Facebook Connect', 'This Facebook account is already connected.');
    } else {
      showError(response, 'Facebook Connect');
    }
  };

  const handleFacebookLogin = async () => {
    setFbLoading(true);

    FB.login(
      (response: fb.StatusResponse) => {
        const { authResponse, status } = response;

        if (status === 'connected') {
          // get user info
          FB.api('/me', { fields: ['id', 'email', 'name', 'picture'] }, (meResponse: any) => {
            // 이메일 정보가 있는 경우
            if (authResponse && meResponse) {
              if (meResponse?.email) {
                facebookLogin(authResponse, meResponse);
              } else {
                showDialog({
                  title: 'Facebook Sign in',
                  body: 'Ready up gamers! We need your email permission for Facebook sign up or you can go with regular email.',
                });
              }
            } else {
              showDialog({
                title: 'Facebook Sign in',
                body: 'Facebook Sign in data could not be obtained.',
              });
            }
          });
        } else if (status === 'not_authorized') {
          // 로그인 거부이니 아무 동작도 하지 않는다.
          console.log('User cancelled sign in or did not fully authorize.');
        } else {
          // 창을 닫았거나 기타 사유 이므로 아무 것도 표시하지 않는다.
          //  showDialog({ title: 'Facebook Sign in', body: 'Sign in failed by unknown reason.' });
        }
        setFbLoading(false);
      },
      { scope: 'email', return_scopes: true, auth_type: 'rerequest' }
    );
    eventTracker('profile_profile_accountinfo_connectfacebook');
  };

  return (
    <StyledLoadingButton
      className={classes.facebookBtn}
      size="large"
      fullWidth
      variant="contained"
      color="primary"
      startIcon={<img src={FacebookIcon} alt="facebook" />}
      onClick={connected ? undefined : handleFacebookLogin}
      loading={fbLoading || isLoading}
      // disconnect 상태를 숨기기 위해 임시 추가
      style={{ cursor: connected ? 'initial' : 'pointer' }}
      disableFocusRipple={connected}
      disableRipple={connected}
      disableElevation={connected}
    >
      {connected ? (
        <Typography variant="button2">
          Connected To Facebook
          {/* <Typography className={classes.disconnect} color="textSecondary">
            Disconnect
          </Typography> */}
        </Typography>
      ) : (
        <Typography variant="button2">Connect With Facebook</Typography>
      )}
    </StyledLoadingButton>
  );
}
