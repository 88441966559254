import { styled, Tab } from '@mui/material';

export interface NewGTabProps {
  color?: string;
}

const GTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'color',
})<NewGTabProps>(({ theme, color = theme.palette.text.primary }) => ({
  fontSize: 'inherit',
  lineHeight: 'inherit',
  minWidth: 'auto',
  minHeight: 'auto',
  padding: 0,
  paddingBottom: '8px',
  color: theme.palette.text.secondary2,
  '&.Mui-selected': {
    color: color,
  },
}));

export default GTab;
