import { FormInput } from '@ground/ui';
import { useFormState } from 'react-hook-form';

import { emailPattern } from 'utils/common';
import { useSignupData } from './SignupProvider';

export default function SignupEmail() {
  const { dirtyFields, errors } = useFormState();
  const { isMobile } = useSignupData();

  return (
    <FormInput
      label="Email"
      name="email"
      type="email"
      data-cy="signup-email"
      placeholder="Enter your Email"
      success={dirtyFields['email'] && !errors['email']}
      rules={{
        required: 'Please enter a valid email.',
        pattern: {
          value: emailPattern,
          message: 'Please enter a valid email.',
        },
      }}
      fullWidth
      size={isMobile ? 'large' : 'medium'}
      withBackground={!isMobile}
    />
  );
}
