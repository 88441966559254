import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import * as React from 'react';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import { LexicalEditor } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEditorAppContext } from './context/EditorAppContext';

// import './index.css';

interface Props {
  editorRef?: React.MutableRefObject<LexicalEditor | null>;
  // placeholder?:string;
}

export default function Viewer({ editorRef }: Props): JSX.Element {
  const { innerWidth } = useEditorAppContext();
  const placeholder = <Placeholder>Enter some text...</Placeholder>;
  const [editor] = useLexicalComposerContext();
  if (editorRef) editorRef.current = editor;

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
    }
  };

  return (
    <>
      <div
        className={`editor-container plain-text`}
        style={{ width: innerWidth, background: '#121212' }}
      >
        <RichTextPlugin
          contentEditable={
            <div className="editor" ref={onRef}>
              <ContentEditable placeholder={'Enter some text...'} />
            </div>
          }
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
    </>
  );
}
