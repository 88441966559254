import { FormInput } from '@ground/ui';
import { Typography } from '@mui/material';

export default function OrderShippingInfoForm() {
  return (
    <div>
      <FormInput
        name="ship_to_email"
        type="email"
        label="Email*"
        placeholder="Your Email"
        fullWidth
        withBackground
        readOnly
      />
      <Typography variant="body3" color="text.secondary2" mt="20px">
        Order details will be sent to the email address connected to your G.Round account.
      </Typography>
    </div>
  );
}
