import { numberWithCommas } from '@ground/tools';
import { Box, Stack, Typography } from '@mui/material';

export interface GpointsListCardProps {
  title: string;
  point?: number;
  isTBA?: boolean;
  description?: string;
}

export default function GpointsListCard({
  title,
  point = 0,
  isTBA,
  description,
}: GpointsListCardProps) {
  return (
    <Box width={94}>
      <Typography variant="body3">{title}</Typography>

      <Stack direction="row" marginTop="21px">
        {isTBA ? (
          <Typography variant="h4">TBA</Typography>
        ) : (
          <>
            <Typography variant="h4">{numberWithCommas(point)}</Typography>
            <Typography variant="h5" marginLeft="4px">
              G.P
            </Typography>
          </>
        )}
      </Stack>
      {!isTBA && (
        <Typography variant="body3" color="text.secondary" marginTop="4px">
          {description}
        </Typography>
      )}
    </Box>
  );
}
