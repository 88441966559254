import useSWRFetch from 'api/useSWRFetch';
import { SiteStateNowDataProps } from 'types/ConstructionType';

export function useConstructionAPI() {
  const url = `https://api-state.gameround.co/api/site-state/now`;

  return useSWRFetch<SiteStateNowDataProps>({
    key: url,
    swrConfig: {
      refreshInterval: 30000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  });
}
