import { useState } from 'react';
import { styled, Typography, Box } from '@mui/material';

import { UserAvatar } from 'atoms';
import VideoClipMedia from './VideoClipMedia';

import circleIcon from 'images/icons/grey-circle.svg';

const Root = styled('div')(() => ({
  borderRadius: 5,
  width: 384,
}));

export interface ClipCardSmallProps {
  userName: string;
  country: string;
  videoTitle?: string;
  srcUrl: string;
  streamerUrl?: string;
  onCardClickHandler: () => void;
  to?: string;
  duration?: number;
}

const ClipCardSmall: React.FC<ClipCardSmallProps> = ({
  userName,
  country,
  videoTitle,
  srcUrl,
  streamerUrl,
  onCardClickHandler,
  to,
  duration,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <Root>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={onCardClickHandler}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <VideoClipMedia
          hover={isHover}
          imageUrl={srcUrl || ''}
          playingTime={duration || 0}
          width={384}
          height={216}
          iconSize="SMALL"
        />
      </Box>

      <Box display="flex" alignItems="center" mt="20px" columnGap="8px">
        <UserAvatar width={28} src={streamerUrl ?? ''} alt={userName} />
        <Box display="flex" alignItems="center" columnGap="4px" overflow="hidden">
          <Typography variant="small1" noWrap>
            {userName}
          </Typography>
          <img src={circleIcon} alt="circle" />
          <Typography variant="small1" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
            {country}
          </Typography>
        </Box>
      </Box>
      <Typography mt="8px" variant="subtitle3" color="text.secondary" noWrap>
        {videoTitle}
      </Typography>
    </Root>
  );
};

export default ClipCardSmall;
