import { FormInput } from '@ground/ui';
import { useFormContext } from 'react-hook-form';

import { useCheckDuplicateNameAPI } from 'api/profile/profileAPI';

interface Props {
  isMobile?: boolean;
  defaultValue?: string;
}

export default function SignupUsername({ isMobile, defaultValue }: Props) {
  const { fetch: checkDuplicateName } = useCheckDuplicateNameAPI();
  const { getValues, setError } = useFormContext();

  const handleBlur = async () => {
    const name = getValues('name');
    const username = (typeof name === 'string' ? name : '').trim();
    // 이름이 기본 값이랑 같으면 중복체크 안함
    if (username === defaultValue) return;
    if (username.length < 4) return;

    // 입력한 이름의 사용이 가능한지 중복체크
    const response = await checkDuplicateName({ name: username });
    // 성공이면 중복. 에러면 겹치지 않는 것
    if (response.status === 200 && response.data.result_code === 'OK') {
      setError('name', { type: 'validate', message: 'This username is already taken.' });
    }
  };

  return (
    <FormInput
      label="Username"
      name="name"
      placeholder="Enter username (4-30 characters)"
      rules={{
        validate: {
          minLength: (v) => v?.trim().length > 3 || 'Please enter min 4 characters',
          maxLength: (v) => v?.trim().length < 31 || 'Please enter max 30 characters',
          required: (v) => !!v?.trim() || 'Please enter a username',
        },
      }}
      size={isMobile ? 'large' : 'medium'}
      withBackground
      // 포커스 벗어날 때 중복 체크
      onBlur={handleBlur}
    />
  );
}
