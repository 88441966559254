import { EventBadgeNew, VideoBox } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import { ImageBox } from 'atoms';
import { Dispatch, iRootState } from 'store';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';
import GameCardPrivateUpperArea from '../GameCardPrivateUpperArea';

type CardImgRootProps = {
  isLauncherImage?: boolean;
};

const CardImgRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isLauncherImage',
})<CardImgRootProps>(({ isLauncherImage }) => ({
  position: 'relative',
  width: 285,
  height: 323,
  borderRadius: '5px 5px 0px 0px',
  overflow: isLauncherImage ? 'hidden' : undefined,
}));

const StartDateWrap = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: 16,
  left: 16,
  width: 30,
  height: 40,
});

const DateDivider = styled('div')(({ theme }) => ({
  width: 30,
  marginTop: 4,
  marginBottom: 4,
  borderTop: `1px solid ${theme.palette.base.dimWhite}`,
}));

const EventTagWrap = styled('div')({
  position: 'absolute',
  left: 12,
  top: -2,
  display: 'flex',
  height: '52px',
});

export default function GameCardMediumUpperArea({
  state = GameStatusType.TESTING,
  started_at,
  thumbnail_url,
  thumbnail_launcher_url,
  thumbnail_video_url,
  hoverVideoPlay,
  event_badge,
  survey_opened,
  isHover = false,
  isLauncherImage,
  isPrivateShow,
  remainingText,
}: GameCardProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const { mute } = useDispatch<Dispatch>();
  const muted = useSelector((state: iRootState) => state.mute);
  const imageUrl = isLauncherImage ? thumbnail_launcher_url : thumbnail_url;

  const handleClickMute = () => {
    mute.toggleMute();
  };

  return (
    <CardImgRoot ref={ref} isLauncherImage={isLauncherImage}>
      {/* private 게임일떄   */}
      {isPrivateShow ? <GameCardPrivateUpperArea remainingText={remainingText} /> : null}
      {inView && imageUrl && (
        <Box sx={{ overflow: 'hidden', borderRadius: '5px 5px 0px 0px' }}>
          {isLauncherImage ? (
            <ImageBox
              src={imageUrl}
              alt="thumbnail"
              resize
              resizeWidth={285}
              resizeHeight={380}
              height={323}
              sx={{
                transition: 'filter .3s, all 0.2s ease-in ',
                filter: isHover && !isPrivateShow ? 'brightness(1.4)' : '',
                transform: isHover && !isPrivateShow ? 'scale(1.1)' : '',
                borderRadius: '5px 5px 0px 0px',
                objectFit: 'none',
                objectPosition: 'center bottom',
              }}
            />
          ) : (
            <ImageBox
              src={imageUrl}
              alt="thumbnail"
              resize
              resizeWidth={285}
              resizeHeight={323}
              sx={{
                transition: 'filter .3s, all 0.2s ease-in ',
                filter: isHover ? 'brightness(1.4)' : '',
                transform: isHover ? 'scale(1.1)' : '',
                borderRadius: '5px 5px 0px 0px',
              }}
            />
          )}
        </Box>
      )}

      {(state === GameStatusType.TESTING || state === GameStatusType.SURVEY) &&
        event_badge &&
        (hoverVideoPlay && thumbnail_video_url ? !isHover : true) && (
          <EventTagWrap>
            <EventBadgeNew {...event_badge} />
          </EventTagWrap>
        )}
      {/* {(state === GameStatusType.SURVEY || survey_opened) && (
        <SurveyChip>
          <Typography display="block" color="white" variant="button3">
            Survey
          </Typography>
        </SurveyChip>
      )} */}
      {state === GameStatusType.UPCOMING && (
        <StartDateWrap>
          <Typography variant="small1" color="text.primary">
            {format(new Date(started_at), 'MMM')}
          </Typography>
          <DateDivider />
          <Typography variant="small1" color="text.primary">
            {format(new Date(started_at), 'd')}
          </Typography>
        </StartDateWrap>
      )}

      {!isPrivateShow && hoverVideoPlay && thumbnail_video_url && (
        <Box position="absolute" top={0} left={0}>
          <VideoBox
            isHover={isHover}
            muted={muted}
            onClickMute={handleClickMute}
            width="285px"
            height="323px"
            src={thumbnail_video_url}
            sx={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px' }}
          />
        </Box>
      )}
    </CardImgRoot>
  );
}
