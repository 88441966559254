import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';

import { StudentInfoRequest, usePostStudentInfoAPI } from 'api/university/universityAPI';
import { ClassSelectForm, FERPA, Fullname, MajorForm, StudentId } from 'components/university';
import type { StudentFormData } from 'types/UniversityType';
import { useLog } from 'utils/userLog';

interface SignupStudentFormProps {
  universityKey: string | null;
  universityId: number;
  defaultValues?: StudentFormData;
  onSubmitSuccess?: (responseData: AxiosResponse) => Promise<void>;
  onSubmitError?: (response: AxiosResponse) => void;
}
export default function SignupStudentForm({
  universityKey,
  universityId,
  defaultValues,
  onSubmitSuccess,
  onSubmitError,
}: SignupStudentFormProps) {
  const { isLoading, fetch } = usePostStudentInfoAPI();
  const methods = useForm<StudentFormData>({ defaultValues });
  const { gxcTag } = useLog();

  const onSubmit = async (formData: StudentFormData) => {
    const request: StudentInfoRequest = {
      universityId: universityId,
      fullName: formData.name.trim(),
      studentNumber: formData.student_number.trim(),
      majorList: formData.major.map((v) => v.major_name),
      classList: formData.class.filter((v) => v.class_id !== null).map((v) => v.class_id as number),
    };

    const response = await fetch(request);
    if (response.status === 200 && response.data.result?.code === 0) {
      onSubmitSuccess?.(response);
    } else {
      onSubmitError?.(response);
    }
  };

  const handleClickComplete = () => {
    gxcTag('click', { area: 'detail', ui: 'complete_signup' });
  };

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form style={{ width: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack gap="20px">
          <Fullname />
          <MajorForm />
          <StudentId />
          <ClassSelectForm uniqueKey={universityKey} />
          <FERPA />
        </Stack>

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          size="large"
          sx={{ marginTop: '40px' }}
          type="submit"
          variant="contained"
          onClick={handleClickComplete}
        >
          Complete Sign up
        </LoadingButton>
      </form>
    </FormProvider>
  );
}
