import { Box, Typography } from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import { useFeaturedGamesAPI } from 'api/displayContent/displayContentAPI';
import { useGameEventBadgeListAPI } from 'api/gamelist/gamelistAPI';
import { Carousel } from 'elements';
import { GameStatusType } from 'types/GameCommonTypes';
import { useLog } from 'utils/userLog';
import { GameCardMobile } from '../../../elements/GameCard';
import FeaturedSkeleton from './FeaturedSkeleton';

import 'swiper/css';
import 'swiper/css/navigation'; // Navigation module
import 'swiper/css/pagination';
import '../PlayingNow/playing-now-swiper.scss';

const Featured: React.FC = () => {
  const { gxcTag } = useLog();

  const { isLoading, data } = useFeaturedGamesAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();

  const loadingSkeletonList = Array.from({ length: 1 }, (_, i) => i);

  const slideData = data && Array.isArray(data?.data) ? data.data : [];

  const isDataNotExist = data?.data?.length === 0;

  if (isDataNotExist) {
    return null;
  }

  return (
    <Box width="100%" position="relative">
      <Box width="100%" p="0px 20px 32px 20px">
        <Typography variant="h4" height="35px">
          Featured
        </Typography>
        <Typography variant="subtitle2" mt="8px" color="text.secondary">
          Check out our new featured playtest!
        </Typography>
      </Box>
      <Carousel
        width={400}
        height={600}
        name="featured-game-list"
        slidesPerView={1}
        slidesPerGroup={1}
        bulletWidth={48}
        hasPagination
        paginationPosition="OUTSIDE"
        spaceBetween={20}
        fullWidth
        style={{ padding: '0px 20px 30px 20px' }}
      >
        {isLoading
          ? loadingSkeletonList.map((_, index) => (
              <SwiperSlide key={index} style={{ width: '100%' }}>
                <FeaturedSkeleton />
              </SwiperSlide>
            ))
          : slideData.map((data) => (
              <SwiperSlide key={data.id} style={{ width: '100%' }}>
                <GameCardMobile
                  {...data}
                  event_badge={eventBadgeListData?.data.find(
                    (badge) => badge.target_id === data.id
                  )}
                  havePlayBtn
                  fullWidth
                  thumbnail_url={data?.thumbnail_launcher_url ?? data?.thumbnail_url}
                  onLikeClicked={({ liked, id }) =>
                    gxcTag('click', {
                      area: 'featured',
                      ui: 'like',
                      parameters: { like_check: liked ? 1 : 0, game_id: id },
                    })
                  }
                  state={GameStatusType.TESTING}
                />
              </SwiperSlide>
            ))}
      </Carousel>
    </Box>
  );
};

export default Featured;
