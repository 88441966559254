import { GCheckbox } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { useQueryDispatch } from 'context';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import WebFont from 'webfontloader';
import { suppoterApplyContents } from '../suppoterApplyContents';

export default function SupporterApplyStep3Content({ isLoading }: { isLoading: boolean }) {
  const { getValues } = useFormContext();
  const { changeQuery } = useQueryDispatch();
  const [isShow, setIsShow] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    WebFont.load({
      google: {
        families: ['La Belle Aurore'],
      },
    });
  }, []);

  return (
    <Box>
      <Box
        mb="60px"
        p="40px"
        sx={{
          userSelect: 'none',
          background: (theme) => theme.palette.gray[100],
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          alert('Prevent right-click');
        }}
      >
        <Box p="60px" sx={{ background: 'white' }} height="1400px" overflow="auto">
          <Typography variant="h5" color="gray.100" mb="40px">
            Non-Disclosure and Confidentiality Agreement
          </Typography>
          <Typography variant="body1" color="gray.100" mb="40px">
            This Nondisclosure Agreement (the “Agreement”) is entered into by and between{' '}
            <Typography fontWeight={700} component="span">
              G.Round Inc.
            </Typography>{' '}
            (“
            <Typography color="primary.main" component="span">
              Disclosing Party
            </Typography>
            “) andand User{' '}
            <Typography fontWeight={700} component="span">
              {getValues('fullname')}
            </Typography>{' '}
            (“
            <Typography color="primary.main" component="span">
              Receiving Party
            </Typography>
            “) for the purpose of preventing the unauthorized disclosure of Confidential Information
            as defined below. The parties agree to enter into a confidential relationship with
            respect to the disclosure of certain proprietary and confidential information
            (“Confidential Information”).
          </Typography>

          <Box type="1" component="ol" pl="30px">
            {suppoterApplyContents.map((text, index) => (
              <Box mb="28px" key={index}>
                <Typography variant="body1" color="gray.100" component="li">
                  <Typography variant="body1" color="primary.main" component="span">
                    {text?.title}
                  </Typography>
                  {` `}
                  <Typography variant="body1" color="gray.100" component="span">
                    {text?.content}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography variant="body1" color="gray.100" mb="80px">
            This Agreement and each party’s obligation shall be binding on the representatives,
            assigns and successors of such party. Each party has signed this Agreement through its
            authorized representative.
          </Typography>

          <Box mb="80px">
            <Typography variant="h5" color="gray.100" mb="20px">
              DISCLOSING PARTY
            </Typography>
            <Box display="flex" mb="20px">
              <Typography variant="subtitle1" color="primary.main">
                G.Round Inc.
              </Typography>
              <Typography variant="subtitle1" color="gray.20" m="0 12px">
                |
              </Typography>
              <Typography variant="subtitle1" color="primary.main">
                James Choi Chief Business Officer
              </Typography>
            </Box>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box display="flex" position="relative">
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  James Choi
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(new Date(), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" color="gray.100" mb="20px">
              RECEIVING PARTY
            </Typography>
            <Typography variant="subtitle1" color="primary.main" mb="20px">
              {getValues('fullname')}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box
                display="flex"
                position="relative"
                width="400px"
                justifyContent={'space-between'}
              >
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {isShow ? getValues('fullname') : null}
                </Typography>

                {!isShow ? (
                  <Button
                    onClick={() => setIsShow((value) => !value)}
                    variant="contained"
                    size="tiny"
                    sx={{ position: 'absolute', bottom: 8, right: '10px', zIndex: 9 }}
                  >
                    <Typography variant="button3">Sign</Typography>
                  </Button>
                ) : null}

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {isShow ? `${format(new Date(), 'MM/dd/yyyy')}` : null}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mb="40px">
        <Typography variant="subtitle1" mb="20px">
          GDPR Agreement
        </Typography>

        <Typography variant="body2" color="text.secondary">
          The Supporters Program, offered by G.Round, is a private playtesting service that values
          your privacy. In accordance with the General Data Protection Regulation (GDPR) and our
          Privacy Policy{` `}
          <Link href="/privacyPolicy.pdf?v=20230515" target="_blank">
            (https://gameround.co/privacyPolicy.pdf?v=20230515)
          </Link>
          , we require your explicit consent before collecting and processing certain personal
          data.By participating in The Supporters Program, you agree to the collection and
          processing of your data to participate in the service that we provide according to our
          Privacy Policy. This includes, but is not limited to, information such as your name, email
          address, age, and country of residence.By agreeing to this, you acknowledge that you have
          read and understood the above and agree to our collection and processing of your personal
          data as described.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" marginTop="12px">
        <GCheckbox value={check} onChange={() => setCheck((value) => !value)} />
        <Typography variant="body2" color="text.primary" ml="8px">
          I Agree
        </Typography>
      </Box>

      <Divider sx={{ margin: '60px 0 40px' }} />

      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => changeQuery({ step: 2 })} variant="ghost" size="large">
          Previous
        </Button>

        <LoadingButton
          disabled={!isShow || !check}
          type="submit"
          variant="contained"
          size="large"
          loading={isLoading}
        >
          Apply
        </LoadingButton>
      </Box>
    </Box>
  );
}
