import { GCheckbox, GIcon } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

import { useRecruitmentApplyAPI } from 'api/recruitment/useRecruitmentAPI';
import { useClientApplyData } from '../../ClientApplyProvider';
import { clientApplyContents } from './clientApply';

const NDAContainer = styled(Box)({
  marginTop: '60px',
});

export default function Step2Contents() {
  const { setClientApplyStep, recruitmentData, setApplyStatus, haveNDAInterview, recruitmentCode } =
    useClientApplyData();
  const [isShow, setIsShow] = useState(false);
  const { isLoading, fetch } = useRecruitmentApplyAPI(recruitmentCode);
  const [toggleInterviewAgree, setToggleInterviewAgree] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    WebFont.load({
      google: {
        families: ['La Belle Aurore'],
      },
    });
  }, []);

  const handleClickApply = async () => {
    const response = await fetch({
      fullname: recruitmentData?.user_info?.fullname,
      auth_start_at: recruitmentData?.user_info?.auth_start_at,
      auth_end_at: recruitmentData?.user_info?.auth_end_at,
      interview_nda_agreed: toggleInterviewAgree,
    });

    if (response.data?.result?.code === 200) {
      if (response?.data.data.state.private_confirm_state === 'PCS_002') {
        // code pcs_001 -> 승인완료
        // code pcs_002 -> 초과 대기.
        setApplyStatus(false);
      } else {
        setApplyStatus(true);
      }
    } else if (response.data?.result?.code === 999012) {
      //이미 등록한 사람일때
      setApplyStatus(false);
    }

    setClientApplyStep(3);
  };

  return (
    <Box>
      <Typography variant="h5" mb="12px">
        秘密保持契約書
      </Typography>

      <Box mb="40px" display="flex" gap="12px">
        <GIcon
          iconName="exclamation"
          iconSize={16}
          size={32}
          backgroundColor="gray.100"
          variant="circle"
          iconColor="error.main"
        />

        <Box>
          <Typography variant="body2" color="error.main">
            *ゲーム パブリッシャー/スタジオとの以下の契約に同意すると、 <br />
            以下の NDA (秘密保持契約) に記載されている情報を共有することが禁止されます。
            <br />
            {/* <Typography variant="body2" color="text.private" component={'span'}>
              Click the 'Sign' button{' '}
            </Typography> */}
            契約の下部にある [署名] ボタンをクリックすると、自動的に署名され、テスト
            アプリケーションに進みます。
          </Typography>
        </Box>
      </Box>

      <Box
        mb="10px"
        p="40px"
        sx={{
          background: (theme) => theme.palette.gray[100],
        }}
      >
        <Box p="60px" sx={{ background: 'white' }} height="1400px" overflow="auto">
          <Typography variant="h5" color="gray.100" mb="40px">
            Non-Disclosure and Confidentiality Agreement
          </Typography>
          <Typography variant="body1" color="gray.100" mb="40px">
            This Nondisclosure Agreement (the “Agreement”) is entered into by and between{' '}
            <Typography color="text.private" component="span">
              {recruitmentData?.studio_name}
            </Typography>{' '}
            (“Disclosing Party“), and{' '}
            <Typography color="text.private" component="span">
              {recruitmentData?.user_info?.fullname}
            </Typography>{' '}
            (“Receiving Party“) for the purpose of preventing the unauthorized disclosure of
            Confidential Information as defined below. The parties agree to enter into a
            confidential relationship with respect to the disclosure of certain proprietary and
            confidential information (“Confidential Information”).
          </Typography>

          <Typography type="1" component="ol" pl="30px" mb="80px">
            {clientApplyContents.map((text, index) => (
              <Typography key={index} variant="body1" color="gray.100" mb="28px" component={'li'}>
                {text}
              </Typography>
            ))}

            <Typography variant="body1" color="gray.100" mb="28px">
              This Agreement and each party’s obligation shall be binding on the representatives,
              assigns and successors of such party. Each party has signed this Agreement through its
              authorized representative.
            </Typography>
          </Typography>

          <Box mb="80px">
            <Typography variant="h5" color="gray.100" mb="20px">
              当事者を開示します
            </Typography>
            <Typography variant="subtitle1" color="text.private" mb="20px">
              {recruitmentData?.studio_name}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box display="flex" position="relative">
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {recruitmentData?.studio_name}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(new Date(), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" color="gray.100" mb="20px">
              受信側です
            </Typography>
            <Typography variant="subtitle1" color="text.private" mb="20px">
              {recruitmentData?.user_info?.fullname}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box
                display="flex"
                position="relative"
                width="400px"
                justifyContent={'space-between'}
              >
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {isShow ? recruitmentData?.user_info?.fullname : null}
                </Typography>

                {!isShow ? (
                  <Button
                    onClick={() => setIsShow(true)}
                    color="private"
                    variant="contained"
                    size="tiny"
                    sx={{ position: 'absolute', bottom: 8, right: '10px', zIndex: 9 }}
                  >
                    <Typography variant="button3">署名します</Typography>
                  </Button>
                ) : null}

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {isShow ? `${format(new Date(), 'MM/dd/yyyy')}` : null}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {haveNDAInterview && (
        <NDAContainer>
          <Typography variant="body1" color="text.secondary" component="span">
            The studio may request interviews from some testers who have provided meaningful
            feedback during or after the testing period for this game. These interviews will be{' '}
            <Typography color="text.primary" component="span">
              40-minute remote video meetings,
            </Typography>{' '}
            and participants will receive an additional{' '}
            <Typography color="text.primary" component="span">
              $15
            </Typography>{' '}
            upon completion. For detailed information and schedule, a G.Round staff will provide
            separate guidance upon receiving your interview request.
          </Typography>
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            sx={(theme) => ({
              borderRadius: '3px',
              background: theme.palette.gray[60],
              padding: '12px 20px',
              marginTop: '40px',
            })}
          >
            <Typography variant="button1" color="text.secondary">
              Would you agree to an interview if requested by the Studio?
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <Box
                sx={{ display: 'flex', gap: '8px', cursor: 'pointer', alignItems: 'center' }}
                onClick={() => {
                  setToggleInterviewAgree((c) => !c);
                }}
              >
                <GCheckbox checked={toggleInterviewAgree} />
                <Typography
                  variant="body2"
                  color={toggleInterviewAgree ? 'text.primary' : 'text.secondary'}
                >
                  Yes, available for interviews.
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Divider sx={{ marginY: '40px' }} />
        </NDAContainer>
      )}

      <Box display="flex" justifyContent="end">
        <LoadingButton
          loading={isLoading}
          disabled={!isShow}
          onClick={handleClickApply}
          color="private"
          variant="contained"
          size="small"
        >
          適用します
        </LoadingButton>
      </Box>
    </Box>
  );
}
