import { Dropdown, GIcon, SearchField } from '@ground/ui';
import { Box, Button, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useClosedGamelistAPI } from 'api/gamelist/gamelistAPI';
import { SectionContainer } from 'components';
import { useQueryDispatch, useQueryState } from 'context';
import { GameStatusType } from 'types/GameCommonTypes';
import TestingCardGrid from './TestingCardGrid/TestingCardGrid';

const sortOptions = [
  { name: 'Latest', value: 'createdAt' },
  { name: 'Most Liked', value: 'likedCount' },
];

const cardtype = GameStatusType.CLOSED;

interface ArchiveListOptions {
  size: number;
  search_keyword: string;
  sort: string;
}

export default function ArchiveSection() {
  const { size, search_keyword, sort } = useQueryState<ArchiveListOptions>();
  const { changeQuery } = useQueryDispatch();
  const { data, isValidating, setSize } = useClosedGamelistAPI(sort, size, search_keyword, true);

  const mergedGameList = useMemo(
    () => data?.map((responseData) => responseData.data.map((game) => game)).flat(),
    [data]
  );

  const totalCount = useMemo(() => data?.[0].next_page?.total_count ?? 0, [data]);

  return (
    <SectionContainer
      title="Archive"
      containerOptions={
        <Box display="flex" columnGap="12px" alignItems="center">
          <SearchField
            name="search"
            placeholder="Search Games or Studios"
            width={300}
            hideInputFirst
            onSearch={(keyword) => changeQuery({ search_keyword: keyword ?? '' })}
            inputProps={{ maxLength: 50 }}
            defaultValue={search_keyword}
          />
          <Dropdown
            size="small"
            options={sortOptions}
            value={sort ?? sortOptions[0].value}
            maxWidth="154px"
            onChangeValue={(option) => {
              changeQuery({ sort: option.toString() });
            }}
          />
        </Box>
      }
    >
      <div>
        {search_keyword && (
          <Box display="flex" alignItems="center" mb={isValidating || totalCount ? '60px' : 0}>
            <Button
              onClick={() => changeQuery({ search_keyword: '' })}
              size="small"
              startIcon={<GIcon iconName="arrow_left" iconSize={16} />}
              variant="ghost"
            >
              Back to Archive
            </Button>
            <Typography ml="20px" variant="body2" color="text.secondary">
              {`${totalCount} result${totalCount > 1 ? 's' : ''} match your search.`}
            </Typography>
          </Box>
        )}
        <TestingCardGrid
          data={mergedGameList}
          loading={isValidating}
          count={size}
          cardtype={cardtype}
        />
        {!isValidating && data && data[data.length - 1]?.next_page?.has_next_page && (
          <Box textAlign="center" marginTop="80px">
            <Button color="dark" onClick={() => setSize((size) => size + 1)} variant="contained">
              Load More
            </Button>
          </Box>
        )}
      </div>
    </SectionContainer>
  );
}
