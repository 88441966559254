import { LinkButton } from 'elements';

interface ContainerLinkProps {
  linkText: string;
  linkUrl: string;
  onClick?: () => void;
}

export default function ContainerLink({ linkText, linkUrl, onClick }: ContainerLinkProps) {
  return (
    <LinkButton size="medium" to={linkUrl} variant="ghost" onClick={onClick}>
      {linkText}
    </LinkButton>
  );
}
