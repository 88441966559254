import { styled, Typography } from '@mui/material';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { ImageBox } from 'atoms';
import { Carousel } from 'elements';
import { GameInfo } from 'types/GameDetailTypes';

const RootCarousel = styled(Carousel)({
  marginTop: 40,
});

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  height: 300,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '0 48px 48px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
}));

const swiperParams: SwiperOptions = {
  slidesPerView: 1,
  lazy: true,
};

interface Props {
  info: GameInfo;
}

export default function GameInfoScreenShotsSlider({ info }: Props) {
  const screenshotsList = Array.isArray(info?.screenshots) ? [...info?.screenshots] : [];
  return (
    <>
      <Typography variant="h5">Screenshots</Typography>
      <RootCarousel
        swiperOption={swiperParams}
        name="game-detail-screenshots"
        width={1200}
        hasNavigation
        hasPagination
        bulletWidth={48}
        navBtnIconType="arrow"
        navBtnIconWidth={48}
        navBtnRowPosition={12}
      >
        {screenshotsList
          .sort((a, b) => a.seq - b.seq)
          .map((screenshot, index) => (
            <SwiperSlide
              key={index}
              style={{ position: 'relative', width: '1200px', height: '675px' }}
            >
              <ImageBox
                src={screenshot.file_url}
                alt={`screenshot ${index}`}
                resize
                resizeWidth={1200}
                resizeHeight={675}
                overlay
                format="webp"
                quality={70} // 이미지가 커서 리사이징 안되는 친구들이 있어서 퀄리티 낮춤
                sx={{ borderRadius: '5px' }}
              />

              <TextContainer>
                {!!screenshot?.title && (
                  <Typography variant="subtitle1" width="827px">
                    {screenshot.title}
                  </Typography>
                )}
                {!!screenshot?.description && (
                  <Typography variant="body2" color="text.secondary" marginTop="4px" width="827px">
                    {screenshot.description}
                  </Typography>
                )}
              </TextContainer>
            </SwiperSlide>
          ))}
      </RootCarousel>
    </>
  );
}
