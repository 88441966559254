import { TextConvertLink } from '@ground/tools';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface NewsArticleProps {
  article: string;
}

export default function NewsArticle({ article }: NewsArticleProps) {
  const [shortText, setShortText] = useState(true);
  const [visibleMoreBtn, setVisibleMoreBtn] = useState(false);

  const articleRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    // article의 높이가 408 클때만 view more 버튼 표출
    articleRef.current?.scrollHeight && setVisibleMoreBtn(articleRef.current?.scrollHeight > 408);
  }, []);

  return (
    <Box width="560px">
      <Typography
        variant="body1"
        color="text.secondary"
        whiteSpace="pre-wrap"
        overflow="hidden"
        ref={articleRef}
        sx={{ maxHeight: shortText ? 408 : '100%', transition: 'max-height 0.3s' }}
      >
        <TextConvertLink wholeText={article} LinkComponent={Link} />
      </Typography>
      {visibleMoreBtn && (
        <Box textAlign="center">
          <Button
            color="light"
            size="small"
            endIcon={shortText ? <ExpandMore /> : <ExpandLess />} // TODO: 추후 우리 icon image로 교체
            onClick={() => setShortText(!shortText)}
            sx={{ marginTop: '20px' }}
          >
            Show {shortText ? 'More' : 'Less'}
          </Button>
        </Box>
      )}
    </Box>
  );
}
