import { Components, Theme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import AlertTheme from './components/Alert';
import ButtonTheme from './components/Button';
import ButtonBaseTheme from './components/ButtonBase';
import DataGridTheme from './components/DataGrid';
import DatePickersTheme from './components/DatePickers';
import DividerTheme from './components/Divider';
import FormHelperTextTheme from './components/FormHelperText';
import IconButtonTheme from './components/IconBase';
import InputBaseTheme from './components/InputBase';
import LoadingButtonTheme from './components/LoadingButton';
import PaperTheme from './components/Paper';
import TypographyTheme from './components/Typography';

const components: Components<Theme> = {
  ...ButtonTheme,
  ...LoadingButtonTheme,
  ...InputBaseTheme,
  ...TypographyTheme,
  ...IconButtonTheme,
  ...DividerTheme,
  ...ButtonBaseTheme,
  ...PaperTheme,
  ...AlertTheme,
  ...FormHelperTextTheme,
  ...DataGridTheme,
  ...DatePickersTheme,
};

export default components;
