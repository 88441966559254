import { Box, Paper, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { useGameInfoSystemRequirementsAPI } from 'api/detail/detailAPI';
import GameInfoSystemRequirementBox from './GameInfoSystemRequirementBox';

export default function GameInfoSystemRequirements() {
  const { id = '' } = useParams<{ id: string }>();

  const { isLoading, data, error } = useGameInfoSystemRequirementsAPI(id);

  return (
    <Box marginTop="120px">
      <Typography variant="h5" marginBottom="40px">
        System Requirements
      </Typography>
      <Paper sx={{ padding: '40px 48px' }}>
        <Box display="flex" justifyContent="space-between" columnGap="40px">
          <GameInfoSystemRequirementBox
            type="Minimum"
            isLoading={isLoading}
            data={data?.data}
            error={error}
          />

          <GameInfoSystemRequirementBox
            type="Recommended"
            isLoading={isLoading}
            data={data?.data}
            error={error}
          />
        </Box>
      </Paper>
    </Box>
  );
}
