import { Box, Link, Typography } from '@mui/material';

interface QuestionTitleProps {
  question: string;
  error?: boolean;
  required?: boolean;
  subtitle?: string;
  detail_url?: string;
  mb?: string;
}

export default function SupporterQuestionTitle({
  question,
  required,
  error,
  subtitle,
  detail_url,
  mb = '20px',
}: QuestionTitleProps) {
  return (
    <Box display="flex" flexDirection="column" mb={mb} position="relative">
      <Box display="inline">
        <Typography color={error ? 'error' : 'text.primary'} variant="body2" display="inline">
          {question}
        </Typography>
        <Typography
          color="text.private"
          variant="body1"
          display="inline"
          position="relative"
          left="8px"
          top="3px"
        >
          {required ? ' *' : ''}
        </Typography>
      </Box>

      {detail_url && (
        <Box display={'flex'}>
          <Typography color="text.secondary2" variant="small2">
            (Test at
          </Typography>
          &nbsp;
          <Link
            href="https://www.speedtest.net"
            target="_blank"
            color="text.private"
            variant="small2"
          >
            https://www.speedtest.net
          </Link>
          <Typography color="text.secondary2" variant="small2">
            )
          </Typography>
        </Box>
      )}

      <Typography color="text.secondary2" variant="small2" mt="8px">
        {subtitle ? subtitle : ''}
      </Typography>
    </Box>
  );
}
