import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { SectionContainer } from 'components';
import { Carousel } from 'elements';
import { ClipCardLarge } from 'elements/NewClipCard';
import { useLog } from 'utils/userLog';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';
import { StoreBottom } from './ClipsPopup/Area/ClipBottomArea';
import { ClipUpperArea } from './ClipsPopup/Area/ClipUpperArea';
import { useClipPopupData } from './ClipsPopup/ClipPopupProvider';
interface IProps {
  title: string;
  dataList: {
    game_id: number;
    id: number;
    link: string;
    thumbnail_url: string;
    created_at: string;
    game_title: string;
    studio_name: string;
    title: string;
    gameLink: string;
    storeLink: string;
    gameId: number;
    productId: string;
  }[];
  count?: number;
  type?: 'store';
}

const ClipStoreListCardLarge: React.FC<IProps> = ({ dataList, count = 4, type, title }) => {
  const { gxcTag } = useLog();
  const { dataIndex, setDataIndex } = useClipPopupData();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryGameId = searchParams.get('gameId') || '';
  const cardType = searchParams.get('type');
  const [gameId, setGameId] = useState(Number(queryGameId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (gameId: number) => {
    setGameId(gameId);
    setDataIndex(gameId);
    setSearchParams({ type: 'store', gameId: String(gameId) });
    setShowModal(true);
    gxcTag('view', {
      area: 'store_clips_popup',
      parameters: {
        clips_id: gameId,
      },
    });
  };

  const closeModal = () => {
    setSearchParams({});
    setShowModal(false);
    gxcTag('view', {
      area: 'store_clips_popup',
      ui: 'close_x',
      parameters: {
        clips_id: gameId,
      },
    });
  };

  const isOpen = showModal || !!(String(gameId) && cardType === 'store');
  const clipUpper = dataList;
  const clipBottom = dataList[dataIndex];

  return (
    <>
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="582px"
          open={isOpen}
          onClose={closeModal}
          body={
            <>
              <ClipUpperArea
                initialSlide={gameId}
                clipsData={clipUpper}
                disableThumbnail
                area="store_clips_popup"
              />
              <StoreBottom {...clipBottom} area="store_clips_popup" />
            </>
          }
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      <SectionContainer title={title}>
        <Carousel
          width={1200}
          name="game-on-store-clips"
          hasNavigation={count > 2}
          swiperOption={{ slidesPerView: 2, spaceBetween: 20, allowTouchMove: false }}
          navigationPositionTop={166}
          onClickLeftArrow={() => gxcTag('click', { area: 'store', ui: 'left_arrow' })}
          onClickRightArrow={() => gxcTag('click', { area: 'store', ui: 'right_arrow' })}
        >
          {dataList?.map((element) => (
            <SwiperSlide key={element.id}>
              <ClipCardLarge
                storeLink={element?.storeLink}
                videoTitle={element.title}
                videoDescription={element.studio_name}
                cardThumbnailUrl={element.thumbnail_url}
                onCardClick={() => {
                  gxcTag('click', {
                    area: 'store',
                    ui: 'clips_card',
                    parameters: {
                      clips_id: element.id,
                    },
                  });
                  openModal(element.game_id);
                }}
                onClickNavLink={() => gxcTag('click', { area: 'store', ui: 'product_page' })}
                type={type}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      </SectionContainer>
    </>
  );
};

export default ClipStoreListCardLarge;
