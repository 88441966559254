import { FormInput } from '@ground/ui';

export default function PersonalDetailsName() {
  return (
    <FormInput
      fullWidth
      label="Username"
      name="name"
      placeholder="Your username"
      rules={{
        validate: {
          minLength: (v) => v?.trim().length > 3,
          maxLength: (v) => v?.trim().length < 31,
          required: (v) => !!v?.trim(),
        },
      }}
      withBackground
    />
  );
}
