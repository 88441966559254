import { Box, Button, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import AboutSlide from '../AboutSlide';

import aboutGamerBGImg from 'images/about/aboutGamer/about_top_bg.png';
import { useLog } from 'utils/userLog';

const Root = styled('div')({
  textAlign: 'center',
  width: 1362,
  paddingTop: 100,
  paddingBottom: 120,
  backgroundImage: `url(${aboutGamerBGImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

// const infoTextList = [
//   {
//     title: 'G.Round',
//     desc: 'A global gaming community platform discovering unreleased outstanding games',
//   },
//   {
//     title: 'Who can join',
//     desc: (
//       <>
//         Everyone with an interest <br />
//         in playtesting unpublished games and game development
//       </>
//     ),
//   },
//   {
//     title: 'What can you do',
//     desc: (
//       <>
//         Play new games every <br />
//         week and provide constructive feedback
//       </>
//     ),
//   },
//   {
//     title: 'Perks',
//     desc: 'Helping game developers and earning G.Points to buy gaming goods',
//   },
// ];

export const aboutGroundSliderData = [
  { id: 1, type: 'VIDEO', link: 'https://youtu.be/Mr_a9qilDPM' },
  // { id: 2, type: 'VIDEO', link: 'https://youtu.be/n1MOHjvBUnI' },
  // { id: 3, type: 'VIDEO', link: 'https://youtu.be/QfMVFCeApy4' },
  // { id: 4, type: 'VIDEO', link: 'https://youtu.be/QeFzUWeqdUo' },
];

export default function ArtAboutGamerHeader() {
  const { gxcTag } = useLog();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Root>
      <Typography variant="h3" align="center">
        What is G.Round?
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '28px' }}>
        G.Round is on a mission to help independent game developers
        <br />
        publish amazing titles, and YOU are critical to our story.
        <br />
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '28px' }}>
        The members of G.Round are an elite playtesting force that provide critical,
        <br />
        early feedback to indie games devs to help them create more compelling titles.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '28px' }}>
        If you've ever dreamed of working in the games industry, now is your chance to start.
        <br />
        Download G.Round to playtest, network with other developers,
        <br />
        and join our mission to help discover and perfect the next great game!
      </Typography>
      <Button
        variant="outlined"
        size="large"
        color="light"
        component={Link}
        to="/testing"
        sx={{
          marginTop: '40px',
          marginBottom: '60px',
        }}
        onClick={() =>
          gxcTag('click', {
            area: 'detail',
            ui: 'play_now',
          })
        }
      >
        Play Now
      </Button>

      <Box width="880px" sx={{ margin: '0 auto' }}>
        <AboutSlide
          sliderData={aboutGroundSliderData}
          slideIndex={currentIndex}
          onChangeIndex={setCurrentIndex}
        />
      </Box>

      {/* <Box mt="32px">
        <Typography color="text.primary" variant="h5">
          G.Round
        </Typography>
        <Typography color="text.primary" mt="8px" variant="body1">
          A global gaming community platform discovering unreleased outstanding games
        </Typography>
      </Box>
      <Tabs
        value={currentIndex}
        onChange={(_event, newValue) => setCurrentIndex(newValue)}
        aria-label="about ground tabs"
        sx={{
          margin: '40px auto 100px',
          width: '760px',
          boxShadow: '0 1px inset rgba(255, 255, 255, 0.15)',
          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'base.dimWhite',
            top: 0,
            bottom: 'unset',
            height: '1px',
          },
        }}
      >
        {infoTextList.map((item, index) => (
          <Tab
            key={index}
            id={`about-ground-tab-${index}`}
            label={
              <Box marginTop="20px" textAlign="left" height="100%">
                <Typography
                  variant="h6"
                  color={currentIndex === index ? 'text.primary' : 'text.secondary2'}
                >
                  {item.title}
                </Typography>
                <Typography variant="subtitle3" color="text.secondary2" marginTop="12px">
                  {item.desc}
                </Typography>
              </Box>
            }
            sx={{ padding: 0, width: '168px' }}
          />
        ))}
      </Tabs> */}
    </Root>
  );
}
