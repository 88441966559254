import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useGameSupportLanguageListAPI } from 'api/detail/detailAPI';
import { RecruitmentData, useRecruitmentAPI } from 'api/recruitment/useRecruitmentAPI';

export interface ClientApplyProps {
  gameId?: string;
  loading: boolean;
  error: boolean;
  recruitmentData?: RecruitmentData;
  clientApplyStep: number;
  setClientApplyStep: Dispatch<SetStateAction<number>>;
  applyStatus: boolean;
  setApplyStatus: Dispatch<SetStateAction<boolean>>; // 신청 실패 성공 상태
  haveNDAInterview?: boolean;
  recruitmentCode: string;
  languageSet?: string[];
  selectLanguageCode: string;
  setSelectLanguageCode: Dispatch<SetStateAction<string>>;
}

const ClientApplyContext = createContext<ClientApplyProps | undefined>(undefined);

export default function ClientApplyProvider({ children }: { children: ReactNode }) {
  const [clientApplyStep, setClientApplyStep] = useState(1);

  const [applyStatus, setApplyStatus] = useState(false);
  const { id: recruitmentCode = '' } = useParams<{ id: string }>();
  const { data, isLoading } = useRecruitmentAPI(recruitmentCode);
  const recruitmentData = data?.data;
  const gameId = recruitmentData?.game_id;
  const navigatorLanguage = navigator.language?.substring(0, 2)?.toUpperCase();
  const { data: languageData, isLoading: languageLoading } = useGameSupportLanguageListAPI(gameId);

  const haveNDAInterview = data?.data?.interview_request_enable;
  const [language, setLanguage] = useState('');

  useEffect(() => {
    // 사용가능한 언어 리스트를 가져와서 리스트에 선택한 언어가 없으면 기본 언어인 EN로 바꾼다
    if (!languageLoading && languageData) {
      //언어 데이터가 로드되었는지 확인
      const supportedLanguages = languageData.data;
      if (supportedLanguages && !supportedLanguages.includes(navigatorLanguage)) {
        setLanguage('EN'); // 지원되는 언어가 아니라면 'EN'로 설정
      } else if (supportedLanguages) {
        setLanguage(navigatorLanguage); // 지원되는 언어라면 해당 언어로 설정
      }
    }
  }, [languageData, languageLoading, navigatorLanguage]);

  return (
    <ClientApplyContext.Provider
      value={{
        gameId,
        loading: isLoading && languageLoading,
        error: !isLoading && data?.result_code === '500',
        recruitmentData,
        clientApplyStep,
        setClientApplyStep,
        applyStatus,
        setApplyStatus,
        haveNDAInterview,
        recruitmentCode,
        languageSet: languageData?.data,
        selectLanguageCode: language,
        setSelectLanguageCode: setLanguage,
      }}
    >
      {children}
    </ClientApplyContext.Provider>
  );
}

export const useClientApplyData = () => {
  const context = useContext(ClientApplyContext);
  if (!context) throw new Error('ClientApplyContext context not found');
  return context;
};
