import { useSelector } from 'react-redux';

import { useFetch, useSWRFetch } from 'api';
import { iRootState } from 'store';

import { ReviewDataProps } from 'types/FormDataType';

export function usePublicReviewRegisterAPI(gameId: string) {
  return useFetch(`v2/games/public/${gameId}/reviews`, { method: 'POST' }, true);
}

export function usePublicReviewUpdateAPI(gameId: string) {
  return useFetch(`v2/games/public/${gameId}/reviews`, { method: 'PATCH' }, true);
}

export function usePrivateReviewRegisterAPI(gameId: string) {
  return useFetch(`games/${gameId}/reviews`, { method: 'PATCH' }, true);
}

export function useGetReviewDataAPI(gameId: string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  // 로그인 시에만 리뷰 API 호출
  const url = isLogin ? `games/${gameId}/reviews` : null;

  return useSWRFetch<ReviewDataProps>({ key: url, useToken: true });
}

export enum SimilarityStatus {
  NONE = 'NONE',
  FAIL = 'FAIL',
  PASS = 'PASS',
  WAITING = 'WAITING',
}

export interface ReviewSurveyStatus {
  review_status: ReviewStatus;
  global_survey_status: GlobalSurveyStatus;
}

export interface ReviewStatus {
  available: boolean;
  complete: boolean;
  similarity_status: SimilarityStatus;
}

export interface GlobalSurveyStatus {
  available: boolean;
  complete: boolean;
  similarity_status: SimilarityStatus;
}

export function useGetReviewSurveyState(gameId: string) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  // 로그인 시에만 리뷰 제출 유무 API 호출
  const url = isLogin ? `v1/games/public/${gameId}/tests/status` : null;

  return useSWRFetch<ReviewSurveyStatus>({ key: url, useToken: true });
}
