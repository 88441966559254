import { Box, Button, Typography, styled } from '@mui/material';

import AboutSlide from '../AboutSlide';

import AboutDevBGImg from 'images/about/aboutDev/about_top_bg_dev.png';

const Root = styled('div')({
  width: 1440,
  paddingTop: 100,
  textAlign: 'center',
  backgroundImage: `url(${AboutDevBGImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const sliderData = [{ id: 1, type: 'VIDEO', link: 'https://youtu.be/oun3YSJ4VXg' }];

export default function ArtDevAboutHeader() {
  const isJapanese = navigator.language.startsWith('ja');

  return (
    <Root>
      <Typography variant="h3" align="center">
        For Developer
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          margin: '0 auto',
          maxWidth: '652px',
          marginTop: '28px',
        }}
      >
        G.Round is the online solution for global public playtesting for your game in development,
        providing market exposure and constructive feedback through our gamers and influencers’
        active gameplay participation.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          margin: '0 auto',
          maxWidth: '650px',
          marginTop: '28px',
        }}
      >
        Based on the test result, you can find the better gaming development direction and ensure
        the success of the games at launch. And business opportunities to find publishing and
        investment partners can be provided when user preference is proven.
      </Typography>

      <Button
        variant="outlined"
        size="large"
        color="light"
        target="_blank"
        href={
          isJapanese
            ? 'https://forms.gle/kjBihayx8qb1K9b58'
            : 'https://docs.google.com/forms/d/e/1FAIpQLSe0lqFCXEBhRRgpAPHGlP_qGXXsOOMdcZV_ou8DZG53ojCEoA/viewform'
        }
        onClick={() => twq?.('event', 'tw-o8sf9-oci0v', {})}
        sx={{
          marginTop: '40px',
          marginBottom: '80px',
        }}
      >
        {isJapanese ? '申し込みはこちらから' : 'Submit Your Game'}
      </Button>
      <Box width="1200px" sx={{ margin: '0 auto' }}>
        <AboutSlide sliderData={sliderData} />
      </Box>
    </Root>
  );
}
