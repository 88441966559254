import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import { ResponseData } from 'types/ResponseType';
import { convertUrlEnv } from 'utils/common';

interface FetchProps<T, D> {
  response: AxiosResponse<ResponseData<T>, D> | null;
  error: AxiosError | null;
  isLoading: boolean;
}

export default function useFetch<T = any, D = any>(
  url: string,
  config: AxiosRequestConfig<D> = {},
  useToken: boolean = false
) {
  const { access_token } = useSelector((state: iRootState) => state.login);
  const [axiosState, setAxiosState] = useState<FetchProps<T, D>>({
    response: null,
    error: null,
    isLoading: false,
  });
  // config가 object라서 같은 데이터가 계속해서 갱신되는 현상을 방지하기 위해 ref로 임시 변경
  const configRef = useRef(config);

  const fetch: (data?: D) => Promise<AxiosResponse<ResponseData<T>, D>> = useCallback(
    async (data?) => {
      setAxiosState((state) => ({ ...state, isLoading: true }));
      let response: AxiosResponse<ResponseData<T>, D>;
      try {
        response = await axios({
          url: convertUrlEnv(url),
          headers:
            useToken && access_token
              ? { Authorization: `Bearer ${access_token}`, ...configRef.current.headers }
              : configRef.current.headers,
          cancelToken: axios.CancelToken.source().token,
          ...configRef.current,
          data,
        });
        setAxiosState({ response, error: null, isLoading: false });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled by cleanup: ', error.message);
        } else {
          setAxiosState({ error, response: null, isLoading: false });
          console.log(error.message);
        }
        return error.message;
      }

      return response;
    },
    [access_token, url, useToken]
  );

  const { response, error, isLoading } = axiosState;
  const data = response?.data;

  return { data, response, error, isLoading, fetch };
}
