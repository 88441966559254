import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import { useFetch } from 'api';
import { useDialog } from 'hooks';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import { PlayGameDivider, PlayGameItemRow } from './PlayGameComponents';
import PlayGameDialogBodyItemGameKey from './PlayGameDialogBodyItemGameKey';

interface PlayGameDialogBodyEpicProps {
  data?: GameDetailProps;
  reload: () => void;
}

export default function PlayGameDialogBodyEpic({ data, reload }: PlayGameDialogBodyEpicProps) {
  const gameFile = data?.game_files?.[0];
  const platform = gameFile?.platform;

  const { showSimpleDialog } = useDialog();
  const { isLoading: btnLoading, fetch } = useFetch(
    `games/${data?.id}/${platform}/download`,
    {},
    true
  );

  const handleClickDownload = () => {
    data?.id && eventTracker('playgamepopup_epic_download', { event_label: data.id });
  };

  const handleClickPlayGame = async () => {
    // Play 버튼 클릭 시 Epic games 게임을 플레이 한 것으로 판단하고 download api 호출
    if (gameFile?.app_id) {
      // 처음 플레이 하는 경우에만 플레이 여부를 기록
      if (platform && !data?.download) {
        const response = await fetch();
        if (response?.status === 200 && response.data?.result_code === 'OK') {
          // 데이터 갱신
          reload();
        }
      }
      window.open(
        `com.epicgames.launcher://store/p/${gameFile.app_id}`,
        '_self',
        'noopener noreferrer'
      );
    } else {
      showSimpleDialog('Alert', 'Not found epic game ID.');
    }

    data?.id && eventTracker('playgamepopup_epic_playgame', { event_label: data.id });
  };

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        This game is on the Epic Games Store, so you will need to download the Epic Games Launcher
        to play the game.
      </Typography>
      <Box marginTop="20px" padding="20px" bgcolor="gray.60">
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            Download Epic Games Launcher
          </Typography>
          <Button
            disabled={gameFile?.main_off}
            variant="ghost"
            size="small"
            href={gameFile?.main_url ?? ''}
            target="_blank"
            onClick={handleClickDownload}
          >
            Download
          </Button>
        </PlayGameItemRow>
        <PlayGameDivider />
        {data && gameFile?.has_game_key && (
          <>
            <PlayGameDialogBodyItemGameKey data={data} />
            <PlayGameDivider />
          </>
        )}
        <PlayGameItemRow>
          <Typography color="text.primary" variant="body2" width={260}>
            Launch Epic Games Launcher
          </Typography>
          <LoadingButton
            loading={btnLoading}
            disabled={gameFile?.sub_off}
            onClick={handleClickPlayGame}
            variant="contained"
            size="small"
          >
            Play Game
          </LoadingButton>
        </PlayGameItemRow>
      </Box>
    </>
  );
}
