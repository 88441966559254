import { Route, Routes } from 'react-router-dom';

import PrivateThemeProvider from 'theme/PrivateThemeProvider';
import NotFound from 'views/main/NotFound';
import ClientApplyMain from './ClientApplyMain';
import ClientApplyProvider from './ClientApplyProvider';
import ClientApplyConfirmMain from './confirm/ClientApplyConfirmMain';

export default function ClientApply() {
  return (
    <PrivateThemeProvider isPrivate>
      <Routes>
        <Route
          path=":id"
          element={
            <ClientApplyProvider>
              <ClientApplyMain />
            </ClientApplyProvider>
          }
        />

        <Route path=":id/confirm" element={<ClientApplyConfirmMain />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </PrivateThemeProvider>
  );
}
