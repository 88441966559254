import { numberWithCommas } from '@ground/tools';
import { GIconButton } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Link, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useGetPinCardNumberAPI } from 'api/gstore/gstoreAPI';
import { useDialog, useFetchResult } from 'hooks';
import { useGStoreOrderDetailDigitalData } from './GStoreOrderDetailDigitalProvider';

const PREFIX = 'GStoreOderDetailDigitalTitleBox';

const classes = {
  infoBox: `${PREFIX}-infoBox`,
  productImage: `${PREFIX}-productImage`,
};

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.infoBox}`]: {
    padding: 20,
    display: 'flex',
    background: theme.palette.gray[90],
    borderRadius: 5,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  [`& .${classes.productImage}`]: {
    width: 150,
    height: 120,
    borderRadius: 3,
  },
}));

interface Props {
  orderId: string;
}

export default function GStoreOrderDetailDigitalTitleBox({ orderId }: Props) {
  const { isLoading, data } = useGStoreOrderDetailDigitalData();
  const { showDialog } = useDialog();
  const { showResult } = useFetchResult();
  const [copied, setCopied] = useState<boolean>(false);
  const {
    isLoading: isGetCardPinLoading,
    data: cardPinData,
    refetch: fetchCardPin,
  } = useGetPinCardNumberAPI(orderId);

  const cardPinNumber = cardPinData?.data || data?.data?.shopOrderDigital?.card_pin;

  const processingDialogContents = {
    title: 'Processing...',
    body: (
      <>
        <Typography variant="body2">
          The order is being processed.
          <br />
          The code will be delivered within 30 mins.
          <br />
          <br />
          Please contact us via{' '}
          <Link href="mailto:support@gameround.co" sx={{ color: 'text.primary' }}>
            support@gameround.co
          </Link>
          <br />
          if you have not received the code in 30 minutes.
        </Typography>
      </>
    ),
    primaryButton: { text: 'OK' },
  };

  return (
    <Root>
      <Typography variant="h5" mb="20px">
        Order Detail
      </Typography>
      {!isLoading && (
        <Box className={classes.infoBox}>
          <Box display="flex" columnGap="40px">
            <img
              className={classes.productImage}
              src={data?.data?.shopProductDigital?.main_media_url}
              alt="product"
            />

            <Box>
              <Typography variant="h6">
                {data?.data?.shopProductDigitalOption?.digital_goods_depth_top}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt="8px">
                Item : {data?.data?.shopProductDigitalOption?.digital_goods_depth_mid}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt="4px">
                Amount : {data?.data?.shopProductDigitalOption?.digital_goods_depth_bottom}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt="4px">
                Quantity : 1
              </Typography>
              <Typography variant="body2" color="text.secondary" mt="4px">
                Price :{' '}
                {data?.data?.shopProductDigitalOption?.point_price &&
                  `${numberWithCommas(data?.data?.shopProductDigitalOption?.point_price)} G.P`}
              </Typography>
              <Typography variant="body3" color="text.secondary" mt="16px">
                <span style={{ marginRight: 32 }}>Order Number {data?.data?.order_id}</span>
                <span>
                  Order Date :{' '}
                  {data?.data?.created_at &&
                    `${format(new Date(data.data.created_at), 'MMM dd, yyyy hh:mm a')}(PT)`}
                </span>
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  padding: '14px 20px',
                  backgroundColor: theme.palette.gray[80],
                  borderRadius: '5px',
                  width: 'fit-content',
                  marginTop: '20px',
                })}
              >
                <Typography color="text.secondary2" variant="body2">
                  Code
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{ marginLeft: '20px', marginRight: '20px', height: '20px' }}
                />
                {cardPinNumber ? (
                  <>
                    <Typography variant="body2" mr="86px" color="text.primary">
                      {cardPinNumber}
                    </Typography>
                    <CopyToClipboard
                      text={data?.data?.shopOrderDigital?.card_pin || ''}
                      onCopy={() => {
                        setCopied(true);
                      }}
                    >
                      <GIconButton size={24} iconName="copy" iconSize={16} />
                    </CopyToClipboard>
                  </>
                ) : (
                  <LoadingButton
                    loading={isGetCardPinLoading}
                    variant="ghost"
                    size="small"
                    onClick={async () => {
                      try {
                        const response = await fetchCardPin();
                        showResult(response, {
                          hideErrorMessage: true,
                          onError: () => {
                            showDialog(processingDialogContents);
                          },
                        });
                      } catch (error) {
                        showDialog(processingDialogContents);
                      }
                    }}
                  >
                    Claim Code
                  </LoadingButton>
                )}

                {copied && (
                  <Typography variant="body3" color="primary.main" ml="20px">
                    Link copied!
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Root>
  );
}
