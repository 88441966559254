import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';

import { ImageBox } from 'atoms';

const PREFIX = 'HowToPlayGridItem';

const classes = {
  image: `${PREFIX}-image`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: 540,
  padding: '60px 0 0',

  [`& .${classes.image}`]: {
    display: 'block',
    maxWidth: 540,
  },
}));

export interface HowToPlayContentProps {
  id: number;
  title: ReactNode;
  image: string;
}

interface Props {
  content: HowToPlayContentProps;
}

export default function HowToPlayGridItem({ content }: Props) {
  return (
    <StyledGrid item>
      <Typography variant="h5">{content.id}</Typography>
      <Divider sx={{ margin: '8px 0 12px' }} />
      <Typography variant="body1" marginBottom="12px" minHeight="40px">
        {content.title}
      </Typography>
      <ImageBox
        className={classes.image}
        alt={`guide${content.id}`}
        src={content.image}
        resize
        resizeWidth={540}
        format="webp"
      />
    </StyledGrid>
  );
}
