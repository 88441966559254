export const getCurrentTime = (): string => {
  // UTC time zone
  return new Date().toISOString();
};

/**
 *
 * @param targetTime log created_at
 * @param timeRange timer(seconds 단위)
 * @returns targetTime이 지금으로부터 timeRange 초 전 시간 이후의 시간인지 여부
 */
export function isWithinLastSeconds(targetTime: string, timeRange: number): boolean {
  const secondssAgo: Date = new Date(Date.now() - timeRange * 1000);
  return secondssAgo <= new Date(targetTime);
}
