import { numberWithCommas } from '@ground/tools';
import { GRadio } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, FormControl, Link, Typography, styled } from '@mui/material';
import { useState } from 'react';

import { useGStoreDetailData } from '../detail/GStoreDetailProvider';
import { AgreeTermText } from './AgreeTermText';

const PREFIX = 'OrderPayInfo';

const classes = {
  shippingPayInfo: `${PREFIX}-shippingPayInfo`,
  shippingPayInfoBox: `${PREFIX}-shippingPayInfoBox`,
  shippingPayInfoBigBox: `${PREFIX}-shippingPayInfoBigBox`,
};

const Root = styled(Box)({
  [`&.${classes.shippingPayInfo}`]: { flexBasis: 472 },
  [`& .${classes.shippingPayInfoBox}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:nth-of-type(1))': {
      marginTop: 8,
    },
  },
  [`& .${classes.shippingPayInfoBigBox}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
});

const FormBox = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.gray[90],
  borderRadius: 5,
  padding: 40,
  marginTop: 20,
}));

interface Props {
  loading: boolean;
}

export default function OrderPayInfo({ loading }: Props) {
  const { data, gpointSummaryData } = useGStoreDetailData();
  const [toggleAgree, setToggleAgree] = useState(false);

  return (
    <Root className={classes.shippingPayInfo}>
      <Typography variant="h5">Pay with</Typography>

      <FormBox>
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <GRadio color="primary" label="G.Points" checked />

          <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

          <Box className={classes.shippingPayInfoBox}>
            <Typography variant="body1" color="text.primary">
              Available G.Points
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {typeof gpointSummaryData?.data?.available_point === 'number' &&
                numberWithCommas(gpointSummaryData?.data?.available_point) + ' G.P'}
            </Typography>
          </Box>
          <Box className={classes.shippingPayInfoBox}>
            <Typography variant="body3" color="text.secondary2">
              Earned G.Points
            </Typography>
            <Typography variant="body3" color="text.secondary2">
              {typeof gpointSummaryData?.data?.earned_point === 'number' &&
                numberWithCommas(gpointSummaryData?.data?.earned_point) + ' G.P'}
            </Typography>
          </Box>
          <Box className={classes.shippingPayInfoBox} style={{ marginTop: '4px' }}>
            <Typography variant="body3" color="text.secondary2">
              Purchased G.Points
            </Typography>
            <Typography variant="body3" color="text.secondary2">
              {typeof gpointSummaryData?.data?.purchase_point === 'number' &&
                numberWithCommas(gpointSummaryData?.data?.purchase_point) + ' G.P'}
            </Typography>
          </Box>
          <Divider sx={{ my: '20px', marginBottom: '12px' }} />
          <Box className={classes.shippingPayInfoBox}>
            <Typography variant="body1" color="text.primary">
              Redeemed G.Points
            </Typography>
            <Typography variant="h4" color="text.primary">
              {data?.data.point && numberWithCommas(data.data.point) + ' G.P'}
            </Typography>
          </Box>
          <Box className={classes.shippingPayInfoBox}>
            <Typography variant="body3" color="text.secondary2">
              Earned G.Points
            </Typography>
            <Typography variant="body3" color="text.secondary2">
              {numberWithCommas(
                Math.min(data?.data.point || 0, gpointSummaryData?.data?.earned_point || 0)
              ) + ' G.P'}
            </Typography>
          </Box>
          <Box className={classes.shippingPayInfoBox} style={{ marginTop: '4px' }}>
            <Typography variant="body3" color="text.secondary2">
              Purchased G.Points
            </Typography>
            <Typography variant="body3" color="text.secondary2">
              {typeof gpointSummaryData?.data?.earned_point === 'number' &&
                numberWithCommas(
                  (data?.data.point || 0) > (gpointSummaryData?.data?.earned_point || 0)
                    ? (data?.data.point || 0) - (gpointSummaryData?.data?.earned_point || 0)
                    : 0
                ) + ' G.P'}
            </Typography>
          </Box>

          <Divider sx={{ mt: '20px' }} />

          <Box className={classes.shippingPayInfoBigBox}>
            <Typography variant="body3" color="text.secondary2">
              G.Point Balance
            </Typography>
            <Typography variant="h4" color="text.primary">
              {typeof gpointSummaryData?.data?.available_point === 'number' &&
                typeof data?.data.point === 'number' &&
                numberWithCommas(gpointSummaryData?.data?.available_point - data.data.point) +
                  ' G.P'}
            </Typography>
          </Box>
        </FormControl>

        <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

        <Typography variant="body3" color="text.secondary2" mb="20px">
          · Products on the G.Point Store are non-refundable and cannot be exchanged, with the
          exception of product defects on G.Round Merchandise.
          <br />
          · Products offered on the G.Point Store may only be purchased with G.Points.
          <br />
          · Merchandise orders can no longer be canceled once packaged and shipped. Digital
          products, once ordered cannot be canceled.
          <br />
          · G.Round is not liable for incorrect addresses.
          <br />
          · The recipient is responsible for any import duties charged by their country.
          <br />· You may cancel your merchandise order before packaging in your order details page
          or by contacting{' '}
          <Link href="mailto:support@gameround.co" color="text.secondary">
            our customer support
          </Link>
          .<br />· Please visit{' '}
          <Link
            href="https://www.notion.so/gameround/G-Points-Wiki-4bedd215f2224ee7aa88f8432d3b9f1d?pvs=4"
            color="text.secondary"
            target="_blank"
          >
            G.Points Wiki
          </Link>{' '}
          for all things related to G.Point Store, Merchandise & Digital Products!
          <br /> · You may request a refund for purchasing G.pints within fourteen days of purchase
          if they were purchased on G.Round and if you have not used any of those points.
        </Typography>

        <AgreeTermText onChange={(value) => setToggleAgree(value)} />

        <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

        <LoadingButton
          loading={loading}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!toggleAgree}
        >
          Place Order
        </LoadingButton>
      </FormBox>
    </Root>
  );
}
