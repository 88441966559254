import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import useSWRFetch from 'api/useSWRFetch';
import { Gnb, gnbItems } from 'components';
import { useDialog, useTermsConfirmDialog } from 'hooks';
import { Dispatch, iRootState } from 'store';
import Footer from 'views/Footer';
import Main from 'views/main/Main';
// import { LoadingPage } from './main/LoadingPage';
import DialogContainer from './DialogContainer';
import ErrorPage from './ErrorPage';

interface TermsProps {
  agreed_terms: boolean;
  agreed_terms_at: string;
}

function AppContainer() {
  const { pathname, search } = useLocation();
  const isSignupPage = useMatch('/signup/*');
  const isLoginPage = useMatch('/login/*');
  const navigate = useNavigate();

  const { isLogin, agreed_terms, filled, verified, withdrawal_request } = useSelector(
    (state: iRootState) => state.login
  );
  const dispatch = useDispatch<Dispatch>();
  const { closeDialog } = useDialog();
  const { showTermsConfirmDialog } = useTermsConfirmDialog();
  const { data } = useSWRFetch<TermsProps>({
    key: isLogin && agreed_terms === null ? 'users/terms' : '',
    useToken: true,
  });

  const isAuth = !!isSignupPage || !!isLoginPage;

  useEffect(() => {
    // 페이지 이동 시 열려있는 다이얼로그를 닫아준다.
    // 추후 멀티 다이얼로그 관리 기능이 추가된다면 선택적으로 닫을 수 있는 기능이 추가되어야 할듯
    closeDialog();
  }, [closeDialog, pathname, search]);

  useEffect(() => {
    // 약관 동의 여부가 없는 경우 동의 여부를 API를 통해 읽어온 후 업데이트 한다.
    if (agreed_terms === null && data?.data) {
      dispatch.login.updateLoginInfo({ agreed_terms: data.data.agreed_terms });
    }
  }, [agreed_terms, data?.data, dispatch.login, pathname, search]);

  useEffect(() => {
    if (isLogin) {
      const { university } = queryString.parse(search);

      if (withdrawal_request) {
        // 회원 탈퇴 요청상태
        navigate('/withdrawal', { replace: true });
      } else if (!verified) {
        // email 인증이 안된 경우
        navigate({ pathname: '/signup/verification', search }, { replace: true });
      } else if (!filled) {
        // 프로필 입력 안된 경우
        navigate({ pathname: '/signup/profile', search }, { replace: true });
      } else if (university) {
        // university 프로그램 참여 url로 유입된 경우
        navigate({ pathname: '/signup/university', search }, { replace: true });
      } else if (agreed_terms === false) {
        // 페이지 이동 시 약관 동의를 하지 않았으면 약관 동의 다이얼로그 띄움
        showTermsConfirmDialog();
      }
    }
  }, [
    isLogin,
    agreed_terms,
    showTermsConfirmDialog,
    pathname,
    search,
    verified,
    navigate,
    filled,
    withdrawal_request,
  ]);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => <ErrorPage {...props} />}
      beforeCapture={(scope) => {
        scope.setTag('platform', 'desktop');
      }}
    >
      {/* <Suspense fallback={<LoadingPage />}> */}
      <Box minWidth={1200}>
        {/* isSign 메뉴를 보여줄것이냐 아닐것이냐 */}
        {!withdrawal_request && <Gnb items={gnbItems} isSign={isAuth} />}
        <Main />
        {!isAuth && !withdrawal_request && <Footer />}
      </Box>
      <DialogContainer />
      {/* </Suspense> */}
    </Sentry.ErrorBoundary>
  );
}

export default memo(AppContainer);
