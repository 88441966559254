import { Dropdown, DropdownOptionValue } from '@ground/ui';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGameDetailVideoClipAPI } from 'api/detail/detailAPI';
import { SectionContainer } from 'components';
import NoVideoClip from 'views/clips/NoVideoClip';
import DetailClipsCardGrid from './detailClipsCardGrid/DetailClipsCardGrid';

const allClipsDropDownOptions = [
  { name: 'Latest', value: 'UPLOAD' },
  { name: 'Most Viewed', value: 'VIEW' },
];

const count = 3;

export default function Clips() {
  const { id = '' } = useParams<{ id: string }>();
  const [sort, setSort] = useState('sortingFilterType=UPLOAD');
  const { isLoading, data } = useGameDetailVideoClipAPI(id, sort);

  return (
    <Box marginTop="20px">
      {(data && Array.isArray(data.data) && data.data.length > 0) || isLoading ? (
        <SectionContainer
          title="Video Clips"
          sx={{ width: '1200px' }}
          containerOptions={
            <Dropdown
              size="small"
              options={allClipsDropDownOptions}
              value={allClipsDropDownOptions[0].value}
              maxWidth="154px"
              onChangeValue={(option: DropdownOptionValue) => {
                setSort(`sortingFilterType=${option.toString()}`);
              }}
            />
          }
        >
          <DetailClipsCardGrid
            id={Number(id)}
            data={data?.data}
            loading={isLoading}
            count={count}
          />
        </SectionContainer>
      ) : (
        data &&
        !isLoading &&
        Array.isArray(data.data) &&
        data.data.length === 0 && (
          <Box marginTop="120px">
            <NoVideoClip />
          </Box>
        )
      )}
    </Box>
  );
}
