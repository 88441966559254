import { Box } from '@mui/material';

import { SmallChip } from '../../atoms';

interface ReviewChipsProps {
  adminBadge?: boolean;
  studioBadge?: boolean;
  creatorBadge?: boolean;
  supporterBadge?: boolean;
  studentBadge?: boolean;
}

export default function ReviewChips({
  adminBadge,
  studioBadge,
  creatorBadge,
  supporterBadge,
  studentBadge,
}: ReviewChipsProps) {
  return (
    <Box display="flex" alignItems="center" gap="4px">
      {adminBadge ? <SmallChip color="primary.main" icon="logo" text="Admin" /> : null}
      {studioBadge ? <SmallChip color="primary.main" icon="computer" text="Studio" /> : null}
      {creatorBadge ? (
        <SmallChip color="primary.main" icon="headphone" text="Content Creator" />
      ) : null}
      {supporterBadge ? <SmallChip color="text.private" icon="flag" text="Supporter" /> : null}
      {studentBadge ? <SmallChip color="primary.main" icon="educator" text="Student" /> : null}
    </Box>
  );
}
