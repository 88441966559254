import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetCountryAPI } from 'api/country/countryAPI';
import { useGetSurveyAnswerDataAPI, useGetSurveyDataAPI } from 'api/survey/surveyAPI';
import { iRootState } from 'store';
import { KeyedMutator } from 'swr/dist/types';
import { SurveyAnswerDataProps, SurveyDataProps } from 'types/FormDataType';
import { ResponseData } from 'types/ResponseType';
import { useGetDetailData } from '../DetailProvider';

export interface DataSame {
  surveyId: number;
  value: boolean;
}

type ContextProps = {
  country: string | null;
  data?: SurveyDataProps;
  dataResult: { code?: string; description?: string };
  answerList?: SurveyAnswerDataProps;
  loading: boolean;
  mutate: () => void;
  fetchData?: KeyedMutator<ResponseData<SurveyDataProps>>;
  fetchAnswer?: KeyedMutator<ResponseData<SurveyAnswerDataProps>>;
};

const SurveyContext = createContext<ContextProps | undefined>(undefined);

export const useSurveyData = () => {
  const context = useContext(SurveyContext);
  if (!context) throw new Error('surveyData context not found');
  return context;
};

interface Props {
  children: ReactNode;
}

export default function SurveyProvider({ children }: Props) {
  const { id = '' } = useParams<{ id: string }>();
  const { country_code } = useSelector((state: iRootState) => state.login);
  const { data } = useGetDetailData();
  const country = useGetCountryAPI(country_code);

  const {
    isValidating: surveyLoading,
    data: surveyData,
    mutate: fetchData,
  } = useGetSurveyDataAPI(id, data, country_code);

  const {
    isValidating: answerLoading,
    data: answerData,
    mutate: fetchAnswer,
  } = useGetSurveyAnswerDataAPI(id, data, country_code);

  const dataList = useMemo(() => surveyData?.data?.[0], [surveyData]);
  const dataResult = useMemo(
    () => ({ code: surveyData?.result_code, description: surveyData?.description }),
    [surveyData]
  );
  const answerList = useMemo(() => answerData?.data?.[0], [answerData]);

  return (
    <SurveyContext.Provider
      value={{
        country: country?.name1,
        data: dataList,
        dataResult: dataResult,
        answerList: answerList,
        loading: surveyLoading || answerLoading,
        mutate: () => {
          fetchData();
          fetchAnswer();
        },
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}
