import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { usePromotionAPI } from 'api/gstore/promotionAPI';
import { SectionContainer } from 'components';
import GStoreItem from './GStoreItem';
import GStoreTitleButton from './components/GStoreTitleButton';

const PeriodBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.gray[90],
  padding: '20px',
  borderRadius: '5px',
  alignItems: 'center',
}));

const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 380px)',
  gridTemplateRows: 'repeat(1, 350px)',
  gap: '30px',
  marginTop: '30px',
});

const GStoreMerchandiseList = () => {
  const navigate = useNavigate();
  const { isLoading, data } = usePromotionAPI();

  const promotionData = data?.data;
  const merchandiseList = promotionData?.products ?? [];

  return merchandiseList?.length > 0 ? (
    <SectionContainer
      title={`G.Round Merch (${merchandiseList.length})`}
      containerOptions={
        <GStoreTitleButton
          onClick={() => {
            navigate('/blog/186');
          }}
        >
          How do I purchase G.Round Merchandise?
        </GStoreTitleButton>
      }
    >
      {promotionData?.started_at && promotionData?.ended_at && (
        <PeriodBox
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: ({ palette }) => palette.opacity.white[10] }}
            />
          }
        >
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Typography variant="body2" color="text.secondary2">
              Purchase Period
            </Typography>
            <Typography variant="body2" ml="8px">
              {format(new Date(promotionData.started_at), 'MMM d, yyyy') +
                ' - ' +
                format(new Date(promotionData.ended_at), 'MMM d, yyyy')}
            </Typography>
            {promotionData?.delivery_at && (
              <>
                <Typography variant="body2" color="text.secondary2" ml="40px">
                  Shipping Date
                </Typography>
                <Typography variant="body2" ml="8px">
                  {format(new Date(promotionData.delivery_at), 'MMM d, yyyy')}
                </Typography>
              </>
            )}
          </Box>
          <Typography variant="body2" color="text.secondary2" align="center" flex={1}>
            Users can only order one product per account during the purchase period.
          </Typography>
        </PeriodBox>
      )}
      {Array.isArray(merchandiseList) && merchandiseList && (
        <GridBox>
          {[...merchandiseList]
            .sort((a, b) => a.seq - b.seq)
            .map((product) => (
              <Box key={product.product_id} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
                <GStoreItem
                  productName={product.name}
                  price={product.point}
                  imageUrl={product.main_media_url}
                  to={`merchandise/${product.product_id}`}
                  isLoading={isLoading}
                  hasNoPriceDisplay={product.point_no_display}
                  isMerchandise
                />
              </Box>
            ))}
        </GridBox>
      )}
    </SectionContainer>
  ) : null;
};

export default GStoreMerchandiseList;
