import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useLauncherVersionCheckAPI } from 'api/launcher';
import { LinkButton } from 'elements';
import { iRootState } from 'store';

import image from 'images/home/launcher-install-popup.png';
import image2x from 'images/home/launcher-install-popup@2x.png';
import image3x from 'images/home/launcher-install-popup@3x.png';
import { ReactComponent as CloseIcon } from 'images/icons/icon_close.svg';

function LauncherReinstallPopup() {
  const { data } = useLauncherVersionCheckAPI('main');
  const [open, setOpen] = useState(!!data?.data.isPopup);

  const handleClickClose = () => {
    setOpen(false);
  };

  return open ? (
    <Box
      sx={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        margin: 'auto',
        width: '380px',
        height: '518px',
        zIndex: (theme) => theme.zIndex.modal - 1,
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          alignItems: 'center',
          padding: '40px 28px 28px',
          backgroundColor: 'blue.pointBG',
          boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
        }}
      >
        <IconButton
          onClick={handleClickClose}
          sx={{
            position: 'absolute',
            width: 36,
            height: 36,
            top: 8,
            right: 8,
            padding: 0,
            color: 'base.dimWhite',
            opacity: 0.5,
            '&:hover': { opacity: 1 },
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack position="relative">
          <img src={image} srcSet={`${image}, ${image2x} 2x, ${image3x} 3x`} alt="content" />
          <Box
            sx={{
              position: 'absolute',
              left: '-7px',
              bottom: '13px',
              width: '278px',
              height: '6px',
              background: 'black',
              opacity: 0.4,
              filter: 'blur(4px)',
            }}
          />
          <Box
            sx={{
              background: 'linear-gradient(180deg, rgba(13, 18, 37, 0) 0%, #0D1225 100%)',
              opacity: 0.4,
              transform: 'matrix(1, 0, 0, -1, 0, 0)',
              width: '264px',
              height: '16px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: -15,
              top: -15,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 900,
              fontSize: 10,
              lineHeight: '10px',
              textAlign: 'center',
              color: 'white',
              backgroundColor: 'error.main',
              borderRadius: '50%',
              width: '49px',
              height: '49px',
            }}
          >
            UPDATE NOW
          </Box>
        </Stack>
        <Typography color="white" variant="h5" mt="14px" align="center">
          Please re-install your launcher
        </Typography>

        <Typography variant="body2" mt="20px" align="center">
          If you have been logged out of the G.Round launcher, you are using an outdated version of
          it. Please re-install to the latest version of the launcher.
        </Typography>

        <LinkButton
          color="dark"
          fullWidth
          to="/blog/178"
          variant="contained"
          sx={{ marginTop: '40px' }}
        >
          Learn More
        </LinkButton>
      </Stack>
    </Box>
  ) : null;
}

// 런처 설치 권유 팝업 레이어
export default function HomePopupLayerLauncherReinstall() {
  const { isLoading } = useLauncherVersionCheckAPI('main');
  const { isLogin } = useSelector((state: iRootState) => state.login);

  //로그인 한 유저에게만 팝업이 뜨도록
  return isLogin && !isLoading ? <LauncherReinstallPopup /> : null;
}
