import { Box, Button, ImageList, ImageListItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetDigitalGoodsListAPI } from 'api/gstore/digitalGoodsAPI';
import { SectionContainer } from 'components';
import GStoreItem from './GStoreItem';
import GStoreTitleButton from './components/GStoreTitleButton';
import PurchaseDigitalProductBox from './components/PurchaseDigitalProductBox';

const GStoreDigitalGoodsList = () => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const { data: digitalGoodsData, isLoading } = useGetDigitalGoodsListAPI();

  const digitalGoodsItemList = digitalGoodsData?.data || [];

  const digitalGoodsItemListSliced =
    digitalGoodsItemList
      .sort((a, b) => a.order_no - b.order_no)
      .slice(0, showAll ? digitalGoodsItemList?.length : 4) || [];

  const isDigitalGoodsCountOdd = digitalGoodsItemListSliced.length % 2 !== 0;

  return digitalGoodsItemList?.length > 0 ? (
    <SectionContainer
      title={`Gift Cards (${digitalGoodsItemList?.length})`}
      containerOptions={
        <GStoreTitleButton
          onClick={() => {
            navigate('/blog/187');
          }}
        >
          How do I purchase Gift Cards?
        </GStoreTitleButton>
      }
    >
      <ImageList rowHeight={300} gap={30} cols={2} sx={{ margin: 0 }}>
        {digitalGoodsItemListSliced.map((product) => (
          <ImageListItem
            cols={1}
            rows={1}
            key={product.product_id}
            sx={{ borderRadius: '5px', overflow: 'hidden' }}
          >
            <GStoreItem
              productName={product.digital_goods_name}
              price={Number(product.display_point.split('GP')[0])}
              imageUrl={product.main_media_url}
              to={`/store/digital/${product.product_id}`}
              isLoading={isLoading}
              hasNoPriceDisplay={product.point_no_display}
            />
          </ImageListItem>
        ))}
        {isDigitalGoodsCountOdd && (
          <ImageListItem cols={1} rows={1} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
            <PurchaseDigitalProductBox />
          </ImageListItem>
        )}
      </ImageList>

      {!showAll && digitalGoodsItemList.length > 4 && (
        <Box textAlign="center" marginTop="80px">
          <Button color="dark" variant="contained" onClick={() => setShowAll(true)}>
            Load More
          </Button>
        </Box>
      )}
    </SectionContainer>
  ) : null;
};

export default GStoreDigitalGoodsList;
