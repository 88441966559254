import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import DetailTabs from './DetailTabs';

export default function DetailTabsWrapper() {
  return (
    <Box id="gameDetailTab" marginTop="60px" width="1200px">
      <Routes>
        <Route path=":tab/*" element={<DetailTabs />} />
      </Routes>
    </Box>
  );
}
