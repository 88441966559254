import { Box, Typography, Button } from '@mui/material';

interface DeleteNotPosibleProps {
  closeDialog: () => void;
}

// Store 진행중일때 회원탈퇴 불가 다이어로그
export default function DeleteStoreDialog({ closeDialog }: DeleteNotPosibleProps) {
  return (
    <>
      <Typography variant="button1" color="text.primary">
        Account Deletion currently unavailable.
      </Typography>
      <br />

      <Typography variant="body2" color="text.secondary">
        You have an order in progress. <br />
        You can request for account deletion after the order has been delivered or canceled.
      </Typography>

      <Box mt="40px">
        <Button color="primary" variant="ghost" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
      </Box>
    </>
  );
}
