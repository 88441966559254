import { FormAutocompleteOption } from '@ground/ui';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useSupporterApplyFormAPI } from 'api/supporter/useSupporterAPI';
import { SuppoterQuestionData } from 'api/supporter/useSuppoterTypes';
import { iRootState } from 'store';
import { questions } from './jpn/questions';

export interface SuppoterApplyProps {
  loading: boolean;
  error: boolean;
  supporterQuestionData?: SuppoterQuestionData[];
  postFileInfo: string[];
  setPostFileInfo: Dispatch<SetStateAction<string[]>>;
  countryList: FormAutocompleteOption[];
  userCountryCode: string | null;
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
}

const SupporterApplyContext = createContext<SuppoterApplyProps | undefined>(undefined);

export default function SupporterApplyProvider({ children }: { children: ReactNode }) {
  const isJapanese = navigator.language?.substring(0, 2)?.toUpperCase() === 'JA';
  const [language, setLanguage] = useState(isJapanese ? 'JA' : 'EN');

  const { data: countriesData } = useGetCountriesAPI();
  const [postFileInfo, setPostFileInfo] = useState<string[]>([]);
  const { country_code } = useSelector((state: iRootState) => state.login);
  const countries = countriesData ?? [];
  const countryList: FormAutocompleteOption[] = countries?.map((country) => ({
    name: country.name,
    value: country.code,
  }));

  const { data, isLoading } = useSupporterApplyFormAPI();
  const supporterQuestionData = language === 'JA' ? questions : data?.data?.data;

  return (
    <SupporterApplyContext.Provider
      value={{
        loading: isLoading,
        error: !isLoading && data?.result_code === '500',
        supporterQuestionData,
        postFileInfo,
        setPostFileInfo,
        countryList,
        userCountryCode: country_code,
        language,
        setLanguage,
      }}
    >
      {children}
    </SupporterApplyContext.Provider>
  );
}

export const useSupporterApplyData = () => {
  const context = useContext(SupporterApplyContext);
  if (!context) throw new Error('SupporterApplyContext context not found');
  return context;
};
