import { Box, CircularProgress, CircularProgressProps, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  isLoading: boolean;
  color?: CircularProgressProps['color'];
  size?: number;
  sx?: SxProps;
  children: ReactNode;
}

function LoadingWrapper({ isLoading, color = 'primary', size = 32, children, sx }: Props) {
  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${size}px`,
        ...sx,
      }}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  ) : (
    <>{children}</>
  );
}

export default LoadingWrapper;
