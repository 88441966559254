import { Box, Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import SupporterApplyQuestionCheckBox from '../question/SupporterApplyQuestionCheckBox';
import SupporterApplyQuestionChoice from '../question/SupporterApplyQuestionChoice';
import SupporterApplyQuestionInputField from '../question/SupporterApplyQuestionInputField';
import SupporterApplyQuestionPcInfo from '../question/SupporterApplyQuestionPcInfo';
import { useSupporterApplyData } from '../SupporterApplyProvider';

export default function SystemSection() {
  const { supporterQuestionData } = useSupporterApplyData();
  const data = supporterQuestionData?.filter((item) => item.question_section_id === 20);
  const { watch } = useFormContext();
  const isOption = watch('supporter_question_vos.10');

  return (
    <Box p="40px" sx={{ backgroundColor: 'gray.90' }} mb="20px">
      {data?.map((question, index) => {
        return (
          <Box key={question.question_id}>
            {question.question_type === 'RADIO' && (
              <SupporterApplyQuestionChoice
                {...question}
                disabled={!question.required && !isOption?.question_answers['20']}
                required={!!question.required || !!isOption?.question_answers['20']}
                index={index + 10}
              />
            )}

            {question.question_type === 'CHECKBOX' && (
              <SupporterApplyQuestionCheckBox
                {...question}
                disabled={!question.required && !isOption?.question_answers['20']}
                required={!!question.required || !!isOption?.question_answers['20']}
                index={index + 10}
              />
            )}

            {question.question_type === 'TEXT' && question.required_option === 'PC' && (
              <SupporterApplyQuestionPcInfo
                {...question}
                disabled={!isOption?.question_answers['10']}
                required={!!question.required || !!isOption?.question_answers['10']}
                index={index + 10}
              />
            )}

            {question.question_type === 'TEXT' && question.required_option === 'Mobile' && (
              <SupporterApplyQuestionInputField
                {...question}
                disabled={!isOption?.question_answers['20']}
                required={!!isOption?.question_answers['20']}
                index={index + 10}
              />
            )}

            {question.required_option !== 'PCDETAIL' && data.length - 1 !== index && (
              <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
            )}
          </Box>
        );
      })}
    </Box>
  );
}
