import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { Ref, forwardRef, useState } from 'react';

import { GIcon, GIconProps } from '../GIcon';

export interface GIconButtonProps extends GIconProps {
  /** disable icon hover opacity effect. not include backgroundColor */
  disableHoverEffect?: boolean;
  /** hover시 backgroundColor */
  backgroundHoverColor?: string;
  ButtonProps?: ButtonBaseProps;
}

const GIconButton = forwardRef((props: GIconButtonProps, ref: Ref<HTMLButtonElement>) => {
  const [hover, setHover] = useState(false);
  const {
    disableHoverEffect,
    backgroundColor,
    backgroundHoverColor = backgroundColor,
    iconOpacity = 1,
    ButtonProps,
    ...others
  } = props;

  const opacity = disableHoverEffect ? iconOpacity : hover ? iconOpacity : iconOpacity * 0.5;

  return (
    <ButtonBase
      ref={ref}
      disabled={props.disabled}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...ButtonProps}
    >
      <GIcon
        {...others}
        backgroundColor={hover ? backgroundHoverColor : backgroundColor}
        iconOpacity={opacity}
      />
    </ButtonBase>
  );
});

export default GIconButton;
