import { Box } from '@mui/material';
import { QuerystringProvider } from 'context';

import AllClipsSection from './AllClipsSection';

const defaultQuery = { search_keyword: '', sort: 'createdAt', size: 15 };

export default function AllClipsContainer() {
  return (
    <Box pt="80px" pb="200px" width="1200px">
      <QuerystringProvider defaultQuery={defaultQuery}>
        <AllClipsSection />
      </QuerystringProvider>
    </Box>
  );
}
