import { Components } from '@mui/material';

const LoadingButtonTheme: Components = {
  MuiLoadingButton: {
    // 브라우저 자동 번역 시 loading 상태로 변경되면서 텍스트가 없어질 때 에러 발생하는 것을 막기 위해
    defaultProps: { translate: 'no' },
  },
};

export default LoadingButtonTheme;
