import { Box, ClickAwayListener, Stack, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { GPopper } from './comment/GPopper';

const GaugeBar = styled(Box)({
  width: '100%',
  height: '5px',
  borderRadius: '20px',
  background: 'linear-gradient(90deg, rgba(60,102,251,1) 0%, rgba(0,224,255,1) 100%)',
});

const CoverBar = styled(Box)<{ percentage: number }>(({ theme, percentage }) => ({
  position: 'absolute',
  height: '100%',
  right: 0,
  top: 0,
  left: `${percentage}%`,
  background: theme.palette.gray[50],
}));

interface Props {
  goal: number | null;
  count: number | null;
  rate?: number | null;
  haveReachingPopup?: boolean;
  isMobile?: boolean;
  onClick?: () => void;
}

export function ReviewGauge({ goal, count, haveReachingPopup, rate, isMobile, onClick }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [reviewEl, setReviewEl] = useState<HTMLElement | null>(null);
  const circleRef = useRef<HTMLDivElement | null>(null);
  const reviewRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const timerRef = useRef<any | null>(null);

  useEffect(() => {
    if (reviewRef?.current) {
      reviewRef.current?.click();
    }
  }, []);

  if (goal === null || count === null) {
    return null;
  }

  const percentage = rate ?? Math.min(100, Math.round((count / goal) * 100));

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (percentage >= 100) {
      event.stopPropagation();
      timerRef.current = setTimeout(() => {
        setAnchorEl(circleRef.current);
      }, 1_000);
    }
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setAnchorEl(null);
  };

  const handleReachingPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setReviewEl(target);
  };

  const handleReachingPopoverClose = () => {
    setReviewEl(null);
  };

  const handleClickOnMobile = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (typeof onClick === 'function') {
      onClick?.();
    } else {
      const target = event.currentTarget;

      if (!anchorEl) {
        setAnchorEl(target);
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
        setAnchorEl(null);
      }
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {haveReachingPopup && (
        <GPopper
          direction="right"
          open={Boolean(reviewEl)}
          anchorEl={reviewEl}
          hasCloseIcon
          handleClose={handleReachingPopoverClose}
          id="write-review"
          paperStyle={{ bottom: -2 }}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
          disablePortal
        >
          <Typography variant="button2" fontWeight={500}>
            Write reviews before reaching <br />
            <span style={{ color: theme.palette.info.main }}>150%</span> to earn G.Points!
          </Typography>
        </GPopper>
      )}
      <GPopper
        direction={haveReachingPopup && !isMobile ? 'center' : 'left'}
        open={open}
        anchorEl={anchorEl}
        paperStyle={{ right: 12, top: 1 }}
        id="target-review"
        disablePortal
      >
        <Typography variant="button2" fontWeight={500}>
          Achieved the studio's
          <br />
          target number of reviews!
        </Typography>
      </GPopper>
      <Stack gap="4px" sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '18px',
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
              }
              setAnchorEl(null);
            }}
          >
            <Box
              onMouseEnter={isMobile ? undefined : handlePopoverOpen}
              onMouseLeave={isMobile ? undefined : handlePopoverClose}
              onClick={isMobile && percentage >= 100 ? handleClickOnMobile : undefined}
              sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}
            >
              {percentage >= 100 && (
                <Box
                  sx={{
                    height: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  ref={circleRef}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <rect width="12" height="12" rx="6" fill="#34F353" />
                    <path d="M3 6L5 8L9 4" stroke="#121212" strokeWidth="1.5" />
                  </svg>
                </Box>
              )}
              <Typography variant="button2" color="text.primary">
                Goal of {goal} reviews
              </Typography>
            </Box>
          </ClickAwayListener>
          <Typography
            variant="button1"
            color="info.main"
            onClick={handleReachingPopoverOpen}
            sx={{ cursor: 'pointer' }}
            ref={reviewRef}
          >
            {rate}%
          </Typography>
        </Box>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <GaugeBar />
          <CoverBar percentage={percentage} />
        </Box>
      </Stack>
    </>
  );
}
