import { Typography, Paper } from '@mui/material';

import { useGetCountryAPI } from 'api/country/countryAPI';
import GameInfoStudioMembers, { StudioMemberData } from './GameInfoStudioMembers';
import { useGetDetailData } from 'views/detail/DetailProvider';

interface StudioDescriptionType {
  id: number;
  studio_id: number;
  language_code: string;
  description: string;
}

interface StudioType {
  id: number;
  studio_name: string;
  country_code: string;
  descriptions: StudioDescriptionType[];
  logo_id: number | null;
  logo_url: string | null;
  studio_member_list: StudioMemberData[];
}

export default function GameInfoStudioInfoPaper({
  studio_name,
  country_code,
  descriptions,
  studio_member_list,
}: StudioType) {
  const { selectLanguageCode } = useGetDetailData();
  const country = useGetCountryAPI(country_code);

  return (
    <Paper sx={{ padding: '40px 48px 48px' }}>
      <Typography variant="h5">{studio_name}</Typography>
      <Typography variant="subtitle3" color="text.secondary" paragraph marginTop="4px">
        {country_code && country?.name}
      </Typography>
      <Typography
        whiteSpace="pre-wrap"
        variant="body1"
        color="text.secondary"
        marginTop="20px"
        width="827px"
      >
        {descriptions?.find(
          (desc) => desc.language_code.toLowerCase() === selectLanguageCode.toLowerCase()
        )?.description ?? descriptions?.[0].description}
      </Typography>

      <GameInfoStudioMembers memberList={studio_member_list} />
    </Paper>
  );
}
