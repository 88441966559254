import { createModel } from '@rematch/core';

import { RootModel } from '.';

export const mute = createModel<RootModel>()({
  state: true,
  reducers: {
    toggleMute: (state: boolean) => {
      return !state;
    },
  },
});
