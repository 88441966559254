import { forwardRef, Ref } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';

const baseUrl = window.location.origin;

// 내부 path인 경우 앞에 baseUrl을 제거한 url 전달
const getInnerPath = (to: string) => (to.includes(baseUrl) ? to.split(baseUrl)[1] : to);

export interface LinkButtonProps extends Omit<ButtonProps<'a'>, 'component' | 'href'> {
  /** 이동할 link 주소 */
  to: string;
  /** inner, outer link type */
  innerLink?: boolean;
  /** `true`이면 새창으로 열기 `false`이면 기존 창에 열기 */
  newWindow?: boolean;
  /** 현재 history를 삭제하고 `to`의 정보로 대체할지 유무 */
  replace?: boolean;
  /** inner link인 경우 전송할 state 값 */
  state?: any;
}

const LinkButton = forwardRef((inProps: LinkButtonProps, ref: Ref<HTMLAnchorElement>) => {
  const {
    to,
    innerLink = !to.startsWith('http') || to.includes(baseUrl), // 설정하지 않으면 http로 시작하지 않거나 baseUrl이 포함되면 내부링크로 판단
    newWindow = !innerLink, // 설정하지 않은 경우 내부링크가 아니라면 무조건 새창으로
    replace,
    onClick,
    state,
    target = newWindow ? '_blank' : '_self',
    ...other
  } = inProps;

  // 에러 체크
  if (process.env.NODE_ENV !== 'production') {
    if (innerLink) {
      if (newWindow) {
        console.warn('innerLink와 newWindow를 같이 사용할 수 없습니다.');
      }

      if (to.startsWith('http') && !to.includes(baseUrl)) {
        console.error('to 속성이 내부 url이 아닙니다.', to);
      }
    }
  }

  const internalOnClick = useLinkClickHandler(getInnerPath(to), { replace, target, state });

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (onClick) onClick(event);

    if (!event.defaultPrevented) {
      event.preventDefault();
      // 내부 링크와 외부 링크 구분하여 동작
      innerLink && !newWindow ? internalOnClick(event) : window.open(to, target);
    }
  }

  return (
    <Button
      {...other}
      ref={ref}
      component="a"
      href={to}
      onClick={handleClick}
      rel="noopener noreferrer"
      target={target}
    />
  );
});

export default LinkButton;
