import { googleLogout } from '@react-oauth/google';
import { createModel } from '@rematch/core';
import * as Sentry from '@sentry/react';

import type { UniversityUserType } from 'types/UniversityType';
import { RootModel } from '.';

import profile from 'images/icons/profile_default_img.svg';

type LoginState = {
  name?: string;
  picture?: string;
  country_code: string | null;
  language_code?: string;
  access_token: string;
  email: string;
  filled: boolean | null; // 프로필 정보 완성 여부
  isLogin: boolean;
  user_id: number | null; // 유저 식별정보 (로그에 사용)
  identifier: string; // 암호화된 유저 식별정보
  agreed_terms: boolean | null; // 약관 동의 여부
  verified: boolean | null; // email verified 여부
  withdrawal_request: boolean;
  level: number;
  supporter: boolean;
  university: { type?: UniversityUserType };
};

export type LoginPayload = Omit<LoginState, 'isLogin'>;

// Sentry에 보낼 유저 데이터 설정
const setSentryUser = (loginData: Pick<LoginState, 'user_id' | 'email' | 'name'>) => {
  Sentry.setUser({
    id: loginData.user_id ?? undefined,
    email: loginData.email,
    username: loginData.name,
  });
};

// Sentry에 보낼 유저 데이터 제거
const clearSentryUser = () => Sentry.setUser(null);

const getLoginData = () => {
  const retData: LoginState = {
    name: '',
    picture: '',
    country_code: '',
    language_code: '',
    access_token: '',
    email: '',
    filled: false,
    isLogin: false,
    identifier: '',
    user_id: null,
    agreed_terms: false,
    verified: false,
    withdrawal_request: false,
    level: 1,
    supporter: false,
    university: {},
  };

  // 기존 로그인 정보가 있으면 가져온다.
  const jsonData = localStorage.getItem('login');
  if (jsonData) {
    const loginData: LoginState = JSON.parse(jsonData);
    // 로그인 데이터가 정상이 아니면 로그인 정보 제거 (Ex. null, undefined 등)
    if (!loginData) {
      clearSentryUser();
      localStorage.removeItem('login');
      return retData;
    }
    // 로그인한 상태의 유저 중 uuid (identifier) 속성이 없는 경우 로그인 정보 제거
    else if (!loginData.hasOwnProperty('identifier')) {
      clearSentryUser();
      localStorage.removeItem('login');
      return retData;
    }

    retData.name = loginData.name ?? '';
    retData.picture = loginData.picture ?? profile;
    retData.country_code = loginData.country_code ?? '';
    retData.language_code = loginData.language_code ?? '';
    retData.access_token = loginData.access_token ?? '';
    retData.email = loginData.email ?? '';
    retData.filled = loginData.filled ?? false;
    retData.isLogin = !!loginData.access_token?.length;
    retData.user_id = loginData.user_id ?? null;
    retData.identifier = loginData.identifier ?? '';
    retData.agreed_terms = loginData.agreed_terms ?? null; // 약관 동의 여부가 없는 경우를 null로 판별
    retData.verified = loginData.verified ?? false;
    retData.withdrawal_request = loginData.withdrawal_request ?? false;
    retData.level = loginData.level ?? 1;
    retData.supporter = loginData.supporter ?? false;
    retData.university = loginData.university ?? {};

    setSentryUser(loginData);
  }

  return retData;
};

export const login = createModel<RootModel>()({
  state: getLoginData(),
  reducers: {
    login: (state: LoginState, payload: LoginPayload) => {
      if (!payload) return state;

      setSentryUser(payload);

      localStorage.setItem('login', JSON.stringify(payload));
      return {
        ...payload,
        isLogin: true,
      };
    },
    logout: () => {
      clearSentryUser();

      localStorage.removeItem('login');
      // facebook 연결 중이면 로그아웃
      FB.getLoginStatus((response) => {
        if (response?.status === 'connected') {
          FB.logout();
        }
      });
      googleLogout();

      return {
        name: '',
        picture: '',
        country_code: '',
        language_code: '',
        access_token: '',
        email: '',
        filled: false,
        isLogin: false,
        identifier: '',
        user_id: null,
        agreed_terms: false,
        verified: false,
        section: 1,
        withdrawal_request: false,
        level: 1,
        supporter: false,
        university: {},
      };
    },
    updateLoginInfo: (
      state: LoginState,
      payload: Partial<Omit<LoginState, 'email' | 'isLogin'>>
    ) => {
      localStorage.setItem('login', JSON.stringify({ ...state, ...payload }));
      return {
        ...state,
        ...payload,
        isLogin: true,
      };
    },
  },
  effects: {
    // async asyncLogin() {
    //   console.log('login');
    //   await new Promise(resolve => setTimeout(resolve, 500));
    //   this.login();
    // },
    // async asyncLogout() {
    //   console.log('logout');
    //   await new Promise(resolve => setTimeout(resolve, 500));
    //   this.logout();
    // },
  },
});
