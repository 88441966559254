import { useSelector } from 'react-redux';
import { iRootState } from 'store';

import { IGStoreDigitalOrderFormData } from 'types/GStoreTypes';
import Order from './Order';

export default function OrderPageDigitalGames() {
  const { email } = useSelector((state: iRootState) => state.login);

  const defaultValues: IGStoreDigitalOrderFormData = {
    ship_to_email: email ?? '',
  };

  return <Order defaultValues={defaultValues} />;
}
