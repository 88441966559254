import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export const FloatingWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={({ palette: { gray } }) => ({
        position: 'sticky',
        top: '149px',
        zIndex: 999,
        background: gray[100],
        padding: '30px 0px 30px 30px',
        borderRadius: '5px',
        height: 'fit-content',
        float: 'inline-end',
      })}
    >
      {children}
    </Box>
  );
};
