import { GIconButton, GTab, GTabs } from '@ground/ui';
import { Box, styled } from '@mui/material';
import { useMemo } from 'react';

import { useBlogCategoryListApi } from 'api/blog/blogAPI';
import { useQueryDispatch, useQueryState } from 'context';
import type { AnnouncementListOptions } from 'types/AnnouncementTypes';
import { useLog } from 'utils/userLog';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '1200px',
  marginBottom: '40px',
});

export default function BlogListToolbar() {
  const { gxcTag } = useLog();
  const { changeQuery } = useQueryDispatch();

  const {
    search_announce_type,
    view_type = 'card',
    search_keyword,
    search_tag,
  } = useQueryState<AnnouncementListOptions>();

  const { data } = useBlogCategoryListApi('b2c');

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    changeQuery({ search_announce_type: newValue });
  };

  const blogCategoryListData = useMemo(
    () => (data?.data && Array.isArray(data?.data?.announce_type) ? data?.data?.announce_type : []),
    [data]
  );

  return search_keyword || search_tag ? null : (
    <Root>
      <GTabs
        id="blog-tab"
        value={search_announce_type}
        size="large"
        onChange={handleChange}
        aria-label="blog nav tabs"
        disableDivider
        indicatorHeight={0}
      >
        <GTab
          label="All"
          value="all"
          size="large"
          onClick={() =>
            gxcTag('click', {
              area: 'category',
              ui: 'tab',
              parameters: {
                tab_name: 'all',
              },
            })
          }
        />

        {blogCategoryListData.map((item) => (
          <GTab
            label={item.code_name}
            value={item.code}
            key={item.code_id}
            size="large"
            onClick={() =>
              gxcTag('click', {
                area: 'category',
                ui: 'tab',
                parameters: {
                  tab_name: item.code_name,
                },
              })
            }
          />
        ))}
      </GTabs>

      <Box display="flex" justifyContent="center" alignItems="flex-start" gap="8px">
        <GIconButton
          iconName="view_square"
          iconSize={24}
          onClick={() => {
            gxcTag('click', {
              area: 'view_mode',
              ui: 'card_view',
            });
            changeQuery({ view_type: 'card' });
          }}
          disableHoverEffect={view_type === 'card'}
        />

        <GIconButton
          iconName="view_line"
          iconSize={24}
          onClick={() => {
            gxcTag('click', {
              area: 'view_mode',
              ui: 'list_view',
            });
            changeQuery({ view_type: 'common' });
          }}
          disableHoverEffect={view_type === 'common'}
        />
      </Box>
    </Root>
  );
}
