import { Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteOptionType,
  AutocompleteProps,
} from '../atoms';

export type FormAutocompleteOption = AutocompleteOption;
export type FormAutocompleteOptionType = AutocompleteOptionType;

interface FormAutocompleteProps extends AutocompleteProps {
  name: string;
  rules?: RegisterOptions;
  /** input 상단의 label */
  label?: string;
}

export default function FormAutocomplete({
  defaultValue,
  label,
  name,
  rules = {},
  size = 'medium',
  onChange,
  onInputChange,
  ...props
}: FormAutocompleteProps) {
  const { control, setValue } = useFormContext();
  const defaultValueRef = useRef(defaultValue);

  // 수동으로 defaultValue 설정한 경우 기본 값을 설정해준다.
  useEffect(() => {
    defaultValueRef.current && setValue(name, defaultValueRef.current);
  }, [name, setValue]);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({
        field: { ref, onChange: onChangeValue, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <Stack rowGap="8px">
            {label && (
              <Typography
                component="label"
                color="text.secondary"
                variant={size === 'large' ? 'subtitle2' : 'body1'}
              >
                {label}
              </Typography>
            )}
            <Autocomplete
              {...field}
              onChange={(e, v, r, d) => {
                onChangeValue(v);
                onChange?.(e, v, r, d);
              }}
              onInputChange={(e, v, r) => {
                // freeSolo인 경우엔 사용자 입력도 값으로 인식
                if (props.freeSolo && v) onChangeValue(v);
                onInputChange?.(e, v, r);
              }}
              value={value}
              error={!!error}
              inputRef={ref}
              size={size}
              {...props}
            />

            {error?.message && (
              <Typography color="error" variant="body3">
                {error.message}
              </Typography>
            )}
          </Stack>
        );
      }}
    />
  );
}
