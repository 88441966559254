import { GIconButton } from '@ground/ui';
import { Box, Button, Dialog, dialogClasses, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { gameGuideList } from 'components';
import { Carousel, GuideCard } from 'elements';
import { iRootState } from 'store';
import { groundDiscordChannel } from 'utils/common';
import { useLog } from 'utils/userLog';

import discordLogo from 'images/banner/discord/discord_mobile_logo.png';
import welcomeBg from './welcome-mobile-bg.png';
import welcomeBg2x from './welcome-mobile-bg@2x.png';
import welcomeBg3x from './welcome-mobile-bg@3x.png';

export default function WelcomeMobileDialog() {
  const { gxcTag } = useLog();
  const { state } = useLocation();
  const welcome = (state as { welcome?: boolean })?.welcome;
  const [open, setOpen] = useState(!!welcome);
  const name = useSelector((state: iRootState) => state.login.name);

  useEffect(() => {
    gxcTag('view', { area: 'welcome_popup' });
  }, [gxcTag]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth={false}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      }}
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.gray[80],
          borderRadius: '5px',
          margin: '20px',
        },
      }}
      sx={{
        [`& .${dialogClasses.paperFullWidth}`]: {
          width: 'calc(100% - 40px)',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1,
        }}
      >
        <GIconButton
          iconName="close"
          iconSize={24}
          size={46}
          onClick={() => {
            setOpen(false);
            gxcTag('click', { area: 'welcome_popup', ui: 'close_x' });
          }}
        />
      </Box>
      {/* 상단 영역 */}
      <Box position="relative">
        <img
          src={welcomeBg}
          srcSet={`${welcomeBg}, ${welcomeBg2x} 2x, ${welcomeBg3x} 3x`}
          style={{
            backgroundColor: 'black',
            display: 'block',
            filter: 'brightness(0.3)',
            objectFit: 'cover',
          }}
          width="100%"
          height={206}
          alt="welcome background"
        />
        <Stack
          sx={{
            position: 'absolute',
            alignItems: 'center',
            top: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            padding: '60px 28px 32px',
          }}
        >
          <Typography color="text.primary" variant="h5">
            Welcome to G.Round
          </Typography>
          <Typography color="text.secondary" variant="subtitle2" mt="12px">
            Hi,{' '}
            <Typography component="span" color="text.primary">
              {name || 'unknown'}
            </Typography>
            !<br />
            You are ready to test games! Let's install the launcher on your PC so you can start
            playing your free games!
          </Typography>
        </Stack>
      </Box>
      {/* 하단 영역 */}
      <Box bgcolor="gray.80" padding="32px 0">
        <Carousel name="welcome-dialog" width={260} hasPagination bulletWidth={24}>
          {gameGuideList.map((guide, index) => (
            <SwiperSlide key={index}>
              <Typography align="center" color="text.primary" mb="20px" variant="subtitle1">
                Playtest games and <br /> help developers!
              </Typography>
              <GuideCard {...guide} isMobile />
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <Stack alignItems="center" textAlign="center" width="260px" mt="8px">
              <img src={discordLogo} alt="discord" />
              <Typography color="text.primary" mt="24px" variant="subtitle1">
                Join Our Gaming Community
              </Typography>
              <Typography color="text.secondary" mt="12px" variant="body2">
                Join the G.Round Discord now
                <br /> to learn more about our games!
              </Typography>
              <Button
                href={groundDiscordChannel}
                size="small"
                target="_blank"
                rel="noopener noreferrer"
                variant="ghost"
                sx={{ marginTop: '24px' }}
                onClick={() => gxcTag('click', { area: 'welcome_popup', ui: 'discord' })}
              >
                Go To Discord Server
              </Button>
            </Stack>
          </SwiperSlide>
        </Carousel>
      </Box>
    </Dialog>
  );
}
