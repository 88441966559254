import { Box, Stack } from '@mui/material';

import { ClassSelectForm, Fullname, MajorForm, StudentId } from 'components/university';

export default function StudentForm() {
  return (
    <Stack gap="32px">
      <Box display="flex" gap="60px">
        <Fullname />
        <StudentId />
      </Box>
      <Box display="flex" gap="60px">
        <MajorForm />
        <ClassSelectForm />
      </Box>
    </Stack>
  );
}
