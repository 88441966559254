import { VerificationInput } from '@ground/ui';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCheckVerificationCodeAPI, useSendEmailVerificationCodeAPI } from 'api/email/emailAPI';
import { iRootState } from 'store';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

export interface SignupVerificationContentProps {
  isMobile?: boolean;
  type?: 'page' | 'dialog'; // 페이지에서 사용될 때와 다이얼로그에서 사용될 때 스타일 구분
  onVerificationSuccess?: () => void;
}

export default function SignupVerificationContent({
  isMobile,
  type = 'page',
  onVerificationSuccess,
}: SignupVerificationContentProps) {
  const { email } = useSelector((state: iRootState) => state.login);
  const { isLoading, fetch } = useSendEmailVerificationCodeAPI();
  const { isLoading: loadingVerification, fetch: fetchVerification } =
    useCheckVerificationCodeAPI();
  const [code, setCode] = useState<Array<string>>([]);
  const [error, setError] = useState<string | null>(null);
  const [resendAvailable, setResendAvailable] = useState(false);
  const { gxcTag } = useLog();

  // 30초가 지난다음 resend code 가능
  useEffect(() => {
    const timerId = window.setTimeout(() => setResendAvailable(true), 30000);
    return () => window.clearTimeout(timerId);
  }, []);

  const handleClickResend = async () => {
    gxcTag(
      'click',
      type === 'page'
        ? { area: 'detail', ui: 'resend_email' }
        : { area: 'code_verification_popup', ui: 'resend' }
    );
    // 코드 발송 후 30초가 안지나서 재발송 불가능한 경우
    if (!resendAvailable) {
      setError('Verification code is already sent. Please try again later.');
      return;
    }

    setResendAvailable(false);
    window.setTimeout(() => setResendAvailable(true), 30000);
    // 혹시 오류 발생하면 clearTimeout 추가

    const response = await fetch();
    if (response.status === 200 && response.data.result_code === 'OK') {
      // 이메일 발송 성공
      setError(null);
    } else {
      setError(
        'An error occurred while sending verification email or email address does not exist.'
      );
    }
    eventTracker('signup_verification_resend');
    twttr?.conversion.trackPid('o91dp', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/5nEKCMudsMQDEI29kb8C' });
  };

  const handleVerification = async (code: Array<string>) => {
    gxcTag('click', {
      area: type === 'page' ? 'detail' : 'code_verification_popup',
      ui: 'continue',
    });
    const verificationCode = code.join('');
    if (verificationCode.length !== 6) {
      setError('Please enter a vaild code.');
      return;
    }

    const response = await fetchVerification({ code: code.join('') });
    if (response.status === 200) {
      if (response.data.result_code === 'OK') {
        setError(null);
        onVerificationSuccess?.();
      } else if (response.data.result_code === '405') {
        //시도횟수(현재10)초과
        setError(
          'You have exceeded the limit of verification attempts. Please request a new verification code.'
        );
      } else if (response.data.result_code === '406') {
        // 코드가 잘못되었을 때
        setError('Please enter a vaild code.');
      } else if (response.data.result_code === '407') {
        // 이메일로 코드가 발송되지 않은 경우
        setError('The verification period has expired.');
      } else {
        setError(`${response.data?.result_code} error`);
      }
    }
  };

  return (
    <>
      <Typography
        align={type === 'page' ? 'center' : 'left'}
        color="text.secondary"
        variant="subtitle2"
      >
        An email with a verification code was just sent to
        <Box color="text.primary" component="span">
          {' '}
          {email ? email : 'sample@gameround.co'}
        </Box>
        . Please check your email then enter the verification code below.
        <br />
        (The verification code is valid for 1 hour)
      </Typography>

      <Divider flexItem sx={{ mt: '40px', mb: '20px' }} />

      <Typography color="text.primary" variant="body1" mb="20px">
        Verification Code
      </Typography>

      <VerificationInput
        error={!!error}
        onChangeValues={(values) => setCode(values)}
        onComplete={(values) => handleVerification(values)}
      />
      <Typography color="error.main" variant="button2" sx={{ minHeight: '16px', marginY: '12px' }}>
        {error}
      </Typography>
      <Divider flexItem sx={{ mb: '20px' }} />

      <Typography color="text.secondary2" variant="body3">
        Didn’t receive the email? Check your spam folder.
      </Typography>

      <LoadingButton
        color="light"
        loading={isLoading}
        fullWidth={isMobile}
        onClick={handleClickResend}
        size="large"
        sx={{
          lineHeight: '20px',
          marginTop: '12px',
          padding: 0,
          textDecoration: 'underline',
          ':hover': { textDecoration: 'underline' },
        }}
        variant="text"
      >
        Resend Verification Email
      </LoadingButton>

      <LoadingButton
        disabled={code.join('').length !== 6}
        loading={loadingVerification}
        fullWidth
        onClick={() => handleVerification(code)}
        size={isMobile ? 'largest' : 'large'}
        sx={{ marginTop: '40px' }}
        variant="contained"
      >
        Continue
      </LoadingButton>
    </>
  );
}
