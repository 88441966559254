import { numberWithCommas } from '@ground/tools';
import { ButtonBase, Typography } from '@mui/material';

interface Props {
  onClick: () => void;
  replyCount: number;
}

export default function ReplyButton({ onClick, replyCount }: Props) {
  return (
    <ButtonBase onClick={onClick}>
      <Typography color="text.secondary" variant="body3">
        Reply ({numberWithCommas(replyCount ?? 0)})
      </Typography>
    </ButtonBase>
  );
}
