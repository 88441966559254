import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPublicReviewDashboardAPI } from 'api/comment/commentAPI';
import { GameStatusType } from 'types/GameCommonTypes';
import { useGetDetailData } from 'views/detail/DetailProvider';

import GPDistributionButton from './GPDistributionButton';

interface Props {
  mutateReviewList: () => void;
}

export default function PointIssuance({ mutateReviewList }: Props) {
  const { id: gameId = '' } = useParams<{ id: string }>();
  const { permissionData, data } = useGetDetailData();
  const hasGPIssuePermission = permissionData?.data?.issue_point_for_picked_game_review;
  const { data: reviewData, mutate } = useGetPublicReviewDashboardAPI(gameId);

  const isAlreadyPaid = reviewData?.data?.is_paid;

  const refetchPickedCountAndListHandler = useCallback(() => {
    mutateReviewList();
    mutate();
  }, [mutate, mutateReviewList]);

  return data?.state === GameStatusType.CLOSED ? (
    <GPDistributionButton
      gameId={gameId}
      getPickedCountCallback={refetchPickedCountAndListHandler}
      disabled={!hasGPIssuePermission || isAlreadyPaid}
      additionalCount={reviewData?.data?.additional_amount}
      pickedCount={reviewData?.data.picked_count}
      pickableCount={reviewData?.data.pick_amount ?? 20} // 20 as default
    />
  ) : null;
}
