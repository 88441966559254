import { ReplyPost, ReplyPostFormData } from '@ground/ui';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useReplyPostInGameDetailAPI } from 'api/comment/commentAPI';
import { useDialog, useError, useSignupDialog } from 'hooks';
import { iRootState } from 'store';

interface Props {
  gameId: number | string;
  reviewId: number | string;
  reload?: () => void;
}

export default function ReplyPostContainer({ gameId, reviewId, reload }: Props) {
  const { showDialog } = useDialog();
  const { showSignupDialog } = useSignupDialog();
  const { showError } = useError();
  const { isLogin, picture } = useSelector((state: iRootState) => state.login);
  const [inputMode, setInputMode] = useState(false); // comment text 입력모드
  const { isLoading: isReplyPostLoading, fetch: postReplyFetch } = useReplyPostInGameDetailAPI(
    gameId,
    reviewId
  );

  const onSubmit = async (
    { comment }: ReplyPostFormData,
    { reset }: UseFormReturn<ReplyPostFormData>
  ) => {
    if (comment.trim().length < 10) {
      showDialog({
        title: 'Leave a Sufficient Review',
        body: 'The studio wants to hear your feedback! Please leave a review that is at least 10 characters long. They wish to improve their game with your help!',
        primaryButton: { text: 'OK' },
      });
      return;
    }

    const response = await postReplyFetch({
      text: comment.trim(),
    });
    if (response.status === 200 && response.data.result?.code === 0) {
      reset();
      setInputMode(false);
      reload?.();
    } else {
      showError(response, 'Comment Post Failed');
    }
  };

  const handleFocusInput = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (isLogin) {
        setInputMode(true);
      } else {
        // 비로그인 시
        event.currentTarget.blur();
        showSignupDialog();
      }
    },
    [isLogin, showSignupDialog]
  );

  const handleBlurInput = () => {
    setInputMode(false);
  };

  return (
    <ReplyPost
      onSubmit={onSubmit}
      onBlurInput={handleBlurInput}
      onFocusInput={handleFocusInput}
      loadingReplyPost={isReplyPostLoading}
      inputMode={inputMode}
      userAvatarUrl={picture}
      isLogin={true}
    />
  );
}
