import type { FormAutocompleteOption } from '@ground/ui';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useGetProfileAPI, type ProfileFormData } from 'api/profile/profileAPI';
import { useUpdateSignDataWithToken } from 'api/sign/signAPI';
import SignupProfileForm from 'views/sign/profile/SignupProfileForm';

interface Props {
  isMobile?: boolean;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
}

export default function ProfileDialog({ isMobile, onSubmitSuccess, onSubmitError }: Props) {
  const { isLoading: loadingCountry, data: countries } = useGetCountriesAPI();
  const { isLoading, data } = useGetProfileAPI();
  const { updateSignData } = useUpdateSignDataWithToken();

  const countryList: FormAutocompleteOption[] = useMemo(
    () =>
      countries?.map((country) => ({
        name: country.name,
        value: country.code,
      })) ?? [],
    [countries]
  );

  const profileData = data?.data;
  const userCountryCode = profileData?.country_code ?? profileData?.ciso;

  const defaultValues: Partial<ProfileFormData> = useMemo(
    () => ({
      name: profileData?.name,
      birthday:
        profileData?.birth_year && profileData?.birth_month && profileData?.birth_day
          ? new Date(
              `${profileData.birth_year}-${profileData.birth_month}-${profileData.birth_day}`
            )
          : null,
      country: countryList.find((country) => country.value === userCountryCode),
      sex: profileData?.sex ?? undefined,
    }),
    [
      countryList,
      profileData?.birth_day,
      profileData?.birth_month,
      profileData?.birth_year,
      profileData?.name,
      profileData?.sex,
      userCountryCode,
    ]
  );

  return (
    <Stack>
      <Typography color="text.secondary" variant="body2" mb="40px">
        Please complete your profile in order to have access to all of G.Round's services.
      </Typography>
      {isLoading || loadingCountry ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 455 }}>
          <CircularProgress color="primary" size={64} />
        </Box>
      ) : (
        <SignupProfileForm
          defaultValues={defaultValues}
          isMobile={isMobile}
          submitText="Complete Profile"
          type="dialog"
          onSubmitSuccess={async (responseData) => {
            // 유저 정보 갱신
            await updateSignData(responseData.access_token);
            onSubmitSuccess?.();
          }}
          onSubmitError={(response) => {
            console.log('error', response);
            onSubmitError?.();
          }}
        />
      )}
    </Stack>
  );
}
