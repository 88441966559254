import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useLog } from 'utils/userLog';
import { useUpdateSignDataWithToken } from 'api/sign/signAPI';
import {
  useGetUniversityInfoAPI,
  useGetUniversityMemberInfoAPI,
} from 'api/university/universityAPI';
import { AxiosResponse } from 'axios';
import { useError } from 'hooks';
import { iRootState } from 'store';
import type { EducatorFormData, StudentFormData } from 'types/UniversityType';
import AuthRoute from 'views/main/AuthRoute';
import { SignLayout } from '../component';
import { useSignupData } from '../signup/SignupProvider';
import SignupEducatorForm from './SignupEducatorForm';
import SignupStudentForm from './SignupStudentForm';

export default function SignupUniversity() {
  const [searchParams] = useSearchParams();
  const universityKey = searchParams.get('university');

  const { gxcTag } = useLog();
  const navigate = useNavigate();
  const { showError } = useError();
  const { isMobile } = useSignupData();
  const { updateSignData } = useUpdateSignDataWithToken();
  const { access_token, university } = useSelector((state: iRootState) => state.login);

  const { isLoading: loadingUniversityInfo, data: universityInfo } =
    useGetUniversityInfoAPI(universityKey);
  // 멤버 정도 가져오기(학생인 경우 데이터가 없는데 오류 체크)
  const { isLoading, data } = useGetUniversityMemberInfoAPI(university.type);

  const educatorDefaultValues: EducatorFormData = {
    name: data?.data?.educator?.educator_fullname ?? '',
    major: data?.data?.major_list?.map((major) => major) ?? [{ major_id: null, major_name: '' }],
    class: data?.data?.class_list?.map((classItem) => classItem) ?? [
      { class_id: null, class_name: '' },
    ],
  };

  const studentDefaultValues: StudentFormData = {
    name: data?.data?.student?.student_fullname ?? '',
    student_number: data?.data?.student?.student_number ?? '',
    major: data?.data?.student?.student_majors?.map((major) => ({
      major_id: null, // 학생의 전공엔 id가 필요 없음
      major_name: major,
    })) ?? [{ major_id: null, major_name: '' }],
    class: data?.data?.class_list?.map((classItem) => ({ class_id: classItem.class_id })) ?? [
      { class_id: null },
    ],
  };

  const onSubmitSuccess = async () => {
    // 유저 정보의 university 값 갱신을 위해
    await updateSignData(access_token);

    gxcTag('view', { area: 'welcome_popup', ui: 'N/A' });

    // 홈으로 보내고 웰컴 다이얼로그 띄우기
    navigate('/', { replace: true, state: { welcome: true } });
  };

  const onSubmitError = (response: AxiosResponse) => {
    showError(response);
  };

  return (
    <AuthRoute to="/signup">
      {loadingUniversityInfo ? (
        <Box sx={{ display: 'flex', alignItems: 'center', height: 495 }}>
          <CircularProgress color="primary" size={64} />
        </Box>
      ) : universityInfo?.data?.university_id ? (
        <SignLayout isMobile={isMobile} title="Personal Details">
          <Box textAlign="center" mb="40px">
            {!isMobile && (
              <Typography color="text.primary" mb="8px" variant="h3">
                University Details
              </Typography>
            )}
            <Typography color="text.secondary" mb="16px" variant="body3">
              {universityInfo?.data.university_name}
            </Typography>
            <Typography color="text.secondary" variant="subtitle2">
              You are an {university.type === 'educator' ? 'Educator' : 'Student'}! Please enter
              your information.
            </Typography>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', height: 495 }}>
              <CircularProgress color="primary" size={64} />
            </Box>
          ) : university.type === 'educator' ? (
            <SignupEducatorForm
              defaultValues={educatorDefaultValues}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitError={onSubmitError}
            />
          ) : university.type === 'student' ? (
            // 이미 학생이면 정보 등록할 필요 없음
            <Navigate to="/" replace />
          ) : (
            <SignupStudentForm
              universityKey={universityKey}
              universityId={universityInfo?.data?.university_id}
              defaultValues={studentDefaultValues}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitError={onSubmitError}
            />
          )}
        </SignLayout>
      ) : (
        <Navigate to="/404" replace />
      )}
    </AuthRoute>
  );
}
