import { PageLayout } from 'components';
import EarnGPoints from './EarnGPoints';
import GStoreBanner from './GStoreBanner';
import UseGPoints from './UseGPoints';

export default function GStoreMain() {
  return (
    <>
      <PageLayout>
        <GStoreBanner />
        <UseGPoints />
      </PageLayout>

      <EarnGPoints />
    </>
  );
}
