import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ModalDialog } from 'elements';
import ReviewDialogBodyV2 from './ReviewDialogBodyV2';

interface Props {
  gameId: number;
  reviewId: number;
  testType: 'public' | 'private';
  isAdmin: boolean;
}

export default function ReviewDialog({ gameId, reviewId, testType, isAdmin }: Props) {
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(pathname); // 닫힐 때 query의 review_id 제거하기 위해
  };

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      width={900}
      backgroundColor={palette.gray[90]}
      title="Review"
      body={
        <ReviewDialogBodyV2
          gameId={gameId}
          reviewId={reviewId}
          testType={testType}
          isAdmin={isAdmin}
          setOpen={setOpen}
        />
      }
    />
  );
}
