import { Box, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  content?: string | string[];
  hasCapitalLetter?: boolean;
  hasNoFullHeight?: boolean;
}

export function SectionBox({ title, content, hasCapitalLetter, hasNoFullHeight }: Props) {
  return (
    <Box sx={{ flex: hasNoFullHeight ? undefined : 1 }}>
      <Typography variant="body3" color="text.secondary" mb="8px">
        {title}
      </Typography>
      {Array.isArray(content) ? (
        <Stack gap="8px">
          {content.map((text, index) => (
            <Typography
              key={index}
              variant="body1"
              color="text.primary"
              sx={{ textTransform: hasCapitalLetter ? 'capitalize' : undefined }}
            >
              {text}
            </Typography>
          ))}
        </Stack>
      ) : (
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ textTransform: hasCapitalLetter ? 'capitalize' : undefined }}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
}
