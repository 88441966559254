import { GIconButton } from '@ground/ui';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Swiper as SwiperClass,
  SwiperOptions,
} from 'swiper';
import { Swiper, SwiperProps } from 'swiper/react';
import { SwiperModule } from 'swiper/types';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade'; // fade effect
import 'swiper/css/navigation'; // Navigation module
import 'swiper/css/pagination'; // Pagination module

interface StyleProps {
  width: number;
  hasPagination?: boolean;
  fullWidth?: boolean;
  bulletWidth: number;
  paginationPosition?: 'INSIDE' | 'OUTSIDE';
}

interface NavigationStyleProps {
  width: number;
  hasPagination?: boolean;
  navigationPositionTop?: number;
  navigationPosition?: 'TOP' | 'CENTER';
  navigationCustom?: boolean;
  navBtnIconWidth?: number;
  navBtnRowPosition?: number;
  paginationPosition?: 'INSIDE' | 'OUTSIDE';
}

const Root = styled('div')(() => ({
  position: 'relative',
  width: 'inherit',
}));

const SwiperRoot = styled(Swiper, {
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'hasPagination' &&
    prop !== 'fullWidth' &&
    prop !== 'bulletWidth' &&
    prop !== 'paginationPosition',
})<StyleProps>(({ theme, hasPagination, width, fullWidth, bulletWidth, paginationPosition }) => ({
  width: fullWidth ? '100%' : width,
  ...(fullWidth && { minWidth: width }),
  height: 'auto',
  ...(hasPagination && paginationPosition === 'OUTSIDE' && { paddingBottom: 45 }),

  '& .swiper-pagination': {
    height: 17,
    bottom: paginationPosition === 'INSIDE' ? 12 : 0,
  },
  '& .swiper-pagination-bullet': {
    width: bulletWidth,
    height: 17,
    paddingTop: 8,
    paddingBottom: 8,
    opacity: paginationPosition === 'INSIDE' ? 1 : 0.6,
    backgroundColor: 'transparent',
    borderRadius: 0,
    margin: '0 2px !important',

    '& .swiper-pagination-content': {
      width: bulletWidth,
      height: 1,
      transition: 'width 300ms, background 300ms',
      backgroundColor: theme.palette.gray[20],
    },
  },

  '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
    opacity: 1,

    '& .swiper-pagination-content': {
      backgroundColor: theme.palette.base.dimWhite,
    },
  },
  '& .swiper-slide-active': {
    transition: `opacity 500ms ${theme.transitions.easing.easeInOut}`,
  },
  '& .swiper-slide-next, .swiper-slide-prev': {
    opacity: fullWidth ? 0.5 : 1,
  },
}));

const NavContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'hasPagination' &&
    prop !== 'navigationPosition' &&
    prop !== 'navigationPositionTop' &&
    prop !== 'navigationCustom' &&
    prop !== 'navBtnIconWidth' &&
    prop !== 'navBtnRowPosition' &&
    prop !== 'paginationPosition',
})<NavigationStyleProps>(
  ({
    width,
    hasPagination,
    navigationPosition,
    navigationPositionTop,
    navigationCustom,
    navBtnIconWidth,
    navBtnRowPosition,
    paginationPosition,
    theme,
  }) => ({
    position: 'absolute',
    top: navigationPositionTop
      ? navigationPositionTop
      : navigationPosition === 'TOP'
      ? -36
      : hasPagination && paginationPosition === 'OUTSIDE'
      ? 'calc(50% - 16.5px)'
      : '50%',
    left: navigationPosition === 'TOP' ? undefined : 0,
    right: 0,
    zIndex: 2,
    width: navigationPosition === 'TOP' ? 72 : navigationCustom ? '100%' : width,
    margin: 'auto',

    '& .nav-btn': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      width: navBtnIconWidth ?? 'initial',
      height: navBtnIconWidth ?? 'initial',
    },

    '& .prev-nav-btn': {
      left: navigationPosition === 'TOP' ? 0 : navBtnRowPosition,
    },

    '& .next-nav-btn': {
      right: navigationPosition === 'TOP' ? 0 : navBtnRowPosition,
    },
  })
);

interface CarouselProps extends SwiperProps {
  /** navigation 버튼 컨테이너의 가로폭에 대한 수정 유무 */
  navigationCustom?: boolean;
  /** navigation 버튼의 가로 길이 */
  navBtnIconWidth?: number;
  /** navigation 버튼의 절대 위치 */
  navBtnRowPosition?: number;
  /** navigation 버튼 아이콘 모양 */
  navBtnIconType?: 'circle' | 'arrow';
  /** navigation 버튼을 갖고 있는지 유무 */
  hasNavigation?: boolean;
  /** pagination 버튼을 갖고 있는지 유무 */
  hasPagination?: boolean;
  /** 메인 배너와 같이 가로로 확정된 형태인지 유무 - navigation값은 false여야 한다. */
  fullWidth?: boolean;
  /** 배너의 가로 길이 */
  width: number;
  /** SwiperSlide 컴포넌트로 감싸진 Element[] 혹은 JSX.Element[] 여야 한다 */
  // children?: Element[] | JSX.Element[];
  /** 네비게이션 버튼이 중복되는 문제 때문에 입력해주어야 한다. */
  name: string;
  /** 네비게이션이 중간 높이로 되어있는데, 임의로 변경해주고 싶을 경우 입력한다; 단위 px */
  navigationPositionTop?: number;
  /** bullet 너비를 조정하고 싶을 경우 입력한다; px */
  bulletWidth?: number;
  /** 네비게이션이 위치를 설정한다. 기본값 : CENTER; OnGoingSlider 참조 */
  navigationPosition?: 'TOP' | 'CENTER';
  /** pagination 위치를 설정한다. 기본값 : OUTSIDE; HomeBanner 참조 */
  paginationPosition?: 'INSIDE' | 'OUTSIDE';
  /** prevNavigationButton 컴포넌트를 커스텀마이징 하고자 할때 입력한다 */
  prevNavigationButtonComponent?: React.ReactNode;
  /** nextNavigationButton 컴포넌트를 커스텀마이징 하고자 할때 입력한다 */
  nextNavigationButtonComponent?: React.ReactNode;
  swiperOption?: SwiperOptions;
  /** onChangeIndex 상위 컴포넌트에 슬라이드의 현재 인덱스 값을 전파하고자 할때 사용하는 함수 */
  onChangeIndex?: (index: number) => void;
  /** Get Swiper instance */
  onSwiper?: (swiper: SwiperClass) => void;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
}

const paginationOption = {
  clickable: true,
  bulletElement: 'div',
  renderBullet: function () {
    return (
      '<div class="swiper-pagination-bullet">' +
      '<div class="swiper-pagination-content"></div>' +
      '</div>'
    );
  },
};

export default function Carousel({
  navigationCustom,
  navBtnIconWidth,
  navBtnRowPosition = -60,
  navBtnIconType = 'circle',
  hasNavigation,
  hasPagination,
  fullWidth,
  width,
  children,
  name,
  swiperOption,
  navigationPositionTop,
  navigationPosition = 'CENTER',
  paginationPosition = 'OUTSIDE',
  bulletWidth = 64,
  prevNavigationButtonComponent,
  nextNavigationButtonComponent,
  effect,
  onChangeIndex,
  onSwiper,
  onClickLeftArrow,
  onClickRightArrow,
  ...props
}: CarouselProps) {
  const swiperParams: SwiperOptions = useMemo(
    () => ({
      // 기본 swiper 에서 슬라이딩에 필요한 슬라이드가 부족하면 navigation buttons 를 숨기는 옵션. 기본값 true이나 커스텀한 현재 컴포넌트에서는 동작하지 않음.
      // watchOverflow: true,
      pagination: hasPagination && children ? paginationOption : false,
      navigation: { prevEl: `.prev-${name}`, nextEl: `.next-${name}` },
      ...swiperOption,
    }),
    [children, hasPagination, name, swiperOption]
  );
  const [isBeginning, setIsBeginning] = useState(swiperParams?.initialSlide ? false : true);
  const [isEnd, setIsEnd] = useState(false);

  const prevIconDisabled = !swiperOption?.loop && isBeginning;
  const nextIconDisabled = !swiperOption?.loop && isEnd;

  const getSwiperModule = useCallback(() => {
    let initialModule: SwiperModule[] = [];

    if (effect === 'fade') {
      initialModule = [...initialModule, EffectFade];
    }
    if (hasPagination) {
      initialModule = [...initialModule, Pagination];
    }
    if (hasNavigation) {
      initialModule = [...initialModule, Navigation];
    }
    if (swiperOption && swiperOption?.autoplay) {
      initialModule = [...initialModule, Autoplay];
    }

    return initialModule;
  }, [effect, hasNavigation, hasPagination, swiperOption]);

  // circle nav 아이콘인지 아닌지에 따라 구분
  const isNavCircleIcon = navBtnIconType === 'circle';

  return (
    <Root>
      {hasNavigation && children && Array.isArray(children) && children.length > 1 ? (
        <NavContainer
          width={width}
          hasPagination={hasPagination}
          navigationPositionTop={navigationPositionTop}
          navigationPosition={navigationPosition}
          navigationCustom={navigationCustom}
          navBtnIconWidth={navBtnIconWidth}
          navBtnRowPosition={navBtnRowPosition}
          paginationPosition={paginationPosition}
        >
          {prevNavigationButtonComponent ? (
            <div className={clsx([`prev-${name}`])} onClick={onClickLeftArrow}>
              {prevNavigationButtonComponent}
            </div>
          ) : (
            <GIconButton
              ButtonProps={{ className: `nav-btn prev-nav-btn prev-${name}` }}
              iconName="arrow_left"
              iconSize={isNavCircleIcon ? 16 : 48}
              size={isNavCircleIcon ? 32 : 48}
              variant={isNavCircleIcon ? 'circle' : undefined}
              disabled={prevIconDisabled}
              onClick={onClickLeftArrow}
              backgroundColor={isNavCircleIcon ? 'gray.60' : undefined}
            />
          )}
          {nextNavigationButtonComponent ? (
            <div className={clsx([`next-${name}`])} onClick={onClickRightArrow}>
              {nextNavigationButtonComponent}
            </div>
          ) : (
            <GIconButton
              ButtonProps={{ className: `nav-btn next-nav-btn next-${name}` }}
              iconName="arrow_right"
              iconSize={isNavCircleIcon ? 16 : 48}
              size={isNavCircleIcon ? 32 : 48}
              variant={isNavCircleIcon ? 'circle' : undefined}
              disabled={nextIconDisabled}
              onClick={onClickRightArrow}
              backgroundColor={isNavCircleIcon ? 'gray.60' : undefined}
            />
          )}
        </NavContainer>
      ) : null}

      <SwiperRoot
        {...swiperParams}
        width={width}
        modules={getSwiperModule()}
        effect={effect}
        hasPagination={hasPagination}
        paginationPosition={paginationPosition}
        fullWidth={fullWidth}
        bulletWidth={bulletWidth}
        onSwiper={onSwiper}
        onSlideChange={(swiper) => {
          onChangeIndex && onChangeIndex(swiper.realIndex);
          swiper.isBeginning ? setIsBeginning(true) : setIsBeginning(false);
          swiper.isEnd ? setIsEnd(true) : setIsEnd(false);
        }}
        {...props}
      >
        {children}
      </SwiperRoot>
    </Root>
  );
}
