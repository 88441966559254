import { CssBaseline, GlobalStyles } from '@mui/material';
import { differenceInDays } from 'date-fns';
import queryString from 'query-string';
import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { useConstructionAPI } from 'api/construction/constructionAPI';
import { DialogContextProvider, LoadingContextProvider } from 'hooks';
import type { Dispatch, iRootState } from 'store';
import { LogSentry, restrictedLog, useLog } from 'utils/userLog';
import Construction from 'views/construction/Construction';
import AppContainer from './AppContainer';
import FloatingDiscordBanner from './FloatingDiscordBanner';
import MobileAppContainer from './MobileAppContainer';
import LauncherAuth from './launcher/LauncherAuth';
import Auth from './launcher/auth/Auth';
import { LoadingPage } from './main/LoadingPage';
import { Construction as MobileConstruction } from './mobile';

const initGlobalStyles = <GlobalStyles styles={{ a: { textDecoration: 'none' } }} />;

export default function App() {
  const { pathname, search } = useLocation();
  const { referralcode } = queryString.parse(search ?? '');
  const { data: siteStateNowData, isLoading } = useConstructionAPI();
  const { isDesktopTransformed } = useSelector((state: iRootState) => state.mobile);
  const dispatch = useDispatch<Dispatch>();
  // devabout, announcement는 mobile에서도 PC 페이지로 보여준다.
  const devaboutMatch = useMatch('devabout');
  const announcementMatch = useMatch('announcement/*');
  const { gxcTag } = useLog();

  const isMobilePageToDesktop = !!devaboutMatch || !!announcementMatch;

  const isMobileView = isMobileOnly && sessionStorage.getItem('isViewDesktop') !== 'true';

  useEffect(() => {
    if (isMobilePageToDesktop) {
      dispatch.mobile.transformDesktopView();
    }
  }, [dispatch.mobile, isMobilePageToDesktop]);

  useEffect(() => {
    gtag('set', 'page_path', pathname + search);
    gtag('event', 'page_view', { send_to: 'UA-161956783-1' });
  }, [pathname, search]);

  useEffect(() => {
    // viewPort를 모바일 유무에 따라 달리 설정해주는 hook 이다. viewport 태그가 index.html에 있어야 작동한다.
    const viewport = document.querySelector('meta[name=viewport]');

    if (isMobileView && viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1');
    } else if (!isMobileView && viewport) {
      viewport.setAttribute('content', 'width=1320, maximum-scale=1, user-scalable=no');
    }
  }, [isMobileView]);

  useEffect(() => {
    // popup view 로그를 수동으로 수집하기 위한 처리.
    if (restrictedLog(search)) {
      return;
    }
    // Url 기반으로 GXC View Log 수집
    gxcTag('view');

    /**
     * eslint-disabled 처리한 이유
     * gxcTag를 포함시키는 경우, Log API가 호출된 후 gxcTag가 호출되어 로그가 한번 더 쌓이는 이슈가 있다.
     * @todo API 호출 후 적재한 로그 데이터가 삭제되는데, 이때 log value가 변하면서 gxcTag가 재정의 되는 듯하다. 해당 이슈 해결 필요.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  useEffect(() => {
    Object.entries({ ...localStorage })
      .filter(([key, _value]) => key.startsWith('draft-'))
      .forEach(([key, value]) => {
        const timeDaysDifference =
          Array.isArray(JSON.parse(value)) &&
          JSON.parse(value).length > 1 &&
          JSON.parse(value)[1]?.time
            ? differenceInDays(new Date(), new Date(JSON.parse(value)[1]?.time))
            : null;

        typeof timeDaysDifference === 'number' &&
          timeDaysDifference > 60 &&
          localStorage.removeItem(key);
      });
  }, []);

  // referral code가 있는 경우 임시저장하여 profile 페이지에서 자동완성 시켜줌
  useEffect(() => {
    if (typeof referralcode === 'string' && referralcode.length > 0) {
      sessionStorage.setItem('referralcode', referralcode);
    }
  }, [referralcode]);

  return (
    <>
      <CssBaseline />
      {process.env.REACT_APP_LOG === 'OFF' ? null : (
        <LogSentry
          url="https://api-sys-log.gameround.co/api/web-log/list"
          timer={process.env.REACT_APP_ENV === 'DEV' ? 1 : 10}
        />
      )}
      {initGlobalStyles}
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Routes>
          <Route
            path="maintenance/*"
            element={
              isMobileView && !isDesktopTransformed ? <MobileConstruction /> : <Construction />
            }
          />
          <Route
            path="*"
            element={
              siteStateNowData?.data.state === 'MAINTENANCE' ? (
                <Navigate to="maintenance" />
              ) : isMobileView && !isDesktopTransformed ? (
                <DialogContextProvider isMobile>
                  <LoadingContextProvider>
                    <MobileAppContainer />
                  </LoadingContextProvider>
                </DialogContextProvider>
              ) : (
                <DialogContextProvider>
                  <LoadingContextProvider>
                    <Routes>
                      <Route path="launcher-auth/*" element={<LauncherAuth />} />
                      <Route path="launcher/oauth/*" element={<Auth />} />
                      <Route path="*" element={<AppContainer />} />
                    </Routes>
                  </LoadingContextProvider>
                </DialogContextProvider>
              )
            }
          />
        </Routes>
      )}
      <FloatingDiscordBanner />
    </>
  );
}
