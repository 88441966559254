import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import NoAuthRoute from 'views/main/NoAuthRoute';
import SignupProfile from '../profile/SignupProfile';
import SignupUniversity from '../university/SignupUniversity';
import SignupVerificationForm from '../verification/SignupVerificationForm';
import SignupBasicForm from './SignupBasicForm';
import SignupEmailForm from './SignupEmailForm';
import SignupProvider from './SignupProvider';

export default function Signup() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '150px',
        paddingBottom: '200px',
      }}
    >
      <SignupProvider>
        <Routes>
          <Route
            index
            element={
              <NoAuthRoute>
                <SignupBasicForm />
              </NoAuthRoute>
            }
          />
          <Route
            path="email"
            element={
              <NoAuthRoute>
                <SignupEmailForm />
              </NoAuthRoute>
            }
          />
          <Route path="verification" element={<SignupVerificationForm />} />
          <Route path="profile" element={<SignupProfile />} />
          <Route path="university" element={<SignupUniversity />} />
          <Route path="*" element={<Navigate replace to={'..'} />} />
        </Routes>
      </SignupProvider>
    </Box>
  );
}
