import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoadingPage } from 'views/main/LoadingPage';

const FBid = '2230134670627492';
const redirectUri = `${window.location.origin}/launcher/oauth/facebook/`;

export default function LauncherAuthFacebook() {
  const [searchParams] = useSearchParams();

  const state = searchParams.get('state');

  // 다른 type의 oAuth도 언젠가 추가
  useEffect(() => {
    if (state) {
      window.location.replace(
        `https://facebook.com/v13.0/dialog/oauth?client_id=${FBid}&redirect_uri=${redirectUri}&state=${state}&response_type=token,granted_scopes&scope=public_profile,email&auth_type=rerequest`
      );
    }
  }, [state]);

  return state ? <LoadingPage /> : <div>Something went wrong.</div>;
}
