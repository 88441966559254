import { Card, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Card)({
  width: 1200,
  height: 420,
  borderRadius: 5,
  position: 'relative',
});

const ItemWrap = styled('div')({
  padding: 40,
  height: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  width: 448,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export default function GameCardLargestSkeleton() {
  return (
    <Root elevation={0}>
      <Skeleton width={752} height={420} variant="rectangular" />
      <ItemWrap>
        <Skeleton width={100} height={20} />
        <Skeleton width={150} height={20} />
        <Skeleton width={200} height={20} />
      </ItemWrap>
    </Root>
  );
}
