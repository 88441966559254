import GStoreDetailDigitalMain from './GStoreDetailDigitalMain';
import { DetailDigitalProvider } from './GStoreDetailDigitalProvider';

export default function GStoreDetailDigital() {
  return (
    <DetailDigitalProvider>
      <GStoreDetailDigitalMain />
    </DetailDigitalProvider>
  );
}
