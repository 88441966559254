import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { Controller, RegisterOptions, get, useFormContext } from 'react-hook-form';

import { SelectionProps } from './FormType';
import { defaultOptions } from './RadioGroupDefaultOptions';

interface Props {
  defaultChecked?: false;
  disabled?: boolean;
  name: string;
  options?: Array<SelectionProps>;
  rules?: RegisterOptions;
  minSelect?: number;
  maxSelect?: number;
  row?: boolean;
}

export default function FormCheckboxGroup({
  defaultChecked = false,
  disabled = false,
  name,
  options = defaultOptions,
  minSelect,
  maxSelect,
  row,
}: Props) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  const handleValidate = () => {
    const values = getValues();

    if (minSelect) {
      if (values?.[name]?.filter((v: boolean) => Boolean(v)).length < minSelect) {
        return `Please select at least ${minSelect} answer${minSelect > 1 ? 's' : ''}.`;
      }
    }
    if (maxSelect) {
      if (values?.[name]?.filter((v: boolean) => Boolean(v)).length > maxSelect) {
        return `Please select at most ${maxSelect} answer${maxSelect > 1 ? 's' : ''}.`;
      }
    }
  };

  return (
    <FormControl required error={!!error} component="fieldset" disabled={disabled}>
      <FormGroup row={row}>
        {options.map((option, index) => (
          <Box key={option.id} textAlign={row ? 'center' : 'left'}>
            <Controller
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      style={{ color: option?.color }}
                      inputRef={ref}
                      color="primary"
                      size="small"
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  }
                  label={option.label ?? ''}
                  labelPlacement={row ? 'top' : 'end'}
                />
              )}
              control={control}
              defaultValue={defaultChecked}
              name={`${name}.${option.order}`}
              rules={index === 0 ? { validate: handleValidate } : undefined}
            />
          </Box>
        ))}
      </FormGroup>
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
