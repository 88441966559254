import { useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import { iRootState } from 'store';

import { GameGuide } from 'components';

export default function HomeGameGuide() {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  return !isLogin ? (
    <Box
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Divider orientation="horizontal" sx={{ width: '1200px' }} />
      <Box
        sx={{
          paddingTop: '40px',
          width: '1200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <GameGuide spacing="40px" />
      </Box>
      <Divider orientation="horizontal" sx={{ marginTop: '40px', width: '1200px' }} />
    </Box>
  ) : null;
}
