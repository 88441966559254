import { getLanguageNativeName } from '@ground/tools';
import { Dropdown, DropdownOptionValue } from '@ground/ui';
import { Box } from '@mui/material';

import { useClientApplyData } from './ClientApplyProvider';

export default function ClientApplyLanguageDropdown() {
  const { languageSet, selectLanguageCode, setSelectLanguageCode } = useClientApplyData();

  const languageOptions = languageSet?.map((language) => ({
    name: getLanguageNativeName(language.toLowerCase()),
    value: language,
  }));

  const handleChangeSelect = (option: DropdownOptionValue) => {
    setSelectLanguageCode(option.toString());
  };

  return languageOptions && selectLanguageCode ? (
    <Box display={'flex'} justifyContent="flex-end" width="100%" m="20px 0">
      <Dropdown
        size="small"
        width={120}
        placeholder="Language"
        options={languageOptions}
        onChangeValue={handleChangeSelect}
        value={selectLanguageCode}
      />
    </Box>
  ) : null;
}
