import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Stack, Typography } from '@mui/material';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetReviewSurveyState } from 'api/review/reviewAPI';
import { useGameDetailSurveyAPI } from 'api/survey/surveyAPI';
import { useDialog, useError } from 'hooks';
import { iRootState } from 'store';
import { SurveyAnswerFormData, SurveyQuestionType } from 'types/FormDataType';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from '../DetailProvider';
import { useImportDraftData } from '../ImportDraftProvider';
import { useSurveyData } from './SurveyProvider';
import { QuestionChoice, QuestionTextField } from './component';

interface Props {
  finished?: boolean;
  questions: SurveyQuestionType[];
  defaultValues?: SurveyAnswerFormData;
  surveyId: number;
  part: number;
  surveyPartId: number;
}

export default function SurveyContent({
  finished = false,
  questions,
  defaultValues,
  surveyId,
  part,
  surveyPartId,
}: Props) {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const methods = useForm<SurveyAnswerFormData>({ defaultValues, reValidateMode: 'onSubmit' });
  const { showSimpleDialog, showDialog } = useDialog();
  const { showError } = useError();
  const { data } = useGetDetailData();
  const { isLoading: submitLoading, fetch: submitApi } = useGameDetailSurveyAPI(id, surveyId, part);
  const { mutate } = useSurveyData();
  const { shouldImportSurveyDraftData } = useImportDraftData();
  const { mutate: surveyStateReload } = useGetReviewSurveyState(id);
  const { identifier } = useSelector((state: iRootState) => state.login);
  const [isUserInput, setIsUserInput] = useState(false);

  const localStorageKey = `draft-${identifier}-${id}-survey-${surveyId}-${surveyPartId}`;

  const savedDraftDataInLocalStorage = localStorage.getItem(localStorageKey) ?? '{}';

  const [draftData, setDraftData] = useState(JSON.parse(savedDraftDataInLocalStorage));

  const savedTimeInDraft =
    draftData && Array.isArray(draftData) && draftData.length > 1
      ? new Date((draftData[1] as { time: Date }).time)
      : null;

  const [savedTime, setSavedTime] = useState<Date | null>(savedTimeInDraft);

  const isPrivate = typeof data?.private_test === 'number';

  // Survey Submit
  const onSubmit = async (formData: SurveyAnswerFormData) => {
    const answerList = Object.entries(formData).map((entry) => {
      // ex) choice-142, essay-321 (객관식, 주관식)
      const question_name = entry[0].split('-');
      const value = entry[1];

      const answer =
        typeof value === 'string'
          ? value
          : // 중복 선택 가능한 답변의 경우 boolean[] 형식이라 [empty, false, true, false]를 '24'로 전환해서 줘야함
            Object.entries(value)
              .reduce<string[]>((checkedList, currentValue) => {
                currentValue[1] && checkedList.push(currentValue[0]);
                return checkedList;
              }, [])
              .join('');

      return {
        answer: answer,
        question_id: question_name?.[1],
        type: question_name?.[0] === 'choice' ? 'POINT' : 'ANSWER', // 선택형(CHOICE)는 point, 주관식(ESSAY)는 answer
      };
    });

    const response = await submitApi(answerList);
    if (response.status === 200) {
      if (response.data.result_code === 'OK') {
        eventTracker(`survey_${finished ? 'update' : 'submit'}`, { event_label: id });
        surveyStateReload();
        localStorage.removeItem(localStorageKey);
        showDialog({
          title: 'Success',
          body: 'Your survey answer has been sent.',
          primaryButton: {
            text: 'OK',
            ButtonProps: {
              color: isPrivate ? 'private' : 'primary',
              variant: 'contained',
            },
          },
          onClose: () => mutate?.(),
        });
      } else if (response.data.result_code === '401') {
        if (response.data.data.error_code.code === 'U001') {
          showDialog({
            title: 'Alert',
            body: `Your profile is incomplete. Head over to your profile page and connect to Facebook. Don't forget to add any other missing information, like country and gender!`,
            primaryButton: {
              text: 'Go to Profile',
              callback: () => {
                navigate('/profile/profile');
                eventTracker('alertpopup_goto_profile');
                twttr?.conversion.trackPid('o91dr', { tw_sale_amount: 0, tw_order_quantity: 0 });
                gtag('event', 'conversion', { send_to: 'AW-669277837/ySe-CMidsMQDEI29kb8C' });
              },
              ButtonProps: {
                color: isPrivate ? 'private' : 'primary',
                variant: 'contained',
              },
            },
            secondaryButton: { text: 'Close' },
          });
        } else if (response.data.data.error_code.code === 'P003') {
          showSimpleDialog('Error', 'Failed to earn point.');
        }
      } else if (response.data.result_code === '402') {
        showSimpleDialog('Error', 'Reward does not exist.');
      } else {
        showError(response);
      }
    } else {
      showError(response);
    }
  };

  useEffect(() => {
    if (draftData && shouldImportSurveyDraftData) {
      methods.reset(draftData[0]);
    }
  }, [draftData, methods, shouldImportSurveyDraftData]);

  useEffect(() => {
    const dirtyFields = methods.formState.dirtyFields;
    setIsUserInput(Object.keys(dirtyFields).length > 0);
  }, [methods.formState.dirtyFields]);

  useEffect(() => {
    const interval = isUserInput
      ? setInterval(() => {
          const currentTime = new Date();
          const surveyDraftData = [methods.getValues(), { time: currentTime }];
          localStorage.setItem(localStorageKey, JSON.stringify(surveyDraftData));

          setDraftData(surveyDraftData);
          setSavedTime(currentTime);
        }, 10000)
      : null;

    return interval ? () => clearInterval(interval) : () => {};
  }, [isUserInput, localStorageKey, methods]);

  useEffect(() => {
    // 모달이 닫힐 때, 값을 임시저장
    return () =>
      localStorage.setItem(
        localStorageKey,
        JSON.stringify([methods.watch(), { time: new Date() }])
      );
  }, [localStorageKey, methods]);

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        <Paper
          elevation={0}
          sx={{ padding: '40px 28px', borderRadius: '5px', backgroundColor: 'gray.70' }}
        >
          <Stack gap="60px">
            {questions.map((question, index) =>
              question.question_type === 'CHOICE' ? (
                <QuestionChoice key={question.id} index={index} {...question} />
              ) : (
                <QuestionTextField key={question.id} index={index} {...question} />
              )
            )}
          </Stack>
        </Paper>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop="40px"
          marginBottom="16px"
          sx={{ width: '100%' }}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              {isUserInput && savedTime && (
                <Box>
                  <Typography variant="button3" color="text.secondary2">
                    Last auto-saved :{' '}
                  </Typography>
                  <Typography variant="button3" color="text.secondary">
                    {format(savedTime, 'MMM dd, yyyy HH:mm:ss')}
                  </Typography>
                </Box>
              )}
            </Stack>
            <LoadingButton
              loading={submitLoading}
              color={isPrivate ? 'private' : 'primary'}
              type="submit"
              size="large"
              variant="contained"
            >
              {finished ? 'Update' : 'Submit'}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
}
