import { Typography, Box, Divider, styled } from '@mui/material';
import { format } from 'date-fns';

import NewsImageSlider from './NewsImageSlider';
import { NewsArticle, NewsProps } from '.';

const StyledArticle = styled('article')({
  '&:last-of-type > hr': {
    display: 'none',
  },
});

export default function NewsComponent({ id, title, description, date, news_files }: NewsProps) {
  return (
    <StyledArticle>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle3" color="text.secondary2" marginTop="8px">
        {format(new Date(date), 'MMMM dd, yyyy')}
      </Typography>
      <Box display="flex" justifyContent="space-between" mt="40px">
        <NewsArticle article={description} />
        {news_files?.length > 0 && (
          <NewsImageSlider name={`game-detail-news-${id}`} images={news_files} />
        )}
      </Box>
      <Divider sx={{ margin: '60px 0' }} />
    </StyledArticle>
  );
}
