import { Box, Button, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useEmailCertificationCodeAPI,
  usePasswordCheckAPI,
  usePasswordStatusCheckAPI,
} from 'api/deleteAccount/deleteAccountAPI';
import { useGetOrderInProgressAPI, useGetProfileAPI } from 'api/profile/profileAPI';

import { useDialog, useFetchResult } from 'hooks';
import { iRootState } from 'store';
import DeleteAccountDialog from './delete-dialog/DeleteAccountDialog';
import DeleteAccountVerification, {
  ConfirmFormData,
} from './delete-dialog/DeleteAccountVerification';
import DeleteFinalRequest from './delete-dialog/DeleteFinalRequest';
import DeleteStoreDialog from './delete-dialog/DeleteStoreDialog';
import DeleteUserAuthDialog from './delete-dialog/DeleteUserAuthDialog';
import VerificationContent from './verification/VerificationContent';

/**TODO: 상태에 대한 정의 필요 */
type DeleteAccountStep = 'Verification' | 'CodeVertification' | 'Account' | 'Request';

interface Props {
  reload: () => void;
}

export default function AccountDelete({ reload }: Props) {
  const { email, level: userLevel } = useSelector((state: iRootState) => state.login);
  const { showDialog, closeDialog } = useDialog();
  const { showResult } = useFetchResult();
  const [deleteAccountStep, setDeleteAccountStep] = useState<DeleteAccountStep>('Verification'); // onClickShowDialogHandler 상황별 설명 주석처리
  const { fetch: fetchOrderInProgress } = useGetOrderInProgressAPI(); // 주문상태 확인
  const { data: profileInfoData } = useGetProfileAPI(); // 비밀번호 정보확인
  const { fetch: fetchPasswordStatusCheck } = usePasswordStatusCheckAPI(); // 탈퇴 이메일 인증코드 전송여부
  const { fetch: passwordCheck } = usePasswordCheckAPI(); // 비밀번호 체크
  const { fetch: emailCertifaction } = useEmailCertificationCodeAPI(); // 이메일 인증코드 전송

  const isPassword = Boolean(!!profileInfoData?.data?.password_use);

  const handdleClickAccountDelete = async () => {
    const response = await fetchPasswordStatusCheck();
    // 이메일 인증 코드 보낸상태일 떄
    if (response.data?.result_code === 'OK') {
      setDeleteAccountStep('CodeVertification');
    } else {
      const response = await fetchOrderInProgress();
      showResult(response, {
        onSuccess: () => {
          // 유저레벨 1은 일반 유저 1이 아니면 어드민, 스튜디오, 인플루언서 등
          response.data.data.in_progress_order
            ? orderInProgressDialogHandler()
            : userLevel === 1
            ? deleteAccountDialogHandler()
            : noneCommonUserDialogHandler();
        },
      });
    }
  };

  // 패스워드 설정 유무에 따른 패스워드인증,이메일인증 처리로직
  const onClickVertificationHandler = useCallback(
    async (data: ConfirmFormData) => {
      if (isPassword) {
        // password 확인 api
        const formData = new FormData();
        formData.append('password', data.password);
        const response = await passwordCheck(formData);
        if (response?.status === 200 && response.data?.result_code === 'OK') {
          closeDialog();
          setDeleteAccountStep('Account');
        } else {
          data.setError('password', {
            message:
              response.data?.result_code === 'U002'
                ? 'Your password is incorrect. Please check again.'
                : response.data?.description,
          });
        }
      } else {
        // 이메일 확인 api
        const response = await emailCertifaction({
          email: email,
          type: 'withdraw',
          device: 'P',
        });

        if (response?.status === 200 && response.data?.result_code === 'OK') {
          closeDialog();
          setDeleteAccountStep('CodeVertification');
        }
      }
    },
    [closeDialog, passwordCheck, isPassword, emailCertifaction, email]
  );

  // 단순 탈퇴를 할것인가에 대한 확인 다이어로그
  const onClickDeleteConfirmHandler = useCallback(() => {
    closeDialog();
    setDeleteAccountStep('Request');
  }, [closeDialog]);

  // 주문이 진행중일때
  const orderInProgressDialogHandler = useCallback(() => {
    showDialog({
      title: 'Delete Account',
      body: <DeleteStoreDialog closeDialog={closeDialog} />,
      onClose: () => reload(),
    });
  }, [closeDialog, showDialog, reload]);

  // 일반회원이 아닐때
  const noneCommonUserDialogHandler = useCallback(() => {
    showDialog({
      title: 'Delete Account',
      body: <DeleteUserAuthDialog closeDialog={closeDialog} />,
      onClose: () => reload(),
    });
  }, [closeDialog, showDialog, reload]);

  // 회원 탈퇴 Dialog
  const deleteAccountDialogHandler = useCallback(() => {
    setDeleteAccountStep('Verification');

    showDialog({
      title: deleteAccountStep === 'Verification' ? 'Account verification' : 'Delete Account',
      body: (
        <>
          {/* 이메일인증 or 패스워드인증  */}
          {deleteAccountStep === 'Verification' && (
            <DeleteAccountVerification
              email={email}
              isPassword={isPassword}
              onClickVertificationHandler={onClickVertificationHandler}
            />
          )}

          {/* 인증 이후 탈퇴확인설명  */}
          {deleteAccountStep === 'Account' && (
            <DeleteAccountDialog
              closeDialog={closeDialog}
              onClickDeleteConfirmHandler={onClickDeleteConfirmHandler}
            />
          )}

          {/* 탈퇴요청 */}
          {deleteAccountStep === 'Request' && <DeleteFinalRequest closeDialog={closeDialog} />}

          {/* 인증코드입력  */}
          {deleteAccountStep === 'CodeVertification' && (
            <VerificationContent onVerificationSuccess={() => setDeleteAccountStep('Account')} />
          )}
        </>
      ),
    });
  }, [
    closeDialog,
    deleteAccountStep,
    email,
    isPassword,
    onClickDeleteConfirmHandler,
    onClickVertificationHandler,
    showDialog,
  ]);

  useEffect(() => {
    deleteAccountStep !== 'Verification' && deleteAccountDialogHandler();
  }, [deleteAccountStep, deleteAccountDialogHandler]);

  return (
    <Paper sx={{ padding: '40px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">
          Are you sure you want to delete your G.Round account? <br />
          It will permanently delete your account and remove all of your personal information.
        </Typography>

        <Button color="primary" variant="ghost" onClick={handdleClickAccountDelete}>
          Delete Account
        </Button>
      </Box>
    </Paper>
  );
}
