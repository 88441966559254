import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import { useGStoreDetailDigitalData } from './GStoreDetailDigitalProvider';
import GStoreDetailDigitalPage from './GStoreDetailDigitalPage';
import OrderPageDigital from 'views/gstore/order/digital/OrderPageDigital';

export default function GStoreDetailDigitalMain() {
  const { shippingInfoOpen, data, isLoading } = useGStoreDetailDigitalData();
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const haveData = data?.data?.id;

  return isLoading ? (
    <LoadingPage />
  ) : haveData ? (
    isLogin && shippingInfoOpen ? (
      // 주문 페이지
      <OrderPageDigital />
    ) : (
      // 상세 페이지
      <GStoreDetailDigitalPage />
    )
  ) : (
    <NotFound />
  );
}
