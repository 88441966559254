import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import ProfileRouter from './ProfileRouter';
import ProfileTab from './ProfileTab';
import GStoreOrderDetailPoint from './point-detail/GStoreOrderDetailPoint';
import GStoreOrder from './profile-order/orderDetail/GStoreOrder';
import ProfileRecommendGame from './recommend-game/ProfileRecommendGame';
import ProfileSummary from './summary/ProfileSummary';

function ProfileMain() {
  return (
    <Box padding="80px 0 200px" width="1200px">
      <ProfileSummary />
      <ProfileRecommendGame />
      <ProfileTab />
      <ProfileRouter />
    </Box>
  );
}

export default function Profile() {
  return (
    <Routes>
      {/* 상세 페이지는 profile 상단 내역이 안보여야해서 여기서 route로 분리 */}
      {/* 주문 내역 상세 */}
      <Route path="order/*" element={<GStoreOrder />} />
      {/* 포인트 구매 내역 상세 */}
      <Route path="point/:trxId" element={<GStoreOrderDetailPoint />} />
      {/* 프로필 페이지 */}
      <Route path="*" element={<ProfileMain />} />
    </Routes>
  );
}
