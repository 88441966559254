import { LoadingWrapper } from '@ground/ui';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import { useGetProfileAPI } from 'api/profile/profileAPI';
import { iRootState } from 'store';
import { AccountDelete } from './account-delete';
import { AccountInfo } from './account-info';
import { PersonalDetails } from './personal-details';
import { PromotionCode } from './promotion-code';
import { SubscribeEmail } from './subscribe-mail';
import UniversityDetails from './university-details/UniversityDetails';

export default function Profile() {
  const { university } = useSelector((state: iRootState) => state.login);
  const { isLoading, data, mutate } = useGetProfileAPI();
  const { isLoading: loadingCountries, data: countriesData } = useGetCountriesAPI();
  const countries = countriesData ?? [];

  const reload = useCallback(async () => {
    mutate();
  }, [mutate]);

  return (
    <LoadingWrapper isLoading={isLoading} size={64}>
      {data?.data ? (
        <Stack gap="60px">
          {/* 계정 정보 */}
          <AccountInfo profileData={data.data} reload={reload} />
          {/* 개인 정보 */}
          <LoadingWrapper isLoading={loadingCountries} size={64}>
            <PersonalDetails profileData={data.data} reload={reload} countries={countries} />
          </LoadingWrapper>
          {/* 유니버시티 프로그램 상세 정보 */}
          {university.type ? <UniversityDetails type={university.type} /> : null}
          {/* 추천 코드 입력 폼 */}
          {!data.data.submit_referral_code && <PromotionCode reload={reload} />}
          {/* 이메일 구독 */}
          <SubscribeEmail subscribeEmail={data.data.subscribe_email} />
          {/* 회원 탈퇴 */}
          <AccountDelete reload={reload} />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
}
