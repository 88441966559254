import { Box, Divider } from '@mui/material';

import { FloatingWrapper } from '../FloatingWrapper';
import ProductDescription from '../ProductDescription';
import ProductPanel from '../ProductPanel';
import ProductSlide from '../ProductSlide';
import ContactBanner from '../ContactBanner';
import { DigitalOptionSelect } from './DigitalOptionSelect';
import { useGStoreDetailDigitalData } from './GStoreDetailDigitalProvider';
import { TextEditor } from '@ground/text-editor';
import { ProductImages } from '../ProductImages';

export default function GStoreDetailDigitalPage() {
  const { data } = useGStoreDetailDigitalData();

  return (
    <Box component="section" p="80px 0px 200px" width="1200px">
      <ProductSlide contents={data?.data?.top_contents || []} />
      <Box width="1200px" position="relative">
        <FloatingWrapper>
          <DigitalOptionSelect />
        </FloatingWrapper>

        <Box maxWidth="1200px">
          <ProductPanel title={data?.data?.name} summary={data?.data?.summary} />
          {data?.data?.editor_content && (
            <TextEditor
              readOnly
              initialEditorState={data?.data?.editor_content}
              innerWidth={1200}
            />
          )}
        </Box>

        {data?.data?.editor_content && (
          <Divider sx={{ marginTop: '250px', marginBottom: '120px' }} />
        )}

        <ProductDescription description={data?.data?.description} />
      </Box>

      <ProductImages contents={data?.data?.detail_contents || []} />
      <ContactBanner />
    </Box>
  );
}
