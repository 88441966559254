import { useCallback, useMemo, useState } from 'react';
import { DialogActions, Button, ButtonProps, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DialogActionButtonProps } from 'hooks';

interface StyledDialogActionsProps {
  buttonOrder?: 'COLUMN' | 'ROW';
}

const StyledDialogActions = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== 'buttonOrder',
})<StyledDialogActionsProps>(({ buttonOrder }) => ({
  marginTop: 40,
  padding: 0,
  justifyContent: 'center',
  flexDirection: buttonOrder === 'COLUMN' ? 'column-reverse' : undefined,
  columnGap: 8,
  rowGap: 12,
}));

interface ActionButtonProps extends DialogActionButtonProps {
  type: 'primary' | 'secondary';
  onClose?: () => void;
  buttonOrder?: 'COLUMN' | 'ROW';
}

const ActionButton = ({
  type,
  onClose,
  text = type === 'primary' ? 'OK' : 'Cancel',
  callback,
  isLoadingButton,
  buttonOrder,
  ButtonProps,
}: ActionButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    if (callback) {
      isLoadingButton && setLoading(true);
      const response = await callback();
      isLoadingButton && setLoading(false);
      if (response !== false) {
        if (onClose) onClose();
      }
    } else {
      if (onClose) onClose();
    }
  }, [callback, isLoadingButton, onClose]);

  const buttonProps: ButtonProps = useMemo(
    () => ({
      fullWidth: true,
      onClick: handleClick,
      color: 'primary',
      variant: type === 'primary' ? 'contained' : 'ghost',
      ...ButtonProps,
    }),
    [ButtonProps, handleClick, type]
  );

  return isLoadingButton ? (
    <LoadingButton
      loading={loading}
      {...buttonProps}
      sx={{ ...buttonProps.sx, ...(buttonOrder === 'COLUMN' && { marginLeft: 0 }) }}
    >
      {text}
    </LoadingButton>
  ) : (
    <Button
      {...buttonProps}
      sx={{ ...buttonProps.sx, ...(buttonOrder === 'COLUMN' && { marginLeft: 0 }) }}
    >
      {text}
    </Button>
  );
};

interface ModalDialogActionsProps {
  /** 다이얼로그가 닫힐 때 실행 될 함수 */
  onClose?: () => void;
  /** 메인 버튼에 들어가는 텍스트와 클릭 시 실행 될 콜백 함수.
   * callback의 return 값이 false이면 다이얼로그가 닫히지 않는다. */
  primaryButton?: DialogActionButtonProps;
  /** 서브 버튼에 들어가는 텍스트와 클릭 시 실행 될 콜백 함수.
   * callback의 return 값이 false이면 다이얼로그가 닫히지 않는다. */
  secondaryButton?: DialogActionButtonProps;
  buttonOrder?: 'COLUMN' | 'ROW';
}
//props 제거
export default function ModalDialogActions({
  onClose,
  primaryButton,
  secondaryButton,
  buttonOrder,
}: ModalDialogActionsProps) {
  return (
    <StyledDialogActions buttonOrder={buttonOrder} disableSpacing>
      {secondaryButton && (
        <ActionButton
          type="secondary"
          buttonOrder={buttonOrder}
          onClose={onClose}
          {...secondaryButton}
        />
      )}
      {primaryButton && (
        <ActionButton
          type="primary"
          buttonOrder={buttonOrder}
          onClose={onClose}
          {...primaryButton}
        />
      )}
    </StyledDialogActions>
  );
}
