import { Grid } from '@mui/material';
import { useMemo } from 'react';

import { GameCardSmall, GameCardSmallSkeleton } from 'elements';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';

export interface TestingCardGridProps {
  data?: GameCardProps[];
  loading: boolean;
  count: number;
  cardtype: GameStatusType;
}

export default function TestingCardGrid({ data, loading, count, cardtype }: TestingCardGridProps) {
  const loadingSkeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  const gamelist = useMemo(
    () =>
      Array.isArray(data) && data.length > 0
        ? data.map?.((data) => ({ ...data, state: cardtype }))
        : undefined,
    [data, cardtype]
  );

  return (
    <Grid container rowGap="40px" columnGap="20px">
      {gamelist
        ? gamelist.map((game) => (
            <Grid item key={game.id}>
              <GameCardSmall {...game} likeButton />
            </Grid>
          ))
        : null}
      {loading &&
        loadingSkeletonList.map((skeleton) => (
          <Grid item key={skeleton}>
            <GameCardSmallSkeleton />
          </Grid>
        ))}
    </Grid>
  );
}
