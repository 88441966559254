import { Box, Stack, Typography } from '@mui/material';
import { useRef } from 'react';

import { NaviLink } from 'atoms';
import { useHover } from 'usehooks-ts';
import VideoClipMedia from './VideoClipMedia';

export interface ClipCardSmallProps {
  studioName: string;
  gameTitle: string;
  srcUrl: string;
  gameId: number;
  numberOfVideos?: number;
  onClickClipButton: () => void;
  onClickNavLink: () => void;
}

const ClipCardSmall: React.FC<ClipCardSmallProps> = ({
  studioName,
  gameTitle,
  srcUrl,
  gameId,
  onClickClipButton,
  onClickNavLink,
}) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Box width="285px" borderRadius="5px">
      <Box
        ref={hoverRef}
        width="285px"
        height="184px"
        onClick={onClickClipButton}
        sx={{ cursor: 'pointer' }}
      >
        <VideoClipMedia
          hover={isHover}
          imageUrl={srcUrl || ''}
          playingTime={0}
          width={285}
          height={184}
          iconSize="SMALL"
        />
      </Box>

      <Stack marginTop="20px" alignItems="flex-start">
        <Typography variant="button1" color="text.primary" noWrap>
          {gameTitle}
        </Typography>
        <Typography variant="body3" color="text.secondary" mt="4px" noWrap>
          {studioName}
        </Typography>

        <NaviLink
          text="Go to the Game Page"
          to={`/detail/${gameId}/info`}
          onClick={onClickNavLink}
        />
      </Stack>
    </Box>
  );
};

export default ClipCardSmall;
