import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'CircularSub',
  h1: {
    fontWeight: 700,
    fontSize: 45,
    lineHeight: '57px',
    letterSpacing: 0,
  },
  h2: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '46px',
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '36px',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30px',
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '27px',
    letterSpacing: 0,
  },
  title: {
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '40px',
    letterSpacing: 0,
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  button1: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
  },
  button2: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  button3: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  body3: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '17px',
    letterSpacing: 0,
  },
  small1: {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  small2: {
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '14px',
    letterSpacing: 0,
  },
  menu: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
  },
  review: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  button: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0,
    textTransform: 'none',
  },
  // 수정 필요
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  // 수정 필요
  overline: {
    lineHeight: 'normal',
    letterSpacing: 0,
  },
};

export default typography;
