import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useSendEmailVerificationCodeAPI } from 'api/email/emailAPI';
import {
  useDialog,
  useProfileDialog,
  useSignupDialog,
  useTermsConfirmDialog,
  useVerificationDialog,
} from 'hooks';
import { iRootState } from 'store';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

export default function useValidate() {
  const { agreed_terms, filled, isLogin, verified } = useSelector(
    (state: iRootState) => state.login
  );
  const { fetch: sendVerificationCode } = useSendEmailVerificationCodeAPI();

  const { gxcTag } = useLog();
  const { showDialog } = useDialog();
  const { showSignupDialog } = useSignupDialog();
  const { showTermsConfirmDialog } = useTermsConfirmDialog();
  const { showVerificationDialog } = useVerificationDialog();
  const { showProfileDialog } = useProfileDialog();

  const commonValidate = useCallback(
    (checkLogin = true, checkTerms = true, checkVerified = true, checkFilled = true): boolean => {
      // 로그인 유무 확인
      if (checkLogin && !isLogin) {
        showSignupDialog();
        return false;
      }

      // 약관 변경 동의 확인
      if (checkTerms && !agreed_terms) {
        showTermsConfirmDialog();
        return false;
      }

      // 이메일 인증 확인
      if (checkVerified && !verified) {
        gxcTag('view', { area: 'email_verification_popup' });
        showDialog({
          title: 'Please Verify Your Email',
          body: (
            <Typography color="text.secondary" variant="body2">
              Your email address has not been verified.
              <br />
              You'll have limited access to G.Round features until you verify it.
            </Typography>
          ),
          primaryButton: {
            text: 'Send Verification Mail',
            callback: async () => {
              gxcTag('click', { area: 'email_verification_popup', ui: 'send' });

              await sendVerificationCode();
              window.setTimeout(() => showVerificationDialog(), 300);
            },
            isLoadingButton: true,
          },
          onClickClose: () => {
            gxcTag('click', { area: 'email_verification_popup', ui: 'close_x' });
          },
        });
        return false;
      }

      // 프로필 미완성 확인
      if (checkFilled && !filled) {
        showProfileDialog();

        eventTracker('alertpopup_goto_profile');
        twttr?.conversion.trackPid('o91dr', { tw_sale_amount: 0, tw_order_quantity: 0 });
        gtag('event', 'conversion', { send_to: 'AW-669277837/ySe-CMidsMQDEI29kb8C' });

        return false;
      }
      return true;
    },
    [
      agreed_terms,
      filled,
      gxcTag,
      isLogin,
      sendVerificationCode,
      showDialog,
      showProfileDialog,
      showSignupDialog,
      showTermsConfirmDialog,
      showVerificationDialog,
      verified,
    ]
  );

  return { commonValidate };
}
