import { Tab, styled } from '@mui/material';

export interface GTabProps {
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

const GTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color',
})<GTabProps>(({ theme, size = 'medium', color = theme.palette.text.primary }) => ({
  ...(size === 'medium'
    ? theme.typography.subtitle1
    : size === 'small'
    ? theme.typography.small1
    : theme.typography.h5),
  minWidth: 'auto',
  minHeight: 'auto',
  padding: 0,

  color: theme.palette.text.secondary2,
  '&.Mui-selected': {
    color: color,
  },
}));

export default GTab;
