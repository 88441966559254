import { useFeaturedGamesAPI } from 'api/displayContent/displayContentAPI';
import { GameListBigContainer, SectionContainer } from 'components';

export default function HomeFeatured() {
  const { data, isLoading, isValidating, error } = useFeaturedGamesAPI();

  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  return isDataNotExist ? null : (
    <SectionContainer title="Featured" sx={{ zIndex: 1 }}>
      <GameListBigContainer count={2} hoverVideoPlay showViewMoreCard useSlider />
    </SectionContainer>
  );
}
