import { useValidate, useDialog } from 'hooks';
import { isNumber } from 'lodash-es';
import { useSelector } from 'react-redux';
import { iRootState } from 'store';
import { DigitalGoodsStatus } from 'types/DigitalGoodsType';
import eventTracker from 'utils/eventTracker';
import { useGStoreDetailDigitalData } from './GStoreDetailDigitalProvider';
import PaymentWidget from '../PaymentWidget';
import { useState } from 'react';

export const DigitalOptionSelect = () => {
  const {
    setShippingInfoOpen,
    selectedOption,
    setSelectedOption,
    data,
    isLoading,
    gpointSummaryData,
    summaryMutate,
  } = useGStoreDetailDigitalData();
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { commonValidate } = useValidate();
  const { showDialog } = useDialog();
  const [selectedItemKey, setSelectedItemKey] = useState('');

  const userPoint = gpointSummaryData?.data?.available_point;
  const optionData = data?.data?.options;

  const productPoint = data?.data?.display_point;
  // 잔액 부족 체크
  const isPointLack =
    isNumber(userPoint) &&
    isNumber(selectedOption?.price) &&
    selectedOption &&
    userPoint < selectedOption.price;

  const orderButtonClickHandler = async () => {
    eventTracker('gstore_product_digital_order', {
      event_label: data?.data?.id ?? '',
    });

    // 옵션 선택 여부 체크
    if (selectedOption?.amount === '' || selectedOption?.item === 0) {
      showDialog({
        title: 'Looks like you missed something',
        body: 'Please select an option before placing an order.',
        primaryButton: { text: 'Confirm' },
      });
      return;
    }

    // 주문 가능 여부 체크
    if (!commonValidate()) return;

    // 주문 정보 입력 페이지 오픈
    setShippingInfoOpen && setShippingInfoOpen(true);
  };

  const itemOptions =
    Array.isArray(optionData) && optionData.length > 0
      ? optionData.map((data) => ({
          name: data.parent_option_name,
          value: data.external_key,
        }))
      : [];

  const amountOptions =
    optionData
      ?.find((option) => option.external_key === selectedItemKey)
      ?.children?.map((option) => ({
        id: option?.option_id,
        name: option.child_option_name + ` (1G.P)`,
        value: option.external_key,
        qty: option.quantity,
        disabled: option.quantity < 1,
        price: option?.point_price,
      })) || [];

  const digitalGoodsStatus = data?.data?.status;

  return (
    <PaymentWidget
      isDisplayPrice={
        !data?.data?.point_no_display && typeof data?.data?.display_point === 'number'
      }
      note={data?.data?.note}
      showDisplayOptions={!isLoading}
      onItemChangeValue={(option) => {
        setSelectedItemKey(option.valueOf().toString());
      }}
      userPoint={userPoint}
      onAmountChangeValue={(option) => {
        const selectedItemOption = data?.data?.options.find(
          (optionData) => optionData.external_key === selectedItemKey
        );
        const selectedAmountOption = data?.data?.options
          .find((optionData) => optionData.external_key === selectedItemKey)
          ?.children?.find((child) => child.external_key === option);

        if (selectedAmountOption) {
          setSelectedOption?.({
            item: selectedItemOption?.parent_option_name || '',
            amount: selectedAmountOption?.child_option_name + '',
            id: selectedAmountOption?.option_id,
            qty: 1,
            price: selectedAmountOption?.point_price,
            title: selectedAmountOption?.child_option_name,
          });
        }
      }}
      totalPrice={
        selectedOption && !!selectedOption.amount && selectedOption.item && isNumber(productPoint)
          ? selectedOption?.price
          : undefined
      }
      isOrderAvailable
      isDisabledButton={
        digitalGoodsStatus === DigitalGoodsStatus.SOLD_OUT ||
        digitalGoodsStatus === DigitalGoodsStatus.UPCOMING ||
        digitalGoodsStatus === DigitalGoodsStatus.DISABLED ||
        (isLogin && !!isPointLack)
      }
      orderButtonLabel={
        digitalGoodsStatus === DigitalGoodsStatus.ENABLE
          ? 'Order Now'
          : digitalGoodsStatus === DigitalGoodsStatus.UPCOMING
          ? 'Coming Soon'
          : digitalGoodsStatus === DigitalGoodsStatus.SOLD_OUT
          ? 'Sold Out'
          : 'Not Available'
      }
      itemOptions={itemOptions}
      amountOptions={amountOptions}
      orderButtonClickHandler={orderButtonClickHandler}
      onComplete={summaryMutate}
    />
  );
};
