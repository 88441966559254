import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { GameCardProps } from 'types/GameCardTypes';
import GameCardMobileUpperArea from './GameCardMobileUpperArea';
import GameCardLikeButtonContainer from 'elements/GameCard/GameCardLikeButton/GameCardLikeButtonContainer';
import GameCardMobileLowerArea from './GameCardMobileLowerArea';

export default function GameCardMobile({
  onLikeClicked,
  onClickCard,
  havePlayBtn,
  gameId,
  fullWidth,
  size = 'medium',
  ...props
}: {
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  onClickCard?: (id: number) => void;
  gameId?: number;
  havePlayBtn?: boolean;
  fullWidth?: boolean;
  size?: 'medium' | 'small';
} & GameCardProps) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Link
      style={{ cursor: props?.isPrivateShow ? 'auto' : 'cursor', textDecoration: 'auto' }}
      to={props?.isPrivateShow ? '' : `/detail/${props.id}/info`}
      onClick={() => onClickCard?.(props.id)}
    >
      <Box
        position="relative"
        width={fullWidth ? '100%' : 285}
        ref={hoverRef}
        sx={{
          borderRadius: '5px',
          boxShadow: isHover && !props.isPrivateShow ? '0px 10px 10px rgba(0, 0, 0, 0.5)' : '',
        }}
      >
        <GameCardMobileUpperArea
          {...props}
          isHover={isHover}
          fullWidth={fullWidth}
          havePlayBtn={havePlayBtn}
          gameId={gameId}
          size={size}
        />
        <GameCardMobileLowerArea {...props} isHover={isHover} isMobile>
          {props.likeButton && (
            <Box position="absolute" sx={{ top: 13, right: 11 }}>
              <GameCardLikeButtonContainer
                gameId={props.id}
                likeCheck={props.like_check}
                onLikeClicked={onLikeClicked}
              />
            </Box>
          )}
        </GameCardMobileLowerArea>
        {props.chipText && (
          <Box
            position="absolute"
            sx={{
              top: 12,
              right: 12,
              zIndex: 999,
              backgroundColor: '#FFF',
              padding: '0px 8px',
              borderRadius: '3px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="small2" color="gray.100">
              {props.chipText}
            </Typography>
          </Box>
        )}
      </Box>
    </Link>
  );
}
