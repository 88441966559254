import useSWRFetch from 'api/useSWRFetch';

export interface CountryData {
  id: number;
  code: string;
  name: string;
  name1: string; // 공식 국가명
}

//countries 데이터를 최초 한번만 호출하기
//기존 useSWR hook 과 함께 사용하기 위해 옵션 형식으로 작성, useSWRImmutable과 같은 동작
const revalidationOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetCountriesAPI() {
  const { isLoading, data } = useSWRFetch<CountryData[]>({
    key: 'countries',
    swrConfig: revalidationOptions,
  });

  // 유저의 선택 제한을 위해 Global, Unkown 국가 코드 값 제거후 data 리턴
  return { isLoading, data: data?.data.slice(0, data?.data.length - 2) };
}

export function useGetCountryAPI(countryCode?: string | null) {
  const { data } = useSWRFetch<CountryData[]>({
    key: 'countries',
    swrConfig: revalidationOptions,
  });
  const countryData = data?.data.find((country) => country.code === countryCode);

  return countryData ?? { id: 0, code: countryCode, name: '', name1: '' };
}
