import useFetch from 'api/useFetch';
import useSWRFetch from 'api/useSWRFetch';
import { orderQueries } from './orderQueries';
import { IOrder, OrderTypeKey } from './orderTypes';

/** Order */
// 주문 개수 조회
export function useGetOrderCount() {
  return useSWRFetch<IOrder.GetOrderCount.Response>({
    key: orderQueries.fetchOrderCount(),
    useToken: true,
  });
}

// 주문 단건 조회
export function useGetOrderDetail(id: string) {
  return useSWRFetch<IOrder.GetOrderDetail.Response>({
    key: id ? orderQueries.fetchOrder(id) : null,
    useToken: true,
  });
}

// 주문 목록 조회
export function useGetOrderList(type: OrderTypeKey) {
  return useSWRFetch<IOrder.GetOrderList.Response>({
    key: orderQueries.fetchOrderList(type),
    useToken: true,
  });
}

// 디지털 게임 주문 생성
export function useCreateDigitalGameOrder() {
  return useFetch<IOrder.CreateOrder.Response, IOrder.CreateOrder.Request>(
    orderQueries.fetchOrderDigitalGame(),
    { method: 'POST' },
    true
  );
}
