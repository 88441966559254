import { GStoreOrderDetailDigitalMain } from './GStoreOrderDetailDigitalMain';
import GStoreOrderDetailDigitalProvider from './GStoreOrderDetailDigitalProvider';

export default function GStoreOrderDetailDigital() {
  return (
    <GStoreOrderDetailDigitalProvider>
      <GStoreOrderDetailDigitalMain />
    </GStoreOrderDetailDigitalProvider>
  );
}
