import { Box, Divider } from '@mui/material';
import { TextEditor } from '@ground/text-editor';

import { useGetDigitalGameProduct } from 'api/gstore';
import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import { FloatingWrapper } from '../FloatingWrapper';
import PaymentWidget from '../PaymentWidget';
import ContactBanner from '../ContactBanner';
import ProductSlide from '../ProductSlide';

import { useGetGPointSummary } from 'api/profile/profileAPI';
import eventTracker from 'utils/eventTracker';
import { useValidate } from 'hooks';
import ProductPanel from '../ProductPanel';
import ProductDescription from '../ProductDescription';
import { ProductImages } from '../ProductImages';

export default function DigitalGamesDetail() {
  const { id = '' } = useParams<{ id: string }>();
  const { isLoading, data } = useGetDigitalGameProduct(Number(id));
  const { commonValidate } = useValidate();
  const navigate = useNavigate();

  const { data: gpointSummaryData, mutate: summaryMutate } = useGetGPointSummary();

  const productSaleStatus = data?.data?.status;
  const userPoint = gpointSummaryData?.data?.available_point;
  const productPoint = data?.data?.price;
  // 잔액 부족 체크
  const isPointLack = isNumber(userPoint) && isNumber(productPoint) && userPoint < productPoint;
  const isDisabledButton =
    isPointLack || productSaleStatus !== 'ENABLE' || Number(data?.data?.available_stock) < 1;

  const orderButtonClickHandler = useCallback(() => {
    eventTracker('gstore_product_order', { event_label: data?.data.id ?? '' });

    // 주문 가능 여부 체크
    if (!commonValidate()) return;

    // 주문 페이지로 이동
    navigate('order');
  }, [commonValidate, data?.data.id, navigate]);

  return isLoading ? (
    <LoadingPage />
  ) : data?.data ? (
    <Box component="section" p="80px 0px 200px" width="1200px" position="relative">
      <ProductSlide contents={data?.data.top_contents ?? []} />
      <Box width="1200px" position="relative" marginTop="30px">
        <FloatingWrapper>
          <PaymentWidget
            isDisabledButton={isDisabledButton}
            orderButtonClickHandler={orderButtonClickHandler}
            orderButtonLabel={
              productSaleStatus === 'ENABLE' // SALE_ENABLED의 경우
                ? Number(data?.data?.available_stock) > 0
                  ? 'Order Now' // 남은 판매 가능 수량이 있는 경우
                  : 'Sold Out' // 판매 가능 수량이 0인 경우
                : productSaleStatus === 'UPCOMING'
                ? 'Coming Soon' // 판매 예정
                : productSaleStatus === 'SOLD_OUT'
                ? 'Sold Out' // SALE_SOLD_OUT의 경우
                : productSaleStatus === 'DISABLE'
                ? 'Not Available' // SALE_DISABLED의 경우
                : 'Unknown'
            }
            isPointLack={isNumber(userPoint) && isNumber(productPoint) && userPoint < productPoint}
            userPoint={userPoint}
            productPoint={productPoint}
            onComplete={summaryMutate}
            // isDisplayPrice={!data?.data?.point_no_display}
            // isOrderAvailable={data?.data.promotion?.status === 'IN_PROGRESS'}
            isDisplayPrice
            isOrderAvailable
            note={data?.data?.note}
          />
        </FloatingWrapper>

        <Box maxWidth="1200px">
          <ProductPanel title={data?.data?.title} summary={data?.data?.overview} />
          {data?.data?.editor_content && (
            <TextEditor
              readOnly
              initialEditorState={data?.data?.editor_content}
              innerWidth={1200}
            />
          )}
        </Box>

        {data?.data?.editor_content && (
          <Divider sx={{ marginTop: '250px', marginBottom: '120px' }} />
        )}

        <ProductDescription description={data?.data?.detail} />
      </Box>
      <ProductImages contents={data?.data?.detail_contents || []} />
      <ContactBanner />
    </Box>
  ) : (
    <NotFound />
  );
}
