import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { LinkButton } from 'elements';
import { iRootState } from 'store';

interface Props {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
}

export default function ErrorPage({ error, eventId, resetError }: Props) {
  const { email, name } = useSelector((state: iRootState) => state.login);

  return (
    <Stack sx={{ alignItems: 'center', padding: '120px' }}>
      <Typography variant="h1" align="center">
        Something went wrong!
      </Typography>

      <Alert severity="error" sx={{ border: 'solid 1px #800', maxWidth: '600px', mt: '40px' }}>
        <AlertTitle>{error.name}</AlertTitle>
        {error.message}
      </Alert>

      <Box sx={{ display: 'flex', mt: '40px', gap: '16px' }}>
        <LinkButton to="/" variant="ghost" onClick={resetError}>
          G.Round Home
        </LinkButton>
        <Button color="light" onClick={() => window.location.reload()} variant="outlined">
          Reload a Page
        </Button>
      </Box>
      <Button
        sx={{ mt: '24px' }}
        variant="contained"
        onClick={() => Sentry.showReportDialog({ user: { email, name }, eventId: eventId })}
      >
        Send error report
      </Button>
    </Stack>
  );
}
