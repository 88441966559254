import { useEffect } from 'react';
import { useCountUp } from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@mui/material';

interface CounterProps {
  /** counter를 표시해줄 element id */
  id: string;
  /** 시작 숫자 */
  startCount?: number;
  /** 종료 숫자 */
  endCount: number;
}

/**
 * 숫자 카운팅 애니메이션 컴포넌트
 * 표시할 영역이 보여질 때 애니메이션 시작되도록 작성
 * 아직 초기단계이므로 옵션은 최소한으로 만들었습니다.
 */
export function Counter({ id, startCount, endCount }: CounterProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });
  const { start } = useCountUp({
    ref: id,
    start: startCount ?? 0,
    end: endCount,
    separator: ',',
    startOnMount: false,
  });

  useEffect(() => {
    if (inView) {
      start();
    }
  }, [inView, start]);

  return <span id={id} ref={ref} />;
}

interface LoadingCounterProps extends CounterProps {
  loading: boolean;
}

export function LoadingCounter({ loading, ...props }: LoadingCounterProps) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  return (
    <div ref={ref} style={{ display: 'inline' }}>
      {loading || !inView ? <Skeleton /> : <Counter {...props} />}
    </div>
  );
}
