import { Typography } from '@mui/material/';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  width: 163,
}));

interface Props {
  title: string;
  price: string;
  desc: string | JSX.Element;
}

export default function ArtDevPackagesTable({ title, price, desc }: Props) {
  return (
    <Root>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h5" color="text.secondary" sx={{ margin: '8px 0 20px' }}>
        {price}
      </Typography>
      {typeof desc === 'string' ? (
        <Typography variant="body2" color="text.secondary2">
          {desc}
        </Typography>
      ) : (
        desc
      )}
    </Root>
  );
}
