import { FormControlLabel, styled, Switch, SwitchProps } from '@mui/material';

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 34,
  padding: 0,
  margin: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '15px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 30,
    height: 30,
    color: '#F6F6F6',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.17)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 25,
    backgroundColor: '##808080',
    backgroundImage: 'linear-gradient(to bottom, #808080, #989898)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'], {
      duration: 500,
    }),
  },
}));

export interface IOSSwitchProps {
  /**
   * switch label on
   * @default - ON
   */
  labelOn?: string;
  /**
   * switch label off
   * @default - OFF
   */
  labelOff?: string;
}

export default function IOSSwitch({
  labelOn = 'ON',
  labelOff = 'OFF',
  ...props
}: SwitchProps & IOSSwitchProps) {
  return (
    <FormControlLabel
      sx={{ fontSize: 14, fontWeight: 300, lineHeight: 1.2, opacity: 0.7 }}
      control={<StyledSwitch {...props} />}
      labelPlacement="start"
      label={props.checked ? labelOn : labelOff}
    />
  );
}
