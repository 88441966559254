import ReactPlayer from 'react-player/youtube';
import { Typography } from '@mui/material';

import { ImageBox } from 'atoms';
import { SurveyAttachType } from 'types/FormDataType';

interface Props {
  type: 'TEXT' | 'IMAGE' | 'VIDEO';
  selection: SurveyAttachType;
}

export default function QuestionSelection({ type, selection }: Props) {
  return (
    <div>
      {type === 'IMAGE'
        ? selection.file_url && (
            <ImageBox
              src={selection.file_url}
              alt={`selection-${selection.seq}`}
              resize
              resizeWidth={180}
              resizeHeight={135}
              format="webp"
              sx={{ borderRadius: '10px' }}
            />
          )
        : type === 'VIDEO'
        ? selection.video_url && (
            <ReactPlayer url={selection.video_url} controls width={180} height={101} />
          )
        : null}
      {selection.description && (
        <Typography color="text.secondary" variant="body2">
          {selection.description}
        </Typography>
      )}
    </div>
  );
}
