import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import axios from 'axios';
import { iRootState } from 'store';
import LoginForm from 'views/sign/signin/LoginForm';
import LoginEmailForm from 'views/sign/signin/LoginEmailForm';
import { LoadingPage } from 'views/main/LoadingPage';

import { useFeaturedGamesAPI } from 'api/displayContent/displayContentAPI';
interface LauncherAuthGroundProps {
  state: string; // laucher return url
}

export default function LauncherAuthGround({ state }: LauncherAuthGroundProps) {
  const { data } = useFeaturedGamesAPI();
  console.log('token 만료체크하기위한 임시 api', data);
  const { isLogin, access_token } = useSelector((state: iRootState) => state.login);
  const navigate = useNavigate();
  // 로그인 성공 유무 (페이지를 벗어나는 경우를 위한 변수. isLogin을 사용하면 로그인 보다 에러가 먼저 발생함)
  const successLogin = useRef(false);

  // 웹에서 로그인 한 정보를 가지고 런처에 로그인 시도
  const loginToLauncher = useCallback(async () => {
    try {
      const response = await axios({
        url: `${window.atob(
          decodeURIComponent(state)
        )}?access_token=${access_token}&state=${state}`,
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers':
        //   'Content-Type, Access-Control-Allow-Headers, X-Requested-With',
        // 'Access-Control-Request-Private-Network': 'true',
        // },
      });
      if (response.status === 200) {
        // 정상적으로 통신한 경우
        if (typeof response.data === 'string' && response.data.includes('launcher-auth/success')) {
          navigate('../success', { replace: true });
        } else {
          // 성공이 아닌 경우는 모두 error로 간주(런처에서 error를 리턴하는 경우. state가 잘못되었다거나..)
          navigate('../error', {
            replace: true,
            state: `1 ${response.status} : ${response.statusText} : ${response.data}`,
          });
        }
      } else {
        // 통신 중 에러 발생(잘못 호출 하였거나.. 발생할 일이 거의 없을듯)
        navigate('../error', {
          replace: true,
          state: `2 ${response.status} : ${response.statusText} : ${response.data}`,
        });
      }
    } catch (e) {
      // 런처가 응답이 없는 경우(런처가 꺼졌거나 통신이 안될 때)
      navigate('../error', { replace: true, state: e.message });
    }
  }, [access_token, navigate, state]);

  useEffect(() => {
    if (isLogin) {
      successLogin.current = true;
      // 로그인 성공했거나 이미 로그인 되어있는 경우
      loginToLauncher();
    }
  }, [isLogin, loginToLauncher]);

  useEffect(() => {
    // 로그인하지 않고 페이지를 벗어나는 경우(sign up, forgot your password 페이지 등)
    return () => {
      if (!successLogin.current) {
        // 런처에 로그인 중단을 알려서 런처의 로그인 팝업을 종료한다.
        axios(`${window.atob(decodeURIComponent(state))}?error=Your login has been canceled.`);
      }
    };
  }, [state]);

  return state ? (
    isLogin ? (
      <LoadingPage />
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingY: '100px',
        }}
      >
        <Routes>
          <Route index element={<LoginForm />} />
          <Route path="email" element={<LoginEmailForm />} />
          <Route path="*" element={<Navigate replace to={'../error'} />} />
        </Routes>
      </Box>
    )
  ) : (
    <div>Something went wrong.</div>
  );
}
