import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import OrderPageMerchandise from '../order/OrderPageMerchandise';
import GStoreDetailPage from './GStoreDetailPage';
import { useGStoreDetailData } from './GStoreDetailProvider';

export default function GStoreDetailMain() {
  const { shippingInfoOpen, isLoading, data } = useGStoreDetailData();
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const haveId = data?.data?.sale_product_id;

  return isLoading ? (
    <LoadingPage />
  ) : haveId ? (
    isLogin && shippingInfoOpen ? (
      // 주문 페이지
      <OrderPageMerchandise />
    ) : (
      // 상세 페이지
      <GStoreDetailPage />
    )
  ) : (
    <NotFound />
  );
}
