import { LoadingWrapper } from '@ground/ui';
import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { StudentFilterQuery, useStudentReviewListAPI } from 'api/comment/commentAPI';
import { useQueryState } from 'context';
import { NoData } from 'elements';
import StudentReviewContainer from './StudentReviewContainer';

export default function StudentReviewList() {
  const { id = '' } = useParams<{ id: string }>();
  const [ref, inView] = useInView({ threshold: 0.1 });
  const query = useQueryState<StudentFilterQuery>();
  const { isValidating, data: reviewList, setSize, mutate } = useStudentReviewListAPI(id, query);

  useEffect(() => {
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);

  const hasReviewList =
    Array.isArray(reviewList) && Array.isArray(reviewList[0].data) && reviewList[0].data.length > 0;
  const isNotDataLast =
    reviewList?.length && reviewList[reviewList.length - 1].next_page?.has_next_page;

  const mutateReviews = () => {
    if (reviewList) {
      // mutate([...reviewList], { revalidate: false }); 가 동작하지 않음
      // https://github.com/vercel/swr/issues/1899
      // SWR 2.0.1 업데이트 후 수정해야 함
      mutate([...reviewList], false);
    } else {
      mutate();
    }
  };

  return (
    <LoadingWrapper isLoading={isValidating} sx={{ height: 474 }} size={64}>
      <Stack gap="36px" mt="40px" px="60px">
        {hasReviewList ? (
          reviewList.map((reviewChunk) =>
            reviewChunk.data.map((review, index) => (
              <div key={review.id}>
                <div
                  ref={isNotDataLast && index === reviewChunk.data.length - 1 ? ref : undefined}
                />
                <StudentReviewContainer reviewData={review} mutateReviewList={mutateReviews} />
              </div>
            ))
          )
        ) : (
          <Box sx={{ height: 474 }}>
            <NoData title="No student reviews." />
          </Box>
        )}
      </Stack>
    </LoadingWrapper>
  );
}
