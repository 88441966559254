import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Skeleton } from '@mui/material';

const PREFIX = 'VideoClipSkeleton';

const classes = {
  root: `${PREFIX}-root`,
  textWrap: `${PREFIX}-textWrap`,
  picture: `${PREFIX}-picture`,
};

const StyledCard = styled(Card)({
  [`&.${classes.root}`]: {
    width: 240,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
  },
  [`& .${classes.textWrap}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  [`& .${classes.picture}`]: {
    borderRadius: 5,
    width: 240,
    height: 136,
    marginBottom: 12,
  },
});

export default function VideoClipSkeleton() {
  return (
    <StyledCard className={classes.root} elevation={0} square>
      <Skeleton className={classes.picture} variant="rectangular" />

      <div className={classes.textWrap}>
        <div>
          <Skeleton width={200} height={20} />
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </StyledCard>
  );
}
