import { Box, Typography, styled } from '@mui/material';

import OrderShippingInfoForm from './OrderShippingInfoForm';

const FormBox = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.gray[90],
  borderRadius: 5,
  padding: 40,
  marginTop: 20,
}));

export default function OrderShippingInfo() {
  return (
    <Box flex={1}>
      <Typography variant="h5" color="text.primary">
        Shipping Information
      </Typography>
      <FormBox>
        <OrderShippingInfoForm />
      </FormBox>
    </Box>
  );
}
