import { FormAutocompleteOption } from '@ground/ui';
import { useSelector } from 'react-redux';

import { useFetch, useSWRFetch } from 'api';
import { storeApiUrl } from 'api/gstore/gstoreAPI';
import { useAxiosWithAuth } from 'hooks';
import { iRootState } from 'store';

export interface GPointsSummaryData {
  user_id: number;
  available_point: number;
  earned_point: number;
  purchase_point: number;
  total_earned_point: number;
  total_redeemed_point: number;
  total_expired_point: number;
  total_purchase_point: number;
}

export interface ProfileDashboardData {
  name: string;
  num_global_survey: number;
  num_new_notification: number;
  num_notification: number;
  num_review: number;
  num_survey: number;
  num_testing: number;
  num_total_testing: number;
  picture: string | null;
  point: number;
  registered_at: string;
}

export interface ProfileData {
  email: string;
  name: string;
  country_code: string | null;
  language_code: string;
  picture: string | null;
  birth_year: number | null;
  birth_month: number | null;
  birth_day: number | null;
  sex: string | null;
  facebook_connect: boolean;
  registered_at: string;
  unregistered_at: string;
  updated_at: string;
  access_token: string;
  verified: boolean;
  filled: boolean;
  agreed_terms: boolean;
  submit_referral_code: boolean;
  subscribe_email: boolean;
  ciso: string | null; // IP기반 국가코드
  cname: string | null; // IP기반 국가이름
  password_use: boolean;
  supporter: boolean;
  university_type: string | null;
}

export interface ProfileFormData {
  name: string;
  birthday: Date | null;
  country: FormAutocompleteOption | null;
  sex: string;
  subscribe_email?: boolean;
}

export interface ProfileRequestData {
  name: string;
  birth_month: number;
  birth_day: number;
  birth_year: number;
  country_code?: string;
  sex: string;
  subscribe_email?: boolean;
}

// 진행 중인 주문 여부 확인
export interface OrderInProgressData {
  in_progress_order: boolean; // 진행중인 주문 존재 여부
  available_point: number; // 유저의 사용 가능 포인트
}

export function useGetProfileAPI() {
  return useSWRFetch<ProfileData>({ key: 'users/profile', useToken: true });
}

export function useSaveProfileAPI() {
  return useFetch<ProfileData, ProfileRequestData>('users/profile', { method: 'PATCH' }, true);
}

export function useCheckDuplicateNameAPI() {
  return useFetch<string, { name: string }>('chkdupliname', { method: 'POST' }, true);
}

export function useGetGPointSummary() {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  const url = isLogin ? `${storeApiUrl}/api/v2/point/account` : null;

  return useSWRFetch<GPointsSummaryData>({ key: url, useToken: true });
}

// POST인데 params를 이용해서 useFetch 사용을 못함(API 수정 요청해야 함)
export function usePostReferralcodeAPI() {
  return useAxiosWithAuth<{ promotionCode: string }>('users/promotions', { method: 'POST' }, true);
}

export function useGetOrderInProgressAPI() {
  const url = `${storeApiUrl}/api/v2/orders/in-progress`;

  return useFetch<OrderInProgressData>(url, { method: 'GET' }, true);
}

// 유저의 profile image upload
export function useUploadUserImageAPI() {
  return useFetch('users/image', { method: 'POST' }, true);
}

// 유저의 referral code(추천인 코드)를 가져옴
export function useGetReferralCodeAPI() {
  return useSWRFetch<string>({ key: 'users/promotions', useToken: true });
}

// 유저의 referral code(추천인 코드)를 생성
export function useCreateReferralCodeAPI() {
  return useFetch<string>('promotions', { method: 'POST' }, true);
}

// 유저의 profile dashboard data
export function useGetDashboardAPI() {
  return useSWRFetch<ProfileDashboardData>({ key: 'dashboard', useToken: true });
}
