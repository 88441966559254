import { Box, Typography } from '@mui/material';
import { Fragment } from 'react';

import { OMerchandiseOrderStatus } from 'api/order';
import { useGStoreOrderDetailData } from './GStoreOrderDetailProvider';

const physicalSteps = ['Order Placed', 'Packing', 'In Transit', 'Delivered'];
const digitalSteps = ['Order Placed', 'Delivered'];

export default function GStoreOrderDetailStepper() {
  const { data } = useGStoreOrderDetailData();

  const orderStatus = data?.data?.status === 'CONFIRMED' ? 'ORDERED' : data?.data?.status;
  const steps = data?.data.type === 'PHYSICAL_GOODS' ? physicalSteps : digitalSteps;

  // 현재 주문 진행상태
  const activeStep = orderStatus ? steps.indexOf(OMerchandiseOrderStatus[orderStatus]) : -1;

  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', gap: '20px', px: '20px' }}>
      {steps.map((label, index) => (
        <Fragment key={index}>
          <Box sx={{ minWidth: '80px', flexBasis: '80px' }}>
            <Typography
              variant="h5"
              color={activeStep >= index ? 'text.primary' : 'text.secondary2'}
              textAlign="center"
              mb="4px"
              sx={{ opacity: activeStep >= index ? 1 : 0.5 }}
            >
              {String(index + 1).padStart(2, '0')}
            </Typography>
            <Typography
              variant="subtitle3"
              color={activeStep === index ? 'text.primary' : 'text.secondary2'}
              textAlign="center"
              sx={{ opacity: activeStep === index ? 1 : 0.5 }}
              noWrap
            >
              {label}
            </Typography>
          </Box>
          {index < steps.length - 1 ? (
            <Box
              sx={{
                backgroundColor: ({ palette }) =>
                  activeStep > index ? palette.base.dimWhite : palette.opacity.white[5],
                width: '100%',
                height: '1px',
              }}
            />
          ) : null}
        </Fragment>
      ))}
    </Box>
  );
}
