/** 초를 HH:MM:SS 로 변환해주는 함수 */
export function getClockTime(time: number) {
  const hasHour = time > 3600;

  return `${hasHour ? `${Math.floor(time / 3600)}:` : ''}${(hasHour
    ? Math.floor(time / 60) % 60
    : Math.floor(time / 60)
  )
    .toString()
    .padStart(hasHour ? 2 : 1, '0')}:${(time % 60).toString().padStart(2, '0')}`;
}
