import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'ArtAboutGamerJoiningCommunity';

const classes = {
  discord: `${PREFIX}-discord`,
};

const Root = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: '0 0 200px',

  [`& .${classes.discord}`]: {
    marginTop: 40,
  },
}));

export default function ArtAboutGamerJoiningCommunity() {
  return (
    <Root>
      <Typography variant="h4" marginTop="100px">
        Joining Our Gaming Community
      </Typography>
      <Typography variant="body1" color="text.secondary" margin="28px auto 0" width="526px">
        We have our own Discord community where gamers from different regions, game developers,
        content creators and our staff communicate. Join our G.Round Discord.
      </Typography>
      <iframe
        className={classes.discord}
        title="G.Round"
        src="https://discord.com/widget?id=601726723947757571&theme=dark"
        width="680"
        height="360"
        // allowTransparency
        frameBorder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      ></iframe>
      <Typography variant="body2" color="text.secondary" margin="33px auto 0">
        Join our community over on Medium, YouTube, Twitter, and Facebook to get the latest
        <br /> news and updates.
      </Typography>
    </Root>
  );
}
