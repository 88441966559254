import { Box } from '@mui/material';

import { PageLayout } from 'components';
import { QuerystringProvider } from 'context';
import BlogFilter from './BlogFilter';
import BlogHighlightBannerSlide from './BlogHighlightBannerSlide';
import BlogListBox from './BlogListBox';
import BlogListToolbar from './BlogListToolbar';

const defaultQuery = { pageSize: 10, search_announce_type: 'all', search_keyword: '' };

export default function BlogListMain() {
  return (
    <PageLayout mt="80px">
      <QuerystringProvider defaultQuery={defaultQuery}>
        {/* HighlightBanner */}
        <BlogHighlightBannerSlide />
        {/* BlogToolbar */}
        <BlogListToolbar />
        {/* BlogBox */}
        <Box display="flex" gap="60px">
          {/* BlogList */}
          <BlogListBox />
          {/* BlogFilter */}
          <BlogFilter />
        </Box>
      </QuerystringProvider>
    </PageLayout>
  );
}
