import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@mui/material';

import LoginForm from './LoginForm';
import LoginEmailForm from './LoginEmailForm';

const Root = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 150,
  paddingBottom: 200,
});

export default function Login() {
  return (
    <Root>
      <Routes>
        <Route index element={<LoginForm />} />
        <Route path="email" element={<LoginEmailForm />} />
        <Route path="*" element={<Navigate replace to={'..'} />} />
      </Routes>
    </Root>
  );
}
