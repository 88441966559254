import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

type ContextProps = {
  shouldImportReviewDraftData: boolean;
  setShouldImportReviewDraftData: Dispatch<SetStateAction<boolean>>;
  shouldImportSurveyDraftData: boolean;
  setShouldImportSurveyDraftData: Dispatch<SetStateAction<boolean>>;
};

const ImportDraftContext = createContext<ContextProps | undefined>(undefined);

export const useImportDraftData = () => {
  const context = useContext(ImportDraftContext);
  if (!context) throw new Error('import draft context not found');
  return context;
};

interface Props {
  children: ReactNode;
}

export default function ImportDraftProvider({ children }: Props) {
  const [shouldImportReviewDraftData, setShouldImportReviewDraftData] = useState(false);
  const [shouldImportSurveyDraftData, setShouldImportSurveyDraftData] = useState(false);

  return (
    <ImportDraftContext.Provider
      value={{
        shouldImportReviewDraftData,
        setShouldImportReviewDraftData,
        shouldImportSurveyDraftData,
        setShouldImportSurveyDraftData,
      }}
    >
      {children}
    </ImportDraftContext.Provider>
  );
}
