import { Box, Button, Typography, styled } from '@mui/material';

import gameController from 'images/game_controller.png';
import familyPlayingGame from 'images/family_play.png';
import familyPlayingGameBig from 'images/family_play_big.png';
import { useRecommendGameDialog } from 'hooks';

const Container = styled(Box)({
  width: '285px',
  height: '270px',
  backgroundColor: '#374c54',
  padding: '32px 20px',
  borderRadius: '5px',
  position: 'relative',
});

interface Props {
  size?: 1 | 2 | 3;
  onRecommendAGameClick?: () => void;
}

const RecommendAGameCard = ({ size, onRecommendAGameClick }: Props) => {
  const { showRecommendGameDialog } = useRecommendGameDialog();

  if (size === 3) {
    return (
      <Container
        sx={{
          width: '893px',
          height: '270px',
          padding: '52px 58px',
        }}
      >
        <img
          src={familyPlayingGameBig}
          alt="game controller"
          style={{ position: 'absolute', bottom: 0, right: '63px' }}
          width={344}
        />
        <Typography variant="h4">Recommend a game</Typography>
        <Typography variant="body2" mt="12px" sx={{ opacity: 0.5 }}>
          Your voice counts in gaming. Recommend a game for <br />
          playtesting and be part of something big.
        </Typography>
        <Button
          variant="ghost"
          size="small"
          sx={{ marginTop: '32px', cursor: 'pointer' }}
          onClick={() => {
            onRecommendAGameClick?.();
            showRecommendGameDialog();
          }}
        >
          Suggest Games
        </Button>
      </Container>
    );
  }

  if (size === 2) {
    return (
      <Container
        sx={{
          width: '590px',
          height: '270px',
          padding: '52px 40px',
        }}
      >
        <img
          src={familyPlayingGame}
          alt="game controller"
          style={{ position: 'absolute', bottom: 0, right: 0 }}
          width={256}
        />
        <Typography variant="h4">Recommend a game</Typography>
        <Typography variant="body2" mt="12px" sx={{ opacity: 0.5 }}>
          Your voice counts in gaming. Recommend a game for <br />
          playtesting and be part of something big.
        </Typography>
        <Button
          variant="ghost"
          size="small"
          sx={{ marginTop: '32px', cursor: 'pointer' }}
          onClick={() => {
            onRecommendAGameClick?.();
            showRecommendGameDialog();
          }}
        >
          Suggest Games
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <img
        src={gameController}
        alt="game controller"
        style={{ position: 'absolute', bottom: 0, right: 0 }}
        width={122}
      />
      <Typography variant="h4">Recommend a game</Typography>
      <Typography variant="body2" mt="12px" sx={{ opacity: 0.5 }}>
        Your voice counts in gaming. <br />
        Recommend a game for playtesting <br />
        and be part of something big.
      </Typography>
      <Button
        variant="ghost"
        size="tiny"
        sx={{ marginTop: '28px', cursor: 'pointer' }}
        onClick={() => {
          onRecommendAGameClick?.();
          showRecommendGameDialog();
        }}
      >
        Suggest Games
      </Button>
    </Container>
  );
};

export default RecommendAGameCard;
