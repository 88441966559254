import { alpha, Box, styled, Typography } from '@mui/material';

import { UserAvatar } from 'atoms';
import { LinkButton } from 'elements';
import ClipThumbnailContainer from './ClipThumbnailContainer';

const ClipBlock = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.gray[90],
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 5,
  width: 590,
  columnGap: 20,
}));

const ContentContainer = styled('div')(() => ({
  minWidth: 0,
}));

const AvatarContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
}));

export interface ClipProps {
  streamer_name: string;
  streamer_country: string;
  streamer_profile_url: string;
  video_title: string;
  game_title: string;
  thumbnail_url: string;
  game_id: number;
  video_id: number;
  duration: number;
}

const Clip: React.FC<ClipProps> = ({
  streamer_name,
  streamer_country,
  streamer_profile_url,
  video_title,
  game_title,
  thumbnail_url,
  game_id,
  video_id,
  duration,
}) => {
  return (
    <ClipBlock>
      <ClipThumbnailContainer
        srcUrl={thumbnail_url}
        duration={duration}
        to={`/detail/${game_id}/clips/${video_id}#detail-tab`}
      />
      <ContentContainer>
        <AvatarContainer>
          <UserAvatar width={28} src={streamer_profile_url} alt={streamer_name} />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', minWidth: 0 }}>
            <Typography noWrap variant="small1">
              {streamer_name}
            </Typography>
            <Typography variant="button2" color="text.secondary">
              {streamer_country}
            </Typography>
          </Box>
        </AvatarContainer>
        <Typography variant="body2" color="text.secondary" height="63px" overflow="hidden">
          {video_title}
        </Typography>
        <LinkButton
          color="light"
          variant="outlined"
          size="tiny"
          sx={(theme) => ({
            marginTop: '16px',
            borderColor: alpha(theme.palette.base.dimWhite, 0.2),
          })}
          to={`/detail/${game_id}`}
        >
          {game_title}
        </LinkButton>
      </ContentContainer>
    </ClipBlock>
  );
};

export default Clip;
