import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, To, useLocation } from 'react-router-dom';

import { iRootState } from 'store';

interface AuthRouteProps {
  children: ReactElement;
  to?: To;
}

export default function AuthRoute({ children, to = '/login' }: AuthRouteProps) {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { pathname, search } = useLocation();

  return isLogin ? children : <Navigate to={to} state={{ from: pathname + search }} replace />;
}
