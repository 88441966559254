import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';

import { SectionBox } from '../../SectionBox';
import { useGStoreOrderDetailDigitalData } from './GStoreOrderDetailDigitalProvider';

export default function GStoreOrderDetailDigitalShippingInfoBox() {
  const { data } = useGStoreOrderDetailDigitalData();
  const theme = useTheme();

  return (
    <Box>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between" mb="20px">
        <Typography variant="h5">Shipping Information</Typography>
      </Stack>
      <Stack
        columnGap="40px"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        sx={{
          background: theme.palette.gray[90],
          borderRadius: '5px',
          padding: '40px',
        }}
      >
        <Stack sx={{ flex: 1, rowGap: '40px' }}>
          <SectionBox title="Email" content={data?.data?.order_user_email} hasNoFullHeight />
        </Stack>
      </Stack>
    </Box>
  );
}
