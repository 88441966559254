import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { Box, Typography } from '@mui/material';

interface ChipProps {
  text: string;
  backgroundColor?: string;
}

// 블로그 상세에서 사용하는 tag chip
export default function BlogTagButton({ text, backgroundColor = 'gray.80' }: ChipProps) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Box
      ref={hoverRef}
      sx={{
        display: 'flex',
        padding: '4px 12px',
        borderRadius: '5px',
        backgroundColor: isHover ? 'gray.50' : backgroundColor,
        textAlign: 'center',
        wordBreak: 'break-all',
      }}
    >
      <Typography color={isHover ? 'text.primary' : 'text.secondary'} variant="body2">
        {text}
      </Typography>
    </Box>
  );
}
