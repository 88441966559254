import { GIconButton } from '@ground/ui';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  backdropClasses,
  dialogClasses,
  dialogContentClasses,
  dialogTitleClasses,
  styled,
} from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import SimpleBar from 'simplebar-react';

import { DialogState } from 'hooks';
import ModalDialogActions from './ModalDialogActions';

import 'simplebar/dist/simplebar.min.css';

const whiteColors = ['#fff', '#FFF', 'rgb(255,255,255)', 'rgba(255,255,255,1)', 'white'];

const Scrollbar = styled(SimpleBar)(({ theme }) => ({
  '& .simplebar-track.simplebar-vertical': {
    width: 6,

    '& .simplebar-scrollbar:before': {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundColor: theme.palette.gray[40],
    },

    '& .simplebar-scrollbar.simplebar-visible:before': {
      opacity: 1,
    },
  },
}));

interface DialogStyles {
  backgroundColor?: string;
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<DialogStyles>(({ theme, backgroundColor }) => ({
  // Backdrop
  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  // Paper
  [`& .${dialogClasses.paper}`]: {
    background: backgroundColor ?? theme.palette.gray[80],
    borderRadius: 5,
  },
  // Title
  [`& .${dialogTitleClasses.root}`]: {
    ...theme.typography.h5,
    padding: '0 30px 0 0',
  },
  // Content
  [`& .${dialogContentClasses.root}`]: {
    marginTop: 20,
    padding: 0,
    color: theme.palette.text.secondary,

    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
  },
}));

export default function ModalDialog({
  open = false,
  title,
  body,
  onClose,
  onClickClose,
  primaryButton,
  secondaryButton,
  maxWidth = false,
  width = 380,
  height,
  disableEscapeKeyDown,
  buttonOrder,
  helperText,
  hasScrollBar,
  hasNoCloseIcon,
  backgroundColor,
}: DialogState) {
  const handleClickClose = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      if (onClickClose) {
        const response = await onClickClose(e);
        if (response !== false) {
          if (onClose) onClose();
        }
      } else {
        if (onClose) onClose();
      }
    },
    [onClickClose, onClose]
  );

  return (
    <StyledDialog
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          if (onClose) onClose();
        }
      }}
      scroll="paper"
      PaperProps={{
        sx: { width, height, padding: '28px', ...(hasScrollBar && { paddingRight: '8px' }) },
      }}
      backgroundColor={backgroundColor}
    >
      {/* Title Area */}
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      {/* Close Button */}
      {!hasNoCloseIcon && (
        <GIconButton
          iconName="close"
          iconSize={24}
          size={36}
          color={backgroundColor && whiteColors.includes(backgroundColor) ? 'black' : undefined}
          onClick={handleClickClose}
          ButtonProps={{ sx: { position: 'absolute', top: '8px', right: '8px' } }}
        />
      )}
      {/* Content Area */}
      <DialogContent id="dialog-description" sx={{ ...(hasScrollBar && { overflowY: 'hidden' }) }}>
        {hasScrollBar ? (
          <Scrollbar
            autoHide={false}
            sx={{ ...(hasScrollBar && { paddingRight: '20px', maxHeight: '100%' }) }}
          >
            {typeof body === 'string' ? (
              <DialogContentText color="text.secondary" variant="body2" whiteSpace="pre-line">
                {body}
              </DialogContentText>
            ) : (
              body
            )}
          </Scrollbar>
        ) : typeof body === 'string' ? (
          <DialogContentText color="text.secondary" variant="body2" whiteSpace="pre-line">
            {body}
          </DialogContentText>
        ) : (
          body
        )}
      </DialogContent>
      {/* Action Button Area */}
      {(primaryButton || secondaryButton) && (
        <ModalDialogActions
          onClose={onClose}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          buttonOrder={buttonOrder}
        />
      )}
      {helperText && (
        <Typography variant="small2" color="text.secondary" mt="20px">
          {helperText}
        </Typography>
      )}
    </StyledDialog>
  );
}
