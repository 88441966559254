import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Skeleton } from '@mui/material';

const PREFIX = 'ClipCardLargeSkeleton';

const classes = {
  root: `${PREFIX}-root`,
  textWrap: `${PREFIX}-textWrap`,
  picture: `${PREFIX}-picture`,
  avatar: `${PREFIX}-avatar`,
};

const StyledCard = styled(Card)({
  [`&.${classes.root}`]: {
    width: '100%',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
  },
  [`& .${classes.textWrap}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px 0px 20px 0px',
    flexDirection: 'row',
  },
  [`& .${classes.picture}`]: {
    borderRadius: 5,
    width: '100%',
    height: 'calc(100vw * (335/360))',
    position: 'relative',
  },
  [`& .${classes.avatar}`]: {
    marginRight: 12,
  },
});

export default function FeaturedSkeleton() {
  return (
    <StyledCard className={classes.root} elevation={0} square>
      <Skeleton className={classes.picture} variant="rectangular" />

      <Box className={classes.textWrap}>
        <Box>
          <Skeleton width={100} height={20} />
          <Skeleton width={200} height={20} />
        </Box>
        <Skeleton className={classes.avatar} width={40} height={40} variant="circular" />
      </Box>
      <Box mt="8px">
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Skeleton width={150} height={20} />
          <Skeleton width={32} height={20} />
        </Box>
        <Skeleton width="100%" height={5} />
      </Box>
    </StyledCard>
  );
}
