import { LoadingWrapper } from '@ground/ui';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { useGetPublicReviewDashboardAPI } from 'api/comment/commentAPI';

import smallBg from 'images/banner/guide_bg.webp';
import smallBg2x from 'images/banner/guide_bg@2x.webp';
import smallBg3x from 'images/banner/guide_bg@3x.webp';

const Container = styled(Box)({
  padding: '32px 40px 24px 40px',
  backgroundImage: `image-set(url(${smallBg}) 1x, url(${smallBg2x}) 2x, url(${smallBg3x}) 3x)`,
  overflow: 'hidden',
  marginBottom: '20px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const AdditionalReviewBox = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: reviewData, isLoading } = useGetPublicReviewDashboardAPI(id);
  const isDisplayBanner =
    reviewData?.data?.available_purchase &&
    reviewData?.data?.is_un_limited === false &&
    reviewData?.data?.additional_amount > 0;

  return (
    <LoadingWrapper isLoading={isLoading} sx={{ height: 134, marginBottom: '20px' }}>
      {isDisplayBanner ? (
        <Container>
          <Stack>
            <Typography variant="subtitle1" fontWeight={700} color="base.white">
              Explore Additional Reviews!
            </Typography>
            <Typography variant="body2" color="text.secondary" mt="6px">
              For access to more reviews, please contact the administrator before the purchase
              period expires.
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              mt="12px"
              sx={{ fontSize: '12px', lineHeight: '20px' }}
            >
              Additional purchase period :{' '}
              <span style={{ color: 'white', fontWeight: 700 }}>
                {!reviewData?.data?.purchase_deadline
                  ? 'Unlimited'
                  : format(new Date(reviewData?.data?.purchase_deadline), 'MMM dd, yyyy') +
                    ` ${reviewData?.data?.dday}`}
              </span>
            </Typography>
          </Stack>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {typeof reviewData?.data?.additional_amount === 'number' &&
            reviewData?.data?.additional_amount > 0 ? (
              <Stack gap="6px">
                <Typography variant="subtitle3" color="text.secondary">
                  Additional
                </Typography>

                <Typography variant="subtitle1" color="base.white">
                  {Math.abs(reviewData?.data?.additional_amount)} Reviews
                </Typography>
              </Stack>
            ) : null}

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ marginLeft: '80px' }}
              onClick={() => {
                window.open('https://forms.gle/jcRyKBPLhSteeKhC9', '_blank');
              }}
            >
              Purchase Request
            </Button>
          </Box>
        </Container>
      ) : null}
    </LoadingWrapper>
  );
};
