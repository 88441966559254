import { Box, Divider } from '@mui/material';
import SupporterApplyQuestionDropbox from '../question/SupporterApplyQuestionDropbox';
import SupporterApplyQuestionInputField from '../question/SupporterApplyQuestionInputField';
import { useSupporterApplyData } from '../SupporterApplyProvider';

// 4섹션
export default function EtcSection() {
  const { supporterQuestionData } = useSupporterApplyData();
  const data = supporterQuestionData?.filter((item) => item.question_section_id === 40);

  return (
    <Box p="40px" sx={{ backgroundColor: 'gray.90' }} mb="20px">
      {data?.map((question, index) => (
        <Box key={question.question_id}>
          {question.question_type === 'TEXT' ? (
            <SupporterApplyQuestionInputField
              {...question}
              index={index + 30}
              required={Boolean(question.required)}
            />
          ) : (
            <SupporterApplyQuestionDropbox
              index={index + 30}
              {...question}
              required={Boolean(question.required)}
            />
          )}

          {data.length - 1 !== index && (
            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
          )}
        </Box>
      ))}
    </Box>
  );
}
