import { Box, Stack, Typography } from '@mui/material';

import { LinkButton } from 'elements';
import { useLog } from 'utils/userLog';
import HomeGameGuide from './HomeGameGuide';

import loginBeforeBanner from 'images/banner/home_login_before_banner.webp';
import loginBeforeBanner2x from 'images/banner/home_login_before_banner@2x.webp';

const HomeLoginBeforeBanner: React.FC = () => {
  const { gxcTag } = useLog();

  return (
    <Box component="section" sx={{ position: 'relative', width: '100%', alignSelf: 'flex-start' }}>
      <Box sx={{ height: 858 }} />
      <Stack
        sx={{
          width: '100%',
          height: 'calc(100vw / 2.4)',
          position: 'absolute',
          minHeight: '800px',
          top: 0,
          left: 0,
          zIndex: 0,
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }}>
          <Box
            sx={{
              width: '100%',
              height: '120px',
              position: 'absolute',
              top: 0,
              background: `linear-gradient(180deg, #000 11.46%, rgba(0, 0, 0, 0.00) 100%)`,
              zIndex: 2,
            }}
          />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              bottom: 0,
              background: `linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, #121212 100%);`,
              zIndex: 2,
            }}
          />
          <Box
            sx={{
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat',
              zIndex: 1,
              width: '100%',
              height: '100%',
              position: 'absolute',
              bottom: 0,
            }}
          />
          <img
            src={loginBeforeBanner}
            srcSet={`${loginBeforeBanner}, ${loginBeforeBanner2x} 2x`}
            alt="login before banner"
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              height: '100%',
              minHeight: '800px',
              minWidth: '1920px',
              zIndex: 1,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '1200px',
              top: '185px',
              zIndex: 2,
            }}
          >
            <Box display="flex" gap="12px" mb="16px">
              <Typography color="base.white" fontSize={50} fontWeight={900} lineHeight="50px">
                IMPACT
              </Typography>
              <Typography color="#00F0FF" fontSize={50} fontWeight={900} lineHeight="50px">
                THE GAME
              </Typography>
            </Box>

            <Typography variant="body1" color="base.white" mt="12px">
              Help developers by providing critical early feedback and earn <br />
              rewards for your reviews. Plus, network with an innovative <br />
              community of developers, gamers, and indie game fans!
            </Typography>

            <Stack direction="row" marginTop="40px" gap="8px">
              <LinkButton
                to={'/signup'}
                color="primary"
                size="large"
                variant="contained"
                onClick={() => {
                  gxcTag('click', { area: 'intro_top_banner', ui: 'signup_now' });
                }}
              >
                Sign Up Now
              </LinkButton>

              <LinkButton
                to={'/testing'}
                color="light"
                size="large"
                variant="outlined"
                onClick={() => {
                  gxcTag('click', { area: 'intro_top_banner', ui: 'browse_games' });
                }}
              >
                Browse Featured Games
              </LinkButton>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: '#221535', flex: 1 }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 515,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 2,
          }}
        >
          <HomeGameGuide />
        </Box>
      </Stack>
    </Box>
  );
};

export default HomeLoginBeforeBanner;
