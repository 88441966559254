import type { FormAutocompleteOption } from '@ground/ui';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetCountriesAPI } from 'api/country/countryAPI';
import {
  useGetProfileAPI,
  usePostReferralcodeAPI,
  type ProfileFormData,
} from 'api/profile/profileAPI';
import { useUpdateSignDataWithToken, useVerifyToken } from 'api/sign/signAPI';
import { useError } from 'hooks';
import type { iRootState } from 'store';
import { useLog } from 'utils/userLog';
import AuthRoute from 'views/main/AuthRoute';
import { SignLayout } from '../component';
import { useSignupData } from '../signup/SignupProvider';
import SignupProfileForm from './SignupProfileForm';

export default function SignupProfile() {
  const [searchParams] = useSearchParams();
  const { isMobile } = useSignupData();
  const { isLoading, data } = useGetProfileAPI();
  const { isLoading: loadingCountry, data: countries } = useGetCountriesAPI();

  const { gxcTag } = useLog();
  const navigate = useNavigate();
  const { showError } = useError();
  const { updateSignData } = useUpdateSignDataWithToken();
  const { refetch: fetchReferralcode } = usePostReferralcodeAPI();
  const { filled } = useSelector((state: iRootState) => state.login);

  const { verifyToken } = useVerifyToken();
  const university = searchParams.get('university');

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  useEffect(() => {
    if (filled) {
      navigate('/', { replace: true });
    }
  }, [navigate, filled]);

  const countryList: FormAutocompleteOption[] =
    countries?.map((country) => ({
      name: country.name,
      value: country.code,
    })) ?? [];

  const profileData = data?.data;
  const userCountryCode = profileData?.country_code ?? profileData?.ciso;

  const defaultValues: Partial<ProfileFormData> = {
    name: profileData?.name,
    birthday:
      profileData?.birth_year && profileData?.birth_month && profileData?.birth_day
        ? new Date(`${profileData.birth_year}-${profileData.birth_month}-${profileData.birth_day}`)
        : null,
    country: countryList.find((country) => country.value === userCountryCode),
    sex: profileData?.sex ?? undefined,
  };

  return (
    <AuthRoute>
      <SignLayout isMobile={isMobile} title="Personal Details">
        <Box textAlign="center" mb="40px">
          {!isMobile && (
            <Typography color="text.primary" mb="8px" variant="h3">
              Personal Details
            </Typography>
          )}
          <Typography color="text.secondary" variant="subtitle2">
            Please enter your profile information
            <br />
            to complete your registration.
          </Typography>
        </Box>

        {isLoading || loadingCountry ? (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 495 }}>
            <CircularProgress color="primary" size={64} />
          </Box>
        ) : (
          <SignupProfileForm
            defaultValues={defaultValues}
            isMobile={isMobile}
            includeNewsletter
            onSubmitSuccess={async (responseData) => {
              // 유저 정보 갱신
              await updateSignData(responseData.access_token);
              // 임시: referralcode가 있는 경우 자동 등록 2023.1.25
              const referralcode = sessionStorage.getItem('referralcode');
              if (referralcode) {
                // 에러 발생해도 별도로 체크하지 않고 무시
                await fetchReferralcode({ params: { promotionCode: referralcode } });
              }

              // University 가입인 경우 교수, 학생 정보 입력 폼으로 이동
              if (university) {
                navigate(`/signup/university?${searchParams.toString()}`);
              } else {
                gxcTag('view', { area: 'welcome_popup', ui: 'N/A' });

                // 홈으로 보내고 웰컴 다이얼로그 띄우기
                navigate('/', { state: { welcome: true } });
              }
            }}
            onSubmitError={(response) => {
              showError(response, 'Profile Error');
            }}
            submitText={university ? 'Continue' : undefined}
          />
        )}
      </SignLayout>
    </AuthRoute>
  );
}
