import { FormDatePicker } from '@ground/ui';
import { subYears } from 'date-fns';

interface Props {
  isMobile?: boolean;
  logArea: string;
}

export default function SignupBirthday({ isMobile, logArea }: Props) {
  // TODO: 추후 Log 정의할 때 다시 추가
  // const { gxcTag } = useLog();

  // const handleClickBirthday = (type: 'month' | 'day' | 'year') => () => {
  //   gxcTag('click', { area: logArea, ui: `select_${type}` });
  // };

  return (
    <FormDatePicker
      name="birthday"
      label="Date of birth"
      size={isMobile ? 'large' : 'medium'}
      minDate={subYears(new Date(), 100)}
      maxDate={subYears(new Date(), 13)}
      required
      withBackground
    />
  );
}
