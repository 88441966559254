import { Box } from '@mui/material';
import { ComponentProps } from 'react';

import ReplyButton from './ReplyButton';
import ReviewBlindButton from './ReviewBlindButton';
import ReviewLikeButton from './ReviewLikeButton';
import ReviewPickButton from './ReviewPickButton';

interface ReviewBottomProps {
  likeProps?: ComponentProps<typeof ReviewLikeButton>;
  replyProps?: ComponentProps<typeof ReplyButton>;
  pickProps?: ComponentProps<typeof ReviewPickButton>;
  blindProps?: ComponentProps<typeof ReviewBlindButton>;
}

export default function ReviewBottom({
  likeProps,
  replyProps,
  pickProps,
  blindProps,
}: ReviewBottomProps) {
  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      {/* left area */}
      <Box display="flex" alignItems="center" gap="8px">
        {likeProps ? <ReviewLikeButton {...likeProps} /> : null}
        {likeProps && replyProps ? <Box sx={{ color: 'gray.30' }}>·</Box> : null}
        {replyProps ? <ReplyButton {...replyProps} /> : null}
      </Box>
      {/* right area */}
      <Box display="flex" gap="8px">
        {pickProps ? <ReviewPickButton {...pickProps} /> : null}
        {blindProps ? <ReviewBlindButton {...blindProps} /> : null}
      </Box>
    </Box>
  );
}
