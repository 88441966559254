// 계약서 내용
export const clientApplyContents = [
  `Definition of Confidential Information. For purposes of this Agreement, “Confidential
  Information” shall include all information or material that has or could have commercial value
  or other utility in the business in which Disclosing Party is engaged. If Confidential
  Information is in written form, the Disclosing Party shall label or stamp the materials with the
  word “Confidential” or some similar warning. If Confidential Information is transmitted orally,
  the Disclosing Party shall promptly provide a writing indicating that such oral communication
  constituted Confidential Information.
  For the purpose of clarity and not limitation, the Receiving Party specifically agrees that the
  foregoing definition of Confidential Information includes all information related to the
  Supporter’s Program, including but not limited to (i) the Receiving Party’s participation and
  activities within the Supporter’s Program, (ii) any playtest opportunities that the Receiving
  Party participates in or is presented to the Receiving Party (whether or not the Receiving
  Party actually participates in such playtests), (iii) any gameplay streams or recordings, in
  video, audio, or any other formats, of any playtest, and (iv) any other data, recordings,
  feedback, suggestions, comments, communications, or other information related to the
  Supporter’s Program or any playtest opportunity within the Supporter’s Program.`,
  `Exclusions from Confidential Information. Receiving Party’s obligations under this
  Agreement do not extend to information that is: (a) publicly known at the time of disclosure
  or subsequently becomes publicly known through no fault of the Receiving Party; (b)
  discovered or created by the Receiving Party before disclosure by Disclosing Party; (c)
  learned by the Receiving Party through legitimate means other than from the Disclosing
  Party or Disclosing Party’s representatives; or (d) is disclosed by Receiving Party with
  Disclosing Party’s prior written approval.`,
  `Obligations of Receiving Party. Receiving Party shall hold and maintain the Confidential
  Information in strictest confidence for the sole and exclusive benefit of the Disclosing Party.
  Receiving Party shall carefully restrict access to Confidential Information to employees,
  contractors and third parties unless required. Receiving Party shall not, without the prior
  written approval of Disclosing Party, use for Receiving Party’s own benefit, publish, copy, or
  otherwise disclose to others, or permit the use by others for their benefit or to the detriment
  of Disclosing Party, any Confidential Information. Receiving Party shall return to Disclosing
  Party any and all records, notes, and other written, printed, or tangible materials in its
  possession pertaining to Confidential Information immediately if Disclosing Party requests it
  in writing.`,
  `Time Periods. The nondisclosure provisions of this Agreement shall survive the
  termination of this Agreement and Receiving Party’s duty to hold Confidential Information in
  confidence shall remain in effect until the Confidential Information no longer qualifies as a
  trade secret or until Disclosing Party sends Receiving Party written notice releasing
  Receiving Party from this Agreement, whichever occurs first.`,
  'Relationships. Nothing contained in this Agreement shall be deemed to constitute either party a partner, joint venture or employee of the other party for any purpose.',
  `Severability. If a court finds any provision of this Agreement invalid or unenforceable, the
  remainder of this Agreement shall be interpreted to best to affect the intent of the parties.`,
  `Integration. This Agreement expresses the complete understanding of the parties with
  respect to the subject matter and supersedes all prior proposals, agreements,
  representations, and understandings. This Agreement may not be amended except in a
  writing signed by both parties.`,
  `Waiver. The failure to exercise any right provided in this Agreement shall not be a waiver
  of prior or subsequent rights.`,
  `Remedies. The Receiving Party acknowledges that their breach of the provisions of this
  Agreement may cause irreparable harm to the Disclosing Party, and that remedies at law
  may be inadequate. Therefore, in addition to any other remedies available at law or in
  equity, the Disclosing Party may seek specific performance or injunctive relief in the event of
  any breach or attempted breach of this Agreement by the Receiving Party without proving
  actual damages.`,
  `Governing Law. The construction, validity and performance of this Agreement shall be
  governed by the laws of the State of California, and the Parties hereto submit to the
  exclusive jurisdiction of the State of California. A person or a party who is not a party to this
  Agreement has no rights under the Agreement (Rights of Third Parties) to enforce any terms
  of this Agreement but this does not affect any right or remedy of a third party, if any, which
  exists or is available under the law. The Receiving Party agrees to reimburse the disclosing
  party for all costs and expenses, including reasonable attorneys’ fees and expert witness
  costs, incurred in enforcing any provisions of this Agreement.`,
];
