import { numberWithCommas } from '@ground/tools';
import { Dropdown, DropdownOptionValue, GIcon } from '@ground/ui';
import { Box, Button, Typography } from '@mui/material';
import { isNumber } from 'lodash-es';
import { useSelector } from 'react-redux';

import { iRootState } from 'store';
import AddGpointButton from 'views/gstore/payment/AddGpointButton';

interface Props {
  isDisplayPrice?: boolean;
  isOrderAvailable?: boolean;
  orderButtonLabel?: string;
  isPointLack?: boolean;
  productPoint?: number;
  onComplete?: () => void;
  userPoint?: number;
  orderButtonClickHandler?: () => void;
  isDisabledButton?: boolean;
  totalPrice?: number;
  note?: string;

  itemOptions?: {
    name: string;
    value: string;
  }[];
  amountOptions?: {
    id: number;
    name: string;
    value: number | string;
    qty: number;
    disabled: boolean;
    price: number;
  }[];
  showDisplayOptions?: boolean;
  onAmountChangeValue?: (option: DropdownOptionValue) => void;
  onItemChangeValue?: (option: DropdownOptionValue) => void;
}

export default function PaymentWidget({
  isDisplayPrice,
  productPoint,
  isOrderAvailable,
  orderButtonLabel,
  isPointLack,
  onComplete,
  userPoint,
  orderButtonClickHandler,
  isDisabledButton,
  totalPrice,
  note,
  itemOptions,
  amountOptions,
  showDisplayOptions,
  onAmountChangeValue,
  onItemChangeValue,
}: Props) {
  const { isLogin } = useSelector((state: iRootState) => state.login);

  return (
    <Box width="300px">
      {isLogin && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="body3" color="text.secondary2">
              My G.Points
            </Typography>
            <Typography variant="body2" color="text.secondary">{`${numberWithCommas(
              userPoint ?? 0
            )} G.P`}</Typography>
          </Box>
          <AddGpointButton onComplete={onComplete} label="Add Your G.Points" />
        </Box>
      )}
      {/* 상품 가격 */}
      {isDisplayPrice && (
        <Typography variant="h5" marginTop={isLogin ? '20px' : 0}>
          {isNumber(productPoint) ? `${numberWithCommas(productPoint)} G.P` : ''}
        </Typography>
      )}
      {note ? (
        <Typography variant="button3" color="text.secondary2" mt="20px">
          {note}
        </Typography>
      ) : null}

      {showDisplayOptions && (
        <>
          {itemOptions && (
            <Box mt="20px">
              <Dropdown
                placeholder="Select Item"
                options={itemOptions}
                onChangeValue={onItemChangeValue}
              />
            </Box>
          )}
          {amountOptions && (
            <Box mt="8px">
              <Dropdown
                placeholder="Select Amount"
                options={amountOptions}
                onChangeValue={onAmountChangeValue}
                // disabled={!!!amountOptions.length}
              />
            </Box>
          )}
        </>
      )}

      {typeof totalPrice === 'number' && isLogin && (
        <Box mt="20px" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" color="text.secondary">
            Total
          </Typography>
          <Typography variant="h5" color="white">
            {isNumber(totalPrice) ? `${numberWithCommas(totalPrice)} G.P` : ''}
          </Typography>
        </Box>
      )}

      {/* 주문 버튼 */}
      {isOrderAvailable ? (
        <Button
          variant="contained"
          color={isDisabledButton ? 'dark' : 'primary'}
          onClick={orderButtonClickHandler}
          disabled={isDisabledButton}
          fullWidth
          sx={{ marginTop: '20px' }}
        >
          {orderButtonLabel}
        </Button>
      ) : (
        <Button variant="contained" color="dark" disabled fullWidth sx={{ marginTop: '20px' }}>
          Not Available
        </Button>
      )}

      {isLogin && isPointLack && (
        <Box display="flex" alignItems="center" gap="12px" mt="20px">
          <GIcon
            iconName="exclamation"
            iconColor="warning.main"
            iconSize={16}
            size={32}
            backgroundColor="gray.80"
            variant="circle"
          />
          <Typography variant="small2" color="text.secondary2" flex={1}>
            You don't have enough points to buy this product. Please earn more points
          </Typography>
        </Box>
      )}
    </Box>
  );
}
