import queryString from 'query-string';

/**
 * 자동 로그 수집 허용 여부를 판단하는 함수
 * @param search url queryString
 * @returns 로그 수집 허용 여부 (true: 수집하면 안됨)
 */

// 자동 View 로그 수집하지 않아야 하는 query key 목록
/**
 * clips 영상보기 queryString이 type key를 가지고 있지 않아
 * 하단의 목록에 임시로 포함하였다.
 * => /detail/:gameId/clips?videoId=#&gameId=#
 * @todo popup queries를 모두 수정후, "type, videoId" 를 제거해준다.
 */
const restrictedQueryKeysForAutoViewLog = ['popup', 'type', 'videoId'];

export const restrictedLog = (search: string) => {
  const stringified = queryString.parse(search);

  const found = Object.entries(stringified).findIndex(([key, _value]) => {
    return restrictedQueryKeysForAutoViewLog.includes(key);
  });

  return Boolean(found !== -1);
};

/**
 * 로그에 수집하면 안되는 queries 제거하여 valid queries를 반환하는 함수.
 * @param search queryString
 * @returns 수집 가능한 queries
 *
 * @use UI 단에서만 사용되는 것이 아닌 유의미한 데이터를 서버에 보내기 위한 처리.
 */

// 로그에 수집하면 안되는 query key 목록
/**
 * @todo popup queries를 모두 수정후, "type, videoId" 를 제거해준다.
 */
const restrictedQueryKeys = ['popup', 'type'];

export const getValidQueries = (search: string): { [key: string]: string | string[] | null } => {
  const stringified = queryString.parse(search);
  const filtered = Object.entries(stringified).filter(([key, _value]) => {
    return !restrictedQueryKeys.includes(key);
  });

  return Object.fromEntries(filtered);
};
