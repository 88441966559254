import { Box, Typography } from '@mui/material';

import { ImageBox } from 'atoms';
import { GameControlGuides } from 'types/GameDetailTypes';

interface Props {
  game_control_guides: GameControlGuides[];
}

export default function HowToPlayControlGuide({ game_control_guides }: Props) {
  return (
    <Box marginBottom="100px">
      <Typography variant="h5">Game Control Guide</Typography>
      {game_control_guides[0].content && (
        <Typography variant="body1" marginTop="40px">
          {game_control_guides[0].content}
        </Typography>
      )}
      {game_control_guides[0].file_url && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '40px',
            backgroundColor: 'gray.100',
          }}
        >
          <ImageBox
            alt="game control guide"
            src={game_control_guides[0].file_url}
            resize
            resizeWidth={1120}
            format="webp"
          />
        </Box>
      )}
    </Box>
  );
}
