import { styled, Tabs } from '@mui/material';

export interface NewGTabsProps {
  /** bottom divider 유무 */
  disableDivider?: boolean;
  /** Indicator 색상 */
  indicatorBgColor?: string;
  /** Indicator 두께 */
  indicatorHeight?: number;
  /** Tab의 크기와 간격에 영향 */
  size?: 'small' | 'medium' | 'large';
  gap?: number;
}

const NewGTabs = styled(Tabs, {
  shouldForwardProp: (prop) =>
    prop !== 'disableDivider' &&
    prop !== 'indicatorBgColor' &&
    prop !== 'indicatorHeight' &&
    prop !== 'size' &&
    prop !== 'gap',
})<NewGTabsProps>(
  ({
    theme,
    disableDivider,
    indicatorHeight = 1,
    indicatorBgColor = theme.palette.text.primary,
    size,
    gap,
  }) => ({
    ...(size === 'medium'
      ? theme.typography.subtitle1
      : size === 'small'
      ? theme.typography.small1
      : theme.typography.h5),
    minHeight: 'auto',
    height: 'auto',
    ...(!disableDivider && { borderBottom: `1px solid ${theme.palette.opacity.white[5]}` }),
    '& .MuiTabs-flexContainer': {
      gap: gap ?? size === 'large' ? 50 : 40,
    },
    '& .MuiTabs-indicator': {
      height: indicatorHeight,
      backgroundColor: indicatorBgColor,
    },
  })
);

export default NewGTabs;
