import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useRecommendGameDialog } from 'hooks';

export default function DialogContainer() {
  const { search } = useLocation();
  const { dialog } = queryString.parse(search ?? '');

  const { showRecommendGameDialog } = useRecommendGameDialog();

  useEffect(() => {
    if (Array.isArray(dialog)) {
      // TODO: dialog 여러개 띄우는 경우가 생기면 구현
      // 동시에 겹치면서 뜨게 하거나 하나씩 차례대로 뜨도록 구현
      // dialogContext에 dialog를 id별로 여러개 띄우느 기능이 우선되어야 함
    } else {
      if (dialog === 'recommend-game') {
        // show recommend game
        // 로딩중인 경우 전체화면 로딩 기능 추가
        showRecommendGameDialog();
      }
    }
    // showRecommendGameDialog를 deps에 넣으면 로그아웃 시 showRecommendGameDialog가 리렌더링 되어 함수가 재호출됨
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  return null;
}
