import GStoreOrderDetailProvider from './GStoreOrderDetailProvider';
import { GStoreOrderDetailMain } from './GStoreOrderDetailMain';

export default function GStoreOrderDetail() {
  return (
    <GStoreOrderDetailProvider>
      <GStoreOrderDetailMain />
    </GStoreOrderDetailProvider>
  );
}
