import { Typography, Box, styled, Link } from '@mui/material';
import { useLog } from 'utils/userLog';
import { GHashLink } from 'elements';

const StyledHashLink = styled(GHashLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',

  '&:hover': {
    color: theme.palette.light.light,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',

  '&:hover': {
    color: theme.palette.light.light,
  },
}));

interface Data {
  text: string;
  url: string;
  target?: string;
  logCode: string;
}

export interface SitemapProps {
  title: string;
  dataList: Data[];
}

export default function SitemapList({ title, dataList }: SitemapProps) {
  const { gxcTag } = useLog();

  return (
    <Box component="dl" sx={{ margin: 0, width: 275 }}>
      <Typography
        component="dt"
        variant="small1"
        color="text.secondary"
        sx={{ marginBottom: '24px' }}
      >
        {title}
      </Typography>
      <Box display="flex" flexDirection={'column'} gap="12px">
        {dataList.map((data, idx) => (
          <Typography component="dd" variant="body3" key={idx}>
            <div
              onClick={() => {
                gxcTag('click', { area: 'footer', ui: data.logCode });
              }}
            >
              {data.url.startsWith('http') ? (
                <StyledLink href={data.url} target={data.target}>
                  {data.text}
                </StyledLink>
              ) : (
                <StyledHashLink to={data.url} target={data.target}>
                  {data.text}
                </StyledHashLink>
              )}
            </div>
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
