import { Box, Divider, Typography } from '@mui/material';

import { format } from 'date-fns';
import { useEffect } from 'react';

import WebFont from 'webfontloader';

import { clientApplyContents } from '../contents/clientApply';

interface ClientApplyContentsProps {
  ndaData: {
    fullname: string;
    nda_agree_at: string;
    studio_name: string;
    title: string;
  };
}

/** nda 계약서 */
export default function ClientApplyContents(props: ClientApplyContentsProps) {
  const { fullname, nda_agree_at, studio_name } = props.ndaData;

  useEffect(() => {
    window.scrollTo(0, 0);
    WebFont.load({
      google: {
        families: ['La Belle Aurore'],
      },
    });
  }, []);

  return (
    <Box width="1200px" mb="40px" p="60px" sx={{ background: (theme) => theme.palette.gray[80] }}>
      <Box
        mb="40px"
        p="40px"
        sx={{
          background: (theme) => theme.palette.gray[100],
        }}
      >
        <Box p="60px" sx={{ background: 'white' }} height="1400px" overflow="auto">
          <Typography variant="h5" color="gray.100" mb="40px">
            Non-Disclosure and Confidentiality Agreement
          </Typography>
          <Typography variant="body1" color="gray.100" mb="40px">
            This Nondisclosure Agreement (the “Agreement”) is entered into by and between{' '}
            <Typography color="primary.main" component="span">
              {studio_name}
            </Typography>{' '}
            (“Disclosing Party“), and{' '}
            <Typography color="primary.main" component="span">
              {fullname}
            </Typography>{' '}
            (“Receiving Party“) for the purpose of preventing the unauthorized disclosure of
            Confidential Information as defined below. The parties agree to enter into a
            confidential relationship with respect to the disclosure of certain proprietary and
            confidential information (“Confidential Information”).
          </Typography>

          <Typography type="1" component="ol" pl="30px" mb="80px">
            {clientApplyContents.map((text, index) => (
              <Typography key={index} variant="body1" color="gray.100" mb="28px" component={'li'}>
                {text}
              </Typography>
            ))}

            <Typography variant="body1" color="gray.100" mb="28px">
              This Agreement and each party’s obligation shall be binding on the representatives,
              assigns and successors of such party. Each party has signed this Agreement through its
              authorized representative.
            </Typography>
          </Typography>

          <Box mb="80px">
            <Typography variant="h5" color="gray.100" mb="20px">
              DISCLOSING PARTY
            </Typography>
            <Typography variant="subtitle1" color="primary.main" mb="20px">
              {studio_name}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box display="flex" position="relative">
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {studio_name}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(nda_agree_at ? new Date(nda_agree_at) : new Date(), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" color="gray.100" mb="20px">
              RECEIVING PARTY
            </Typography>
            <Typography variant="subtitle1" color="primary.main" mb="20px">
              {fullname}
            </Typography>

            <Box display="flex" gap="20px" mb="20px">
              <Typography variant="body1" color="gray.100">
                Signature:
              </Typography>
              <Box
                display="flex"
                position="relative"
                width="400px"
                justifyContent={'space-between'}
              >
                <Typography
                  sx={{
                    fontFamily: 'La Belle Aurore',
                    fontSize: 26,
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  color="gray.100"
                >
                  {fullname}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="55px">
              <Typography variant="body1" color="gray.100">
                Date:
              </Typography>
              <Box display="flex" position="relative">
                <Typography variant="body1" color="gray.100">
                  {format(nda_agree_at ? new Date(nda_agree_at) : new Date(), 'MM/dd/yyyy')}
                </Typography>

                <Divider
                  sx={{
                    position: 'absolute',
                    top: '-2px',
                    left: '-10px',
                    borderBottom: (theme) => `1px solid ${theme.palette.gray[100]}`,
                    height: 1,
                    width: '400px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
