import { Routes, Route, Navigate } from 'react-router-dom';
import WithdrawalMain from './WithdrawalMain';

export default function WithdrawalRouter() {
  return (
    <Routes>
      <Route index element={<WithdrawalMain />} />
      <Route path="*" element={<Navigate to="withdrawal" replace />} />
    </Routes>
  );
}
