import { LoadingWrapper } from '@ground/ui';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { usePrivateGameMyReviewListV2API } from 'api/comment/commentAPI';
import { NoData } from 'elements';
import MyReviewPrivateContainer from './MyReviewPrivateContainer';

export default function MyCommentsPrivate() {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading, mutate } = usePrivateGameMyReviewListV2API(id);
  const reviewList = data?.data;
  const hasReviewList = reviewList && Array.isArray(reviewList) && reviewList.length > 0;

  return (
    <LoadingWrapper isLoading={isLoading} size={64}>
      {hasReviewList ? (
        <Stack gap="20px" px="60px">
          {reviewList.map((review) => (
            <MyReviewPrivateContainer
              key={review.id}
              reviewData={review}
              mutateReviewList={mutate}
            />
          ))}
        </Stack>
      ) : (
        <Box sx={{ height: 474 }}>
          <NoData title="Leave a review!" subTitle="We're eager to hear your thoughts." />
        </Box>
      )}
    </LoadingWrapper>
  );
}
