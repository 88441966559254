import { FormInput, FormRadioGroup, SelectionProps } from '@ground/ui';
import { Box, Button, Divider } from '@mui/material';
import { useQueryDispatch } from 'context';
import { get, useFormContext } from 'react-hook-form';
import SupporterQuestionTitle from '../../jpn/question/SupporterApplyQuestionTitle';
import FileUploadButton from '../../jpn/question/SuppoterApplyFileUploadArea';

const options: SelectionProps[] = [
  {
    id: 1,
    order: 1,
    label: 'Paypal',
  },
  {
    id: 2,
    order: 2,
    label: 'Wise',
  },
];

export default function SupporterApplyStep2Content() {
  const { changeQuery } = useQueryDispatch();
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb="40px">
        {/* payment 영역 */}
        <Box display="flex" flexDirection="column">
          <SupporterQuestionTitle
            error={!!get(errors, 'payment_info')}
            question="お支払いアカウントです"
            required
          />

          <FormRadioGroup
            options={options}
            name={'payment_info'}
            rules={{ required: true }}
            sx={{ display: 'flex', gap: '40px' }}
            radio={{ color: 'private' }}
          />
        </Box>

        {/* 이메일 영역 */}
        <Box width={510} display="flex" flexDirection={'column'}>
          <SupporterQuestionTitle
            question="Eメールです "
            error={!!get(errors, 'payment_email')}
            required
            mb="8px"
          />
          <FormInput
            type="text"
            name="payment_email"
            fullWidth
            placeholder="回答を入力してください"
            withBackground
            rules={{ required: true }}
          />
        </Box>
      </Box>

      <Divider sx={{ margin: '40px 0 40px' }} />

      <FileUploadButton />

      <Divider sx={{ margin: '40px 0' }} />

      <Box display="flex" justifyContent="space-between">
        <Button
          onClick={() => {
            if (!getValues('files')) {
              setValue('files', []);
            }
            changeQuery({ step: 1 });
          }}
          variant="ghost"
          size="large"
        >
          前のページです
        </Button>

        <Button type="submit" color="private" variant="contained" size="large">
          次へです
        </Button>
      </Box>
    </Box>
  );
}
