import { GIcon } from '@ground/ui';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReadNotificationAPI } from 'api/notification/notificationAPI';
import type { NotificationTypes } from 'types/NotificationType';
import { NotificationCategory } from 'types/NotificationType';
import { getKeyNameOfValue } from 'utils/objectFormatter';
import { useLog } from 'utils/userLog';

function isToday(date: Date, currentDate: Date) {
  return currentDate.toDateString() === date.toDateString();
}

const IconImage = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
});

const EmptyImage = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background: theme.palette.grey[800],
}));

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLast',
})<{ isLast: boolean }>(({ theme }) => ({
  display: 'flex',
  paddingTop: '12px',
  paddingBottom: '12px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.gray[80],
  cursor: 'pointer',
}));

const RedDot = styled(Box)(({ theme }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  position: 'absolute',
  right: 2,
  top: 4,
  backgroundColor: theme.palette.error.main,
  border: `2px solid ${theme.palette.gray[80]}`,
}));

interface Props {
  badgeId: number; // 뱃지(=알림) 고유값
  badgeSort: NotificationTypes.Category; // 뱃지 카테고리
  message: string; // 뱃지 메시지
  imageUrl: string | null; // 뱃지 thumbnail 이미지
  link: string | null; // 뱃지 landing url
  DateReceived: string; // 뱃지 발송 받은 날짜
  isRead: boolean; // 뱃지 읽음 여부
  isLast: boolean; // 마지막 뱃지 여부
  onRead?: () => void;
  setIsListOpen: Dispatch<React.SetStateAction<boolean>>;
}

export function NotificationItem({
  badgeId,
  badgeSort,
  message,
  link,
  imageUrl,
  DateReceived,
  isRead,
  isLast,
  onRead,
  setIsListOpen,
}: Props) {
  const theme = useTheme();
  const { gxcTag } = useLog();
  const navigate = useNavigate();

  const [read, setRead] = useState(isRead);

  const { fetch: fetchNotificationRead } = useReadNotificationAPI(badgeId);

  const handleClick = useCallback(() => {
    gxcTag('click', {
      area: 'gnb',
      ui: 'notification_list',
      parameters: { notification_id: String(badgeId) },
    });
    // 노티 읽음 처리하는 부분
    if (!read) {
      setRead(true);
      fetchNotificationRead().then((v) => onRead?.());
    }
    // 링크가 있다면 이동
    if (link) {
      setIsListOpen(false);
      if (link.startsWith('http')) {
        // 외부 링크
        window.open(link, '_blank', 'noopener noreferrer');
      } else {
        // 내부 링크
        navigate(link);
      }
    }
  }, [badgeId, fetchNotificationRead, gxcTag, link, navigate, onRead, read, setIsListOpen]);

  return (
    <Container onClick={handleClick} isLast={isLast}>
      <Box
        sx={{
          position: 'relative',
          width: '60px',
          height: '60px',
          marginRight: '16px',
        }}
      >
        {imageUrl ? (
          <IconImage
            src={imageUrl}
            alt="avatar"
            style={{ objectFit: 'cover', ...(read && { filter: 'grayscale(100%)', opacity: 0.5 }) }}
          />
        ) : (
          <EmptyImage />
        )}
        {!read && <RedDot />}
      </Box>

      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Typography variant="body2" color={read ? 'text.secondary2' : 'primary'}>
            {getKeyNameOfValue(NotificationCategory, badgeSort)}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          color={read ? 'text.secondary2' : 'text.secondary'}
          mt="4px"
          width="300px"
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{
            __html: message
              .replace(
                /{{/gi,
                `<span style="color: ${
                  read ? theme.palette.text.secondary2 : theme.palette.text.primary
                }; font-weight: 700">`
              )
              .replace(/}}/gi, '</span>'),
          }}
        />

        {/**
         * 알림 발송된 날짜/시간 표출
         * 1) Today 알림의 경우 -> Today 텍스트 옆에 알림 시분 표출 [Today hh:mm]
         * 2) 어제 이전 알림의 경우 -> 날짜만 표출 [MMM DD, YYYY]
         */}
        <Typography variant="body3" color="text.secondary2" mt="4px">
          {!DateReceived
            ? '-'
            : isToday(new Date(), new Date(DateReceived))
            ? 'Today ' + format(new Date(DateReceived), 'hh:mm')
            : format(new Date(DateReceived), 'MMM dd, yyyy')}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" marginLeft="12px" width="16px">
        {link && <GIcon iconName="arrow_right" iconSize={16} iconOpacity={read ? 0.4 : 1} />}
      </Box>
    </Container>
  );
}
