import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/youtube';

import { useLog } from 'utils/userLog';

export default function AboutYoutubePlayer({
  url,
  globalPause,
}: {
  url: string;
  globalPause: boolean;
}) {
  const { gxcTag } = useLog();
  const [playing, setPlaying] = useState(false);

  // 슬라이더가 swipe 될 때 모든 재생 중인 유튜브 pause
  useEffect(() => {
    if (globalPause) {
      setPlaying(false);
    }
  }, [globalPause]);

  const handlePlay = () => {
    setPlaying(true);
    gxcTag('click', {
      area: 'detail',
      ui: 'youtube_play',
    });
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleEnded = () => {
    setPlaying(false);
  };

  return (
    <ReactPlayer
      url={url}
      width={760}
      height={427}
      controls
      playing={playing}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnded={handleEnded}
    />
  );
}
