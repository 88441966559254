import { FormCheckbox } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';

import { useGetSavedAddressAPI } from 'api/gstore/gstoreAPI';
import OrderShippingInfoForm from './OrderShippingInfoForm';
import { IStoreMerchandise } from 'api/gstore';

const FormBox = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.gray[90],
  borderRadius: 5,
  padding: 40,
  marginTop: 20,
}));

interface IProps {
  countries?: IStoreMerchandise.GetMerchandiseDetail.Country[];
}

export default function OrderShippingInfo({ countries }: IProps) {
  const { isValidating, data } = useGetSavedAddressAPI();

  const savedAddressData = data?.data;

  return (
    <Box flex={1}>
      <Typography variant="h5" color="text.primary">
        Shipping Information
      </Typography>

      {!isValidating && countries && savedAddressData && (
        <FormBox>
          <OrderShippingInfoForm
            countries={countries.map((country) => ({
              ...country,
              id_card_item: typeof country?.id_card_item === 'number' ? country.id_card_item : null,
              id_card_title:
                typeof country?.id_card_title === 'string' ? country.id_card_title : null,
            }))}
          />
          <Box mt="40px">
            <FormCheckbox name="save_destination" label="Save This Address" defaultChecked />
          </Box>
        </FormBox>
      )}
    </Box>
  );
}
