import { Typography } from '@mui/material';

/** 초를 HH:MM 로 변환해주는 함수 */
const getPlayTime = (time: number) => {
  const hasHour = time >= 3600;

  return `${hasHour ? `${Math.floor(time / 3600)}h ` : ''}${(Math.floor(time / 60) % 60)
    .toString()
    .padStart(hasHour ? 2 : 1, '0')}m`;
};

type DeployType = 'GENERAL' | 'STEAM' | 'EPIC' | 'SELF';

interface ReviewTimestampProps {
  deployType?: DeployType;
  playingTime?: number | null;
  totalPlayingTime?: number | null;
}
export default function ReviewTimestamp(props: ReviewTimestampProps) {
  const { deployType, playingTime, totalPlayingTime } = props;

  return deployType === 'EPIC' || deployType === 'SELF' || playingTime ? (
    <Typography color="text.secondary2" variant="small2" ml="36px">
      {deployType === 'EPIC'
        ? 'Played on Epic Games Launcher'
        : deployType === 'SELF'
        ? "Played on Studio's Launcher"
        : playingTime
        ? `${getPlayTime(playingTime)} at the time of review${
            totalPlayingTime ? ` (Total Playtime - ${getPlayTime(totalPlayingTime)})` : ''
          }`
        : null}
    </Typography>
  ) : null;
}
