import { GCheckbox } from '@ground/ui';
import { Box, ButtonBase, Link, Typography } from '@mui/material';
import { useState } from 'react';

interface Props {
  onChange: (value: boolean) => void;
}

export const AgreeTermText = ({ onChange }: Props) => {
  const [toggleAgree, setToggleAgree] = useState(false);

  return (
    <ButtonBase
      onClick={() => {
        setToggleAgree((value) => {
          onChange?.(!value);
          return !value;
        });
      }}
    >
      <Box sx={{ alignSelf: 'flex-start' }}>
        <GCheckbox checked={toggleAgree} />
      </Box>
      <Typography variant="body2" color="text.secondary" ml="8px" sx={{ textAlign: 'start' }}>
        By placing my order, I agree and accept that G.Points cannot be canceled once purchased, as
        well as the{' '}
        <Link href="/userTerms.pdf" target="_blank" rel="noopener noreferrer" color="text.primary">
          Terms of Use
        </Link>{' '}
        &{' '}
        <Link
          href="/privacyPolicy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          color="text.primary"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </ButtonBase>
  );
};
