import { ReactNode } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  width: 226,
  paddingLeft: 24,
  paddingRight: 24,
  borderRight: `1px solid ${theme.palette.divider}`,
  '&:nth-of-type(1)': {
    width: 200,
    paddingLeft: 0,
  },
  '&:nth-of-type(5)': {
    width: 196,
    paddingRight: 0,
    borderRight: 'none',
  },

  '&:nth-of-type(2) > ul': {
    listStyle: 'disc',
    // textIndent: -5,
    paddingLeft: 10,
  },
}));

const ListStyled = styled('li')({
  marginBottom: 16,
});

export interface ArtDevOnBoardCardProps {
  id: number;
  title: string;
  period: ReactNode;
  stage: string;
  desc: ReactNode[];
}

export default function ArtDevOnBoardList({
  id,
  title,
  period,
  stage,
  desc,
}: ArtDevOnBoardCardProps) {
  return (
    <Root>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '32px', height: '32px', backgroundColor: '#3C53C6', borderRadius: '3px' }}
      >
        {id}
      </Box>
      <Typography variant="subtitle1" sx={{ marginTop: '20px', marginBottom: '48px' }}>
        {title}
      </Typography>
      <Box sx={{ height: '54px' }}>
        <Typography variant="h6">{period}</Typography>
        <Typography variant="body3" sx={{ marginTop: '4px', opacity: 0.6 }}>
          {stage}
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={(theme) => ({
          ...theme.typography.body2,
          marginTop: '40px',
          paddingLeft: 0,
          listStyle: 'none',
          opacity: 0.6,
        })}
      >
        {desc.map((item, index) => (
          <ListStyled key={index}>{item}</ListStyled>
        ))}
      </Box>
    </Root>
  );
}
