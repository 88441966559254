import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/dist/types';

import {
  IGStoreDigitalProductOrderDetail,
  useGetDigitalProductOrderDetailAPI,
} from 'api/gstore/gstoreAPI';
import { ResponseData } from 'types/ResponseType';

interface ContextProps {
  orderId?: string;
  data?: ResponseData<IGStoreDigitalProductOrderDetail>;
  isLoading?: boolean;
  mutate?: KeyedMutator<ResponseData<IGStoreDigitalProductOrderDetail>>;
}

const GStoreOrderDetailContext = createContext<ContextProps | undefined>(undefined);

interface IProps {
  children: ReactNode;
}

export default function GStoreOrderDetailDigitalProvider({ children }: IProps) {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const { isLoading, data, mutate } = useGetDigitalProductOrderDetailAPI(orderId);

  return (
    <GStoreOrderDetailContext.Provider
      value={{
        data,
        isLoading,
        orderId,
        mutate,
      }}
    >
      {children}
    </GStoreOrderDetailContext.Provider>
  );
}

export const useGStoreOrderDetailDigitalData = () => {
  const context = useContext(GStoreOrderDetailContext);
  if (!context) throw new Error('GStoreOrderDetailData context not found');
  return context;
};
