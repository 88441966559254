import { isEqual, merge } from 'lodash-es';
import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { iRootState } from 'store';
import type { Log, LogAction, PathLog, Platform } from 'utils/userLog/types/logTypes';
import { getCurrentTime } from 'utils/userLog/utils/dateFns';
import { getUrlBasedLog } from 'utils/userLog/utils/getUrlBasedLog';
import { objectKeyToSnakeCase } from 'utils/userLog/utils/toSnakeCase';
import packageJson from '../../../../package.json';

type AppendLogParams = Partial<Omit<PathLog, 'service' | 'page'>> & Pick<Log, 'action'>;

const storageKey = 'logs';

export function useLog() {
  const { pathname, search } = useLocation();
  const {
    login: { user_id },
    mobile: { isDesktopTransformed },
  } = useSelector((state: iRootState) => state);
  const isMobile = isMobileOnly && !isDesktopTransformed;

  const getLogs = useCallback((): Log[] => {
    const storedLogs = sessionStorage.getItem(storageKey);
    return storedLogs ? JSON.parse(storedLogs) : [];
  }, []);

  const saveLog = useCallback(
    (updated: AppendLogParams) => {
      // REACT_APP_LOG 값이 'OFF'면 로그를 쌓지 않음
      if (process.env.REACT_APP_LOG === 'OFF') return;

      const platformName: Platform = isMobile ? 'mobile_web' : 'pc_web';
      const pathLogs = getUrlBasedLog({ path: pathname, search });

      if (!pathLogs) {
        // do nothing
        return;
      }

      const currentLogs = getLogs();

      // updated에서 parameters를 추가할 경우 pathLogs의 parameters가 덮어써져 merge로 변경
      let newLog: Omit<Log, 'created_at'> = merge(
        {
          platform: platformName,
          user_id: String(user_id),
          version: packageJson.version,
        },
        pathLogs,
        updated
      );

      // parameter를 snake_case로 변경하기
      const formattedParams =
        typeof newLog?.parameters !== 'string'
          ? objectKeyToSnakeCase(newLog.parameters) || 'N/A'
          : 'N/A';

      newLog = { ...newLog, parameters: formattedParams };

      // 2024.01.03 BC 중복 데이터도 수집해야 함
      // 중복 데이터 수집 방지 (로그 수집 시간을 제외한 데이터 체크)
      // if (currentLogs.length > 0) {
      //   let existingLog = currentLogs[currentLogs.length - 1];
      //   const { created_at, ...rest } = existingLog;
      //   const previousLog = rest;

      //   if (isEqual(previousLog, newLog)) {
      //     // do nothing
      //     return;
      //   }
      // }

      if (process.env.NODE_ENV === 'development') {
        console.debug(
          'new log',
          JSON.stringify({ ...newLog, created_at: getCurrentTime() }, null, '\t')
        );
      }

      const updatedLogs: Log[] = [...currentLogs, { ...newLog, created_at: getCurrentTime() }];

      sessionStorage.setItem(storageKey, JSON.stringify(updatedLogs));
    },
    [getLogs, isMobile, pathname, search, user_id]
  );

  const clearLogs = useCallback(
    (logsToDelete: Log[]) => {
      const currentLogs = getLogs();

      // 서버에 전송한 데이터 외에 새로 추가된 로그 데이터 유실 방지
      // e.g. 서버에 데이터를 전송하는 동안 쌓인 로그
      if (!isEqual(currentLogs, logsToDelete)) {
        const latestLogs = currentLogs.slice(logsToDelete.length);

        sessionStorage.setItem(storageKey, JSON.stringify(latestLogs));

        return;
      }

      sessionStorage.removeItem(storageKey);
      return;
    },
    [getLogs]
  );

  const memoizedGxcTag = useCallback(
    (action: LogAction, values?: Omit<AppendLogParams, 'action'>) => saveLog({ ...values, action }),
    [saveLog]
  );

  return {
    getLogs,
    gxcTag: memoizedGxcTag,
    clearLogs,
  };
}
