import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigation, SwiperOptions, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/swiper';

import { ImageBox } from 'atoms';
import { YoutubePlayer } from 'components';
import { useLog } from 'utils/userLog';
import { useClipPopupData } from '../ClipPopupProvider';
import CustomSwiper from '../CustomSwiper';

const swiperParams: SwiperOptions = {
  slidesPerView: 4,
  spaceBetween: 16,
  threshold: 1000, // Thumb Slider에서 allowTouchMove: false 사용 시 마우스를 아래로 조금만 움직여도 터치 이벤트가 막혀 threshold 값으로 대체함
};

interface PropTypes {
  area?: string;
  initialSlide: number;
  clipsData?: any[];
  disableThumbnail?: boolean;
}

export function ClipUpperArea({ clipsData = [], disableThumbnail, initialSlide, area }: PropTypes) {
  const { gxcTag } = useLog();
  const { setDataIndex } = useClipPopupData();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [pause, setPause] = useState(false);
  const handleTransitionStart = () => setPause(true);
  const handleTransitionEnd = () => setPause(false);

  return (
    <>
      <Swiper
        initialSlide={initialSlide}
        onSlideChangeTransitionStart={handleTransitionStart}
        onSlideChangeTransitionEnd={handleTransitionEnd}
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        onRealIndexChange={(item) => {
          setDataIndex(item.realIndex);
        }}
      >
        {clipsData?.map((content) => (
          <SwiperSlide key={content.id}>
            {({ isActive }) => (
              <YoutubePlayer
                width="800px"
                height="450px"
                url={content.link}
                globalPause={pause}
                autoplay={isActive}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {clipsData?.length && !disableThumbnail ? (
        <Box
          sx={{
            background: (theme) => theme.palette.gray[90],
            padding: '12px 20px',
          }}
        >
          <Typography color="text.secondary2" variant="subtitle3" mb="8px" ml="36px">
            Related Clips
          </Typography>

          <CustomSwiper
            style={{ overflow: 'hidden' }}
            name="clipsPopup"
            className="clips_popup"
            {...swiperParams}
            hasNavigation
            width={688}
            navBtnRowPosition={-36}
            navigationPositionTop={50}
            onSwiper={setThumbsSwiper}
            modules={[Thumbs, Navigation]}
            nextNavDisabled={clipsData.length < 5}
          >
            {clipsData?.map((data) => (
              <SwiperSlide
                key={data.id}
                onClick={() =>
                  gxcTag('click', {
                    area: area,
                    ui: 'related_clips',
                    parameters: { clips_id: data.id },
                  })
                }
              >
                <ImageBox
                  src={data.thumbnail_url}
                  alt="clips image thumbnail"
                  resize
                  resizeWidth={160}
                  resizeHeight={90}
                  format="webp"
                />
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </Box>
      ) : null}
    </>
  );
}
