import { IconButton, styled } from '@mui/material';
import lottie from 'lottie-web/build/player/lottie_light';
import { useRef } from 'react';

import { useVideoLikeAPI } from 'api/like/likeAPI';

import likeIcon from 'images/icons/like.svg';
import unlikeIcon from 'images/icons/unlike.svg';
import lottieLikeButton from 'lotties/like-button.json';

const PREFIX = 'VideoClipLikeButton';

const classes = {
  likeButton: `${PREFIX}-likeButton`,
  lottie: `${PREFIX}-lottie`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.likeButton}`]: {
    position: 'relative',
    padding: 0,
    top: -2,
  },

  [`& .${classes.lottie}`]: {
    pointerEvents: 'none',
    position: 'absolute',
    width: 32,
    height: 32,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface Props {
  id: number;
  liked: boolean;
  height?: number;
  onLikeChange?: (liked: boolean) => void;
}

export default function VideoClipLikeButton({ id, liked, height = 14, onLikeChange }: Props) {
  const { fetchLike } = useVideoLikeAPI(id);
  // 로티 애니메이션으로 구성되는 element
  const element = useRef<HTMLDivElement>(null);

  const handleClickLike = async () => {
    fetchLike();

    if (element.current) {
      const lottieItem = lottie.loadAnimation({
        container: element.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: lottieLikeButton,
      });
      lottieItem.addEventListener('complete', function () {
        lottieItem.removeEventListener('complete');
        lottie.destroy();
      });
    }

    onLikeChange && onLikeChange(!liked);
  };

  return (
    <StyledIconButton
      className={classes.likeButton}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={handleClickLike}
      disabled={liked}
      size="small"
      data-cy="clip-like-button"
    >
      <img height={height} src={liked ? likeIcon : unlikeIcon} alt="like" />
      <div className={classes.lottie} ref={element}></div>
    </StyledIconButton>
  );
}
