import { Components, Theme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const DatePickersTheme: Components<Theme> = {
  MuiDateCalendar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.gray[60],
      }),
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      weekDayLabel: {
        fontSize: 14,
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: ({ theme }) => ({
        fontSize: 14,
        fontWeight: 500,
        width: '60px',
        ':hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-selected:focus': {
          backgroundColor: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: 14,
        ':hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-selected:focus': {
          backgroundColor: theme.palette.primary.main,
        },
      }),
    },
  },
};

export default DatePickersTheme;
