import { Link } from '@mui/material';

import { GHashLink } from 'elements';
import { useDialog } from 'hooks';
import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';
import PlayGameDialogBodyEpic from './playgame/PlayGameDialogBodyEpic';
import PlayGameDialogBodyGeneral from './playgame/PlayGameDialogBodyGeneral';
import PlayGameDialogBodySelf from './playgame/PlayGameDialogBodySelf';
import PlayGameDialogBodySteam from './playgame/PlayGameDialogBodySteam';

interface PlayGameDialogBodyProps {
  id: number;
  data?: GameDetailProps;
  reload: () => void;
}

export default function PlayGameDialogBody({ id, data, reload }: PlayGameDialogBodyProps) {
  const { closeDialog } = useDialog();

  const deploy_type = data?.game_files?.[0].deploy_type;

  const handleClickHowtoInstall = () => {
    closeDialog();

    eventTracker(`playgamepopup_${deploy_type?.toLowerCase()}_click_here`, { event_label: id });
  };

  return (
    <>
      {/* 최신 버전 런처를 사용하고 있지 않은 경우의 popup*/}
      {deploy_type === 'GENERAL' ? (
        <PlayGameDialogBodyGeneral data={data} />
      ) : deploy_type === 'STEAM' ? (
        <PlayGameDialogBodySteam data={data} />
      ) : deploy_type === 'EPIC' ? (
        <PlayGameDialogBodyEpic data={data} reload={reload} />
      ) : (
        <PlayGameDialogBodySelf data={data} reload={reload} />
      )}

      <Link
        color="text.secondary2"
        component={GHashLink}
        variant="small2"
        to={`/detail/${data?.id}/guide#detail-tab`}
        onClick={handleClickHowtoInstall}
        sx={{ display: 'block', marginTop: '20px' }}
      >
        Click here to learn how to play the game!
      </Link>
    </>
  );
}
