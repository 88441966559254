import { FormInput } from '@ground/ui';
import { Typography } from '@mui/material';

export default function PersonalDetails() {
  return (
    <>
      <Typography variant="subtitle2" color="text.secondary" mb="8px">
        Username
      </Typography>
      <FormInput
        fullWidth
        name="name"
        placeholder="Your username"
        rules={{
          validate: {
            minLength: (v) => v?.trim().length > 3,
            maxLength: (v) => v?.trim().length < 31,
            required: (v) => !!v?.trim(),
          },
        }}
        withBackground
        size="large"
      />
    </>
  );
}
