import { isNumber } from 'lodash-es';
import { useMemo } from 'react';

import { useGameEventBadgeListAPI, usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { useGetNotificationCountAPI } from 'api/notification/notificationAPI';
import GameListBigCard from 'components/GameList/GameListBigCard';
import { GameCardProps } from 'types/GameCardTypes';
import { getConvertTimeText } from 'utils/common';
import { useLog } from 'utils/userLog';

interface GameCardListBigContainerProps {
  count?: number;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  likeButton?: boolean;
}

export default function GameListBigContainer({
  count = 4,
  hoverVideoPlay,
  showViewMoreCard,
  likeButton,
}: GameCardListBigContainerProps) {
  const { isLoading, isValidating, data, error } = usePrivateGameListAPI();
  const { data: timeData } = useGetNotificationCountAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;
  const privateGameData = data?.data?.sort((a, b) =>
    isNumber(a?.order_seq) && isNumber(b?.order_seq) ? a?.order_seq - b?.order_seq : 1
  );
  const transactionTime = timeData?.transaction_time;
  const { gxcTag } = useLog();

  const gamelist: GameCardProps[] = useMemo(
    () =>
      Array.isArray(privateGameData) && privateGameData && privateGameData.length > 0
        ? privateGameData
            .map((data) => {
              const timeText = getConvertTimeText(data.started_at, transactionTime);
              return {
                ...data,
                hoverVideoPlay: hoverVideoPlay,
                isPrivateShow: !!timeText,
                remainingText: timeText,
              };
            })
            .slice(0, count)
        : [],
    [privateGameData, count, transactionTime, hoverVideoPlay]
  );

  return isDataNotExist ? null : (
    <GameListBigCard
      data={gamelist}
      loading={isLoading || isValidating}
      count={count}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={likeButton}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      onClickCard={(gameId) => {
        gxcTag('click', { area: 'play_test', ui: 'game_card', parameters: { game_id: gameId } });
      }}
    />
  );
}
