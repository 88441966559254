import { Box, Divider, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ReviewGauge } from 'components/ReviewGauge';
import { useGetDetailData } from 'views/detail/DetailProvider';
import DetailArchiveArea from './DetailArchiveArea';
import DetailGenre from './DetailGenre';
import DetailGpointButton from './DetailGpointButton';
import DetailIConArea from './DetailIConArea';
import DetailPlayButtonArea from './DetailPlayButtonArea';
import DetailTestInfo from './DetailTestInfo';

import { GameStatusType } from '../../../../types/GameCommonTypes';

export default function DetailInfo() {
  const { data, info } = useGetDetailData();
  const isPrivate = typeof data?.private_test === 'number';
  const hasGauge =
    typeof data?.required_review === 'number' && typeof data?.progressed_review === 'number';

  return (
    <Stack width={313}>
      {/* Test Period */}
      <Divider />
      {data && data.started_at && data.ended_at && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginY: '12px' }}
          >
            <Typography variant="body3" color="text.secondary2">
              Playtesting Period
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {format(new Date(data.started_at), 'MMM d, yyyy')} -{' '}
              {format(new Date(data.ended_at), 'MMM d, yyyy')}
            </Typography>
          </Stack>
          <Divider />
        </>
      )}
      {/* Game Description */}
      <Typography
        color="text.secondary"
        variant="body2"
        marginY="20px"
        height={data?.started_at ? '138px' : '182px'}
        maxHeight={data?.started_at ? '138px' : '182px'}
        whiteSpace="pre-wrap"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: data?.started_at ? 8 : 11,
        }}
      >
        {info?.description}
      </Typography>
      {/* Game Genre */}
      <Divider />
      <DetailGenre />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        marginTop="20px"
      >
        {!isPrivate && <DetailIConArea />}
        <DetailTestInfo />
      </Box>

      {data && !isPrivate && (
        <>
          <Divider sx={{ marginBottom: hasGauge ? '20px' : '0px', marginTop: '12px' }} />
          {hasGauge && (
            <ReviewGauge
              goal={data?.required_review}
              count={data?.progressed_review}
              rate={data?.progress_rate}
              haveReachingPopup={
                data?.state === GameStatusType.TESTING &&
                typeof data?.progress_rate === 'number' &&
                data?.progress_rate < 150
              }
            />
          )}
        </>
      )}

      {data?.state === 'CLOSED' ? <DetailArchiveArea /> : <DetailPlayButtonArea />}

      {!isPrivate && data?.state === 'TESTING' && <DetailGpointButton />}
    </Stack>
  );
}
