import { GSwitch } from '@ground/ui';
import { Box, Typography, styled } from '@mui/material';

const SettingElementBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
  width: '100%',
  borderBottom: '1px solid rgba(255,255,255,0.05)',
});

interface Props {
  text: string;
}

export function SettingItem({ text }: Props) {
  return (
    <SettingElementBox>
      <Typography color="text.secondary" variant="body2" sx={{ width: '324px' }}>
        {text}
      </Typography>
      <GSwitch size="small" />
    </SettingElementBox>
  );
}
