import { keyframes, styled } from '@mui/material';

import loadingImg from 'images/icons/loading-mask.svg';

const PREFIX = 'LoadingPage';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
};

const ellipsis = keyframes`
  0% {
    bottom: 0;
  }

  100% {
    bottom: 100%;
  }
`;

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.gray[100],
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.image}`]: {
    margin: 'auto',
  },
}));

const ImageWrapper = styled('div')(() => ({
  backgroundColor: 'rgb(18, 18, 18)',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  animation: `${ellipsis} .85s ease-in-out infinite alternate`,
  width: '100%',
}));

const Row = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

export function LoadingPage() {
  return (
    <Root className={classes.root}>
      <Row>
        <ImageWrapper></ImageWrapper>
        <img className={classes.image} src={loadingImg} width={40} height={40} alt="loading" />
      </Row>
    </Root>
  );
}
