import { useCallback } from 'react';

import { ModalDialog } from 'elements';
import { useGetDetailData } from '../DetailProvider';
import { useImportDraftData } from '../ImportDraftProvider';
import Survey from './Survey';
import SurveyProvider from './SurveyProvider';

export default function DetailSurvey() {
  const { openSurvey, setOpenSurvey } = useGetDetailData();
  const { setShouldImportSurveyDraftData } = useImportDraftData();

  const closeHandler = useCallback(() => {
    setOpenSurvey(false);
    setShouldImportSurveyDraftData(false);
  }, [setOpenSurvey, setShouldImportSurveyDraftData]);

  return (
    <ModalDialog
      open={openSurvey}
      onClose={closeHandler}
      title="Survey"
      body={
        <SurveyProvider>
          <Survey />
        </SurveyProvider>
      }
      width={1000}
      height={1000}
      disableEscapeKeyDown
      hasScrollBar
    />
  );
}
