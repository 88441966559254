import { GCheckbox, LoadingWrapper } from '@ground/ui';
import { Box, FormControlLabel, Stack } from '@mui/material';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetPrivateGameReviewListAPI } from 'api/comment/commentAPI';
import { NoData } from 'elements';
import ReviewContainerPrivateAdmin from './ReviewContainerPrivateAdmin';
import ReviewDialog from './ReviewDialog';

export default function ReviewsMainPrivateAdmin() {
  const { id = '' } = useParams<{ id: string }>();
  const [filterOptions, setFilterOptions] = useState({
    liked: false,
    replied: false,
  });

  const { search } = useLocation();
  const { review_id } = queryString.parse(search, { parseNumbers: true });

  const {
    data: reviewListData,
    isLoading,
    mutate,
  } = useGetPrivateGameReviewListAPI(id, filterOptions.liked, filterOptions.replied);

  const reviewList = reviewListData?.data ?? [];

  const hasReviewList = Array.isArray(reviewList) && reviewList.length > 0;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '24px',
          marginBottom: '40px',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <FormControlLabel
            control={
              <GCheckbox
                title="Liked"
                value={filterOptions.liked}
                onChange={(_, checked) => {
                  setFilterOptions((options) => ({ ...options, liked: checked }));
                }}
              />
            }
            slotProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
            label="Liked"
            sx={{
              margin: 0,
              '& .MuiFormControlLabel-label': { marginLeft: '8px' },
              // labelPlacement="start"인 경우 label margin 조정
              '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                marginLeft: 0,
                marginRight: '8px',
              },
            }}
          />
          <FormControlLabel
            control={
              <GCheckbox
                title="Replied"
                value={filterOptions.replied}
                onChange={(_, checked) => {
                  setFilterOptions((options) => ({ ...options, replied: checked }));
                }}
              />
            }
            componentsProps={{ typography: { variant: 'body2', color: 'text.primary' } }}
            label="Replied"
            sx={{
              margin: 0,
              '& .MuiFormControlLabel-label': { marginLeft: '8px' },
              // labelPlacement="start"인 경우 label margin 조정
              '&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label': {
                marginLeft: 0,
                marginRight: '8px',
              },
            }}
          />
        </Box>
      </Box>

      <LoadingWrapper isLoading={isLoading} sx={{ height: 474 }} size={64}>
        {hasReviewList ? (
          <Stack gap="36px" px="60px">
            {reviewList.map((review) => (
              <ReviewContainerPrivateAdmin
                key={review.id}
                reviewData={review}
                mutateReviewList={mutate}
              />
            ))}
          </Stack>
        ) : (
          <Box sx={{ py: '120px' }}>
            <NoData subTitle="No reviews have been submitted yet." />
          </Box>
        )}
      </LoadingWrapper>
      {/* game id, review id가 있을 때 review 팝업 보여주기 */}
      {id && Number(review_id) ? (
        <ReviewDialog gameId={Number(id)} reviewId={Number(review_id)} testType="private" isAdmin />
      ) : null}
    </Box>
  );
}
