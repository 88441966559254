import { Box, Skeleton, SxProps } from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import { Carousel, SubmainBanner } from 'elements';
import type { BannerDataType } from 'types/BannerType';

interface Props {
  /** Carousel을 구분하기 위한 이름(공백 없이 입력) */
  name: string;
  /** Submain Banner data list */
  banners: BannerDataType[];
  /** swipe effect type */
  effect?: 'slide' | 'fade';
  /** 데이터 로딩 중 상태 */
  isLoading?: boolean;
  /** container styles */
  sx?: SxProps;
}

export default function SubmainBannerList({
  name,
  banners,
  effect = 'fade',
  isLoading,
  sx,
}: Props) {
  return (
    <Box
      component="section"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
        mb: '80px',
        ...sx,
      }}
    >
      {isLoading ? (
        <Skeleton
          sx={{ backgroundColor: 'rgba(100, 100, 100, 0.3)' }}
          variant="rectangular"
          width={1920}
          height={300}
        />
      ) : (
        <Box
          sx={{
            minWidth: '1920px',
            width: '100%',
            height: '300px',
            backgroundColor: '#000',
          }}
        >
          <Carousel
            swiperOption={{
              slidesPerView: 1,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false,
              },
              allowTouchMove: false,
              loop: banners && banners.length > 1,
            }}
            name={name}
            width={1920}
            paginationPosition="INSIDE"
            hasPagination
            effect={effect}
          >
            {banners.map?.((banner) => (
              <SwiperSlide key={banner.site_banner_id}>
                <SubmainBanner {...banner} />
              </SwiperSlide>
            ))}
          </Carousel>
        </Box>
      )}
    </Box>
  );
}
