import { GIconButton } from '@ground/ui';
import { Box, Button, Fab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { useLocalStorageFAB } from 'hooks';
import { iRootState } from 'store';
import { groundDiscordChannel } from 'utils/common';
import { useLog } from 'utils/userLog';

import discordLarge from 'images/banner/discord/discord_bottom_bg.png';
import discordLogo from 'images/banner/discord/discord_logo.svg';
import discordSmall from 'images/icons/discord_small.svg';

export default function FloatingDiscordBanner() {
  const { isDesktopTransformed } = useSelector((state: iRootState) => state.mobile);
  const isMobile = isMobileOnly && !isDesktopTransformed;
  const { gxcTag } = useLog();

  const [floatingActionBtn, setFloatingActionBtn] = useLocalStorageFAB();
  const [iconToggle, setIconToggle] = useState(floatingActionBtn.discordBannerStatus);

  const handleClick = () => {
    gxcTag('click', { area: 'discord_floating', ui: 'discord_join' });
    window.open(groundDiscordChannel, '_blank');
  };

  // 최초진입 하고나서 페이지를 완전이탈 이후에 다시 들어왔을때 축소
  // 최초진입 후 (펼쳐진 상태) 에서 닫기 버튼 클릭 이후 부터 축소
  useEffect(() => {
    if (iconToggle === true) {
      setFloatingActionBtn({ discordBannerStatus: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconToggle]);

  return (
    <>
      {iconToggle ? (
        <Box
          position="fixed"
          zIndex={9}
          sx={{
            bottom: isMobile ? 12 : 40,
            right: isMobile ? 12 : 40,
            height: isMobile ? 214 : 210,
            width: '250px',
            boxShadow: isMobile ? '0px 5px 5px rgba(0, 0, 0, 0.3)' : '',
          }}
        >
          <Box
            position="absolute"
            sx={{
              zIndex: 1,
              borderRadius: '5px',
              backgroundColor: 'primary.main',
              height: isMobile ? 214 : 210,
              right: 0,
              bottom: 0,
            }}
          >
            <img height={210} src={discordLarge} alt="discord small icon" />
          </Box>

          <Box
            sx={{
              position: 'relative',
              zIndex: 2,
              padding: '20px',
            }}
          >
            <Box sx={{ display: 'flex', position: 'absolute', top: 4, right: 4 }}>
              <GIconButton
                iconName="close"
                iconSize={16}
                size={24}
                onClick={() => {
                  gxcTag('click', { area: 'discord_floating', ui: 'close_x' });
                  setIconToggle(false);
                }}
              />
            </Box>
            <img
              height="20px"
              src={discordLogo}
              alt="discord logo"
              style={{ display: 'inline-block' }}
            />
            <Typography variant="subtitle1" color="text.primary" mt="8px">
              G.Round Discord Server
            </Typography>
            <Typography variant="small2" color="text.primary" mt="8px" mb="16px">
              Love games? Join the G.Round discord server to interact with developers and other
              players. Find Q&As, get support and find the latest news for giveaways and much more!
            </Typography>

            <Button
              size={isMobile ? 'small' : 'tiny'}
              variant="contained"
              color="dark"
              onClick={handleClick}
            >
              Join
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          position="fixed"
          zIndex={9}
          sx={{
            bottom: isMobile ? 12 : 40,
            right: isMobile ? 12 : 40,
            boxBottomShadow: isMobile ? '  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)' : '',
          }}
          onClick={() => {
            gxcTag('click', { area: 'discord_floating', ui: 'layer_open' });
            setIconToggle(true);
          }}
        >
          <Fab
            aria-label="discord banner floating reduction icon"
            sx={{
              width: 60,
              height: 60,
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            <img height={24} src={discordSmall} alt="discord small icon" />
          </Fab>
        </Box>
      )}
    </>
  );
}
