import { useFetch, useSWRFetch } from 'api';
import { GameDetailProps } from 'types/GameDetailTypes';
import { SurveyDataProps, SurveyAnswerDataProps } from 'types/FormDataType';

export function useGameDetailSurveyAPI(gameId: string, surveyId: number, part: number) {
  return useFetch(`games/${gameId}/surveys/${surveyId}/parts/${part}`, { method: 'PATCH' }, true);
}

export function useGetSurveyDataAPI(
  gameId: string,
  data: GameDetailProps | undefined,
  country_code: string | null
) {
  const url = `games/${gameId}/surveys/${data?.survey_type === 'GLOBAL' ? 'GO' : country_code}`;

  return useSWRFetch<SurveyDataProps[]>({ key: country_code && data ? url : null, useToken: true });
}

export function useGetSurveyAnswerDataAPI(
  gameId: string,
  data: GameDetailProps | undefined,
  country_code: string | null
) {
  const url = `games/${gameId}/surveys/${
    data?.survey_type === 'GLOBAL' ? 'GO' : country_code
  }/answers`;

  return useSWRFetch<SurveyAnswerDataProps[]>({
    key: country_code && data ? url : null,
    useToken: true,
  });
}
