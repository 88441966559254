import { Skeleton, styled } from '@mui/material';

const PREFIX = 'ClipCardLargeSkeleton';

const classes = {
  root: `${PREFIX}-root`,
  textWrap: `${PREFIX}-textWrap`,
  picture: `${PREFIX}-picture`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    width: 590,
    backgroundColor: 'transparent',
  },
  [`& .${classes.picture}`]: {
    borderRadius: 5,
  },
  [`& .${classes.textWrap}`]: {
    display: 'flex',
    marginTop: 20,
  },
  [`& .${classes.avatar}`]: {
    marginRight: 12,
  },
});

export default function ClipCardLargeSkeleton() {
  return (
    <Root className={classes.root}>
      <Skeleton className={classes.picture} width={590} height={332} variant="rectangular" />

      <div className={classes.textWrap}>
        <Skeleton className={classes.avatar} width={40} height={40} variant="circular" />
        <div>
          <Skeleton width={100} height={20} />
          <Skeleton width={200} height={20} />
        </div>
      </div>
    </Root>
  );
}
