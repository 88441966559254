import { Box } from '@mui/material';
import { ComponentProps } from 'react';

import { SmallChip } from '../../atoms';
import ReviewPopupMenu, { ReviewPopupMenuItem } from './ReviewPopupMenu';
import ReviewStatus from './ReviewStatus';

interface ReviewStatusAreaProps {
  distributed?: boolean;
  status: ComponentProps<typeof ReviewStatus>;
  menuList?: ReviewPopupMenuItem[];
}

export default function ReviewStatusArea({ distributed, status, menuList }: ReviewStatusAreaProps) {
  return (
    <Box display="flex" gap="20px" alignItems="center" height="24px" flexShrink={0}>
      {distributed ? <SmallChip color="text.secondary" text="G.P Distributed" /> : null}
      <ReviewStatus {...status} />
      {menuList && menuList.length > 0 ? <ReviewPopupMenu menuList={menuList} /> : null}
    </Box>
  );
}
