import { Box, Typography } from '@mui/material';

import { useGetDetailData } from 'views/detail/DetailProvider';
import DiscordBanner from 'views/detail/banner/discord/DiscordBanner';

export default function SurveyDiscordBanner() {
  const { data } = useGetDetailData();

  return (
    <>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mt="60px" mb="20px">
        <Typography color="text.primary" variant="subtitle1">
          Join the Discord community
        </Typography>
        <Typography color="text.secondary" variant="body3">
          Click the banner to join the conversation on Discord.
        </Typography>
      </Box>
      <DiscordBanner discord_channel={data?.discord_channel} type="survey" />
    </>
  );
}
