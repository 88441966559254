import { useDialog } from 'hooks';
import { Box, Typography } from '@mui/material';
import DiscordBanner from 'views/detail/banner/discord/DiscordBanner';
import { GameGuide } from 'components';
import { groundDiscordChannel } from 'utils/common';
import { useLog } from 'utils/userLog';

export default function GnbGameGuideButton() {
  const { showDialog } = useDialog();
  const { gxcTag } = useLog();

  const handleClickShowDialog = () => {
    gxcTag('click', { area: 'gnb', ui: 'how_it_works' });
    gxcTag('view', { area: 'how_it_works_popup' });
    showDialog({
      width: 1200,
      title: 'How it works?',
      body: (
        <>
          <Typography color="text.secondary" variant="body2">
            Playtest games and help developers make their game better.
            <br /> Win yourself amazing rewards including our exclusive merchandise and share your
            experiences with our community in Discord
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '80px 0',
            }}
          >
            <GameGuide spacing="20px" />
          </Box>

          <DiscordBanner
            discord_channel={groundDiscordChannel}
            type="comment"
            onClick={() => {
              gxcTag('click', { area: 'how_it_works_popup', ui: 'discord' });
            }}
          />
        </>
      ),
      onClickClose: () => {
        gxcTag('click', { area: 'how_it_works_popup', ui: 'close_x' });
      },
    });
  };

  return (
    <Typography
      variant="small1"
      color="text.secondary2"
      onClick={handleClickShowDialog}
      sx={{ cursor: 'pointer', '&:hover': { color: 'text.primary' }, padding: '8px 10px' }}
    >
      How it works?
    </Typography>
  );
}
