import { GCheckbox } from '@ground/ui';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  SxProps,
} from '@mui/material';
import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { SelectionProps } from './FormType';
import { defaultOptions } from './RadioGroupDefaultOptions';

interface Props {
  defaultChecked?: false;
  disabled?: boolean;
  name: string;
  options?: Array<SelectionProps>;
  rules?: RegisterOptions;
  minSelect?: number;
  row?: boolean;
  required: boolean;
  sx?: SxProps;
  index: number;
}

export default function FormCheckboxGroup({
  defaultChecked = false,
  disabled = false,
  name,
  options = defaultOptions,
  minSelect = 0,
  row,
  required,
  sx = {
    mr: '40px',
    mb: '10px',
  },
  index,
}: Props) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  const handleValidate = () => {
    const check = getValues(name).filter((item: boolean) => {
      return item === true;
    });

    if (required && check?.length < minSelect) {
      return `Please select at least 1 answer`;
    }
  };

  return (
    <FormControl error={!!error} component="fieldset" disabled={disabled}>
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {options.map((option, index) => (
          <Box key={option.id} textAlign={row ? 'center' : 'left'} sx={sx}>
            <Controller
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormControlLabel
                  sx={{ marginLeft: 0, marginRight: 0 }}
                  control={
                    <GCheckbox
                      {...field}
                      style={{ color: option?.color, marginRight: '8px' }}
                      inputRef={ref}
                      color="primary"
                      size="small"
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  }
                  label={option.label ?? ''}
                  labelPlacement={row ? 'top' : 'end'}
                />
              )}
              control={control}
              defaultValue={defaultChecked}
              name={`${name}.${option.order}`}
              rules={{ validate: handleValidate }}
            />
          </Box>
        ))}
      </FormGroup>
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
