import { forwardRef, Ref } from 'react';
import { FormControl, Typography, TypographyProps } from '@mui/material';

import InputFieldBase, { InputFieldBaseProps } from '../InputFieldBase';

export interface InputFieldProps extends Omit<InputFieldBaseProps, 'ref'> {
  /** input 상단의 label */
  label?: string;
  /** input 하단의 도움말 */
  helperText?: string;

  /** Label typography property */
  InputLabelProps?: TypographyProps<'label'>;
  /** HelperText typography property */
  HelperTextProps?: TypographyProps;
}

const InputField = forwardRef((inProps: InputFieldProps, ref: Ref<HTMLDivElement>) => {
  const {
    className,
    disabled,
    error,
    fullWidth,
    size,

    label,
    helperText,

    success = false,
    withBackground,

    InputLabelProps,
    HelperTextProps,
    sx,
    ...other
  } = inProps;

  return (
    <FormControl
      className={className}
      ref={ref}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      sx={sx}
    >
      {label && (
        <Typography
          component="label"
          color="text.secondary"
          variant={size === 'large' ? 'subtitle2' : 'body1'}
          marginBottom="8px"
          {...InputLabelProps}
        >
          {label}
        </Typography>
      )}
      <InputFieldBase
        disabled={disabled}
        success={success}
        error={error}
        fullWidth={fullWidth}
        size={size}
        withBackground={withBackground}
        {...other}
      />
      {helperText && (
        <Typography
          color={
            disabled
              ? 'text.disabled'
              : success
              ? 'success.main'
              : error
              ? 'error.main'
              : 'text.primary'
          }
          mt="8px"
          variant="body3"
          {...HelperTextProps}
        >
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
});

export default InputField;
