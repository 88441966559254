import { Stack, Typography, Skeleton, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import { DetailContent } from 'types/DigitalGoodsType';

interface Props {
  description?: string;
  isLoading?: boolean;
  contents: Omit<DetailContent, 'type'>[];
}

export const ProductImages = ({ isLoading, contents }: Props) => {
  return (
    <Stack mt="120px">
      <Typography variant="h3">Product Images</Typography>

      <Grid container spacing="40px" mt="8px">
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton width="100%" height="100%" variant="rectangular" sx={{ height: 100 }} />
          </Grid>
        ) : (
          contents &&
          [...contents]
            .sort((a, b) => a.seq - b.seq)
            .map((media, index) =>
              media?.media_type === 'VIDEO' ? (
                <Grid item xs={6} key={index}>
                  <ReactPlayer
                    url={media.url}
                    controls
                    width={580}
                    height={400}
                    style={{ borderRadius: 5 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={6} style={{ boxSizing: 'border-box' }} key={index}>
                  <img
                    key={index}
                    src={media.url}
                    alt="product details"
                    style={{ borderRadius: 5, width: 580, height: 400 }}
                  />
                </Grid>
              )
            )
        )}
      </Grid>
    </Stack>
  );
};
