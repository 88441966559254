import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';
import { ReviewGauge } from '../../../components/ReviewGauge';

interface Props extends GameCardProps {
  isMobile?: boolean;
}

export default function GameCardMobileLowerArea({
  state,
  studio_name,
  title,
  started_at,
  ended_at,
  isHover = false,
  isPrivateShow,
  children,
  required_review,
  progressed_review,
  progress_rate,
  isMobile,
}: PropsWithChildren<Props>) {
  const hasGauge = typeof required_review === 'number' && typeof progressed_review === 'number';

  return (
    <Box
      p="20px"
      sx={{
        backgroundColor: isHover && !isPrivateShow ? 'gray.70' : 'gray.80',
        borderRadius: '0px 0px 5px 5px',
        position: 'relative',
        minHeight: '159px',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        color="text.primary"
        variant="subtitle1"
        noWrap
        sx={{ width: '220px', opacity: isPrivateShow ? 0.3 : '' }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle3"
        color={isHover && !isPrivateShow ? 'text.primary' : 'text.secondary2'}
        noWrap
        sx={{ marginTop: '6px', width: '220px', opacity: isPrivateShow ? 0.3 : '' }}
      >
        {studio_name}
      </Typography>
      {(state === GameStatusType.TESTING ||
        state === GameStatusType.SURVEY ||
        state === GameStatusType.CLOSED) && (
        <Typography
          variant="subtitle3"
          color={isHover && !isPrivateShow ? 'text.primary' : 'text.secondary2'}
          sx={{ marginTop: '6px', opacity: isPrivateShow ? 0.3 : '' }}
        >
          Period: {format(new Date(started_at), 'MMM dd, yyyy')} -{' '}
          {format(new Date(ended_at), 'MMM dd, yyyy')}
        </Typography>
      )}
      {hasGauge && (
        <Box sx={{ marginTop: '28px' }}>
          <ReviewGauge
            goal={required_review}
            count={progressed_review}
            rate={progress_rate}
            isMobile={isMobile}
          />
        </Box>
      )}
      {children}
    </Box>
  );
}
