import { Skeleton, styled } from '@mui/material';

const PREFIX = 'ClipCardSmallSkeleton';

const classes = {
  root: `${PREFIX}-root`,
  textWrap: `${PREFIX}-textWrap`,
  picture: `${PREFIX}-picture`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    width: 384,
  },
  [`& .${classes.picture}`]: {
    borderRadius: 5,
  },
  [`& .${classes.textWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  [`& .${classes.avatar}`]: {
    marginRight: 8,
  },
});

export default function ClipCardSmallSkeleton() {
  return (
    <Root className={classes.root}>
      <Skeleton className={classes.picture} width={384} height={216} variant="rectangular" />

      <div className={classes.textWrap}>
        <Skeleton className={classes.avatar} width={28} height={28} variant="circular" />
        <Skeleton width="50%" height={16} />
      </div>
      <Skeleton width="50%" height={16} sx={{ marginTop: 1 }} />
    </Root>
  );
}
