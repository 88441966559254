import { styled, Typography, Button, Box, Divider } from '@mui/material';

import imgGtr from 'images/about/aboutDev/about_gtr_logo.svg';
import imgGis from 'images/about/aboutDev/about_gis_logo.svg';

const PREFIX = 'ArtDevServiceValues';

const classes = {
  valuesTextWrap: `${PREFIX}-valuesTextWrap`,
  imgWrap: `${PREFIX}-imgWrap`,
  imgWrapGI: `${PREFIX}-imgWrapGI`,
};

const Root = styled('div')(({ theme }) => ({
  width: 1080,
  padding: '100px 0 100px',

  [`& .${classes.valuesTextWrap}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 60,
    gap: 60,
  },

  [`& .${classes.imgWrap}`]: {
    margin: '108px 0 0 103px',
  },

  [`& .${classes.imgWrapGI}`]: {
    margin: '108px 0 0 62px',
  },
}));

const serviceValueList = [
  {
    id: 1,
    title: 'Constructive User Feedback and Market Insight',
    desc:
      'Developers will get valuable insight into their game development process through 100s and 1000s of real user feedback via G.Round testing. Based on these test results, game studios can not only fine-tune their game content and business model but also get to know who their target users are and which countries they’re from to optimize their game launch. G.Round’s community of gamers also enables developers to localize their games in their top regions with ease. Game studios can then make data-driven strategies and even present their test results to potential publishing and investment partners to use as leverage.',
  },
  {
    id: 2,
    title: 'Business Opportunities',
    desc: (
      <>
        G.Round is an innovative platform for publishers and investors to find actual user vetted
        games compared to the conventional way of attending offline events. Finding prominent games
        and game studios from around the world is definitely a tough task. Evaluating and quickly
        making the right decisions is even more difficult. G.Round shall be a MUST HAVE evaluation
        tool for publishers and investors in their search for attractive game titles in the global
        market.
        <br /> The test results of each game by our global community and the user preference data
        will give confidence to the publishers and investors in choosing their optimal partnerships.
      </>
    ),
  },
  {
    id: 3,
    title: 'Publishing Opportunities',
    desc: (
      <>
        We believe that game studios should focus on developing their games with the support of the
        gamer community. <br />
        Based on the positive G.Round test results and feedback, we can leverage our own global
        network of publishers to help you as a studio identify suitable partners.
      </>
    ),
  },
  {
    id: 4,
    title: 'Investment Opportunities',
    desc:
      'We know the importance of funding as it fuels continuous game development and company operations. Based on your company’s growth stage and required capital, we will provide different types of investment opportunities via our own funds and our partner networks.',
  },
];

export default function ArtDevServiceValues() {
  return (
    <Root>
      <Typography variant="h4">G.Round Service Values</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ marginTop: '12px' }}>
        Provides Global Market Insight & Business Parterships
      </Typography>
      <div className={classes.valuesTextWrap}>
        {serviceValueList.map((item) => (
          <Box key={item.id} sx={{ width: '510px' }}>
            <Typography variant="h6">{item.title}</Typography>
            <Typography variant="body1" color="text.secondary2" sx={{ marginTop: '12px' }}>
              {item.desc}
            </Typography>
          </Box>
        ))}
      </div>
      <Divider
        sx={{
          marginTop: '60px',
          marginBottom: '32px',
        }}
      />

      <Box display="flex">
        <Box sx={{ width: '881px' }}>
          <Typography variant="h6">Early Stage</Typography>
          <Typography
            variant="body1"
            color="text.secondary2"
            sx={{ marginTop: '12px', marginBottom: '20px' }}
          >
            Indie Game Studios in early stage will be reviewed by GTR, the global game accelerator.
            once their games were tested and proven by G.Round gamers. <br />
            GTR provides Pre-Seed program and Seed program including equity investment and game
            acceleration. will be offered by GTR when game studios want and test results are
            positive. <br />* Pre-Seed : 40~100K USD funding at 800K~1M USD valuation
            <br />* Seed : 150~300K USD funding at 3~5M USD valuation
          </Typography>
          <Button
            variant="outlined"
            color="light"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.globaltopround.com"
          >
            Visit Us
          </Button>
        </Box>
        <div className={classes.imgWrap}>
          <img src={imgGtr} alt="gtr" />
        </div>
      </Box>
      <Divider
        sx={{
          marginTop: '32px',
          marginBottom: '32px',
        }}
      />
      <Box display="flex">
        <Box sx={{ width: '880px' }}>
          <Typography variant="h6">Growth Stage</Typography>
          <Typography
            variant="body1"
            color="text.secondary2"
            sx={{ marginTop: '12px', marginBottom: '20px' }}
          >
            Only selected game startups in growth stage will be invited to the Game Invest events,
            once their games were tested and proven by G.Round gamers.
            <br />
            The Game Invest IR event (twice a year, Asia and Europe) will be held as a private
            meetup with 10+ active game investors to initiate official investment reviews.
            <br /> * Pre-Series A : 1~2M USD funding at 10~15M valuation
            <br />* Series A : 4~5M USD funding at 20~30M valuation
          </Typography>
          <Button variant="outlined" color="primary" component="a" href="" disabled>
            Coming Soon
          </Button>
        </Box>
        <div className={classes.imgWrapGI}>
          <img src={imgGis} alt="gtr" />
        </div>
      </Box>
    </Root>
  );
}
