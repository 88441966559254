const domain = 'https://image.gameround.co';

type ImageFormat = 'jpg' | 'jpeg' | 'png' | 'gif' | 'webp' | 'svg' | 'tiff';

interface Options {
  resizeWidth?: number;
  resizeHeight?: number;
  format?: ImageFormat;
  quality?: number;
}

const createQuery = (size: number, options: Options) => {
  const width = options.resizeWidth ? `&w=${options.resizeWidth * size}` : '';
  const height = options.resizeHeight ? `&h=${options.resizeHeight * size}` : '';
  const format = options.format ? `&f=${options.format}` : '';
  const quality = options.quality ? `&q=${options.quality}` : '';

  return `?${width}${height}${format}${quality}`;
};

export interface AutoResizeImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  /** 변경할 가로 사이즈 (가로 값과 세로 값 중 하나는 존재해야 함) */
  resizeWidth?: number;
  /** 변경할 세로 사이즈 (가로 값과 세로 값 중 하나는 존재해야 함) */
  resizeHeight?: number;
  /** 변경하려는 이미지 포맷 */
  format?: ImageFormat;
  /** 변경할 이미지 품질 (1-100) */
  quality?: number;
}

/**
 * @name AutoResizeImage
 * @description
 * 이미지 서버를 활용하여 이미지를 최적화된 사이즈로 변경하여 img 컴포넌트를 반환해주는 함수.
 *
 * resizeWidth와 resizeHeight 중 하나는 필수 값이다.
 *
 * 원본 이미지를 바탕으로 1x, 2x, 3x까지 고해상도 이미지 대응.
 *
 * gif 이미지는 지원되지 않음.
 *
 * 변환한 이미지 용량이 1MB 보다 크면 원본 이미지를 반환한다. 람다 함수 response는 1MB 이상 반환할 수 없기 때문
 *
 * @see https://www.notion.so/gameround/71a7846aafb84e55a6807f6c74dc2d5b
 */
export default function AutoResizeImage({
  src = '',
  alt,
  resizeWidth,
  resizeHeight,
  format,
  quality,
  width,
  height,
  ...props
}: AutoResizeImageProps) {
  // 변경할 가로나 세로 사이즈가 없으면 에러 표출하고 원본 이미지를 반환
  if (!(resizeWidth || resizeHeight)) {
    console.error('AutoResizeImage Error : Either resizeWidth or resizeHeight must be set.');
  }

  // resize를 요청할 image url
  let newSrc = '';

  try {
    newSrc = domain + new URL(src).pathname;
  } catch (error) {
    // src 규칙을 위반한 경우 error 발생
    console.log('Error : ', (error as Error).message);
    // resize 실패로 처리하여 원본 이미지 반환
    newSrc = src;
  }

  // query string 생성을 위한 옵션들
  const options = { resizeWidth, resizeHeight, format, quality };

  return (
    <img
      src={src}
      srcSet={`${newSrc}${createQuery(1, options)}, ${newSrc}${createQuery(
        2,
        options
      )} 2x, ${newSrc}${createQuery(3, options)} 3x`}
      alt={alt}
      width={width ?? resizeWidth}
      height={height ?? resizeHeight}
      {...props}
    />
  );
}
