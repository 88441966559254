import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { LoginPayload } from 'models/login';
import { Dispatch } from 'store';

export default function useSign() {
  const { login } = useDispatch<Dispatch>();

  // sign up, in 완료 후 프로세스 함수
  const signComplete = useCallback(
    (loginData: LoginPayload, forceReload: boolean = false) => {
      // 로그인 정보를 local storage에 저장
      login.login(loginData);
      // 페이지 강제 리로드가 필요한 경우
      if (forceReload) window.location.reload();
    },
    [login]
  );

  return { signComplete };
}
