import { Box } from '@mui/material';

import NoData from 'elements/NoData/NoData';

export default function NoVideoClip() {
  return (
    <Box width="100%" height="100%">
      <NoData title="Clips will be uploaded soon. Please stay tuned!" />
    </Box>
  );
}
