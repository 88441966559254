import { numberWithCommas } from '@ground/tools';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useGameLikeToggleAPI } from 'api/like/likeAPI';
import { IconButtonForm } from 'elements';
import { useSignupDialog, useThrottle } from 'hooks';
import { iRootState } from 'store';
import eventTracker from 'utils/eventTracker';
import { useGetDetailData } from 'views/detail/DetailProvider';

export default function DetailIConArea() {
  const { isLogin } = useSelector((state: iRootState) => state.login);
  const { showSignupDialog } = useSignupDialog();
  const { data } = useGetDetailData();
  const { fetchLike } = useGameLikeToggleAPI(data?.id ?? '');
  const [liked, setLiked] = useState(!!data?.like_check);
  const [numLike, setUserLike] = useState(data?.num_like ?? 0);

  const handleClickFavorite = (liked: boolean, numLike: number) => {
    if (isLogin) {
      fetchLike();
      // 빠른 처리를 위해 결과와 상관없이 미리 좋아요 처리
      setLiked(!liked);
      setUserLike(numLike + (liked ? -1 : 1));
      data && eventTracker('gamedetail_upper_like', { event_label: data.id });
    } else {
      showSignupDialog();
    }
  };

  const throttleHandleClickFavorite = useThrottle(handleClickFavorite, 500);

  return (
    <Stack direction="row" columnGap="20px">
      <IconButtonForm
        icon="favorite"
        isActivated={liked}
        text={numberWithCommas(numLike)}
        onClick={() => throttleHandleClickFavorite(liked, numLike)}
        size="small"
      />
      {/* TODO: Share button */}
      {/* <IconButtonForm icon="share" text="Share" onClick={handleClickFavorite} size="small" /> */}
    </Stack>
  );
}
