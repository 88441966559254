import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack } from '@mui/material';

import { ReviewItemDataV1 } from 'types/CommentType';
import ReplyContainer from './ReplyContainer';
import ReplyPostContainer from './ReplyPostContainer';

interface Props {
  gameId: number;
  reviewId: number;
  reloadReplyHandler: () => void;
  postReplyHandler: () => void;
  onViewMore: () => void;
  replyListData: ReviewItemDataV1[];
  showViewMore: boolean;
  loadingViewMore: boolean;
}

export default function ReplyList({
  gameId,
  reviewId,
  reloadReplyHandler,
  postReplyHandler,
  onViewMore,
  replyListData,
  showViewMore,
  loadingViewMore,
}: Props) {
  return (
    <Stack gap="32px" sx={{ marginLeft: '40px' }}>
      {/* REPLY POST */}
      <ReplyPostContainer
        gameId={gameId}
        reviewId={reviewId}
        reload={() => {
          reloadReplyHandler();
          postReplyHandler();
        }}
      />

      <Stack gap="20px">
        {replyListData.map((replyData) => (
          <ReplyContainer
            key={replyData.id}
            reply={replyData}
            mutateReplyList={reloadReplyHandler}
          />
        ))}

        {!showViewMore && <Divider />}
      </Stack>

      {showViewMore && (
        <Box textAlign="center">
          <LoadingButton
            color="primary"
            endIcon={<ExpandMoreIcon />}
            loading={loadingViewMore}
            onClick={onViewMore}
            size="small"
          >
            View More
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
}
