import { GIcon, GIconProps } from '@ground/ui';
import { Box, ButtonBase, Stack, styled, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WeiboShareButton,
  WhatsappShareButton,
} from 'react-share';
import { useLog } from 'utils/userLog';

const ShareButton = styled(Stack)(({ theme }) => ({
  width: 74,
  height: 72,
  borderRadius: '3px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',

  '&:hover': {
    backgroundColor: theme.palette.gray[90],

    '& > div': {
      opacity: 1,
    },

    '& p': {
      color: theme.palette.text.primary,
    },
  },

  '& > div': {
    opacity: 0.6,
  },
}));

const ButtonContainer = styled('div')({
  marginTop: '20px',
});

const SnsIcon = ({ iconName }: Pick<GIconProps, 'iconName'>) => (
  <GIcon iconName={iconName} iconSize={24} size={36} variant="circle" backgroundColor="gray.60" />
);

interface Props {
  isCopied?: boolean;
  onClickCopyHandler?: () => void;
  title: string;
}

export function ShareModal({ isCopied, onClickCopyHandler, title }: Props) {
  const { gxcTag } = useLog();
  const shareUrl = window.location.href;

  const handleClickShareButton = (title: string) => {
    gxcTag('click', { area: 'share_popup', ui: title });
  };

  return (
    <ButtonContainer>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          onClick={() => handleClickShareButton('facebook')}
        >
          <ShareButton>
            <SnsIcon iconName="facebook" />
            <Typography variant="button3" color="text.secondary">
              Facebook
            </Typography>
          </ShareButton>
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          onClick={() => handleClickShareButton('twitter')}
        >
          <ShareButton>
            <SnsIcon iconName="x" />
            <Typography variant="button3" color="text.secondary">
              X
            </Typography>
          </ShareButton>
        </TwitterShareButton>
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          onClick={() => handleClickShareButton('reddit')}
        >
          <ShareButton>
            <SnsIcon iconName="reddit" />
            <Typography variant="button3" color="text.secondary">
              Reddit
            </Typography>
          </ShareButton>
        </RedditShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          onClick={() => handleClickShareButton('whatsapp')}
        >
          <ShareButton>
            <SnsIcon iconName="whatsupApp" />
            <Typography variant="button3" color="text.secondary">
              WhatsApp
            </Typography>
          </ShareButton>
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl} onClick={() => handleClickShareButton('linkedin')}>
          <ShareButton>
            <SnsIcon iconName="linkedin" />
            <Typography variant="button3" color="text.secondary">
              LinkedIn
            </Typography>
          </ShareButton>
        </LinkedinShareButton>
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="2230134670627492"
          onClick={() => handleClickShareButton('fb_messenger')}
        >
          <ShareButton>
            <SnsIcon iconName="fbMessenger" />
            <Typography variant="button3" color="text.secondary">
              FB messenger
            </Typography>
          </ShareButton>
        </FacebookMessengerShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          onClick={() => handleClickShareButton('telegram')}
        >
          <ShareButton>
            <SnsIcon iconName="telegram" />
            <Typography variant="button3" color="text.secondary">
              Telegram
            </Typography>
          </ShareButton>
        </TelegramShareButton>
        <LineShareButton
          url={shareUrl}
          title={title}
          onClick={() => handleClickShareButton('line')}
        >
          <ShareButton>
            <SnsIcon iconName="line" />
            <Typography variant="button3" color="text.secondary">
              Line
            </Typography>
          </ShareButton>
        </LineShareButton>
        <WeiboShareButton
          url={shareUrl}
          title={title}
          onClick={() => handleClickShareButton('weibo')}
        >
          <ShareButton>
            <SnsIcon iconName="weibo" />
            <Typography variant="button3" color="text.secondary">
              Weibo
            </Typography>
          </ShareButton>
        </WeiboShareButton>
        <CopyToClipboard
          text={shareUrl}
          onCopy={() => {
            handleClickShareButton('copylink');
            onClickCopyHandler && onClickCopyHandler();
          }}
        >
          <ButtonBase>
            <ShareButton>
              <SnsIcon iconName="copy" />
              <Typography variant="button3" color="text.secondary">
                Copy Link
              </Typography>
            </ShareButton>
          </ButtonBase>
        </CopyToClipboard>
      </Box>
      <Box mt="6px" sx={{ display: 'flex', justifyContent: 'center' }}>
        {isCopied ? (
          <Stack direction="row" gap="8px">
            <GIcon iconColor="success.main" iconName="check" iconSize={16} />
            <Typography color="success.main" variant="body3">
              Link copied
            </Typography>
          </Stack>
        ) : (
          <Box height="17px" />
        )}
      </Box>
    </ButtonContainer>
  );
}
