import { ReactNode } from 'react';

import icon1 from './guide_icon1.png';
import icon2 from './guide_icon2.png';
import icon3 from './guide_icon3.png';
import icon4 from './guide_icon4.png';

export interface GuideCard {
  srcInfo: string;
  title: string;
  description: ReactNode;
}

export const gameGuideList: GuideCard[] = [
  {
    srcInfo: icon1,
    title: 'Play Games',
    description: 'Discover and get access to upcoming and unreleased video games.',
  },
  {
    srcInfo: icon2,
    title: 'Leave Feedback',
    description: 'Provide feedback through reviews and survey to help developers',
  },
  {
    srcInfo: icon3,
    title: 'Enjoy Rewards',
    description: (
      <>
        Play and participate to earn G.Points.
        <br />
        Use G.Points for exclusive rewards.
      </>
    ),
  },
  {
    srcInfo: icon4,
    title: 'Share with Community',
    description: 'Share your ideas with others and speak directly with developers on Discord.',
  },
];
