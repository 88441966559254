import { FormInput } from '@ground/ui';
import { Typography } from '@mui/material';
import { useFormState } from 'react-hook-form';

import { passwordPattern } from 'utils/common';
import { useSignupData } from './SignupProvider';

export default function SignupPassword() {
  const { dirtyFields, errors } = useFormState();
  const { isMobile } = useSignupData();

  return (
    <div>
      <FormInput
        label="Password"
        name="password"
        type="password"
        autoComplete="new-password"
        password
        placeholder="Enter your password"
        success={dirtyFields['password'] && !errors['password']}
        rules={{
          required: 'Please enter a password.',
          minLength: { value: 8, message: 'Invalid format' },
          pattern: { value: passwordPattern, message: 'Invalid format' },
        }}
        fullWidth
        size={isMobile ? 'large' : 'medium'}
        withBackground={!isMobile}
      />
      <Typography variant="body3" mt="8px" color="text.secondary2">
        Password must be 8-16 characters and include at least one lowercase letter, one uppercase
        letter, and a number.
      </Typography>
    </div>
  );
}
