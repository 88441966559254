import { useMemo } from 'react';

import { useGameEventBadgeListAPI, useGamelistAPI } from 'api/gamelist/gamelistAPI';
import GameListMediumCard from 'components/GameList/GameListMediumCard';
import { GameCardProps } from 'types/GameCardTypes';
import { GameStatusType } from 'types/GameCommonTypes';
import { HaveMoreContainer } from 'components/HaveMoreContainer';
interface GameCardListMediumContainerProps {
  cardtype: GameStatusType;
  count?: number;
  name: string;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  useSlider: boolean;
  includeSurveyGame?: boolean;
  likeButton?: boolean;
  navigationPositionTop?: number;
  isLauncherImage?: boolean;
  onClickCard?: (gameId?: number) => void;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
  onLikeClicked?: ({ liked, id }: { liked: boolean; id: string | number }) => void;
  hasMoreBtn?: boolean;
  fillEmptyCard?: boolean;
  fillHeight?: boolean;
}

export default function GameListMediumContainer({
  cardtype,
  count = 4,
  name,
  hoverVideoPlay,
  showViewMoreCard,
  useSlider,
  includeSurveyGame,
  likeButton = true,
  navigationPositionTop,
  isLauncherImage,
  onClickCard,
  onClickLeftArrow,
  onClickRightArrow,
  onLikeClicked,
  hasMoreBtn,
  fillEmptyCard,
  fillHeight,
}: GameCardListMediumContainerProps) {
  const { isLoading, isValidating, data, error } = useGamelistAPI(cardtype, includeSurveyGame);
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const gameList: GameCardProps[] = useMemo(
    () =>
      Array.isArray(data?.data) && data?.data && data?.data.length > 0
        ? data.data
            .map?.((data) => ({
              ...data,
              state: cardtype,
              likeButton,
              hoverVideoPlay,
            }))
            .slice(0, useSlider || hasMoreBtn ? data.data.length : count)
        : [],
    [data?.data, useSlider, hasMoreBtn, count, cardtype, likeButton, hoverVideoPlay]
  );

  return isDataNotExist ? null : hasMoreBtn && gameList.length > 4 ? (
    <HaveMoreContainer height="718px">
      <GameListMediumCard
        data={gameList}
        loading={isLoading || isValidating}
        count={count}
        cardtype={cardtype}
        name={name}
        hoverVideoPlay={hoverVideoPlay}
        likeButton={likeButton}
        showViewMoreCard={showViewMoreCard}
        eventBadgeList={eventBadgeListData?.data}
        useSlider={useSlider}
        navigationPositionTop={navigationPositionTop}
        isLauncherImage={isLauncherImage}
        onClickCard={(gameId) => {
          onClickCard?.(gameId);
        }}
        onClickLeftArrow={onClickLeftArrow}
        onClickRightArrow={onClickRightArrow}
        onLikeClicked={onLikeClicked}
        fillEmptyCard={fillEmptyCard}
        fillHeight={fillHeight}
      />
    </HaveMoreContainer>
  ) : (
    <GameListMediumCard
      data={gameList}
      loading={isLoading || isValidating}
      count={count}
      cardtype={cardtype}
      name={name}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={likeButton}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      useSlider={useSlider}
      navigationPositionTop={navigationPositionTop}
      isLauncherImage={isLauncherImage}
      onClickCard={(gameId) => {
        onClickCard?.(gameId);
      }}
      onClickLeftArrow={onClickLeftArrow}
      onClickRightArrow={onClickRightArrow}
      onLikeClicked={onLikeClicked}
      fillEmptyCard={fillEmptyCard}
      fillHeight={fillHeight}
    />
  );
}
