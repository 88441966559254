import { Box, Typography, Button, Link } from '@mui/material';

/** 유저레벨 정보
 * 1 일반, 30 인플루언서, 50 스튜디오, 100 관리자
 */
interface DeleteNotPosibleProps {
  closeDialog: () => void;
}

// 일반유저가 아닐때 회원탈퇴 불가 다이어로그
export default function DeleteUserAuthDialog({ closeDialog }: DeleteNotPosibleProps) {
  return (
    <>
      <Typography variant="button1" color="text.primary">
        Account deletion requests are not allowed.
      </Typography>
      <br />

      <Typography variant="body2" color="text.secondary">
        G.ROUND Administrators or Influencers cannot <br />
        delete their accounts in this manner.
        <br />
        Please contact the support team.
      </Typography>

      <Link
        color="primary"
        href="mailto:support@gameround.co"
        rel="noopener noreferrer"
        target="_blank"
        variant="body2"
        sx={{ textDecoration: 'none' }}
      >
        support@gameround.co
      </Link>

      <Box mt="40px" mb="16px">
        <Box mt="12px"></Box>
        <Button color="primary" variant="ghost" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
      </Box>
    </>
  );
}
