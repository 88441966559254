import { useLocalStorage } from 'usehooks-ts'; // 배너공지할떄 사용
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSendEmailVerificationCodeAPI } from 'api/email/emailAPI';
import { useLoading, useProfileDialog, useVerificationDialog } from 'hooks';
import { iRootState } from 'store';
import NotificationBanner from '../NotificationBanner';
import { useLog } from 'utils/userLog';

interface TopLineBannerProps {
  type?: 'desktop' | 'mobile';
}

// TODO: componenets 로 옮기거나 view 단으로 이동
export default function TopLineBanner({ type = 'desktop' }: TopLineBannerProps) {
  // 배너공지할떄 사용
  const [showNotiBanner, setShowNotiBanner] = useLocalStorage<string | null>(
    'bannerStatus',
    'openBanner'
  );

  const { isLogin, filled, verified } = useSelector((state: iRootState) => state.login);
  // 이메일 미인증, 프로필 미완성, 런처 신규버전 아닌 경우 배너 출력
  const [showBanner, setShowBanner] = useState(isLogin && (!verified || !filled));

  const { showVerificationDialog } = useVerificationDialog(type === 'mobile');
  const { showProfileDialog } = useProfileDialog();
  const { fetch: sendVerificationCode } = useSendEmailVerificationCodeAPI();
  const { setLoading } = useLoading();
  const { gxcTag } = useLog();

  const unverifiedEmail = isLogin && !verified;
  const incompleteProfile = isLogin && !filled;

  const handleClickVerify = async () => {
    gxcTag('click', { area: 'topbar_email_verification', ui: 'verify_now' });
    setLoading(true);
    await sendVerificationCode();
    setLoading(false);

    showVerificationDialog();
  };

  const handleClickProfile = () => {
    gxcTag('click', { area: 'topbar_complete_profile', ui: 'complete_profile' });
    showProfileDialog();
  };

  const handleClickClose = () => {
    setShowNotiBanner('closeBanner'); // 배너공지할떄 사용
    setShowBanner(false);
    setSessionTime('close10minutes', 10);
  };

  function setSessionTime(key: string, expireMinutes: number) {
    const now = new Date();
    const item = {
      expiry: now.setMinutes(now.getMinutes() + expireMinutes),
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  function getSessionTime(key: string) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      setShowBanner(true);
    }
  }

  useEffect(() => {
    getSessionTime('close10minutes');
  }, []);

  useEffect(() => {
    if (unverifiedEmail) {
      setShowBanner(true);
    } else if (incompleteProfile) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [incompleteProfile, unverifiedEmail]);

  // 배너공지할때 사용
  useEffect(() => {
    if (showNotiBanner !== 'closeBanner') {
      setShowNotiBanner('openBanner');
    }
  }, [setShowNotiBanner, showNotiBanner]);

  // 배너공지할때 사용
  // if (showNotiBanner === 'openBanner') {
  //   return (
  //     <NotificationBanner
  //       type={type}
  //       name="server_down"
  //       text="The G.Round site will be unavailable for server maintenance on 6th Nov, 10PM (PT) for 1 hour."
  //       onClose={handleClickClose}
  //       showCloseButton
  //     />
  //   );
  // }

  return (
    <>
      {showBanner ? (
        <NotificationBanner
          type={type}
          name={unverifiedEmail ? 'verification' : incompleteProfile ? 'profile' : ''}
          text={
            unverifiedEmail
              ? 'Your email address has not been verified. Verify your email and start playing games!'
              : incompleteProfile
              ? 'You have not completed your profile yet, please provide the missing details to start playing games!'
              : ''
          }
          linkText={
            unverifiedEmail ? 'Verify now' : incompleteProfile ? 'Complete profile' : undefined
          }
          onClickLink={
            unverifiedEmail ? handleClickVerify : incompleteProfile ? handleClickProfile : undefined
          }
          onClose={handleClickClose}
        />
      ) : null}
    </>
  );
}
