import { Box, CircularProgress, Divider, Typography, styled } from '@mui/material';
import { Dispatch } from 'react';
import SimpleBar from 'simplebar-react';

import { useGetNotificationListAPI } from 'api/notification/notificationAPI';
import { NoData } from 'elements';
import { NotificationItem } from '.';

import 'simplebar/dist/simplebar.min.css';

const Scrollbar = styled(SimpleBar)(({ theme }) => ({
  '& .simplebar-track.simplebar-vertical': {
    width: 6,

    '& .simplebar-scrollbar:before': {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundColor: theme.palette.gray[40],
    },

    '& .simplebar-scrollbar.simplebar-visible:before': {
      opacity: 1,
    },
  },
}));

const NotificationTitleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '28px',
  marginBottom: '12px',
});

const height = 430;

interface Props {
  revalidateCount: () => void;
  setIsListOpen: Dispatch<React.SetStateAction<boolean>>;
}

export function NotificationItemList({ revalidateCount, setIsListOpen }: Props) {
  const { data, isLoading, isValidating } = useGetNotificationListAPI();

  return (
    <div>
      <NotificationTitleBox>
        <Typography variant="subtitle1">Notification</Typography>
      </NotificationTitleBox>
      <Divider sx={{ marginRight: '28px' }} />

      <Box height={height}>
        {isLoading || isValidating ? (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="primary" size={64} />
          </Box>
        ) : data?.data && data.data.notification_send_list.length > 0 ? (
          <Scrollbar
            autoHide={false}
            sx={{
              maxHeight: height,
              position: 'relative',
              paddingRight: '25px',
              marginRight: '3px',
            }}
          >
            <Box paddingBottom="24px">
              {data?.data &&
                data.data.notification_send_list.map((item, index) => {
                  return (
                    <NotificationItem
                      key={item.notification_send_id}
                      badgeId={item.notification_send_id}
                      badgeSort={item.notification_category}
                      imageUrl={item.thumbnail_img_url}
                      message={item.badge_message}
                      isRead={item.read_flag}
                      link={item.pc_landing_url}
                      DateReceived={item.created_at}
                      isLast={index === data.data.notification_send_list.length - 1}
                      onRead={revalidateCount}
                      setIsListOpen={setIsListOpen}
                    />
                  );
                })}
            </Box>
          </Scrollbar>
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: '28px',
            }}
          >
            <NoData title="No notifications in the last 30 days." />
          </Box>
        )}
      </Box>
    </div>
  );
}
