import { useSelector } from 'react-redux';

import { usePrivateGameListAPI } from 'api/gamelist/gamelistAPI';
import { iRootState } from 'store';
import PrivateThemeProvider from 'theme/PrivateThemeProvider';
import { LoadingPage } from 'views/main/LoadingPage';
import NotFound from 'views/main/NotFound';
import DetailAuth from './DetailAuth';
import DetailLower from './DetailLower';
import { useGetDetailData } from './DetailProvider';
import ImportDraftProvider from './ImportDraftProvider';
import DetailReview from './review/DetailReview';
import DetailSurvey from './survey/DetailSurvey';
import DetailTabsWrapper from './tabs/DetailTabsWrapper';
import DetailUpper from './upper/DetailUpper';

export default function DetailMain() {
  const { isLogin, level } = useSelector((state: iRootState) => state.login);
  const { data: privateList, isLoading } = usePrivateGameListAPI();
  const { data, loading } = useGetDetailData();

  if (loading || isLoading) return <LoadingPage />;
  if (data?.display === false) return <NotFound />;

  // 일반게임일땐 null, 프라이빗 게임은 프라이빗 게임 리스트가 배열로 넘어옴
  if (data?.private_test !== null) {
    if (!isLogin) return <DetailAuth code="AUTH" />;

    // 어드민은 체크 없이 다 보여진다.
    if (level !== 100) {
      // 접근할수있는 pirvate game list
      const privateGame = !!privateList?.data.find((item) => item.id === data?.id);
      if (privateGame) {
        // 게임상태로 처리 Testing,Closed
        if (data?.state === 'UPCOMING') return <DetailAuth code="UPCOMING" />;
        if (data?.state === 'CLOSED') return <DetailAuth code="CLOSED" />;
      } else {
        return <DetailAuth code="AUTH" />;
      }
    }
  }

  return (
    <PrivateThemeProvider isPrivate={!!data?.private_test}>
      <ImportDraftProvider>
        <DetailUpper />
        <DetailTabsWrapper />
        <DetailLower />
        <DetailReview />
        <DetailSurvey />
      </ImportDraftProvider>
    </PrivateThemeProvider>
  );
}
