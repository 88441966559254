import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import eventTracker from 'utils/eventTracker';

export default function GpointTitleUsePointSection() {
  const navigate = useNavigate();

  const handleClickUseGpoints = () => {
    navigate('/store');
    eventTracker('profile_gpoint_use_gpoint');
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="80px">
      <Typography variant="h5">G.Points</Typography>
      <Button
        id="ground-profile-use-point-btn"
        variant="contained"
        size="small"
        onClick={handleClickUseGpoints}
      >
        Use G.Points
      </Button>
    </Box>
  );
}
