import { Box, Stack, styled, Typography } from '@mui/material';

import { LinkButton } from 'elements';

import gpointBannerImage from 'images/banner/g_points_bg.webp';
import gpointBannerImage2x from 'images/banner/g_points_bg@2x.webp';
import gpointBannerImage3x from 'images/banner/g_points_bg@3x.webp';
import { useLog } from 'utils/userLog';

const GPointBannerBlock = styled('section')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 280,
  backgroundColor: '#05040F',
  position: 'relative',
  overflowX: 'hidden',

  '& > img': {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

const GPointBannerContentContainer = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 94,
}));

const HomeGPointBanner: React.FC = () => {
  const { gxcTag } = useLog();

  return (
    <GPointBannerBlock>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          background: '#180E11',
        }}
      />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          background: '#091521',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Box sx={{ backgroundColor: '#05040F', flex: 1 }} />
        <img
          src={gpointBannerImage}
          srcSet={`${gpointBannerImage}, ${gpointBannerImage2x} 2x, ${gpointBannerImage3x} 3x`}
          alt="gpoint-banner"
        />

        <GPointBannerContentContainer>
          <Typography variant="h6" sx={{ zIndex: 2 }}>
            Redeem our product with your hard earned G.Points
          </Typography>
          <LinkButton
            variant="outlined"
            color="light"
            size="large"
            sx={{ marginTop: '28px' }}
            to="/store"
            onClick={() => gxcTag('click', { area: 'store', ui: 'view_more' })}
          >
            G.Point
          </LinkButton>
        </GPointBannerContentContainer>
        <Box
          sx={{
            background: 'linear-gradient(90deg, rgb(3,8,4) 0%, rgb(5, 4, 15) 100%);',
            flex: 1,
          }}
        />
      </Box>
    </GPointBannerBlock>
  );
};

export default HomeGPointBanner;
