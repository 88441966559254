import { Box, Link, styled, Typography } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.gray[90],
  width: 1200,
  height: 88,
  borderRadius: 5,
}));

export default function GStoreOrderDetailNotificationBanner() {
  return (
    <Root>
      <Typography variant="body1" color="text.secondary2">
        For any inquiries regarding your order, please contact{' '}
        <Link href="mailto:support@gameround.co" color="text.secondary">
          support@gameround.co
        </Link>
      </Typography>
    </Root>
  );
}
