import { Box, Stack } from '@mui/material';

import { ClassForm, Fullname, MajorForm } from 'components/university';

interface Props {
  majorCount: number;
}

export default function EducatorForm({ majorCount }: Props) {
  return (
    <Stack gap="32px">
      <Fullname />
      <Box display="flex" gap="60px">
        <MajorForm minCount={majorCount} />
        <ClassForm />
      </Box>
    </Stack>
  );
}
