import { Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { useAboutCountryStatisticsAPI } from 'api/about/aboutCountryStatisticsAPI';
import AboutGamerStatisticsCard from './component/AboutGamerStatisticsCard';

const PREFIX = 'ArtAboutGamerStatistics';

const classes = {
  cardsWrap: `${PREFIX}-cardsWrap`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 80,

  [`& .${classes.cardsWrap}`]: {
    display: 'flex',
    columnGap: 20,
  },
}));

export default function ArtAboutGamerStatistics() {
  const { isLoading, data } = useAboutCountryStatisticsAPI();

  const cardInfoList = [
    {
      id: 1,
      topText: 'Featured',
      title: 'Games',
      number: data?.data?.summary?.game_count ?? 0,
    },
    {
      id: 2,
      topText: 'Over',
      title: 'Users',
      number: data?.data?.summary?.user_count ?? 0,
    },
    {
      id: 3,
      topText: 'Users in',
      title: 'Regions',
      number: data?.data?.summary?.country_count ?? 0,
    },
    {
      id: 4,
      topText: 'Over',
      title: 'Supporters',
      number: 1000,
    },
  ];

  return (
    <Root>
      <Typography variant="h4" color="base.dimWhite" textAlign="center" marginBottom="32px">
        Our Community Statistics
      </Typography>
      <div className={classes.cardsWrap}>
        {cardInfoList.map((info, index) => (
          <AboutGamerStatisticsCard key={index} {...info} loading={isLoading} />
        ))}
      </div>
    </Root>
  );
}
