import { Box, Stack, Typography } from '@mui/material';
import { ComponentProps } from 'react';

import { Avatar } from '../../atoms';
import ReviewChips from './ReviewChips';
import ReviewTimestamp from './ReviewTimestamp';
import ReviewUniversity from './ReviewUniversity';

export interface ReviewUserProps {
  userAvatarUrl: string;
  username: string;
  university?: ComponentProps<typeof ReviewUniversity>;
  badge?: ComponentProps<typeof ReviewChips>;
  timestamp?: ComponentProps<typeof ReviewTimestamp>;
}

export default function ReviewUserInfo({
  userAvatarUrl,
  username,
  badge,
  university,
  timestamp,
}: ReviewUserProps) {
  return (
    <Stack gap="4px" flexShrink={1} overflow="hidden">
      {/* user info */}
      <Box display="flex" alignItems="center" gap="12px">
        {/* user */}
        <Box display="flex" alignItems="center" gap="8px">
          <Avatar size="small" src={userAvatarUrl} alt="user avatar" />
          <Typography noWrap variant="subtitle1">
            {username}
          </Typography>
        </Box>
        {/* university info */}
        {university ? <ReviewUniversity {...university} /> : null}
        {/* chips */}
        {badge ? <ReviewChips {...badge} /> : null}
      </Box>
      {/* play time info */}
      {timestamp ? <ReviewTimestamp {...timestamp} /> : null}
    </Stack>
  );
}
