import { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as ExpandMoreIcon } from 'images/about/aboutGamer/arrow_right.svg';

const PREFIX = 'ArtAboutGamerFAQ';

const classes = {
  panelWrap: `${PREFIX}-panelWrap`,
  panel: `${PREFIX}-panel`,
  desc: `${PREFIX}-desc`,
};

const Root = styled('div')(({ theme }) => ({
  width: 1080,
  height: 'auto',
  paddingBottom: 80,

  '& .MuiAccordion-root:before': {
    backgroundColor: 'transparent',
  },

  //panel이 펼쳐진 상태
  '& .MuiAccordion-root.Mui-expanded': {
    margin: '20px auto 0',
  },
  '& .MuiAccordion-root.Mui-expanded:first-of-type': {
    marginTop: 20,
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 62,
  },

  //panel
  [`& .${classes.panelWrap}`]: {
    margin: '20px auto 0',
    border: `1px solid ${alpha(theme.palette.base.white, 0.15)}`,
    borderRadius: 5,
    backgroundColor: '#3348b2',
    backgroundImage: 'none',
    boxShadow: 'none',

    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
      paddingBottom: 8,
      paddingLeft: 12,
    },
  },

  [`& .${classes.panel}`]: {
    height: 62,
    padding: '0 30px 0 12px',
    alignItems: 'center',
    transition: 'none',

    //panel 확장 아이콘
    '& svg': {
      opacity: 0.2,
    },
    '&:hover': {
      opacity: 'initial',
      '& svg': {
        opacity: 'initial',
      },
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 20,
    },
  },

  [`& .${classes.desc}`]: {
    maxWidth: 976,
    marginTop: 8,
    marginBottom: 0,
    paddingLeft: 20,
  },
}));

const faqText = [
  {
    title: 'What stage of game development is suitable for G.Round?',
    desc: [
      'We currently support PC or Console games from Pre-Alpha to Beta. Steam Early Access games are possible as well, but games in the development stage are preferred.',
    ],
  },
  {
    title: 'Can specific countries or a specific type of users be selected?',
    desc: [
      'We usually recommend your game be exposed to all users on the G.Round platform. It gives very meaningful data regarding target regions and users that love your game.',
      <>
        <a href="#gameTestingPackages" style={{ textDecoration: 'none', color: '#0089f2' }}>
          The premium-platinum package{' '}
        </a>
        can provide customized pre-selection of test users.
      </>,
    ],
  },
  {
    title:
      'Unlike other platforms like Steam or playtesting services, what are the values of G.Round?',
    desc: [
      'We test only two or three games weekly by carefully selecting high-quality games. Your game easily gets noticed by our community users and international content creators (influencers).',
      'We provide more constructive feedback from game users and structured detailed game analysis of game experts. And further business opportunities can be provided once G.Round test results are very positive.',
      'Users are incentivized to provide feedback by receiving G.Points that can be exchanged for real-life products improving their own gameplay.',
      <>
        Startup packages for early stage studios include business support from our partner,
        <a
          href="https://www.globaltopround.com/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: '#0089f2' }}
        >
          {' '}
          the GTR Accelerator
        </a>{' '}
        focusing on the growth of early game startups. GTR provides you with the consulting needed
        to improve your business, game & company pitch to potentially become a GTR Top 10 Cohort
        studio.
      </>,
    ],
  },
  {
    title: 'What kind of data can be obtained after the G.Round test?',
    desc: [
      'We provide an on-line report card that includes user preference data by regions and demographic, game evaluation results, and negative or positive words clustering with detailed user feedback.',
    ],
  },
  {
    title: 'How can a crowdfunding campaign proceed with the G.Round test?',
    desc: [
      'In partnership with Kickstarter, G.Round provides a special mix of game testing and crowdfunding. G.Round leads the campaign for crowdfunding under our name. Due to limited opportunities, only one or two games are selected quarterly.',
      'As a collaborator, G.Round participates in UA marketing campaigns and charges promotion fees based on our contributions, providing marketing support to any crowdfunding platform.',
    ],
  },
  {
    title: 'How many tests are possible? Can I come back to G.Round after my first test?',
    desc: [
      'Your game can be tested on G.Round multiple times from Pre-Alpha to Beta. But, we recommend leaving 3-4 months between playtests. Once you’ve made improvements following the suggestions, you can resubmit the game.',
    ],
  },
];

export default function ArtDevaboutFAQ() {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Root>
      <Typography variant="h4" marginBottom="32px" textAlign="center">
        FAQ
      </Typography>

      {faqText.map((row, idx) => (
        <Accordion
          key={idx}
          className={classes.panelWrap}
          square
          expanded={expanded === idx}
          onChange={handleChange(idx)}
        >
          <AccordionSummary
            className={classes.panel}
            expandIcon={<ExpandMoreIcon />}
            sx={{ opacity: expanded === idx ? 'initial' : 0.6 }}
          >
            <Typography variant="h6">{row.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul className={classes.desc}>
              {row.desc.map((item, index) => (
                <Typography
                  component="li"
                  variant="body1"
                  sx={{ listStyleType: 'none' }}
                  key={index}
                >
                  {item}
                  <br />
                  <br />
                </Typography>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </Root>
  );
}
