import { FormFieldArray, FormHiddenInput, FormInput } from '@ground/ui';

interface ClassField {
  class_id: number | null;
  class_name: string;
}

export default function ClassForm() {
  return (
    <FormFieldArray<ClassField>
      label="Class"
      fieldName="class"
      initialValue={{ class_id: null, class_name: '' }}
      minCount={1}
      maxCount={20}
    >
      {({ name }) => (
        <>
          <FormHiddenInput name={`${name}.class_id`} />
          <FormInput
            name={`${name}.class_name`}
            fullWidth
            withBackground
            placeholder="Enter Class name"
            rules={{ required: true, maxLength: { value: 50, message: 'max 50 characters' } }}
          />
        </>
      )}
    </FormFieldArray>
  );
}
