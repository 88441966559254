import { InputAdornment, inputAdornmentClasses, inputBaseClasses, styled } from '@mui/material';
import {
  FormEvent,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { GIconButton } from '../GIconButton';
import InputFieldBase, { InputFieldBaseProps } from '../InputFieldBase';

export interface StyledInputFieldProps extends InputFieldBaseProps {
  /** hide input */
  hideInput: boolean;
  width?: number;
}

const StyledInputField = styled(InputFieldBase, {
  shouldForwardProp: (prop) => prop !== 'hideInput' && prop !== 'width',
})<StyledInputFieldProps>(({ hideInput, width }) => ({
  width: !hideInput && width ? width : 'initial',
  justifyContent: 'flex-end',
  transition: 'background 0.3s',

  [`& .${inputBaseClasses.input}`]: {
    transition: 'width 0.3s',
  },

  // root와 input을 숨기고 검색 버튼만 보이게 만든다.
  ...(hideInput && {
    backgroundColor: 'transparent',
    cursor: 'auto',
    '&:hover': {
      borderColor: 'transparent',
    },

    [`&.${inputBaseClasses.focused}`]: {
      borderColor: 'transparent',
    },

    [`& .${inputBaseClasses.input}`]: {
      width: 0,
    },

    [`& .${inputAdornmentClasses.positionEnd}`]: {
      marginLeft: 0,
    },

    '& > .MuiInputBase-root': {
      backgroundColor: 'transparent',
    },
  }),
}));

export interface SearchFieldProps extends InputFieldBaseProps {
  /** 텍스트 입력 후 search 실행 시 실행되는 함수 */
  onSearch: (search: string) => void;
  /** hide input first time */
  hideInputFirst?: boolean;
  width?: number;
}

const SearchField = forwardRef((props: SearchFieldProps, ref: Ref<HTMLDivElement>) => {
  const { disabled, onSearch, hideInputFirst = false, width, defaultValue, ...other } = props;
  const [searchValue, setSearchValue] = useState((defaultValue as string) ?? '');
  const [hideInput, setHideInput] = useState(!defaultValue && hideInputFirst);
  const inputRef = useRef<HTMLInputElement>(null);

  // 기본 값이 변경되면 현재 값에 변경
  useEffect(() => {
    setSearchValue(defaultValue as string);
  }, [defaultValue]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSearch && onSearch(searchValue);
  };

  const handleClickSearch = useCallback(() => {
    hideInput && setHideInput(false);
    inputRef.current?.focus();
  }, [hideInput, inputRef]);

  const SearchAdorment = useMemo(
    () => (
      <InputAdornment position="end">
        <GIconButton
          disabled={disabled}
          iconName="search"
          iconSize={16}
          ButtonProps={{ type: 'submit' }}
          onClick={handleClickSearch}
        />
      </InputAdornment>
    ),
    [disabled, handleClickSearch]
  );

  return (
    <form onSubmit={handleSubmit}>
      <StyledInputField
        width={width}
        disabled={disabled}
        endAdornment={SearchAdorment}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        {...other}
        ref={ref}
        inputRef={inputRef}
        hideInput={hideInput}
      />
    </form>
  );
});

export default SearchField;
