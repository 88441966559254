import { HashLink } from '@xzar90/react-router-hash-link';
import { IHashLinkBaseProps } from '@xzar90/react-router-hash-link/dist/IHashLinkProps';
import { forwardRef } from 'react';
import { LinkProps } from 'react-router-dom';

export type GHashLinkProps = IHashLinkBaseProps & LinkProps;

const GHashLink = forwardRef((props: GHashLinkProps, _ref) => {
  // y좌표에 gnb-container 높이만큼 offset 추가하여 스크롤
  const scrollWithOffset = (el: HTMLElement) => {
    const gnbContainer = document.getElementById('gnb-container');
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -(gnbContainer?.clientHeight ?? 0);

    window.scrollTo({ top: yCoordinate + yOffset });
  };

  return <HashLink {...props} scroll={scrollWithOffset} />;
});

export default GHashLink;
