import { Box, Button, Typography } from '@mui/material';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSupporterApplyData } from '../../SupporterApplyProvider';
import SupporterQuestionTitle from './SupporterApplyQuestionTitle';

const clientApplyContents = [
  '身分証明書またはパスポートの画像を添付してください（画像は最大5枚まで可能です）',
  '添付画像には、ID番号、写真、その他の詳細情報など、必要な情報がすべて明確に表示されるようにお願いします。',
  'それぞれ 100MB 以下の jpg または png イメージを添付します',
];

const removeIcon = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="black" />
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M8.88141 8.88031C9.22312 8.5386 9.77714 8.5386 10.1189 8.88031L19.3112 18.0727C19.6529 18.4144 19.6529 18.9684 19.3112 19.3101C18.9695 19.6518 18.4155 19.6518 18.0738 19.3101L8.88141 10.1177C8.5397 9.77603 8.5397 9.22201 8.88141 8.88031Z"
        fill="#D2D7DD"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M8.88141 19.3101C8.5397 18.9684 8.5397 18.4144 8.88141 18.0727L18.0738 8.8803C18.4155 8.53859 18.9695 8.53859 19.3112 8.8803C19.6529 9.22201 19.6529 9.77603 19.3112 10.1177L10.1189 19.3101C9.77714 19.6518 9.22312 19.6518 8.88141 19.3101Z"
        fill="#D2D7DD"
      />
    </g>
  </svg>
);

const FileUploadButton = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { postFileInfo, setPostFileInfo } = useSupporterApplyData();
  const { register, setValue } = useFormContext();
  const name = 'files';

  const convertFileToBase64 = (fileList: File) => {
    if (fileList) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event?.target?.result as string;
        const data = Array.isArray(base64) ? base64[0] : base64;

        setPostFileInfo((original) => [...original, data]);
        setValue(name, data);
      };

      reader.onerror = (error) => {
        console.log('Error: ', error);
      };

      reader.readAsDataURL(fileList);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSizeInBytes = 100 * 1024 * 1024;
      const possibleCount = 5 - postFileInfo.length;

      if (postFileInfo.length + files.length > 5) {
        alert('Up to 5 files can be attached.');
        return;
      }

      for (let i = 0; i < possibleCount; i++) {
        const file = files[i];

        if (!allowedTypes.includes(file?.type) && file?.type !== undefined) {
          alert('You can only upload jpg or png files.');
          return;
        }

        if (file?.size > maxSizeInBytes) {
          alert('File size cannot exceed 100MB.');
          return;
        }

        convertFileToBase64(file);
      }
    }
  };

  return (
    <Box>
      <SupporterQuestionTitle
        question="ID 添付ファイルです"
        required
        // error={getValues('files').length === 0}
      />

      {clientApplyContents.map((text, index) => (
        <Typography key={index} variant="body2" color="text.secondary" mb="8px" component={'li'}>
          {text}
        </Typography>
      ))}

      <Box sx={{ display: 'flex' }} mt="32px">
        <Button onClick={handleButtonClick} variant="ghost" size="small">
          アップロードします
        </Button>
      </Box>

      <input
        // {...register(name, { required: true })}
        {...register(name)}
        type="file"
        accept="image/jpeg,image/png"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />

      <Box sx={{ display: 'flex', gap: '34px' }}>
        {postFileInfo.map((image: any, index: number) => (
          <Box key={image.id} sx={{ position: 'relative' }} mt="54px">
            <Box
              sx={{ position: 'absolute', top: -14, right: -14, cursor: 'pointer' }}
              onClick={() => {
                if (postFileInfo.length === 1) {
                  setValue(name, null);
                }
                setPostFileInfo((data) => data.filter((_data, idx) => idx !== index));
              }}
            >
              {removeIcon}
            </Box>

            <img alt="uploaded file" src={image} width={150} height={90} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FileUploadButton;
