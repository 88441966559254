import { Stack } from '@mui/material';

import { useGetDetailData } from '../DetailProvider';
import { useReviewData } from './ReviewProvider';
import {
  PrivateReviewQuestionTextField,
  ReviewQuestionChoice,
  ReviewQuestionTextField,
} from './component';

export default function ReviewPaper({ disabled = false }: { disabled?: boolean }) {
  const { data } = useReviewData();
  const { data: gameDetailData } = useGetDetailData();
  const isPrivate = typeof gameDetailData?.private_test === 'number';

  return data ? (
    <Stack rowGap="24px">
      {data?.essential_questions?.map((question, index) => (
        <ReviewQuestionChoice
          key={question.id}
          disabled={disabled}
          index={index}
          isPrivate={isPrivate}
          {...question}
        />
      ))}

      {data?.optional_questions?.map((question, index) =>
        isPrivate ? (
          <PrivateReviewQuestionTextField
            key={question.id}
            disabled={disabled}
            index={index}
            {...question}
            seq={(data?.essential_questions.length || 0) + index + 1}
            first_review={data.first_review}
          />
        ) : (
          <ReviewQuestionTextField
            key={question.id}
            disabled={disabled}
            index={index}
            {...question}
            seq={(data?.essential_questions.length || 0) + index + 1}
            first_review={data.first_review}
          />
        )
      )}
    </Stack>
  ) : null;
}
