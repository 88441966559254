import { GStoreDigitalProductOrderStatusEnum, GStoreOrderStatusEnum } from 'api/gstore/gstoreAPI';

/** @deprecated */
export function convertOrderStatusToWord(orderStatus?: keyof typeof GStoreOrderStatusEnum): string {
  if (!orderStatus) {
    return '';
  }

  switch (orderStatus) {
    case GStoreOrderStatusEnum.ORDERED:
      return 'Order Placed';
    case GStoreOrderStatusEnum.CONFIRMED:
      return 'Order Confirmed';
    case GStoreOrderStatusEnum.PACKING:
      return 'Packing';
    case GStoreOrderStatusEnum.IN_TRANSIT:
      return 'In Transit';
    case GStoreOrderStatusEnum.DELIVERED:
      return 'Delivered';
    case GStoreOrderStatusEnum.CANCELED_ORDER:
      return 'Order Cancelled';
    case GStoreOrderStatusEnum.CANCELED_PAYMENT:
      return 'Refund Completed';
    default:
      return orderStatus;
  }
}

/** @deprecated */
export function convertDigitalOrderStatusToWord(
  orderStatus?: GStoreDigitalProductOrderStatusEnum
): string {
  if (!orderStatus) {
    return '';
  }

  switch (orderStatus) {
    case GStoreDigitalProductOrderStatusEnum.ORDERED_REQUESTED:
      return 'Order Requested';
    case GStoreDigitalProductOrderStatusEnum.ORDERED:
      return 'Order Placed';
    case GStoreDigitalProductOrderStatusEnum.CONFIRMED:
      return 'Order Confirmed';
    case GStoreDigitalProductOrderStatusEnum.PACKING:
      return 'Packing';
    case GStoreDigitalProductOrderStatusEnum.IN_TRANSIT:
      return 'In Transit';
    case GStoreDigitalProductOrderStatusEnum.DELIVERED:
      return 'Delivered';
    case GStoreDigitalProductOrderStatusEnum.CANCELED_ORDER:
      return 'Order Cancelled';
    case GStoreDigitalProductOrderStatusEnum.CANCELED_PAYMENT:
      return 'Refund Completed';
    default:
      return orderStatus;
  }
}
