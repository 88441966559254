import { numberWithCommas } from '@ground/tools';
import { Box, Stack, Typography } from '@mui/material';

import { IStoreDigitalGame } from 'api/gstore';

interface Props {
  digitalGameData?: IStoreDigitalGame.GetProduct.Response;
}

export default function OrderProductInfo({ digitalGameData }: Props) {
  return (
    <Stack gap="20px" width="100%">
      {/* title */}
      <Stack gap="4px">
        <Typography variant="h5">Order/Payment</Typography>
        <Typography variant="body2" color="text.secondary">
          Please enter your shipping information and proceed with payment.
        </Typography>
      </Stack>
      {/* content */}
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'gray.90',
          padding: '20px',
          borderRadius: '5px',
          gap: '40px',
        }}
      >
        <img
          src={digitalGameData?.thumbnail_url}
          alt="product"
          style={{ width: 150, height: 120, borderRadius: 5, objectFit: 'cover' }}
        />
        <Stack gap="8px">
          <Typography variant="h6" color="text.primary">
            {digitalGameData?.title}
          </Typography>
          <Stack gap="4px">
            <Typography variant="body2" color="text.secondary">
              Quantity : 1
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Price :{' '}
              {typeof digitalGameData?.price === 'number'
                ? `${numberWithCommas(digitalGameData.price)} G.P`
                : ''}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
