import { Navigate, Route, Routes } from 'react-router-dom';

import GStoreOrderDetail from './GStoreOrderDetail';
import GStoreOrderDetailDigital from './digital/GStoreOrderDetailDigital';

export default function GStoreOrder() {
  return (
    <Routes>
      {/* Physical Goods, Digital Game 주문 상세 */}
      <Route path=":orderId" element={<GStoreOrderDetail />} />
      {/* Digital Goods 상세 */}
      <Route path="digital/:orderId" element={<GStoreOrderDetailDigital />} />
      <Route path="*" element={<Navigate to="/profile/orders" />} />
    </Routes>
  );
}
