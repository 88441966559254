export enum GameStatusType {
  PREPARING = 'PREPARING',
  APPLIED = 'APPLIED',
  CANDIDATE = 'CANDIDATE',
  UPCOMING = 'UPCOMING',
  TESTING = 'TESTING',
  CLOSED = 'CLOSED',
  SURVEY = 'SURVEY',
  GLOBALSURVEY = 'GLOBALSURVEY',
  ALL = 'ALL',
  BESTGAMES = 'BESTGAMES',
}

export enum GameSortByType {
  DATE = 'DATE',
  CREATEDDATE = 'CREATEDDATE',
  SEQUENCE = 'SEQUENCE',
  ENDDATE = 'ENDDATE',
  TOTALUSERS = 'TOTALUSERS',
}

export enum GameSortingType {
  DESCENDING = 'DESCENDING',
  ASCENDING = 'ASCENDING',
}

export enum CardListSortingType {
  createdAt = 'createdAt',
  likedCount = 'likedCount',
}
