/**
 *
 * object를 selectOption type의 array로 변환하는 함수
 * @params object { 남:1, 여:2 }
 * @return selectOptions [{ label: '남', value: 1 }, { label: '여', value: 2 }]
 */

export const getSelectOptions = <T extends number | string | boolean>(object: {
  [key: string]: string | number;
}) => {
  return Object.entries(object).map(([key, value]) => ({
    label: key,
    value: value,
  })) as { value: T; label: string }[];
};

/**
 *
 * object에서 value에 해당하는 key name을 반환하는 함수
 * @params obj { 남:1, 여:2 }
 * @params code 2
 * @return '남'
 */

export const getKeyNameOfValue = <K extends string>(
  obj: { [key in K]: string | number },
  code: string | number
) => {
  const found = Object.entries(obj).find(([_key, value]) => value === code);

  return found?.[0] as K;
};
