import { CredentialResponse, GoogleOAuthProvider, GoogleLogin as Login } from '@react-oauth/google';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useFetch } from 'api';
import { useDialog, useError, useSign } from 'hooks';
import { LoginPayload } from 'models/login';
import { useLog } from 'utils/userLog';
// import GoogleOAuthLogin from './GoogleOAuthLogin';

interface Props {
  isMobile?: boolean;
  isSignup?: boolean;
}

const googleClientId = '48174526186-rve67rd2rsi1clu63snbt7nv9ga183tl.apps.googleusercontent.com';

export default function GoogleLogin({ isMobile, isSignup }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<string | undefined>(undefined);
  const { fetch: fetchLogin } = useFetch<LoginPayload>('users/google', { method: 'POST' });
  const { showSimpleDialog } = useDialog();
  const { showError } = useError();
  const { signComplete } = useSign();
  const { gxcTag } = useLog();

  useEffect(() => {
    if (!wrapperRef.current) return;

    const instance = wrapperRef.current;
    setWidth(`${instance.offsetWidth}px`);
  }, []);

  const onSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      gxcTag('click', { area: 'account', ui: 'google' });

      const formData = queryString.stringify(credentialResponse);
      const response = await fetchLogin(formData);
      if (response.status === 200 && response.data.data) {
        // 로그인 성공한 경우
        signComplete(response.data.data);

        // event tracker
        // Requirement to add Script for Google Ads 2021.11.26
        // https://www.notion.so/gameround/c9aa543eb50443c29b0c8a8246dbebea
        gtag('event', 'conversion', { send_to: 'AW-669277837/uDS-CMudwoMDEI29kb8C' });
        // add conversion tracking code at 'sign up with google' button
        // https://ground.atlassian.net/browse/GR2023-126 2023.01.20
        if (isSignup) {
          gtag('event', 'conversion', { send_to: 'AW-669277837/xYp8COyNt4gYEI29kb8C' });
        }
      } else {
        console.error(response);
        showError(response);
      }
    },
    [fetchLogin, gxcTag, isSignup, showError, signComplete]
  );

  const onError = useCallback(() => {
    console.log('Sign in Failed');
    gxcTag('click', { area: 'account', ui: 'google' });

    showSimpleDialog(
      'An error happened when you tried to sign in with Google ID',
      'Disable your ad blockers and pop-up blockers, and try again.'
    );
  }, [gxcTag, showSimpleDialog]);

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      {/* <GoogleOAuthLogin isMobile={isMobile} isSignup={isSignup} /> */}
      <div ref={wrapperRef}>
        <Login
          onSuccess={onSuccess}
          onError={onError}
          logo_alignment="center"
          size="large"
          text={isSignup ? 'signup_with' : 'signin_with'}
          useOneTap
          width={width}
          // locale="en_US"
          // auto_select
        />
      </div>
    </GoogleOAuthProvider>
  );
}
