import { Box, Typography, Link } from '@mui/material';

const bottomText = [
  { title: 'Recommendations are limited to once per day.' },
  {
    title: 'Recommended games must be unreleased and not releasing in the next 2-3 months.',
  },
  {
    title:
      "You will earn 20 G.Points if the recommended game meets G.Round's criteria for consideration.",
  },
  { title: '1,000 G.Points is offered once the game is on G.Round.' },
  {
    title:
      'If the recommended game is in contact with G.Round for testing, it will be excluded from the recommendation.',
  },
  {
    title: (
      <>
        For any inquiries regarding your game recommendation, please contact{' '}
        <Link
          color="text.secondary"
          href="mailto:support@gameround.co"
          rel="noopener noreferrer"
          target="_blank"
        >
          support@gameround.co
        </Link>
      </>
    ),
  },
];

export default function RecommendGameBottomListText() {
  return (
    <Box component="ul" sx={{ margin: '32px 0', padding: '0 0 0 20px' }}>
      {bottomText.map((item, index) => (
        <Typography component="li" variant="body3" color="text.secondary" key={index}>
          {item.title}
        </Typography>
      ))}
    </Box>
  );
}
