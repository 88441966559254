import { TextConvertLink } from '@ground/tools';
import { Box, Button, Link, Stack, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { GIcon } from '../../atoms';

const StyledLink = styled(Link)(() => ({ color: 'inherit', textDecoration: 'underline' }));

const Picked = () => (
  <Stack color="primary.main" sx={{ position: 'absolute', top: 0, right: '10px' }}>
    <Typography
      color="text.primary"
      variant="button3"
      sx={{ backgroundColor: 'primary.main', padding: '6px 6px 4px' }}
    >
      Picked
    </Typography>
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="6" viewBox="0 0 43 6" fill="none">
      <path
        d="M43 0L43 6L21.5 1.398L-2.33915e-07 6L0 -2.10742e-06L21.5 -1.05371e-06L43 0Z"
        fill="currentColor"
      />
    </svg>
  </Stack>
);

interface ReviewTextareaProps {
  text: string;
  isTextSelectable: boolean;
  isBanned: boolean;
  isDeleted: boolean;
  hasHidePermission?: boolean;
  picked?: boolean;
  type: 'review' | 'reply';
}

export default function ReviewTextarea({
  text,
  isTextSelectable,
  isBanned,
  isDeleted,
  hasHidePermission,
  picked,
  type,
}: ReviewTextareaProps) {
  const textRef = useRef<HTMLParagraphElement>(null);

  const [fold, setFold] = useState(true);
  const [visibleMoreBtn, setVisibleMoreBtn] = useState(false);

  useEffect(() => {
    // article의 높이가 200 클때만 view more 버튼 표출
    textRef.current?.scrollHeight && setVisibleMoreBtn(textRef.current?.scrollHeight > 200);
  }, []);

  return (
    <Stack
      sx={{
        position: 'relative',
        backgroundColor: 'gray.80',
        borderRadius: '5px',
        padding: '40px',
        ...(visibleMoreBtn && { paddingBottom: '12px' }),
      }}
    >
      {/* review main text */}
      <Typography
        variant="review"
        whiteSpace="pre-wrap"
        ref={textRef}
        sx={{
          opacity: !isDeleted && isBanned && hasHidePermission ? 0.5 : 1,
          wordBreak: 'break-word',
          color: isBanned || isDeleted ? 'text.secondary2' : 'text.secondary',
          userSelect: isTextSelectable ? 'text' : 'none',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: fold ? 10 : 'initial',
        }}
      >
        {isDeleted ? (
          `${type} was deleted`
        ) : isBanned ? (
          hasHidePermission ? (
            <TextConvertLink wholeText={text} LinkComponent={StyledLink} />
          ) : (
            `${type} deleted by operator due to inappropriate content.`
          )
        ) : (
          <TextConvertLink wholeText={text} LinkComponent={StyledLink} />
        )}
      </Typography>
      {visibleMoreBtn && (
        <Box marginTop="20px" textAlign="center">
          <Button
            color="dark"
            endIcon={<GIcon iconName={fold ? 'arrow_down' : 'arrow_up'} iconSize={16} />}
            size="small"
            onClick={() => setFold(!fold)}
            variant="contained"
            sx={{ backgroundColor: 'transparent', opacity: 0.5 }}
          >
            View {fold ? 'More' : 'Less'}
          </Button>
        </Box>
      )}
      {picked ? <Picked /> : null}
    </Stack>
  );
}
