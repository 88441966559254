import { Box, styled, Typography } from '@mui/material';

import gpointStoreBackgroundImage from 'images/mobile/home/store_bg.png';
import gpointStoreBackgroundImage2x from 'images/mobile/home/store_bg@2x.png';
import gpointStoreBackgroundImage3x from 'images/mobile/home/store_bg@3x.png';

const GPointStoreContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: 214,
  overflow: 'hidden',
  maxHeight: 214,
}));

const GPointStore: React.FC = () => {
  return (
    <GPointStoreContainer>
      <img
        src={gpointStoreBackgroundImage}
        srcSet={`${gpointStoreBackgroundImage}, ${gpointStoreBackgroundImage2x} 2x, ${gpointStoreBackgroundImage3x} 3x`}
        alt="background"
        style={{
          objectFit: 'cover',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
        }}
      />
      <Box
        sx={{
          zIndex: 1,
          position: 'absolute',
          top: '50%',
          left: 20,
          right: 20,
          transform: 'translateY(-50%)',
        }}
      >
        <Typography variant="h4" mb="8px">
          G.Point Store
        </Typography>
        <Typography variant="body2" color="text.secondary">
          You can use G.Points to redeem merchandise in our G.Point Store!
        </Typography>
      </Box>
    </GPointStoreContainer>
  );
};

export default GPointStore;
