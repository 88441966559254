import { FormRadioGroup } from '@ground/ui';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  useWithDrawalReasonAPI,
  useWithDrawalRequestAPI,
} from 'api/deleteAccount/deleteAccountAPI';
import { Dispatch } from 'store';

interface DeleteFinalRequestProps {
  closeDialog: () => void;
}

export interface FormData {
  reason: number;
}

// 탈퇴 설문 요청
export default function DeleteSurveySubmit({ closeDialog }: DeleteFinalRequestProps) {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormData>({ mode: 'onChange' });
  const { data } = useWithDrawalReasonAPI(); // 주문상태 확인
  const { fetch } = useWithDrawalRequestAPI(); // 탈퇴요청

  const reasonList =
    data?.data?.user_withdrawal_reasons?.map((item, index) => ({
      label: item.code_name,
      order: index,
      id: item.code,
    })) ?? [];

  const onClickRequestHandler = async (data: FormData) => {
    const response = await fetch({
      reasons_code: reasonList[data.reason].id,
      request_state: 'REQUEST',
    });

    if (response?.status === 200 && response.data?.result_code === 'OK') {
      dispatch.login.logout();
      enqueueSnackbar('success', { variant: 'success' });
      navigate('/');
      closeDialog();
    } else {
      // todo 구체적으로 처리할것
      enqueueSnackbar('error', { variant: 'error' });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onClickRequestHandler)}>
          <Typography variant="button1" color="text.primary">
            Why do you want to delete your account?
          </Typography>

          <Divider sx={{ margin: '20px 0' }} />

          <FormRadioGroup
            options={reasonList}
            name="reason"
            rules={{ required: true }}
            sx={{ rowGap: '20px', flexDirection: 'column' }}
            defaultValue="0"
          />

          <Divider sx={{ margin: '20px 0' }} />

          {methods.formState.errors.reason && (
            <Typography color={'error.main'} mt="8px" variant="body3">
              {'Please select a reason'}
            </Typography>
          )}

          <Box mt="40px" mb="16px">
            <Button color="primary" variant="contained" type="submit" fullWidth>
              Submit
            </Button>
            <Box mt="12px"></Box>
            <Button color="primary" variant="ghost" onClick={closeDialog} fullWidth>
              Cancel
            </Button>
          </Box>
          <Typography variant="body3" color="text.secondary2">
            You will be logged out after requsting account deletion.
          </Typography>
        </form>
      </FormProvider>
    </>
  );
}
