import { LoadingWrapper } from '@ground/ui';
import { Link, Typography } from '@mui/material';
import { EmbeddedCheckout, EmbeddedCheckoutProvider, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCheckPaymentStatus } from 'api/payment/paymentAPI';
import { useDialog, useFetchResult } from 'hooks';
import { iRootState } from 'store';

interface Props {
  clientSecret: string;
  checkoutSessionId: string;
  onComplete?: () => void;
}

export default function PaymentElement({ clientSecret, checkoutSessionId, onComplete }: Props) {
  const stripe = useStripe();
  const { showDialog } = useDialog();
  const { showResult } = useFetchResult();

  const navigate = useNavigate();
  const { isLoading, fetch } = useCheckPaymentStatus(checkoutSessionId);
  const { email: userEmail } = useSelector((state: iRootState) => state.login);

  const handleComplete = useCallback(async () => {
    const result = await fetch();
    showResult(result, {
      hideErrorMessage: true,
      onSuccess: () => {
        onComplete?.();
        showDialog({
          title: 'Purchase Successful!',
          body: (
            <>
              <Typography color="text.primary" variant="body1">
                Thank you for buying G.Points :) <br />
                Payment details will be sent to your <br />
                registered email from Stripe Inc.
              </Typography>
              <Typography color="text.secondary" variant="body2" mt="20px">
                For purchase inquiries, contact
                <br />
                <Link
                  href="mailto:support@gameround.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="text.secondary"
                >
                  support@gameround.co
                </Link>
              </Typography>
            </>
          ),
          primaryButton: {
            text: 'Check My G.Points',
            callback: () => navigate('/profile/gpoint'),
          },
          secondaryButton: { text: 'Go to G.Point Store', callback: () => navigate('/store') },
        });
      },
      onError: () => {
        showDialog({
          title: 'Sorry, Purchase Failed',
          body: (
            <Typography variant="body1" color="text.primary">
              Purchase failed.
              <br />
              Please retry or email us for assistance.
            </Typography>
          ),
          primaryButton: {
            text: 'Back to G.Points List',
            callback: () => navigate('/profile/gpoint'),
          },
          secondaryButton: {
            text: 'Write Inquiry Email',
            callback: () => {
              const email = 'support@gameround.co';
              const subject = encodeURIComponent(
                `Inquiry about G.Points Purchase: ${checkoutSessionId}`
              );
              const emailBody = encodeURIComponent(
                `• Stripe Checkout Session ID: ${checkoutSessionId}\n• User Login ID: ${userEmail}\n------------------------------------------------\nPlease write your inquiry starting from here.`
              );
              window.open('mailto:' + email + '?subject=' + subject + '&body=' + emailBody);
            },
          },
        });
      },
    });
  }, [checkoutSessionId, fetch, navigate, onComplete, showDialog, showResult, userEmail]);

  return (
    <LoadingWrapper isLoading={isLoading} sx={{ height: '600px' }}>
      {clientSecret && checkoutSessionId ? (
        <EmbeddedCheckoutProvider
          stripe={stripe}
          options={{
            clientSecret,
            // onComplete: 결제가 승인 완료된 이후에만 실행됨
            onComplete: handleComplete,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : null}
    </LoadingWrapper>
  );
}
