import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';

import { useGetUniversityMemberInfoAPI } from 'api/university/universityAPI';
import type { EducatorFormData, StudentFormData, UniversityUserType } from 'types/UniversityType';
import EducatorDetails from './EducatorDetails';
import StudentDetails from './StudentDetails';

interface Props {
  type: UniversityUserType;
}

// 교수 학생 정보 수정
export default function UniversityDetails({ type }: Props) {
  const { isValidating, data, mutate } = useGetUniversityMemberInfoAPI(type);

  const educatorDefaultValues: EducatorFormData = {
    name: data?.data?.educator?.educator_fullname ?? '',
    major: data?.data?.major_list?.map((major) => major) ?? [{ major_id: null, major_name: '' }],
    class: data?.data?.class_list?.map((classItem) => classItem) ?? [
      { class_id: null, class_name: '' },
    ],
  };

  const studentDefaultValues: StudentFormData = {
    name: data?.data?.student?.student_fullname ?? '',
    student_number: data?.data?.student?.student_number ?? '',
    major: data?.data?.student?.student_majors?.map((major) => ({
      major_id: null, // 학생의 전공엔 id가 필요 없음
      major_name: major,
    })) ?? [{ major_id: null, major_name: '' }],
    class: data?.data?.class_list?.map((classItem) => ({ class_id: classItem.class_id })) ?? [
      { class_id: null },
    ],
  };

  return (
    <Stack gap="20px">
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Typography color="text.primary" variant="h5">
          University Details
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {data?.data.university_name ?? ''}
        </Typography>
      </Box>
      <Paper sx={{ padding: '40px' }}>
        {isValidating ? (
          <Box display="flex" justifyContent="center" padding="80px">
            <CircularProgress size={48} />
          </Box>
        ) : type === 'educator' ? (
          <EducatorDetails defaultValues={educatorDefaultValues} mutateInfo={mutate} />
        ) : (
          <StudentDetails defaultValues={studentDefaultValues} mutateInfo={mutate} />
        )}
      </Paper>
    </Stack>
  );
}
