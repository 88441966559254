import { Box, Link, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { LinkButton } from 'elements';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { AppleLogin, EmailSignButton, FacebookLogin, GoogleLogin, SignLayout } from '../component';
import { useSignupData } from './SignupProvider';

export default function SignupBasicForm() {
  const { search, state } = useLocation();
  const { isMobile } = useSignupData();
  const { gxcTag } = useLog();

  const handleClickTerms = () => {
    eventTracker('signup_terms');
    gxcTag('click', { area: 'agreement', ui: 'terms_of_use' });
  };

  const handleClickPrivacy = () => {
    eventTracker('signup_privacy');
    gxcTag('click', { area: 'agreement', ui: 'privacy_policy' });
  };

  const handleClickSignin = () => {
    eventTracker('signup_signin');
    gxcTag('click', { area: 'signin', ui: 'signin' });
  };

  return (
    <SignLayout isMobile={isMobile} title="Sign up">
      <Box mb="40px" textAlign="center">
        {!isMobile && (
          <Typography color="text.primary" mb="10px" variant="h3">
            Sign Up
          </Typography>
        )}
        <Typography color="text.secondary" variant="subtitle2">
          Discover upcoming games
        </Typography>
      </Box>

      <Stack rowGap="12px" width="100%">
        <FacebookLogin isMobile={isMobile} isSignup />
        <GoogleLogin isMobile={isMobile} isSignup />
        <AppleLogin isMobile={isMobile} isSignup />
        <EmailSignButton isMobile={isMobile} isSignup />
      </Stack>

      <Typography variant={isMobile ? 'body2' : 'body3'} color="text.secondary2" mt="20px">
        By signing up, you agree to the{' '}
        <Link
          href="/userTerms.pdf"
          target="_blank"
          rel="noopener noreferrer"
          color="text.secondary"
          onClick={handleClickTerms}
        >
          Terms of Use
        </Link>
        {' and the '}
        <Link
          href="/privacyPolicy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          color="text.secondary"
          onClick={handleClickPrivacy}
        >
          Privacy Policy
        </Link>
        .
      </Typography>

      <Typography align="center" color="text.secondary" variant="body2" mt="40px">
        Already have an account?
      </Typography>

      <LinkButton
        to={`/login${search}`}
        fullWidth
        onClick={handleClickSignin}
        variant="ghost"
        size={isMobile ? 'largest' : 'large'}
        state={state}
        sx={{ mt: '20px' }}
      >
        Sign in
      </LinkButton>
    </SignLayout>
  );
}
