import { GIconButton } from '@ground/ui';
import { Box, Link, styled } from '@mui/material';

import { GameDetailProps } from 'types/GameDetailTypes';
import eventTracker from 'utils/eventTracker';

const SnsLink = styled(Link)({
  display: 'inline-flex',
  marginRight: 16,
});

interface Props {
  data: GameDetailProps;
}

export default function GameInfoSnsChannels({ data }: Props) {
  const handleClickSnsLink = (name: string) => () => {
    eventTracker(`gamedetail_tab_info_${name}`, { event_label: data.id });
  };

  return (
    <Box>
      {data.facebook && (
        <SnsLink href={data.facebook} target="_blank" onClick={handleClickSnsLink('facebook')}>
          <GIconButton
            iconName="facebook"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </SnsLink>
      )}
      {data.discord && (
        <SnsLink href={data.discord} target="_blank" onClick={handleClickSnsLink('discord')}>
          <GIconButton
            iconName="discord"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </SnsLink>
      )}
      {data.twitter && (
        <SnsLink href={data.twitter} target="_blank" onClick={handleClickSnsLink('twitter')}>
          <GIconButton
            iconName="x"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </SnsLink>
      )}

      {data.instagram && (
        <SnsLink href={data.instagram} target="_blank" onClick={handleClickSnsLink('instagram')}>
          <GIconButton
            iconName="instagram"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </SnsLink>
      )}
      {data.youtube && (
        <SnsLink href={data.youtube} target="_blank" onClick={handleClickSnsLink('youtube')}>
          <GIconButton
            iconName="youtube"
            iconSize={24}
            size={42}
            variant="circle"
            backgroundColor="gray.60"
          />
        </SnsLink>
      )}
    </Box>
  );
}
