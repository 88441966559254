import { isNumber } from 'lodash-es';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import Carousel from 'elements/Carousel';
import AboutYoutubePlayer from './AboutYoutubePlayer';

interface SliderData {
  id: number;
  link: string;
}

interface AboutSlideProps {
  sliderData?: SliderData[];
  slideIndex?: number;
  onChangeIndex?: Dispatch<SetStateAction<number>>;
}

export default function AboutSlide({ sliderData, slideIndex, onChangeIndex }: AboutSlideProps) {
  const [pause, setPause] = useState(false);
  const ref = useRef<Swiper | null>(null);

  const handleTransitionStart = () => {
    setPause(true);
  };

  const handleTransitionEnd = () => {
    setPause(false);
  };

  // slider index 변경 시 swiper slide index도 같이 변경
  useEffect(() => {
    if (isNumber(slideIndex)) ref.current?.slideTo(slideIndex);
  }, [slideIndex]);

  return (
    <Carousel
      width={760}
      height={427}
      name="about-videos"
      hasNavigation={sliderData && sliderData.length > 1}
      navBtnIconType="arrow"
      navBtnRowPosition={-68}
      swiperOption={{ slidesPerView: 1 }}
      onSlideChangeTransitionStart={handleTransitionStart}
      onSlideChangeTransitionEnd={handleTransitionEnd}
      onChangeIndex={onChangeIndex}
      onSwiper={(swiper) => {
        ref.current = swiper;
      }}
    >
      {sliderData?.map((content) => (
        <SwiperSlide key={content.id}>
          <AboutYoutubePlayer url={content.link} globalPause={pause} />
        </SwiperSlide>
      ))}
    </Carousel>
  );
}
