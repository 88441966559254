import { Divider, Typography } from '@mui/material';

interface ReviewUniversityProps {
  name: string;
  studentId: string;
  majorList: string[];
}

export default function ReviewUniversity(props: ReviewUniversityProps) {
  const { name, studentId, majorList } = props;

  const infoText = `${name} · ${studentId} · ${majorList.join(', ')}`;

  return (
    <>
      <Divider
        flexItem
        orientation="vertical"
        sx={{ marginY: '6px', borderColor: 'opacity.white.10' }}
      />
      <Typography color="text.secondary" noWrap variant="body2">
        {infoText}
      </Typography>
    </>
  );
}
