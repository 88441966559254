import { AutoResizeImage } from '@ground/tools';
import { SmallChip } from '@ground/ui';
import { Box, Stack, Typography } from '@mui/material';
import format from 'date-fns/format';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { AnnouncementType } from 'types/AnnouncementTypes';

interface BlogListItemProps {
  data: AnnouncementType;
}

export default function FilterList({ data }: BlogListItemProps) {
  const { search } = useLocation();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Box
      ref={hoverRef}
      width="840px"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        py: '28px',
      })}
    >
      <Link to={`/blog/${data.announce_id}`} state={search}>
        <Box sx={{ columnGap: '40px', display: 'flex' }}>
          <Stack gap="12px" flex={1}>
            <Box display="flex" gap="12px">
              <SmallChip bgColor="white" color="gray.100" text={data.announce_type_name} />
              <Typography
                color={isHover ? 'base.white' : 'text.primary'}
                variant="subtitle1"
                noWrap
              >
                {data.announce_title}
              </Typography>
            </Box>

            <Typography
              color={isHover ? 'base.white' : 'text.secondary'}
              variant="body2"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                height: '40px',
              }}
            >
              {data.announce_desc}
            </Typography>

            <Typography color={isHover ? 'base.white' : 'text.secondary2'} variant="body3">
              {data.rgst_at ? format(new Date(data.rgst_at), 'MMM d, yyyy') : ''}
            </Typography>
          </Stack>

          <AutoResizeImage
            style={{
              borderRadius: '5px',
              transition: 'filter .3s, all 0.2s ease-in ',
              transform: isHover ? 'scale(1.1)' : '',
            }}
            src={data.announce_thumb_location}
            alt="blog_image"
            resizeWidth={169}
            resizeHeight={101}
          />
        </Box>
      </Link>
    </Box>
  );
}
