import { Box, Stack } from '@mui/material';
import { ComponentProps } from 'react';

import { ReviewBottom, ReviewStatusArea, ReviewTextarea, ReviewUserInfo } from '../../elements';

interface ReplyProps {
  userInfoProps: Omit<ComponentProps<typeof ReviewUserInfo>, 'university' | 'timestamp'>;
  statusAreaProps: Omit<ComponentProps<typeof ReviewStatusArea>, 'distributed'>;
  textfieldProps: Omit<ComponentProps<typeof ReviewTextarea>, 'picked' | 'type'>;
  bottomProps?: Omit<ComponentProps<typeof ReviewBottom>, 'replyProps' | 'pickProps'>;
}

export default function Reply({
  userInfoProps,
  statusAreaProps,
  textfieldProps,
  bottomProps,
}: ReplyProps) {
  return (
    <Stack width="100%" gap="12px">
      <Box display="flex" gap="40px" alignItems="flex-end" justifyContent="space-between">
        {/* left area */}
        <ReviewUserInfo {...userInfoProps} />
        {/* right area */}
        <ReviewStatusArea {...statusAreaProps} />
      </Box>
      {/* text area */}
      <ReviewTextarea {...textfieldProps} type="reply" />
      {/* bottom area */}
      {bottomProps ? <ReviewBottom {...bottomProps} /> : null}
    </Stack>
  );
}
