import { Box, Stack } from '@mui/material';
import { ComponentProps } from 'react';

import { ReviewBottom, ReviewStatusArea, ReviewTextarea, ReviewUserInfo } from '../../elements';

interface ReviewProps {
  userInfoProps: ComponentProps<typeof ReviewUserInfo>;
  statusAreaProps: ComponentProps<typeof ReviewStatusArea>;
  textfieldProps: Omit<ComponentProps<typeof ReviewTextarea>, 'type'>;
  bottomProps?: ComponentProps<typeof ReviewBottom>;
}

export default function Review({
  userInfoProps,
  statusAreaProps,
  textfieldProps,
  bottomProps,
}: ReviewProps) {
  return (
    <Stack width="100%" gap="12px">
      <Box display="flex" gap="40px" alignItems="flex-end" justifyContent="space-between">
        {/* left area */}
        <ReviewUserInfo {...userInfoProps} />
        {/* right area */}
        <ReviewStatusArea {...statusAreaProps} />
      </Box>
      {/* text area */}
      <ReviewTextarea {...textfieldProps} type="review" />
      {/* bottom area */}
      {bottomProps ? <ReviewBottom {...bottomProps} /> : null}
    </Stack>
  );
}
