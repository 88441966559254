import { createContext, useContext, useState, SetStateAction, Dispatch, ReactNode } from 'react';

export interface ClipPopupProps {
  dataIndex: number;
  setDataIndex: Dispatch<SetStateAction<number>>;
}

const ClipPopupProviderContext = createContext<ClipPopupProps | undefined>(undefined);

export default function ClipPopupProvider({ children }: { children: ReactNode }) {
  const [dataIndex, setDataIndex] = useState(0);

  return (
    <ClipPopupProviderContext.Provider
      value={{
        dataIndex,
        setDataIndex,
      }}
    >
      {children}
    </ClipPopupProviderContext.Provider>
  );
}

export const useClipPopupData = () => {
  const context = useContext(ClipPopupProviderContext);
  if (!context) throw new Error('PopupProvider context not found');
  return context;
};
