import { Box, IconButton, Link, Typography } from '@mui/material';
import { Link as RouteLink } from 'react-router-dom';

import eventTracker from 'utils/eventTracker';

import closeIcon from 'images/icons/close_btn.svg';

interface NotificationBannerProps {
  type: 'desktop' | 'mobile';
  name: string;
  text: string;
  linkText?: string;
  linkTo?: string;
  onClickLink?: () => void;
  showCloseButton?: boolean;
  onClose?: () => void;
}

export default function NotificationBanner({
  type,
  name,
  text,
  linkText,
  linkTo,
  onClickLink,
  showCloseButton,
  onClose,
}: NotificationBannerProps) {
  const handleClickLink = () => {
    onClickLink?.();
    eventTracker(`promotion_banner_${type}_${name}`);
    twttr?.conversion.trackPid('o91ds', { tw_sale_amount: 0, tw_order_quantity: 0 });
    gtag('event', 'conversion', { send_to: 'AW-669277837/WW4ICMKdsMQDEI29kb8C' });
  };

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent={type === 'desktop' ? 'center' : 'space-between'}
      padding={type === 'desktop' ? '8px 11px' : '12px 14px'}
      gap="16px"
      width="100%"
      sx={{ backgroundColor: 'primary.main' }}
    >
      <Typography
        color="base.white"
        variant={type === 'desktop' ? 'button2' : 'body3'}
        noWrap={type === 'desktop'}
      >
        {text}
      </Typography>

      {linkText &&
        (linkTo ? (
          <Link
            component={RouteLink}
            onClick={handleClickLink}
            to={linkTo}
            variant={type === 'desktop' ? 'button2' : 'subtitle3'}
            sx={{
              color: 'base.white',
              flexShrink: 0,
              ...(type === 'mobile' && { fontWeight: 700 }),
            }}
          >
            {linkText}
          </Link>
        ) : (
          <Link
            onClick={handleClickLink}
            variant={type === 'desktop' ? 'button2' : 'subtitle3'}
            sx={{
              color: 'base.white',
              cursor: 'pointer',
              flexShrink: 0,
              ...(type === 'mobile' && { fontWeight: 700 }),
            }}
          >
            {linkText}
          </Link>
        ))}
      {/* 아직 모바일에선 close 고려안함 */}
      {type === 'desktop' && showCloseButton && (
        <IconButton
          data-testid="closeNotiBanner"
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, padding: '11px' }}
        >
          <img src={closeIcon} alt="close button" />
        </IconButton>
      )}
    </Box>
  );
}
