import { numberWithCommas } from '@ground/tools';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography, styled } from '@mui/material';
import { format } from 'date-fns';

import { GStoreOrderStatusEnum, useCancelOrderAPI } from 'api/gstore/gstoreAPI';
import { useDialog, useFetchResult } from 'hooks';
import eventTracker from 'utils/eventTracker';
import { useGStoreOrderDetailData } from './GStoreOrderDetailProvider';

const PREFIX = 'GStoreOderDetailTitleBox';

const classes = {
  infoBox: `${PREFIX}-infoBox`,
  productImage: `${PREFIX}-productImage`,
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.infoBox}`]: {
    padding: 20,
    display: 'flex',
    background: theme.palette.gray[90],
    borderRadius: 5,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  [`& .${classes.productImage}`]: {
    width: 150,
    height: 120,
    borderRadius: 3,
  },
}));

export default function GStoreOrderDetailTitleBox() {
  const { isLoading, data, mutate, orderId } = useGStoreOrderDetailData();
  const { showDialog } = useDialog();
  const { showResult } = useFetchResult();
  const { isLoading: loadingCancelOrder, fetch: fetchCancelOrder } = useCancelOrderAPI(orderId);
  const productData = data?.data?.product;

  const handleClickCancel = () => {
    eventTracker('profile_order_detail_cancelorder', { event_label: orderId ?? '' });
    showDialog({
      title: 'Order cancellation',
      body: 'Are you sure to cancel this order?',
      primaryButton: {
        text: 'Confirm',
        isLoadingButton: true,
        callback: async () => {
          const response = await fetchCancelOrder();
          showResult(response, {
            onSuccess: () => {
              mutate?.();
            },
          });
        },
      },
      secondaryButton: { text: 'Cancel' },
    });
  };

  return (
    <Root>
      <Typography variant="h5" mb="20px">
        Order Detail
      </Typography>
      {!isLoading && (
        <Box className={classes.infoBox}>
          <Box display="flex" columnGap="40px">
            <img className={classes.productImage} src={productData?.thumbnail_url} alt="product" />

            <div>
              <Typography variant="h6">{productData?.title}</Typography>
              <Typography variant="body2" color="text.secondary" mt="8px">
                Quantity : {productData?.quantity}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt="4px">
                Price : {productData?.price && `${numberWithCommas(productData.price)} G.P`}
              </Typography>
              <Typography variant="body3" color="text.secondary" mt="24px">
                <span style={{ marginRight: 32 }}>Order Number {data?.data?.id}</span>
                <span>
                  Order Date :{' '}
                  {data?.data?.order_date &&
                    `${format(new Date(data.data.order_date), 'MMM dd, yyyy hh:mm a')}(PT)`}
                </span>
              </Typography>
            </div>
          </Box>
          {data?.data.type === 'PHYSICAL_GOODS' &&
            (data.data.status === GStoreOrderStatusEnum.ORDERED ||
              data.data.status === GStoreOrderStatusEnum.CONFIRMED) && (
              <LoadingButton
                loading={loadingCancelOrder}
                onClick={handleClickCancel}
                variant="ghost"
              >
                Cancel Order
              </LoadingButton>
            )}
        </Box>
      )}
    </Root>
  );
}
