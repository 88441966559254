import { Box, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { ImageBox } from 'atoms';
import { YoutubePlayer } from 'components';
import { Carousel } from 'elements';
import client_apply_blur from 'images/recruitment/client_apply_blur.png';
import { useClientApplyData } from '../../ClientApplyProvider';

const WIDTH = 362;
const HEIGHT = 204;

const CarouselWrapper = styled(Carousel)({
  borderRadius: 5,
  paddingBottom: 29,
});

export default function DetailSlide() {
  const [pause, setPause] = useState(false);
  const { recruitmentData } = useClientApplyData();

  const handleTransitionStart = () => setPause(true);
  const handleTransitionEnd = () => setPause(false);

  return recruitmentData?.cover_vidimg === 'y' &&
    recruitmentData?.images &&
    recruitmentData?.images.length ? (
    <CarouselWrapper
      width={WIDTH}
      height={HEIGHT}
      name="detail-contents"
      bulletWidth={48}
      hasPagination
      onSlideChangeTransitionStart={handleTransitionStart}
      onSlideChangeTransitionEnd={handleTransitionEnd}
    >
      {recruitmentData.images?.map((content, index) =>
        content.type === 'VIDEO' ? (
          <SwiperSlide key={index} style={{ width: WIDTH, height: HEIGHT }}>
            <YoutubePlayer
              url={content.image_url}
              globalPause={pause}
              height={HEIGHT}
              width={WIDTH}
            />
          </SwiperSlide>
        ) : (
          <SwiperSlide key={index} style={{ width: WIDTH, height: HEIGHT }}>
            <ImageBox
              src={content.image_url}
              alt="cover image"
              resize
              resizeWidth={WIDTH}
              resizeHeight={HEIGHT}
              format="webp"
              sx={{ borderRadius: '5px' }}
            />
          </SwiperSlide>
        )
      )}
    </CarouselWrapper>
  ) : (
    <Box
      width={WIDTH}
      height={HEIGHT}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img
        width="100%"
        height={HEIGHT}
        src={client_apply_blur}
        alt="blur_img"
        style={{
          position: 'absolute',
        }}
      />
      <Typography variant="subtitle1" textAlign="center" position="absolute">
        This is undisclosed information.
      </Typography>
    </Box>
  );
}
