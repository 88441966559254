import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    height: 24,
    margin: '0 8px',
    minWidth: 24,
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  '& .MuiPaginationItem-circulared': {
    borderRadius: 6,
  },
  ' & .MuiPaginationItem-icon': {
    fontSize: '1.5rem',
  },
}));

export interface GPaginationProps {
  count?: number;
  defaultPage?: number;
  onChange?: (page: number) => void;
  page?: number;
}

export default function GPagination({ count, defaultPage, onChange, page }: GPaginationProps) {
  return (
    <StyledPagination
      count={count}
      color="secondary"
      defaultPage={defaultPage}
      onChange={(event: object, page: number) => onChange && onChange(page)}
      page={page}
      // renderItem={(item: any) => {
      //   console.log(item);
      //   return <PaginationItem {...item} />;
      // }}
      shape="rounded"
    />
  );
}
