import { useMemo } from 'react';

import { useTestingGameVideoCollectionAPI } from 'api/clips/clipsAPI';
import { ContentsLayout, PageLayout } from 'components';
import { featureClips, storeClips } from 'utils/clipsData';
import ClipBanner from './ClipBanner';
import ClipFeatureListCardLarge from './ClipFeatureListCardLarge';
import ClipStoreListCardLarge from './ClipStoreListCardLarge';
import TestingGameClips from './TestingGameClips';

export default function ClipsMain() {
  const { isLoading, data, isValidating, error } = useTestingGameVideoCollectionAPI();

  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const testingList = useMemo(() => data?.data, [data]);

  return (
    <PageLayout>
      <ClipBanner />

      <ContentsLayout>
        <ClipFeatureListCardLarge
          dataList={featureClips}
          count={featureClips.length}
          type="feature"
          title={`Featured Clips (${featureClips.length})`}
        />

        {isDataNotExist ? null : (
          <TestingGameClips count={4} dataList={testingList} isLoading={isLoading} />
        )}

        <ClipStoreListCardLarge
          dataList={storeClips}
          count={storeClips.length}
          type="store"
          title={`Games on G.Point Store (${storeClips.length})`}
        />
      </ContentsLayout>
    </PageLayout>
  );
}
