import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ContainerLink, SectionContainer } from 'components';
import { ClipCardSmall, ClipCardSmallSkeleton } from 'elements/NewClipCard';
import { TestingGameVideoCollectionType } from 'types/ClipsTypes';
import { useLog } from 'utils/userLog';
import ClipPopupModal from 'views/clips/ClipsPopup/ClipPopupModal';
import TestingGameClipsConainer from 'views/clips/TestingGameClipsContainer';
import NoVideoClip from './NoVideoClip';

interface IProps {
  isLoading?: boolean;
  dataList?: TestingGameVideoCollectionType[];
  count?: number;
}

function TestingGameClips({ isLoading, dataList, count = 4 }: IProps) {
  const { gxcTag } = useLog();
  const skeletonList = useMemo(() => Array.from({ length: count }, (_, i) => i), [count]);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryGameId = searchParams.get('gameId') || '';
  const cardType = searchParams.get('type');
  const [gameId, setGameId] = useState(Number(queryGameId));
  const [showModal, setShowModal] = useState(false);

  const openModal = (gameId: number) => {
    setGameId(gameId);

    gxcTag('view', {
      area: 'ongoing_clips_popup',
      parameters: { game_id: gameId },
    });
    setSearchParams({ type: 'testing', gameId: String(gameId) });
    setShowModal(true);
  };

  const closeModal = () => {
    gxcTag('view', {
      area: 'ongoing_clips_popup',
      ui: 'close_x',
      parameters: { game_id: gameId },
    });
    setSearchParams({});
    setShowModal(false);
  };

  const isOpen = showModal || !!(String(gameId) && cardType === 'testing');

  return (
    <SectionContainer
      title="Ongoing Games' Clips"
      containerOptions={
        <ContainerLink
          linkText="See All Clips"
          linkUrl="allclip"
          onClick={() =>
            gxcTag('click', {
              area: 'ongoing',
              ui: 'all_clips',
            })
          }
        />
      }
    >
      {isOpen ? (
        <ClipPopupModal
          width="800px"
          height="772px"
          open={isOpen}
          onClose={closeModal}
          body={<TestingGameClipsConainer gameId={Number(gameId)} />}
          disableEscapeKeyDown
          hasScrollBar
        />
      ) : null}

      {isLoading ? (
        <Grid container>
          {skeletonList.map((item) => (
            <Grid key={item} item xs={3}>
              <ClipCardSmallSkeleton />
            </Grid>
          ))}
        </Grid>
      ) : Array.isArray(dataList) && dataList.length > 0 ? (
        <Grid container rowGap="60px" columnGap="20px">
          {dataList.map((element) => (
            <Grid key={element.game_id} item>
              <ClipCardSmall
                studioName={element.studio_name}
                gameTitle={element.title}
                srcUrl={element.thumbnail_url}
                numberOfVideos={element.clip_count}
                gameId={element.game_id}
                onClickClipButton={() => {
                  gxcTag('click', {
                    area: 'ongoing',
                    ui: 'clips_card',
                    parameters: { game_id: element.game_id },
                  });
                  openModal(element.game_id);
                }}
                onClickNavLink={() => {
                  gxcTag('click', {
                    area: 'ongoing',
                    ui: 'game_page',
                    parameters: { game_id: gameId },
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoVideoClip />
      )}
    </SectionContainer>
  );
}

export default TestingGameClips;
