import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import { AddressDialog } from 'components/dialog/AddressDialog';
import eventTracker from 'utils/eventTracker';
import { SectionBox } from '../SectionBox';
import { useGStoreOrderDetailData } from './GStoreOrderDetailProvider';

export default function GStoreOrderDetailShippingInfoBox() {
  const { data, orderId, mutate } = useGStoreOrderDetailData();
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const shippingData = data?.data?.shipping;
  const orderStatus = data?.data?.status;
  const isDigitalGame = data?.data.type === 'DIGITAL_GAMES';
  const theme = useTheme();

  if (isDigitalGame) {
    return (
      <Box>
        <Stack alignItems="flex-end" direction="row" justifyContent="space-between" mb="20px">
          <Typography variant="h5">Shipping Information</Typography>
        </Stack>
        <Stack
          columnGap="40px"
          direction="row"
          divider={<Divider flexItem orientation="vertical" />}
          sx={{
            background: theme.palette.gray[90],
            borderRadius: '5px',
            padding: '40px',
          }}
        >
          <Stack sx={{ flex: 1, rowGap: '40px' }}>
            <SectionBox title="Email" content={data?.data?.order_user_email} />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between" mb="20px">
        <Typography variant="h5">Shipping Information</Typography>
        {(orderStatus === 'ORDERED' || orderStatus === 'CONFIRMED') && (
          <Button
            onClick={() => {
              eventTracker('profile_order_detail_editshipinfo', { event_label: orderId ?? '' });
              setAddressDialogOpen(true);
            }}
            variant="ghost"
            size="small"
          >
            Edit Address
          </Button>
        )}
        {addressDialogOpen ? (
          <AddressDialog
            open={addressDialogOpen}
            title="Edit Shipping Information"
            primaryButton={{ text: 'Update' }}
            secondaryButton={{ text: 'Cancel' }}
            onClose={() => {
              setAddressDialogOpen(false);
            }}
            shippingData={shippingData}
            orderId={orderId}
            saleProductId={data?.data.product.sale_product_id}
            refetch={mutate}
          />
        ) : null}
      </Stack>
      <Stack
        columnGap="40px"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        sx={{
          background: theme.palette.gray[90],
          borderRadius: '5px',
          padding: '40px',
        }}
      >
        <Stack sx={{ flex: 1, rowGap: '40px' }}>
          <SectionBox title="Recipient’s Name" content={shippingData?.name} hasNoFullHeight />
          <SectionBox title="Email" content={shippingData?.email} hasNoFullHeight />
          <SectionBox title="Phone" content={shippingData?.phone} hasNoFullHeight />
        </Stack>

        <Stack sx={{ flex: 1, rowGap: '40px' }}>
          <SectionBox title="Country" content={shippingData?.country_name} hasNoFullHeight />
          {shippingData?.id_card_item && (
            <SectionBox
              title={shippingData?.id_card_title ?? ''}
              content={shippingData?.id_card_no ?? ''}
              hasNoFullHeight
            />
          )}
          <SectionBox title="Province/State" content={shippingData?.state} hasNoFullHeight />

          {!shippingData?.id_card_item && (
            <SectionBox title="City" content={shippingData?.city} hasNoFullHeight />
          )}
        </Stack>

        <Stack sx={{ flex: 1, rowGap: '40px' }}>
          {shippingData?.id_card_item && (
            <SectionBox title="City" content={shippingData?.city} hasNoFullHeight />
          )}
          <SectionBox
            title="Address Line"
            content={shippingData?.address1 + ' ' + shippingData?.address2}
            hasNoFullHeight
          />
          <SectionBox title="Zip code" content={shippingData?.zipcode} hasNoFullHeight />
        </Stack>
      </Stack>
    </Box>
  );
}
