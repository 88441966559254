import { FormAutocomplete, FormAutocompleteOption } from '@ground/ui';
import { Box, Typography } from '@mui/material';

interface Props {
  countryList: FormAutocompleteOption[];
  disabled: boolean;
}

export default function PersonalDetailsCountry({ countryList, disabled }: Props) {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="subtitle2" color="text.secondary" sx={{ marginBottom: '8px' }}>
          Country
        </Typography>
      </Box>
      <FormAutocomplete
        name="country"
        placeholder="Select your country"
        options={countryList}
        readOnly={disabled}
        withBackground
        rules={{ required: true }}
        size="large"
      />
      {!disabled && (
        <Typography variant="body3" color="text.secondary2" mt="8px">
          *Your country cannot be changed after you submit.
        </Typography>
      )}
    </div>
  );
}
