import { LoadingWrapper } from '@ground/ui';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { usePublicGameMyReviewListV2API } from 'api/comment/commentAPI';
import { NoData } from 'elements';
import MyReviewPublicContainer from './MyReviewPublicContainer';

export default function MyCommentsMain() {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading, mutate } = usePublicGameMyReviewListV2API(id);
  const reviewList = data?.data;
  const hasReviewList = reviewList && Array.isArray(reviewList) && reviewList.length > 0;

  return (
    <Box>
      <Typography variant="body1" color="text.secondary2" my="28px">
        The 'Reply' function on your review is for communication directly with the game studio.
        <br />
        You'll receive notifications if a studio member comments on your review. Feel free to
        discuss your review further or <br />
        share additional thoughts about the game with the studio.
      </Typography>
      <Divider sx={{ marginBottom: '40px' }} />
      <LoadingWrapper isLoading={isLoading} size={64}>
        {hasReviewList ? (
          <Stack gap="20px" px="60px">
            {reviewList.map((review) => (
              <MyReviewPublicContainer
                key={review.id}
                reviewData={review}
                mutateReviewList={mutate}
              />
            ))}
          </Stack>
        ) : (
          <Box sx={{ height: 474 }}>
            <NoData title="Leave a review!" subTitle="We're eager to hear your thoughts." />
          </Box>
        )}
      </LoadingWrapper>
    </Box>
  );
}
