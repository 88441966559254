import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Divider, ListItemButton, ListItemText, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { iRootState } from 'store';
import { SurveyAnswerFormData, SurveyQuestionListType } from 'types/FormDataType';
import SurveyContent from './SurveyContent';
import { useSurveyData } from './SurveyProvider';

interface SurveyListItemProps {
  defaultOpen: boolean;
  index: number;
  surveyId: number;
  surveyPartId: number;
}

export default function SurveyListItem({
  defaultOpen,
  index,
  surveyId,
  survey_questions,
  started_at,
  state,
  part,
  surveyPartId,
}: SurveyListItemProps & SurveyQuestionListType) {
  const [open, setOpen] = useState(defaultOpen);
  const { answerList } = useSurveyData();
  const { identifier } = useSelector((state: iRootState) => state.login);
  const { id = '' } = useParams<{ id: string }>();
  const surveyDraftData = useMemo(
    () => localStorage.getItem(`draft-${identifier}-${id}-survey-${surveyId}-${surveyPartId}`),
    [id, identifier, surveyPartId, surveyId]
  );

  const handleClick = () => {
    setOpen((value) => !value);
  };

  // 서베이 미작성 여부 체크(답변이 하나라도 안되어 있다면 unfinished)
  const unfinished = survey_questions?.some(
    (question) =>
      !(
        answerList &&
        Object.entries(answerList).find(
          // 답변 리스트에서 현재 질문에 해당되는 답변이 있는지 찾는다.
          ([questionId, answerValue]) => Number(questionId) === question.id && answerValue
        )
      )
  );

  // 이미 등록한 서베이 답변이 있으면 폼 초기값으로 만들어준다.
  const defaultValues = survey_questions?.reduce<SurveyAnswerFormData>(
    (defaultAnswers, question) => {
      // 저장된 데이터 조건
      const isDraftAnswerExist =
        open &&
        surveyDraftData &&
        Array.isArray(JSON.parse(surveyDraftData)) &&
        JSON.parse(surveyDraftData).length > 1;

      // 임시저장된 데이터가 있으면 그것을 불러온다.
      if (isDraftAnswerExist) {
        return JSON.parse(surveyDraftData)[0];
      }

      // 해당 질문의 답변이 있는지 찾는다.
      const answer = answerList?.[question.id]?.toString();

      // 답변이 있으면 기본 값으로 넣어준다.
      if (answer) {
        // 질문 타입이 다중 선택형인 경우 boolean[] 형태로 값을 만든다.
        const questionChoiceAnswer =
          question.question_type === 'CHOICE' && question.selection_max_amount > 1
            ? // 다중 선택된 값들만 true로 넣어서 배열을 반환
              answer.split('').reduce<boolean[]>((duplicateSelections, selection) => {
                duplicateSelections[Number(selection)] = true;
                return duplicateSelections;
              }, [])
            : answer;
        // 초기값 등록

        defaultAnswers[`${question.question_type.toLowerCase()}-${question.id}`] =
          questionChoiceAnswer;
      }

      return defaultAnswers;
    },
    {}
  );

  return (
    <div>
      <Divider />
      <ListItemButton
        disabled={state === 'UPCOMING'}
        disableGutters
        onClick={handleClick}
        sx={{ color: open ? 'text.primary' : 'text.secondary2' }}
      >
        <ListItemText
          disableTypography
          primary={
            <Box display="flex" alignItems="center">
              <Typography variant="h5">SURVEY {index + 1}</Typography>
              <Typography variant="subtitle3" mx="20px">
                {format(new Date(started_at), 'MMMM dd ~')}
              </Typography>
              {survey_questions &&
                (unfinished ? (
                  <Typography
                    component="span"
                    color="base.white"
                    variant="small2"
                    sx={{
                      padding: '3px 8px',
                      borderRadius: '3px',
                      backgroundColor: 'primary.main',
                    }}
                  >
                    Unfinished
                  </Typography>
                ) : (
                  <CheckCircleIcon sx={{ fontSize: '20px', color: 'text.secondary2' }} />
                ))}
            </Box>
          }
          sx={{ marginY: '8px' }}
        />
        {open ? <ExpandLess sx={{ fontSize: '40px' }} /> : <ExpandMore sx={{ fontSize: '40px' }} />}
      </ListItemButton>
      {survey_questions && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <SurveyContent
            finished={!unfinished}
            questions={survey_questions}
            defaultValues={defaultValues}
            surveyId={surveyId}
            part={part}
            surveyPartId={surveyPartId}
          />
        </Collapse>
      )}
    </div>
  );
}
