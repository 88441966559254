import { Avatar, Box, styled, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import { usePopularClipsAPI } from 'api/displayContent/displayContentAPI';
import { ImageBox } from 'atoms';
import { useMobileVideoDialog } from 'hooks/mobile';
import { useLog } from 'utils/userLog';
import { getClockTime } from '../Common';
import VideoClipSkeleton from './VideoClipSkeleton';

import playIcon from 'images/icons/ico_play.svg';
import streamer1 from 'images/mobile/streamer/streamer1.png';
import streamer10 from 'images/mobile/streamer/streamer10.png';
import streamer11 from 'images/mobile/streamer/streamer11.png';
import streamer12 from 'images/mobile/streamer/streamer12.png';
import streamer2 from 'images/mobile/streamer/streamer2.png';
import streamer3 from 'images/mobile/streamer/streamer3.png';
import streamer4 from 'images/mobile/streamer/streamer4.png';
import streamer5 from 'images/mobile/streamer/streamer5.png';
import streamer6 from 'images/mobile/streamer/streamer6.png';
import streamer7 from 'images/mobile/streamer/streamer7.png';
import streamer8 from 'images/mobile/streamer/streamer8.png';
import streamer9 from 'images/mobile/streamer/streamer9.png';

const VideoClipContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: 'auto',
  backgroundColor: theme.palette.blue.pointBG,
  paddingTop: 60,
  paddingBottom: 60,
  overflow: 'hidden',
}));

const StreamerAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

const DurationChip = styled('div')(() => ({
  position: 'absolute',
  right: 8,
  bottom: 8,
  padding: '4px 8px',
  borderRadius: 5,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

const VideoClip: React.FC = () => {
  const { gxcTag } = useLog();
  const { showVideoDialog } = useMobileVideoDialog();
  const { isLoading, data } = usePopularClipsAPI();
  const loadingSkeletonList = Array.from({ length: 8 }, (_, i) => i);

  const dataList = data?.data ?? [];

  const handleClickClipBox = (link: string, videoId: number) => {
    gxcTag('click', { area: 'video_clips', ui: 'clips_card', parameters: { video_id: videoId } });

    showVideoDialog(link, {
      area: 'video_clips_popup',
      parameters: {
        video_id: videoId,
      },
    });
  };

  return (
    <VideoClipContainer>
      <Box sx={{ padding: '0px 20px', marginBottom: 5 }}>
        <Typography variant="h4" mb="8px">
          Video Clips
        </Typography>
        <Typography variant="subtitle2">
          Enjoy real gameplay clips from our partner streamers!
        </Typography>
      </Box>

      <Box px={2.5}>
        <Box
          sx={{
            width: 280,
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: 1,
            rowGap: 1.5,
          }}
        >
          <StreamerAvatar src={streamer1} />
          <StreamerAvatar src={streamer2} />
          <StreamerAvatar src={streamer3} />
          <StreamerAvatar src={streamer4} />
          <StreamerAvatar src={streamer5} />
          <StreamerAvatar src={streamer6} />
          <StreamerAvatar src={streamer7} />
          <StreamerAvatar src={streamer8} />
          <StreamerAvatar src={streamer9} />
          <StreamerAvatar src={streamer10} />
          <StreamerAvatar src={streamer11} />
          <StreamerAvatar src={streamer12} />
        </Box>
      </Box>

      <Swiper
        spaceBetween={16}
        style={{
          marginTop: 40,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        slidesPerView="auto"
      >
        {isLoading
          ? loadingSkeletonList.map((data) => (
              <SwiperSlide key={data} style={{ width: 240, flexBasis: 240 }}>
                <VideoClipSkeleton />
              </SwiperSlide>
            ))
          : dataList.map((data) => (
              <SwiperSlide key={data.video_id} style={{ width: 240, flexBasis: 240 }}>
                {/* video slide upper area */}
                <Box
                  position="relative"
                  onClick={() => handleClickClipBox(data.link, data.video_id)}
                >
                  {/* thumbnail image */}
                  <ImageBox
                    src={data.thumbnail_url}
                    alt="thumbnail"
                    resize
                    resizeWidth={240}
                    resizeHeight={136}
                    format="webp"
                    overlay
                    sx={{ objectFit: 'cover', borderRadius: '5px' }}
                  />
                  {/* play icon */}
                  <img
                    src={playIcon}
                    alt="play icon"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                  {/* video duration time */}
                  {data.duration && (
                    <DurationChip>
                      <Typography variant="small2">{getClockTime(data.duration)}</Typography>
                    </DurationChip>
                  )}
                </Box>
                {/* video title */}
                <Typography variant="body2" mt="12px">
                  {data.video_title}
                </Typography>
              </SwiperSlide>
            ))}
      </Swiper>
    </VideoClipContainer>
  );
};

export default VideoClip;
