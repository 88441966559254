import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, Divider, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGamePlayTimeInfoAPI } from 'api/detail/detailAPI';
import { SimilarityStatus, useGetReviewSurveyState } from 'api/review/reviewAPI';
import { useDialog, useValidate } from 'hooks';
import { iRootState } from 'store';
import PrivateThemeProvider from 'theme/PrivateThemeProvider';
import { GameStatusType } from 'types/GameCommonTypes';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';
import { useGetDetailData } from 'views/detail/DetailProvider';
import { useImportDraftData } from 'views/detail/ImportDraftProvider';
import PlayGameDialogBody from 'views/detail/dialog/PlayGameDialogBody';

// 링크 공통 일단 여기.. 비슷하게 쓰이는 곳이많아서 추후에 공통으로 만들어야 될것같다.
function SupportLink() {
  return (
    <Link
      href="mailto:support@gameround.co"
      target="_blank"
      rel="noopener noreferrer"
      color="text.secondary2"
    >
      support@gameround.co
    </Link>
  );
}

export default function DetailPlayButtonArea() {
  const { id = '' } = useParams<{ id: string }>();
  const { showDialog } = useDialog();
  const { commonValidate } = useValidate();
  const { gxcTag } = useLog();

  const { identifier } = useSelector((state: iRootState) => state.login);
  const { setShouldImportReviewDraftData, setShouldImportSurveyDraftData } = useImportDraftData();

  const { mutate: playTimeReload } = useGamePlayTimeInfoAPI(id);

  const {
    data,
    reload,
    setOpenReview,
    setOpenSurvey,
    launcherUserSummaryLoading,
    launcherUserSummary,
  } = useGetDetailData();

  const {
    isValidating: isReviewSurveyStatusLoading,
    data: reviewSurveyStatus,
    mutate: refetchStatus,
  } = useGetReviewSurveyState(id);
  const reviewState = reviewSurveyStatus?.data.review_status;
  const surveyState = reviewSurveyStatus?.data.global_survey_status;

  const isEditReview = reviewState?.complete;
  const isEditSurvey = surveyState?.complete;

  // G.Round 런처(GENERAL) - 런처 플레이 정보에서 플레이 횟수가 0이면 disabled
  // 그 외 런처 - download 기록이 없으면 disabled
  const disabledReview = !launcherUserSummary?.data?.play_count && !data?.download;
  const disabledSurvey = disabledReview || !isEditReview;

  const isPrivate = typeof data?.private_test === 'number';

  const CommonButtonProps: ButtonProps = {
    color: isPrivate ? 'private' : 'primary',
  };

  const showPlayGameDialog = () => {
    if (commonValidate()) {
      setTimeout(() => {
        showDialog({
          title: 'It’s time to play!',
          body: (
            <PrivateThemeProvider isPrivate={isPrivate}>
              <PlayGameDialogBody id={Number(id)} data={data} reload={reload} />
            </PrivateThemeProvider>
          ),
          width: 500,
        });
      }, 100);
      eventTracker('gamedetail_upper_play_game', { event_label: id });
      twttr?.conversion.trackPid('o91di', { tw_sale_amount: 0, tw_order_quantity: 0 });
      gtag('event', 'conversion', { send_to: 'AW-669277837/JrNJCNedsMQDEI29kb8C' });
    }
  };

  const handleClickPlayBtn = () => {
    gxcTag('click', { area: 'detail', ui: 'play_now' });
    showPlayGameDialog();
  };

  const handleClickReviewBtn = async () => {
    gxcTag('click', {
      area: 'detail',
      ui: 'write_a_review',
      parameters: {
        downloaded: data?.download,
        play_count: launcherUserSummary?.data?.play_count,
        total_play_time: launcherUserSummary?.data?.total_play_time,
        total_active_play_time: launcherUserSummary?.data?.total_active_play_time,
      },
    });

    if (!commonValidate()) return;

    const playTimeInfo = await playTimeReload();
    // 플레이 타임 정보를 못 불러온 경우
    if (playTimeInfo?.result?.code !== 0) {
      showDialog({
        title: 'Unknown Error',
        body: 'An unknown error has occurred. Please check your network connection and try again later.',
        primaryButton: {
          ButtonProps: CommonButtonProps,
          text: 'OK',
        },
      });

      return;
    }

    // 최소 요구 플레이 타임 0이면 체크 안함
    const minPlay = playTimeInfo.data.min_play ?? 0;
    // total_active_play_time 실제 게임한시간
    // total_play_time 실제 게임한시간 + 활동 안하고 게임을 켜두시간
    const playTime = playTimeInfo.data.total_active_play_time ?? 0;

    const verified = playTimeInfo.data.verified;

    // 플레이 시도도 하지 않은 경우
    if (verified === false && disabledReview) {
      showDialog({
        title: 'Almost there...',
        body: (
          <>
            <Typography variant="body2" color="text.secondary">
              You are almost ready to review, but first you need to play the game! Once you play the
              game you'll be able to write a review.
            </Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Typography variant="small2" color="text.secondary2">
              The game must be closed properly before users can write a review. Force quitting a
              game will not be counted as a gameplay.
            </Typography>
          </>
        ),
        primaryButton: {
          ButtonProps: CommonButtonProps,
          text: 'Play Now',
          callback: () => {
            gxcTag('click', { area: 'prevent_review_no_gameplay_popup', ui: 'play_now' });
            showPlayGameDialog();
          },
        },
      });

      return;
    }

    // 리뷰 불가 경우 case1 플레이타임 0, undefined, null인 경우
    if (verified === false && minPlay > 0 && !playTime) {
      showDialog({
        title: 'Almost there...',
        body: (
          <>
            <Typography variant="body2" color="text.secondary">
              You are almost ready to review, but first you need to play the game for at{' '}
              <Typography variant="body2" color="text.primary" component="span">
                least {minPlay} minutes!{' '}
              </Typography>
              Once you play the game you'll be able to write a review.
            </Typography>

            <Divider sx={{ margin: '20px 0' }} />
            <Typography variant="small2" color="text.secondary2">
              The game must be played and closed properly before users can write a review. Force
              quitting a game or booting up the game and not playing will not be counted. For any
              inquiries please contact <SupportLink />
            </Typography>
          </>
        ),
        primaryButton: {
          ButtonProps: CommonButtonProps,
          text: 'Play Now',
          callback: () => {
            gxcTag('click', { area: 'prevent_review_no_playtime_popup', ui: 'play_now' });
            showPlayGameDialog();
          },
        },
      });

      return;
    }

    // 플레이타임 미달인 경우
    if (verified === false && minPlay > 0 && playTime < minPlay * 60) {
      showDialog({
        title: 'Almost there...',
        body: (
          <>
            <Typography variant="body2" color="text.secondary">
              You are almost ready to review, but first you need to play for{' '}
              <Typography variant="body2" color="text.primary" component="span">
                {minPlay - Math.floor(playTime / 60)} more minutes!
              </Typography>{' '}
              Once you play the game for at{' '}
              <Typography variant="body2" color="text.primary" component="span">
                least {minPlay} minutes,{' '}
              </Typography>
              you'll be able to write a review.
            </Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Typography variant="small2" color="text.secondary2">
              The game must be played and closed properly before users can write a review. Force
              quitting a game or booting up the game and not playing will not be counted. For any
              inquiries please contact <SupportLink />
            </Typography>
          </>
        ),
        primaryButton: {
          ButtonProps: CommonButtonProps,
          text: 'Play Now',
          callback: () => {
            gxcTag('click', { area: 'prevent_review_playtime_popup', ui: 'play_now' });
            showPlayGameDialog();
          },
        },
      });

      return;
    }

    const statusResult = await refetchStatus();

    if (statusResult?.data?.review_status?.similarity_status === SimilarityStatus.WAITING) {
      showDialog({
        title: 'Editing Disabled',
        body: (
          <Typography variant="body2" color="text.secondary">
            This review is currently under inspection. You will be able to make edits once the
            inspection is complete. <br /> (The inspection process typically takes about 10 minutes.
            )
          </Typography>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    // 리뷰 임시저장 값
    const reviewDraftData = localStorage.getItem(`draft-${identifier}-${id}-review`);

    // 저장된 값이 있는지 유무
    reviewDraftData
      ? showDialog({
          title: 'Confirmation',
          body: `Do you want to continue your previous ${
            isEditReview ? 'edit' : 'draft'
          } or start a new one?`,
          primaryButton: {
            ButtonProps: CommonButtonProps,
            text: 'Use saved draft',
            callback: () => {
              setShouldImportReviewDraftData(true);
              setOpenReview(true);
            },
          },
          secondaryButton: {
            text: isEditReview ? 'Start a new edit' : 'Create New',
            callback: () => {
              localStorage.removeItem(`draft-${identifier}-${id}-review`);
              setShouldImportReviewDraftData(false);
              setOpenReview(true);
            },
          },
        })
      : setOpenReview(true);

    eventTracker('gamedetail_upper_review', { event_label: id });

    twttr?.conversion.trackPid('o91dt', {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    gtag('event', 'conversion', { send_to: 'AW-669277837/B55mCOCdsMQDEI29kb8C' });
  };

  // survey 버튼 클릭 시
  const handleClickSurveyBtn = async () => {
    gxcTag('click', {
      area: 'detail',
      ui: 'write_a_survey',
      parameters: {
        downloaded: data?.download,
        play_count: launcherUserSummary?.data?.play_count,
        total_play_time: launcherUserSummary?.data?.total_play_time,
        total_active_play_time: launcherUserSummary?.data?.total_active_play_time,
      },
    });

    if (!commonValidate()) return;

    // 서베이 불가 모달
    if (disabledSurvey) {
      showDialog({
        title: 'You might have forgotten something...',
        body: 'Remember to write and submit your review  before filling the survey.',
        secondaryButton: { text: 'Close' },
      });

      return;
    }

    await refetchStatus();

    if (surveyState?.similarity_status === SimilarityStatus.WAITING) {
      showDialog({
        title: 'Editing Disabled',
        body: (
          <Typography variant="body2" color="text.secondary">
            This survey is currently under inspection. You will be able to make edits once the
            inspection is complete. <br /> (The inspection process typically takes about 10 minutes.
            )
          </Typography>
        ),
        secondaryButton: { text: 'Close' },
      });
      return;
    }

    const hasSurveyDraftData = Object.keys({ ...localStorage }).find((key) =>
      key.includes(`draft-${identifier}-${id}-survey`)
    );

    // 서베이 리스트
    const surveyDraftDataKeyList = Object.keys({ ...localStorage }).filter((key) =>
      key.includes(`draft-${identifier}-${id}-survey`)
    );

    // 저장된 값이 있는지 유무
    hasSurveyDraftData
      ? showDialog({
          title: 'Confirmation',
          body: `Do you want to continue your previous ${
            isEditSurvey ? 'edit' : 'draft'
          } or start a new one?`,

          primaryButton: {
            ButtonProps: CommonButtonProps,
            text: 'Use saved draft',
            callback: () => {
              setShouldImportSurveyDraftData(true);
              setOpenSurvey(true);
            },
          },
          secondaryButton: {
            text: isEditSurvey ? 'Start a new edit' : 'Create New',
            callback: () => {
              surveyDraftDataKeyList.forEach((key) => {
                localStorage.removeItem(key);
              });
              setShouldImportSurveyDraftData(false);
              setOpenSurvey(true);
            },
          },
        })
      : setOpenSurvey(true);

    eventTracker('gamedetail_upper_survey', { event_label: id });

    twttr?.conversion.trackPid('o91ea', {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    gtag('event', 'conversion', { send_to: 'AW-669277837/B55mCOCdsMQDEI29kb8C' });
  };

  return (
    <Box marginTop="20px">
      <Button
        disabled={
          data?.state !== GameStatusType.TESTING &&
          data?.state !== GameStatusType.SURVEY &&
          data?.survey_type !== 'GLOBAL'
        }
        fullWidth
        onClick={handleClickPlayBtn}
        size="large"
        variant="contained"
      >
        Play Now
      </Button>

      <Box display="flex" marginTop="16px" columnGap="8px">
        <LoadingButton
          loading={launcherUserSummaryLoading || isReviewSurveyStatusLoading}
          // pick 된 리뷰는 수정 불가
          disabled={
            data?.is_pick === true ||
            data?.state !== GameStatusType.TESTING ||
            !reviewState?.available
          }
          fullWidth
          onClick={handleClickReviewBtn}
          variant="ghost"
        >
          {isEditReview ? 'Edit Review' : 'Write a Review'}
        </LoadingButton>
        <LoadingButton
          loading={isReviewSurveyStatusLoading}
          disabled={
            (data?.state !== GameStatusType.SURVEY && !data?.survey_type) || !surveyState?.available
          }
          fullWidth
          onClick={handleClickSurveyBtn}
          variant="ghost"
        >
          {isEditSurvey ? 'Edit Survey' : 'Write a Survey'}
        </LoadingButton>
      </Box>
    </Box>
  );
}
