import type {} from '@mui/lab/themeAugmentation';
import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles';

import components from './components';
import palette from './palette';
import shape from './shape';
import typography from './typography';

// Color 추가 정의
interface BaseColors {
  dimWhite: string;
  white: string;
  black: string;
}

interface PrivateColor {
  primary: string;
  hover: string;
}
interface BlueColors {
  primary: string;
  hover: string;
  pointBG: string;
}

interface GrayColors {
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  100: string;
}

type OpacityColor = { 5: string; 10: string; 15: string; 20: string };

interface OpacityColors {
  black: OpacityColor;
  white: OpacityColor;
}

// 추가한 스타일 정의
declare module '@mui/material/styles' {
  interface TypeText {
    primary: string;
    secondary: string;
    secondary2: string;
    disabled: string;
    private: string;
  }
  interface Palette {
    base: BaseColors;
    blue: BlueColors;
    gray: GrayColors;
    opacity: OpacityColors;
    light: PaletteColor;
    dark: PaletteColor;
    private: PrivateColor;
  }

  interface TypographyVariants {
    title: React.CSSProperties;
    subtitle3: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    button3: React.CSSProperties;
    body3: React.CSSProperties;
    small1: React.CSSProperties;
    small2: React.CSSProperties;
    menu: React.CSSProperties;
    review: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    base: Partial<BaseColors>;
    blue: Partial<BlueColors>;
    gray: Partial<GrayColors>;
    opacity: Partial<OpacityColors>;
    light: PaletteColorOptions;
    dark: PaletteColorOptions;
    private: Partial<PrivateColor>;
  }
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    button3?: React.CSSProperties;
    body3?: React.CSSProperties;
    small1?: React.CSSProperties;
    small2?: React.CSSProperties;
    menu?: React.CSSProperties;
    review?: React.CSSProperties;
  }

  interface ShapeOptions {
    borderRadius?: number;
  }
}

// Typography 재정의
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    subtitle3: true;
    button1: true;
    button2: true;
    button3: true;
    body3: true;
    small1: true;
    small2: true;
    menu: true;
    review: true;
  }
}

// Button 재정의
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
    private: true;
  }

  interface ButtonPropsVariantOverrides {
    ghost: true;
  }

  interface ButtonPropsSizeOverrides {
    tiny: true;
    largest: true;
  }
}

// InputBase 재정의
declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

// TextField 재정의
declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

// create G.Round theme
export function createGroundTheme(customTypography = typography) {
  return createTheme({ components, palette, shape, typography: customTypography });
}
