import {
  Box,
  createSvgIcon,
  inputBaseClasses,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { forwardRef, MouseEventHandler, Ref, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import InputFieldBase, { InputFieldBaseProps } from '../InputFieldBase';

// import 'simplebar/dist/simplebar.min.css';

const ArrowDropDown = createSvgIcon(
  <path d="M4.5 4L3.49691e-07 -7.86805e-07L9 0L4.5 4Z" fill="#555555" />,
  'ArrowDropDown'
);

interface DropdownRootStyles {
  disabled: boolean;
  width?: number | string;
  maxWidth?: number | string;
}

const DropdownRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'maxWidth',
})<DropdownRootStyles>(({ theme, disabled, width, maxWidth }) => ({
  borderRadius: '3px',
  backgroundColor: theme.palette.gray[80],
  ...(disabled && {
    opacity: 0.3,
    pointerEvents: 'none',
  }),
  width: width,
  maxWidth: maxWidth,
}));

interface StyledInputProps extends InputFieldBaseProps {
  /** option list open state */
  open: boolean;
  /** readOnly or disabled 상태일 때 enabled=false */
  enabled: boolean;
}

const StyledInputField = styled(InputFieldBase, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'enabled',
})<StyledInputProps>(({ open, enabled, theme, withBackground }) => ({
  cursor: enabled ? 'pointer' : 'default',
  userSelect: 'none',
  ...(open && {
    borderColor: theme.palette.gray[withBackground ? 50 : 60],
  }),
  [`& .${inputBaseClasses.input}`]: {
    pointerEvents: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface ScrollbarStyles {
  withBackground?: boolean;
}

const Scrollbar = styled(SimpleBar, {
  shouldForwardProp: (prop) => prop !== 'withBackground',
})<ScrollbarStyles>(({ theme, withBackground }) => ({
  '& .simplebar-track.simplebar-vertical': {
    width: 12,
    backgroundColor: theme.palette.gray[withBackground ? 60 : 80],

    '& .simplebar-scrollbar:before': {
      left: 3,
      right: 3,
      top: 10,
      bottom: 10,
      borderRadius: '10px',
      backgroundColor: theme.palette.gray[40],
    },

    '& .simplebar-scrollbar.simplebar-visible:before': {
      opacity: 1,
    },
  },
}));

interface OptionListItemStyles {
  withBackground?: boolean;
}

const OptionListItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'withBackground',
})<OptionListItemStyles>(({ theme, withBackground }) => ({
  color: theme.palette.text.secondary,
  padding: '6px 12px',
  '&:hover': {
    color: theme.palette.base.dimWhite,
    backgroundColor: theme.palette.gray[withBackground ? 50 : 60],
  },
  '&.Mui-selected': {
    color: theme.palette.base.dimWhite,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.gray[withBackground ? 50 : 60],
    },
  },
}));

export type DropdownOptionValue = number | string;

export interface DropdownOption {
  name: string;
  value: DropdownOptionValue;
  disabled?: boolean;
}

export interface DropdownProps extends Omit<InputFieldBaseProps, 'ref'> {
  /** select width */
  width?: number | string;
  /** select max width */
  maxWidth?: number | string;
  /** option list max height */
  maxHeight?: number | string;
  /** Dropdown에서 사용할 option 리스트 */
  options: DropdownOption[];
  /** 선택한 옵션이 변경될 때 호출되는 핸들러 */
  onChangeValue?: (option: DropdownOptionValue) => void;
}

const Dropdown = forwardRef((inProps: DropdownProps, ref: Ref<HTMLDivElement>) => {
  const {
    // DropdownProps
    width,
    maxWidth,
    maxHeight = 200,
    options,
    onChangeValue,
    onClick,
    // InputFieldBaseProps
    disabled = false,
    readOnly = false,
    value,
    withBackground,
    size = 'medium',
    ...other
  } = inProps;
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [selectOption, setSelectOption] = useState<DropdownOption | undefined>(
    value
      ? options.find(
          (option) => option.value?.toString() === (value as DropdownOptionValue)?.toString()
        )
      : undefined
  );
  const [open, setOpen] = useState<boolean>(false);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    onClick?.(event);
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickOption = (option: DropdownOption) => () => {
    setOpen(false);

    if (selectOption?.value !== option.value) {
      onChangeValue && onChangeValue(option.value);
      setSelectOption(option);
    }
  };

  const ArrowDropDownIcon = useMemo(
    () => (
      <Box display="flex" alignItems="center">
        <ArrowDropDown
          sx={{
            width: 9,
            height: 4,
            transition: 'transform 300ms',
            ...(open && { transform: 'rotate(180deg)' }),
          }}
          viewBox="0 0 9 4"
        />
      </Box>
    ),
    [open]
  );

  const enabled = useMemo(() => !(disabled || readOnly), [disabled, readOnly]);

  return (
    <DropdownRoot disabled={disabled} maxWidth={maxWidth} width={width} ref={ref}>
      <StyledInputField
        ref={setAnchorEl}
        disabled={disabled}
        readOnly={readOnly}
        onClick={enabled ? handleClick : undefined}
        endAdornment={ArrowDropDownIcon}
        fullWidth
        open={open}
        enabled={enabled}
        value={selectOption?.name ?? ''}
        withBackground={withBackground}
        inputProps={{ readOnly: true }}
        size={size}
        {...other}
      />
      {/* option list */}
      {enabled && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: (theme) => ({
              backgroundColor: theme.palette.gray[withBackground ? 60 : 80],
              border: `1px solid ${theme.palette.gray[withBackground ? 50 : 60]}`,
              borderRadius: '3px',
              marginTop: '4px',
              width: anchorEl?.offsetWidth,
            }),
          }}
          MenuListProps={{ disablePadding: true }}
        >
          <Scrollbar
            withBackground={withBackground}
            autoHide={false}
            style={{ maxHeight: maxHeight, padding: '10px 4px' }}
          >
            {options.map((option) => (
              <OptionListItem
                key={option.value}
                onClick={handleClickOption(option)}
                selected={selectOption && selectOption.value === option.value}
                withBackground={withBackground}
                disabled={option.disabled}
              >
                <Typography
                  variant={size === 'medium' ? 'body2' : size === 'small' ? 'button2' : 'body1'}
                  noWrap
                >
                  {option.name}
                </Typography>
              </OptionListItem>
            ))}
          </Scrollbar>
        </Menu>
      )}
    </DropdownRoot>
  );
});

export default Dropdown;
