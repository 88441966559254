import { useEffect, useMemo } from 'react';

import AuthFacebookMe from './AuthFacebookMe';

// 정상
// https://gameround.co/launcher/oauth/facebook/?#access_token={access_token}&data_access_expiration_time=1660564584&expires_in=3816&long_lived_token={token}&granted_scopes=public_profile,email&denied_scopes=&state={state}
// email 권한 deny
// https://gameround.co/launcher/oauth/facebook/?#access_token={access_token}&data_access_expiration_time=1660564584&expires_in=3816&long_lived_token={token}&granted_scopes=public_profile&denied_scopes=email&state={state}
// 로그인 취소
// https://gameround.co/launcher/oauth/facebook/?error=access_denied&error_code=200&error_description=Permissions+error&error_reason=user_denied&state={state}
export default function AuthFacebook() {
  // redirect parameter에 access_token이 넘어오는 경우엔 #이 붙어 있어서 hash로 분류되고 에러가 난 경우엔 일반 querystring으로 넘어온다.
  const searchParams = useMemo(
    () =>
      new URLSearchParams(
        window.location.search ? window.location.search : window.location.hash.substring(1)
      ),
    []
  );

  // email 등 권한이 없는 경우
  const denied = searchParams.get('denied_scopes');
  // 로그인을 취소하는 경우
  const error = searchParams.get('error');
  // 알 수 없는 에러
  const errorMessage = searchParams.get('error_message');
  const state = searchParams.get('state');

  useEffect(() => {
    // 에러가 발생한 경우 런처로 redirect
    if (state && (denied || error || errorMessage)) {
      window.location.replace(
        `${window.atob(decodeURIComponent(state))}?&error=${
          denied || error || errorMessage
        }&state=${state}`
      );
      console.log(denied, error, errorMessage);
    }
  }, [denied, error, errorMessage, state]);

  return (
    <div style={{ padding: 16 }}>
      {denied ? (
        `${denied} permission denied.`
      ) : error ? (
        error
      ) : errorMessage ? (
        errorMessage
      ) : (
        <AuthFacebookMe />
      )}
    </div>
  );
}
