import { Box, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useWindowSize } from 'usehooks-ts';

import { useHomeBannerAPI } from 'api/home/homeBannerAPI';
import { LinkButton } from 'elements';
import Carousel from 'elements/Carousel';
import eventTracker from 'utils/eventTracker';
import { useLog } from 'utils/userLog';

const TextContainer = styled(Stack)(() => ({
  position: 'absolute',
  left: 'calc(50% - 600px)',
  top: 310,
  width: 480,
  transform: 'translateY(-50%)',
  zIndex: 3,
}));

export default function HomeLoginAfterBanner() {
  const { isLoading, data } = useHomeBannerAPI();
  const { width } = useWindowSize();
  const titleRef = useRef<HTMLSpanElement>(null);
  const { gxcTag } = useLog();

  return (
    <Box component="section" sx={{ position: 'relative', width: '100%', alignSelf: 'flex-start' }}>
      <Box height="515px" />
      <Stack
        sx={{
          width: '100%',
          height: 'calc(100vw / 2.4)',
          position: 'absolute',
          minHeight: '800px',
          top: 0,
          left: 0,
          zIndex: 0,
          overflow: 'hidden',
        }}
      >
        {isLoading || data?.data.length === 0 ? (
          <Skeleton
            sx={{
              backgroundColor: 'rgba(100, 100, 100, 0.3)',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              marginBottom: '15px',
            }}
            variant="rectangular"
            width={1200}
            height={500}
          />
        ) : (
          <Carousel
            swiperOption={{
              centeredSlides: true,
              slidesPerView: 'auto',
              autoplay: {
                delay: 5000,
                disableOnInteraction: false,
              },
              loop: data?.data && data.data.length > 1,
              slideToClickedSlide: true,
            }}
            name="main-banner"
            paginationPosition="INSIDE"
            width={1200}
            hasNavigation
            navBtnIconType="arrow"
            navBtnRowPosition={-85}
            navigationPositionTop={256}
            style={{ overflow: 'visible' }}
            onClickLeftArrow={() => {
              gxcTag('click', {
                area: 'top_banners',
                ui: 'left_arrow',
              });
            }}
            onClickRightArrow={() => {
              gxcTag('click', {
                area: 'top_banners',
                ui: 'right_arrow',
              });
            }}
          >
            {data?.data?.map?.((banner) => (
              <SwiperSlide
                key={banner.site_banner_id}
                style={{
                  width: Math.max(width, 1920),
                  height: Math.max(width / 2.4, 800),
                }}
              >
                {({ isActive }) => (
                  <>
                    <Box
                      sx={{
                        width: '100%',
                        height: '120px',
                        position: 'absolute',
                        top: 0,
                        background: `linear-gradient(180deg, #000 11.46%, rgba(0, 0, 0, 0.00) 100%)`,
                        zIndex: 2,
                      }}
                    />
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bottom: 0,
                        background: `linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, #121212 100%);`,
                        zIndex: 2,
                      }}
                    />
                    <Box
                      sx={{
                        background:
                          'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat',
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bottom: 0,
                      }}
                    />
                    <img
                      src={banner.image_url}
                      alt="banner"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '100%',
                        height: '100%',
                        minHeight: '800px',
                        minWidth: '1920px',
                        zIndex: 1,
                      }}
                    />

                    {isActive && (
                      <TextContainer>
                        {/* headline text */}
                        {banner?.headline && (
                          <Typography variant="h1" ref={titleRef}>
                            {banner.headline}
                          </Typography>
                        )}
                        {/* bodycopy text */}
                        {banner?.body_copy && (
                          <Typography variant="body1" mt="12px">
                            {banner?.body_copy}
                          </Typography>
                        )}

                        <Stack direction="row" marginTop="40px" gap="8px">
                          {/* button 1 */}
                          {banner?.btn_name && banner?.btn_link_url && (
                            <LinkButton
                              to={banner?.btn_link_url}
                              color="light"
                              size="large"
                              variant="contained"
                              onClick={() => {
                                eventTracker('home_banner_main', {
                                  event_label: banner?.site_banner_id,
                                });
                                gxcTag('click', {
                                  area: 'top_banners',
                                  ui: banner?.btn_name?.toLowerCase().replace(' ', '_'),
                                  parameters: {
                                    banner_id: banner?.site_banner_id + '',
                                    link_url: banner?.btn_link_url,
                                  },
                                });
                              }}
                            >
                              {banner?.btn_name}
                            </LinkButton>
                          )}
                          {/* button 2 */}
                          {banner?.exist_option_btn &&
                            banner?.option_btn_name &&
                            banner?.option_btn_link_url && (
                              <LinkButton
                                to={banner?.option_btn_link_url}
                                color="light"
                                size="large"
                                variant="outlined"
                                onClick={() => {
                                  eventTracker('home_banner_option', {
                                    event_label: banner?.site_banner_id,
                                  });
                                  gxcTag('click', {
                                    area: 'top_banners',
                                    ui: banner?.option_btn_name?.toLowerCase().replace(' ', '_'),
                                    parameters: {
                                      banner_id: banner?.site_banner_id + '',
                                      link_url: banner?.option_btn_link_url,
                                    },
                                  });
                                }}
                              >
                                {banner?.option_btn_name}
                              </LinkButton>
                            )}
                        </Stack>
                      </TextContainer>
                    )}
                  </>
                )}
              </SwiperSlide>
            ))}
          </Carousel>
        )}
      </Stack>
    </Box>
  );
}
