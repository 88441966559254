import { Box } from '@mui/material';

import { NoData } from 'elements';

import noDataIcon from 'images/private_no_data_icon.svg';

export default function ReviewsMainPrivateUser() {
  return (
    <Box sx={{ height: 474 }}>
      <NoData
        imageSrc={noDataIcon}
        title={
          <>
            Other reviews are only viewable
            <br />
            by members of the studio or admin team.
          </>
        }
      />
    </Box>
  );
}
