import { LoadingButton } from '@mui/lab';

interface Props {
  loading: boolean;
  isBlind: boolean;
  onClick: () => void;
}

export default function ReviewBlindButton({ loading, isBlind, onClick }: Props) {
  return (
    <LoadingButton loading={loading} onClick={onClick} variant="ghost" size="tiny">
      {isBlind ? 'Hidden' : 'Hide'}
    </LoadingButton>
  );
}
