import { useMemo } from 'react';
import GameListBigCard from 'components/GameList/GameListBigCard';
import { useGameEventBadgeListAPI } from 'api/gamelist/gamelistAPI';
import { useFeaturedGamesAPI } from 'api/displayContent/displayContentAPI';
import { GameCardProps } from 'types/GameCardTypes';
import { useLog } from 'utils/userLog';
interface GameCardListBigContainerProps {
  count?: number;
  hoverVideoPlay?: boolean;
  showViewMoreCard?: boolean;
  likeButton?: boolean;
  useSlider?: boolean;
}

export default function GameListBigContainer({
  count = 4,
  hoverVideoPlay,
  showViewMoreCard,
  likeButton,
  useSlider,
}: GameCardListBigContainerProps) {
  const { gxcTag } = useLog();
  const { isLoading, isValidating, data, error } = useFeaturedGamesAPI();
  const { data: eventBadgeListData } = useGameEventBadgeListAPI();
  const isDataNotExist =
    (Array.isArray(data?.data) && data?.data.length === 0 && !isLoading && !isValidating) || error;

  const gamelist: GameCardProps[] = useMemo(
    () =>
      Array.isArray(data?.data) && data?.data && data?.data.length > 0
        ? useSlider
          ? data?.data
          : data?.data.slice(0, count)
        : [],
    [data?.data, useSlider, count]
  );

  return isDataNotExist ? null : (
    <GameListBigCard
      data={gamelist}
      loading={isLoading || isValidating}
      count={count}
      hoverVideoPlay={hoverVideoPlay}
      likeButton={likeButton}
      showViewMoreCard={showViewMoreCard}
      eventBadgeList={eventBadgeListData?.data}
      onClickCard={(gameId?: string) => {
        gxcTag('click', {
          area: 'featured',
          ui: 'game_card',
          parameters: typeof gameId === 'string' ? { game_id: gameId } : undefined,
        });
      }}
      useSlider={useSlider}
    />
  );
}
