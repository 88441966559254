import { SVGProps, useState } from 'react';

interface MuteIconProps extends SVGProps<SVGSVGElement> {
  muted: boolean;
}

export default function MuteIcon({ muted, ...props }: MuteIconProps) {
  const [hover, setHover] = useState(false);

  const handleOnMouseEnter = () => {
    setHover(true);
  };

  const handleOnMouseLeave = () => {
    setHover(false);
  };

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      <circle opacity={hover ? 0.6 : 0.4} cx="16" cy="16" r="16" fill="black" />
      <mask id="path-2-inside-1_3637_5068" fill="white">
        <path d="M17.4709 9.08183C17.1382 8.93675 16.7582 8.99129 16.4905 9.19243L12.1974 12.4207H9.53953C9.14544 12.4207 8.7512 12.5586 8.45119 12.8342C8.15001 13.1106 8 13.4718 8 13.8334V17.5067C8 17.8684 8.15001 18.2303 8.45119 18.5056C8.7512 18.7816 9.14544 18.9201 9.53953 18.9201H12.65L16.4905 21.8074C16.7582 22.0086 17.1382 22.0631 17.4709 21.9185C17.8044 21.7737 18 21.4696 18 21.1524V9.84832C18 9.53071 17.8044 9.22683 17.4709 9.08183" />
      </mask>
      <path
        d="M16.4905 9.19243L15.8895 8.39318L15.8897 8.39304L16.4905 9.19243ZM12.1974 12.4207L12.7984 13.2199L12.5314 13.4207H12.1974V12.4207ZM8.45119 12.8342L9.1277 13.5707L9.12726 13.5711L8.45119 12.8342ZM8.45119 18.5056L9.12593 17.7676L9.12815 17.7696L8.45119 18.5056ZM12.65 18.9201V17.9201H12.9839L13.2509 18.1208L12.65 18.9201ZM16.4905 21.8074L17.0915 21.0081L17.0915 21.0082L16.4905 21.8074ZM17.4709 21.9185L17.0723 21.0014L17.0726 21.0013L17.4709 21.9185ZM17.0712 9.99847C17.0757 10.0004 17.0779 9.99988 17.0768 10C17.0764 10.0001 17.0769 10.0001 17.0786 9.9994C17.0804 9.99871 17.0849 9.99669 17.0914 9.99181L15.8897 8.39304C16.4403 7.97926 17.2018 7.87356 17.8706 8.16518L17.0712 9.99847ZM17.0915 9.99168L12.7984 13.2199L11.5964 11.6214L15.8895 8.39318L17.0915 9.99168ZM12.1974 13.4207H9.53953V11.4207H12.1974V13.4207ZM9.53953 13.4207C9.37354 13.4207 9.2273 13.4792 9.1277 13.5707L7.77467 12.0978C8.2751 11.6381 8.91733 11.4207 9.53953 11.4207V13.4207ZM9.12726 13.5711C9.03042 13.6599 9 13.7547 9 13.8334H7C7 13.1888 7.2696 12.5612 7.77511 12.0974L9.12726 13.5711ZM9 13.8334V17.5067H7V13.8334H9ZM9 17.5067C9 17.5863 9.031 17.6808 9.12593 17.7676L7.77644 19.2437C7.26902 18.7798 7 18.1504 7 17.5067H9ZM9.12815 17.7696C9.22831 17.8618 9.37465 17.9201 9.53953 17.9201V19.9201C8.91623 19.9201 8.27409 19.7014 7.77422 19.2417L9.12815 17.7696ZM9.53953 17.9201H12.65V19.9201H9.53953V17.9201ZM13.2509 18.1208L17.0915 21.0081L15.8896 22.6068L12.0491 19.7194L13.2509 18.1208ZM17.0915 21.0082C17.085 21.0033 17.0805 21.0012 17.0787 21.0005C17.077 20.9999 17.0766 20.9999 17.0771 21C17.0785 21.0001 17.0766 20.9995 17.0723 21.0014L17.8695 22.8356C17.2015 23.126 16.4403 23.0208 15.8896 22.6067L17.0915 21.0082ZM17.0726 21.0013C17.0624 21.0057 17 21.0482 17 21.1524H19C19 21.891 18.5463 22.5417 17.8693 22.8358L17.0726 21.0013ZM17 21.1524V9.84832H19V21.1524H17ZM17 9.84832C17 9.95223 17.0625 9.99466 17.0721 9.99886L17.8697 8.16479C18.5463 8.459 19 9.10919 19 9.84832H17Z"
        fill="#D2D7DD"
        mask="url(#path-2-inside-1_3637_5068)"
      />
      <path
        d={muted ? 'M20 14L23 17' : 'M19.5 12.5C21.1236 13.9259 21.5 16.5 19.5 18.5'}
        stroke="#D2D7DD"
        strokeLinecap="round"
      />
      <path
        d={muted ? 'M23 14L20 17' : 'M21 11C22.4322 12.2692 24.6747 16.5 21 20'}
        stroke="#D2D7DD"
        strokeLinecap="round"
      />
    </svg>
  );
}
