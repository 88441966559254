import { Components } from '@mui/material';

const DividerTheme: Components = {
  MuiDivider: {
    styleOverrides: {
      wrapper: { paddingLeft: '20px', paddingRight: '20px' },
      wrapperVertical: { paddingTop: '20px', paddingBottom: '20px' },
    },
  },
};

export default DividerTheme;
