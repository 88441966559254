import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import bgCriteria from 'images/about/aboutDev/about_criteria_bg.png';

const PREFIX = 'ArtDevAboutCriteria';

const classes = {
  listWrap: `${PREFIX}-listWrap`,
  list: `${PREFIX}-list`,
};

const Root = styled('div')(({ theme }) => ({
  width: 1440,
  height: 421,
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${bgCriteria})`,
  backgroundSize: 'cover',

  [`& .${classes.listWrap}`]: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'flex-start',
    '& > ul:nth-of-type(2)': {
      marginLeft: 23,
    },
  },

  [`& .${classes.list}`]: {
    width: 532,
    margin: 0,
    paddingLeft: 20,
  },
}));

const listText = [
  'PC game',
  'Console game with a PC build & keyboard support',
  'MVP to late BETA',
  'Recommended 30-60 minutes of game time',
];

const listText2 = [
  'Can be publicly showcased',
  'Our QA team test for major blockers/critical bugs',
  'English Controls & Instructions',
  'No adult/gambling content',
];

export default function ArtDevAboutCriteria() {
  return (
    <Root>
      <Typography variant="h4" sx={{ width: '1080px', margin: '80px auto 40px' }}>
        Game Testing Criteria
      </Typography>
      <div className={classes.listWrap}>
        <ul className={classes.list}>
          {listText.map((item, index) => (
            <Typography variant="h6" component="li" key={index} sx={{ marginBottom: '28px' }}>
              {item}
            </Typography>
          ))}
        </ul>
        <ul className={classes.list}>
          {listText2.map((item, index) => (
            <Typography variant="h6" component="li" key={index} sx={{ marginBottom: '28px' }}>
              {item}
            </Typography>
          ))}
        </ul>
      </div>
    </Root>
  );
}
