import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { KeyedMutator } from 'swr';

import { useGetShippingCountryList } from 'api/gstore';
import { storeApiUrl } from 'api/gstore/gstoreAPI';
import { IOrder } from 'api/order';
import useFetch from 'api/useFetch';
import { ModalDialog } from 'elements';
import { DialogState, useFetchResult } from 'hooks';
import { IGStoreShippingFormData } from 'types/GStoreTypes';
import { ResponseData } from 'types/ResponseType';
import OrderShippingInfoForm from 'views/gstore/order/OrderShippingInfoForm';

interface IProps extends DialogState {
  shippingData?: IOrder.GetOrderDetail.OrderDetail['shipping'];
  orderId?: string;
  saleProductId?: string | null;
  refetch?: KeyedMutator<ResponseData>;
}

export function AddressDialog({ shippingData, ...props }: IProps) {
  const { showResult } = useFetchResult();

  const { isLoading, fetch } = useFetch(
    `${storeApiUrl}/api/store/order/${props.orderId}/shipping`,
    { method: 'PUT' },
    true
  );

  const { data: countryList } = useGetShippingCountryList(props.saleProductId ?? undefined);

  const defaultValues: IGStoreShippingFormData = {
    ship_to_name: shippingData?.name ?? '',
    ship_to_email: shippingData?.email ?? '',
    // +가 없는 번호로 저장된 경우 +를 붙여서 넣어준다.
    ship_to_phone: shippingData?.phone
      ? shippingData?.phone.startsWith('+')
        ? shippingData?.phone
        : `+${shippingData?.phone}`
      : '',
    ship_to_address1: shippingData?.address1 ?? '',
    ship_to_address2: shippingData?.address2 ?? '',
    ship_to_city: shippingData?.city ?? '',
    ship_to_state: shippingData?.state ?? '',
    ship_to_zipcode: shippingData?.zipcode ?? '',
    ship_to_country_code: shippingData?.country_code ?? '',
    id_card_no: shippingData?.id_card_no ?? '',
  };

  const methods = useForm<IGStoreShippingFormData>({
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const onSubmit = async (formData: IGStoreShippingFormData) => {
    const response = await fetch(formData);
    showResult(response, {
      onSuccess: async () => {
        if (props.onClose) props.onClose();
        if (props.refetch) await props.refetch();
      },
    });
  };

  return (
    <ModalDialog
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      width={500}
      body={
        <FormProvider {...methods}>
          <DevTool control={methods.control} placement="top-right" />
          <form autoComplete="off" onSubmit={methods?.handleSubmit(onSubmit)}>
            {countryList ? <OrderShippingInfoForm countries={countryList.data} /> : null}
            {(props.primaryButton || props.secondaryButton) && (
              <DialogActions sx={{ marginTop: '40px', padding: 0 }}>
                {props.secondaryButton && (
                  <Button color="primary" variant="ghost" fullWidth onClick={props.onClose}>
                    {props.secondaryButton.text}
                  </Button>
                )}
                {props.primaryButton && (
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    fullWidth
                    sx={{ marginLeft: '8px' }}
                  >
                    {props.primaryButton.text}
                  </LoadingButton>
                )}
              </DialogActions>
            )}
          </form>
        </FormProvider>
      }
    />
  );
}
