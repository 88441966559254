import { numberWithCommas } from '@ground/tools';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { isNumber } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { useGetPaymentDetailAPI } from 'api/gstore/gstoreAPI';
import { SectionBox } from '../SectionBox';

export default function GStoreOrderDetailPaymentBox() {
  const { orderId } = useParams<{ orderId: string }>();
  const { data: paymentData } = useGetPaymentDetailAPI(orderId);
  const theme = useTheme();

  const paymentList = paymentData?.data || [];

  return (
    <Box>
      <Typography variant="h5" mb="20px">
        Payment Information
      </Typography>

      <Stack
        direction="row"
        columnGap="40px"
        divider={<Divider flexItem orientation="vertical" />}
        sx={{ background: theme.palette.gray[90], borderRadius: '5px', padding: '40px' }}
      >
        <SectionBox
          title="Order Date"
          content={paymentList?.map((payment) =>
            payment.payment_at
              ? `${format(new Date(payment.payment_at), 'MMM dd, yyyy hh:mm a')}(PT)`
              : ''
          )}
        />

        <SectionBox
          title="Payment Method"
          content={paymentList?.map((payment) => payment.payment_type)}
        />
        <SectionBox
          title="Order Total"
          content={paymentList?.map(
            (payment) => (isNumber(payment.amount) ? numberWithCommas(payment.amount) : '') + ' GP'
          )}
        />
      </Stack>
    </Box>
  );
}
