import { createContext, PropsWithChildren, useContext, useState } from 'react';

export interface SignupBasicFormData {
  email: string;
  password: string;
  verificationCode: string;
}

export interface SignupProfileFormData {
  name: string;
  country: string;
  gender: string;
  birth_month: string;
  birth_day: string;
  birth_year: string;
  subscribe_email?: boolean;
}

export type SignupMode = 'basic' | 'profile' | 'complete';

export interface SignupProps {
  isMobile: boolean;
  basicFormData?: SignupBasicFormData;
  setBasicFormData: (data: SignupBasicFormData) => void;
  profileFormData?: SignupProfileFormData;
  setProfileFormData: (data: SignupProfileFormData) => void;
  mode: SignupMode;
  setMode: React.Dispatch<React.SetStateAction<SignupMode>>;
}

const SignupContext = createContext<SignupProps | undefined>(undefined);

interface Props {
  isMobile?: boolean;
}

export default function SignupProvider({ children, isMobile = false }: PropsWithChildren<Props>) {
  const [basicFormData, setBasicFormData] = useState<SignupBasicFormData | undefined>();
  const [profileFormData, setProfileFormData] = useState<SignupProfileFormData | undefined>();
  const [mode, setMode] = useState<SignupMode>('basic');

  return (
    <SignupContext.Provider
      value={{
        isMobile,
        basicFormData,
        setBasicFormData: (data) => {
          setBasicFormData(data);
          setMode('profile');
        },
        profileFormData,
        setProfileFormData: (data) => setProfileFormData(data),
        mode,
        setMode,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
}

export const useSignupData = () => {
  const context = useContext(SignupContext);
  if (!context) throw new Error('SignupData context not found');
  return context;
};
