import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import about_testing_img3 from 'images/home/featured-banner.png';

const CardRoot = styled(Box)(() => ({
  width: 590,
  height: 434,
  borderRadius: 5,
}));

export default function GameCardBigDiscord() {
  return (
    <Link to={'/blog/220'}>
      <CardRoot>
        <img src={about_testing_img3} alt="featured banner" width="590px" height="434px" />
      </CardRoot>
    </Link>
  );
}
